// import React from "react";
// import "./Theme27.css";
// import quotes from "../../../../Images/theme-27-testimonial.png"
// import testimonialdefault from '../../../../Images/testimonialdefault.png'
// import { environmentVariables } from "../../../../config/env.config";
// const TestimonialCarousel = ({ testimonials, currentElem, handleTouchStart, handleTouchMove, handleTouchEnd }) => {
//   const testimonialStyle = {
//     transform: `translateX(-${(currentElem * 100) / testimonials?.length}%)`,
//     transition: "transform 0.5s ease-in-out",
//     // width: `${testimonials * 100}%`,
//   };
//   const getImage = (val) => {
//     if (val?.imageView) {
//       return `${val?.imageView}`;
//     }
//     if (val?.image == "null") {
//       return `${testimonialdefault}`;
//     }
//     if (val?.image && val?.image != null) {
//       return `${environmentVariables?.apiUrl}uploads/${val?.image}`;
//     }
//   };
//   //   console.log(testimonials, currentElem, "inside carousel");
//   return (
//     <div className="testimonials-container" onTouchStart={handleTouchStart} onTouchMove={handleTouchMove} onTouchEnd={handleTouchEnd}>
//       <div className="testimonials" style={testimonialStyle}>
//         {testimonials.map((val, index) => {
//           return (
           
//           );
//         })}
//       </div>
//     </div>
//   );
// };

// export default TestimonialCarousel;

import React from "react";
import { environmentVariables } from "../../../../config/env.config";
import testimonialdefault from '../../../../Images/theme-27-img.png';
import "./Theme27.css";
import quotes from "../../../../Images/theme-27-testimonial.png"
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const TestimonialCarousel = ({ testimonials, currentElem, handleTouchStart, handleTouchMove, handleTouchEnd }) => {
  const testimonialStyle = {
    transform: `translateX(-${(currentElem * 100) / testimonials?.length}%)`,
    transition: "transform 0.5s ease-in-out",
    // width: `${testimonials * 100}%`,
  };
  const getImage = (val) => {
    if (val?.imageView) {
      return `${val?.imageView}`;
    }
    if (val?.image == "null") {
      return `${testimonialdefault}`;
    }
    if (val?.image && val?.image != null) {
      return `${environmentVariables?.apiUrl}uploads/${val?.image}`;
    }
  };
  //   console.log(testimonials, currentElem, "inside carousel");
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    draggable: true,
    arrows: false,
  };
  return (
    <>
      <div className="testimonials-container">
        <Slider {...settings}>
          {testimonials.map((val, index) => {
            return (
              <div class="testibox" key={index}>
              <div className="quote"><img src={quotes}/></div>
              <div className="testimonialcontent">
              
                <div className="clintname">
                
                  <p className="testimonialdescription">
                    {val?.description != "undefined" ? val?.description : ""}
                  </p>


<div className="hrline"></div>

                  <b className="testimonialname">
                   - {val?.name != "undefined" ? val?.name : ""}
                  </b>
                </div>
                <div className="testiimg">
                  <img
                    src={
                      getImage(val)
                    }
                    class="mx-auto d-block img-fluid clint-img2"
                    alt="Testimonial Image"
                  />
                </div>
              </div>
            </div>
            );
          })}
        </Slider>
      </div>
    </>
  );
};

export default TestimonialCarousel;
