import React, { useRef, useState } from "react";
// import "./TemplatComman.css";
import bannerImage from "../../../../Images/SalonBanner.png";
import logoImage from "../../../../Images/Saloonlogo1.jpg";
import testimonialImage from "../../../../Images/avatar.png";
import FacebookIcon from "../../../../Images/facebookIcon.jpg";
import InstagramIcon from "../../../../Images/instagramIcon.png";
import TweeterIcon from "../../../../Images/twitterIcon.png";
import LinedinIcon from "../../../../Images/linedInIcon.png";
import WhatsappIcon from "../../../../Images/whatsappIcon.png";
import CalanderIconn from "../../../../Images/calendarIcondays.png";

import "./Theme7.css";
import GalleryCarousel from "./GalleryCarousel";
import TestimonialCarousel from "./TestimonialCarousel";
const Theme7Template = (props) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const calendarInputRef = useRef(null);
  const testimonialArray = [
    {
      name: "Ernest Reinger",
      description:
        "I absolutely love the Constriction Services! They have exceeded my expectations in every way.",
      formCount: "0",
      image: "null",
    },
    {
      name: "Ernest Reinger",
      description:
        "I absolutely love the Constriction Services! They have exceeded my expectations in every way.",
      formCount: "1",
      image: "null",
    },
    {
      name: "Ernest Reinger",
      description:
        "I absolutely love the Constriction Services! They have exceeded my expectations in every way.",
      formCount: "2",
      image: "null",
    },
  ];
  const galleryArray = [
    {
      id: 60,
      business_id: 10,
      image: "1701329881279Rectangle6.jpg",
      created_by: 72,
      created_at: "2023-11-30T07:38:01.000Z",
      updated_at: "2023-11-30T07:38:01.000Z",
    },
    {
      id: 61,
      business_id: 10,
      image: "1701329883848Rectangle7.jpg",
      created_by: 72,
      created_at: "2023-11-30T07:38:03.000Z",
      updated_at: "2023-11-30T07:38:03.000Z",
    },
    {
      id: 62,
      business_id: 10,
      image: "1701329887518Rectangle597.jpg",
      created_by: 72,
      created_at: "2023-11-30T07:38:07.000Z",
      updated_at: "2023-11-30T07:38:07.000Z",
    },
    {
      id: 63,
      business_id: 10,
      image: "1701329891477Rectangle598.jpg",
      created_by: 72,
      created_at: "2023-11-30T07:38:11.000Z",
      updated_at: "2023-11-30T07:38:11.000Z",
    },
    {
      id: 64,
      business_id: 10,
      image: "1701329899378Rectangle599.jpg",
      created_by: 72,
      created_at: "2023-11-30T07:38:19.000Z",
      updated_at: "2023-11-30T07:38:19.000Z",
    },
    {
      id: 65,
      business_id: 10,
      image: "1701329902425Rectangle600.jpg",
      created_by: 72,
      created_at: "2023-11-30T07:38:22.000Z",
      updated_at: "2023-11-30T07:38:22.000Z",
    },
  ];

  const openCalendar = () => {
   
    if (calendarInputRef.current) {
      calendarInputRef.current.showPicker();
     
    }
  };
  return (
    <div>
      <div class="main-temaplt" id="temp7">
        <div className="container">
          <div className="row justify-content-center">
            <div style={{ width: "420px" }} className="col-12 wtemplate">
             
              <article className="imgsechere sec">
                <img
                  src={bannerImage}
                  className="mx-auto d-block img-fluid coverimghere "
                  style={{width:"100%"}}
                />
              </article>


              <div className="logoTextContainer">
               <div>
               <img
                src={logoImage}
                className="mx-auto d-block img-fluid logo-profile"
              />
              </div>
               <article className="personalinfo sec">
                <h3 className="username">Anastasia Anastasia</h3>
                <p className="titleuser">Barber, Looks Salon</p>
              </article>
              </div>
              <article className="makeappitmneets sec">
                {/* <h3 class="sectitle">Make Appointment</h3> */}
                <div className="contact-form">
                  <div class="col-12 mb-3" style={{position:"relative"}}>
                    <input
                      ref={calendarInputRef}
                      type="date"
                      class="form-control clear_string"
                      placeholder="Date"
                      name="currentDate"
                      // value={selectedDate}
                      // onChange={(e) => setSelectedDate(e.target.value)}
                    />
                        <div className="calanderIcon" onClick={openCalendar}><img src={CalanderIconn} alt="Calendar Icon" /></div>
                  </div>
                  <div class="col-12 mb-3">
                    <select
                      class="form-select"
                      aria-label="Default select example"
                      // onChange={(e) => setSelectedTime(e.target.value)}
                    >
                      <option value="Select time">Select time</option>
                      <option>{`2:00 PM to 5:00 PM`}</option>
                    </select>
                  </div>
                  <div class="col-12 mb-3">
                    <button
                      className="btn btnmakeappitmnets"
                      data-bs-toggle="modal"
                      data-bs-target="#appotimnets"
                    >
                      Make An Appointment
                    </button>
                  </div>
                </div>
              </article>

             
              <article className="abouttemplate sec">
                <h3 class="sectitle">About me</h3>
                <p className="para">
                  {`We offer premium salon services, including hair styling, skincare, and beauty treatments. Our expert stylists and beauty professionals deliver a relaxing experience tailored to each client’s unique style and needs.`}
                </p>
              </article>
              <article class="gallery sec sec-common">
                <h3 class="sectitle">Gallery </h3>
                <GalleryCarousel slides={galleryArray} />
              </article>
              <article className="social-links-sec sec">
                <h3 class="sectitle">Social Media</h3>
                <ul class="socil-link socil-link-inner">
                  <li>
                    <a>
                      {/* <i class={`fa-brands fa-facebook`}></i> */}
                      <img src={FacebookIcon} />
                    </a>
                  </li>
                  <li>
                    <a>
                      {/* <i class={`fa-brands fa-google`}></i> */}
                      <img src={InstagramIcon} />
                    </a>
                  </li>
                 
                  <li>
                    <a>
                      {/* <i class={`fa-brands fa-twitter`}></i> */}
                      <img src={TweeterIcon} />
                    </a>
                  </li>
                  <li>
                    <a>
                      {/* <i class={`fa-brands fa-twitter`}></i> */}
                      <img src={WhatsappIcon} />
                    </a>
                  </li>
                  <li>
                    <a>
                      {/* <i class={`fa-brands fa-twitter`}></i> */}
                      <img src={LinedinIcon} />
                    </a>
                  </li>
                </ul>
              </article>
              <article className="businessHours sec">
                <h3 class="sectitle">Working Hours</h3>

                <ul class="timelist">
                  <li>
                    <div className="day">Monday :</div>
                    <div className="time">
                      <span>8:00 AM</span>-<span>5:00 PM</span>
                    </div>
                  </li>
                  <li>
                    <div className="day">Tuesday :</div>
                    <div className="time">
                      <span>8:00 AM</span>-<span>5:00 PM</span>
                    </div>
                  </li>
                  <li>
                    <div className="day">Wednesday :</div>
                    <div className="time">
                      <span>8:00 AM</span>-<span>5:00 PM</span>
                    </div>
                  </li>
                  <li>
                    <div className="day">Thursday :</div>
                    <div className="time">
                      <span>8:00 AM</span>-<span>5:00 PM</span>
                    </div>
                  </li>
                  <li>
                    <div className="day">Friday :</div>
                    <div className="time">
                      <span>8:00 AM</span>-<span>5:00 PM</span>
                    </div>
                  </li>
                  <li>
                    <div className="day">Saturday :</div>
                    <div className="time">
                      <span>8:00 AM</span>-<span>5:00 PM</span>
                    </div>
                  </li>
                </ul>
              </article>
              {/* <article className="makeappitmneets sec">
                <h3 class="sectitle">Make Appointment</h3>
                <div className="contact-form">
                  <div class="col-12 mb-3">
                    <input
                      type="date"
                      class="form-control clear_string"
                      placeholder="Date"
                      name="currentDate"
                      // value={selectedDate}
                      // onChange={(e) => setSelectedDate(e.target.value)}
                    />
                  </div>
                  <div class="col-12 mb-3">
                    <select
                      class="form-select"
                      aria-label="Default select example"
                      // onChange={(e) => setSelectedTime(e.target.value)}
                    >
                      <option value="Select time">Select time</option>
                      <option>{`2:00 PM - 5:00 PM`}</option>
                    </select>
                  </div>
                  <div class="col-12 mb-3">
                    <button
                      className="btn btnmakeappitmnets"
                      data-bs-toggle="modal"
                      data-bs-target="#appotimnets"
                    >
                      Make An Appointment
                    </button>
                  </div>
                </div>
              </article> */}

              <article className="conatctform sec">
                <h3 class="sectitle">Contact Form</h3>
                <div class="contact-form">
                  <form>
                    <div class="col-12 mb-3">
                      <input
                        type="text"
                        class="form-control clear_string"
                        placeholder="Full Name"
                        name="last_name"
                        value=""
                        required=""
                      />
                    </div>
                    <div class="col-12 mb-3">
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Email"
                        name="email"
                        value=""
                        required=""
                      />
                    </div>
                    <div class="col-12 mb-3">
                      <input
                        type="text"
                        class="form-control clear_string"
                        maxlength="10"
                        minlength="10"
                        placeholder="Mobile Number"
                        name="mobile_number"
                        value=""
                        required=""
                      />
                    </div>
                    <div class="col-12 mb-3">
                      <textarea
                        class="form-control clear_string"
                        rows="3"
                        placeholder="Message*"
                        required=""
                        name="message"
                      ></textarea>
                    </div>

                    <div class="col-12 mb-3">
                      <button type="submit" class="btn btn-submitform">
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </article>
              <article className="servicesName sec">
                <h3 class="sectitle">Services </h3>
                <div className="mainservicebox">
                  <ul className="servesbox">
                    <li>Hair Cutting</li>
                    <li>Hair Colors</li>
                    <li>Hair Spa</li>
                    <li>Hair Treatments</li>
                  </ul>
                </div>
              </article>
              <article class="testimoniyalbox sec">
                <h3 class="sectitle testimonial">Testimonial </h3>

                <div>
                  <TestimonialCarousel
                    testimonials={testimonialArray}
                    currentElem={currentIndex}
                  />
                  <div className="carousel-container">
                    <button
                      onClick={() =>
                        currentIndex == 0
                          ? setCurrentIndex(testimonialArray.length - 1)
                          : setCurrentIndex((prev) => prev - 1)
                      }
                      className="carousel-btn"
                    >
                      <i class="fa fa-angle-left"></i>
                    </button>
                    <button
                      onClick={() => {
                        currentIndex == testimonialArray.length - 1
                          ? setCurrentIndex(0)
                          : setCurrentIndex((prev) => prev + 1);
                      }}
                      className="carousel-btn"
                    >
                      <i class="fa fa-angle-right"></i>
                    </button>
                  </div>
                </div>
              </article>
              <article class="contact sec">
                <h3 class="sectitle">Contact</h3>
                <ul class="conlink">
                  <li>
                    <a href="">
                      <i class="fa-solid fa-phone"></i>
                      <span>{`987654321`}</span>
                    </a>
                  </li>

                  <li>
                    <a href="">
                      <i class="fa-solid fa-envelope"></i>
                      <span>{` christian@gmail.com`}</span>
                    </a>
                  </li>

                  <li>
                    <a href="">
                      <i class="fa-solid fa-map"></i>
                      <span>{`No 17A / 22, Ajmal Khan Road,`}</span>
                    </a>
                  </li>
                  <li>
                    <a href="">
                      <i class="fa-solid fa-link"></i>
                      <span>{`www.Businessbay.io`}</span>
                    </a>
                  </li>
                </ul>
              </article>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Theme7Template;
