import React, { useContext } from "react";
import { userContext } from "../../../context/userContext";
import { environmentVariables } from "../../../config/env.config";
import { useNavigate } from "react-router";
import ResumeIcon from "./../../../Images/ResumeLogo.png";
import ProfilPic from "./../../../Images/ResumeProfile.png";
import PhoneIcon from "./../../../Images/Resume2phoneIcon.png";
import MessageIcon from "./../../../Images/Letter.png";
import LinkedInIcon from "./../../../Images/Resume2linkedInIcon.png";
import MapIcon from "./../../../Images/Mapicons.png";

import MailIcon from "./../../../Images/Resume2mailIcon.png";
import { AchivementsUl, AchivementsWrapper, ActivementsList, CertificationContainerWrapperJs, CertificationName, CertificationValue, CertificatoinContainerWrapper, CompanyLocationNameJs, CompanyLocationWrapperJs, ContainerJs, Date, DateCompanyLocationWrapperJs, DateWrapperJs, DesignationJs, DesignationWrapper, EducationSingleContainer, EducationWrapper, ExpertiesJs, ExpertiesContainerWrapperJs, ExpertiexListWrapper, HobbiesContainerWrapperJs, HorizontalLine, HorizontalLineWrapper, Image, Imagewrapper, KeySkillsContainerWrapperJs, LeftContainerJs, OneProject, PassingYear, PassingYearWrapper, PersonalDetailContainerWrapper, PersonalDetailsWrapper, PersonalInfoWrapperJs, PersonalInformationWrapper, PersonalNameJs, PersonalNameValueJs, PersonalWrapper, PersonalWrapperValue, PhoneIconTextWrapper, PhoneTextJs, PhoneTextValueWrapperJs, PhoneValue, PhoneWrapperJs, ProfileHeaadingJs, ProfileHeadingWrapper, ProfileHeadingWrapperJs, ProfileSummaryJs, ProfileSummaryWrapper, ProjectLinkJs, ProjectLinkWrapper, ProjectWrapperContainer, ResumeLeftRightContainerjs, ResumeLeftWrapperJs, ResumeRightWrapperJs, RightContainer, Root, SchoolUniversity, SchoolUniversityBordName, SchoolUniversityWrapper, SingleWorkExperienceJs, UserDesignation, UserDesignationJs, UserDesignationWrapper, UserProfileDestinationWrapper, UserProfileNameWrapper, Username, UsernameJs, WorkExperienceBoldHeadingWrapper, WorkExperienceHeadingJs, WorkexperienceSummaryJs, WorkexperienceSummaryWrapper } from "./Resume2ExtStyle";



const Resume2 = (props) => {
    // console.log(props,"propspropspropsprops")
    const { userData } = useContext(userContext);
    const baseUrl = environmentVariables?.apiUrl;
    const navigate = useNavigate();

    const formatDate = (dateString) => {
      // console.log(Date); // Check what Date is right here
      if (!dateString) return "";
      
      const date = new window.Date(dateString);
      const options = { month: "long", year: "numeric" };
      return date.toLocaleDateString("en-US", options);
  };


    const renderAchievement = () => {
      if (props?.onState?.achievement) {
        return (
          <AchivementsWrapper>
            <WorkExperienceBoldHeadingWrapper>
              <WorkExperienceHeadingJs>Achievements</WorkExperienceHeadingJs>
              <HorizontalLineWrapper>
                <HorizontalLine />
              </HorizontalLineWrapper>
            </WorkExperienceBoldHeadingWrapper>
    
            <AchivementsUl>
              {props.achievementArray.length > 0 ? (
                props.achievementArray.map((achievement, index) => (
                  <ActivementsList key={index}>{achievement.skillname}</ActivementsList>
                ))
              ) : (
              null
              )}
            </AchivementsUl>
          </AchivementsWrapper>
        );
      } else {
        return null;
      }
    };

    const renderAdditionalDetail = () => {
      if (props?.onState?.adddetails) {
        return (
          <HobbiesContainerWrapperJs>
            <WorkExperienceBoldHeadingWrapper>
              <WorkExperienceHeadingJs>Additional Details</WorkExperienceHeadingJs>
              <HorizontalLineWrapper>
                <HorizontalLine />
              </HorizontalLineWrapper>
            </WorkExperienceBoldHeadingWrapper>
    
            <ExpertiexListWrapper>
              {props.additionaldetailArray.length > 0 ? (
                props.additionaldetailArray.map((detail, index) => (
                  <ExpertiesJs key={index}>{detail.skillname}</ExpertiesJs>
                ))
              ) : (
              null
              )}
            </ExpertiexListWrapper>
          </HobbiesContainerWrapperJs>
        );
      } else {
        return null;
      }
    };

    const renderCertification = () => {
      if (props?.onState?.certification) {
        return (
          <CertificationContainerWrapperJs>
            <WorkExperienceBoldHeadingWrapper>
              <WorkExperienceHeadingJs>Certifications</WorkExperienceHeadingJs>
              <HorizontalLineWrapper>
                <HorizontalLine />
              </HorizontalLineWrapper>
            </WorkExperienceBoldHeadingWrapper>
    
            {props.certificationArray.length > 0 ? (
              props.certificationArray.map((certificate, index) => (
                <div key={index}>
                  <DesignationWrapper>
                    <DesignationJs>{certificate.certificatename}</DesignationJs>
                  </DesignationWrapper>
                  <CertificationName>{certificate.organisationname}</CertificationName>
                  <CertificationValue>{certificate.certificateidnumber}</CertificationValue>
                  <CertificationValue>
                    {formatDate(certificate.date)} - {formatDate(certificate.enddate)}
                  </CertificationValue>
                </div>
              ))
            ) : (
            null
            )}
          </CertificationContainerWrapperJs>
        );
      } else {
        return null;
      }
    };

    const renderTools = () => {
      if (props?.onState?.tools) {
        return (
          <ExpertiesContainerWrapperJs>
            <WorkExperienceBoldHeadingWrapper>
              <WorkExperienceHeadingJs>Tools</WorkExperienceHeadingJs>
              <HorizontalLineWrapper>
                <HorizontalLine />
              </HorizontalLineWrapper>
            </WorkExperienceBoldHeadingWrapper>
    
            <ExpertiexListWrapper>
              {props.toolsArray.length > 0 ? (
                props.toolsArray.map((tool, index) => (
                  <ExpertiesJs key={index}>{tool.toolname}</ExpertiesJs>
                ))
              ) : (
                null
              )}
            </ExpertiexListWrapper>
          </ExpertiesContainerWrapperJs>
        );
      } else {
        return null;
      }
    };

    const renderProjects = () => {
      if (props?.onState?.project) {
        return (
          <ProjectWrapperContainer>
            <ProfileHeadingWrapper>
              <ProfileHeaadingJs>Project</ProfileHeaadingJs>
              <HorizontalLineWrapper>
                <HorizontalLine />
              </HorizontalLineWrapper>
            </ProfileHeadingWrapper>
    
            {props.projectArray.map((project, index) => (
              <OneProject key={index}>
                <DateCompanyLocationWrapperJs>
                  <DateWrapperJs>
                    <Date>{formatDate(project.startDate)} - {project.currentlyWorking ? "Current" : formatDate(project.endDate)}</Date>
                  </DateWrapperJs>
                  <CompanyLocationWrapperJs>
                    <CompanyLocationNameJs>{project.projectname}</CompanyLocationNameJs>
                  </CompanyLocationWrapperJs>
                </DateCompanyLocationWrapperJs>
    
                <WorkexperienceSummaryWrapper>
                  <WorkexperienceSummaryJs>{project.projectdescription}</WorkexperienceSummaryJs>
                </WorkexperienceSummaryWrapper>
    
                <ProjectLinkWrapper>
                  <ProjectLinkJs href={project.projectlink} target="_blank">{project.projectlink}</ProjectLinkJs>
                </ProjectLinkWrapper>
              </OneProject>
            ))}
          </ProjectWrapperContainer>
        );
      } else {
        return null;
      }
    };

    const renderWrkExp = () => {
      if (props?.onState?.workExp) {
        return (
          <WorkExperienceBoldHeadingWrapper>
            <WorkExperienceHeadingJs>Work Experience</WorkExperienceHeadingJs>
            <HorizontalLineWrapper>
              <HorizontalLine />
            </HorizontalLineWrapper>
    
            {props.workExpArray.map((wrkExp, index) => (
              <SingleWorkExperienceJs key={index}>
                <DesignationWrapper>
                  <DesignationJs>{wrkExp?.jobtitle}</DesignationJs>
                </DesignationWrapper>
    
                <DateCompanyLocationWrapperJs>
                  <DateWrapperJs>
                    <Date>
                      {formatDate(wrkExp?.startdate)} -{" "}
                      {wrkExp?.enddate === "current" ? "Current" : formatDate(wrkExp?.enddate)}
                    </Date>
                  </DateWrapperJs>
                  <CompanyLocationWrapperJs>
                    <CompanyLocationNameJs>
                      {wrkExp?.companyname}, {wrkExp?.cityname}
                    </CompanyLocationNameJs>
                  </CompanyLocationWrapperJs>
                </DateCompanyLocationWrapperJs>
    
                <WorkexperienceSummaryWrapper>
                  <WorkexperienceSummaryJs>{wrkExp?.workSummary}</WorkexperienceSummaryJs>
                </WorkexperienceSummaryWrapper>
              </SingleWorkExperienceJs>
            ))}
          </WorkExperienceBoldHeadingWrapper>
        );
      } else {
        return null;
      }
    };
    
    const renderSkills = () => {
      if (props?.onState?.services) {
        return (
          <KeySkillsContainerWrapperJs>
            <WorkExperienceBoldHeadingWrapper>
              <WorkExperienceHeadingJs>Key Skills</WorkExperienceHeadingJs>
              <HorizontalLineWrapper>
                <HorizontalLine />
              </HorizontalLineWrapper>
            </WorkExperienceBoldHeadingWrapper>
    
            <ExpertiexListWrapper>
              {props.skillArray.map((skill, index) => (
                <ExpertiesJs key={index}>{skill.skillname}</ExpertiesJs>
              ))}
            </ExpertiexListWrapper>
          </KeySkillsContainerWrapperJs>
        );
      } else {
        return null;
      }
    };


    const renderEducation = () => {
      if (props?.onState?.isExperience) {
        return (
          <EducationWrapper>
            <WorkExperienceBoldHeadingWrapper>
              <WorkExperienceHeadingJs>Education</WorkExperienceHeadingJs>
              <HorizontalLineWrapper>
                <HorizontalLine />
              </HorizontalLineWrapper>
            </WorkExperienceBoldHeadingWrapper>
    
            {props.socialMediaData.map((education, index) => (
              <EducationSingleContainer key={index}>
                <DesignationWrapper DesignationName>
                  <DesignationJs>{education.degree}</DesignationJs>
                </DesignationWrapper>
                <SchoolUniversityWrapper>
                  <SchoolUniversity>{education.schuniname}</SchoolUniversity>
                  <SchoolUniversityBordName>{education.boardname}</SchoolUniversityBordName>
                  <PassingYearWrapper>
                    <PassingYear>
                      {formatDate(education.graduationyear)} - {formatDate(education.graduationendyear)}
                    </PassingYear>
                  </PassingYearWrapper>
                </SchoolUniversityWrapper>
              </EducationSingleContainer>
            ))}
          </EducationWrapper>
        );
      } else {
        return null;
      }
    };

    const renderPrsnldetails = () => {
      if (props?.onState?.prsnldetail) {
        return (
          <PersonalInformationWrapper>
            <WorkExperienceBoldHeadingWrapper>
              <WorkExperienceHeadingJs>PERSONAL INFORMATION</WorkExperienceHeadingJs>
              <HorizontalLineWrapper>
                <HorizontalLine />
              </HorizontalLineWrapper>
            </WorkExperienceBoldHeadingWrapper>
    
            <PersonalInfoWrapperJs>
              <PersonalWrapper>
                <PersonalNameJs>Father/ Spouse Name:</PersonalNameJs>
              </PersonalWrapper>
              <PersonalWrapperValue>
                <PersonalNameValueJs>{props?.formikData?.values?.fathername}</PersonalNameValueJs>
              </PersonalWrapperValue>
            </PersonalInfoWrapperJs>
    
            <PersonalInfoWrapperJs>
              <PersonalWrapper>
                <PersonalNameJs>Marital Status:</PersonalNameJs>
              </PersonalWrapper>
              <PersonalWrapperValue>
                <PersonalNameValueJs>{props?.formikData?.values?.maritalstatus}</PersonalNameValueJs>
              </PersonalWrapperValue>
            </PersonalInfoWrapperJs>
    
            <PersonalInfoWrapperJs>
              <PersonalWrapper>
                <PersonalNameJs>DOB:</PersonalNameJs>
              </PersonalWrapper>
              <PersonalWrapperValue>
                <PersonalNameValueJs>{props?.formikData?.values?.userdob}</PersonalNameValueJs>
              </PersonalWrapperValue>
            </PersonalInfoWrapperJs>
          </PersonalInformationWrapper>
        );
      } else {
        return null;
      }
    };
    

    const renderLanguage = () => {
      if (props?.onState?.language) {
        return (
          <HobbiesContainerWrapperJs>
            <WorkExperienceBoldHeadingWrapper>
              <WorkExperienceHeadingJs>Language</WorkExperienceHeadingJs>
              <HorizontalLineWrapper>
                <HorizontalLine />
              </HorizontalLineWrapper>
            </WorkExperienceBoldHeadingWrapper>
    
            <ExpertiexListWrapper>
              {props.languageArray.map((language, index) => (
                <ExpertiesJs key={index}>{language.languagename}</ExpertiesJs>
              ))}
            </ExpertiexListWrapper>
          </HobbiesContainerWrapperJs>
        );
      } else {
        return null;
      }
    };
   
    

    return (
        <Root>
          <ContainerJs>
           <ResumeLeftRightContainerjs>
              <ResumeLeftWrapperJs>
                <LeftContainerJs>
                <Imagewrapper>
                  <Image
                //    src={ProfilPic}
                src={
                    props?.logoImageUrl
                      ? props?.logoImageUrl?.includes("base64")
                        ? props?.logoImageUrl
                        :props?.logoImageUrl == "null"
                        ? ""
                        : `${baseUrl}uploads/resume/${props?.logoImageUrl}`
                      : ProfilPic
                  }
                   />
                 <UserProfileDestinationWrapper>
                 <UserProfileNameWrapper>
                    <UsernameJs> {props?.formikData?.values?.fullName || "Gagan"}</UsernameJs>
                  </UserProfileNameWrapper>
                  <UserDesignationWrapper>
                    <UserDesignationJs>
                    {props?.designation || "Node js"}
                    </UserDesignationJs>
                  </UserDesignationWrapper>
                 </UserProfileDestinationWrapper>
                </Imagewrapper>
  
                <PersonalDetailContainerWrapper>
                <PersonalDetailsWrapper>
                  <PhoneIconTextWrapper>
                    <PhoneWrapperJs src={PhoneIcon}/>
                  </PhoneIconTextWrapper>
                  <PhoneTextValueWrapperJs>
                    <PhoneTextJs>Phone</PhoneTextJs>
                    <PhoneValue>{props?.formikData?.values?.phone}</PhoneValue>
                  </PhoneTextValueWrapperJs>
  
                </PersonalDetailsWrapper>
  
                <PersonalDetailsWrapper>
                  <PhoneIconTextWrapper>
                    <PhoneWrapperJs src={MailIcon}/>
  
                  </PhoneIconTextWrapper>
                  <PhoneTextValueWrapperJs>
                    <PhoneTextJs>Email</PhoneTextJs>
                    <PhoneValue>{props?.formikData?.values?.email}</PhoneValue>
                  </PhoneTextValueWrapperJs>
  
                </PersonalDetailsWrapper>
  
                <PersonalDetailsWrapper>
                  <PhoneIconTextWrapper>
                    <PhoneWrapperJs src={MapIcon}/>
  
                  </PhoneIconTextWrapper>
                  <PhoneTextValueWrapperJs>
                    <PhoneTextJs>Location</PhoneTextJs>
                    <PhoneValue> {props?.formikData?.values?.address}</PhoneValue>
                  </PhoneTextValueWrapperJs>
  
                </PersonalDetailsWrapper>
  
                <PersonalDetailsWrapper>
                  <PhoneIconTextWrapper>
                    <PhoneWrapperJs src={LinkedInIcon}/>
  
                  </PhoneIconTextWrapper>
                  <PhoneTextValueWrapperJs>
                    <PhoneTextJs>Social Media Link</PhoneTextJs>
                    <PhoneValue>  {props?.formikData?.values?.linkedIn} </PhoneValue>
                  </PhoneTextValueWrapperJs>
  
                </PersonalDetailsWrapper>
                </PersonalDetailContainerWrapper>
  
                
                </LeftContainerJs>
              </ResumeLeftWrapperJs>
              <ResumeRightWrapperJs>
                 <RightContainer>
                  <ProfileHeadingWrapperJs> 
                    <ProfileHeaadingJs>
                      Profile
                    </ProfileHeaadingJs>
                    <HorizontalLineWrapper>
                      <HorizontalLine />
                    </HorizontalLineWrapper>
                  </ProfileHeadingWrapperJs>
                  <ProfileSummaryWrapper>
                    <ProfileSummaryJs>
                  {/* A student majoring in Advertising and UI / UX design. In this CV is my design and marketing experience. Things I achieve through projects and works. Therefore, I want a job starting UI/UX as a career to stabilize and enhance my competence. */}
                  {props?.formikData?.values?.description}
                    </ProfileSummaryJs>
                  </ProfileSummaryWrapper>
            
 {props?.onState?.workExp && renderWrkExp()}
 {props?.onState?.project && renderProjects()}
 {props?.onState?.isExperience && renderEducation()}
 {props?.onState?.adddetails && renderAdditionalDetail()}
 {props?.onState?.services && renderSkills()}
 {props?.onState?.certification && renderCertification()}
 {props?.onState?.language && renderLanguage()}
 {props?.onState?.achievement && renderAchievement()}
 {props?.onState?.tools && renderTools()}
 {props?.onState?.prsnldetail && renderPrsnldetails()}
  
                 </RightContainer>
              </ResumeRightWrapperJs>
           </ResumeLeftRightContainerjs>
          </ContainerJs>
        </Root>
    )

};

export default Resume2;