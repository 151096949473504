import React, { useEffect, useContext } from "react";
import { useState } from "react";
import SocialForm from "./SocialForm";
import { toast } from "react-toastify";
// import { businessContext } from "../../../context/businessContext";
import { businessObjContext } from "../../../context/businessObjContext";
import "./../../Pages/Updatedcss/BusinessChangeBlockUpdatedStyle.css";
import ResumeEducationSocialForm from "./ResumeEducationSocialForm";
import ResumeWorkExperienceSocialform from "./ResumeWorkExperienceSocialform";

function ResumeExperience({ setSocialMediaData,setWorkExpArray, socialMediaData }) {
  const [formList2, setFormList2] = useState([]);
  const [formCount, setFormCount] = useState(0);
  const [isAddServiceDisable, setIsAddSocialDisable] = useState(false);

  // const [isAddDisable, setIsAddDisable] = useState(false);
  const [socialData, setSocialData] = useState([]);
  const [isCurrentlyWorkingChecked, setIsCurrentlyWorkingChecked] = useState(false);

  const { businessObjData } = useContext(businessObjContext);
  const { resumeData } = useContext(businessObjContext);
  const createForm = () => {
    setIsAddSocialDisable(true);
    const newFormCount = formCount + 1;
    setFormCount(newFormCount);
    const newForm = {
      formCount: newFormCount,
      nameOfSocial: "",
      link: "",
    };
    const updatedSocialData = [...socialData, newForm];

    setSocialData(updatedSocialData);
    // setFormList2(updatedSocialData);
  };

  useEffect(() => {
    setFormList2(socialData);
    setWorkExpArray(socialData);
  }, [socialData]);

  useEffect(() => {
    let getServicesData;

    // console.log(businessObjData, "servce2i bb2j");
    if (resumeData && resumeData?.wrkExpcontent) {
      getServicesData = JSON.parse(resumeData?.wrkExpcontent);
      if (getServicesData.length) {
        // getServicesData = JSON.parse(getServicesData);
      }
    }

    // console.log(getServicesData, "service22i  Obj");
    if (getServicesData && getServicesData?.length) {
      setFormList2(getServicesData);
      setFormCount(getServicesData?.length);
      setSocialData(getServicesData);
    } else {
      setFormList2([]);
      setFormCount(0);
      setSocialData([]);
    }
  }, [resumeData]);

  const handleCurrentlyWorkingChange = (isChecked) => {
    if (isChecked) {
      setIsCurrentlyWorkingChecked(true);
    }
  };

  return (
    <>
      {/* {console.log(socialData,"rmList223")} */}
      {socialData ? (
        <div className="row ResumeAddNewStyleForm" id="Socialformpadd">
          {socialData.map((item, index, arr) => (
            <>
              <ResumeWorkExperienceSocialform
                formCount={item?.formCount}
                socialData={socialData}
                setIsAddSocialDisable={setIsAddSocialDisable}
                setSocialData={setSocialData}
                index={index}
                // removeForm={removeForm}
                isCurrentlyWorkingChecked={isCurrentlyWorkingChecked}
                handleCurrentlyWorkingChange={handleCurrentlyWorkingChange}
              ></ResumeWorkExperienceSocialform>
            </>
          ))}
        </div>
      ) : null}

      <button
        disabled={isAddServiceDisable}
        type="button"
        onClick={createForm}
        className="btn btn-cu addbuttonedit"
      >
        + Add new Work Experience
      </button>
    </>
  );
}

export default ResumeExperience;
