import React, { useEffect, useContext } from "react";
import { useState } from "react";
import AppointmentForm from "./AppointmentForm";
import { businessObjContext } from "../../../context/businessObjContext";

function AppointmentsCreate({
  setAppointmentArray,
  appointmentSlotDuration,
  businessHourObject,
  appointmentArray,
}) {
  // const [formList, setFormList] = useState([]);
  const [formCount, setFormCount] = useState(0);
  const [isAddServiceDisable, setIsAddAppointmentDisable] = useState(false);
  const [appointmentData, setAppointmentData] = useState([]);
  const [dayOfTheWeek, setDayOfTheWeek] = useState();
  const { businessObjData } = useContext(businessObjContext);

  // let getLocalStorageDataGet = localStorage.getItem("businessObj");

  function generateTimeSlots(startTime, endTime, slotDuration) {
    const slots = [];

    // Convert input strings to Date objects
    const startDate = new Date(`2000-01-01T${startTime}`);
    const endDate = new Date(`2000-01-01T${endTime}`);

    // Calculate slot duration in milliseconds
    const slotDurationMs = slotDuration * 60 * 1000;

    // Generate time slots
    let currentSlotStart = startDate.getTime();
    let formCount = 0;
    while (currentSlotStart < endDate.getTime()) {
      const currentSlotEnd = currentSlotStart + slotDurationMs;

      // Format the slot start and end times
      const formattedStart = new Date(currentSlotStart).toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
      });
      const formattedEnd = new Date(currentSlotEnd).toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
      });

      // Add the slot to the array
      slots.push({
        formCount: formCount,
        startDate: formattedStart,
        endDate: formattedEnd,
      });

      // Move to the next slot
      formCount++;
      currentSlotStart = currentSlotEnd;
    }

    return slots;
  }
  
  const handleChangeDay = (e) => {
    setDayOfTheWeek(e.target.value);
  };

  // useEffect(() => {
  //   let getLocalStorageData;
  //   if (getLocalStorageDataGet) {
  //     getLocalStorageData = JSON.parse(getLocalStorageDataGet);
  //   }
  //   // console.log(getLocalStorageData, "appointment bu b2j");
  //   if (
  //     getLocalStorageData &&
  //     getLocalStorageData?.appointmentObj &&
  //     getLocalStorageData?.appointmentObj?.content
  //   ) {
  //     getLocalStorageData = JSON.parse(
  //       getLocalStorageData?.appointmentObj?.content
  //     );
  //   }
  //   // console.log(getLocalStorageData,"getLocalStorageData",Array.isArray(getLocalStorageData?.length))
  //   if (getLocalStorageData && getLocalStorageData?.length) {
  //     if (Array.isArray(getLocalStorageData) == false) {
  //       getLocalStorageData = JSON.parse(getLocalStorageData);
  //     }
  //   }

  //   if (getLocalStorageData && getLocalStorageData?.length) {
  //     // console.log(getLocalStorageData, "appoint  Obj");
  //     setFormList(getLocalStorageData);
  //     setFormCount(getLocalStorageData?.length);
  //     setAppointmentData(getLocalStorageData);
  //   }
  //   return () => {
  //     setAppointmentData([]);
  //   };
  // }, [getLocalStorageDataGet]);

  // const createForm = () => {
  //   setIsAddAppointmentDisable(true);
  //   const newFormCount = formCount + 1;
  //   setFormCount(newFormCount);
  //   const newForm = {
  //     formCount: newFormCount,
  //     startDate: "",
  //     endDate: "",
  //   };
  //   const updatedData = [...formList, newForm];
  //   // setFormList(updatedData);
  //   setAppointmentData(updatedData);
  // };

  // const removeForm = (formKey) => {
  //   const updatedFormList = formList.filter((form) => form.key !== formKey);
  //   setFormList(updatedFormList);
  // };

  useEffect(() => {
    // setFormList(appointmentData);
    // setAppointmentArray(appointmentData);
    // dayOfTheWeek
    //   ? setAppointmentArray((prevArray) => ({
    //       ...prevArray,
    //       dayOfTheWeek: appointmentData,
    //     }))
    //   : setAppointmentArray(appointmentArray);
    // console.log(appointmentArray, "appointmentarrayinsdeiapp");
  }, [appointmentData]);
  useEffect(() => {
    if (businessObjData && businessObjData?.appointmentObj) {
      try {
        if (businessObjData?.appointmentObj?.content) {
          const parsedContent = JSON.parse(
            businessObjData?.appointmentObj?.content
          );

          setAppointmentArray(parsedContent);
          parsedContent[`${dayOfTheWeek}`]
            ? setAppointmentData(parsedContent[`${dayOfTheWeek}`])
            : setAppointmentData([]);
        }
      } catch (err) {
        console.log(err);
      }
    } else {
      let appointmentObj = {};
      appointmentObj["monday"] = generateTimeSlots(
        businessHourObject?.modayStartDate,
        businessHourObject?.modayEndDate,
        appointmentSlotDuration["monday"]
      );
      appointmentObj["tuesday"] = generateTimeSlots(
        businessHourObject?.tuesdayStartDate,
        businessHourObject?.tuesdayEndDate,
        appointmentSlotDuration["tuesday"]
      );
      appointmentObj["wednesday"] = generateTimeSlots(
        businessHourObject?.wednesdayStartDate,
        businessHourObject?.wednesdayEndDate,
        appointmentSlotDuration["wednesday"]
      );
      appointmentObj["thursday"] = generateTimeSlots(
        businessHourObject?.thrusdayStartDate,
        businessHourObject?.thrusdayEndDate,
        appointmentSlotDuration["thursday"]
      );
      appointmentObj["friday"] = generateTimeSlots(
        businessHourObject?.fridayStartDate,
        businessHourObject?.fridayEndDate,
        appointmentSlotDuration["friday"]
      );
      appointmentObj["saturday"] = generateTimeSlots(
        businessHourObject?.SaturdayStartDate,
        businessHourObject?.SaturdayEndDate,
        appointmentSlotDuration["saturday"]
      );
      appointmentObj["sunday"] = generateTimeSlots(
        businessHourObject?.SundayStartDate,
        businessHourObject?.SundayEndDate,
        appointmentSlotDuration["sunday"]
      );

      setAppointmentArray(appointmentObj);
      appointmentObj[`${dayOfTheWeek}`]
        ? setAppointmentData(appointmentObj[`${dayOfTheWeek}`])
        : setAppointmentData([]);
    }
  }, [businessHourObject, dayOfTheWeek, businessObjData]);

  return (
    <>
      <select
        className="appointmentdayselectbox"
        value={dayOfTheWeek}
        onChange={(e) => handleChangeDay(e)}
      >
        <option>Select Day</option>
        {businessHourObject?.isMonday && <option value="monday">Monday</option>}
        {businessHourObject?.isTuesday && (
          <option value="tuesday">Tuesday</option>
        )}
        {businessHourObject?.isWednesDay && (
          <option value="wednesday">Wednesday</option>
        )}
        {businessHourObject?.isthrusdayDay && (
          <option value="thursday">Thursday</option>
        )}
        {businessHourObject?.isfridayDay && (
          <option value="friday">Friday</option>
        )}
        {businessHourObject?.issaturdayDay && (
          <option value="saturday">Saturday</option>
        )}
        {businessHourObject?.issundayDay && (
          <option value="sunday">Sunday</option>
        )}
      </select>

      <div className="row">
        {appointmentData &&
          appointmentData?.length != 0 &&
          appointmentData?.map((form, index) => (
            <>
              <div key={index}>
                {/* {form} */}
                <AppointmentForm
                  formCount={form.formCount}
                  appointmentData={appointmentData}
                  setIsAddAppointmentDisable={setIsAddAppointmentDisable}
                  setAppointmentData={setAppointmentData}
                  index={index}
                  dayOfTheWeek={dayOfTheWeek}
                  setAppointmentArray={setAppointmentArray}
                  // removeForm={removeForm}
                ></AppointmentForm>
              </div>
            </>
          ))}
      </div>
    </>
  );
}

export default AppointmentsCreate;
/*
 <button
        disabled={isAddServiceDisable}
        type="button"
        onClick={createForm}
        className="btn btn-cu addbuttonedit"
      >
        + Add new Appointment
      </button>
      */
