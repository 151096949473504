import React from 'react'

function Footer() {


  if (location?.pathname === '/business') {
    return null; 
  }


  return (
    <>

    <footer>
      <div className='container CopywriteContainer'>

      {/* <div className="row py-1">
        <ul className='footerlink'
          style={{
            listStyle: "none",
            padding: 0,
            margin: 0,
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            color:'#000',
          }}
        >
          <li style={{ marginRight: "20px", }}>
            <a href="/faqs">FAQs</a>
          </li>
          <li style={{ marginRight: "20px" }}>
            <a href="/shipping">Shipping</a>
          </li>
          <li style={{ marginRight: "20px" }}>
            <a href="/privacy">Privacy</a>
          </li>
          <li style={{ marginRight: "20px" }}>
            <a href="/refund">Refund</a>
          </li>
          <li>
            <a href="/terms-and-conditions">T&C</a>
          </li>
        </ul>
      </div> */}



<div className='row '>
  <p className='text-center copyright_bottom'>Copyright © Bastionex India Technology Pvt Ltd | All Rights Reserved 2024</p>
</div>
      </div>
    </footer>
 
    </>
  )
}

export default Footer
