import React, { useState, useEffect } from 'react';

const TermAndCondition = (props) => {
    const [activeTab, setActiveTab] = useState("Inclusions");
    const [termsConditions, setTermsConditions] = useState([]);

    useEffect(() => {
        try {
            const parsedTermsConditions = props?.termsConditions || [];
            setTermsConditions(parsedTermsConditions);
        } catch (error) {
            console.error("Error parsing JSON:", error);
            setTermsConditions([]);
        }
    }, [props.termsConditions]); // Updated dependency

    const renderContent = () => {
        const activeContent = termsConditions.find(tc => tc.type === activeTab);
        if (!activeContent) return null;

        return (
            <div>
                <ul style={{ paddingLeft: "20px" }}>
                    {activeContent.text.map((item, index) => (
                        <li key={index} className='font-DM text-E7E7E7 font-semibold text-base py-2 list-disc'>
                            {item}
                        </li>
                    ))}
                </ul>
            </div>
        );
    };

    const getTabClass = (tabName) => {
        return activeTab === tabName
            ? "bg-Cabdefaultcolor rounded cursor-pointer flex items-center content-center align-center text-f7f7f7 py-3 text-center font-DM font-medium"
            : "bg-transparent rounded cursor-pointer flex items-center content-center align-center text-f7f7f7 py-3 text-center font-DM font-medium";
    };

    const getTabStyle = (tabName) => {
        return activeTab === tabName ? { flexBasis: '33.33%', display: "flex", justifyContent: "center" } : { flex: 1, display: "flex", justifyContent: "center" };
    };

    return (
        <div className='px-2'>
            <div className='capitalize font-Poppins text-xl text-white font-medium pb-2'>
                Term & Conditions
            </div>

            <div className='flex border-1 border-d0d0d030 rounded my-2' style={{ justifyContent: "space-between" }}>
                <div onClick={() => setActiveTab("Inclusions")} className={getTabClass("Inclusions")} style={getTabStyle("Inclusions")}>Inclusions</div>
                <div onClick={() => setActiveTab("Exclusions")} className={getTabClass("Exclusions")} style={getTabStyle("Exclusions")}>Exclusions</div>
                <div onClick={() => setActiveTab("T&C")} className={getTabClass("T&C")} style={getTabStyle("T&C")}>T&C</div>
            </div>
            <div>{renderContent()}</div>
        </div>
    );
}

export default TermAndCondition;
