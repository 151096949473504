import React,{ useEffect, useState } from 'react'
import { environmentVariables } from '../../../config/env.config';

function EditQrLink() {
  const fronturl = environmentVariables?.frntUrl;
  const [Link, setLink] = useState('');

const linkurl = {Link}

  return (
    <div className='sec-gap'>
<h3 className='templatheading'>Your Page Code <span>(Once saved, cannot be changed later)</span></h3>
<div class="input-group mb-1">
  <span class="input-group-text" id="basic-addon3">{`${fronturl}users/`}</span>
  <input type="text"
   class="form-control"
   id="basic-url" 
   value={Link}
  onChange={e => setLink(e.target.value)}/>

</div>
<small className='etidspan'>
*minimum 5 characters required.</small>


    </div>
  )
}

export default EditQrLink
