// src/utils/manifest.js
export const createDynamicManifest = (route) => {
    let manifestContent;
    const baseUrl = 'https://uat.businessbay.io';
    // const baseUrl = 'http://localhost:3000';
    manifestContent = {
        name: "Uat Businessbay App",
        short_name: "Businessbay",
        start_url: baseUrl+route,
        display: "standalone",
        background_color: "#ffffff",
        theme_color: "#0000ff",
        icons: [
            {
            "src": baseUrl+"/businessbay-logo-64x64.png",
            "sizes": "64x64 32x32 24x24 16x16",
            "type": "image/png",
            "purpose": "maskable any"   
            },
            {
            "src":baseUrl+"/businessbay-logo-192x192.png",
            "type": "image/png",
            "sizes": "192x192",
            "purpose": "maskable any"
            
            },
            {
            "src": baseUrl+"/businessbay-logo-512x512.png",
            "type": "image/png",
            "sizes": "512x512",
            "purpose": "maskable any"
            }
        ],
    };
    // if (route === "/about") {
    //   manifestContent = {
    //     name: "App for About",
    //     short_name: "AboutAPP",
    //     start_url: baseUrl+route,
    //     display: "standalone",
    //     background_color: "#ffffff",
    //     theme_color: "#0000ff",
    //     icons: [
    //         {
    //         "src": baseUrl+"/businessbay-logo-64x64.png",
    //         "sizes": "64x64 32x32 24x24 16x16",
    //         "type": "image/png",
    //         "purpose": "maskable any" 
    //         },
    //         {
            
    //         "src":baseUrl+"/businessbay-logo-192x192.png",
    //         "type": "image/png",
    //         "sizes": "192x192",
    //         "purpose": "maskable any"
            
    //         },
    //         {
    //         "src": baseUrl+"/businessbay-logo-512x512.png",
    //         "type": "image/png",
    //         "sizes": "512x512",
    //         "purpose": "maskable any"
    //         }
    //     ],
    //   };
    // } else {
    //   manifestContent = {
    //     name: "Home App",
    //     short_name: "App",
    //     start_url: baseUrl+route,
    //     display: "standalone",
    //     background_color: "#ffffff",
    //     theme_color: "#000000",
    //     icons: [
    //         {
    //         "src": baseUrl+"/businessbay-logo-64x64.png",
    //         "sizes": "64x64 32x32 24x24 16x16",
    //         "type": "image/png",
    //         "purpose": "maskable any" 
    //         },
    //         {
            
    //         "src":baseUrl+"/businessbay-logo-192x192.png",
    //         "type": "image/png",
    //         "sizes": "192x192",
    //         "purpose": "maskable any"
            
    //         },
    //         {
    //         "src": baseUrl+"/businessbay-logo-512x512.png",
    //         "type": "image/png",
    //         "sizes": "512x512",
    //         "purpose": "maskable any"
    //         }
    //     ],
    //   };
    // }
  
    const stringManifest = JSON.stringify(manifestContent);
    const blob = new Blob([stringManifest], { type: "application/json" });
    const url = URL.createObjectURL(blob);
    let manifestLink = document.querySelector('link[rel="manifest"]');
    if (!manifestLink) {
      manifestLink = document.createElement('link');
      manifestLink.rel = "manifest";
      document.head.appendChild(manifestLink);
    }
    manifestLink.href = url;
  };
  