import React, { useEffect, useContext } from "react";
import { useState } from "react";
import ServiceForm from "./ServiceForm";
import { businessObjContext } from "../../../context/businessObjContext";
import "./../Updatedcss/BusinessChangeBlockUpdatedStyle.css"
import ResumeServiceForm from "./ResumeServiceForm";
import ResumeachievementForm from "./ResumeachievementForm";

function ResumeAchievementscreate({ setServicesArray,setAchievementArray, businessObj }) {
  const [formCount, setFormCount] = useState(0);
  const [isAddServiceDisable, setIsAddServiceDisable] = useState(false);
  const [isAddDisable, setIsAddDisable] = useState(false);
  const [buttonLink, setButtonLink] = useState();
  const [servicesData, setServicesData] = useState([]);
  const { businessObjData } = useContext(businessObjContext);
  const { resumeData } = useContext(businessObjContext);

  const [showFileInput, setShowFileInput] = useState(false);

  const createForm = () => {
    const newFormCount = formCount + 1;
    setFormCount(newFormCount);

    const newForm = {
      formCount: newFormCount,
      banner: null,
      name: "",
      description: "",
      buttonLink: "",
      buttonName: "",
      bannerImage: null,
      isImageUpload:true
    };
    const updatedData = [...servicesData, newForm];
    setServicesData([...servicesData, newForm]);
    setIsAddServiceDisable(true);
    setIsAddDisable(true);
  };

  const removeForm = (index) => {
    const newServicesData = [...servicesData];
    newServicesData.splice(index, 1);
    setServicesData(newServicesData);
    setAchievementArray(newServicesData);
    setIsAddServiceDisable(false);
  };
  useEffect(() => {
    if (resumeData?.achievementcontent) {
      const serviceDataFromLocal = JSON.parse(
        resumeData?.achievementcontent
      );
      const updatedServiceData = serviceDataFromLocal.map((service, index) => ({
        ...service,
        formCount: index,
        isImageUpload:false
      }));
setFormCount(updatedServiceData.length)
      setServicesData([...updatedServiceData]);
    } else {
      setServicesData([]);
    }
  }, [resumeData]);

  useEffect(() => {
    setAchievementArray(servicesData);
  }, [servicesData]);

  return (
    <>
      <div className="row" id="AddNewStyleForm">
        {servicesData.map((form, index) => (
          <>
            <ResumeachievementForm
              formCount={formCount}
              servicesData={servicesData}
              setIsAddServiceDisable={setIsAddServiceDisable}
              setServicesData={setServicesData}
              removeForm={removeForm}
              index={index}
           
            ></ResumeachievementForm>
          </>
        ))}
      </div>
      <button
        disabled={isAddServiceDisable}
        type="button"
        onClick={createForm}
        className="btn btn-cu addbuttonedit"
      >
        + Add new Achievements
      </button>
    </>
  );
}

export default ResumeAchievementscreate;
