import React from 'react';
import "./LinkQrcodeStyle.css";
import LogoImg from "./Images/logo.jpg";

const LinkQrcode = () => {
  return (
    <div>
        
        <div class="wrapper">
         <div class="wrapper-inner">
            <div class="heading-wrapper">
                <div class="logo"> <img src={LogoImg} alt="logo" /> </div>               
            </div>
            <ul>
                <li> <a href="https://app.businessbay.io/" target="_blank"> Businessbay.io </a></li>
                <li> <a href="https://app.businessbay.io/register" target="_blank">Get your free Digital Business Card </a></li>
                <p> Social Links </p>
                <li> <a href="https://www.linkedin.com/company/business-bay/?viewAsMember=true" target="_blank"> Linkedin </a></li>
                <li><a href="https://www.instagram.com/_business_bay/" target="_blank"> Instagram</a></li>
                <li> <a href="https://www.facebook.com/people/Business-Bay/61551454974033/" target="_blank">Facebook </a></li>
                <li> <a href="https://twitter.com/i/flow/login?redirect_after_login=%2Fbusinessbay__" target="_blank"> Twitter </a></li>
                <li> <a href="https://t.me/businessbay_io" target="_blank"> Telegram </a></li>
            </ul>
        </div>
    </div>
    </div>
  )
}

export default LinkQrcode