import styled from "styled-components";
// import bg from '../../../images/bg.png';
import bg from "../../../Images/bg.png";
import { Link } from "react-router-dom";

export const Container = styled.div`
  box-sizing: border-box;
  max-width: 1240px;
  width: 100%;
  margin: 0 auto;
  background-color: #fff;
  padding: 0 0 10px 0;
  @media (max-width: 640px) {
    padding: 0 15px;
  }
`;




export const WrapperInner = styled.div`
  box-sizing: border-box;
  width: 100%;
  padding: 5px 88px 5px 100px;
  @media (max-width: 992px) {
    padding: 0 50px;
  }
  @media (max-width: 640px) {
    padding: 0;
  }
`;



export const HeaderWrapper = styled.div`
  max-width: 1240px;
  width: 100%;
  box-sizing: border-box;
  padding: 59px 0;
  background: #fff url(${bg});
  background-size: cover;
  background-position: left top;
  background-repeat: no-repeat;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 auto;
  @media (max-width: 640px) {
    padding: 29px 0;
  }
`;

export const LogoWrapper = styled.div`
  box-sizing: border-box;
  width: 168px;
  height: 168px;
  border-radius: 100%;
  border: 2px solid #ffffff;
  @media (max-width: 640px) {
    width: 128px;
    height: 128px;
  }
`;

export const Logo = styled.img`
  display: block;
  width: 100%;
  border-radius: 50%;
`;

export const HeadingWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;


export const NameWrapper = styled.h1`
  font-weight: 400;
  font-size: 48px;
  font-family: "Poppins", sans-serif;
  line-height: 72px;
  margin-bottom: 7px;
  color: #000;
  text-align: center;
  @media (max-width: 640px) {
    font-size: 32px;
    line-height: 48px;
    margin-bottom: 4px;
  }
`;



export const NamePara = styled.p`
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  font-size: 24px;
  line-height: 38px;
  margin-bottom: 16px;
  color: #000;
  text-transform: uppercase;
  letter-spacing: 2px;
  text-align: center;
  @media (max-width: 640px) {
    font-size: 14px;
    line-height: 21px;
  }
`;

export const ContactLists = styled.ul`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 24px 0 3px 0;
  margin-bottom: 37px;
  position: relative;
  @media (max-width: 640px) {
    &:after {
      position: absolute;
      content: "";
      left: 0;
      top: 0;
      width: 2px;
      height: 100%;
      background: #a9a9a9;
    }
    &:before {
      position: absolute;
      content: "";
      left: -3px;
      top: 0;
      width: 8px;
      height: 30px;
      border-radius: 50px;
      background: #dc8c01;
      z-index: 10;
    }
  }
`;
export const ContactHeading = styled.h3`
  position: absolute;
  left: 18px;
  top: 0;
  font-size: 16px;
  line-height: 24px;
  color: #000;
  text-transform: uppercase;
  display: none;
  @media (max-width: 640px) {
    display: block;
  }
`;
export const ListLeft = styled.div`
  width: 60%;
  @media (max-width: 640px) {
    width: 100%;
  }
`;
export const ListRight = styled.div`
  width: 40%;
  @media (max-width: 640px) {
    width: 100%;
  }
`;
export const ContactList = styled.li`
  margin-bottom: 21px;
  position: relative;
  @media screen and (max-width: 640px) {
    padding-left: 18px;
    z-index: 20;
    &:after {
      position: absolute;
      content: "";
      left: -4px;
      top: 7px;
      width: 10px;
      height: 10px;
      border-radius: 100px;
      background: #dc8c01;
      z-index: 20;
    }
  }
`;
export const ContactListHeading = styled.h3`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #2f2f2f;
  text-transform: upercase;
  font-family: "Poppins", sans-serif;
`;
export const ContactListPara = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #2f2f2f;
  padding-top: 5px;
  font-family: "Poppins", sans-serif;
`;

export const ContactLink = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #407bff;
  word-wrap: break-word;
`;

export const SummaryLists = styled.ul`
  position: relative;
  margin-bottom: 40px;
  &:after {
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    width: 4px;
    height: 100%;
    background: #a9a9a9;
  }
  &:before {
    position: absolute;
    content: "";
    left: -3px;
    top: 0;
    width: 10px;
    height: 40px;
    border-radius: 50px;
    background: #dc8c01;
    z-index: 10;
  }
  @media (max-width: 640px) {
    &:after {
      width: 2px;
    }
    &:before {
      left: -3px;
      top: 0;
      height: 30px;
      width: 8px;
    }
  }
`;

export const SumaryList = styled.li`
  box-sizing: border-box;
  padding-left: 23px;
`;

export const InnerSumaryList = styled.div`
box-sizing: border-box;
padding-left: 23px;
position: relative;
z-index: 20;
margin-bottom: 32px;
&:after {
  position: absolute;
  content: "";
  left: -5px;
  top: 10px;
  width: 10px;
  height: 10px;
  border-radius: 100px;
  background: #dc8c01;
  z-index: 20;
}
`;

export const SummaryHeading = styled.h2`
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  font-family: "Poppins", sans-serif;
  color: #000000;
  text-transform: uppercase;
  margin-bottom: 4px;
  @media (max-width: 640px) {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 0;
  }
`;

export const SummaryPara = styled.p`
  padding: 10px 0;
  font-weight: 400;
  font-size: 20px;
  font-family: "Poppins", sans-serif;
  line-height: 30px;
  color: #2f2f2f;
  @media (max-width: 640px) {
    font-size: 14px;
    line-height: 21px;
  }
`;
export const ProjectSummaryPara = styled.p`
  padding: 10px 0;
  font-weight: 400;
  font-size: 20px;
  font-family: "Poppins", sans-serif;
  line-height: 30px;
  color: #616161;
  @media (max-width: 640px) {
    font-size: 14px;
    line-height: 21px;
  }
`;

export const WorkHeading = styled.h2`
  box-sizing: border-box;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: #000000;
  font-family: "Poppins", sans-serif;
  text-transform: uppercase;
  margin-bottom: 4px;
  padding-left: 23px;
  @media (max-width: 640px) {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 4px;
  }
`;

export const WorkList = styled.li`
  box-sizing: border-box;
  padding-left: 23px;
  position: relative;
  z-index: 20;
  margin-bottom: 32px;
  &:after {
    position: absolute;
    content: "";
    left: -5px;
    top: 10px;
    width: 16px;
    height: 16px;
    border-radius: 100px;
    background: #dc8c01;
    z-index: 20;
  }
  @media (max-width: 640px) {
    &:after {
      top: 7px;
      left: -4px;
      width: 10px;
      height: 10px;
    }
  }
`;

export const WorkInnerHeading = styled.h3`
  font-weight: 500;
  font-size: 24px;
  font-family: "Poppins", sans-serif;
  line-height: 36px;
  color: #000000;
  text-transform: capitalize;
  @media (max-width: 640px) {
    font-size: 14px;
    line-height: 21px;
  }
`;
export const TextWrapper = styled.span`
  display: inline-block;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  padding-left: 10px;
  color: #000000;
  font-style: italic;
  @media (max-width: 640px) {
    display: block;
    font-size: 14px;
    line-height: 21px;
    padding-left: 0px;
  }
`;
export const WorkInnerPara = styled.p`
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: #000000;
  // font-style: italic;
  font-family: "Poppins", sans-serif;

  @media (max-width: 640px) {
    font-size: 14px;
    line-height: 21px;
    margin-bottom: 10px;
  }
`;
export const CertifiWorkInnerPara = styled.p`
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: #2f2f2f;
  font-style: italic;
  font-family: "Poppins", sans-serif;
  @media (max-width: 640px) {
    font-size: 14px;
    line-height: 21px;
    margin-bottom: 10px;
  }
`;
export const WorkInnerPara2 = styled.p`
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: #000000;
  font-style: italic;
  font-family: "Poppins", sans-serif;

  @media (max-width: 640px) {
    font-size: 14px;
    line-height: 21px;
    margin-bottom: 10px;
  }
`;
export const WorkInnerLists = styled.ul`
  box-sizing: border-box;
  padding-left: 20px;
  list-style-type: none;
`;
export const WorkInnerList = styled.li`
  box-sizing: border-box;
  padding-left: 15px;
  position: relative;
  font-weight: 400;
  font-size: 23px;
  line-height: 40px;
  font-family: "Poppins", sans-serif;
  color: ${({ color }) => (color ? color : "#616161")};
  &:after {
    position: absolute;
    content: "";
    left: -5px;
    top: 16px;
    width: 6px;
    height: 6px;
    border-radius: 100px;
    background: #a9a9a9;
    z-index: 20;
  }
  @media (max-width: 640px) {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 12px;
    padding-left: 10px;
    &:after {
      position: absolute;
      content: "";
      left: -5px;
      top: 10px;
      width: 6px;
      height: 6px;
      background: #a9a9a9;
    }
  }
  ${(p)=>p.WorkExperience && `
  position: initial;
  padding: 0;
  `}
`;

export const ProductSubHeading = styled.h3`
  font-weight: 400;
  font-size: 24px;
  line-height: 36px;
  font-family: "Poppins", sans-serif;
  color: #2f2f2f;
  @media (max-width: 640px) {
    font-size: 14px;
    line-height: 21px;
  }
`;
export const ProductSubHeadingOrg = styled.h3`
  font-weight: 400;
  font-size: 23px;
  line-height: 36px;
  font-style: italic;
  font-family: "Poppins", sans-serif;
  color: #2f2f2f;
  @media (max-width: 640px) {
    font-size: 14px;
    line-height: 21px;
  }
`;

export const ProductLink = styled(Link)`
  color: #407bff;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  font-family: "Poppins", sans-serif;
  font-style: italic;
  color: #407bff;
  @media (max-width: 640px) {
    font-size: 14px;
    line-height: 21px;
  }
`;

export const EducationWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;
export const EducationLeft = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  @media (max-width: 640px) {
    width: 100%;
    flex-direction: column;
  }
`;

export const EducationRight = styled.div`
  @media (max-width: 640px) {
    width: 100%;
  }
`;

export const EducationDegree = styled.h3`
  font-weight: 400;
  font-size: 24px;
  line-height: 40px;
  color: #2f2f2f;
  font-family: "Poppins", sans-serif;
  @media (max-width: 640px) {
    font-size: 14px;
    line-height: 21px;
  }
`;

export const EducationTime = styled.p`
  font-weight: 400;
  font-size: 20px;
  line-height: 40px;
  color: #616161;
  font-style: italic;
  font-family: "Poppins", sans-serif;
  @media (max-width: 640px) {
    font-size: 14px;
    line-height: 21px;
  }
`;

export const EducationCollege = styled.p`
  font-weight: 400;
  font-size: 20px;
  line-height: 40px;
  font-family: "Poppins", sans-serif;
  color: #616161;
  @media (max-width: 640px) {
    font-size: 14px;
    line-height: 21px;
  }
`;

export const SkillWrapper = styled.ul`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-left: 35px;
  @media (max-width: 640px) {
    flex-direction: column;
  }
`;

export const SkillList = styled.li`
  box-sizing: border-box;
  width: 40%;
  padding-left: 15px;
  position: relative;
  font-weight: 400;
  font-size: 24px;
  line-height: 40px;
  color: #2f2f2f;
  margin-bottom: 8px;
  font-family: "Be Vietnam pro", sans-serif;
  &:after {
    position: absolute;
    content: "";
    left: -5px;
    top: 16px;
    width: 6px;
    height: 6px;
    border-radius: 100px;
    background: #2f2f2f;
    z-index: 20;
  }
  @media (max-width: 640px) {
    padding-left: 10px;
    width: 100%;
    font-size: 14px;
    line-height: 21px;
    &:after {
      left: -5px;
      top: 9px;
      width: 6px;
      height: 6px;
    }
  }
`;
export const SkillListB = styled.li`
  box-sizing: border-box;
  width: 40%;
  padding-left: 15px;
  position: relative;
  font-weight: 400;
  font-size: 24px;
  line-height: 40px;
  color: #2f2f2f;
  margin-bottom: 8px;
  font-family: "Poppins", sans-serif;
  &:after {
    position: absolute;
    content: "";
    left: -5px;
    top: 16px;
    width: 6px;
    height: 6px;
    border-radius: 100px;
    background: #2f2f2f;
    z-index: 20;
  }
  @media (max-width: 640px) {
    padding-left: 10px;
    width: 100%;
    font-size: 14px;
    line-height: 21px;
    &:after {
      left: -5px;
      top: 9px;
      width: 6px;
      height: 6px;
    }
  }
`;

export const ToolWrapper = styled.ul`
  margin-left: 23px;
`;

export const ToolWrapperList = styled.li`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
`;

export const ToolPara = styled.p`
  width: 50%;
  font-size: 24px;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  line-height: 40px;
  color: #2f2f2f;
  @media screen and (max-width: 992px) {
    width: 60%;
  }
  @media screen and (max-width: 640px) {
    width: 60%;
    font-size: 14px;
    line-height: 21px;
  }
`;

export const ProgressWrapper = styled.div`
  width: 20%;
`;

export const ProgressContainer = styled.div`
  background-color: rgb(192, 192, 192);
  width: 100%;
  border-radius: 15px;
`;

export const ProgressSkill = styled.div`
  background-color: #dc8c01;
  color: white;
  padding: 2.5%;
  text-align: right;
  font-size: 20px;
  border-radius: 15px;
  width: ${({ width }) => (width ? width : "20%")};
`;

export const AchievementWrapper = styled.div`
  margin-left: 23px;
`;

export const PersonalWrapper = styled.ul`
  margin-left: 23px;
`;

export const PersonalList = styled.li`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-bottom: 8px;
  color: #2f2f2f;
  font-size: 24px;
  line-height: 40px;
  font-weight: 400;
  column-gap: 60px;
  text-transform: capitalize;
  @media (max-width: 600px) {
    font-size: 14px;
    line-height: 21px;
    justify-content: space-between;
    column-gap: 0px;
  }
`;
export const PersonalTitle = styled.div`
  min-width: 300px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  color: #2f2f2f;
  @media (max-width: 768px) {
    min-width: 50%;
    font-size: 16px;
  }
  @media (max-width: 640px) {
    font-size: 14px;
  }
`;
export const PersonalText = styled.div`
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  @media (max-width: 768px) {
    font-size: 16px;
  }
  @media (max-width: 640px) {
    font-size: 14px;
  }
`;




export const InnerContainer = styled(Container)`
padding: 0 0 10px 0;
`;
export const InnerWrapperInner = styled(WrapperInner)`
padding: 0 30px;
`;
export const InnerHeaderWrapper = styled(HeaderWrapper)`
padding: 29px 0;
`;

export const InnerLogoWrapper = styled(LogoWrapper)`
width: 128px;
height: 128px ;
`;

export const InnerNameWrapper = styled(NameWrapper)`
font-size: 25px;
line-height: 48px;
margin-bottom: 0px;
text-transform: capitalize;
font-weight: 600;
`;
export const InnerNamePara = styled(NamePara)`
font-size: 18px;
line-height: 21px;
margin-bottom: 30px;
text-transform: uppercase;
font-weight: 400;
letter-spacing: 1px;
`;

export const InnerContactLists = styled(ContactLists)`
&:after {
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    width: 2px;
    height: 100%;
    background: #a9a9a9;
  }
  &:before {
    position: absolute;
    content: "";
    left: -3px;
    top: 0;
    width: 8px;
    height: 30px;
    border-radius: 50px;
    background: #dc8c01;
    z-index: 10;
  }
}
`;

export const InnerContactHeading = styled(ContactHeading)`
display: block;
`;

export const InnerListLeft = styled(ListLeft)`
width: 100%;
margin: 20px 0 0 0;
`;

export const InnerListRight = styled(ListRight)`
width: 100%;
`;

export const InnerContactList = styled(ContactList)`
padding-left: 18px;
z-index: 20;
&:after {
  position: absolute;
  content: "";
  left: -4px;
  top: 7px;
  width: 10px;
  height: 10px;
  border-radius: 100px;
  background: #dc8c01;
  z-index: 20;
`;


export const InnerSummaryLists = styled(SummaryLists)`
&:after {
    width: 2px;
  }
  &:before {
    left: -3px;
    top: 0;
    height: 30px;
    width: 8px;
  }
`;

export const InnerSummaryHeading = styled(SummaryHeading)`
font-size: 16px;
line-height: 24px;
margin-bottom: 0;
`;

export const InnerSummaryPara = styled(SummaryPara)`
font-size: 14px;
line-height: 21px;
`;

export const InnerProjectSummaryPara = styled(ProjectSummaryPara)`
font-size: 14px;
line-height: 21px;
`;

export const InnerWorkHeading = styled(WorkHeading)`
font-size: 16px;
line-height: 24px;
margin-bottom: 4px;
`;

export const InnerWorkList = styled(WorkList)`
&:after {
    top: 7px;
    left: -4px;
    width: 10px;
    height: 10px;
  }
`;

export const InnerWorkInnerHeading = styled(WorkInnerHeading)`
font-size: 14px;
line-height: 21px;
`;

export const InnerTextWrapper = styled(TextWrapper)`
display: block;
font-size: 14px;
line-height: 21px;
padding: 0px;
`;

export const InnerWorkInnerPara = styled(WorkInnerPara)`
font-size: 14px;
line-height: 21px;
margin-bottom: 10px;
`;

export const InnerCertifiWorkInnerPara = styled(CertifiWorkInnerPara)`
font-size: 14px;
line-height: 21px;
margin-bottom: 10px;
`;


export const InnerWorkInnerPara2 = styled(WorkInnerPara2)`
font-size: 14px;
line-height: 21px;
margin-bottom: 10px;
`;

export const InnerWorkInnerList = styled(WorkInnerList)`
font-size: 14px;
line-height: 20px;
margin-bottom: 12px;
position: revert;
padding-left: 0px;
&:after {
  position: absolute;
  content: "";
  left: -5px;
  top: 10px;
  width: 6px;
  height: 6px;
  background: #a9a9a9;
}
${(p)=>p.InnerAchivement && `
&:after {
  position: initial;
 
}
`}
`;


export const InnerProductSubHeading = styled(ProductSubHeading)`
font-size: 14px;
line-height: 21px;
`;

export const InnerProductSubHeadingOrg = styled(ProductSubHeadingOrg)`
font-size: 14px;
line-height: 21px;
`;

export const InnerProductLink = styled(ProductLink)`
font-size: 14px;
line-height: 21px;
`;

export const InnerEducationLeft = styled(EducationLeft)`
width: 100%;
    flex-direction: column;
`;

export const InnerEducationRight = styled(EducationRight)`
width: 100%;
`;

export const InnerEducationDegree = styled(EducationDegree)`
font-size: 14px;
line-height: 21px;
`;

export const InnerEducationTime = styled(EducationTime)`
font-size: 14px;
line-height: 21px;
`;

export const InnerEducationCollege = styled(EducationCollege)`
font-size: 14px;
line-height: 21px;
`;

export const InnerSkillWrapper = styled(SkillWrapper)`
flex-direction: column;
`;

export const InnerSkillList = styled(SkillList)`
padding-left: 10px;
width: 100%;
font-size: 14px;
line-height: 21px;
&:after {
  left: -5px;
  top: 9px;
  width: 6px;
  height: 6px;
}
`;

export const InnerSkillListB = styled(SkillListB)`
padding-left: 10px;
width: 100%;
font-size: 14px;
line-height: 21px;
&:after {
  left: -5px;
  top: 9px;
  width: 6px;
  height: 6px;
}
`;

export const InnerToolPara = styled(ToolPara)`
width: 60%;
font-size: 14px;
line-height: 21px;
`;

export const InnerPersonalList = styled(PersonalList)`
font-size: 14px;
line-height: 21px;
justify-content: space-between;
column-gap: 0px;
`;

export const InnerPersonalTitle = styled(PersonalTitle)`
min-width: 50%;
min-width: 300px;
font-family: "Poppins", sans-serif;
font-weight: 500;
font-size: 14px;
color: #000;
`;