import React from "react";

function GalleryMake() {
  return (
    <div className="servicebox tile">
      <div className="mb-3">
        <label className="form-label">Image Link</label>
        <input className="form-control" type="email" placeholder="Image Link" />
      </div>

      <div className="mb-3">
        <label className="form-label"> Choose Image</label>
        <input className="form-control" type="file" />
      </div>

      <div className="mb-3">
        <label className="form-label">Image Vedio </label>
        <input className="form-control" type="email" placeholder="Vedio Link" />
      </div>

      <div className="mb-3">
        <label className="form-label">Choose Vedio</label>
        <input className="form-control" type="file" />
      </div>
    </div>
  );
}

export default GalleryMake;
