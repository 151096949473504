import React, {
  useContext,
  useEffect,
  useState,
  useRef,
  useCallback,
} from "react";
import PageName from "../Utils/PageName";
import { Link } from "react-router-dom";
import { environmentVariables } from "../../config/env.config";
import axios from "axios";
import { userContext } from "../../context/userContext";

import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import ReactDOM from "react-dom";
import DataTable from "react-data-table-component";
import SortIcon from "@material-ui/icons/ArrowDownward";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import { businessContext } from "../../context/businessContext";
// import CircularLoader from "../Components/CircularLoader/CircularLoader";
import CircularLoader from "../../Backend/Components/CircularLoader/CircularLoader";
import Swal from "sweetalert2";
import DatePicker from "react-datepicker";
import "./UpdateRequestcardstyle.css";
import "./Businesslist.css";
import Modal from "@mui/material/Modal";
import { Box } from "@mui/material";
import FilterIcon from "../../../src/Images/FilterIcon.png";
import { useDispatch, useSelector } from 'react-redux';
import queryString from 'query-string';
 


function Business({
  isChangeDynamicBusinessName,
  setIsChangeDynamicBusinessName,
  setDynamicBusinessName,
}) {
  const [data, setData] = useState([]);
  const [resumeData, setResumeData] = useState();

  const [loading, setLoading] = useState(true);
  const baseUrl = environmentVariables?.apiUrl;
  const { userData, setUserData } = useContext(userContext);

  const { businessData, setBusinessData } = useContext(businessContext);
  const [businesslistData, setBusinesslistData] = useState([]);
  const [isBusiness, setIsBusiness] = useState(false);
  const [resumeAvailable, setResumeAvailable] = useState();
  const [isLoader, setIsloader] = useState(true);
  const [userId, setUserId] = useState(null);
  const storedUserData = localStorage.getItem("user");
  const userDataObject = JSON.parse(storedUserData);
  const [isLoading, setIsLoading] = useState(true);
  let showLastLoginDateColumn = userData.user_type == "super_admin";
  let showEventsColumn = userData.user_type === "super_admin";
  const [paginationTotalRows, setPaginationTotalRows] = useState(0);
  const [paginationPerPage, setPaginationPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedRowsPerPage, setSelectedRowsPerPage] = useState([]);
  const [action] = useState({ fromUser: false }); //this is a way to have an instant-changing state
  const [rowsPerPage, setRowsPerPage] = useState(10); //change to 10 after you remove paginationRowsPerPageOptions
  const [page, setPage] = useState(1); //change to 10 after you remove paginationRowsPerPageOptions

  const [businessLink, setBusinessLink] = useState("");
  const [copied, setCopied] = useState(false);
  const tableExtensions = {
    export: false,
    print: false,
  };
  const fromDate = useRef(null);
  const toDate = useRef(null);

  const httpProtocol = environmentVariables?.httpProtocol;
  const fronturl = environmentVariables?.frntUrl;


  const handleEventClick = (rowData) => {
    // Implement your logic here for handling the click on the event icon
    // For example, you can open a popup or perform some other action
  };
  // const customStyles = {
  //   dataTableExtensions: {
  //     backgroundColor: "lightgray",
  //     padding: "10px",
  //   },
  // };

  const Boxstyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    // height: 500,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    zIndex: 999,
    borderRadius: "15px",
    border: "none",
    outline: "none",
    focus: "none",
    boxShadow:
      " rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;",
    padding: "3rem 5rem",
    "@media (max-width: 650px)": {
      width: "425px",
      padding: "2rem",
    },
    "@media (max-width: 430px)": {
      width: "380px",
      padding: "2rem",
    },
    "@media (max-width: 390px)": {
      width: "350px",
      padding: "2rem",
    },
  };

  const customStyles = {
    dataTableExtensions: {
      backgroundColor: "lightgray",
      padding: "10px",
    },
    headRow: {
      style: {
        backgroundColor: "#e9e9e9",
        borderRadius: "2px 2px 0 0",
        paddingLeft: "25px",
        border: "none",
        textTransform: "capitalize",
        position: "sticky",
        left: 0,
        top: 0,
        zIndex: 1,
        boxShadow: "0 2px 2px -1px rgba(0, 0, 0, 0.1)",
        overflow: "hidden",
        whiteSpace: "nowrap",
      },
    },
    headCells: {
      style: {
        color: "#525252",
        fontSize: "16px",
        minHeight: "55px",
      },
    },
    rows: {
      style: {
        padding: "6px 0px 6px 25px",
        fontSize: "14px",
        textTransform: "capitalize",
        minHeight: "58px !important",
      },
    },
    BusinessUserName: {
      position: "sticky",
      left: "0",
      zIndex: "1",
      backgroundColor: "#fff",
      overflow: "hidden",
      whiteSpace: "nowrap",
    },
  };
  const [modalOpen, setModalOpen] = useState(false);
  const [eventName, setEventName] = useState(null);
  const [eventLocation, setEventLocation] = useState(null);
  const [eventDate, setEventDate] = useState(null);
  const [businessEventId, setBusinessEventId] = useState(null);
  const [sortOrder, setSortOrder] = useState("");
  const [sortColumn, setSortColumn] = useState("");


  const [selectedItem, setSelectedItem] = useState(null);
  const [tempData, setTempData] = useState([]);
  const [totalCount, setTotalcount] = useState([]);
  const [tempBusinessList, setTempBusinessList] = useState([]);
  const [totalActivecount, setTotalActivecount] = useState([]);
  const [totalInActivecount, setTotalInActivecount] = useState([]);
  const [isEventEnabled, setIsEventEnabled] = useState([]);
  const [events, setEvents] = useState([]); // State to hold events fetched from API
  const [selectedEvent, setSelectedEvent] = useState(""); // State to store the selected event
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedEventId, setSelectedEventId] = useState("");

  const fetchBusinessData = async (
    userId,
    page = 1,
    rowsPerPage,
    searchQuery
  ) => {
    try {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${environmentVariables?.apiUrl}api/v1/business/fetchBusiness?id=${userId}&page=${page}&limit=${rowsPerPage}&businessStatus=${activeBusinessStatus}&startDate=${selectedDateFrom}&endDate=${selectedDateTo}&search=${searchQuery}&sortOrder=${sortOrder}&sortColumn=${sortColumn}`, //note: same api on APP.js please check too
        headers: {
          _token: userData?.token,
        },
        withCredentials: true,
      };
      await axios
        .request(config)
        .then((response) => {

          setIsloader(false);
          setResumeAvailable(response?.data?.data?.resume_id)

          let newData = response?.data?.data.map((val) => {
            val.created_at = val.created_at.split("T")[0];
            return val;
          });

          // newData = newData?.sort(
          //   (x, y) => new Date(y?.created_at) - new Date(x?.created_at)
          // );
          // setTempData(newData);

          setPaginationTotalRows(response?.data?.totalItems);
          setTotalcount(response?.data?.totalItems);
          setTotalActivecount(response?.data?.activeCount);
          setTotalInActivecount(response?.data?.inactiveCount);
          setBusinesslistData(response?.data?.data);
          setBusinesslistData(newData);
          let filteredData = [];

          // if (activeBusinessStatus != "All Status") {
          //   filteredData = newData.filter(
          //     (el) => el?.status == activeBusinessStatus
          //   );
          // } else {
          //   filteredData = newData;
          // }
          // if (selectedDateFrom && selectedDateTo) {
          //   filteredData = filteredData?.filter((el) => {
          //     const date = new Date(el.created_at);
          //     return date >= selectedDateFrom && date <= selectedDateTo;
          //   });
          // }
          // // setTempData(filteredData);
          // if (activeBusinessStatus != "") {
          //   setBusinesslistData(filteredData);
          // } else {
          //   setBusinesslistData(newData);
          // }
        })
        .catch((error) => {
          setIsloader(false);
        });
      // setBusinesslistData(newData);
      setIsLoading(false);
    } catch (err) {
      console.log(err, "error");
    }
  };

  const handleSort = (column, sortDirection) => {
    const key = column.sortCoulmn;
    const direction = sortDirection === 'asc' ? 'asc' : 'desc';
    setSortColumn(key);
    setSortOrder(direction);
  };
  useEffect(()=>{
    fetchBusinessData(userId, 1, 10, searchQuery);
  },[sortColumn, sortOrder]);

  const fetchEvents = async () => {
    try {
      axios
        .get(`${baseUrl}api/v1/events/getAllEvents`, {
          headers: {
            _token: userData?.token,
          },
          withCredentials: true,
        })
        .then((response) => {
          // setData(response?.data?.events);
          // setIsLoading(false)
          setEvents(response?.data?.events);
        })
        .catch((error) => {
          console.log(error);
          setIsLoading(false);
        });
    } catch (err) {}
  };

  const handlePageChange = (page) => {
    fetchBusinessData(userId, page, rowsPerPage, searchQuery);
    setCurrentPage(page);
  };

  const loadPageFilter = () => {
    const nextPage = 1;
    setCurrentPage(nextPage);
    fetchBusinessData(userId, nextPage, rowsPerPage, searchQuery);
  };

  const handleSearch = () => {
    loadPageFilter();
    setShowFilter(false);
  };

  const handleRowsPerPageChange = async (newRowsPerPage) => {
    if (!businesslistData.length) return; 
    fetchBusinessData(1, newRowsPerPage);
    setRowsPerPage(newRowsPerPage);
    setCurrentPage(1);
    setSelectedRowsPerPage([]);
  };

  const handleOnSelectedRowsChange = useCallback(
    ({ selectedRows }) => {
      if (!action.fromUser) return; 

      selectedRowsPerPage[currentPage] = selectedRows; 
      //the array would get an index to control each page
      console.log(JSON.stringify(selectedRowsPerPage));
    },
    [currentPage, selectedRowsPerPage, action.fromUser]
  );

  useEffect(() => {
    // if (userId) {
    fetchBusinessData(userId, page, rowsPerPage, searchQuery);
    // }
    fetchEvents();
  }, [userId, page, rowsPerPage, searchQuery]);

  useEffect(() => {
    if (userDataObject) {
      if (userDataObject && userDataObject.created_by != 0) {
        if (
          userDataObject?.permission.some(
            (obj) =>
              obj.permissionobj?.name?.includes("show business") ||
              obj.permissionobj?.name?.includes("create business")
          )
        ) {
          setUserId(userDataObject.created_by);
        }
      } else {
        const userId = userDataObject.id;
        setUserId(userId); // Set the 'userId' state
      }
    } else {
      console.log("Failed to parse user data from storage.");
    }
  }, []);

  const navigate = useNavigate();
  const dispatch = useDispatch();



  const fetchResumeMongo = async (business_id) => {
    try {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${environmentVariables?.apiUrl}api/v1/resume/fetchResumeById?id=${business_id}`,
        headers: {
          _token: userData?.token,
        },
        
        withCredentials: true,
      };
      const response = await axios.request(config)
      return response?.data?.resumeObj; 
    } catch (err) {
      console.log(err, "error");
    }
  };

  const handleTabClick = async (route) => {
    const parsedQuery = queryString.parseUrl(route);
    const busid = parseInt(parsedQuery.query.id, 10);
  
    try {
      const resumeData = await fetchResumeMongo(busid);
  
      dispatch({
        type: 'SET_USER_OBJECT',
        payload: { 'user_id': userDataObject.id, 'business_id': busid, 'resumeObj': resumeData }
      });
  
      navigate(route);
    } catch (error) {
      console.error('Error fetching resume data:', error);
    }
  };

  const handleviewClick = (slug, slug_subdomain) => {
    if (window != undefined) {
      let redirectUrl = (slug_subdomain != null)? httpProtocol +slug_subdomain+"?pswutlzoq=install":(slug !=null)? "/"+ slug+"?pswutlzoq=install":"/";

      window.open(
        `${redirectUrl}`,
        "_blank"
      );
    }
  };
  const [activeBusinessStatus, setBusinessStatus] = useState("All status");

  const handleToggleStatus = (businessId, currentStatus) => {
    const newStatus = currentStatus === "active" ? "inactive" : "active";
    // Make an API call to update the status
    axios
      .put(
        `${baseUrl}api/v1/business/updateStatus/${businessId}`,
        { status: newStatus },
        {
          headers: {
            _token: userData?.token,
          },
          // withCredentials: true
        }
      )
      .then((response) => {
        toast.success(response?.data?.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        fetchBusinessData(userId, page, rowsPerPage, searchQuery);
        const updatedBusinessListData = businesslistData.map((business) => {
          if (business.id === businessId) {
            return { ...business, status: newStatus };
          }
          return business;
        });
        setBusinesslistData(updatedBusinessListData);
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        console.error("Error updating business status:", error);
      });
  };

  const handleEventToggleStatus = async (eventId, currentStatus) => {
    try {
      const newStatus = currentStatus == "1" ? "0" : "1";

      const obj = {
        businessId: eventId,
        is_event_enabled: newStatus,
      };

      const res = await axios.post(
        `${baseUrl}api/v1/business/addeventDetails`,
        obj,
        {
          headers: {
            _token: userData?.token,
          },
        }
      );
      if (res && res.status == 200) {
        // fetchBusinessData(userId,page, rowsPerPage);
        const updatedBusinessListData = businesslistData.map((business) => {
          if (business.id === eventId) {
            return { ...business, is_event_enabled: newStatus };
          }
          return business;
        });
        setBusinesslistData(updatedBusinessListData);
      }
    } catch (err) {
      console.error("Error toggling event status:", err);
    }
  };

  const handleDeleteUser = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        // let data = JSON.stringify({
        //   userId: id,
        // });

        let config = {
          method: "delete",
          maxBodyLength: Infinity,
          url: `${environmentVariables?.apiUrl}api/v1/business/deleteBusiness?id=${id}`,
          headers: {
            _token: userData?.token,
            "Content-Type": "application/json",
          },
          // withCredentials: true,
        };

        axios
          .request(config)
          .then((response) => {
            setIsChangeDynamicBusinessName(!isChangeDynamicBusinessName);
            Swal.fire(
              "Deleted!",
              "Selected Business has been deleted.",
              // "success"
            );
            fetchBusinessData(userId, page, rowsPerPage, searchQuery);
            window.location.reload(2000);
            setDynamicBusinessName(businessData[0]?.title);
          })
          .catch((error) => {
            Swal.fire(
              "Error!",
              error?.response?.data?.message || error?.message,
              "error"
            );
          });
      }
    });
  };


  const handleCopuClick = (slug,slug_subdomain) => {
    // const originUrl = window.location.origin;
    // const linkToCopy = `${originUrl}/${slugb}`;

    if (window != undefined) {
      let linkToCopy = (slug_subdomain != null)? httpProtocol +slug_subdomain+"?pswutlzoq=install":(slug !=null)? fronturl+ slug+"?pswutlzoq=install":"/";
      setBusinessLink(linkToCopy);
    }
  };

  useEffect(() => {
    if (businessLink) {
      navigator.clipboard
        .writeText(businessLink)
        .then((res) => {
          setCopied(true);
        })
        .catch((error) => {
          console.error("Failed to copy link: ", error);
        });
      toast.info("Link Copy on Clipboard", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
    }
  }, [businessLink]);

  const columns = [
    {
      name: "User Name",
      selector: (row) => row?.userObj?.name || userData?.name,
      sortCoulmn:"name",
      sortable: true,
      fixed: "left",
      // style: customStyles.BusinessUserName,
    },
    {
      name: "Business Name",
      selector: (row) => row.title,
      sortCoulmn:"title",
      sortable: true,
      sortFunction: (a, b) => a?.title?.localeCompare(b?.title), 
      cell: (d) => {
        const capitalizedName =
          d.title.charAt(0).toUpperCase() + d.title.slice(1);
        return capitalizedName;
      },
    },
    {
      name: "Status",
      sortCoulmn:"status",
      selector: (row) => row.status,
      sortable: true,
      cell: (d) => {
        const capitalizedName =
          d.status.charAt(0).toUpperCase() + d.status.slice(1);
        return capitalizedName;
      },
      width: "100px",
    },
    {
      name: "Is User Imported",
      selector: (row) => row.status,
      sortCoulmn:"status",
      sortable: true,
      cell: (d) => {
      
        if (activeBusinessStatus === "imported") {
          return d?.userObj?.isLoggedIn == 1 ? "Yes" : "No";
        } else if (activeBusinessStatus === "notimported") {
          return d?.userObj?.isLoggedIn == 1 ? "No" : "Yes";
        } else {
          // Default case to show all data
          return d?.userObj?.isLoggedIn == 1 ? "No" : "Yes";
        }
      },
      omit: !showLastLoginDateColumn,
    },
    {
      name: "Viewers",
      selector: (row) => row.viewer,
      sortCoulmn:"viewer",
      sortable: true,
      width: "110px",
    },

    {
      name: "Events",
      sortable: false,
      cell: (d) => (
        <div className="form-check form-switch" style={{ padding: "0" }}>
          <input
            className="form-check-input"
            type="checkbox"
            checked={d?.is_event_enabled == "1"}
            id={`flexSwitchCheckDefault_${d.id}`}
            onChange={() => {
              handleEventToggleStatus(d.id, d.is_event_enabled);
            }}
          />
          {d?.is_event_enabled == "1" && (
            <i
              className="first fas fa-eye"
              data-bs-toggle="modal"
              data-bs-target="#planselect"
              style={{ marginLeft: "10px", cursor: "pointer" }}
              onClick={() => handleOpenModal(d)}
            ></i>
          )}
        </div>
      ),
      width: "135px",
      omit: !showLastLoginDateColumn,
    },

    {
      name: "Created Date",
      sortCoulmn:"created_at",
      selector: (row) => {
        let dateString = new Date(row?.created_at).toLocaleDateString();
        const [month, day, year] = dateString.split("/");
        return `${day}/${month}/${year}`;
      },
      sortable: true,
      width: "160px",
    },

    {
      name: "Last Login Date",
      sortCoulmn:"latestLogin_created_at",
      selector: (row) => {
        if (showLastLoginDateColumn) {
          if(!!row?.latestLogin){
            const date = new Date(
              row?.latestLogin && row?.latestLogin?.created_at
            );
            const dateString = date.toLocaleDateString("en-GB");
            return dateString;
          }else{
            return "-";
          }
        } else {
          return "-";
        }
      },
      sortable: true,
      omit: !showLastLoginDateColumn,
    },

    {
      name: "Active / In-Active",
      sortable: false,
      cell: (d) => (
        <div>
          {userDataObject?.user_type == "super_admin" ? (
            <div className="form-check form-switch">
              <input
                className="form-check-input"
                type="checkbox"
                checked={d?.status == "active" ? true : false}
                id={`flexSwitchCheckDefault_${d.id}`}
                onClick={() => {
                  handleToggleStatus(d.id, d.status);
                }}
              />
            </div>
          ) : userDataObject && userDataObject?.created_by != 0 ? (
            userDataObject &&
            userDataObject?.permission &&
            userDataObject?.permission.length &&
            userDataObject?.permission?.some((obj) =>
              obj?.permissionobj?.name?.includes("manage business")
            ) ? (
              <div className="form-check form-switch">
                <input
                  className="form-check-input"
                  // type="checkbox"
                  type="checkbox"
                  checked={d?.status == "active" ? true : false}
                  id={`flexSwitchCheckDefault_${d.id}`}
                  onClick={() => {
                    handleToggleStatus(d.id, d.status);
                  }}
                />
              </div>
            ) : (
              "--"
            )
          ) : (
            <div className="form-check form-switch">
              <input
                className="form-check-input"
                // type="checkbox"
                type="checkbox"
                checked={d?.status == "active" ? true : false}
                id={`flexSwitchCheckDefault_${d.id}`}
                onClick={() => {
                  handleToggleStatus(d.id, d.status);
                }}
              />
            </div>
          )}
        </div>
      ),
    },
    {
      name: "Resume",
      selector: (row) => row.resumeAvailable,
      sortCoulmn:"resume_id",
      sortable: true,
      cell: (d) => {
        return Number.isInteger(d.resume_id) ? "Yes" : "No";
      },
      width: "110px",
    },
    {
      name: "Action",
      sortable: false,
      cell: (d) => (
        <div>
          {userDataObject?.user_type == "super_admin" ? (
            <i
              key={`edit_${d.id}`}
              onClick={() => handleTabClick(`/business?id=${d.id}`)}
              className="first fas fa-pen L491"
            ></i>
          ) : userDataObject && userDataObject?.created_by != 0 ? (
            userDataObject &&
            userDataObject?.permission &&
            userDataObject?.permission.length &&
            userDataObject?.permission?.some((obj) =>
              obj?.permissionobj?.name?.includes("manage business")
            ) ? (
              <i
                key={`edit_${d.id}`}
                onClick={() => handleTabClick(`/business?id=${d.id}`)}
                className="first fas fa-pen L503"
              ></i>
            ) : (
              ""
            )
          ) : (
            <i
              key={`edit_${d.id}`}
              onClick={() => handleTabClick(`/business?id=${d.id}`)}
              className="first fas fa-pen L512"
            ></i>
          )}
          {userDataObject?.user_type == "super_admin" ? (
            <i
              style={{ width: "50px" }}
              onClick={() => handleDeleteUser(d.id)}
              className="fas fa-trash-alt"
            ></i>
          ) : userDataObject && userDataObject?.created_by != 0 ? (
            userDataObject &&
            userDataObject?.permission &&
            userDataObject?.permission.length &&
            userDataObject?.permission?.some((obj) =>
              obj?.permissionobj?.name?.includes("create business")
            ) ? (
              <i
                style={{ width: "50px" }}
                onClick={() => handleDeleteUser(d.id)}
                className="fas fa-trash-alt"
              ></i>
            ) : (
              ""
            )
          ) : (
            <i
              style={{ width: "50px" }}
              onClick={() => handleDeleteUser(d.id)}
              className="fas fa-trash-alt"
            ></i>
          )}
          <i
            key={`view_${d.id}`}
            onClick={() => handleviewClick(d.slug, d.slug_subdomain)}
            className="first fas fa-eye"
          ></i>
          <i
            key={`copy_${d.id}`}
            onClick={() => handleCopuClick(d.slug, d.slug_subdomain)}
            className="first fas fa-copy"
          ></i>
        </div>
      ),
    },
  ];
  console.log("businesslistData===>>>", businesslistData);
  
  const tableData = {
    columns,
    data: businesslistData,
  };
  const [selectedDateFrom, setSelectedDateFrom] = useState("");
  const [selectedDateTo, setSelectedDateTo] = useState("");
  const [showFilter, setShowFilter] = useState(false);

  const handleFilterOpen = () => {
    setShowFilter(!showFilter);
  };

  const handleFilterClose = () => {
    setShowFilter(false);
  };
  const handleChangeBusinessStatus = (e) => {
    if (e.target.value == "active") {
      setBusinessStatus("active");
    } else if (e.target.value == "inactive") {
      setBusinessStatus("inactive");
    } else if (e.target.value == "event") {
      setBusinessStatus("event");
    } else if (e.target.value == "All Status") {
      setBusinessStatus("All Status");
    }  else if (e.target.value == "imported") {
      setBusinessStatus("imported");
    }   else if (e.target.value == "notimported") {
      setBusinessStatus("notimported");
    }

  };

  useEffect(() => {
    if (selectedDateTo != "" && selectedDateFrom != "") {
      if (selectedDateTo < selectedDateFrom) {
        toast.warning("To Date should be equal to or greater then From Date", {
          position: toast.POSITION.TOP_RIGHT,
        });
        return;
      }
    }
    if (
      activeBusinessStatus == "All Status" &&
      !selectedDateFrom &&
      !selectedDateTo
    ) {
      setBusinesslistData(tempData);
    } else {
      let filteredData = tempData;
      if (activeBusinessStatus != "" && activeBusinessStatus != "All Status") {
        filteredData = filteredData.filter(
          (el) => el?.status == activeBusinessStatus
        );
      }
      if (selectedDateFrom && selectedDateTo) {
        filteredData = filteredData.filter((el) => {
          const date = new Date(el.created_at);
          return date >= selectedDateFrom && date <= selectedDateTo;
        });
      }
      // setTempData(filteredData);
      setBusinesslistData(filteredData);
    }
  }, [activeBusinessStatus, selectedDateFrom, selectedDateTo]);

  useEffect(() => {
    let Mydata =
      businesslistData.length === 0 ? businessData : businesslistData;
    setIsloader(false);
    if (Mydata && Mydata.length > 0) {
      setIsloader(false);
    }
  });

  const handleReset = () => {
    setBusinessStatus("All Status");
    setBusinesslistData(tempData);
    setSelectedDateFrom("");
    setSelectedDateTo("");
  };

  const handleOpenModal = async (d) => {
    try {
      const response = await axios.get(
        `${baseUrl}api/v1/business/fetcheventDetails?businessId=${d.id}`,
        {
          headers: {
            _token: userData?.token,
          },
        }
      );
      const eventData = response.data;
      const eventDetails = eventData.event_content
        ? JSON.parse(eventData.event_content)[0]
        : null;
      if (eventDetails) {
        setEventName(eventDetails.Eventname || "");
        setEventLocation(eventDetails.eventlocation || "");
        setEventDate(eventDetails.eventdate || "");
        setBusinessEventId(d.id);
        setSelectedEvent(eventDetails.selectedEvent || "");
      } else {
        setBusinessEventId(d.id);
        setEventName(null);
        setEventLocation(null);
        setEventDate(null);
        setSelectedEvent(null);
        console.error("Event content is null");
      }
    } catch (err) {
      console.error("Error fetching event details:", err);
      setEventName(null);
      setEventLocation(null);
      setEventDate(null);
      setBusinessEventId(null);
      setSelectedEvent(null);
    }
  };

  const handleSave = async (businessEventId,eventId) => {
    try {
      const eventData = [
        {
          // Eventname: eventName,
          selectedEvent: selectedEvent,
          eventlocation: eventLocation,
          eventdate: eventDate,
        },
      ];

      const obj = {
        businessId: businessEventId,
        event_content: JSON.stringify(eventData),
        is_event_enabled: 1,
        event_id: eventId
      };

      const res = await axios.post(
        `${baseUrl}api/v1/business/addeventDetails`,
        obj,
        {
          headers: {
            _token: userData?.token,
          },
        }
      );

      if (res && res.data && res.data.success) {
        fetchBusinessData(userId, page, rowsPerPage, searchQuery);
        // Handle success, show message, close modal, etc.
        const modalElement = document.getElementById("planselect");
        const modal = bootstrap.Modal.getInstance(modalElement);
        modal.hide();
      }
    } catch (err) {
      // Handle error
      console.error("Error saving event details:", err);
    }
  };

  const handleSearchInputChange = (event) => {
    // Update the search query state when the user types in the search input
    setSearchQuery(event.target.value);
  };

  return (
    <div>
      <PageName PageName="Business Information" />

      <div
        className="modal fade"
        id="planselect"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content EditStatus_form">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Event Details
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <form>
                <div className="g-3 mb-3">
                  <select
                    className="form-select"
                    value={selectedEvent}
                    onChange={(e) => {
                      setSelectedEvent(e.target.value);
                      // Find the selected event from the events array
                      const selectedEventData = events.find(
                        (event) => event.name === e.target.value
                      );
                      // Set the corresponding location and date values based on the selected event
                      setEventLocation(selectedEventData?.Location || "");
                      setEventDate(selectedEventData?.Date || "");
                      setSelectedEventId(selectedEventData?.id || "");
                    }}
                  >
                    <option value="">Select Event</option>
                    {events.map((event) => (
                      <option key={event.id} value={event.name}>
                        {event.name}
                      </option>
                    ))}
                  </select>
                  <div className="col d-none">
                    <textarea
                      className="form-control"
                      placeholder="Enter Remarks"
                      aria-label="Name"
                    ></textarea>
                  </div>
                  <div className="mb-2 col-md-12" style={{ marginTop: "0px" }}>
                    {/* <input
                    
                    className="form-control"
                    type="text"
                    placeholder="Event Name"
                    value={eventName || ""}
                    onChange={(e)=> setEventName(e.target.value)}
                  /> */}
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Location"
                      value={eventLocation || ""}
                      onChange={(e) => setEventLocation(e.target.value)}
                    />
                    <input
                      className="form-control"
                      type="date"
                      placeholder="Name"
                      value={eventDate || ""}
                      onChange={(e) => setEventDate(e.target.value)}
                    />
                  </div>

                  {/* <div className="col "></div> */}
                  <div className="col d-none">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Plan Name"
                      aria-label="Name"
                      name="txn_id"
                    />
                  </div>
                </div>
                <div className="ChangesEditform">
                  <button
                    type="button"
                    className="btn btn-primary save-cta"
                    onClick={() => handleSave(businessEventId,selectedEventId)}
                    data-bs-dismiss="modal"
                  >
                    {/* {isSubmit ? <CircularLoader size={20} /> : " Save changes"} */}
                    save
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <article>
        <div className="row d-none" style={{ position: "relative" }}>
          <div className="col-md-12">
            <div className="tile">
              <div className="tile-body">
                <form className="row">
                  <div className="mb-2 col-md-3">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Search....."
                    />
                  </div>
                  <div className="mb-2 col-md-3">
                    <select className="form-select">
                      <option value="">Select an option</option>
                      <option value="option1">xxx </option>
                      <option value="option2">xxxx</option>
                    </select>
                  </div>
                  <div className="mb-2 col-md-4 align-self-end">
                    <button className="btn btn-primary me-3" type="button">
                      <i className="bi bi-check-circle-fill ms-2 "></i>Search
                    </button>
                    <button className="btn btn-danger" type="button">
                      <i className="bi bi-check-circle-fill me-2"></i>Reset
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        {isLoader ? (
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "55%",
              display: "flex",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <CircularLoader size={40} />
          </div>
        ) : (
          <div className="main">
            {/* {businesslistData && businesslistData?.length > 0 && ( */}
            <>
              {userData.user_type === "super_admin" && (
                <div>
                  <label
                    style={{
                      color: "red",
                      fontSize: "16px",
                      marginBottom: "16px",
                      fontWeight: "bold",
                    }}
                  >
                    <span style={{ marginRight: "10px", color: "#9f9f9f" }}>
                      {" "}
                      Filter applied:{" "}
                    </span>
                    {activeBusinessStatus == "active"
                      ? "Active Business "
                      : activeBusinessStatus == "inactive"
                      ? "Inactive Business "
                      : activeBusinessStatus == "event"
                      ? "Event Business "
                      : activeBusinessStatus == "All status"
                      ? "All Business"
                      : null}
                    {selectedDateFrom !== ""
                      ? `${new Date(
                          selectedDateFrom
                        ).toLocaleDateString()} to ${new Date(
                          selectedDateTo
                        ).toLocaleDateString()}`
                      : " Till Now"}
                  </label>
                </div>
              )}

              <div className="filter-button-wrapper requestcardfilterbuttonWrapper">
                <div>
                  <input
                    className=" customsearchbyword"
                    type="text"
                    value={searchQuery}
                    onChange={handleSearchInputChange}
                    placeholder="Search Business/ Username"
                  />
                </div>
                <div className="eventTotalcardui">
                  {userData.user_type === "super_admin" && (
                    <>
                      <div
                        className="tile bbox Total_Titlebox_container"
                        style={{
                          minHeight: "107px",
                          minWidth: "100px",
                          margin: "0 15px",
                        }}
                      >
                        <i className="fa-solid fa-business-time"></i>
                        <h3>Total Business</h3>
                        <h5 className="tlcounts">{totalCount}</h5>
                      </div>
                      <div
                        className="tile bbox Total_Titlebox_container"
                        style={{
                          minHeight: "107px",
                          minWidth: "100px",
                          margin: "0 15px",
                        }}
                      >
                        <i className="fa-solid fa-business-time"></i>
                        <h3>Total Active Business</h3>
                        <h5 className="tlcounts">{totalActivecount}</h5>
                      </div>
                      <div
                        className="tile bbox Total_Titlebox_container"
                        style={{
                          minHeight: "107px",
                          minWidth: "100px",
                          margin: "0 15px",
                        }}
                      >
                        <i className="fa-solid fa-business-time"></i>
                        <h3>Total InActive Business</h3>
                        <h5 className="tlcounts">{totalInActivecount}</h5>
                      </div>
                    </>
                  )}
                  <span
                    className="FilterIconTextWrapper"
                    onClick={handleFilterOpen}
                  >
                    {" "}
                    {/* <i className="fa fa-filter" aria-hidden="true"></i> */}
                    <div className="FilterIcon">
                      {" "}
                      <img src={FilterIcon} width={28} />
                      <div className="filtertext">Filters</div>
                    </div>
                  </span>
                  <button
                    className="btn btn-primary requestcardfilterbuttonstyle"
                    onClick={handleFilterOpen}
                    id="FilterIconText"
                  >
                    Filter
                  </button>
                </div>
              </div>
              <Modal
                open={showFilter}
                onClose={handleFilterClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={Boxstyle}>
                  {showFilter && (
                    <div className="Contact_filter_fieldbutton_wrapper ModalPopupContainer">
                      <div className="Contact_filter_field_wrapper ModalPopupWrapper">
                        <div
                          className="col-md-2 Contact_filter_Inputfield"
                          id="ModalSelectfield"
                        >
                          {/* <h5 className="mb-3">Filter by Status</h5> */}
                          <select
                            value={activeBusinessStatus}
                            onChange={(e) => handleChangeBusinessStatus(e)}
                            className="form-select"
                          >
                            <option value={"All Status"}>All Status</option>
                            <option value="active">Active </option>
                            <option value="inactive">In-Active </option>
                            <option value="event">Event </option>
                          </select>
                          <div
                            style={{
                              fontSize: "14px",
                              color: "red",
                              bottom: "70px",
                            }}
                          ></div>
                        </div>
                        <div
                          className="col-md-2 Contact_filter_Inputfield"
                          id="ModalSelectfield"
                        >
                          {/* <h5 className="mb-3">From Date </h5> */}
                          <div className="dateiconmain">
                            <DatePicker
                              placeholderText="From Date"
                              selected={selectedDateFrom}
                              onChange={(date) => setSelectedDateFrom(date)}
                              className="form-control"
                              ref={fromDate}
                              dateFormat="dd/MM/yyyy"
                              popperPlacement="bottom-end"
                            />
                            <div
                              className="contact_filter_toDate_calander_icon"
                              onClick={() => fromDate.current.setOpen(true)}
                            >
                              <i className="fa-regular fa-calendar"></i>
                            </div>
                          </div>
                        </div>

                        <div
                          className="col-md-2 Contact_filter_Inputfield"
                          id="ModalSelectfield"
                        >
                          {/* <h5 className="mb-3">To Date </h5> */}
                          <div className="dateiconmain">
                            <DatePicker
                              placeholderText="To Date"
                              selected={selectedDateTo}
                              onChange={(date) => setSelectedDateTo(date)}
                              className="form-control"
                              ref={toDate}
                              dateFormat="dd/MM/yyyy"
                              popperPlacement="bottom-end"
                            />
                            <div
                              className="contact_filter_toDate_calander_icon"
                              onClick={() => toDate.current.setOpen(true)}
                            >
                              <i className="fa-regular fa-calendar"></i>
                            </div>
                          </div>
                        </div>
                        {/* <div className="mb-2 col-md-2 Contact_filter_Inputfield">
                      <h5 className="mb-3">From Date </h5>
                      <DatePicker
                        selected={selectedDateFrom}
                        onChange={(date) => setSelectedDateFrom(date)}
                        className="form-control"
                        ref={fromDate}
                        dateFormat="dd/MM/yyyy"
                      />
                      <div
                        className="contact_filter_toDate_calander_icon"
                        onClick={() => fromDate.current.setOpen(true)}
                      >
                        <i className="fa-regular fa-calendar"></i>
                      </div>
                    </div> */}
                        {/* <div className="mb-2 col-md-2 Contact_filter_Inputfield">
                      <h5 className="mb-3">To Date </h5>
                      <DatePicker
                        selected={selectedDateTo}
                        onChange={(date) => setSelectedDateTo(date)}
                        className="form-control"
                        ref={toDate}
                        dateFormat="dd/MM/yyyy"
                      />
                      <div
                        className="contact_filter_toDate_calander_icon"
                        onClick={() => toDate.current.setOpen(true)}
                      >
                        <i className="fa-regular fa-calendar"></i>
                      </div>
                    </div> */}
                        <div className="contact_filter_buttonwrapper">
                          <button
                            className="contact_filter_buttonstyle"
                            onClick={handleFilterClose}
                          >
                            Close 
                          </button>
                          <button
                            className="contact_filter_buttonstyle"
                            onClick={() => handleReset()}
                          >
                            Reset{" "}
                          </button>
                          <button
                            className="contact_filter_buttonstyle"
                            onClick={() => handleSearch()}
                          >
                            Search
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </Box>
              </Modal>
            </>
            {/* // )} */}

            <div className="scrollable-columns">
              <div className="sticky-header-container">
                <DataTableExtensions
                  {...tableData}
                  {...tableExtensions}
                  customStyles={customStyles}
                  // filterPlaceholder="Search Business"
                  noFilter
                  className="sercgetwcr"
                >
                  <DataTable
                    columns={columns}
                    data={data}
                    noHeader
                    defaultSortField="id"
                    sortIcon={<SortIcon />}
                    sortServer
                    onSort={handleSort}
                    pagination
                    highlightOnHover
                    paginationServer
                    paginationTotalRows={paginationTotalRows}
                    paginationPerPage={paginationPerPage}
                    paginationRowsPerPageOptions={[
                      10, 20, 50, 100, 300, 600, 1000, 5000, 10000,
                    ]} // Customize as needed
                    onChangePage={handlePageChange}
                    onChangeRowsPerPage={handleRowsPerPageChange}
                    onSelectedRowsChange={handleOnSelectedRowsChange}
                    dense
                    customStyles={customStyles}
                  />
                </DataTableExtensions>
              </div>
            </div>
          </div>
        )}
      </article>
    </div>
  );
}

export default Business;
