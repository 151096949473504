import * as React from 'react';

function PricingPage() {
  // Paste the stripe-pricing-table snippet in your React component
  return (
    <stripe-pricing-table
      pricing-table-id="'{{PRICING_TABLE_ID}}'"
      publishable-key="pk_test_51BTUDGJAJfZb9HEBwDg86TN1KNprHjkfipXmEDMb0gSCassK5T3ZfxsAbcgKVmAIXF7oZ6ItlZZbXO6idTHE67IM007EwQ4uN3"
      client-reference-id="{{CLIENT_REFERENCE_ID}}"
    >
    </stripe-pricing-table>
  );
}

export default PricingPage;