import React, { useState, useEffect } from "react";
import PhoneInput from "react-phone-input-2";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import "react-phone-input-2/lib/bootstrap.css";
import "react-datepicker/dist/react-datepicker.css";
import "./../Updatedcss/BusinessChangeBlockUpdatedStyle.css";
import { Horizontalline } from "./ResumeViewStyleThemejs";
import './Popupstyle.css'
import CircularLoader from "../../Components/CircularLoader/CircularLoader";
import { Box, Modal, Slider, Button } from "@mui/material";
import Typography from '@mui/material/Typography';
import { ExpandTwoTone } from "@mui/icons-material";
import { environmentVariables } from "../../../config/env.config";

const ResumeWorkExperienceSocialform = ({
  formCount,
  socialData,
  setIsAddSocialDisable,
  setSocialData,
  index,
  isCurrentlyWorkingChecked,
  handleCurrentlyWorkingChange,
}) => {

  const [data, setData] = useState({
    formCount: formCount,
    jobtitle: "",
    cityname: "",
    startdate: null,
    enddate: null,
    workSummary: "",
    degree: "",
    companyname: "",
    currentlyWorking: false,
  
  });
  const [countryCode, setCountryCode] = useState("");
  const [isAddDisable, setIsAddDisable] = useState(false);
  const [isAddShow, setIsAddShow] = useState(true);
  const [isEditMode, setIsEditMode] = useState(false); 
  const [allFieldsFilled, setAllFieldsFilled] = useState(false);
  const [errors, setErrors] = useState({});
  const [isAddButtonDisabled, setIsAddButtonDisabled] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [name, setName] = useState("");
  const [position, setPosition] = useState("");
  const [experience, setExperience] = useState("");
  const [isSubmit, setIsSubmit] = useState(false);
  const baseUrl = environmentVariables?.apiUrl;
  const [generatedSummary, setGeneratedSummary] = useState('');

  useEffect(() => {
    const filled =
      data.jobtitle &&
      data.cityname &&
      data.startdate &&
      data.degree &&
      data.companyname &&
      data.enddate &&
      data.workSummary
    setAllFieldsFilled(filled);
  }, [data]);




  useEffect(() => {
    let currentForm = socialData.filter((val) => val.formCount == formCount);
    if (currentForm.length > 0) {
      setData(currentForm[0]);
    }
    currentForm[0]["jobtitle"] ? setIsAddShow(false) : setIsAddShow(true);
  }, [socialData]);
  const getSocialLinkLabel = (name) => {
    if (name == "whatsapp") {
      return "Mobile";
    } else if (name == "telegram") {
      return "Username";
    } else if (name == "discord") {
      return "Channel Name";
    } else if (name == "pinterest") {
      return "Username";
    } else if (name == "gmail") {
      return "Email Id";
    }
  };


  useEffect(() => {
    // When in edit mode, populate form fields with data from state
    if (isEditMode) {
      const currentForm = socialData.find((val) => val.formCount === formCount);
      if (currentForm) {
        setData(currentForm);
      }
    }
  }, [isEditMode, socialData, formCount]);


  const handleAddSocial = () => {
    let selectedForm = socialData.filter(
      (obj) => obj.formCount === data.formCount
    );
    if (selectedForm.length > 0) {
      const updateItem = (formCount) => {
        const index = socialData.findIndex(
          (item) => item.formCount == formCount
        );

        const updatedItems = [...socialData];

        if (index !== -1) {

          updatedItems[index] = {
            ...updatedItems[index],
            cityname: data.cityname,
            jobtitle: data.jobtitle,
            workSummary: data.workSummary,
            startdate: data.startdate,
            degree: data.degree,
            companyname: data.companyname,
            enddate: data.enddate,
            currentlyWorking: data.currentlyWorking,
          };
        }
        setSocialData(updatedItems);
      };
      updateItem(data.formCount);
    } else {
      setSocialData([...socialData, data]);
    }
    setIsAddDisable(true);
    setIsAddSocialDisable(false);
  };

  const EditFormData = () => {
    setIsAddShow(true); // Show the form
    setIsEditMode(true); // Enter edit mode
  };

  // const handleInputChange = (e, field) => {
  //   setData({ ...data, [field]: e.target.value });
  // };

  const handleInputChange = (e, field) => {
    const inputValue = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    let updatedData = { ...data, [field]: inputValue }; // Prepare updated data with the new input value
    let error = "";
    if (field === "currentlyWorking") {
      // Handle Currently Working checkbox
      if (inputValue) {
        // If checkbox is checked, clear the end date
        updatedData = { ...updatedData, enddate: "current" };
      }
    } else if (field === "startdate") {
      // Start date validation
      const startDate = inputValue;
      const endDate = data.enddate;
      if (endDate && startDate > endDate) {
        updatedData = { ...updatedData, enddate: startDate }; // Update end date if necessary
      } else if (endDate && startDate === endDate) {
        error = "Please select an end date that is after the start date";
      }
    } else if (field === "enddate") {
      // End date validation
      const endDate = inputValue;
      const startDate = data.startdate;
      if (startDate && endDate < startDate) {
        error = "Please select an end date that is after the start date";
      }
    } else if (field === "jobtitle") {
      // const regex = /^[A-Za-z\s]{2,50}$/;
      const regex = /^[A-Za-z0-9\s/.,]{2,50}$/;
      if (!regex.test(inputValue)) {
        error = "Job title must contain only alphabetic characters";
      }
    } else if (field === "companyname") {
      // const regex = /^[A-Za-z\s]{2,50}$/; 
      const regex = /^[A-Za-z0-9\s/.,]{2,50}$/;
      if (!regex.test(inputValue)) {
        error = "contain only Number and alphabetic characters";
      }
    } else if (field === "cityname") {
      // const regex = /^[A-Za-z\s]{2,50}$/; 
      const regex = /^[A-Za-z0-9\s/.,]{2,50}$/;
      if (!regex.test(inputValue)) {
        error = "Location must contain only alphabetic characters";
      }
    } else if (field === "workSummary") {
      const regex = /^.{2,700}$/; 
      if (!regex.test(inputValue)) {
        error = "Work summary must be between 2 and 700 characters";
      }
    }

    setData(updatedData); // Update state only after validation
    if (field === 'currentlyWorking' && inputValue) {
      handleCurrentlyWorkingChange(true);
    }
    setErrors(prevErrors => ({
      ...prevErrors,
      [field]: error
    }));
  };


  const removeForm = (formKey) => {
    const newFormList = socialData.filter((val, index) => index !== formKey);
    setSocialData([...newFormList]);

    // if (socialData.length == 0) {
    setIsAddSocialDisable(false);
    // }
  };

  const handleSelectChange = (e) => {
    const selectedValue = e.target.value;
    setData({ ...data });
  };

  useEffect(() => {
    // Check if any errors exist in the errors object
    const hasErrors = Object.values(errors).some(error => error !== "");
    // Update the add button disabled state accordingly
    setIsAddButtonDisabled(hasErrors);
  }, [errors]);

  const handlegen = async () => {
    setIsSubmit(true);
    try {
      const response = await fetch(`${baseUrl}api/v1/resume/generatesummary`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name,
          designation: position,
          experience,
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to generate summary");
      }

      const data = await response.json();
      const contentWithoutQuotes = data.content.slice(1, -1);
      // formik.setFieldValue("description", contentWithoutQuotes);
      setGeneratedSummary(contentWithoutQuotes);
      // setData({
      //   ...data,
      //   workSummary: contentWithoutQuotes,
      // });
      setIsSubmit(false);
      // handleClose();
    } catch (error) {
      setIsSubmit(false);
      console.error("Error generating summary:", error);
    }
  };

  const handleConfirmSummary = () => {
    // setDescription(generatedSummary);
    setData({
        ...data,
        workSummary: generatedSummary,
      });
    handleClose();
  };

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    p: 4,
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setName('');
    setPosition('');
    setExperience('');
    setGeneratedSummary('');
  };

  return (
    <div key={`form-${formCount}`} className="col-12" id="Socialformsidepad">
      <div className="serviceboxedittime" id="SocialformsideMar">
        <i
          class="fa-solid fa-circle-xmark crossedit"
          onClick={() => removeForm(index)}
        ></i>

        <div className="">
          <div className="row mb-3" id="ResumeSocialInnerFormPaddissu" style={{ marginBottom: "0px !important" }}>
            <div className="">
              <div className="row mb-3" id="ResumeSocialInnerFormPaddissu">
                <span onClick={EditFormData} style={{padding:"2px 15px 10px 0px", cursor:"pointer", textAlign:"end"}}><i class="fa-solid fa-pen-to-square"></i></span>
                <div class=" col-md-12">
                  {/* <label class="form-label">Job Title</label> */}
                  <input
                    class="form-control"
                    type="text"
                    placeholder="Job Title"
                    value={data.jobtitle}
                    onChange={(e) => handleInputChange(e, "jobtitle")}
                    disabled={!isAddShow}
                  />
                  {errors.jobtitle && <div className="error" style={{ color: 'red', fontSize: "12px" }}>{errors.jobtitle}</div>}
                </div>

                <div class=" col-md-12">
                  {/* <label class="form-label">Employer</label> */}
                  <input
                    class="form-control"
                    type="text"
                    placeholder="Company Name"
                    value={data.companyname}
                    onChange={(e) => handleInputChange(e, "companyname")}
                    disabled={!isAddShow}
                  />
                  {errors.companyname && <div className="error" style={{ color: 'red', fontSize: "12px" }}>{errors.companyname}</div>}
                </div>

                <div class=" col-md-12">
                  {/* <label class="form-label">Location</label> */}
                  <input
                    class="form-control"
                    type="text"
                    placeholder="City Name"
                    value={data.cityname}
                    onChange={(e) => handleInputChange(e, "cityname")}
                    disabled={!isAddShow}
                  />
                  {errors.cityname && <div className="error" style={{ color: 'red', fontSize: "12px" }}>{errors.cityname}</div>}
                </div>

                <div class=" col-md-12">
                <div className="textarea-container" style={{ position: "relative" }}>
                  <textarea
                    class="form-control"
                    rows="3"
                    placeholder="Work Summary"
                    value={data.workSummary}
                    onChange={(e) => handleInputChange(e, "workSummary")}
                    disabled={!isAddShow}
                  />
                  <img
                      src="https://www.freepnglogos.com/uploads/logo-chatgpt-png/chatgpt-square-logo-blue-and-black-png-10.png"
                      alt="ChatGPT"
                      className="chatgpt-icon"
                      onClick={handleOpen}

                    />
                  </div>
                  {errors.workSummary && <div className="error" style={{ color: 'red', fontSize: "12px" }}>{errors.workSummary}</div>}
                </div>

                <Modal
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={style}>

                    <div className="modal-content">
                      <Typography id="modal-modal-title" variant="h6" component="h2">
                      AI Summary Generator 
                      </Typography>
                      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        <div className="modal-input">
                          <label>Name:</label>
                          <input
                            type="text"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            placeholder="Your name"
                          />
                        </div>
                        <div className="modal-input">
                          <label>Designation:</label>
                          <input
                            type="text"
                            value={position}
                            onChange={(e) => setPosition(e.target.value)}
                            placeholder="Your designation"
                          />
                        </div>
                        <div className="modal-input">
                          <label>Experience:</label>
                          <input
                            type="text"
                            value={experience}
                            onChange={(e) => setExperience(e.target.value)}
                            placeholder="Work experience in years"
                          />
                        </div>

                        <div>
                {generatedSummary && (
                  <>
                    <h5>Want to use the generated summary? Click on the "Use" button:</h5>
                    <p>{generatedSummary}</p>
                  </>
                )}
              </div>

                        <div className="modal-footer">
                        {generatedSummary && (
                    <button onClick={handleConfirmSummary}>use</button>
                        )}
                          <button onClick={handleClose}>Close</button>
                          <button
                            onClick={handlegen}
                            type="submit"
                          >
                            {isSubmit ? <CircularLoader size={25} /> : "Generate"}
                          </button>
                        </div>
                      </Typography>
                    </div>
                  </Box>
                </Modal>

                <div class=" col-md-12">
                  <input
                    class="form-control"
                    type="date"
                    value={data.startdate}
                    onChange={(e) => handleInputChange(e, "startdate")}
                    disabled={!isAddShow}
                  />
                  {errors.startdate && <div className="error" style={{ color: 'red', fontSize: "12px" }}>{errors.startdate}</div>}
                </div>

                <div class=" col-md-12">
                  {/* <label class="form-label">End Year</label> */}
                  <input
                    class="form-control"
                    type="date"
                    value={data.enddate}
                    onChange={(e) => handleInputChange(e, "enddate")}
                    disabled={!isAddShow || data.currentlyWorking}
                  />
                  {errors.enddate && <div className="error" style={{ color: 'red', fontSize: "12px" }}>{errors.enddate}</div>}
                </div>
                {/* <div class=" col-md-12">
                  <input
                    type="checkbox"
                    checked={data.currentlyWorking}
                    onChange={(e) => handleInputChange(e, "currentlyWorking")}
                    disabled={!isAddShow}
                  />
                  <label style={{ marginLeft: '5px' }}>Currently Working</label>
                </div> */}

{!isCurrentlyWorkingChecked || data.currentlyWorking ? (
        <div className="col-md-12">
          <input
            type="checkbox"
            checked={data.currentlyWorking}
            onChange={(e) => handleInputChange(e, 'currentlyWorking')}
          />
          <label style={{ marginLeft: '5px' }}>Currently Working</label>
        </div>
      ) : null}

              </div>

            </div>
          </div>
        </div>

        {isAddShow ? (
          <div class="resumeworkexpaddbutton" >
            <button
              type="button"
              onClick={handleAddSocial}
              className={`btn btn-cu addbuttoneditform editButtonTextimonial ${allFieldsFilled ? 'filled' : ''}`}
              disabled={isAddButtonDisabled}
            >
              Add
            </button>
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default ResumeWorkExperienceSocialform;
