import React, { useEffect, useContext } from "react";
import { useState } from "react";
import ServiceForm from "./ServiceForm";
import { businessObjContext } from "../../../context/businessObjContext";
import "./../Updatedcss/BusinessChangeBlockUpdatedStyle.css"
import ResumeServiceForm from "./ResumeServiceForm";
import ResumeToolsServiceForm from "./ResumeToolsServiceForm";
import ResumeToolsLanguagesForm from "./ResumeToolsLanguagesForm";

function ResumeNewBusinessLanguages({ setServicesArray,setLanguageArray, businessObj }) {
  const [formCount, setFormCount] = useState(0);
  const [isAddServiceDisable, setIsAddServiceDisable] = useState(false);
  const [isAddDisable, setIsAddDisable] = useState(false);
  const [buttonLink, setButtonLink] = useState();
  const [servicesData, setServicesData] = useState([]);
  const { businessObjData } = useContext(businessObjContext);
  const { resumeData } = useContext(businessObjContext);
  const [showFileInput, setShowFileInput] = useState(false);

  const createForm = () => {
    const newFormCount = formCount + 1;
    setFormCount(newFormCount);

    const newForm = {
      formCount: newFormCount,
      banner: null,
      name: "",
      description: "",
      buttonLink: "",
      buttonName: "",
      bannerImage: null,
      isImageUpload:true
    };
    const updatedData = [...servicesData, newForm];
    setServicesData([...servicesData, newForm]);

    // setServicesData([
    //   ...servicesData,
    //   {
    //     formCount: formCount,
    //     banner: null,
    //     name: "",
    //     description: "",
    //     buttonLink: "",
    //     buttonName: "",
    //     bannerImage: null,
    //   },
    // ]);
    setIsAddServiceDisable(true);
    setIsAddDisable(true);
    // const newForm = (
    // );
    // setFormList([...formList, newForm]);
    // setFormCount(formCount + 1);
  };

  const removeForm = (index) => {
    const newServicesData = [...servicesData];
    newServicesData.splice(index, 1);
    setServicesData(newServicesData);
    setLanguageArray(newServicesData);
    setIsAddServiceDisable(false);
    // const updatedFormList = formList.filter((form) => form.key !== formKey);
    // setFormList(updatedFormList);
  };
  useEffect(() => {
    if (resumeData?.languagecontent) {
      const serviceDataFromLocal = JSON.parse(
        resumeData?.languagecontent
      );
      const updatedServiceData = serviceDataFromLocal.map((service, index) => ({
        ...service,
        formCount: index,
        isImageUpload:false
      }));
      // console.log(updatedServiceData,"updatedServiceData@$ ")
setFormCount(updatedServiceData.length)
      // setServicesData([...servicesData, ...serviceDataFromLocal]);//// edit here
      setServicesData([...updatedServiceData]);
    } else {
      setServicesData([]);
    }
  }, [resumeData]);

  useEffect(() => {
    setLanguageArray(servicesData);
  }, [servicesData]);

  return (
    <>
      <div className="row" id="AddNewStyleForm">
        {servicesData.map((form, index) => (
          <>
            <ResumeToolsLanguagesForm
              formCount={formCount}
              servicesData={servicesData}
              setIsAddServiceDisable={setIsAddServiceDisable}
              setServicesData={setServicesData}
              removeForm={removeForm}
              index={index}
           
            ></ResumeToolsLanguagesForm>
            
            
          </>
        ))}
      </div>
      <button
        disabled={isAddServiceDisable}
        type="button"
        onClick={createForm}
        className="btn btn-cu addbuttonedit"
      >
        + Add new Languages
      </button>
    </>
  );
}

export default ResumeNewBusinessLanguages;
