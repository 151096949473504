import styled from "styled-components";

export const Root = styled.div`
display:flex;
justify-content: center;
background-color: #C5C5C5;
// padding: 20px;
`;
export const Container = styled.div`

width: 1260px;
`;
export const ContainerJs = styled.div`
width: 420px;
`;
export const Wrapper = styled.div`
width: 100%;
display: flex;
padding: 0 20px;

@media(max-width: 768px){
    display: flex;
    flex-direction: column;
}
@media(max-width: 525px){
    padding: 0px;
}
`;
export const WrapperJs = styled.div`
width: 100%;
padding: 0px;
display: flex;
flex-direction: column;

`;
export const LeftSideBar = styled.div`
width: 35%;
background-color:#000;
padding: 40px 0;
@media(max-width: 768px){
    width: 100%;
    background-color:#fff;
    padding: 0;
}
`;
export const LeftSideBarJs = styled(LeftSideBar)`
width: 100%;
background-color:#fff;
padding: 0px;
`;
export const RightSideBar = styled.div`
width: 65%;
background-color:#fff;
padding: 40px 0;
@media(max-width: 768px){
    width: 100%;
}
`;

export const RightSideBarJs = styled(RightSideBar)`
width: 100%;
`;



export const NamedesignationWrapper = styled.div`
display:flex;
justify-content: center;
align-items: center;
text-align: center;
flex-direction:column;
padding: 0 20px;
@media(max-width: 768px){
    background-color: #000;
    padding: 35px 10px;
}
`;
export const NamedesignationWrapperJs = styled.div`
display:flex;
justify-content: center;
align-items: center;
text-align: center;
flex-direction:column;
background-color: #000;
padding: 35px 0 35px 0px;

`;
export const NameWrapper = styled.div``;
export const Name = styled.div`
font-family: "Roboto", sans-serif;
font-weight: 400;
color:#fff;
font-size: 38px;
word-break:break-all;

@media(max-width: 625px){
    font-size: 32px;
}

`;
export const DesignationWrapper = styled.div``;
export const Designation = styled.div`
font-family: "Roboto", sans-serif;
font-weight: 400;
color:#fff;
font-size: 22px;
text-transform: uppercase;
word-break:break-all;

@media(max-width: 625px){
    font-size: 20px;
}
`;
export const ContactContainerWrapperFirst = styled.div``;
export const ContactContainerWrapper = styled.div`
padding: 30px 50px;
@media(max-width: 1260px){
    margin: 30px 40px;
    padding:0;
}
@media(max-width: 960px){
padding: 20px;
    margin: 30px 20px;
}

`;

export const ContactContainerWrapperJs = styled(ContactContainerWrapper)`
padding: 30px 30px;
// background-color:#fff;
`;
export const ContactHeadingWrapper = styled.div`
padding:10px 0;
`;
export const ContactHeading = styled.div`
font-family: "Roboto", sans-serif;
font-weight: 500;
color:#fff;
font-size: 20px;
text-transform: uppercase;
@media(max-width: 768px){
    color:#000;
}
`;
export const ContactHeadingJs = styled.div`
font-family: "Roboto", sans-serif;
font-weight: 500;
color:#000;
font-size: 20px;
text-transform: uppercase;

`;
export const PhoneMailmapLinWrapper = styled.div`
display:flex;
padding: 10px 0;
`;

export const PhoneIconValue = styled.div`
font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 16px;
  word-break:break-all;
  color:#fff;
  padding-left: 15px;
  @media(max-width: 768px){
    color:#000;
}
`;

export const PhoneIconValueJs = styled.div`
font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 16px;
  word-break:break-all;
  color:#000;
  padding-left: 15px;
  
`;

export const LineWrapper = styled.div`
display:flex;
justify-content: center;
`;
export const Line = styled.div`
width: 80%;
height:1px;
background-color:#323232;
@media(max-width: 768px){
    background-color:#E9E9E9;   
}
`;
export const LineJs = styled.div`
width: 80%;
height:1px;
background-color:#E9E9E9;
`;


export const KeySkillsUl = styled.ul`

`;
export const KeySkillsli = styled.li`
font-family: "Roboto", sans-serif;
font-weight: 400;
color:#fff;
font-size: 16px;
text-transform: capitalize;
padding: 10px 0;
list-style-type: disc;
@media(max-width: 768px){
    color:#000;
}
`; 
export const KeySkillsliJs = styled.li`
font-family: "Roboto", sans-serif;
font-weight: 400;
color:#000;
font-size: 16px;
text-transform: capitalize;
padding: 10px 0;
list-style-type: disc;

`; 


export const CertificationWrapper = styled.div``;
export const CertificationName = styled.div`
font-family: "Roboto", sans-serif;
font-weight: 500;
color:#fff;
font-size: 20px;
@media(max-width: 768px){
    color:#000;
}
`;
export const CertificationNameJs = styled.div`
font-family: "Roboto", sans-serif;
font-weight: 500;
color:#2F2F2F;
font-size: 20px;
text-transform: capitalize;
`;
export const CertificationFrom = styled.div`
font-family: "Roboto", sans-serif;
font-weight: 400;
color:#fff;
font-size: 16px;
padding: 5px 0;
@media(max-width: 768px){
    color:#000;
}
`;
export const CertificationFromJs = styled.div`
font-family: "Roboto", sans-serif;
font-weight: 400;
color:#000;
font-size: 20px;
padding: 5px 0;

`;
export const CertificationDate = styled.div`
font-family: "Roboto", sans-serif;
font-weight: 400;
color:#fff;
font-size: 16px;
@media(max-width: 768px){
    color:#000;
}
`;
export const CertificationDateJs = styled.div`
font-family: "Roboto", sans-serif;
font-weight: 400;
color:#000;
font-size: 20px;
font-style: italic;
`;



export const ProfilecontainerWrapperWithLine = styled.div``;
export const ProfilecontainerWrapper = styled.div`
display:flex;
`;
export const ProfileLeftColorWrapper = styled.div`
display: flex; 
align-items: center;
@media(max-width: 768px){
    align-items:flex-start;
}
`;
export const ProfileLeftColor = styled.div`
height: 90%;
width:20px;
background-color: #FFBF00;
border-radius: 0 10px 10px 0px;

${(p)=>p.WorkExperience && `
background-color:#279AFF;
`}

${(p)=>p.Project && `
background-color:#6CC906;
`}
${(p)=>p.Education && `
background-color:#843AE1;
`}
${(p)=>p.Achivements && `
background-color:#CE7C00;
`}
${(p)=>p.PersonalInformation && `
background-color:#D23FC4;
`}
${(p)=>p.Skills && `
background-color:#BD1130;
margin-top: 30px;
`}
${(p)=>p.Tools && `
background-color:#3FD29D;
margin-top: 30px;
`}

${(p)=>p.Certification && `
background-color:#3F7AD2;
margin-top: 30px;
`}
${(p)=>p.Language && `
background-color:#0F705F;
margin-top: 30px;
`}

${(p)=>p.Hobbies && `
background-color:#6CC906;
margin-top: 30px;
`}





@media(max-width: 768px){
    height: 55px;
}
`;

export const ProfileLeftColorWrapperJs = styled.div`
display: flex; 
align-items: start;
@media(max-width: 768px){
    align-items:flex-start;
}
`;

export const ProfileLeftColorWRKJS = styled(ProfileLeftColor)`
height: 65px;
`;
export const ProfileRightTextWrapper = styled.div`
flex: 1;
padding: 10px 30px;

@media(min-width: 768px) and (max-width: 960px){
    padding: 10px 20px;
}

@media(max-width: 525px){
    padding: 10px 15px;
}
`;
export const ProfileHeadingWrapper = styled.div``;
export const ProfileHeading = styled.div`
font-family: "Roboto", sans-serif;
font-weight: 500;
color:#000;
font-size: 20px;
text-transform: uppercase;
padding-bottom: 10px;
`;
export const ProfileSummary = styled.div`
font-family: "Roboto", sans-serif;
font-weight: 400;
color:#2F2F2F;
font-size: 16px;
`;



export const RightLineWrapper = styled.div`
display:flex;
justify-content: center;
margin: 20px 0;
`;
export const RightLine = styled.div`
width: 90%;
height:1px;
background-color:#DCDCDC;
`;

export const RightLineWrapperEXt = styled.div`
display:flex;
justify-content: center;
margin: 20px 0;
`;
export const RightLineEXt = styled.div`
width: 80%;
height:1px;
background-color:#323232;
`;

export const WorkExperienceDateCompContainer = styled.div`
display: flex;
    justify-content: space-between;
    align-items: flex-start;

    @media(max-width: 525px){
        align-items: baseline;
    }
`;

export const WorkExperienceDateCompContainerJs = styled.div`
display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;

    @media(max-width: 525px){
        align-items: baseline;
    }
`;
export const DesignationCompanyNameLocWrapper = styled.div``;
export const CompanyDesignationName = styled.div`
font-family: "Roboto", sans-serif;
font-weight: 500;
font-size: 20px;
@media(max-width: 625px){
    font-weight: 500;
    font-size: 16px;
}
`;

export const CompanyDesignationNameJs = styled(CompanyDesignationName)`
font-size: 18px;
`;
export const DesignationCompanyName = styled.div`
font-family: "Roboto", sans-serif;
font-weight: 400;
color:#616161;
font-size: 16px;
`;

export const DesignationCompanyNameJs = styled.div`
font-family: "Roboto", sans-serif;
font-weight: 500;
font-size: 18px;
padding: 2px 0;
`;
export const DesignationCompanyDateWrapper = styled.div``;
export const DesignationCompanyDate = styled.div`
font-family: "Roboto", sans-serif;
font-weight: 400;
color:#000;
font-style: italic;
font-size: 16px;
@media(max-width: 525px){
    font-size: 14px;
    color: #616161;
}
`;

export const WorkExperienceDateCompContainerWithSummary = styled.div`
padding: 10px 0;

`;
export const WorkExpSummaryWrapper = styled.div`
padding: 20px 0;
`;
export const WrkSummary = styled.div`
font-family: "Roboto", sans-serif;
font-weight: 400;
color:#616161;
font-size: 16px;
`;

export const WrkSummaryJs = styled.div`
font-family: "Roboto", sans-serif;
font-weight: 400;
color:#616161;
font-size: 16px;
padding-left: 20px;
`;

export const ProjectLinkWrapper = styled.div``;
export const ProjectLink = styled.a`
color: #407BFF;
font-family: "Roboto", sans-serif;
font-weight: 400;
font-style: italic;
font-size: 16px;
`;

export const AchivementsWrapperUl = styled.ul``;
export const ActivementsList = styled.li`
font-family: "Roboto", sans-serif;
font-weight: 600;
font-size: 16px;
color: #2F2F2F;
text-transform: capitalize;
padding: 10px 0;
list-style-type: disc;

@media(max-width: 768px){
font-weight: 400;
font-size: 16px;
color: #000;
}
`;

export const ActivementsListJs = styled.li`
font-family: "Roboto", sans-serif;
font-weight: 400;
font-size: 16px;
color: #000;
text-transform: capitalize;
padding: 10px 0;
list-style-type: disc;
`;


export const PersonalInformationWrapper = styled.div`
display: grid;
grid-template-columns: 1fr 1fr 1fr;
padding: 10px 0;
@media(max-width: 1024px){
    grid-template-columns: 1fr 1fr;
}
`;
export const PersonalInformationWrapperJs = styled.div`
display: grid;
grid-template-columns: 1fr 1fr;
padding: 10px 0;

`;
export const PersonalInformationKey = styled.div`
font-family: "Roboto", sans-serif;
font-weight: 500;
font-size: 16px;
color: #2F2F2F;
`;
export const PersonalInformationValue = styled.div`

font-family: "Roboto", sans-serif;
font-weight: 400;
font-size: 16px;
color: #2F2F2F;
`;

export const PersonalInformationWrapperContainer = styled.div`
margin: 10px 0;
`;
