import NewBusinessDetilsCreate from "./NewBusinessDetilsCreate";
import NewTestimonialsCreate from "./NewTestimonialsCreate";
import NewAppointmentsCreate from "./NewAppointmentsCreate";
import NewSocialmediaCreate from "./NewSocialmediaCreate";
import NewGallerycreate from "./NewGallerycreate";
import NewBusinessHour from "./NewBusinessHour";
import PhoneInput from "react-phone-input-2";
import ResumeLogoIcon from "./../../../Images/avatar.png";
import "react-phone-input-2/lib/bootstrap.css";
// import bannerimage from "../../../Images/placeholder-imagevmycard.jpg";
import bannerimage from "../../../Images/blackpic.jpg";
import avatar from "../../../Images/blackpic2.jpg";
import { useLocation, useParams } from "react-router";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { environmentVariables } from "../../../config/env.config";
import React, { useContext, useEffect, useRef, useState } from "react";
import { userContext } from "../../../context/userContext";
import ViewThem from "./ViewThem";
import { businessContext } from "../../../context/businessContext";
import { businessObjContext } from "../../../context/businessObjContext";
import CircularLoader from "../../Components/CircularLoader/CircularLoader";
import "./../Updatedcss/BusinessChangeBlockUpdatedStyle.css";
import { useFormik } from "formik";
import * as Yup from "yup";

import { Box, Modal, Slider, Button , useMediaQuery} from "@mui/material";
import AvatarEditor from "react-avatar-editor";
import { FcAddImage } from "react-icons/fc";
import { AiFillGithub } from "react-icons/ai";
import styled from "styled-components";
import { useSelector } from "react-redux";
import ResumeTemplateSlider from "./ResumeTemplateSlider";
import ResumeViewThem from "./ResumeViewThem";
import ResumeNewSocialmediaCreate from "./ResumeNewSocialmediaCreate";
import ResumeNewBusinessDetilsCreate from "./ResumeNewBusinessDetilsCreate";
import ResumeNewTestimonialsCreate from "./ResumeNewTestimonialsCreate";
import ResumeEducationNewSocialmediaCreate from "./ResumeEducationNewSocialmediaCreate";
import ResumeAddDetailsNewBusinessDetilsCreate from "./ResumeAddDetailsNewBusinessDetilsCreate";
import ResumeAchievementscreate from "./ResumeAchievementscreate";
import ResumeNewBusinessTools from "./ResumeNewBusinessTools";
import ResumeNewBusinessLanguages from "./ResumeNewBusinessLanguages";
import ResumeExperience from "./ResumeExperience";
import ResumeNewCertification from "./ResumeNewCertification";
import Typography from "@mui/material/Typography";
import "./Popupstyle.css";
import ProgressBar from "../../../frontend/Components/ProgressBar";
import { isValidPhoneNumber } from "../../../utils/utility";

const ErrorStyle = styled.div`
  color: #ff1717;
  font-size: 12px;
`;

function ResumeDetails({
  appointmentSlotDuration,
  setAppointmentSlotDuration,
  // resumeData,
  businessObj,
  mobilePreviewLoading,
  themeColor,
  themeNumber,
  activeTab,
  setActiveTab,
}) {
  const { userData, setUserData } = useContext(userContext);
  const { businessObjData } = useContext(businessObjContext);
  const { resumeData } = useContext(businessObjContext);
  const { businessData, setBusinessData } = useContext(businessContext);
  const [countryName, setCountryName] = useState("");
  const fileInputRefBanner = useRef(null);
  const fileInputRefLogo = useRef(null);
  const baseUrl = environmentVariables?.apiUrl;
  const [title, setTitle] = useState("");
  const [designation, setDesignation] = useState("");
  // const [email, setEmail] = useState("");
  const [subTitle, setSubTitle] = useState("");
  const [description, setDescription] = useState("");
  const [bannerImageUrl, setBannerImageUrl] = useState("");
  const [invalidPhoneErr, setInvalidPhoneErr] = useState("");
  const [logoImageUrl, setLogoImageUrl] = useState("");
  const inputFieldNames = [
    "logo",
    "fullName",
    "email",
    "staff_name",
    "phone",
    "address",
    "country",
    "designation",
    "fathername",
    "userdob",
    "maritalstatus",
    "linkedIn",
  ];
  const initialFormData = Object.fromEntries(
    inputFieldNames.map((name) => [name, ""])
  );
  const [fillData, setFillData] = useState(initialFormData);

  //contact info
  const [isEnabledContactInfo, setIsEnabledContactInfo] = useState(false);
  const [isEnabledContactForm, setIsEnabledContactForm] = useState(false);
  const [existingGalleryImages, setExistingGalleryImages] = useState([]);
  const [loadingSavePreview, setLoadingSavePreview] = useState(false);
  const [maritalstatus, setMaritalStatus] = useState("");
  const [phone, setContactInfoPhone] = useState("");
  const [email, setContactInfoEmail] = useState("");
  const [isSubmit, setIsSubmit] = useState(false);
  const [contactInfoWebUrl, setContactInfoWebUrl] = useState("");
  const [contactInfoAddress, setContactInfoAddress] = useState("");
  const [galleryView, setGalleryView] = useState([]);
  const [galleryData, setGalleryData] = useState([]);
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
  const [businessId, setBusinessId] = useState("");
  // console.log(businessId,"businessId",businessObjData?.id)
  const [logoData, setLogoData] = useState("");
  const [bannerData, setBannerData] = useState("");
  const [onState, setOnState] = useState({
    contactInfo: false,
    services: false,
    additionaldetails: false,
    project: false,
    isExperience: false,
    businessHours: false,
    gallery: false,
    appointment: false,
    contactForm: false,
    tools: false,
    workExp: false,
    certification: false,
  });

  let UpdatedsuggessitionData;

  const matches = useMediaQuery("(max-width:450px)");

  const suggessitionData = businessObj?.contactInfo?.content;
  if (suggessitionData) {
    UpdatedsuggessitionData = JSON.parse(suggessitionData);
    if (UpdatedsuggessitionData?.length) {
      UpdatedsuggessitionData = JSON.parse(UpdatedsuggessitionData);
    }
    // console.log(UpdatedsuggessitionData, "Businessdata");
  } else {
    console.log("No suggestion data available");
  }

  //services array
  const [skillArray, setSkillArray] = useState([]);
  const [isEnabledServices, setIsEnabledServices] = useState(false);
  const [additinaldetailArray, setAdditinaldetailArray] = useState([]);
  const [isEnabledAdddetails, setIsEnabledAdddetails] = useState(false);
  const [isEnabledAchievement, setIsEnabledAchievement] = useState(false);
  const [isEnabledPrsnldetails, setIsEnabledPrsnldetailst] = useState(false);
  const [achievementArray, setAchievementArray] = useState([]);

  const [toolsArray, setToolsArray] = useState([]);
  const [isEnabledTools, setIsEnabledTools] = useState(false);

  const [workExpArray, setWorkExpArray] = useState([]);
  const [isEnabledWorkExp, setIsEnabledWorkExp] = useState(false);

  const [languageArray, setLanguageArray] = useState([]);
  const [isEnabledLanguage, setIsEnabledLanguage] = useState(false);

  const [projectArray, setProjectArray] = useState([]);
  const [isProject, setIsProject] = useState(false);

  //Certification array
  const [certificationArray, setCertificationArray] = useState([]);
  const [iscertification, setIsCertification] = useState(false);

  //appointment array
  const [isAppointment, setIsAppointment] = useState(false);
  const [appointmentArray, setAppointmentArray] = useState([]);
  //social
  const [isExperience, setIsExperience] = useState(false);
  const [socialMediaData, setSocialMediaData] = useState([]);

  //business hours
  const [businessHourObject, setBusinessHourObject] = useState({});
  const [IsBusinessHourObject, setIsBusinessHourObject] = useState(false);

  //galary array
  const [gallaryObject, setGallaryObject] = useState({});
  const [isGallaryEnable, SetIsGallaryEnable] = useState(false);

  const [showModal, setShowModal] = useState(false);
  const [name, setName] = useState("");
  const [position, setPosition] = useState("");
  const [experience, setExperience] = useState("");
  // const [summary, setSummary] = useState("");
  const [divStates, setDivStates] = useState({
    div1: false,
    div2: false,
    div3: false,
    div4: false,
    div5: false,
    div6: false,
    div7: false,
    div8: false,
  });

  const [generatedSummary, setGeneratedSummary] = useState("");

  const toggleDiv = (divId) => {
    setDivStates({
      ...divStates,
      [divId]: !divStates[divId],
    });
  };
  const isBannerShow = (theme) => {
    const themeswithbanner = [
      "theme3",
      "theme6",
      "theme7",
      "theme8",
      "theme9",
      "theme10",
    ];
    if (themeswithbanner.includes(theme)) {
      return true;
    } else {
      return false;
    }
  };
  const isLogoShow = (theme) => {
    const themeswithbanner = ["theme10"];
    if (!themeswithbanner.includes(theme)) {
      return true;
    } else {
      return false;
    }
  };
  console.log(resumeData.banner, "resumeData@#");
  useEffect(() => {
    if (resumeData && resumeData?.business_id) {
      setBusinessId(resumeData?.business_id);
      setTitle(resumeData?.title || "");
      // setDesignation(resumeData?.designation || "");
      // setSubTitle(resumeData?.sub_title || "");
      // setDescription(resumeData?.description || "");
      setBannerImageUrl(resumeData?.banner || "");
      // setSlug(resumeData?.slug || "");
      setLogoImageUrl(resumeData?.logo || "");
    } else {
      setBusinessId(businessObjData?.id);
      setTitle("");
      setDesignation("");
      setSubTitle("");
      setDescription("");
      setBannerImageUrl("");
      setLogoImageUrl("");
      // setSlug("");
    }

    let contactInfoData;
    if (
      resumeData &&
      resumeData?.contactInfo &&
      resumeData?.contactInfo?.business_id &&
      resumeData?.contactInfo?.content
    ) {
      contactInfoData = JSON.parse(resumeData?.contactInfo?.content);
      if (contactInfoData.length > 0) {
        contactInfoData = JSON.parse(contactInfoData);
      }
    }
    formik.setValues({
      ...formik.values,

      phone: contactInfoData?.phone || "",
      email: contactInfoData?.email || "",
      subTitle: resumeData?.sub_title || "",
      designation: resumeData?.designation || "",
      description: resumeData?.description || "",
    });
  }, [resumeData]);

  useEffect(() => {
    let stateObj = {};
    if (resumeData?.is_education_is_enabled) {
      stateObj["isExperience"] = true;
      setIsExperience(true);
    }
    if (resumeData?.is_certification_is_enabled) {
      stateObj["certification"] = true;
      setIsCertification(true);
    }
    if (resumeData?.is_wrkExp_is_enabled) {
      stateObj["workExp"] = true;
      setIsEnabledWorkExp(true);
    }
    if (resumeData?.is_skills_is_enabled) {
      stateObj["services"] = true;
      setIsEnabledServices(true);
    }
    if (resumeData?.is_tools_is_enabled) {
      stateObj["tools"] = true;
      setIsEnabledTools(true);
    }
    if (resumeData?.is_language_is_enabled) {
      stateObj["language"] = true;
      setIsEnabledLanguage(true);
    }
    if (resumeData?.is_project_is_enabled) {
      stateObj["project"] = true;
      setIsProject(true);
    }
    if (resumeData?.isEnabledAdddetails) {
      stateObj["adddetails"] = true;
      setIsEnabledAdddetails(true);
    }
    if (resumeData?.is_achievement_is_enabled) {
      stateObj["achievement"] = true;
      setIsEnabledAchievement(true);
    }
    if (resumeData?.is_prsnldetail_is_enabled) {
      stateObj["prsnldetail"] = true;
      setIsEnabledPrsnldetailst(true);
    }
    setOnState(stateObj);
  }, [resumeData]);

  const handleBannerChange = (e) => {
    const file = e.target.files[0];
    setBannerData(e.target.files[0]);
    // Use the FileReader API to display the selected image
    const reader = new FileReader();
    reader.onload = () => {
      setBannerImageUrl(reader.result); //Imediate image for showing
      formik.setFieldValue("banner", reader.result);
    };
    reader.readAsDataURL(file);
  };

  const handleLogoChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSrc(URL.createObjectURL(file)); //Zoom Image for view
      setModalOpen(true);
      const reader = new FileReader();
      reader.onload = () => {
        setLogoImageUrl(reader.result); //set image on Circle Imediate
        formik.setFieldValue("logo", reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  const validationSchema = Yup.object().shape({
    fullName: Yup.string()
      .required("Name is required")
      .min(3, "Minimum 3 character Required")
      .matches(/^[a-zA-Z\s]*$/, "Only alphabets are allowed for Name")
      .max(55, "Maximum 55 Characters Required "),
    fathername: Yup.string()
      .required("Fathername is required")
      .min(3, "Minimum 3 character Required")
      .matches(/^[a-zA-Z\s]*$/, "Only alphabets are allowed for Name")
      .max(55, "Maximum 55 Characters Required "),
    linkedIn: Yup.string().required("linkedIn field is required"),
    email: Yup.string()
      .required("Email is required")
      .matches(emailPattern, "please enter valid email"),
    phone: Yup.string()
      .required("Phone number is required")
      .min(10, "Minimum 10 characters Required")
      .max(12, "Maximum 12 characters Required"),
    address: Yup.string()
      .required("address is required")
      .min(3, "Minimum 3 characters Required")
      .max(50, "Minimum 50 characters Required")
      // .matches(/^[a-zA-Z0-9\s]*$/, "Enter a valid city Name"),
      .matches(/^[a-zA-Z0-9\s,.-]*$/, "Enter a valid city name"),

    country: Yup.string()
      .required("Country is required")
      .min(3, "Minimum 3 characters Required")
      .max(50, "Minimum 50 characters Required")
      .matches(/^[a-zA-Z\s]*$/, "Enter a valid Country Name"),
    phone: Yup.string().min(10, "Enter valid Mobile Number"),
    email: Yup.string().email("Enter valid Email"),
    subTitle: Yup.string().matches(
      /^[a-zA-Z]+([a-zA-Z' -]*[a-zA-Z])*$/,
      "Only alphabets are allowed"
    ),
    designation: Yup.string()

      .min(2, "Minimum 2 characters")
      .max(40, "Maximum 40 characters"),
    description: Yup.string()
      .min(20, "Minimum 20 characters")
      .max(500, "Maximum 500 characters"),
  });

  const ReduxObject = useSelector((state) => state.user?.userObject);
  const [candidateName, setCandidateName] = useState(
    ReduxObject?.resumeObj?.fullName
  );
  // console.log("ReduxObjectReduxObject====>>>>", ReduxObject)

  const handleChange = (e) => {
    const { name, value } = e.target;
    // console.log(name,value,"asdasdasdsadasdasd")
    if (name == "fullName") {
      setCandidateName(value);
    }
    setFillData({
      ...fillData,
      [name]: value,
    });
  };

  const handleSubmitMongo = async (e) => {
    const formData = new FormData();
    formData.append("fullName", candidateName);
    formData.append("business_id", ReduxObject?.business_id);
    formData.append("user_id", ReduxObject?.user_id);
    formData.append("logo", logoData);
    axios
      .post(`${baseUrl}api/v1/resume/resumeDetail`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          _token: userData?.token,
        },
      })
      .then((res) => {
        if (res?.data?.success) {
          toast.success(res?.data?.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
          setActiveTab("tab4");
          setIsSubmit(false);
        }
      })
      .catch((err) => {
        console.log(err, "error occurred");
        toast.error(
          err?.response?.data?.message?.message || err?.response?.data?.message,
          {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          }
        );
        setIsSubmit(false);
      });
  };
  const slug = businessObjData?.slug;

  const handleviewClick = (slug) => {
    if (window != undefined) {
      window.open(`/${slug}`, "_blank");
    }
  };

  const handleUpdateBusiness = (e) => {
    setLoadingSavePreview(true);

    let reskey = resumeData.resume_color || "resume1";

    if (reskey) {
      e.stopPropagation();
      let obj = {
        // theme: resumeData.themeColor,
        theme_color: resumeData.resume_color,
        businessId: resumeData?.business_id,
      };
      const formData = new FormData();
      // formData.append('resume_color', props.themeColor);
      formData.append("created_by", userData?.id);
      formData.append("business_id", businessId);
      // formData.append('business_id', 536);
      formData.append("banner", bannerImageUrl);
      formData.append("logo", logoImageUrl);
      formData.append("city", formik?.values?.address);
      formData.append("designation", formik?.values?.designation);
      formData.append("summary", formik?.values?.description);
      formData.append("email", formik?.values?.email);
      formData.append("fullName", formik.values.fullName);
      formData.append("fathername", formik?.values?.fathername);
      formData.append("linkedIn", formik?.values?.linkedIn);
      formData.append("userdob", formik?.values?.userdob);
      formData.append("maritalstatus", formik?.values?.maritalstatus);
      formData.append("phone", formik?.values?.phone);
      // formData.append('country', props.formikData?.values?.country);
      formData.append("resume_color", ReduxObject?.SelectResumeId);

      formData.append("is_education_is_enabled", isExperience ? 1 : 0);
      formData.append("educationcontent", JSON.stringify(socialMediaData));
      formData.append("is_project_is_enabled", isProject ? 1 : 0);
      formData.append("projectcontent", JSON.stringify(projectArray));
      formData.append("is_skills_is_enabled", isEnabledServices ? 1 : 0);
      formData.append("skillscontent", JSON.stringify(skillArray));
      formData.append("isEnabledAdddetails", isEnabledAdddetails ? 1 : 0);
      formData.append(
        "additionaldetailscontent",
        JSON.stringify(additinaldetailArray)
      );
      formData.append(
        "is_achievement_is_enabled",
        isEnabledAchievement ? 1 : 0
      );
      formData.append(
        "is_prsnldetail_is_enabled",
        isEnabledPrsnldetails ? 1 : 0
      );
      formData.append("achievementcontent", JSON.stringify(achievementArray));
      formData.append("is_tools_is_enabled", isEnabledTools ? 1 : 0);
      formData.append("toolscontent", JSON.stringify(toolsArray));
      formData.append("is_language_is_enabled", isEnabledLanguage ? 1 : 0);
      formData.append("languagecontent", JSON.stringify(languageArray));
      formData.append("is_wrkExp_is_enabled", isEnabledWorkExp ? 1 : 0);
      formData.append("wrkExpcontent", JSON.stringify(workExpArray));
      formData.append("is_certification_is_enabled", iscertification ? 1 : 0);
      formData.append(
        "certificationcontent",
        JSON.stringify(certificationArray)
      );

      axios
        .post(`${baseUrl}api/v1/resume/resumeDetail`, formData, {
          headers: {
            _token: userData?.token,
          },
          withCredentials: true,
        })
        .then((res) => {
          if (res?.data?.success) {
            // window.open(
            //   `/resume-preview/${businessId ?? ""}`,
            //   "_blank"
            // );
            // window.open(`/${slug}`, "_blank");
            window.open(`/resume-preview/${businessId ?? ""}`, "_blank");
            setLoadingSavePreview(false);
            resumeData.setThemeColor(selectedTheme);
          }
        })
        .catch((err) => {
          setLoadingSavePreview(false);
          toast.error(err?.response?.data?.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
        });
    } else if (resumeData.activeTab == "tab2") {
      props.handleSubmit(e);

      setLoadingSavePreview(false);
    } else if (resumeData.activeTab == "tab3") {
      props.handleSubmit(e);
      setLoadingSavePreview(false);
    } else {
      window.open(`/${businessId ?? ""}`, "_blank");
    }
  };

  // const formik = useFormik({
  //   initialValues: {
  //     fullName: "",
  //     phone: "",
  //     email: "",
  //     email: "",
  //     address: "",
  //     country: "",
  //     phone: "",
  //     subTitle: resumeData?.sub_title || "",
  //     designation: resumeData?.designation || "",
  //     description: resumeData?.description || "",
  //   },
  //   validationSchema: validationSchema,
  //   onSubmit: handleSubmit,
  // });

  let initialValues = {
    logo: "",
    banner: "",
    fullName: "",
    phone: "",
    email: "",
    address: "",
    country: "",
    phone: "",
    subTitle: resumeData?.sub_title || "",
    designation: "",
    description: "",
    fathername: "",
    userdob: "",
    maritalstatus: "",
    linkedIn: "",
    workExp: "",
    projects: "",
  };
  const formik = useFormik({
    validationSchema: validationSchema,
    onSubmit: handleUpdateBusiness,
  });

  useEffect(() => {
    initialValues = {
      logo: resumeData?.logo || "",
      banner: resumeData?.banner || "",
      fullName: resumeData?.fullName || businessObj.sub_title || "",
      email:
        resumeData?.email || UpdatedsuggessitionData?.contactInfoEmail || "",
      phone:
        resumeData?.phone || UpdatedsuggessitionData?.contactInfoPhone || "",
      address:
        resumeData?.city || UpdatedsuggessitionData?.contactInfoAddress || "",
      description: resumeData?.summary || "",
      designation: resumeData?.designation || businessObj?.designation || "",
      fathername: resumeData?.fathername || "",
      userdob: resumeData?.userdob || "",
      maritalstatus: resumeData?.maritalstatus || "",
      linkedIn: resumeData?.linkedIn || "",
      workExp: "",
      projects: "",
      tools: "",
      skills: "",
      education: "",
      certification: "",
      language: "",
      hobbies: "",
      achievements: "",
    };

    formik.setValues(initialValues);
  }, [resumeData]);

  // useEffect(() => {
  //   initialValues = {
  //     fullName: resumeData?.product_id || "",
  //   };

  //   formik.setValues(initialValues);
  // }, [IndividualSeoData]);

  const handleSubmitWithoutTabChange = async (e) => {
    // try {
    setIsLoadingSubmit(true);
    // e.preventDefault();
    if (email != "") {
      if (!emailPattern.test(email)) {
        toast.error("Kindly provide valid eEmail", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        setIsLoadingSubmit(false);
        return;
      }
    }

    const formData = new FormData();
    formData.append("banner", bannerData);
    formData.append("logo", logoData);
    formData.append("businessId", businessId);
    formData.append("title", title);
    formData.append("designation", formik.values.designation);
    formData.append("sub_title", formik.values.subTitle);
    formData.append("description", formik.values.description);
    formData.append("slug", slug);
    let formCount = 0;
    formData.append(
      "is_enabledContactInfo",
      isEnabledContactInfo == true ? 1 : 0
    );
    let content = {
      phone: formik.values.phone,
      email: formik.values.email,
      contactInfoWebUrl,
      contactInfoAddress,
    };
    if (isEnabledContactInfo == true) {
      formData.append("content", JSON.stringify(content));
    }

    formData.append(
      "is_contact_form_enabled",
      isEnabledContactForm == true ? 1 : 0
    );
    formData.append("is_services", isEnabledServices ? 1 : 0);
    if (servicesArray && servicesArray.length) {
      servicesArray.forEach((file, index) => {
        if (file?.banner && file?.banner["0"].name?.length) {
          formData.append(`servicesArray`, file?.banner["0"]);
        } else if (file && file.image) {
          formData.append(`servicesImages[${index}]`, file?.image);
        }
        formData.append(`serviceDescription[${index}]`, file?.description);
        formData.append(`serviceName[${index}]`, file?.name);
        formData.append(`serviceButtonLink[${index}]`, file?.buttonLink);
        formData.append(`serviceButtonName[${index}]`, file?.buttonName);
      });
    }

    formData.append("is_testimonial", isTestimonial ? 1 : 0);
    if (testimonialArray && testimonialArray.length) {
      testimonialArray.forEach((file, index) => {
        if (
          file &&
          file.image &&
          file.image["0"] &&
          file.image["0"].name?.length
        ) {
          formData.append(`testimonialArray`, file?.image["0"]);
        } else {
          formData.append(`testimonialImageArray[${index}]`, file?.image);
        }
        formData.append(`testimonialDescription[${index}]`, file?.description);
        formData.append(`testimonialName[${index}]`, file?.name);
        formData.append(`testimonialFormCount[${index}]`, index);
      });
    }

    formData.append("is_business_hours_enabled", IsBusinessHourObject ? 1 : 0);
    formData.append("businesshoursOBject", JSON.stringify(businessHourObject));

    //appointment array
    formData.append("appointmentContent", JSON.stringify(appointmentArray));
    formData.append("is_appointment", isAppointment ? 1 : 0);

    //social media
    formData.append("is_social", isExperience ? 1 : 0);
    formData.append("isSocalData", JSON.stringify(socialMediaData));

    // gallary
    formData.append("isGallaryEnable", isGallaryEnable ? 1 : 0);
    // formData.append(`gallary`, gallaryObject?.gallary);
    galleryData &&
      galleryData.length &&
      galleryData.map((val, index) => {
        formData.append("gallary", val);
      });
    existingGalleryImages.forEach((val) => {
      formData.append("gallaryArray", val);
    });
    formData.append("gallary_video", gallaryObject?.gallary_video);
    formData.append("gallaryImage", gallaryObject?.gallaryImage);
    formData.append("gallaryVideoLink", gallaryObject?.videoLink);

    axios
      .post(`${baseUrl}api/v1/business/updateDetails`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          _token: userData?.token,
        },
      })
      .then((res) => {
        if (res?.data?.success) {
          toast.success(res?.data?.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
          window.open(`/${resumeData?.slug ? resumeData?.slug : ""}`, "_blank");
          setIsLoadingSubmit(false);
        }
      })
      .catch((err) => {
        console.log(err, "error occurred");
        toast.error(err?.response?.data?.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        setIsLoadingSubmit(false);
      });
    // } catch (err) {
    //   console.log(err);
    //   toast.error(err, {
    //     position: toast.POSITION.TOP_RIGHT,
    //   });
    //   return;
    // }
  };

  const handleImageClickBanner = () => {
    fileInputRefBanner.current.click();
  };

  const handleImageClickLogo = () => {
    fileInputRefLogo.current.click();
  };

  // const [activeTab, setActiveTab] = useState("tab1");

  const handlePreviousClick = () => {
    if (activeTab === "tab4") {
      setActiveTab("tab3");
    }
  };

  const handleChangeCompanyName = (e) => {
    const alphanumericWithSpaceRegex = /^[a-zA-Z0-9\s]+$/;
    if (
      e.target.value === "" ||
      alphanumericWithSpaceRegex.test(e.target.value)
    ) {
      setTitle(e.target.value);
    }
  };

  const [src, setSrc] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [slideValue, setSlideValue] = useState(10);
  const cropRef = useRef(null);
  const inputRef = useRef(null);

  const handleSave = async () => {
    const dataUrl = cropRef.current.getImage().toDataURL();
    setLogoImageUrl(dataUrl);
    setModalOpen(false);

    // Convert data URL to Blob
    const byteString = atob(dataUrl.split(",")[1]);
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([ab], { type: "image/png" }); // Adjust the MIME type accordingly
    // Create a File object from the Blob
    const file = new File([blob], "filename.png", { type: "image/png" }); // Adjust the file name and type accordingly
    setLogoData(file);
  };
  const boxStyle = {};
  const modalStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  const handlegen = async () => {
    setIsSubmit(true);
    try {
      const response = await fetch(`${baseUrl}api/v1/resume/generatesummary`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name,
          designation: position,
          experience,
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to generate summary");
      }

      const data = await response.json();
      // console.log(data, "!@#Es data")
      const contentWithoutQuotes = data.content.slice(1, -1);
      setGeneratedSummary(contentWithoutQuotes);

      // formik.setFieldValue("description", contentWithoutQuotes);
      setIsSubmit(false);
      // handleClose();
    } catch (error) {
      setIsSubmit(false);
      console.error("Error generating summary:", error);
    }
  };

  const handleConfirmSummary = () => {
    // setDescription(generatedSummary);
    formik.setFieldValue("description", generatedSummary);
    handleClose();
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-54%, -50%)",
    // width: 400,
    width: matches ? 450 : 400,
    // p: 4,
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setName("");
    setPosition("");
    setExperience("");
    setGeneratedSummary("");
  };
  // console.log(name,"namenamenamenamename")

  /* resume score functionality */

  useEffect(() => {
    calculateProgress();
  }, [formik.values]);

  const fieldWeights = {
    logo: 15,
    banner: 15,
    fullName: 5,
    phone: 5,
    email: 5,
    address: 3,
    designation: 2,
    description: 10,
    linkedIn: 5,
    workExp: 10,
    projects: 10,
    skills: 5,
    tools: 2,
    education: 10,
    certification: 2,
    language: 3,
    hobbies: 3,
    achievements: 2,
    userdob: 1,
    maritalstatus: 1,
    fathername: 1,
  };
  const calculateProgress = () => {
    let totalWeight = 0;
    let filledWeight = 0;
    for (const field in formik.values) {
      if (!!fieldWeights[field]) {
        if (ReduxObject?.SelectResumeId == "Resume_3" && field == "logo") {
          totalWeight += 15;
          filledWeight += 15;
        } else if (
          ReduxObject?.SelectResumeId != "Resume_6" &&
          field == "banner"
        ) {
          totalWeight += 15;
          filledWeight += 15;
        } else {
          totalWeight += fieldWeights[field];
          if (formik.values[field]) {
            filledWeight += fieldWeights[field];
          }
        }
      }
    }
    if (filledWeight > 0 && totalWeight > 0) {
      return Math.round(((filledWeight / totalWeight) * 100).toFixed(2));
    } else {
      return 0;
    }
  };

  /* resume score functionality */

  useEffect(() => {
    const updateFormikData = (fieldName, value) => {
      if (value.length > 0) {
        formik.setFieldValue(fieldName, value);
      } else {
        formik.setFieldValue(fieldName, "");
      }
    };
    if (formik.values) {
      updateFormikData("workExp", workExpArray);
      updateFormikData("projects", projectArray);
      updateFormikData("skills", skillArray);
      updateFormikData("tools", toolsArray);
      updateFormikData("education", socialMediaData);
      updateFormikData("certification", certificationArray);
      updateFormikData("language", languageArray);
      updateFormikData("hobbies", additinaldetailArray);
      updateFormikData("achievements", achievementArray);
    }
  }, [
    skillArray,
    projectArray,
    workExpArray,
    toolsArray,
    socialMediaData,
    certificationArray,
    languageArray,
    additinaldetailArray,
    achievementArray,
  ]);

  const handleBlur = (value, phoneData) => {
    phoneData.iso2 = phoneData.countryCode;
    let contactNumber = formik.values.phone;
    if(!!contactNumber){
      let val = contactNumber.split("-");
      let valArray = val.length > 0 ? val[1] : "";
      if (!isValidPhoneNumber(valArray, phoneData)) {
        setInvalidPhoneErr(`Invalid phone number for ${phoneData.name}`);
      } else {
        setInvalidPhoneErr("");
      }
    }
  };

  return (
    <>
      {/* <ResumeTemplateSlider /> */}
      <div
        className="ChangeBlock_Sequenceview_Wrapper"
        // style={{ marginTop: "50px" }}
      >
        <div className="row ChangeBlock_Sequenceview_Container">
          <div className="col-12 col-lg-8 ChangeBlock_Container ChangeblockContainerpd">
            <ProgressBar percentage={calculateProgress()} />
            <ResumeTemplateSlider />
            <div className="tile">
              <form className="row">
                {/* {console.log(ReduxObject.SelectResumeId === "Resume_6","ReduxObjectReduxObjectReduxObject")} */}
                <div className="d-flex justify-content-between mb-4 col-md-12 AddDetailsBanner_logoWrapper">
                  {ReduxObject?.SelectResumeId === "Resume_6" ||
                  isBannerShow(resumeData?.theme_color) ? (
                    <div class="mb-4 col-md-8">
                      <div className="boxbanner DetailsSelectBanner_wrapper">
                        <label className="form-label">Select Banner:</label>
                        <div className="bannerbox">
                          <img
                            src={
                              bannerImageUrl
                                ? bannerImageUrl?.includes("base64")
                                  ? bannerImageUrl
                                  : bannerImageUrl == "null"
                                  ? ""
                                  : `${baseUrl}uploads/resume/${bannerImageUrl}`
                                : bannerimage
                            }
                            className="mx-auto d-block img-fluid"
                            onClick={(e) => handleImageClickBanner(e)}
                          ></img>
                        </div>
                        <input
                          className="form-control d-none"
                          type="file"
                          name="banner"
                          onChange={handleBannerChange}
                          ref={fileInputRefBanner}
                        />
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                  {/* { console.log(ReduxObject?.SelectResumeId,"resumeDataresumeData")} */}
                  {ReduxObject?.SelectResumeId !== "Resume_3" && (
                    <>
                      {isLogoShow(resumeData?.theme_color) ? (
                        <div
                          className="mb-3 col-md-12"
                          style={{
                            display: "flex",
                            justifyContent: "flex-start",
                          }}
                        >
                          <div className="boxbanner detailsdptextlogowrapper">
                            <label className="form-label detailsdptext">
                              Select Profile Logo (Size : 150 x 150)
                            </label>
                            <div className="bannerbox2 AdddetailsdpContainer">
                              <img
                                src={
                                  logoImageUrl
                                    ? logoImageUrl?.includes("base64")
                                      ? logoImageUrl
                                      : logoImageUrl == "null"
                                      ? ""
                                      : `${baseUrl}uploads/resume/${logoImageUrl}`
                                    : avatar
                                }
                                className="mx-auto d-block"
                                onClick={handleImageClickLogo}
                              />
                            </div>

                            <input
                              className="form-control d-none"
                              type="file"
                              name="logo"
                              onChange={handleLogoChange}
                              ref={fileInputRefLogo}
                              accept=".png, .jpg, .jpeg"
                            />
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                    </>
                  )}
                </div>

                <div className="AddDetailsNameDestiCom_Wrapper">
                  <div className="mb-3 col-md-6 AddDetailswidContainer">
                    <label className="form-label">Full Name </label>
                    <input
                      name="fullName"
                      className="form-control"
                      type="text"
                      placeholder="Name"
                      // value={candidateName}
                      // onChange={handleChange}
                      value={formik?.values?.fullName}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {/* {formik.touched.subTitle && formik.errors.subTitle && (
                      <ErrorStyle className="error">{formik.errors.subTitle}</ErrorStyle>
                    )} */}
                    {formik.touched.fullName && formik.errors.fullName && (
                      <ErrorStyle className="error">
                        {formik.errors.fullName}
                      </ErrorStyle>
                    )}
                  </div>

                  <div className="mb-3 col-md-6 AddDetailswidContainer AddDetailsMarContainer">
                    <label className="form-label"> Current Designation </label>
                    <input
                      name="designation"
                      className="form-control"
                      type="text"
                      placeholder="Designation"
                      onChange={formik.handleChange}
                      value={formik.values?.designation}
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.designation &&
                      formik.errors.designation && (
                        <ErrorStyle className="error">
                          {formik.errors.designation}
                        </ErrorStyle>
                      )}
                  </div>
                </div>
                <div className="AddDetailsNameDestiCom_Wrapper">
                  <div className="mb-3 col-md-6 AddDetailswidContainer AddDetailsMarContainer">
                    <label className="form-label">Email</label>
                    <input
                      className="form-control"
                      type="email"
                      name="email"
                      placeholder="Email"
                      value={formik.values?.email}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />

                    {formik.touched.email && formik.errors.email && (
                      <ErrorStyle className="error">
                        {formik.errors.email}
                      </ErrorStyle>
                    )}
                  </div>

                  <div className="mb-3 col-md-6 AddDetailswidContainer">
                    <label className="form-label">Phone </label>
                    {/* <input
                      // value={title}
                      className="form-control"
                      name="phone"
                      type="number"
                      placeholder="Phone"
                      // onChange={(e) => setTitle(e.target.value)}
                      // onChange={(e) => handleChangeCompanyName(e)}
                      onChange={formik.handleChange}
                      value={formik.values.phone}
                      onBlur={formik.handleBlur}
                    /> */}
                    <PhoneInput
                      className="Resumebasicphoneinputstyle"
                      country={"in"}
                      enableSearch={true}
                      value={formik.values?.phone}
                      onChange={(value, country) => {
                        if (country.dialCode) {
                          formik.setFieldValue(
                            "phone",
                            `+${country.dialCode}-${value.substring(
                              country.dialCode.length
                            )}`
                          );
                          setInvalidPhoneErr('');
                        } else {
                          formik.setFieldValue("phone", value);
                          setInvalidPhoneErr('');
                        }
                      }}
                      onBlur={(e, country) =>
                        handleBlur(e, country) && formik.handleBlur("phone")
                      }
                      isValid={(inputNumber, country) =>
                        isValidPhoneNumber(inputNumber, country)
                      }
                      inputProps={{
                        name: "phone",
                        id: "phone",
                        className: "form-control",
                        placeholder: "Phone",
                      }}
                    />
                    {formik.touched.phone && formik.errors.phone && (
                      <ErrorStyle className="error">
                        {formik.errors.phone}
                      </ErrorStyle>
                    )}
                    {invalidPhoneErr && (
                      <ErrorStyle className="error">
                        {invalidPhoneErr}
                      </ErrorStyle>
                    )}
                  </div>
                </div>

                <div className="AddDetailsNameDestiCom_Wrapper">
                  <div className="mb-3 col-md-6 AddDetailswidContainer">
                    <label className="form-label">Address </label>
                    <input
                      name="address"
                      className="form-control"
                      type="text"
                      placeholder="Address"
                      onChange={formik.handleChange}
                      value={formik.values?.address}
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.address && formik.errors.address && (
                      <ErrorStyle className="error">
                        {formik.errors.address}
                      </ErrorStyle>
                    )}
                  </div>

                  {/* ///////////////////////  Linked In  */}

                  <div className="mb-3 col-md-6 AddDetailswidContainer AddDetailsMarContainer">
                    <label className="form-label">LinkedIn </label>
                    <input
                      name="linkedIn"
                      className="form-control"
                      type="text"
                      placeholder="LinkedIn"
                      value={formik?.values?.linkedIn}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.linkedIn && formik.errors.linkedIn && (
                      <ErrorStyle className="error">
                        {formik.errors.linkedIn}
                      </ErrorStyle>
                    )}
                  </div>
                </div>

                <div className="mb-3 col-md-12">
                  <label className="form-label">Summary </label>
                  <div
                    className="textarea-container"
                    style={{ position: "relative" }}
                  >
                    <textarea
                      value={description}
                      {...formik.getFieldProps("description")}
                      className="form-control"
                      rows="4"
                      placeholder="Summary"
                      onChange={formik.handleChange}
                      // value={formik.values.description}
                      onBlur={formik.handleBlur}
                    ></textarea>
                    <img
                      src="https://www.freepnglogos.com/uploads/logo-chatgpt-png/chatgpt-square-logo-blue-and-black-png-10.png"
                      alt="ChatGPT"
                      className="chatgpt-icon"
                      onClick={handleOpen}
                    />
                  </div>
                  {formik.touched.description && formik.errors.description && (
                    <ErrorStyle className="error">
                      {formik.errors.description}
                    </ErrorStyle>
                  )}
                </div>

                <Modal
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                

                >
                  <Box sx={style} className="PopupGeneratingmodal">
                    <div className="modal-content">
                      <Typography
                        id="modal-modal-title"
                        variant="h6"
                        component="h2"
                      >
                        AI Summary Generator 
                      </Typography>
                      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        <div className="modal-input">
                          <label>Name:</label>
                          <input
                            type="text"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            placeholder="Your name"
                          />
                        </div>
                        <div className="modal-input">
                          <label>Designation:</label>
                          <input
                            type="text"
                            value={position}
                            onChange={(e) => setPosition(e.target.value)}
                            placeholder="Your designation"
                          />
                        </div>
                        <div className="modal-input">
                          <label>Experience:</label>
                          <input
                            type="text"
                            value={experience}
                            onChange={(e) => setExperience(e.target.value)}
                            placeholder="Work experience in years"
                          />
                        </div>
                        <div>
                          {generatedSummary && (
                            <>
                              <h5>
                                Want to use the generated summary? Click on the
                                "Use" button:
                              </h5>
                              <p>{generatedSummary}</p>
                            </>
                          )}
                        </div>
                        <div className="modal-footer">
                          {generatedSummary && (
                            <button onClick={handleConfirmSummary}>use</button>
                          )}
                          <button onClick={handleClose}>Close</button>
                          <button onClick={handlegen} type="submit">
                            {isSubmit ? (
                              <CircularLoader size={25} />
                            ) : (
                              "Generate"
                            )}
                          </button>
                        </div>
                      </Typography>
                    </div>
                  </Box>
                </Modal>

                {/* {showModal && (
                  <div className="modal">
                    <div className="modal-content">
                      <span className="close" onClick={handleModalClose}>
                        &times;
                      </span>
                      <div className="modal-body">
                        <label>Name:</label>
                        <input
                          type="text"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                        />
                        <label>Position:</label>
                        <input
                          type="text"
                          value={position}
                          onChange={(e) => setPosition(e.target.value)}
                        />
                        <label>Experience:</label>
                        <input
                          type="text"
                          value={experience}
                          onChange={(e) => setExperience(e.target.value)}
                        />
                      </div>
                      <div className="modal-footer">
                        <button onClick={handleModalClose}>Close</button>
                        <button onClick={handleSubmit}>Submit</button>
                      </div>
                    </div>
                  </div>
                )} */}

                {/* <div class="mb-3 col-md-12 ">
                <div class="form-group">
                  <label class="form-label">Personalized link:</label>
                  <div class="input-group">
                    <input
                      type="text"
                      className="form-control"
                      readonly=""
                      value=" https://vmycards.com/freedom-5"
                    />
                    <input
                      className="input-group-text text-start"
                      placeholder="Enter Slug"
                      name="slug"
                      type="text"
                      onChange={(e) => setSlug(e.target.value)}
                      value={slug}
                    />
                  </div>
                </div>
              </div> */}
                <label className="form-label" style={{ fontSize: "17px" }}>
                  Add Experience
                </label>
                {/* <h5 style={{fontWeight: "400"}} className="settings">Settings:</h5> */}

                <div className="accordion" id="accordionExample">
                  {/* ///COntact Info-----------------COntact Info  */}

                  {/* --------- work experience --------- */}
                  {/* <div className="accordion-item">
                      <h2 className="accordion-header" id="headingOne">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseOne"
                          aria-expanded="false"
                          aria-controls="collapseOne"
                          onClick={(e) => {
                          
                          }}
                        >
                          <div className="d-flex">
                            <h5 style={{ fontWeight: "400" }} border-0>
                              Work Experience
                            </h5>
                          </div>
                        </button>

                        <div className="form-check form-switch">
                          <label
                            className="form-check-label"
                          
                          >
                            On/Off
                          </label>
                          <input
                            className="form-check-input"
                            type="checkbox"
                            role="switch"
                            id="flexSwitchCheckDefault"
                            checked={onState?.isExperience}
                            onChange={() => toggleDiv("div5")}
                            onClick={() => {
                              setIsExperience(!isExperience);
                              // setOnState({
                              //   ...onState,
                              //   isExperience: !onState.isExperience,
                              // });
                            }}
                          />
                        </div>
                      </h2>
                      <div
                        id="collapseOne"
                        className="accordion-collapse collapse "
                        aria-labelledby="headingOne"
                        data-bs-parent="#accordionExample"
                      >
                        <div
                          className="accordion-body"
                          id="AddnewServicesformwrapper"
                          style={{ padding: "15px 0" }}
                        >
                      
                          <div
                            className="row"
                            style={{ justifyContent: "center" }}
                          >
                            
                            <ResumeNewSocialmediaCreate
                              setSocialMediaData={setSocialMediaData}
                              socialMediaData={socialMediaData}
                            />
                          </div>
                       
                        </div>
                      </div>
                    </div> */}

                  {/* =========Work Experience=================== */}
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingNine">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#Nine"
                        aria-expanded="false"
                        aria-controls="collapseThree"
                        onClick={() => {}}
                      >
                        <div className="d-flex">
                          <h5 style={{ fontWeight: "400" }}>Work Experience</h5>
                        </div>
                      </button>
                      <div className="form-check form-switch">
                        <label className="form-check-label">On/Off</label>
                        <input
                          className="form-check-input"
                          type="checkbox"
                          role="switch"
                          id="flexSwitchCheckDefault"
                          checked={onState?.workExp}
                          onChange={() => toggleDiv("div6")}
                          onClick={() => {
                            setIsEnabledWorkExp(!isEnabledWorkExp);
                            setOnState({
                              ...onState,
                              workExp: !onState.workExp,
                            });
                          }}
                        />
                      </div>
                    </h2>
                    <div
                      id="Nine"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingNine"
                      data-bs-parent="#accordionExample"
                    >
                      <div
                        className="accordion-body"
                        id="AddnewServicesformwrapper"
                        style={{ padding: "15px 0" }}
                      >
                        <div
                          className="row"
                          style={{ justifyContent: "center" }}
                        >
                          <ResumeExperience
                            setWorkExpArray={setWorkExpArray}
                            workExpArray={workExpArray}
                            resumeData={resumeData}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* =========Projects============== */}
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingThree">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseThree"
                        aria-expanded="false"
                        aria-controls="collapseThree"
                        onClick={() => {}}
                      >
                        <div className="d-flex">
                          <h5 style={{ fontWeight: "400" }}>Projects</h5>
                        </div>
                      </button>
                      <div className="form-check form-switch">
                        <label
                          className="form-check-label"
                          // for="flexSwitchCheckDefault"
                        >
                          On/Off
                        </label>
                        <input
                          className="form-check-input"
                          type="checkbox"
                          role="switch"
                          id="flexSwitchCheckDefault"
                          checked={onState?.project}
                          onClick={() => {
                            setIsProject(!isProject);
                            setOnState({
                              ...onState,
                              project: !onState.project,
                            });
                          }}
                          onChange={() => toggleDiv("div3")}
                        />
                      </div>
                    </h2>
                    <div
                      id="collapseThree"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingThree"
                      data-bs-parent="#accordionExample"
                    >
                      <div
                        className="accordion-body"
                        id="AddnewServicesformwrapper"
                      >
                        <div className="row">
                          <ResumeNewTestimonialsCreate
                            setProjectArray={setProjectArray}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* ///Skill ---------------services  */}
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingTwo">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseTwo"
                        aria-expanded="false"
                        aria-controls="collapseTwo"
                        onClick={(e) => {}}
                      >
                        <div className="d-flex">
                          <h5 style={{ fontWeight: "400" }}>Skills</h5>
                        </div>
                      </button>
                      <div className="form-check form-switch">
                        <label className="form-check-label">On/Off</label>
                        <input
                          className="form-check-input"
                          type="checkbox"
                          role="switch"
                          id="flexSwitchCheckDefault"
                          checked={onState?.services}
                          onClick={(e) => {
                            setIsEnabledServices(!isEnabledServices);
                            setOnState({
                              ...onState,
                              services: !onState.services,
                            });
                          }}
                          onChange={() => toggleDiv("div2")}
                        />
                      </div>
                    </h2>
                    <div
                      id="collapseTwo"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingTwo"
                      data-bs-parent="#accordionExample"
                    >
                      <div
                        className="accordion-body"
                        id="AddnewServicesformwrapper"
                      >
                        <div className="row">
                          <ResumeNewBusinessDetilsCreate
                            setSkillArray={setSkillArray}
                            resumeData={resumeData}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* ///Tools ---------------Tools  */}
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingSeven">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseSeven"
                        aria-expanded="false"
                        aria-controls="collapseSeven"
                        onClick={(e) => {}}
                      >
                        <div className="d-flex">
                          <h5 style={{ fontWeight: "400" }}>Tools</h5>
                        </div>
                      </button>
                      <div className="form-check form-switch">
                        <label className="form-check-label">On/Off</label>
                        <input
                          className="form-check-input"
                          type="checkbox"
                          role="switch"
                          id="flexSwitchCheckDefault"
                          checked={onState?.tools}
                          onClick={(e) => {
                            setIsEnabledTools(!isEnabledTools);
                            setOnState({
                              ...onState,
                              tools: !onState.tools,
                            });
                          }}
                          onChange={() => toggleDiv("div1")}
                        />
                      </div>
                    </h2>
                    <div
                      id="collapseSeven"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingSeven"
                      data-bs-parent="#accordionExample"
                    >
                      <div
                        className="accordion-body"
                        id="AddnewServicesformwrapper"
                      >
                        <div className="row">
                          <ResumeNewBusinessTools
                            setToolsArray={setToolsArray}
                            resumeData={resumeData}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <label
                    className="form-label"
                    style={{ fontSize: "17px", marginTop: "10px" }}
                  >
                    Education & Certificate
                  </label>

                  {/* ///Contact form-----------------Contact form  */}
                  {/* <div className="accordion-item ContactFormPadIssue">
                      <h2 className="accordion-header">
                        <div className="d-flex border-0">
                          <h5 style={{ fontWeight: "400" }} border-0>
                            Contact Form
                          </h5>
                        </div>

                        <div className="form-check form-switch">
                          <label
                            className="form-check-label"
                          // for="flexSwitchCheckDefault"
                          >
                            On/Off
                          </label>
                          <input
                            onClick={(e) => {
                              setIsEnabledContactForm(!isEnabledContactForm);
                              setOnState({
                                ...onState,
                                contactForm: !onState.contactForm,
                              });
                            }}
                            className="form-check-input"
                            type="checkbox"
                            role="switch"
                            id="flexSwitchCheckDefault"
                            // onChange={() => toggleDiv("div1")}
                            checked={onState?.contactForm}
                          />
                        </div>
                      </h2>
                      <div
                        id="collapseOne"
                        className="accordion-collapse collapse "
                        aria-labelledby="headingOne"
                        data-bs-parent="#accordionExample"
                      ></div>
                    </div> */}

                  {/* =========Education=================== */}
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingFive">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#five"
                        aria-expanded="false"
                        aria-controls="collapseThree"
                        onClick={() => {}}
                      >
                        <div className="d-flex">
                          <h5 style={{ fontWeight: "400" }}>Education</h5>
                        </div>
                      </button>
                      <div className="form-check form-switch">
                        <label className="form-check-label">On/Off</label>
                        <input
                          className="form-check-input"
                          type="checkbox"
                          role="switch"
                          id="flexSwitchCheckDefault"
                          checked={onState?.isExperience}
                          onChange={() => toggleDiv("div5")}
                          onClick={() => {
                            setIsExperience(!isExperience);
                            setOnState({
                              ...onState,
                              isExperience: !onState.isExperience,
                            });
                          }}
                        />
                      </div>
                    </h2>
                    <div
                      id="five"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingFive"
                      data-bs-parent="#accordionExample"
                    >
                      <div
                        className="accordion-body"
                        id="AddnewServicesformwrapper"
                        style={{ padding: "15px 0" }}
                      >
                        <div
                          className="row"
                          style={{ justifyContent: "center" }}
                        >
                          <ResumeEducationNewSocialmediaCreate
                            setSocialMediaData={setSocialMediaData}
                            socialMediaData={socialMediaData}
                            resumeData={resumeData}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* =========Certification============== */}
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingTen">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseTen"
                        aria-expanded="false"
                        aria-controls="collapseTen"
                        onClick={() => {}}
                      >
                        <div className="d-flex">
                          <h5 style={{ fontWeight: "400" }}>Certification</h5>
                        </div>
                      </button>
                      <div className="form-check form-switch">
                        <label
                          className="form-check-label"
                          // for="flexSwitchCheckDefault"
                        >
                          On/Off
                        </label>
                        <input
                          className="form-check-input"
                          type="checkbox"
                          role="switch"
                          id="flexSwitchCheckDefault"
                          checked={onState?.certification}
                          onClick={() => {
                            setIsCertification(!iscertification);
                            setOnState({
                              ...onState,
                              certification: !onState.certification,
                            });
                          }}
                          onChange={() => toggleDiv("div10")}
                        />
                      </div>
                    </h2>
                    <div
                      id="collapseTen"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingTen"
                      data-bs-parent="#accordionExample"
                    >
                      <div
                        className="accordion-body"
                        id="AddnewServicesformwrapper"
                      >
                        <div className="row">
                          <ResumeNewCertification
                            setCertificationArray={setCertificationArray}
                            resumeData={resumeData}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <label
                    className="form-label"
                    style={{ fontSize: "17px", marginTop: "10px" }}
                  >
                    Personal Information
                  </label>

                  {/* ///Languages ---------------Languages  */}
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingEight">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseEight"
                        aria-expanded="false"
                        aria-controls="collapseEight"
                        onClick={(e) => {}}
                      >
                        <div className="d-flex">
                          <h5 style={{ fontWeight: "400" }}> Languages </h5>
                        </div>
                      </button>
                      <div className="form-check form-switch">
                        <label className="form-check-label">On/Off</label>
                        <input
                          className="form-check-input"
                          type="checkbox"
                          role="switch"
                          id="flexSwitchCheckDefault"
                          checked={onState?.language}
                          onClick={(e) => {
                            setIsEnabledLanguage(!isEnabledLanguage);
                            setOnState({
                              ...onState,
                              language: !onState.language,
                            });
                          }}
                          // onChange={() => toggleDiv("div2")}
                        />
                      </div>
                    </h2>
                    <div
                      id="collapseEight"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingEight"
                      data-bs-parent="#accordionExample"
                    >
                      <div
                        className="accordion-body"
                        id="AddnewServicesformwrapper"
                      >
                        <div className="row">
                          <ResumeNewBusinessLanguages
                            setLanguageArray={setLanguageArray}
                            resumeData={resumeData}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingFive">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#six"
                        aria-expanded="false"
                        aria-controls="collapseSix"
                        onClick={(e) => {}}
                      >
                        <div className="d-flex">
                          <h5 style={{ fontWeight: "400" }}>
                            Hobbies / Interests
                          </h5>
                        </div>
                      </button>
                      <div className="form-check form-switch">
                        <label className="form-check-label">On/Off</label>
                        <input
                          className="form-check-input"
                          type="checkbox"
                          role="switch"
                          id="flexSwitchCheckDefault"
                          checked={onState?.adddetails}
                          onClick={(e) => {
                            setIsEnabledAdddetails(!isEnabledAdddetails);
                            setOnState({
                              ...onState,
                              adddetails: !onState.adddetails,
                            });
                          }}
                          onChange={() => toggleDiv("div6")}
                        />
                      </div>
                    </h2>
                    <div
                      id="six"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingsix"
                      data-bs-parent="#accordionExample"
                    >
                      <div
                        className="accordion-body"
                        id="AddnewServicesformwrapper"
                      >
                        <div className="row">
                          <ResumeAddDetailsNewBusinessDetilsCreate
                            setAdditinaldetailArray={setAdditinaldetailArray}
                            resumeData={resumeData}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingFive">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#seven"
                        aria-expanded="false"
                        aria-controls="collapseSix"
                        onClick={(e) => {}}
                      >
                        <div className="d-flex">
                          <h5 style={{ fontWeight: "400" }}>Achievements</h5>
                        </div>
                      </button>
                      <div className="form-check form-switch">
                        <label className="form-check-label">On/Off</label>
                        <input
                          className="form-check-input"
                          type="checkbox"
                          role="switch"
                          id="flexSwitchCheckDefault"
                          checked={onState?.achievement}
                          onClick={(e) => {
                            setIsEnabledAchievement(!isEnabledAchievement);
                            setOnState({
                              ...onState,
                              achievement: !onState.achievement,
                            });
                          }}
                          onChange={() => toggleDiv("div7")}
                        />
                      </div>
                    </h2>
                    <div
                      id="seven"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingsix"
                      data-bs-parent="#accordionExample"
                    >
                      <div
                        className="accordion-body"
                        id="AddnewServicesformwrapper"
                      >
                        <div className="row">
                          <ResumeAchievementscreate
                            setAchievementArray={setAchievementArray}
                            resumeData={resumeData}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* ////////////////////////////////////////   personal Details  /////////////////////// */}

                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingFifteen">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#Fifteen"
                        aria-expanded="false"
                        aria-controls="collapseSix"
                        onClick={(e) => {}}
                      >
                        <div className="d-flex">
                          <h5 style={{ fontWeight: "400" }}>
                            Personal Details
                          </h5>
                        </div>
                      </button>
                      <div className="form-check form-switch">
                        <label className="form-check-label">On/Off</label>
                        <input
                          className="form-check-input"
                          type="checkbox"
                          role="switch"
                          id="flexSwitchCheckDefault"
                          checked={onState?.prsnldetail}
                          onClick={(e) => {
                            setIsEnabledPrsnldetailst(!isEnabledPrsnldetails);
                            setOnState({
                              ...onState,
                              prsnldetail: !onState.prsnldetail,
                            });
                          }}
                          onChange={() => toggleDiv("div8")}
                        />
                      </div>
                    </h2>
                    <div
                      id="Fifteen"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingFifteen"
                      data-bs-parent="#accordionExample"
                      style={{ marginTop: "20px" }}
                    >
                      {/* /////////////////////////////////         DOB / Marital status    ///////////////// */}

                      <div className="AddDetailsNameDestiCom_Wrapper">
                        <div className="mb-3 col-md-6 AddDetailswidContainer AddDetailsMarContainer">
                          <label className="form-label">DOB</label>
                          <input
                            name="userdob"
                            className="form-control"
                            type="date"
                            placeholder="e.g. XYZ Tyagi"
                            value={formik?.values?.userdob}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                          {formik.touched.userdob && formik.errors.userdob && (
                            <ErrorStyle className="error">
                              {formik.errors.userdob}
                            </ErrorStyle>
                          )}
                        </div>

                        <div className="mb-3 col-md-6 AddDetailswidContainer">
                          <label className="form-label">Marital Status </label>
                          <select
                            className="form-control"
                            name="maritalstatus"
                            value={formik.values?.maritalstatus}
                            onChange={(e) => {
                              formik.handleChange(e);
                              setMaritalStatus(e.target.value); // Update maritalStatus state
                            }}
                            onBlur={formik.handleBlur}
                          >
                            <option value="">Select Marital Status</option>
                            <option value="Single">Single</option>
                            <option value="Married">Married</option>
                          </select>
                          {formik.touched.maritalstatus &&
                            formik.errors.maritalstatus && (
                              <ErrorStyle className="error">
                                {formik.errors.maritalstatus}
                              </ErrorStyle>
                            )}
                        </div>
                      </div>

                      <div className="AddDetailsNameDestiCom_Wrapper">
                        <div className="mb-3 col-md-6 AddDetailswidContainer AddDetailsMarContainer">
                          <label className="form-label">
                            Father/ Spouse Name{" "}
                          </label>
                          <input
                            name="fathername"
                            className="form-control"
                            type="text"
                            placeholder="e.g. XYZ Tyagi"
                            value={formik?.values?.fathername}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                          {formik.touched.fathername &&
                            formik.errors.fathername && (
                              <ErrorStyle className="error">
                                {formik.errors.fathername}
                              </ErrorStyle>
                            )}
                        </div>
                      </div>

                      {/* //////////////////////////////  */}
                    </div>
                  </div>

                  {/* =====business hour===== */}
                  {/* <div className="accordion-item">
                      <h2 className="accordion-header" id="headingsix">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#six"
                          aria-expanded="false"
                          aria-controls="collapseThree"
                          onClick={(e) => {
                        
                          }}
                        >
                          <div className="d-flex">
                            <h5 style={{ fontWeight: "400" }}>Business Hours</h5>
                          </div>
                        </button>
                        <div className="form-check form-switch">
                          <label
                            className="form-check-label"
                        
                          >
                            On/Off
                          </label>
                          <input
                            className="form-check-input"
                            type="checkbox"
                            role="switch"
                            id="flexSwitchCheckDefault"
                            checked={onState?.businessHours}
                            onChange={() => toggleDiv("div6")}
                            onClick={(e) => {
                              setIsBusinessHourObject(!IsBusinessHourObject);
                              setOnState({
                                ...onState,
                                businessHours: !onState.businessHours,
                              });
                            }}
                          />
                        </div>
                      </h2>
                      <div
                        id="six"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingsix"
                        data-bs-parent="#accordionExample"
                      >
                        <div
                          className="accordion-body"
                          style={{ padding: "10px 0" }}
                        >
                          
                          <div className="row" style={{ padding: "10px 0" }}>
                            <NewBusinessHour
                              setAppointmentArray={setAppointmentArray}
                              appointmentSlotDuration={appointmentSlotDuration}
                              setAppointmentSlotDuration={
                                setAppointmentSlotDuration
                              }
                              setBusinessHourObject={setBusinessHourObject}
                            />
                          </div>
                  
                        </div>
                      </div>
                    </div> */}

                  {/* =============appointments============ */}
                  {/* <div class="accordion-item">
                      <h2 class="accordion-header" id="headingFour">
                      
                        <div className="d-flex">
                          <h5 style={{ fontWeight: "400" }}>Appointments</h5>
                          <div class="form-check form-switch">
                            <label
                              class="form-check-label"
                          
                            >
                              On/Off
                            </label>
                            <input
                              class="form-check-input"
                              type="checkbox"
                              role="switch"
                              id="flexSwitchCheckDefault"
                              checked={onState?.appointment}
                              onChange={() => toggleDiv("div4")}
                              onClick={() => {
                                setIsAppointment(!isAppointment);
                                setOnState({
                                  ...onState,
                                  appointment: !onState?.appointment,
                                });
                              }}
                            />
                          </div>
                        </div>
                      
                      </h2>
                      
                    </div> */}
                  {/* ======gallary=========== */}
                  {/* <div className="accordion-item">
                      <div className="accordion-header" id="headingseven">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#seven"
                          aria-expanded="false"
                          aria-controls="collapseThree"
                          onClick={(e) => {
                            // SetIsGallaryEnable(!isGallaryEnable);
                            // setOnState({
                            //   ...onState,
                            //   gallery: !onState.gallery,
                            // });
                          }}
                        >
                          <div className="d-flex">
                            <h5 style={{ fontWeight: "400" }}>Gallery</h5>
                          </div>
                        </button>
                        <div className="form-check form-switch">
                          <label
                            className="form-check-label"
                          // for="flexSwitchCheckDefault"
                          >
                            On/Off
                          </label>
                          <input
                            className="form-check-input"
                            type="checkbox"
                            role="switch"
                            id="flexSwitchCheckDefault"
                            checked={onState?.gallery}
                            onClick={(e) => {
                              SetIsGallaryEnable(!isGallaryEnable);
                              setOnState({
                                ...onState,
                                gallery: !onState.gallery,
                              });
                            }}
                            onChange={() => toggleDiv("div7")}
                          />
                        </div>
                      </div>
                      <div
                        id="seven"
                        className={`accordion-collapse collapse`}
                        aria-labelledby="headingseven"
                        data-bs-parent="#accordionExample"
                      >
                        <div
                          className="accordion-body"
                          id="AddnewGallaryformwrapper"
                        >
                          <div className="row">
                            <NewGallerycreate
                              businessId={businessId}
                              existingGalleryImages={existingGalleryImages}
                              setExistingGalleryImages={setExistingGalleryImages}
                              gallaryObject={gallaryObject}
                              galleryData={galleryData}
                              setGalleryData={setGalleryData}
                              galleryView={galleryView}
                              setGalleryView={setGalleryView}
                              setGallaryObject={setGallaryObject}
                              isGallaryEnable={isGallaryEnable}
                            />
                          </div>
                        </div>
                      </div>
                    </div> */}
                </div>

                <div className="row Adddetails_saveprev_ButtonWrapper">
                  <div className="Changeblock_saveandpreview_buttonWrapper Changeblock_saveandpreview_buttonWrappertwo">
                    <button
                      className="btn btn-cu save-cta Changeblock_saveandpreview_button "
                      id="changeblockprenxtbtn"
                      type="button"
                      onClick={handlePreviousClick}
                    >
                      Previous
                    </button>

                    <button
                      className="btn btn-cu save-cta Changeblock_saveandpreview_button "
                      id="changeblockprenxtbtn"
                      type="button"
                      // onClick={handlePreviousClick}
                      onClick={() => handleviewClick(slug)}
                    >
                      SKIP & PREVIEW
                    </button>

                    <button
                      className="btn btn-cu save-cta Changeblock_saveandpreview_button  Sertbtn"
                      id="changeblockprenxtbtn"
                      type="button"
                      // onClick={handlePreviousClick}
                      onClick={(e) => {
                        handleUpdateBusiness(e);
                      }}
                    >
                      SAVE & PREVIEW
                    </button>

                    {/* {isSubmit ? (
                      <CircularLoader size={40} />
                    ) : (
                      <button
                        type="button"
                        className="btn btn-cu save-cta Changeblock_saveandpreview_button"
                        id="changeblockprenxtbtn"
                        onClick={(e) => handleSubmitMongo(e)}
                      >
                     
                        Resume preview
                      </button>
                    )} */}
                  </div>
                </div>
              </form>
            </div>
          </div>

          <div className="ChangeBlockView_Container">
            <ResumeViewThem
              mobilePreviewLoading={mobilePreviewLoading}
              isLoadingSubmit={isLoadingSubmit}
              handleSubmit={handleSubmitWithoutTabChange}
              activeTab={activeTab}
              existingGalleryImages={existingGalleryImages}
              galleryView={galleryView}
              resumeData={resumeData}
              title={title}
              formikData={formik}
              themeColor={themeColor}
              themeNumber={themeNumber}
              designation={formik.values?.designation}
              subTitle={formik.values?.subTitle}
              description={formik.values?.description}
              bannerImageUrl={bannerImageUrl}
              logoImageUrl={logoImageUrl}
              contactInfo={{
                contactInfoAddress,
                email: formik.values?.email,
                phone: formik.values?.phone,
                fathername: formik.values?.fathername,
                userdob: formik.values?.userdob,
                maritalstatus: formik.values?.maritalstatus,
                linkedIn: formik.values?.linkedIn,
                contactInfoWebUrl,
              }}
              businessHourObject={businessHourObject}
              socialMediaData={socialMediaData}
              skillArray={skillArray}
              additinaldetailArray={additinaldetailArray}
              achievementArray={achievementArray}
              projectArray={projectArray}
              certificationArray={certificationArray}
              gallaryObject={gallaryObject}
              galleryData={galleryData}
              onState={onState}
              appointmentArray={appointmentArray}
              toolsArray={toolsArray}
              languageArray={languageArray}
              workExpArray={workExpArray}
            />
          </div>
        </div>
      </div>
      <Modal sx={modalStyle} open={modalOpen}>
        <Box sx={boxStyle}>
          <AvatarEditor
            ref={cropRef}
            image={src}
            style={{ width: "100%", height: "100%" }}
            border={50}
            borderRadius={150}
            color={[0, 0, 0, 0.72]}
            scale={slideValue / 10}
            rotate={0}
          />
          <Slider
            min={10}
            max={50}
            sx={{
              margin: "0 auto",
              width: "80%",
              color: "cyan",
            }}
            size="medium"
            defaultValue={slideValue}
            value={slideValue}
            onChange={(e) => setSlideValue(e.target.value)}
          />
          <Box
            sx={{
              display: "flex",
              padding: "10px",
              border: "3px solid white",
              background: "black",
            }}
          >
            <Button
              size="small"
              sx={{
                marginRight: "10px",
                color: "white",
                borderColor: "white",
              }}
              variant="outlined"
              onClick={(e) => setModalOpen(false)}
            >
              cancel
            </Button>
            <Button
              sx={{ background: "#5596e6" }}
              size="small"
              variant="contained"
              onClick={handleSave}
            >
              Save
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
}

export default ResumeDetails;
