import React from "react";
import { Link } from "react-router-dom";

function Page404() {

  return (
    <>
      <div className="login-23">
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-12 col-lg-12 col-md-12 bg-img login-slides">
              <div className="" style={{display:"flex; justify-content-center"}}>
                <div className="waviy">
                  <h3 className="text-center" style={{color:"#fff"}}>Page Not Found!!</h3>
                </div>
                <p>
                  <Link to={'/'} style={{color:"#fff"}}>Go to home page.</Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Page404;