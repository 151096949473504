import React, { useRef, useState } from "react";
// import "./TemplatComman.css";
import bannerImage from "../../../../Images/SalonBanner.png";
import logoImage from "../../../../Images/Saloonlogo1.jpg";
import testimonialImage from "../../../../Images/avatar.png";
import FacebookIcon from "../../../../Images/facebookIcon.jpg";
import InstagramIcon from "../../../../Images/instagramIcon.png";
import TweeterIcon from "../../../../Images/twitterIcon.png";
import LinedinIcon from "../../../../Images/linedInIcon.png";
import WhatsappIcon from "../../../../Images/whatsappIcon.png";
import CalanderIconn from "../../../../Images/calendarIcondays.png";



import TopHeaderImage from "../../../../Images/Restaurant/TopImage.png";
import MenuIcon from "../../../../Images/Restaurant/Tabmenu.png";
import MenuIcon2 from "../../../../Images/Restaurant/Tabmenu2.png";
import ReviewIcon from "../../../../Images/Restaurant/TabReview.png";
import ReviewIcon2 from "../../../../Images/Restaurant/TabReview2.png";
import OrderIcon from "../../../../Images/Restaurant/tabOrder.png";
import OrderIcon2 from "../../../../Images/Restaurant/tabOrder2.png";
import OpeningIcon from "../../../../Images/Restaurant/tabopeninghr.png";
import OpeningIcon2 from "../../../../Images/Restaurant/tabopeninghr2.png";
import GalleryIcon from "../../../../Images/Restaurant/tabimage-gallery.png";
import GalleryIcon2 from "../../../../Images/Restaurant/tabimage-gallery2.png";
import SocialIcon from "../../../../Images/Restaurant/tabsocial-media.png";
import SocialIcon2 from "../../../../Images/Restaurant/tabsocial-media2.png";
import ContactIcon from "../../../../Images/Restaurant/tabcontact-us.png";
import ContactIcon2 from "../../../../Images/Restaurant/tabcontact-us2.png";
import AboutIcon from "../../../../Images/Restaurant/tabinfo-button.png";
import AboutIcon2 from "../../../../Images/Restaurant/tabinfo-button2.png";
import FirstDishImg from "../../../../Images/Restaurant/FirstMenuimg.png";
import BestSellerIcon from "../../../../Images/Restaurant/Bestsellertitle.png";
import StarIcon from "../../../../Images/Restaurant/Star.png";
import AddmoreIcon from "../../../../Images/Restaurant/addmoreicon.png";
import OrderImg from "../../../../Images/Restaurant/Reviewfirstimg.png";
import OrderreviewIcon from "../../../../Images/Restaurant/reviewstar.png";
import OrderImageeIcon from "../../../../Images/Restaurant/order.png";
import GalleryIconImage from "../../../../Images/Restaurant/GalleryImage1.png";
import GalleryIconImage2 from "../../../../Images/Restaurant/GalleryIconImage2.png";
import GalleryIconImage3 from "../../../../Images/Restaurant/GalleryIconImage3.png";
import PhoneContact from "../../../../Images/Restaurant/phoneIcon.png";
import AddressIcon from "../../../../Images/Restaurant/address.png";
import MailIDIcon from "../../../../Images/Restaurant/mailid.png";
import WebsiteIcon from "../../../../Images/Restaurant/website.png";
import FacebookimgIcon from "../../../../Images/Restaurant/FacebookIcon.png";
import YoutubeimgIcon from "../../../../Images/Restaurant/youtubeicon.png";
import XimgIcon from "../../../../Images/Restaurant/xIcon.png";


import "./Theme14.css";
import GalleryCarousel from "./GalleryCarousel";
import TestimonialCarousel from "./TestimonialCarousel";
import { AboutParagraph, AboutText, AboutTextWrapper, AddOrderContainer, AddorderContainerWrapper, AddorderImage, AddorderIncrement, 
  AddorderIncrementWrapper, AddorderText, AddorderTextContainer, AddorderValue, Addorderseller, ChefName, ContactIconWrapper, ContactTabIcon,
   ContactTabValue, Container, Cross, CrossWrapper, Designation, GalleryImage, GalleryImageWrapper, GalleryWrapper, Image, IncrementSpan, MenuAddMoreWrapper,
    MenuAddmoreIcon, MenuBestSaller, MenuCardWrapper, MenuDeatilsContainer, MenuImage, MenuImageWrapper, MenuName, MenuNameWrapper, MenuPrice, MenuPriceWrapper,
     MenuStar, MenuStarWrapper, MenuStarvalue, MenuVageFilter, MenuVagefilterWrapper, MondaySatWrapper, MondayText, MondayTextWrapper, MondayTextvalue, Name,
      NewTabGallaryWrapper, NewTabGallaryWrapperContainer, NewTabMenuHeadingWrapper, NewTabMenuText, NewTabMenuWrapper, NewTabOrderMenuWrapper, NewTabOrderWrapper, 
      OpeningHours, OpeningHoursContainer, OrderCardWrapper, OrderCardWrapperContainer, OrderImage, OrderImageWrapper, OrderNameWrapper, OrderParagraph,
       OrderStarImageWrapper, OrderStatus, OrderStatusCardWrapper, OrderStatusCardWrapper2, OrderStatusKeyValueWrapper, OrderStatusValue, OrderStatuskey, 
       OrderTextWrapper, Ordername, OrderstarImage, OrderstatusContainernew, SocialmediaIconn, SundayText, SundayTextValue, SundayWrapper, TabContactPhoneWrapper,
        TabImage, TabImageWrapper, TabSocialContainer, TabSocialIConWrapper, TabText, TabTextWrapper, TabWrapper, TabWrapperContainer, TextDetails, TopImageWrapper,
         Wrapper } from "./Theme14ExtStyleComponent";

         import styled from "styled-components";
         import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
         
         
         const DineIn = styled.div`
             color: #fff;
             font-size: 10px;
             background: linear-gradient(to right, #3D8C20, #7BD25B);
             padding: 7px;
             border-radius: 50px 0 0 50px;
             width: 100px;
             right: 0;
             top: 20px;
             position: absolute;
            @media(max-width:480px) {
              position: absolute;
              right: 0;
              top: -35px!important;
            }
         `;
         const LocationInfo = styled.div`
             color: #fff;
             font-size: 12px;
             background: #252525bd;
             padding: 7px 15px;
             margin-top: 5px;
             border-radius: 50px;
             border: 1px solid #CD2F34;
         `;



const Theme14Template = (props) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const calendarInputRef = useRef(null);
  const [activeTab, setActiveTab] = useState("menu");

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };
  const testimonialArray = [
    {
      name: "Ernest Reinger",
      description:
        "I absolutely love the Constriction Services! They have exceeded my expectations in every way.",
      formCount: "0",
      image: "null",
    },
    {
      name: "Ernest Reinger",
      description:
        "I absolutely love the Constriction Services! They have exceeded my expectations in every way.",
      formCount: "1",
      image: "null",
    },
    {
      name: "Ernest Reinger",
      description:
        "I absolutely love the Constriction Services! They have exceeded my expectations in every way.",
      formCount: "2",
      image: "null",
    },
  ];
  const galleryArray = [
    {
      id: 60,
      business_id: 10,
      image: "1701329881279Rectangle6.jpg",
      created_by: 72,
      created_at: "2023-11-30T07:38:01.000Z",
      updated_at: "2023-11-30T07:38:01.000Z",
    },
    {
      id: 61,
      business_id: 10,
      image: "1701329883848Rectangle7.jpg",
      created_by: 72,
      created_at: "2023-11-30T07:38:03.000Z",
      updated_at: "2023-11-30T07:38:03.000Z",
    },
    {
      id: 62,
      business_id: 10,
      image: "1701329887518Rectangle597.jpg",
      created_by: 72,
      created_at: "2023-11-30T07:38:07.000Z",
      updated_at: "2023-11-30T07:38:07.000Z",
    },
    {
      id: 63,
      business_id: 10,
      image: "1701329891477Rectangle598.jpg",
      created_by: 72,
      created_at: "2023-11-30T07:38:11.000Z",
      updated_at: "2023-11-30T07:38:11.000Z",
    },
    {
      id: 64,
      business_id: 10,
      image: "1701329899378Rectangle599.jpg",
      created_by: 72,
      created_at: "2023-11-30T07:38:19.000Z",
      updated_at: "2023-11-30T07:38:19.000Z",
    },
    {
      id: 65,
      business_id: 10,
      image: "1701329902425Rectangle600.jpg",
      created_by: 72,
      created_at: "2023-11-30T07:38:22.000Z",
      updated_at: "2023-11-30T07:38:22.000Z",
    },
  ];

  const menuItems = [
    { name: 'Cherry Healthy', price: '₹289.00', rating: '4.9', },
    { name: 'Cherry Healthy', price: '₹150.00', rating: '4.9' },
    { name: 'Cherry Healthy', price: '₹190.00', rating: '4.9' },
    { name: 'Cherry Healthy', price: '₹200.00', rating: '4.9' },
    { name: 'Cherry Healthy', price: '₹190.00', rating: '4.9' },
    { name: 'Cherry Healthy', price: '₹200.00', rating: '4.9' },
    { name: 'Cherry Healthy', price: '₹190.00', rating: '4.9' },
    { name: 'Cherry Healthy', price: '₹200.00', rating: '4.9' },
];

  const openCalendar = () => {
   
    if (calendarInputRef.current) {
      calendarInputRef.current.showPicker();
     
    }
  };
  return (
    <div>
      <div class="main-temaplt" id="temp14">
        <div className="container">
          <div className="row justify-content-center">
            <div style={{ width: "420px" }} className="col-12 wtemplate">
             
            <Wrapper>
            <TopImageWrapper InternalTheme>
                <Image src={TopHeaderImage}/>
                <TextDetails InternalTheme>
                <DineIn>Dine-in available</DineIn>
                  <Name InternalTheme>L' Osteria Bella</Name>
                  <div className="mainservicebox">
                  <ul className="servesbox">
                  <li className="service-list">North India</li>
                  <li className="service-list">Chinees</li>
                  <li className="service-list">Beverages</li>
                  </ul>
                  </div>
                  <LocationInfo>
                      Huaz Khas Village, New Delhi
                    </LocationInfo>
                </TextDetails>
              </TopImageWrapper>

              <TabWrapperContainer>
                <TabWrapper
                 active={activeTab === "menu"}
                 onClick={() => handleTabClick("menu")}
                 background={activeTab === "menu" ? "#FCEBEB" : "#F3F3F3"} 
                 color={activeTab === "menu" ? "#FCEBEB" : "#F3F3F3"} 
                >
                  <TabImageWrapper>
                    <TabImage src={activeTab === "menu" ? MenuIcon2 : MenuIcon}/>
                  </TabImageWrapper>
                  <TabTextWrapper>
                    
                    <TabText
                     color={activeTab === "menu" ? "#DE3036" : "#A2A3A4"} 
                    >Menu</TabText>
                  </TabTextWrapper>

                </TabWrapper>
                {/* <TabWrapper 
                 active={activeTab === "review"}
                 onClick={() => handleTabClick("review")}
                 background={activeTab === "review" ? "#FCEBEB" : "#F3F3F3"} 
                >
                  <TabImageWrapper>
                    <TabImage 
                    src={activeTab === "review" ? ReviewIcon2 : ReviewIcon}
                   />
                  </TabImageWrapper>
                  <TabTextWrapper>
                    <TabText 
                     color={activeTab === "review" ? "#DE3036" : "#A2A3A4"} 
                    >Review & Rating</TabText>
                  </TabTextWrapper>

                </TabWrapper> */}
                <TabWrapper 
                 active={activeTab === "order"}
                 onClick={() => handleTabClick("order")}
                 background={activeTab === "order" ? "#FCEBEB" : "#F3F3F3"} 
                >
                  <TabImageWrapper>
                    <TabImage 
                     src={activeTab === "order" ? OrderIcon2 : OrderIcon}
                   />
                  </TabImageWrapper>
                  <TabTextWrapper>
                    <TabText 
                     color={activeTab === "order" ? "#DE3036" : "#A2A3A4"} 
                    >Order</TabText>
                  </TabTextWrapper>

                </TabWrapper>
                <TabWrapper 
                 active={activeTab === "openinghours"}
                 onClick={() => handleTabClick("openinghours")}
                 background={activeTab === "openinghours" ? "#FCEBEB" : "#F3F3F3"} 
                >
                  <TabImageWrapper>
                    <TabImage  src={activeTab === "openinghours" ? OpeningIcon2 : OpeningIcon} />
                  </TabImageWrapper>
                  <TabTextWrapper>
                    <TabText 
                     color={activeTab === "openinghours" ? "#DE3036" : "#A2A3A4"} 
                    >Opening Hours</TabText>
                  </TabTextWrapper>

                </TabWrapper>
                <TabWrapper 
                 active={activeTab === "Gallery"}
                 onClick={() => handleTabClick("Gallery")}
                 background={activeTab === "Gallery" ? "#FCEBEB" : "#F3F3F3"} 
                >
                  <TabImageWrapper>
                    <TabImage src={activeTab === "Gallery" ? GalleryIcon2 : GalleryIcon}/>
                  </TabImageWrapper>
                  <TabTextWrapper>
                    <TabText 
                     color={activeTab === "Gallery" ? "#DE3036" : "#A2A3A4"} 
                    >Gallery</TabText>
                  </TabTextWrapper>

                </TabWrapper>
                <TabWrapper 
                  active={activeTab === "Socialmedia"}
                  onClick={() => handleTabClick("Socialmedia")}
                  background={activeTab === "Socialmedia" ? "#FCEBEB" : "#F3F3F3"} 
                >
                  <TabImageWrapper>
                    <TabImage src={activeTab === "Socialmedia" ? SocialIcon2 : SocialIcon}/>
                  </TabImageWrapper>
                  <TabTextWrapper>
                    <TabText 
                     color={activeTab === "Socialmedia" ? "#DE3036" : "#A2A3A4"} 
                    >Social media</TabText>
                  </TabTextWrapper>

                </TabWrapper>
                <TabWrapper 
                 active={activeTab === "Contactinfo"}
                 onClick={() => handleTabClick("Contactinfo")}
                 background={activeTab === "Contactinfo" ? "#FCEBEB" : "#F3F3F3"} 
                >
                  <TabImageWrapper>
                    
                    <TabImage src={activeTab === "Contactinfo" ? ContactIcon2 : ContactIcon}/>

                  </TabImageWrapper>
                  <TabTextWrapper>
                    <TabText 
                     color={activeTab === "Contactinfo" ? "#DE3036" : "#A2A3A4"} 
                    >Contact Info</TabText>
                  </TabTextWrapper>
                </TabWrapper>
                <TabWrapper 
                  active={activeTab === "about"}
                  onClick={() => handleTabClick("about")}
                  background={activeTab === "about" ? "#FCEBEB" : "#F3F3F3"} 
                >
                  <TabImageWrapper>
                    <TabImage 
                    src={activeTab === "about" ? AboutIcon2 : AboutIcon} />
                   
                  </TabImageWrapper>
                  <TabTextWrapper>
                    <TabText 
                     color={activeTab === "about" ? "#DE3036" : "#A2A3A4"} 
                    > About</TabText>
                  </TabTextWrapper>

                </TabWrapper>
              </TabWrapperContainer>
            </Wrapper>

            {activeTab === "menu" && (
              <div>
               <NewTabMenuWrapper>
                <NewTabMenuHeadingWrapper>
                  <NewTabMenuText InternalTheme>Menu</NewTabMenuText>
                </NewTabMenuHeadingWrapper>
                <Container InternalTheme>
                <MenuDeatilsContainer InternalTheme>
                    {menuItems.map((menuItem, index) => (
                        <MenuCardWrapper key={index}>
                            <MenuImageWrapper>
                                <MenuImage src={FirstDishImg} />
                                {/* <MenuBestSaller src={BestSellerIcon}></MenuBestSaller> */}
                                {index === 0 && <MenuBestSaller src={BestSellerIcon}></MenuBestSaller>}
                               <MenuVagefilterWrapper>
                               <MenuVageFilter></MenuVageFilter>
                               </MenuVagefilterWrapper>
                            </MenuImageWrapper>
                            <MenuNameWrapper>
                                <MenuName InternalTheme>{menuItem.name}</MenuName>
                            </MenuNameWrapper>
                            {/* <MenuStarWrapper>
                                <MenuStar src={StarIcon} />
                                <MenuStarvalue>{menuItem.rating}</MenuStarvalue>
                            </MenuStarWrapper> */}
                            <MenuPriceWrapper>
                                <MenuPrice InternalTheme>{menuItem.price}</MenuPrice>
                                <MenuAddMoreWrapper>
                                    <MenuAddmoreIcon InternalTheme src={AddmoreIcon} />
                                </MenuAddMoreWrapper>
                            </MenuPriceWrapper>
                        </MenuCardWrapper>
                    ))}
                </MenuDeatilsContainer>
            </Container>
               </NewTabMenuWrapper>
              </div>
              )}

            {/* {activeTab === "review" && (
                <NewTabOrderWrapper>
               <NewTabMenuHeadingWrapper>
                  <NewTabMenuText InternalTheme> Review & Rating </NewTabMenuText>
                </NewTabMenuHeadingWrapper>
                <OrderCardWrapperContainer>
                  <OrderCardWrapper>
                    <OrderImageWrapper>
                      <OrderImage InternalTheme src={OrderImg}/> 
                   
                    <OrderTextWrapper>
                      <OrderNameWrapper>
                        <Ordername>Christina Smith</Ordername>
                      </OrderNameWrapper>
                      <OrderStarImageWrapper>
                        <OrderstarImage InternalTheme src={OrderreviewIcon}/>
                      </OrderStarImageWrapper>
                      <OrderParagraph>Eodem modo typi, qui nunc nobis videntur parum clari, fiant sollemnes futurum.  </OrderParagraph>
                    </OrderTextWrapper>
                    </OrderImageWrapper>
                  </OrderCardWrapper>
                  <OrderCardWrapper>
                    <OrderImageWrapper>
                      <OrderImage InternalTheme src={OrderImg}/> 
                   
                    <OrderTextWrapper>
                      <OrderNameWrapper>
                        <Ordername>Christina Smith</Ordername>
                      </OrderNameWrapper>
                      <OrderStarImageWrapper>
                        <OrderstarImage src={OrderreviewIcon}/>
                      </OrderStarImageWrapper>
                      <OrderParagraph>Eodem modo typi, qui nunc nobis videntur parum clari, fiant sollemnes futurum.  </OrderParagraph>
                    </OrderTextWrapper>
                    </OrderImageWrapper>
                  </OrderCardWrapper>
                </OrderCardWrapperContainer>
                
                </ NewTabOrderWrapper>
              )} */}

            {activeTab === "order" && (
                <NewTabOrderMenuWrapper>
                   <NewTabMenuHeadingWrapper>
                     <NewTabMenuText InternalTheme>Orders</NewTabMenuText>
                   </NewTabMenuHeadingWrapper>
                   <OrderstatusContainernew>
                   <OrderStatusCardWrapper2>
                      <AddOrderContainer>
                        <AddorderContainerWrapper>
                          <AddorderImage InternalTheme src={OrderImageeIcon}/>
                          <Addorderseller />
                        </AddorderContainerWrapper>
                      </AddOrderContainer>
                      <AddorderTextContainer>
                        <AddorderText InternalTheme>Cherry Healthy</AddorderText>
                        <AddorderValue InternalTheme>₹289.00</AddorderValue>
                        <AddorderIncrementWrapper>
                          <AddorderIncrement> <IncrementSpan>-</IncrementSpan> 14 <IncrementSpan>+</IncrementSpan></AddorderIncrement>
                        </AddorderIncrementWrapper>
                      </AddorderTextContainer>
                      <CrossWrapper InternalTheme>
                        <Cross >+</Cross>
                      </CrossWrapper>
                    </OrderStatusCardWrapper2>
                  <OrderStatusCardWrapper>
                    <OrderStatus>
                      Order Status:
                    </OrderStatus>
                    <OrderStatusKeyValueWrapper>
                      <OrderStatuskey InternalTheme>Order ID</OrderStatuskey>
                      <OrderStatusValue InternalTheme>#FM209391</OrderStatusValue>
                    </OrderStatusKeyValueWrapper>
                    <OrderStatusKeyValueWrapper>
                    <OrderStatuskey InternalTheme>Order date</OrderStatuskey>
                      <OrderStatusValue InternalTheme>Apr 1, 2024 | 40mins</OrderStatusValue>
                    </OrderStatusKeyValueWrapper>
                    <OrderStatusKeyValueWrapper>
                    <OrderStatuskey InternalTheme>Order ID</OrderStatuskey>
                      <OrderStatusValue InternalTheme>#FM209391</OrderStatusValue> 
                  </OrderStatusKeyValueWrapper>
                </OrderStatusCardWrapper>
                   </OrderstatusContainernew>

                 </NewTabOrderMenuWrapper>
              )}
            
            {activeTab === "openinghours" && (
               <OrderCardWrapperContainer>
               <OpeningHoursContainer>
               <OpeningHours>Opening Hours</OpeningHours>
               <MondaySatWrapper>
               <MondayTextWrapper> <MondayText InternalTheme>MONDAY - SATURDAY</MondayText></MondayTextWrapper>
               <MondayTextWrapper> <MondayTextvalue InternalTheme> 10:00 AM to 10:00 PM </MondayTextvalue></MondayTextWrapper>
              <SundayWrapper>
              <SundayText InternalTheme> SUNDAY </SundayText>
                <SundayTextValue InternalTheme>CLOSED</SundayTextValue>
              </SundayWrapper>
               </MondaySatWrapper>
               </OpeningHoursContainer>
                </OrderCardWrapperContainer>
              )}

            {activeTab === "Gallery" && (
              <NewTabGallaryWrapperContainer>
                <NewTabGallaryWrapper>
                  <GalleryWrapper>
                    <GalleryImageWrapper>
                    <GalleryImage src={GalleryIconImage}/>
                    </GalleryImageWrapper>
                    <GalleryImageWrapper>
                    <GalleryImage src={GalleryIconImage2}/>
                    </GalleryImageWrapper>
                    <GalleryImageWrapper>
                    <GalleryImage src={GalleryIconImage3}/>
                    </GalleryImageWrapper>
                    <GalleryImageWrapper>
                    <GalleryImage src={GalleryIconImage}/>
                    </GalleryImageWrapper>
                    <GalleryImageWrapper>
                    <GalleryImage src={GalleryIconImage2}/>
                    </GalleryImageWrapper>
                    <GalleryImageWrapper>
                    <GalleryImage src={GalleryIconImage}/>
                    </GalleryImageWrapper>
                   
                  </GalleryWrapper>
               </NewTabGallaryWrapper>
              </NewTabGallaryWrapperContainer>
              )}

            {activeTab === "Socialmedia" && (
                <TabSocialContainer>
                  <TabSocialIConWrapper InternalTheme>
                  <SocialmediaIconn src={FacebookimgIcon}/>    
                  </TabSocialIConWrapper>
                  <TabSocialIConWrapper InternalTheme>
                  <SocialmediaIconn src={YoutubeimgIcon}/>    
                  </TabSocialIConWrapper>
                
                
                </TabSocialContainer>   
              )}

            {activeTab === "Contactinfo" && (
                <OrderCardWrapperContainer>
                <OpeningHoursContainer>
                <AboutTextWrapper>
                  <AboutText InternalTheme> Contact Info </AboutText>
                  <TabContactPhoneWrapper InternalTheme>
                  <ContactIconWrapper InternalTheme>
                    <ContactTabIcon src={PhoneContact} />
                  </ContactIconWrapper>
                  <ContactTabValue InternalTheme>
                  91-11-42222000(628)
                  </ContactTabValue>
                </TabContactPhoneWrapper>
                 
                  <TabContactPhoneWrapper InternalTheme>
                    <ContactIconWrapper InternalTheme>
                      <ContactTabIcon src={WebsiteIcon} />
                    </ContactIconWrapper>
                    <ContactTabValue InternalTheme>
                    https://www.diner.com/
                    </ContactTabValue>

                  </TabContactPhoneWrapper>
                  <TabContactPhoneWrapper InternalTheme>
                    <ContactIconWrapper InternalTheme>
                      <ContactTabIcon src={MailIDIcon} />
                    </ContactIconWrapper>
                    <ContactTabValue InternalTheme>
                    Contact@diner.com
                    </ContactTabValue>

                  </TabContactPhoneWrapper>
                  <TabContactPhoneWrapper InternalTheme>
                    <ContactIconWrapper InternalTheme>
                      <ContactTabIcon src={AddressIcon} />
                    </ContactIconWrapper>
                    <ContactTabValue InternalTheme>
                    Level 1, portside wharf, 39 hercules st, hamilton QLD (opposite dendy cinemas)
                    </ContactTabValue>

                  </TabContactPhoneWrapper>

                </AboutTextWrapper>
                </OpeningHoursContainer>
                 </OrderCardWrapperContainer>
              )}

            {activeTab === "about" && (
                <OrderCardWrapperContainer>
                <OpeningHoursContainer>
                <AboutTextWrapper>
                  <AboutText>About</AboutText>
                  <AboutParagraph>
                  {/* Brown uses drawing as a prerequisite to guide her work. Through the use of repetition, Brown captures images that both attract and confound her.  Though her drawings are not as exhibited as her paintings, both art mediums contain similar aspects in showcasing her erotic view of art through subject matter */}
                  We serve delectable dishes made from the finest ingredients in a welcoming atmosphere. Our menu caters to all tastes, ensuring a memorable dining experience, whether you're here for a quick bite or a special occasion.
                  </AboutParagraph>
                </AboutTextWrapper>
              
                </OpeningHoursContainer>
                 </OrderCardWrapperContainer>
              )}






             
              {/* <article className="imgsechere sec">
                <img
                  src={bannerImage}
                  className="mx-auto d-block img-fluid coverimghere "
                  style={{width:"100%"}}
                />
              </article> */}
              {/* <div className="logoTextContainer">
               <div>
               <img
                src={logoImage}
                className="mx-auto d-block img-fluid logo-profile"
              />
              </div>
               <article className="personalinfo sec">
                <h3 className="username">Anastasia Anastasia</h3>
                <p className="titleuser">Barber, Looks Salon</p>
              </article>
              </div> */}
              {/* <article className="makeappitmneets sec"> 
                <div className="contact-form">
                  <div class="col-12 mb-3" style={{position:"relative"}}>
                    <input
                      ref={calendarInputRef}
                      type="date"
                      class="form-control clear_string"
                      placeholder="Date"
                      name="currentDate"
                    />
                        <div className="calanderIcon" onClick={openCalendar}><img src={CalanderIconn} alt="Calendar Icon" /></div>
                  </div>
                  <div class="col-12 mb-3">
                    <select
                      class="form-select"
                      aria-label="Default select example"
                      // onChange={(e) => setSelectedTime(e.target.value)}
                    >
                      <option value="Select time">Select time</option>
                      <option>{`2:00 PM to 5:00 PM`}</option>
                    </select>
                  </div>
                  <div class="col-12 mb-3">
                    <button
                      className="btn btnmakeappitmnets"
                      data-bs-toggle="modal"
                      data-bs-target="#appotimnets"
                    >
                      Make An Appointment
                    </button>
                  </div>
                </div>
              </article> */}
              {/* <article className="abouttemplate sec">
                <h3 class="sectitle">About me</h3>
                <p className="para">
                  {` Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s.`}
                </p>
              </article> */}
              {/* <article class="gallery sec sec-common">
                <h3 class="sectitle">Gallery </h3>
                <GalleryCarousel slides={galleryArray} />
              </article> */}
              {/* <article className="social-links-sec sec">
                <h3 class="sectitle">Social Media</h3>
                <ul class="socil-link socil-link-inner">
                  <li>
                    <a>
                     
                      <img src={FacebookIcon} />
                    </a>
                  </li>
                  <li>
                    <a>
                    
                      <img src={InstagramIcon} />
                    </a>
                  </li>
                 
                  <li>
                    <a>
                    
                      <img src={TweeterIcon} />
                    </a>
                  </li>
                  <li>
                    <a>
                     
                      <img src={WhatsappIcon} />
                    </a>
                  </li>
                  <li>
                    <a>
                    
                      <img src={LinedinIcon} />
                    </a>
                  </li>
                </ul>
              </article> */}
              {/* <article className="businessHours sec">
                <h3 class="sectitle">Working Hours</h3>

                <ul class="timelist">
                  <li>
                    <div className="day">Monday :</div>
                    <div className="time">
                      <span>8:00 AM</span>-<span>5:00 PM</span>
                    </div>
                  </li>
                  <li>
                    <div className="day">Tuesday :</div>
                    <div className="time">
                      <span>8:00 AM</span>-<span>5:00 PM</span>
                    </div>
                  </li>
                  <li>
                    <div className="day">Wednesday :</div>
                    <div className="time">
                      <span>8:00 AM</span>-<span>5:00 PM</span>
                    </div>
                  </li>
                  <li>
                    <div className="day">Thursday :</div>
                    <div className="time">
                      <span>8:00 AM</span>-<span>5:00 PM</span>
                    </div>
                  </li>
                  <li>
                    <div className="day">Friday :</div>
                    <div className="time">
                      <span>8:00 AM</span>-<span>5:00 PM</span>
                    </div>
                  </li>
                  <li>
                    <div className="day">Saturday :</div>
                    <div className="time">
                      <span>8:00 AM</span>-<span>5:00 PM</span>
                    </div>
                  </li>
                </ul>
              </article> */}
              {/* <article className="conatctform sec">
                <h3 class="sectitle">Contact Form</h3>
                <div class="contact-form">
                  <form>
                    <div class="col-12 mb-3">
                      <input
                        type="text"
                        class="form-control clear_string"
                        placeholder="Full Name"
                        name="last_name"
                        value=""
                        required=""
                      />
                    </div>
                    <div class="col-12 mb-3">
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Email"
                        name="email"
                        value=""
                        required=""
                      />
                    </div>
                    <div class="col-12 mb-3">
                      <input
                        type="text"
                        class="form-control clear_string"
                        maxlength="10"
                        minlength="10"
                        placeholder="Mobile Number"
                        name="mobile_number"
                        value=""
                        required=""
                      />
                    </div>
                    <div class="col-12 mb-3">
                      <textarea
                        class="form-control clear_string"
                        rows="3"
                        placeholder="Message*"
                        required=""
                        name="message"
                      ></textarea>
                    </div>

                    <div class="col-12 mb-3">
                      <button type="submit" class="btn btn-submitform">
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </article> */}
              {/* <article className="servicesName sec">
                <h3 class="sectitle">Services </h3>
                <div className="mainservicebox">
                  <ul className="servesbox">
                    <li>Hair Cutting</li>
                    <li>Hair Colors</li>
                    <li>Hair Spa</li>
                    <li>Hair Treatments</li>
                  </ul>
                </div>
              </article> */}
              {/* <article class="testimoniyalbox sec">
                <h3 class="sectitle testimonial">Testimonial </h3>

                <div>
                  <TestimonialCarousel
                    testimonials={testimonialArray}
                    currentElem={currentIndex}
                  />
                  <div className="carousel-container">
                    <button
                      onClick={() =>
                        currentIndex == 0
                          ? setCurrentIndex(testimonialArray.length - 1)
                          : setCurrentIndex((prev) => prev - 1)
                      }
                      className="carousel-btn"
                    >
                      <i class="fa fa-angle-left"></i>
                    </button>
                    <button
                      onClick={() => {
                        currentIndex == testimonialArray.length - 1
                          ? setCurrentIndex(0)
                          : setCurrentIndex((prev) => prev + 1);
                      }}
                      className="carousel-btn"
                    >
                      <i class="fa fa-angle-right"></i>
                    </button>
                  </div>
                </div>
              </article> */}
              {/* <article class="contact sec">
                <h3 class="sectitle">Contact</h3>
                <ul class="conlink">
                  <li>
                    <a href="">
                      <i class="fa-solid fa-phone"></i>
                      <span>{`987654321`}</span>
                    </a>
                  </li>

                  <li>
                    <a href="">
                      <i class="fa-solid fa-envelope"></i>
                      <span>{` christian@gmail.com`}</span>
                    </a>
                  </li>

                  <li>
                    <a href="">
                      <i class="fa-solid fa-map"></i>
                      <span>{`No 17A / 22, Ajmal Khan Road,`}</span>
                    </a>
                  </li>
                </ul>
              </article> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Theme14Template;
