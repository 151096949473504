import React, { useState, useEffect } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import "./../Updatedcss/BusinessChangeBlockUpdatedStyle.css";
import { isValidPhoneNumber } from "../../../utils/utility";

const SocialForm = ({
  formCount,
  socialData,
  setIsAddSocialDisable,
  setSocialData,
  index,
}) => {
  const [data, setData] = useState({
    formCount: formCount,
    nameOfSocial: "",
    link: "",
  });
  
  const [invalidPhoneErr, setInvalidPhoneErr] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [isAddDisable, setIsAddDisable] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isAddShow, setIsAddShow] = useState(false);
  useEffect(() => {
    let currentForm = socialData.filter((val) => val.formCount == formCount);
    if (currentForm.length > 0) {
      setData(currentForm[0]);
    }
    currentForm[0]["nameOfSocial"] ? setIsAddShow(false) : setIsAddShow(true);
  }, [socialData]);
  const getSocialLinkLabel = (name) => {
    if (name == "whatsapp") {
      return "Mobile";
    } else if (name == "telegram") {
      return "Username";
    } else if (name == "discord") {
      return "Channel Name";
    } else if (name == "pinterest") {
      return "Username";
    } else if (name == "gmail") {
      return "Email Id";
    } else {
      return "Link";
    }
  };
  const handleAddSocial = () => {
    let selectedForm = socialData.filter(
      (obj) => obj.formCount == data.formCount
    );
    if (selectedForm.length > 0) {
      const updateItem = (formCount) => {
        const index = socialData.findIndex(
          (item) => item.formCount == formCount
        );

        const updatedItems = [...socialData];
        if (index !== -1) {
          updatedItems[index] = {
            ...updatedItems[index],
            nameOfSocial: data.nameOfSocial,
            link: data.link,
          };
        }
        setSocialData(updatedItems);
      };
      updateItem(formCount);
    } else {
      setSocialData([...socialData, data]);
    }
    // setIsAddDisable(true);
    // setIsAddSocialDisable(false);
    setIsAddShow(false);
    setIsAddSocialDisable(false);
    setIsEditing(false);
  };

  const removeForm = (formKey) => {
    // console.log(formKey,"fmKey,formKey,",socialData)
    const newFormList = socialData.filter((val, index) => index !== formKey);
    // console.log(newFormList,"newF342")
    setSocialData([...newFormList]);
    // console.log(socialData, "socialData!@#!@#");

    // if (socialData.length == 0) {
    setIsAddSocialDisable(false);
    // }
  };

  const handleSelectChange = (e) => {
    const selectedValue = e.target.value;
    setData({ ...data, nameOfSocial: selectedValue });
  };

  const handleEditForm = () => {
    setIsEditing(true);
    setIsAddShow(true);
  };

  
  const handleBlur = (value, phoneData) => {
    phoneData.iso2 = phoneData.countryCode;
    let contactNumber = value.target.value;
    if(!!contactNumber){
      let val = contactNumber.split(" ");
      // let valArray = (val.length>0)?val[1]:"";
      let valArray = (val.length > 0) ? val.slice(1).join('') : "";
      let valueData = (valArray)?valArray.replace("-",""):"";
      if (!isValidPhoneNumber(valueData, phoneData)) {
        setInvalidPhoneErr(`Invalid phone number for ${phoneData.name}`);
      } else {
        setInvalidPhoneErr('');
      }
    }
  };

  return (
    <div key={`form-${formCount}`} className="col-12" id="Socialformsidepad">
      <div className="serviceboxedittime" id="SocialformsideMar">
        <i
          class="fa-solid fa-circle-xmark crossedit"
          onClick={() => removeForm(index)}
        ></i>

        <div className="">
        {/* <span onClick={handleEditForm} className="editButton">Edit</span>  */}
        <span onClick={handleEditForm} style={{padding:"2px 15px 10px 0px", cursor:"pointer",display:"flex", justifyContent:"end"}}> 
          <i class="fa-solid fa-pen-to-square"></i>
          </span>

          <div className="row mb-3" id="SocialInnerFormPaddissu">
            <div class=" col-md-12">
              <label class="form-label">Social Media Name</label>
              <select
                disabled={!isAddShow}
                name="type"
                class="form-select"
                onChange={handleSelectChange}
                value={data["nameOfSocial"]}
              >
                <option value="">Select </option>
                <option value="FaceBook">FaceBook </option>
                <option value="Twitter">Twitter</option>
                <option value="Youtube">Youtube</option>
                <option value="Gmail">Gmail</option>
                <option value="Instagram">Instagram</option>
                <option value="whatsapp">Whatsapp</option>
                <option value="linkedin">LinkedIn</option>
                {/* <option value="discord">Discord</option> */}
                <option value="telegram">Telegram</option>
                <option value="pinterest">Pinterest</option>
                {/* <option value="star">Google review</option> */}
              </select>
            </div>
            <div class=" col-md-12 mt-3">
              <label class="form-label">
                {getSocialLinkLabel(data["nameOfSocial"].toLowerCase())}
              </label>

              {data["nameOfSocial"] == "whatsapp" ? (
                <>
                  <PhoneInput
                    disabled={!isAddShow}
                    country={"in"}
                    enableSearch={true}
                    value={data["link"]}
                    onChange={(e) => {
                      setInvalidPhoneErr('');
                      setData({ ...data, link: e })
                    }}
                    onBlur={(e, country) => handleBlur(e, country)}
                    isValid={(inputNumber, country) => isValidPhoneNumber(inputNumber, country)}
                  />
                  {invalidPhoneErr && <div id="error_msg">
                                      {invalidPhoneErr}
                                    </div>}
                </>
              ) : (
                <input
                  disabled={!isAddShow}
                  class="form-control"
                  type="text"
                  placeholder="Link"
                  onChange={(e) => setData({ ...data, link: e.target.value })}
                  value={data["link"]}
                />
              )}
            </div>
          </div>
        </div>

        {isAddShow ? (
          <button
            type="button"
            // disabled={isAddDisable}
            disabled={(!data["nameOfSocial"] || !data["link"]  || (!!invalidPhoneErr)?true:false)}
            onClick={handleAddSocial}
            className="btn btn-cu addbuttoneditform editButtonTextimonial"
          >
            Add
          </button>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default SocialForm;
