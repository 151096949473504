import React, { useContext, useState } from "react";
import { userContext } from "../../context/userContext";
import axios from "axios";
import { toast } from "react-toastify";
import { environmentVariables } from "../../config/env.config";
import PageName from "../Utils/PageName";

const AddPhysicalCard = () => {
  const [frontImg, setFrontImg] = useState(null);
  const [frontImgShow, setFrontImgShow] = useState(null);

  const [backImg, setBackImg] = useState(null);
  const [backImgShow, setBackImgShow] = useState(null);

  const [thumbnail, setThumbnail] = useState(null);
  const [thumbnailShow, setThumbnailShow] = useState(null);

  const [fontFile, setFontFile] = useState(null);
  const [fontFile2, setFontFile2] = useState(null);
  const [cssFile, setCssFile] = useState(null);
  const [cssContent, setCssContent] = useState(null);
  const [isSubmit, setIsSubmit] = useState(false);
  const [selectedStyle, setSelectedStyle] = useState(null);

  const [err, setErr] = useState(null);
  const { userData } = useContext(userContext);
  const handleChangeFrontImage = (e) => {
    setFrontImg(e.target.files[0]);
    if (e.target.files[0]) {
      const reader = new FileReader();
      reader.onload = (event) => {
        setFrontImgShow(event.target.result);
      };
      reader.readAsDataURL(e.target.files[0]);
    } else {
      setFrontImgShow(null);
    }
  };
  const handleChangeBackImage = (e) => {
    setBackImg(e.target.files[0]);
    if (e.target.files[0]) {
      const reader = new FileReader();
      reader.onload = (event) => {
        setBackImgShow(event.target.result);
      };
      reader.readAsDataURL(e.target.files[0]);
    } else {
      setBackImgShow(null);
    }
  };
  const handleChangeThumbnail = (e) => {
    setThumbnail(e.target.files[0]);
    if (e.target.files[0]) {
      const reader = new FileReader();
      reader.onload = (event) => {
        setThumbnailShow(event.target.result);
      };
      reader.readAsDataURL(e.target.files[0]);
    } else {
      setThumbnailShow(null);
    }
  };
  const handleChangeStyleFile = (e) => {
    if (e.target?.files.length > 0) {
      setCssFile(e.target.files[0]);
      setSelectedStyle("file");
    } else {
      setSelectedStyle(null);
    }
  };
  const handleChangeStyleContent = (e) => {
    setCssContent(e.target.value);
    setSelectedStyle("text");
    if (e.target.value == "") {
      setSelectedStyle(null);
    }
  };
  const handleSubmit = () => {
    setIsSubmit(true);
    let data = new FormData();
    data.append("front_image", frontImg);
    data.append("back_image", backImg);
    data.append("thumbnail_image", thumbnail);
    data.append("font_file", fontFile);
    data.append("font_file2", fontFile2);
    if (!frontImg) {
      setErr("Front Image is required");
      return;
    }
    if (!backImg) {
      setErr("Back Image is required");
      return;
    }
    if (!thumbnail) {
      setErr("Thumbnail Image is required");
      return;
    }
    if (!fontFile) {
      setErr("Font File is required");
      return;
    }
    if (
      !cssFile &&
      (cssContent == null || cssContent == "" || cssContent.trim() == "")
    ) {
      setErr("css file or css text is required");
      return;
    }
    if (!cssFile && cssContent) {
      data.append("style_content", cssContent);
    }
    if (!cssContent && cssFile) {
      data.append("style_file", cssFile);
    }
    setIsSubmit(true);

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${environmentVariables?.apiUrl}api/v1/admin/createCard`,
      headers: {
        _token: userData?.token,
      },
      // withCredentials: true,
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        toast.success("Card created successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        setIsSubmit(false);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message || err?.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        setIsSubmit(false);
      });
  };
  return (
    <>
    <PageName PageName="Add Physical Card" />
      <article>
        <div class="row">
          <div class="col-md-12">
            <div class="tile">
              <article>
                <div class="row">
                  <div class="col-12 col-lg-12">
                    <div class="row">
                      <div class="col-12 col-lg-4">
                        <div class="boxcardhere">
                          <p class="sectitlecard">
                            <span>Front Image</span>
                          </p>

                          <div class="uploadimg">
                            <img
                              src={
                                frontImgShow ||
                                "https://vmycards.com/assets/card-images/Card-1/Show.png"
                              }
                              class="mx-auto d-block img-fluid"
                            />
                          </div>

                          <div class="mt-4">
                            <label for="formFile" class="form-label">
                              upload Front Image
                            </label>
                            <input
                              class="form-control"
                              type="file"
                              id="formFile"
                              onChange={handleChangeFrontImage}
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col-12 col-lg-4">
                        <div class="boxcardhere">
                          <p class="sectitlecard">
                            <span>Back Image</span>
                          </p>

                          <div class="uploadimg">
                            <img
                              src={
                                backImgShow ||
                                "https://vmycards.com/assets/card-images/Card-1/Show.png"
                              }
                              class="mx-auto d-block img-fluid"
                            />
                          </div>

                          <div class="mt-4">
                            <label for="formFile" class="form-label">
                              upload Back Image
                            </label>
                            <input
                              class="form-control"
                              type="file"
                              id="formFile"
                              onChange={handleChangeBackImage}
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col-12 col-lg-4">
                        <div class="boxcardhere">
                          <p class="sectitlecard">
                            <span>Thumbnail Image</span>
                          </p>

                          <div class="uploadimg">
                            <img
                              src={
                                thumbnailShow ||
                                "https://vmycards.com/assets/card-images/Card-1/Show.png"
                              }
                              class="mx-auto d-block img-fluid"
                            />
                          </div>

                          <div class="mt-4">
                            <label for="formFile" class="form-label">
                              upload Thumbnail Image
                            </label>
                            <input
                              class="form-control"
                              type="file"
                              id="formFile"
                              onChange={handleChangeThumbnail}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </article>

              <hr />

              <article></article>
              <article>
                <div class="row">
                  <div class="col-12 col-lg-12">
                    <p class="sectitlecard">
                      <span>Font File</span>
                    </p>

                    <div class="row">
                      <div class="col-12 col-lg-12">
                        <div class="">
                          <label for="formFile" class="form-label">
                            upload Font File
                          </label>
                          <input
                            class="form-control"
                            type="file"
                            id="formFile"
                            onChange={(e) => setFontFile(e.target.files[0])}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </article>
              <article>
                <div class="row">
                  <div class="col-12 col-lg-12">
                    <p class="sectitlecard">
                      <span>Font File 2</span>
                    </p>

                    <div class="row">
                      <div class="col-12 col-lg-12">
                        <div class="">
                          <label for="formFile" class="form-label">
                            upload Font File 2
                          </label>
                          <input
                            class="form-control"
                            type="file"
                            id="formFile"
                            onChange={(e) => setFontFile2(e.target.files[0])}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </article>
              <article>
                <div class="row">
                  <div class="col-12 col-lg-12">
                    <p class="sectitlecard">
                      <span>CSS File</span>
                    </p>

                    <div class="row">
                      <div class="col-12 col-lg-12">
                        <textarea
                          class="form-control"
                          placeholder="CSS"
                          row="10"
                          style={{ height: "150px" }}
                          value={cssContent}
                          disabled={selectedStyle == "file"}
                          onChange={(e) => handleChangeStyleContent(e)}
                        ></textarea>
                        <small className="d-block text-center mt-3">Or</small>
                        <div class="">
                          <label for="formFile" class="form-label">
                            upload Css File
                          </label>
                          <input
                            class="form-control"
                            type="file"
                            id="formFile"
                            disabled={selectedStyle == "text"}
                            onChange={(e) => handleChangeStyleFile(e)}
                          />
                        </div>
                        {err ? (
                          <div style={{ color: "red", fontSize: "14px" }}>
                            {err}
                          </div>
                        ) : (
                          <></>
                        )}
                        <button
                          onClick={handleSubmit}
                          class="btn btn-primary mt-4"
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </article>
            </div>
          </div>
        </div>
      </article>
    </>
  );
};

export default AddPhysicalCard;
