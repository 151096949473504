import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { userContext } from "../../../context/userContext";

import { businessContext } from "../../../context/businessContext";
import { businessObjContext } from "../../../context/businessObjContext";

function ThemTemplate({
  setSelectedTheme,
  setSelectThemeColor,
  setThemeColor1,
  setThemeNumber,
  isNewTheme,
  businessName,
  setBusinessName,
  isChecking,
  businessTheme,
}) {
  const [activeTab, setActiveTab] = useState({
    tab1: 1,
    tab2: 1,
    tab3: 1,
    tab4: 1,
    tab5: 1,
    tab6: 1,
    tab7: 1,
    tab8: 1,
    tab9: 1,
    tab10: 1,
    tab11: 1,
    tab14: 1,
    tab12: 1,
    tab16: 1,
    tab17: 1,
    tab15: 1,
    tab18: 1,
    tab20: 1,
    tab22: 1,
    // tab27: 1,
  });
  const [selectedCategory, setSelectedCategory] = useState("");
  const { userData, setUserData } = useContext(userContext);
  // const [businessName, setBusinessName] = useState("");
  const [selectedDiv, setSelectedDiv] = useState("div1");
  const [themeColor, setThemeColor] = useState("color1");
  const storedData = localStorage.getItem("businessObj");
  const { businessObjData, setBusinessObjData } =
    useContext(businessObjContext);
  useEffect(() => {
    let themeNumber = 1;
    //if(isChecking==null){
    if (storedData != "undefined") {
      const businessData = JSON.parse(storedData);
      const theme_color = businessData?.theme_color;
      let thN = parseInt(theme_color?.replace("theme", ""), 10);
      themeNumber = isNaN(thN) || thN === 0 ? 1 : thN;
    }
    //}
    setSelectedDiv("div" + themeNumber);
    handleDivClick("div" + themeNumber);
  }, [businessObjData]);

  const handleChangeBusinessName = (e) => {
    const alphanumericWithSpaceRegex = /^[a-zA-Z0-9\s']+$/;
    if (
      e.target.value === "" ||
      alphanumericWithSpaceRegex.test(e.target.value)
    ) {
      setBusinessName(e.target.value);
    }
  };
  const handleTabClick = (item, tab, color) => {
    // setActiveTab(tab)
    setActiveTab({
      ...activeTab,
      [`tab${item}`]: tab,
    });
    // console.log(activeTab, color);
    // console.log("VVVVVV", item, tab, "cole", color, activeTab);
    setThemeColor(color);
    setThemeNumber(`theme${item}`);
    setSelectThemeColor(color);
    // if (!isNewTheme) {
    //   setThemeColor1(color);
    //   setThemeNumber(`theme${item}`);
    // }
  };

  const handleDivClick = (divId) => {
    setSelectedTheme("theme" + divId?.toString().substring(3));
    setSelectedDiv(divId);
    if (!isNewTheme) {
      setThemeNumber(`theme${divId?.toString().substring(3)}`);
    }
  };

  const handleFetchTheme = async () => {
    try {
      const { data: res } = await axios.get(
        `${baseUrl}api/v1/business/fetchTheme`,
        {
          headers: {
            _token: userData?.token,
          },
          withCredentials: true,
        }
      );
      // console.log(res, "Res themem ");
      // toast.error("Theme is mandatory", {
      //   position: toast.POSITION.TOP_RIGHT,
      // });
    } catch (err) {
      toast.error(err, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
    }
  };

  // const data = [2, 3, 5, 4, 6, 1, 7, 9, 12];
  // const data = [2, 3, 5, 4, 6, 1, 7, 9, 12, 14, 17];
  const data = [2, 3, 5, 4, 6, 1, 7, 9, 11,12, 14, 15, 16, 17, 18, 20,22];

  const filteredData = data.filter((item) => {
    if (!selectedCategory) {   
      // If no category is selected, include all items
      return true;
    } else if (
      selectedCategory === "Salon" &&
      (item === 7 || item === 8 || item === 9)
    ) {
      return true;
    } else if (selectedCategory === "Contructor" && item === 1) {
      return true;
    } else if (selectedCategory === "Doctor" && (item === 4 || item === 18)) {
      return true;
    } else if (selectedCategory === "Art" && (item === 3 || item === 10 || item === 11)) {
      return true;
    } else if (
      selectedCategory === "Influncer" &&
      (item === 6 || item === 5 || item === 2)
    ) {
      return true;
    } else if (selectedCategory === "Googlereview" && item === 12 
    ) {
      return true;
    } else if (
      selectedCategory === "restaurant" && (item === 14)
    ) {
      return true;
    } else if (selectedCategory === "attorney" && (item === 17)){
      return true;
    } else if (selectedCategory === "journalist" && (item === 16)
    ){
      return true;
    }else if (selectedCategory === "mini_marketplace" && (item === 20)
    ){
      return true;
    } else if (selectedCategory === "chef" && (item === 15)
    ){
      return true;
    } else if (selectedCategory === "cab" && (item === 22)
    ){
      return true;
    }
    return false;
  });

  return (
    <>
      {isChecking == null ? (
        <div class="modal-body">
          <div 
          style={{
            fontSize: "10px",
            padding: "0px 15px",
            color: "red",
          }}
          >This name will be used as a subdomain, so please enter the correct business name. A maximum of 63 characters is allowed.*</div>
          
          <input
            type="text"
            className="form-control mb-2 businessinputname"
            value={businessName}
            placeholder="Enter your Business Name*"
            onChange={(e) => handleChangeBusinessName(e)}
            style={{
              boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
              margin: "5px 15px 0 15px",
              marginBottom:"0px"

            }}
          ></input>
          {isChecking == null ? (
          <div class="category-model">
            <select
              className="form-select category_item"
              onChange={(e) => setSelectedCategory(e.target.value)}
            >
              <option value="">All Category</option>
              <option value="mini_marketplace"> Mini Marketplace</option>
              <option value="restaurant"> Restaurant</option>
              <option value="cab"> Cab</option>
              <option value="Influncer"> Influencer</option>
              <option value="Googlereview"> Google review</option>
              <option value="Contructor">Real Estate & Construction</option>
              <option value="Salon">Spa & salon</option>
              <option value="Doctor">Medical</option>
              <option value="Art"> Art & Craft</option>
              <option value="journalist"> Journalist</option>
              <option value="chef"> Chef</option>
              <option value="attorney"> Attorney</option>
            </select>
          </div>
        ) : (
          <div
            style={{
              marginBottom: "20px",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <select
              className="form-select category_item"
              onChange={(e) => setSelectedCategory(e.target.value)}
              style={{ width: "250px" }}
            >
              <option value="">All Category </option>
              <option value="mini_marketplace"> Mini Marketplace</option>
              <option value="restaurant"> Restaurant</option>
              <option value="cab"> Cab</option>
              <option value="Influncer"> Influencer</option>
              <option value="Googlereview"> Google review</option>
              <option value="Contructor">Real Estate & Construction</option>
              <option value="Salon">Spa & salon</option>
              <option value="Doctor">Medical</option>
              <option value="Art"> Art & Craft</option>
              <option value="journalist"> Journalist</option>
              <option value="chef"> Chef</option>
              <option value="attorney"> Attorney</option>
        

            </select>
          </div>
        )}
          
        </div>
      ) : (
        <div>
        </div>
      )}

      {/* {isChecking == null ? (
        <div class="category-model">
          <select
            className="form-select category_item"
            onChange={(e) => setSelectedCategory(e.target.value)}
          >
            <option value="">All Category</option>
            <option value="mini_marketplace"> Mini Marketplace</option>
            <option value="restaurant"> Restaurant</option>
            <option value="cab"> Cab</option>
            <option value="Influncer"> Influencer</option>
            <option value="Googlereview"> Google review</option>
            <option value="Contructor">Real Estate & Construction</option>
            <option value="Salon">Spa & salon</option>
            <option value="Doctor">Medical</option>
            <option value="Art"> Art & Craft</option>
            <option value="journalist"> Journalist</option>
            <option value="chef"> Chef</option>
            <option value="attorney"> Attorney</option>
          </select>
        </div>
      ) : (
        <div
          style={{
            marginBottom: "20px",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <select
            className="form-select category_item"
            onChange={(e) => setSelectedCategory(e.target.value)}
            style={{ width: "250px" }}
          >
            <option value="">All Category </option>
            <option value="mini_marketplace"> Mini Marketplace</option>
            <option value="restaurant"> Restaurant</option>
            <option value="cab"> Cab</option>
            <option value="Influncer"> Influencer</option>
            <option value="Googlereview"> Google review</option>
            <option value="Contructor">Real Estate & Construction</option>
            <option value="Salon">Spa & salon</option>
            <option value="Doctor">Medical</option>
            <option value="Art"> Art & Craft</option>
            <option value="journalist"> Journalist</option>
            <option value="chef"> Chef</option>
            <option value="attorney"> Attorney</option>
      

          </select>
        </div>
      )} */}
      {filteredData &&
        filteredData.length > 0 &&
        filteredData.map((item, index) => (
          <div className="col-12 col-lg-4 mb-4" key={index}>
            <div
              id={`div${item}`}
              className={`themboxinner L502 k${item}  L${selectedDiv} ${
                selectedDiv === `div${item}` ? "selected-active" : "ddd"
              }`}
              onClick={() => handleDivClick(`div${item}`)}
            >
              <div className={`themimg ${item}`}>
                <img
                  src={`../theme/theme${item}/color${
                    activeTab[`tab${item}`]
                  }.png`}
                  className="mx-auto d-block img-fluid"
                  alt={`../theme/theme${item}/color${
                    activeTab[`tab${item}`]
                  }.png`}
                />
              </div>

              {/* <ul className="colorthem">
              <li
                style={
                  selectedDiv == `div${item}` && themeColor == `color1`
                    ? { background: "#F9D254", border: "1px solid black" }
                    : { background: "#F9D254" }
                }
                className={`tab-item ${
                  activeTab[`tab${item}`] === "tab1" ? "active" : ""
                }`}
                onClick={() => handleTabClick(item, "1", "color1")}
              ></li>
              <li
                style={
                  selectedDiv == `div${item}` && themeColor == `color2`
                    ? { background: "#8ECAE6", border: "1px solid black" }
                    : { background: "#8ECAE6" }
                }
                className={`tab-item ${
                  activeTab[`tab${item}`] === "tab2" ? "active" : ""
                }`}
                onClick={() => handleTabClick(item, "2", "color2")}
              ></li>
              <li
                style={
                  selectedDiv == `div${item}` && themeColor == `color3`
                    ? { background: "#F9D254", border: "1px solid black" }
                    : { background: "#F9D254" }
                }
                className={`tab-item ${
                  activeTab[`tab${item}`] === "tab3" ? "active" : ""
                }`}
                onClick={() => handleTabClick(item, "3", "color3")}
              ></li>
              <li
                style={
                  selectedDiv == `div${item}` && themeColor == `color4`
                    ? { background: "#FDF0D5", border: "1px solid black" }
                    : { background: "#FDF0D5" }
                }
                className={`tab-item ${
                  activeTab[`tab${item}`] === "tab4" ? "active" : ""
                }`}
                onClick={() => handleTabClick(item, "4", "color4")}
              ></li>
            </ul> */}
            </div>
          </div>
        ))}
    </>
  );
}
export default ThemTemplate;
