import React, { useEffect, useContext } from "react";
import { useState } from "react";
import TestimonialForm from "./testimonialForm";
import RestaurantMenuForm from "./restaurantMenuForm";
import { businessObjContext } from "../../../context/businessObjContext";
import MiniMarketuproductForm from "./MiniMarketuproductForm";

function NewminimarketProductCreate({handleFetchById, setMiniMarketArray,servicesArray,setMiniMarketTempArray,handleSubmitWithoutTabChange }) {
  const [formList, setFormList] = useState([]);
  const [formCount, setFormCount] = useState(0);
  const [isAddTestimonialDisable, setIsAddTestimonialDisable] = useState(false);
  const [isAddDisable, setIsAddDisable] = useState(false);
  const [restaurantData, setRestaurantData] = useState([]);
  const [data, setData] = useState({
    formCount: formCount,
    image: null,
    name: "",
    description: "",
    imageView: null,
  });
  const { businessObjData } = useContext(businessObjContext);

  const removeForm = (formKey) => {
    const newFormList = restaurantData?.filter(
      (val, index) => index !== formKey
    );
    setRestaurantData([...newFormList]);
    if (formKey == restaurantData?.length - 1) {
      setIsAddTestimonialDisable(false);
    }
  };


  useEffect(() => {
    let getTestimonialData;

    if (businessObjData && businessObjData?.miniMktProductArr) {
      getTestimonialData = (businessObjData?.miniMktProductArr);
    }

    if (getTestimonialData && getTestimonialData?.length) {
      const updatedTestimonialData = getTestimonialData.map((el, index) => ({
        ...el,
        formCount: index,
        isImageUpload: false,
      }));
      setFormList(updatedTestimonialData);
      setFormCount(updatedTestimonialData?.length);
      setRestaurantData(updatedTestimonialData);
    } else {
      setFormList([]);
      setFormCount(0);
      setRestaurantData([]);
    }
  }, [businessObjData]);

  const createForm = () => {
    setIsAddTestimonialDisable(true);
    const newFormCount = formCount + 1;
    setFormCount(newFormCount);
    const newForm = {
      formCount: newFormCount,
      image: null,
      gallery:null,
      name: "",
      price: "",
      description: "",
      category: "",
      imageView: null,
      galleryView:null,
      
      isImageUpload: true,
      quantity: "",
      service:"",
      isNew:true,
    };
    const updatedData = [...formList, newForm];
    setRestaurantData(updatedData);
  };

  useEffect(() => {
    setFormList(restaurantData);
    setMiniMarketArray(restaurantData);
    // setMiniMarketTempArray(restaurantData);
    if (restaurantData.length == 0) {
      setIsAddTestimonialDisable(false);
    }
    return () => {
      setMiniMarketArray([])
    }
  }, [restaurantData]);

  return (
    <>
      <div className="row" id="AddNewStyleForm">
        {restaurantData.map((form, index) => (
          <MiniMarketuproductForm
            formCount={formCount}
            restaurantData={restaurantData}
            setIsAddTestimonialDisable={setIsAddTestimonialDisable}
            setRestaurantData={setRestaurantData}
            removeForm={removeForm}
            index={index}
            servicesArray={servicesArray}
            handleFetchById={handleFetchById}
            handleSubmitWithoutTabChange={handleSubmitWithoutTabChange}
          ></MiniMarketuproductForm>
        ))}
      </div>
      <button
        disabled={isAddTestimonialDisable}
        type="button"
        onClick={createForm}
        className="btn btn-cu addbuttonedit"
      >
        + Add
      </button>
    </>
  );
}

export default NewminimarketProductCreate;
