import React from "react";

function SubBusinessHoursData(props) {
  let rs = props.rs;
  const businessHoursData = rs.businessHourObj
    ? JSON.parse(rs.businessHourObj.content)
    : null;
  // console.log(businessHoursData, "QAZ");

  const changeTimeFormatt = (time24) => {
    // Split the time string into hours and minutes
    const [hours, minutes] = time24.split(":").map(Number);

    // Create a Date object with today's date
    const date = new Date();

    // Set the hours and minutes to the Date object
    date.setHours(hours);
    date.setMinutes(minutes);

    // Format the time in AM/PM format
    const time12 = date.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    });

    return time12;
  };

  return (
    <>
      <article  className="businesshour sec" >
        <h3 className="sectitle business 4">Working Hour</h3>
        

        <ul className="timelist">
          {businessHoursData?.issundayDay && 
          businessHoursData?.SundayStartDate &&
          businessHoursData?.SundayEndDate && (
            <li>
              <div className="day">Sunday</div>
              <div className="colon">:</div>
              <div className="time">
                <span>
                  {changeTimeFormatt(businessHoursData?.SundayStartDate)}
                </span>{" "}
                -{" "}
                <span>
                  {changeTimeFormatt(businessHoursData?.SundayEndDate)}
                </span>
              </div>
            </li>
          )}

          {businessHoursData?.isMonday &&
          businessHoursData?.modayStartDate &&
          businessHoursData?.modayEndDate &&
           (
            <li>
              <div className="day">Monday</div>
              <div className="colon">:</div>
              <div className="time">
                <span>
                  {changeTimeFormatt(businessHoursData?.modayStartDate)}
                </span>{" "}
                -{" "}
                <span>
                  {changeTimeFormatt(businessHoursData?.modayEndDate)}
                </span>
              </div>
            </li>
          )}

          {businessHoursData?.isTuesday &&
          businessHoursData?.tuesdayStartDate &&
          businessHoursData?.tuesdayEndDate &&
           (
            <li>
              <div className="day">Tuesday</div>
              <div className="colon">:</div>
              <div className="time">
                <span>
                  {changeTimeFormatt(businessHoursData?.tuesdayStartDate)}
                </span>{" "}
                -{" "}
                <span>
                  {changeTimeFormatt(businessHoursData?.tuesdayEndDate)}
                </span>
              </div>
            </li>
          )}

          {businessHoursData?.isWednesDay &&
          businessHoursData?.wednesdayStartDate && 
          businessHoursData?.wednesdayEndDate &&
           (
            <li>
              <div className="day">Wednesday</div>
              <div className="colon">:</div>
              <div className="time">
                <span>
                  {changeTimeFormatt(businessHoursData?.wednesdayStartDate)}
                </span>{" "}
                -{" "}
                <span>
                  {changeTimeFormatt(businessHoursData?.wednesdayEndDate)}
                </span>
              </div>
            </li>
          )}

          {businessHoursData?.isthrusdayDay &&
          businessHoursData?.thrusdayStartDate &&
          businessHoursData?.thrusdayEndDate
          &&
           (
            <li>
              <div className="day">Thursday</div>
              <div className="colon">:</div>
              <div className="time">
                <span>
                  {changeTimeFormatt(businessHoursData?.thrusdayStartDate)}
                </span>{" "}
                -{" "}
                <span>
                  {changeTimeFormatt(businessHoursData?.thrusdayEndDate)}
                </span>
              </div>
            </li>
          )}

          {businessHoursData?.isfridayDay &&
          businessHoursData?.fridayStartDate &&
          businessHoursData?.fridayEndDate &&
           (
            <li>
              <div className="day">Friday</div>
              <div className="colon">:</div>
              <div className="time">
                <span>
                  {changeTimeFormatt(businessHoursData?.fridayStartDate)}
                </span>{" "}
                -{" "}
                <span>
                  {changeTimeFormatt(businessHoursData?.fridayEndDate)}
                </span>
              </div>
            </li>
          )}

          {businessHoursData?.issaturdayDay &&
          businessHoursData?.SaturdayStartDate &&
          businessHoursData?.SaturdayEndDate &&
           (
            <li>
              <div className="day">Saturday</div>
              <div className="colon">:</div>
              <div className="time">
                <span>
                  {changeTimeFormatt(businessHoursData?.SaturdayStartDate)}
                </span>{" "}
                -{" "}
                <span>
                  {changeTimeFormatt(businessHoursData?.SaturdayEndDate)}
                </span>
              </div>
            </li>
          )}
        </ul>
      </article>
    </>
  );
}
export default SubBusinessHoursData;
