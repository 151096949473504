import React from "react";
import Logo from "../../../Images/whitelogo.png";
import facebook from "../../../Images/facebook.png";
import instagram from "../../../Images/instagram.png";
import linkdin from "../../../Images/linkdin.png";
import twitter from "../../../Images/twitter.png";
import pinterest from "../../../Images/pinterest.png";
import "./Footer.css";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate(null);
  return (
    <>
      <div className="container-fluid black">
        <div className="container">
          <div className="row">
            <div className="col-md-3 first-col">
              <img src={Logo} style={{width:"191px"}}/>
              <h2>
                Get Bulk <span>Orders</span>
              </h2>
              <div
                onClick={() => navigate("/register")}
                className="blue-button"
                style={{ cursor: "pointer" }}
              >
                Get Started
              </div>
              <div className="social">
                <ul>
                  <li
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      window.open(
                        "https://www.facebook.com/profile.php?id=61551454974033",
                        "_blank"
                      )
                    }
                  >
                    <img src={facebook} />
                  </li>
                  <li
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      window.open(
                        "https://www.instagram.com/_business_bay/",
                        "_blank"
                      )
                    }
                  >
                    <img src={instagram} />
                  </li>
                  <li
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      window.open(
                        "https://www.linkedin.com/company/business-bay/?viewAsMember=true",
                        "_blank"
                      )
                    }
                  >
                    <img src={linkdin} />
                  </li>
                  <li
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      window.open("https://twitter.com/businessbay__", "_blank")
                    }
                  >
                    <img src={twitter} />
                  </li>
                  <li
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      window.open(
                        "https://in.pinterest.com/businessbay_/",
                        "_blank"
                      )
                    }
                  >
                    <img src={pinterest} />
                  </li>
                </ul>

             <div style={{width:"100%", display:"flex", alignItems:"center", textAlign:"center"}}>
             <div style={{
                margin: "50px 0 0 0",
                // fontSize: "18px",
                fontSize:"22px",
                color: "white",
                width: "100%",
              }}>
              Metaspace Technologies Pvt. Ltd.
              </div>
             </div>
              
              </div>
           
            </div>
            <div className="col-md-3 links">
              <h2>Useful Links</h2>
              <ul>
                <li
                 
                  onClick={() => navigate("/login")}
                >
                <div  style={{ cursor: "pointer",width:"max-content"}}>  Login</div>
                </li>
                <li
                  
                  onClick={() => navigate("/register")}
                >
                   <div  style={{ cursor: "pointer",width:"max-content"}}>  Register</div>
                 
                </li>
              </ul>
            </div>
            <div className="col-md-3 links">
              <h2>Links</h2>
              <ul>
                <li
                  onClick={() => {
                    navigate("/shipping");
                    window.scrollTo(0, 0)}}
                 
                >
                  {/* Shipping */}
                  <div  style={{ cursor: "pointer",width:"max-content"}}>  Shipping</div>
                </li>
                <li
                  onClick={() => {
                    navigate("/faqs");
                    window.scrollTo(0, 0)}}
              
                >
                  {/* FAQs */}
                  <div  style={{ cursor: "pointer",width:"max-content"}}>  FAQs</div>
                </li>
                <li
                  onClick={() => {
                    navigate("/privacy");
                    window.scrollTo(0, 0)}}
               
                >
                  {/* Privacy */}
                  <div  style={{ cursor: "pointer",width:"max-content"}}>  Privacy</div>
                </li>
                <li
                  onClick={() => {
                    navigate("/refund");
                     window.scrollTo(0, 0)}}
                 
                >
                  {/* Cancellation & Refund  */}
                  <div  style={{ cursor: "pointer",width:"max-content"}}> Cancellation & Refund </div>
                </li>
                <li
                  onClick={() => {
                    navigate("/term");
                    window.scrollTo(0, 0);
                  }}
                 
                >
                 {/* T&Cs */}
                 <div  style={{ cursor: "pointer",width:"max-content"}}>  T&Cs</div>
                </li>
              </ul>
            </div>
            <div className="col-md-3 links">
              <h2>Contact Info</h2>
              <ul>
                <li>
                Metaspace Labs Information Technology L.L.C <br /> UAE- Dubai
                  - Al meydan road - KML building - office 121 
                </li>

                <li>
                Metaspace Technologies Pvt. Ltd. <br />
                B-2, Block B, Sector 4, Noida, Uttar Pradesh 201301
                </li>
                

                <li>info@businessbay.io</li>
                <li>+971-565954252</li>
                <li>+91-9818867316</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="copyright">
          <div><h5>© 2024, BusinessBay. All rights reserved.</h5></div>
         <div>
         <h5
            onClick={() => window.open(`https://metaspacelab.io/`, "_blank")}
            style={{
            //   cursor: "pointer",
            //   position: "absolute",
            //   right: "20px",
            //   top: "20px",
            }}
            // className="poweredbytext"
          >
            Powered by : Metaspace Labs Information Technology L.L.C / Metaspace Technologies Pvt Ltd
          </h5>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
