import React from 'react';
import CarImage from "./../../../../Images/DefaultBMWCabrio.png";
import CarImage1 from "./../../../../Images/DefaultToyota.png";
import CarImage2 from "./../../../../Images/DefaultHondacv.png";
import CarImage3 from "./../../../../Images/HondaCrv.png";
import { environmentVariables } from "../../../../config/env.config";


const AvilableCabTab = (props) => {
  return (
    // <div className='px-2'>
    //     <div className='capitalize font-Poppins text-xl text-white font-medium'>
    //     Available Cabs
    //     </div>

    //     <div className='bg-cabcard rounded flex my-4 ' style={{justifyContent:"space-between"}}>
    //         <div className='p-2'>
    //         <div className='p-2 font-DM text-white font-medium text-lg'> BMW Cabrio </div>
    //          <div className='py-1 px-2'>   
    //             <div className='font-DM text-D0D0D0 font-medium'> Automatic </div>
    //             <div className='font-DM text-D0D0D0 font-medium'> 3 seaters </div>
    //             <div className='font-DM text-D0D0D0 font-medium'>Octane</div>
    //             </div>
    //         </div>
    //         <div style={{marginTop:"-10px"}}>
    //             <img style={{width:"240px"}} src={CarImage}/>
    //         </div>

    //     </div>


    //     <div className='bg-cabcard rounded flex my-4' style={{justifyContent:"space-between"}}>
    //         <div className='p-2'>
    //         <div className='p-2 font-DM text-white font-medium text-lg'> Toyota Camry </div>
    //          <div className='py-1 px-2'>   
    //             <div className='font-DM text-D0D0D0 font-medium'> Manual </div>
    //             <div className='font-DM text-D0D0D0 font-medium'> 4 seaters   </div>
    //             <div className='font-DM text-D0D0D0 font-medium'>Octane</div>
    //             </div>
    //         </div>
    //         <div style={{marginTop:"-10px"}}>
    //             <img style={{width:"240px"}} src={CarImage1}/>
    //         </div>
    //     </div>

    //     <div className='bg-cabcard rounded flex my-4' style={{justifyContent:"space-between"}}>
    //         <div className='p-2'>
    //         <div className='p-2 font-DM text-white font-medium text-lg'>Honda CR-V</div>
    //          <div className='py-1 px-2'>   
    //             <div className='font-DM text-D0D0D0 font-medium'> Manual </div>
    //             <div className='font-DM text-D0D0D0 font-medium'> 2 seaters </div>
    //             <div className='font-DM text-D0D0D0 font-medium'>Octane</div>
    //             </div>
    //         </div>
    //         <div style={{marginTop:"-10px"}}>
    //             <img style={{width:"240px"}} src={CarImage2}/>
    //         </div>

    //     </div>

    //     <div className='bg-cabcard rounded flex my-4' style={{justifyContent:"space-between"}}>
    //         <div className='p-2'>
    //         <div className='p-2 font-DM text-white font-medium text-lg'> Toyota Camry </div>
    //          <div className='py-1 px-2'>   
    //             <div className='font-DM text-D0D0D0 font-medium'> Manual </div>
    //             <div className='font-DM text-D0D0D0 font-medium'> 4 seaters </div>
    //             <div className='font-DM text-D0D0D0 font-medium'>Octane</div>
    //             </div>
    //         </div>
    //         <div style={{marginTop:"-10px"}}>
    //             <img style={{width:"240px"}} src={CarImage1}/>
    //         </div>

    //     </div>

    //     <div className='bg-cabcard rounded flex my-4' style={{justifyContent:"space-between"}}>
    //         <div className='p-2'>
    //         <div className='p-2 font-DM text-white font-medium text-lg'> BMW Cabrio </div>
    //          <div className='py-1 px-2'>   
    //             <div className='font-DM text-D0D0D0 font-medium'> Automatic </div>
    //             <div className='font-DM text-D0D0D0 font-medium'> 3 seaters </div>
    //             <div className='font-DM text-D0D0D0 font-medium'>Octane</div>
    //             </div>
    //         </div>
    //         <div style={{marginTop:"-10px"}}>
    //             <img style={{width:"240px"}} src={CarImage3}/>
    //         </div>

    //     </div>
    // </div>
        <div className='px-2'>
          <div className='capitalize font-Poppins text-xl text-white font-medium'>
            Available Cabs
          </div>
          {props?.cabs?.map((cab, index) => (
            <div key={index} className='bg-cabcard rounded flex my-4' style={{ justifyContent: "space-between", overflow:"hidden", height: "120px", overflow:"hidden" }}>
              <div className='p-2 name-seater'>
                <div className='p-2 font-DM text-white font-medium text-lg' style={{paddingBottom:"0px !important", fontSize:"18px"}}>{cab?.product_name}</div>
                <div className='py-1 px-2' style={{display:"flex", gap:"10px", whiteSpace: "nowrap"}}>
                  <div className='font-DM text-D0D0D0 font-medium'>{cab?.product_seator} seaters</div>
                  {/* <div className='font-DM text-D0D0D0 font-medium'>{cab?.product_description}</div> */}
                </div>
              </div>
              <div style={{ }}>
                <img style={{ width: "240px" }} src={cab?.imageView ? cab?.imageView : `${environmentVariables?.apiUrl}uploads/${cab?.image}`} alt={cab?.product_name} />
              </div>
            </div>
          ))}
        </div>
  )
}

export default AvilableCabTab