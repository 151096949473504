import React, { useState, useEffect } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import "./../Updatedcss/BusinessChangeBlockUpdatedStyle.css";
import { isValidPhoneNumber } from "../../../utils/utility";

const TermsConditionForm = ({
  formCount,
  socialData,
  setIsAddSocialDisable,
  setSocialData,
  index,
  isTypeSelected,
}) => {
  const [data, setData] = useState({
    formCount: formCount,
    key: "",
    value: "",
  });

  const [isAddDisable, setIsAddDisable] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isAddShow, setIsAddShow] = useState(false);
  const [buttonLabel, setButtonLabel] = useState("Add");
  const [inputValText, setInputValText] = useState("");
  useEffect(() => {
    let currentForm = socialData.filter((val) => val.formCount == formCount);
    if (currentForm.length > 0) {
      setData(currentForm[0]);
    }
    // console.log(currentForm,isAddShow,';;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;')
    currentForm[0]["type"] == "" && Array.isArray(currentForm[0]["text"]) && currentForm[0]["text"].length == 0 ? setIsAddShow(true) : setIsAddShow(false);
  }, [socialData]);
  const handleAddSocial = () => {
    let selectedForm = socialData.filter(
      (obj) => obj.formCount == data.formCount
    );
    if (selectedForm.length > 0) {
      const updateItem = (formCount) => {
        const index = socialData.findIndex(
          (item) => item.formCount == formCount
        );

        const updatedItems = [...socialData];
        if (index !== -1) {
          updatedItems[index] = {
            ...updatedItems[index],
            type: data.type,
            text: data.text,
          };
        }
        setSocialData(updatedItems);
      };
      updateItem(formCount);
    } else {
      setSocialData([...socialData, data]);
    }
    // setIsAddDisable(true);
    // setIsAddSocialDisable(false);
    setIsAddShow(false);
    setIsAddSocialDisable(false);
    setIsEditing(false);
    setButtonLabel("Add");
  };

  const removeForm = (formKey) => {
    const newFormList = socialData.filter((val, index) => index !== formKey);
    setSocialData([...newFormList]);
    setIsAddSocialDisable(false);
  };

  const handleSelectChange = (e) => {
    const selectedValue = e.target.value;
    setData({ ...data, type: selectedValue });
  };

  const isDisabled = (optionValue) => {
    return (
      optionValue !== data.type && isTypeSelected(optionValue)
    );
  };


  const handleEditForm = () => {
    setIsEditing(true);
    setIsAddShow(true);
    setButtonLabel("Update");
  };

  const onChangeTCText = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      if (inputValText.trim() !== '') {
        let prevArray = Array.isArray(data?.text) && data?.text.length != 0 ? data?.text : [];
        prevArray.push(inputValText)
        setData({ ...data, text: prevArray });
        setInputValText('');
      }
    }
  }
  const removeTextArray = (index) => {
    let existingArr = Array.isArray(data?.text) && [...data.text];
    existingArr.splice(index, 1);
    setData({ ...data, text: existingArr })
  }

  const onAddText = () => {
    if (inputValText.trim() !== '') {
      let prevArray = Array.isArray(data?.text) && data?.text.length !== 0 ? data?.text : [];
      prevArray.push(inputValText);
      setData({ ...data, text: prevArray });
      setInputValText(''); // Clear the input field
    }
  };

  return (
    <div key={`form-${formCount}`} className="col-12" id="Socialformsidepad">
      <div className="serviceboxedittime" id="SocialformsideMar">
        <i
          class="fa-solid fa-circle-xmark crossedit"
          onClick={() => removeForm(index)}
        ></i>

        <div className="">
          {!isAddShow && (
            <span onClick={() => handleEditForm()} style={{ cursor: "pointer", display: "flex", justifyContent: "end" }}>
              <i className="fa-solid fa-pen-to-square"></i>
            </span>
          )}

          <div className="row mb-3" id="SocialInnerFormPaddissu">
            <div class=" col-md-12">
              <label class="form-label">Type</label>
              <select
                disabled={!isAddShow}
                name="type"
                className="form-select"
                onChange={handleSelectChange}
                value={data["type"]}
              >
                <option value="">Select </option>
                <option value="Inclusions" disabled={isDisabled("Inclusions")} className={isDisabled("Inclusions") ? "disabled-option" : ""} >
                  Inclusions
                </option>
                <option value="Exclusions" disabled={isDisabled("Exclusions")} className={isDisabled("Exclusions") ? "disabled-option" : ""} >
                  Exclusions
                </option>
                <option value="T&C" disabled={isDisabled("T&C")} className={isDisabled("T&C") ? "disabled-option" : ""} >
                  T&C
                </option>
              </select>
            </div>


            <div class=" col-md-12 mt-3" style={{position: "relative"}}>
              <label class="form-label">
                Text
              </label>
              <textarea
                disabled={!isAddShow}
                class="form-control"
                placeholder="T&C"
                onChange={(e) => setInputValText(e.target.value)}
                // onKeyDown={(e) => onChangeTCText(e)}
                value={inputValText}
              />
              <button
                disabled={!isAddShow || inputValText.trim() === ''}
                class="btn btn-cu plsbutton"
                onClick={onAddText}
              >
                +
              </button>
            </div>
            <div class="col-md-12 mt-3">
              {Array.isArray(data?.text) && (
                <ul>
                  {data?.text.length > 0 && data.text.map((val, index) => (
                    <div key={index} className="crosstext">
                      {isAddShow && (
                        <i
                          class="fa-solid fa-circle-xmark"
                          onClick={() => removeTextArray(index)}
                        ></i>
                      )}
                      <li>{val}</li>
                    </div>
                  ))}
                </ul>
              )}
            </div>
          </div>
        </div>

        {isAddShow ? (
          <button
            type="button"
            // disabled={isAddDisable}
            disabled={!data["type"] || !data["text"].length}
            onClick={handleAddSocial}
            className="btn btn-cu addbuttoneditform editButtonTextimonial"
          >
            {buttonLabel}
          </button>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default TermsConditionForm;
