import React from "react";
import "./SecondSection.css";
import "./TemplatesSection.css";
import Slides from "../../../Images/slides.png";
import { useNavigate } from "react-router-dom";

const TemplatesSection = ({userData}) => {
  const navigate = useNavigate(null);


  const handleClick = () => {
    if (userData) {
      navigate("/business?create=1");
    } else {
      navigate("/register");
    }
  };

  // console.log("1111111",userData)
  return (
    <>
      <div className="template-sec">
        <div className="container">
          <div className="row align-center">
            <div className="col-md-7 relative">
              <img src={Slides} />
            </div>
            <div className="col-md-5 second-text">
              <h2>We offer a wide variety of templates & styles</h2>
              <h5>
              By providing cutting-edge networking solutions, we empower business through connectivity and innovation.
              </h5>
              <div
                style={{ cursor: "pointer" }}
                // onClick={() => navigate("/register")}
                onClick={handleClick}
                className="blue-button"
              >
                Choose Your Style
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TemplatesSection;
