import React, { useRef, useContext, useEffect, useState } from "react";
import styled from "styled-components";
import {
  Container,
  LeftSideBar,
  NameWrapper,
  NamedesignationWrapper,
  RightSideBar,
  Root,
  Name,
  DesignationWrapper,
  Designation,
  ContactContainerWrapper,
  ContactHeadingWrapper,
  ContactHeading,
  PhoneMailmapLinWrapper,
  PhoneIconValue,
  Wrapper,
  LineWrapper,
  Line,
  ContactContainerWrapperFirst,
  KeySkillsUl,
  KeySkillsli,
  CertificationWrapper,
  CertificationName,
  CertificationFrom,
  CertificationDate,
  ProfileLeftColorWrapper,
  ProfilecontainerWrapper,
  ProfileLeftColor,
  ProfileRightTextWrapper,
  ProfileHeadingWrapper,
  ProfileHeading,
  ProfileSummary,
  ProfilecontainerWrapperWithLine,
  RightLineWrapper,
  RightLine,
  WorkExperienceDateCompContainer,
  DesignationCompanyNameLocWrapper,
  CompanyDesignationName,
  DesignationCompanyName,
  DesignationCompanyDate,
  DesignationCompanyDateWrapper,
  WorkExperienceDateCompContainerWithSummary,
  WorkExpSummaryWrapper,
  WrkSummary,
  ProjectLinkWrapper,
  ProjectLink,
  AchivementsWrapperUl,
  ActivementsList,
  PersonalInformationWrapper,
  PersonalInformationKey,
  PersonalInformationValue,
  PersonalInformationWrapperContainer,
  RightLineWrapperEXt,
  RightLineEXt,
} from "./Resume3ExtStyle";
import LinkedInIcon from "./../../../Images/linkedIn3.png";
import PhoneIcons from "./../../../Images/resphone3.png";
import MailIcon from "./../../../Images/mail3.png";
import MapIcon from "./../../../Images/map3.png";
import LinkedInIcon768 from "./../../../Images/LinkedIn768.png";
import PhoneIcons768 from "./../../../Images/phone768.png";
import MailIcon768 from "./../../../Images/mail768.png";
import MapIcon768 from "./../../../Images/map768.png";
import { userContext } from "../../../context/userContext";
import ShareVector from "./../../../Images/BlackShare.png";
import { IconContainer, Power, PowerSpan, PoweredWrapper, ShareButtonWrapper, ShareIcon, SingleIcon, SingleIconWrapper } from "./StyleThemejs";
import { EmailIcon, LinkedinIcon, TelegramIcon, WhatsappIcon } from "react-share";

export const PhoneIcon = styled.img`
  width: 24px;
  height: fit-content;
`;

const PoweredWrapper3 = styled.div`
// margin: 45px 0 35px 0;
padding: 35px 0;
display:flex;
justify-content: center;
align-items: center;
text-align: center;
background-color: #fff;
margin: 0 20px;
`;
const Power3 = styled.div`
color: #A3A3A3;
font-size: 14px;
font-family: "Be Vietnam Pro", sans-serif;
font-weight: 400;
`;


const Resume3Ext = (props) => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const { userData } = useContext(userContext);
  const [educationData, setEducationData] = useState([]);
  const [projectData, setProjectData] = useState([]);
  const [certificateData, setCertificateData] = useState([]);
  const [skillData, setSkillData] = useState([]);
  const [additionalData, setAdditionalData] = useState([]);
  const [achievementData, setAchievementData] = useState([]);
  const [workExpData, setworkExpData] = useState([]);
  const [toolData, settoolData] = useState([]);
  const [language, setLanguage] = useState([]);
  const [showShareOptions, setShowShareOptions] = useState(false);

  const iconContainerRef = useRef(null);

  useEffect(() => {
    if (props?.resumeData?.educationcontent) {
      setEducationData(JSON.parse(props.resumeData.educationcontent));
    }
    if (props?.resumeData?.projectcontent) {
      setProjectData(JSON.parse(props.resumeData.projectcontent));
    }
    if (props?.resumeData?.certificationcontent) {
      setCertificateData(JSON.parse(props.resumeData.certificationcontent));
    }
    if (props?.resumeData?.skillscontent) {
      setSkillData(JSON.parse(props.resumeData.skillscontent));
    }
    if (props?.resumeData?.additionaldetailscontent) {
      setAdditionalData(JSON.parse(props.resumeData.additionaldetailscontent));
    }
    if (props?.resumeData?.toolscontent) {
      settoolData(JSON.parse(props.resumeData.toolscontent));
    }
    if (props?.resumeData?.languagecontent) {
      setLanguage(JSON.parse(props.resumeData.languagecontent));
    }
    if (props?.resumeData?.wrkExpcontent) {
      setworkExpData(JSON.parse(props.resumeData.wrkExpcontent));
    }
    if (props?.resumeData?.achievementcontent) {
      setAchievementData(JSON.parse(props.resumeData.achievementcontent));
    }
  }, [props.resumeData]);

  const updateScreenWidth = () => {
    setScreenWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", updateScreenWidth);
    return () => {
      window.removeEventListener("resize", updateScreenWidth);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (iconContainerRef.current && !iconContainerRef.current.contains(event.target)) {
      setShowShareOptions(false);
    }
  };
  useEffect(() => {
      document.addEventListener("click", handleClickOutside, false);
      return () => {
        document.removeEventListener("click", handleClickOutside, false);
      };
  }, []);

  const formatDate = (dateString) => {
    if (!dateString) return ""; // Return empty string if dateString is falsy

    const date = new Date(dateString); // Parse the date string
    const options = { month: "long", year: "numeric" }; // Format options
    return date.toLocaleDateString("en-US", options); // Format date to "Month-YYYY" format
  };

  const handleShare = () => {
    setShowShareOptions(!showShareOptions);
  };


  const handleShareOnPlatform = (platform) => {
    const resumePreviewLink = window.location.href;
    const message = "Check out my resume!";
    const fullMessage = `${message} ${resumePreviewLink}`;
    let shareUrl = '';

    switch (platform) {
      case 'linkedin':
        // shareUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(resumePreviewLink)}`;
        // break;
        navigator.clipboard.writeText(fullMessage).then(() => {
          alert("Message copied to clipboard! Please paste it in LinkedIn messages.");
          window.open("https://www.linkedin.com/messaging", "_blank"); // Opens LinkedIn Messaging
        }).catch(err => {
          console.error('Could not copy text: ', err);
        });
        break;
      case 'whatsapp':
        shareUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(resumePreviewLink)}`;
        break;
      case 'telegram':
        shareUrl = `https://t.me/share/url?url=${encodeURIComponent(resumePreviewLink)}`;
        break;
      case 'email':
        shareUrl = `mailto:?subject=Checkoutmyresume&body=${encodeURIComponent(resumePreviewLink)}`;
        // setShowEmailShare(true);
        break;
      // case 'facebook':

      //   break;
      default:
        break;
    }

    if (shareUrl) {
      window.open(shareUrl, "_blank");
    }
  };


  const renderSkills = () => {
    if (skillData?.length) {
      return (
        <ProfilecontainerWrapperWithLine>
          <ContactContainerWrapper>
            <ContactHeadingWrapper>
              <ContactHeading>Key Skills</ContactHeading>
            </ContactHeadingWrapper>
            <KeySkillsUl>
              {skillData.map((skill, index) => (
                <KeySkillsli key={index}>{skill.skillname}</KeySkillsli>
              ))}
            </KeySkillsUl>
          </ContactContainerWrapper>
          <RightLineWrapperEXt>
            <RightLineEXt />
          </RightLineWrapperEXt>
        </ProfilecontainerWrapperWithLine>
      );
    } else {
      return null;
    }
  };

  const renderPrsnldetails = () => {
    return props?.resumeData?.is_prsnldetail_is_enabled === 1 ? (
      <ProfilecontainerWrapper>
        <ProfileLeftColorWrapper>
          <ProfileLeftColor PersonalInformation />
        </ProfileLeftColorWrapper>
        <ProfileRightTextWrapper>
          <ProfileHeadingWrapper>
            <ProfileHeading>PERSONAL INFORMATION</ProfileHeading>
          </ProfileHeadingWrapper>
          <PersonalInformationWrapperContainer>
            <PersonalInformationWrapper>
              {props?.resumeData?.fathername && (
              <PersonalInformationKey>
                Father/ Spouse Name:
              </PersonalInformationKey>
              )}
              <PersonalInformationValue>
               {props?.resumeData?.fathername}
              </PersonalInformationValue>
            </PersonalInformationWrapper>

            <PersonalInformationWrapper>
              {props?.resumeData?.maritalstatus && (
              <PersonalInformationKey>Marital Status:</PersonalInformationKey>
              )}
              <PersonalInformationValue>
                {props?.resumeData?.maritalstatus}
              </PersonalInformationValue>
            </PersonalInformationWrapper>

            <PersonalInformationWrapper>
              {props?.resumeData?.userdob && (
              <PersonalInformationKey>DOB:</PersonalInformationKey>
              )}
              <PersonalInformationValue>
                {props?.resumeData?.userdob}
              </PersonalInformationValue>
            </PersonalInformationWrapper>
          </PersonalInformationWrapperContainer>
        </ProfileRightTextWrapper>
      </ProfilecontainerWrapper>
    ) : null;
  };

  const renderAchievement = () => {
    if (achievementData?.length) {
      return (
        <ProfilecontainerWrapperWithLine>
          <ProfilecontainerWrapper>
            <ProfileLeftColorWrapper>
              <ProfileLeftColor Achievements />
            </ProfileLeftColorWrapper>
            <ProfileRightTextWrapper>
              <ProfileHeadingWrapper>
                <ProfileHeading>Achievements</ProfileHeading>
              </ProfileHeadingWrapper>
              <WorkExperienceDateCompContainerWithSummary>
                <AchivementsWrapperUl>
                  {achievementData.map((details, index) => (
                    <ActivementsList key={index}>
                      {details.skillname}
                    </ActivementsList>
                  ))}
                </AchivementsWrapperUl>
              </WorkExperienceDateCompContainerWithSummary>
            </ProfileRightTextWrapper>
          </ProfilecontainerWrapper>
          <RightLineWrapper>
            <RightLine />
          </RightLineWrapper>
        </ProfilecontainerWrapperWithLine>
      );
    } else {
      return null;
    }
  };

  const renderProjects = () => {
    if (projectData?.length) {
      return (
        <ProfilecontainerWrapperWithLine>
          <ProfilecontainerWrapper>
            <ProfileLeftColorWrapper>
              <ProfileLeftColor Project />
            </ProfileLeftColorWrapper>
            <ProfileRightTextWrapper>
              <ProfileHeadingWrapper>
                <ProfileHeading>PROJECT</ProfileHeading>
              </ProfileHeadingWrapper>
              {projectData.map((project, index) => (
                <WorkExperienceDateCompContainerWithSummary key={index}>
                  <WorkExperienceDateCompContainer>
                    <DesignationCompanyNameLocWrapper>
                      <CompanyDesignationName>
                        {project.projectname}
                      </CompanyDesignationName>
                      <DesignationCompanyDate>
                        ({formatDate(project.startDate)?.substr(0, 3)}{" "}
                        {formatDate(project.startDate)?.substr(-4)} -{" "}
                        {project.currentlyWorking
                          ? "Present"
                          : `${formatDate(project.endDate)?.substr(
                            0,
                            3
                          )} ${formatDate(project.endDate)?.substr(-4)}`}
                        )
                      </DesignationCompanyDate>
                    </DesignationCompanyNameLocWrapper>
                  </WorkExperienceDateCompContainer>
                  <WorkExpSummaryWrapper>
                    <WrkSummary>{project.projectdescription}</WrkSummary>
                  </WorkExpSummaryWrapper>
                  <ProjectLinkWrapper>
                    <ProjectLink href={project.projectlink} target="_blank">
                      {project.projectlink}
                    </ProjectLink>
                  </ProjectLinkWrapper>
                </WorkExperienceDateCompContainerWithSummary>
              ))}
            </ProfileRightTextWrapper>
          </ProfilecontainerWrapper>

          <RightLineWrapper>
            <RightLine />
          </RightLineWrapper>
        </ProfilecontainerWrapperWithLine>
      );
    } else {
      return null;
    }
  };

  const renderCertifications = () => {
    if (certificateData?.length) {
      return (
        <ContactContainerWrapperFirst>

          <ContactContainerWrapper >
            <ContactHeadingWrapper>
              <ContactHeading>Certifications</ContactHeading>
            </ContactHeadingWrapper>
            {certificateData.map((certificate, index) => (
              <CertificationWrapper key={index} style={{ margin: "20px 0" }}>
                <CertificationName>
                  {certificate.certificatename}
                </CertificationName>
                <CertificationFrom>
                  {certificate.organisationname}
                </CertificationFrom>
                <CertificationDate>
                  {formatDate(certificate.date)}
                </CertificationDate>
              </CertificationWrapper>
            ))}
          </ContactContainerWrapper>

          <LineWrapper>
            <Line />
          </LineWrapper>
        </ContactContainerWrapperFirst>
      );
    } else {
      return null;
    }
  };

  const renderWorkExperience = () => {
    if (props?.resumeData?.is_wrkExp_is_enabled === 1 && workExpData?.length) {
      return (
        <ProfilecontainerWrapperWithLine>
          <ProfilecontainerWrapper>
            <ProfileLeftColorWrapper>
              <ProfileLeftColor WorkExperience />
            </ProfileLeftColorWrapper>
            <ProfileRightTextWrapper>
              <ProfileHeadingWrapper>
                <ProfileHeading>Work Experience</ProfileHeading>
              </ProfileHeadingWrapper>
              {workExpData.map((exp, index) => (
                <WorkExperienceDateCompContainerWithSummary key={index}>
                  <WorkExperienceDateCompContainer>
                    <DesignationCompanyNameLocWrapper>
                      <CompanyDesignationName>
                        {exp.jobtitle}
                      </CompanyDesignationName>
                      <DesignationCompanyName>
                        {exp.companyname}
                      </DesignationCompanyName>
                    </DesignationCompanyNameLocWrapper>
                    <DesignationCompanyDateWrapper>
                      <DesignationCompanyDate>
                        (
                        {`${formatDate(exp.startdate)?.substr(
                          0,
                          3
                        )} ${formatDate(exp.startdate)?.substr(-4)} - ${exp.currentlyWorking
                            ? "Present"
                            : `${formatDate(exp.enddate)?.substr(
                              0,
                              3
                            )} ${formatDate(exp.enddate)?.substr(-4)}`
                          }`}
                        )
                      </DesignationCompanyDate>
                    </DesignationCompanyDateWrapper>
                  </WorkExperienceDateCompContainer>
                  <WorkExpSummaryWrapper>
                    <WrkSummary>{exp.workSummary}</WrkSummary>
                  </WorkExpSummaryWrapper>
                </WorkExperienceDateCompContainerWithSummary>
              ))}
            </ProfileRightTextWrapper>
          </ProfilecontainerWrapper>

          <RightLineWrapper>
            <RightLine />
          </RightLineWrapper>
        </ProfilecontainerWrapperWithLine>
      );
    } else {
      return null;
    }
  };

  const renderEducation = () => {
    if (
      props?.resumeData?.is_education_is_enabled === 1 &&
      educationData?.length
    ) {
      return (
        <ProfilecontainerWrapperWithLine>
          <ProfilecontainerWrapper>
            <ProfileLeftColorWrapper>
              <ProfileLeftColor Education />
            </ProfileLeftColorWrapper>
            <ProfileRightTextWrapper>
              <ProfileHeadingWrapper>
                <ProfileHeading>Education</ProfileHeading>
              </ProfileHeadingWrapper>
              {educationData.map((item, index) => (
                <WorkExperienceDateCompContainerWithSummary key={index}>
                  <WorkExperienceDateCompContainer>
                    <DesignationCompanyNameLocWrapper>
                      <CompanyDesignationName>
                        {item.degree}
                      </CompanyDesignationName>
                      <DesignationCompanyName>
                        {item.schuniname}
                      </DesignationCompanyName>
                      <DesignationCompanyName>
                        {item.boardname}
                      </DesignationCompanyName>
                      <DesignationCompanyName>
                        {item.location}
                      </DesignationCompanyName>
                    </DesignationCompanyNameLocWrapper>
                    <DesignationCompanyDateWrapper>
                      <DesignationCompanyDate>
                        (
                        {`${formatDate(item.graduationyear)?.substr(
                          0,
                          3
                        )} ${formatDate(item.graduationyear)?.substr(-4)} - ${item.currentlyWorking
                            ? "Present"
                            : `${formatDate(item.graduationendyear)?.substr(
                              0,
                              3
                            )} ${formatDate(item.graduationendyear)?.substr(
                              -4
                            )}`
                          }`}
                        )
                      </DesignationCompanyDate>
                    </DesignationCompanyDateWrapper>
                  </WorkExperienceDateCompContainer>
                </WorkExperienceDateCompContainerWithSummary>
              ))}
            </ProfileRightTextWrapper>
          </ProfilecontainerWrapper>

          <RightLineWrapper>
            <RightLine />
          </RightLineWrapper>
        </ProfilecontainerWrapperWithLine>
      );
    } else {
      return null;
    }
  };

  const renderTools = () => {
    if (props?.resumeData?.is_tools_is_enabled === 1 && toolData?.length) {
      return (
        <ContactContainerWrapperFirst>
          <ContactContainerWrapper>
            <ContactHeadingWrapper>
              <ContactHeading>Tools</ContactHeading>
            </ContactHeadingWrapper>
            <KeySkillsUl>
              {toolData.map((tool, index) => (
                <KeySkillsli key={index}>{tool.toolname}</KeySkillsli>
              ))}
            </KeySkillsUl>
          </ContactContainerWrapper>
          <LineWrapper>
            <Line />
          </LineWrapper>
        </ContactContainerWrapperFirst>
      );
    } else {
      return null;
    }
  };

  const renderLanguages = () => {
    if (props?.resumeData?.is_language_is_enabled === 1 && language?.length) {
      return (
        <ContactContainerWrapperFirst>
          <ContactContainerWrapper>
            <ContactHeadingWrapper>
              <ContactHeading>Languages</ContactHeading>
            </ContactHeadingWrapper>
            <KeySkillsUl>
              {language.map((lang, index) => (
                <KeySkillsli key={index}>{lang.languagename}</KeySkillsli>
              ))}
            </KeySkillsUl>
          </ContactContainerWrapper>
          <LineWrapper>
            <Line />
          </LineWrapper>
        </ContactContainerWrapperFirst>
      );
    } else {
      return null;
    }
  };

  const renderAdditionalDetail = () => {
    if (additionalData?.length) {
      return (
        <ContactContainerWrapperFirst>
          <ContactContainerWrapper>
            <ContactHeadingWrapper>
              <ContactHeading>Hobbies</ContactHeading>
            </ContactHeadingWrapper>
            <KeySkillsUl>
              {additionalData.map((hobby, index) => (
                <KeySkillsli key={index}>{hobby.skillname}</KeySkillsli>
              ))}
            </KeySkillsUl>
          </ContactContainerWrapper>
        </ContactContainerWrapperFirst>
      );
    } else {
      return null;
    }
  };

  return (
    <Root>
      <Container>
        <Wrapper>
          <LeftSideBar >
            <NamedesignationWrapper style={{ paddingTop: "15px" }}>
              <NameWrapper>
                <Name>{props?.resumeData?.fullName}</Name>
              </NameWrapper>
              <DesignationWrapper>
                <Designation> {props?.resumeData?.designation}</Designation>
              </DesignationWrapper>
            </NamedesignationWrapper>
            <ContactContainerWrapperFirst>
              <ContactContainerWrapper>
                <ContactHeadingWrapper>
                  <ContactHeading>contact info</ContactHeading>
                </ContactHeadingWrapper>
                <PhoneMailmapLinWrapper>
                  <PhoneIcon
                    src={screenWidth <= 768 ? PhoneIcons768 : PhoneIcons}
                  />
                  <PhoneIconValue>{props?.resumeData?.phone}</PhoneIconValue>
                </PhoneMailmapLinWrapper>

                <PhoneMailmapLinWrapper>
                  <PhoneIcon
                    src={screenWidth <= 768 ? MailIcon768 : MailIcon}
                  />
                  <PhoneIconValue>{props?.resumeData?.email}</PhoneIconValue>
                </PhoneMailmapLinWrapper>
                {props?.resumeData?.city && (
                <PhoneMailmapLinWrapper>
                  <PhoneIcon src={screenWidth <= 768 ? MapIcon768 : MapIcon} />
                  <PhoneIconValue>{props?.resumeData?.city}</PhoneIconValue>
                </PhoneMailmapLinWrapper>
                )}
                {props?.resumeData?.linkedIn && (
                <PhoneMailmapLinWrapper>
                  <PhoneIcon
                    src={screenWidth <= 768 ? LinkedInIcon768 : LinkedInIcon}
                  />
                
                  <a
                    href={props?.resumeData?.linkedIn}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <PhoneIconValue LinkedInLinkLength>
                      {props?.resumeData?.linkedIn}
                    </PhoneIconValue>
                  </a>
                 
                </PhoneMailmapLinWrapper>
                )}
              </ContactContainerWrapper>
              <LineWrapper>
                <Line />
              </LineWrapper>
            </ContactContainerWrapperFirst>

            {props?.resumeData?.is_skills_is_enabled === 1 && renderSkills()}
            {props?.resumeData?.is_tools_is_enabled === 1 && renderTools()}

            {props?.resumeData?.is_certification_is_enabled === 1 &&
              renderCertifications()}
            {props?.resumeData?.is_language_is_enabled === 1 &&
              renderLanguages()}

            {props?.resumeData?.isEnabledAdddetails === 1 &&
              renderAdditionalDetail()}
          </LeftSideBar>
          <RightSideBar style={{ position: "relative" }}>
            <ShareButtonWrapper style={{}} onClick={handleShare} ref={iconContainerRef}>
              <ShareIcon src={ShareVector} />
            </ShareButtonWrapper>
            {showShareOptions && (
              <IconContainer className="share-options-modal" style={{ background: "#F8F8EC" }}>
                <SingleIconWrapper className="share-options-list">
                  <SingleIcon onClick={() => handleShareOnPlatform('telegram')}>
                    <TelegramIcon size={35} round />
                  </SingleIcon>
                  <SingleIcon onClick={() => handleShareOnPlatform('email')}>
                    <EmailIcon size={35} round />
                  </SingleIcon>
                  <SingleIcon onClick={() => handleShareOnPlatform('linkedin')}>
                    <LinkedinIcon size={35} round />
                  </SingleIcon>
                  <SingleIcon onClick={() => handleShareOnPlatform('whatsapp')}>
                    <WhatsappIcon size={35} round />
                  </SingleIcon>
                </SingleIconWrapper>
              </IconContainer>
            )}
            <ProfilecontainerWrapperWithLine style={{ paddingTop: "15px" }}>

              <ProfilecontainerWrapper>
                <ProfileLeftColorWrapper>
                  <ProfileLeftColor />
                </ProfileLeftColorWrapper>
                {props?.resumeData?.summary && (
                <ProfileRightTextWrapper>
                  <ProfileHeadingWrapper>
                    <ProfileHeading>Profile</ProfileHeading>
                  </ProfileHeadingWrapper>
                  <ProfileHeadingWrapper>
                    <ProfileSummary>
                      {props?.resumeData?.summary}
                    </ProfileSummary>
                  </ProfileHeadingWrapper>
                </ProfileRightTextWrapper>
                )}
              </ProfilecontainerWrapper>
              <RightLineWrapper>
                <RightLine />
              </RightLineWrapper>
            </ProfilecontainerWrapperWithLine>

            {props?.resumeData?.is_wrkExp_is_enabled === 1 &&
              renderWorkExperience()}

            {props?.resumeData?.is_project_is_enabled === 1 && renderProjects()}

            {props?.resumeData?.is_education_is_enabled === 1 &&
              renderEducation()}

            {props?.resumeData?.is_achievement_is_enabled === 1 &&
              renderAchievement()}

            <ProfilecontainerWrapperWithLine>
              {props?.resumeData?.is_prsnldetail_is_enabled === 1 &&
                renderPrsnldetails()}
            </ProfilecontainerWrapperWithLine>
          </RightSideBar>

        </Wrapper>
        <PoweredWrapper3>
          <Power3>
            Powered by <PowerSpan>BusinessBay</PowerSpan>
          </Power3>
        </PoweredWrapper3>
      </Container>
    </Root>
  );
};

export default Resume3Ext;
