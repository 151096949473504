import React, { useContext,useState, useEffect, useRef } from "react";
import { environmentVariables } from "../../../config/env.config";
import testimonialdefault from "../../../Images/testimonialdefault.png";
import avatarImage from "../../../Images/minimarketproducticon.png";
import "./../Updatedcss/BusinessChangeBlockUpdatedStyle.css";
import { useFormik } from "formik";
import * as Yup from "yup";
import styled from "styled-components";
import { currencyContext } from "../../../context/currencyContext.js";
import axios from "axios";
import Swal from "sweetalert2"; 
import { toast } from "react-toastify";
import { businessObjContext } from "../../../context/businessObjContext.js";
import { userContext } from "../../../context/userContext.js";

const FormikErrorStyle = styled.div`
font-size:11px;
color: #ff3333;
text-align: justify;
    padding-bottom: 10px;
`;


const MiniMarketuproductForm = ({
  formCount,
  restaurantData,
  setIsAddTestimonialDisable,
  setRestaurantData,
  handleFetchById,
  handleSubmitWithoutTabChange,
  index,
  removeForm,
  servicesArray
}) => {
  const fileInputRef = useRef();
  const selectElementRef = useRef();
  const openFileInput = () => {
    fileInputRef.current.click();
  };
  const { businessObjData } = useContext(businessObjContext);
  const { userData, setUserData } = useContext(userContext);

  const baseUrl = environmentVariables?.apiUrl;
  const [image, setImage] = useState(null);
  const [imgGalleryArrayPreview,setImgGalleryArrayPreview]=useState([]);
  const [imgGalleryArray,setImgGalleryArray]=useState([]);
const [productId,setProductId]=useState(null);
  const [addBtnDisable, setAddBtnDisable] = useState(false);
  const { currencyObjData } = useContext(currencyContext);

  const [data, setData] = useState({
    formCount: formCount,
    image: null,
    name: "",
    price: "not available",
    description:"",
    category: "enquiry",
    imageView: null,
    isImageUpload: true,
    quantity: "",
    service:""
  });


  const handleGalleryImages=(event)=>{
const files=Array.from(event.target.files);
const filePreviews=files.map((file)=>URL.createObjectURL(file))
const allImagePreviews=[...imgGalleryArrayPreview,...filePreviews]
setImgGalleryArrayPreview(allImagePreviews);
setImgGalleryArray([...imgGalleryArray,...files])
// console.log(imgGalleryArray,'------------------------------------>>>>>>>>>>>>>>>>>>..')
  }
  const handleEditProduct=()=>{
    setData({ ...data, isImageUpload: true });
    setImage(restaurantData[index]?.image);
    setAddBtnDisable(false);
    setProductId(restaurantData[index]?.id)
  }
  const getImage = (data) => {
    if (data["imageView"]) {
      return data["imageView"];
    } else {
      if (data["image"]) {
        if (data["image"] != "null") {
          return `${baseUrl}uploads/${data["image"]}`;
        } else {
          return testimonialdefault;
        }
      } else {
        return avatarImage;
      }
    }
  };

  const validationSchema = Yup.object({
    name: Yup.string()
      .matches(/^[a-zA-Z0-9 ,.?\/()&@$'_\-\\"]*$/, "Only ‘,.?-_/@$&’ characters are allowed")
      .required("Name is required")
      .min(2, "Minimum 2 characters"),
    description: Yup.string()
    .matches(/^[a-zA-Z0-9 ,.?\/()&@$'_\-\\"]*$/, "Only ‘,.?-_/@$&’ characters are allowed"),
    category: Yup.string(),
  });

  const formik = useFormik({
    initialValues: {
      name: data.name || "",
      price: data.price || "not available",
      category: data.category || "enquiry",
      quantity: data.quantity || "",
      service: data.service || "",
      description: data.description || "",
    },
    validationSchema: validationSchema,
    enableReinitialize: true, 
    onSubmit: (values) => {
      // handleAddService(values);
    },
  });

  const handleAddService = (values) => {
    // console.log("values====>>>>", values); 
    setAddBtnDisable(true);
    const newRestaurantData = restaurantData.map((val, index) => {
      if (val.formCount == formCount) {
        val.name = values?.name;
        val.price = values?.price;
        val.category = values?.category;
        val.quantity = values?.quantity;
        val.image = image;
        val.imageView = data?.imageView;
        val.isImageUpload = data?.isImageUpload;
        val.service = values?.service;
        val.description = values?.description;
        return val;
      }
      return val;
    });
    setRestaurantData(newRestaurantData);

let formData = new FormData();
if(!!image){
  if(typeof image!="string"){
    for (let i = 0; i < image.length; i++) {
      formData.append("productImage", image[i], image[i].name);
    }
  }else {
    formData.append("productImage",image);
  }
}

let gallery=imgGalleryArray;
let count=0;
for (let i = 0; i < gallery.length; i++) {
  if(typeof gallery[i]=='string'){
    formData.append(`existingGallery[${count}]`,gallery[i]);
    count++;
  }else{
    formData.append(`productGallery`, gallery[i], gallery[i].name);
  }
}

// data.append('productGallery', fs.createReadStream('/C:/Users/user/Downloads/Telegram Desktop/67.png'));
// data.append('productGallery', fs.createReadStream('/C:/Users/user/Downloads/Telegram Desktop/1213 (2).png'));
formData.append('productName', values?.name);
if(productId){
  formData.append("id", productId);
}
formData.append('productPrice', values?.price||"Not available");
formData.append('productQuantity',values?.quantity||1);
formData.append('productDesc', values?.description);
formData.append('productCategory', 'enquiry');
formData.append('businessId', businessObjData?.id);
formData.append('productService', values?.service);

let config = {
  method: 'post',
  maxBodyLength: Infinity,
  url: `${environmentVariables?.apiUrl}api/v1/business/addMinimktProduct`,
  data : formData,
  headers: {
    _token: userData?.token,
  },
};

axios.request(config)
.then((response) => {
  toast.success("Product Added successfully", {
    position: toast.POSITION.TOP_RIGHT,
    autoClose: 1000,
  });
  handleSubmitWithoutTabChange('productadd')
  let currentForm = restaurantData[index];
    // handleFetchById(businessObjData?.id)
    setData({
      name: currentForm?.product_name||currentForm?.name,
      price: currentForm?.product_price||currentForm?.price,
      category: currentForm?.product_category||currentForm?.category,
      quantity: currentForm?.product_quantity||currentForm?.quantity,
      image: currentForm?.image,
      gallery:currentForm?.image,
      imageView: currentForm?.imageView,
      formCount: index,
      isImageUpload: currentForm?.isImageUpload,
      service: currentForm?.product_service||currentForm?.service,
      description: currentForm?.description||currentForm?.product_description,
    });
    setTimeout(() => {
      handleFetchById(businessObjData?.id);
      setIsAddTestimonialDisable(false);
    }, 2000);
})
.catch((error) => {
  toast.error(error?.response?.data?.message||error?.message)
  console.log(error);
});

  };

  const handleChangeImage = (e) => {
    setImage(e.target.files);
    if (e.target.files.length > 0) {
      const reader = new FileReader();
      reader.onload = () => {
        setData({ ...data, imageView: reader.result });
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  };
  useEffect(() => {
    let currentForm = restaurantData[index];
    // console.log(restaurantData,'.........................................')
    setData({
      name: currentForm?.product_name||currentForm?.name,
      price: currentForm?.product_price||currentForm?.price,
      category: currentForm?.product_category||currentForm?.category,
      quantity: currentForm?.product_quantity||currentForm?.quantity,
      image: currentForm?.image,
      gallery:currentForm?.image,
      imageView: currentForm?.imageView,
      formCount: index,
      isImageUpload: currentForm?.isImageUpload,
      service: currentForm?.product_service||currentForm?.service,
      description: currentForm?.description||currentForm?.product_description,
    });
    let galleryData=restaurantData[index]?.gallery;
    if(galleryData){
      let galleryImages=JSON.parse(galleryData);
      let baseUrl=`${environmentVariables?.apiUrl}uploads`
      const addBaseUrlToImages = (images, baseUrl) => {
        return images.map(image => `${baseUrl}/${image}`);
      };
      let imagesWithBaseUrl=addBaseUrlToImages(galleryImages,baseUrl);
      setImgGalleryArrayPreview(imagesWithBaseUrl)
      setImgGalleryArray(galleryImages)
    } 
    // if(restaurantData[index]?.image){
    //   setImage(restaurantData[index]?.image);
    // }
    // setImgGalleryArrayPreview()
  }, [restaurantData]);

  const deleteMinimktProduct = async (id, business_id) => {
    try {
      const response = await axios.delete(`${baseUrl}api/v1/business/deleteMinimktProduct`, {
        data: { id, business_id },
        headers: {
          _token: userData?.token,
        },
      });
      return response.data;
    } catch (error) {
      console.error("Error deleting product:", error);
      return { success: false, message: error.message };
    }
  };
  const handleRemoveGalleryImg=(index)=>{
    let newGalleryImgs=[...imgGalleryArrayPreview];
    let newGalleryImgsFiles=[...imgGalleryArray];

    newGalleryImgs.splice(index,1);
    newGalleryImgsFiles.splice(index,1);
    
    // console.log(newGalleryImgs,index,'/;.;//////////////////////')
    setImgGalleryArrayPreview(newGalleryImgs);
    setImgGalleryArray(newGalleryImgsFiles)
  }

 const handleRemoveForm = async (index) => {
    const { id, business_id } = restaurantData[index];

    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        if(restaurantData[index]?.isNew){
          removeForm(index);
          Swal.fire("Deleted!", "The product has been deleted.", "success");
        }else{
          const result = await deleteMinimktProduct(id, business_id);
        if (result.success) {
          removeForm(index);
          Swal.fire("Deleted!", "The product has been deleted.", "success");
        } else {
          console.error("Failed to delete product:", result.message);
          Swal.fire("Error!", "Failed to delete the product.", "error");
        }
        }
      }
    });
  };


  return (
    <div
      key={`form-${formCount}`}
      className="col-12"
      id="AddNewStyleFormfieldpad"
    >
      <div className="serviceboxedittesti MobileviewServices">
        <i
          className="fa-solid fa-circle-xmark crossedit"
          onClick={() => handleRemoveForm(index)}
        >
          {" "}
        </i>

        <div className="boxbanner">
          <div className="bannerboxedittesti ">
            <img
              style={{
                cursor:
                  data["isImageUpload"] && !addBtnDisable
                    ? "pointer"
                    : "inherit",
              }}
              onClick={() => {
                data["isImageUpload"] && !addBtnDisable
                  ? openFileInput()
                  : null;
              }}
              src={getImage(data)}
              className="mx-auto d-block img-fluid"
              
            />
            <i
              style={{
                display:
                  data["isImageUpload"] && !addBtnDisable ? "block" : "none",
              }}
              onClick={() => {
                data["isImageUpload"] && !addBtnDisable
                  ? openFileInput()
                  : null;
              }}
              id="uploadicon"
              className="fa fa-solid fa-upload"
            ></i>
          </div>
          {data["isImageUpload"] && !addBtnDisable && (
            <input
              ref={fileInputRef}
              style={{ display: "none" }}
              onChange={(e) => {
                handleChangeImage(e);
              }}
              className="form-control"
              type="file"
              placeholder="Title"
              accept=".png, .jpg, .jpeg"
            />
          )}
        </div>
    
        <div className="AddDetailsTextimonialphnview">
        <div className="col-md-12">
              <label className="form-label">Choose Categories</label>
              <select
                name="service"
                className="form-select"
                {...formik.getFieldProps("service")}
              >
                {console.log('servicesArray=====>>>>', servicesArray)}
                <option value="">Select</option>
                {servicesArray.map((service) => (
                  <option key={service?.name} value={service?.id}>
                    {service?.name?.charAt(0)?.toUpperCase() + service?.name?.slice(1)}
                  </option>
                ))}
              </select>
              {formik.touched.service && formik.errors.service ? (
                <div className="text-danger">{formik.errors.service}</div>
              ) : null}
            </div>
            <div className="" style={{ fontSize: "16px", fontWeight: "500" }}>
            {data["isImageUpload"] && !addBtnDisable ? (
              <div>
                <input
                  {...formik.getFieldProps("name")}
                  className="form-control"
                  type="text"
                  placeholder="Name"
                />
                {formik.touched.name && formik.errors.name ? (
                  <FormikErrorStyle>{formik.errors.name}</FormikErrorStyle>
                ) : null}
              </div>
            ) : (
              <div>{data["name"] == "undefined" ? "" : data["name"]}</div>
            )}
          </div>
          {/* <div className="" style={{ fontSize: "16px", fontWeight: "500" }}>
            {data["isImageUpload"] && !addBtnDisable ? (
              <div>
                <input
                  {...formik.getFieldProps("price")}
                  className="form-control"
                  type="number"
                  placeholder={`Price in ${currencyObjData?.name} `}
                />
                {formik.touched.price && formik.errors.price ? (
                  <FormikErrorStyle>{formik.errors.price}</FormikErrorStyle>
                ) : null}
              </div>
            ) : (
              <div>{data["price"] == "undefined" ? "" : data["price"]}</div>
            )}
          </div>  */}
          {/* {data["isImageUpload"] && !addBtnDisable ? (
          <div>
          <input
          {...formik.getFieldProps("quantity")}
              class="form-control"
              type="text"
              placeholder="Quantity"
                />
                {formik.touched.quantity && formik.errors.quantity ? (
                  <FormikErrorStyle>{formik.errors.quantity}</FormikErrorStyle>
                ) : null}
                </div>
              ) : (
              <div>{data["quantity"] == "undefined" ? "" : data["quantity"]}</div>
            )} */}
      
      <div className="" style={{ fontSize: "16px", fontWeight: "500" }}>
            {data["isImageUpload"] && !addBtnDisable ? (
              <div>
                <textarea
                  {...formik.getFieldProps("description")}
                  className="form-control"
                  placeholder="description"
                />
                {formik.touched.description && formik.errors.description ? (
                  <FormikErrorStyle>{formik.errors.description}</FormikErrorStyle>
                ) : null}
              </div>
            ) : (
              <div>{data["description"] == "undefined" ? "" : data["description"]}</div>
            )}
          </div>

          {data["isImageUpload"]&&!addBtnDisable?<><label class="form-label" style={{paddingTop:"10px"}}>Add Product Photos</label></>:<></>}
          <div  className="" style={{ fontSize: "16px", fontWeight: "500" }}>
          {data["isImageUpload"] && !addBtnDisable ? (
              <div> 
              <input
              onChange={(e)=>handleGalleryImages(e)}
                className="form-control"
                type="file"
                multiple
                placeholder="Name"
                disabled={imgGalleryArrayPreview?.length >= 4}
              />
              <p className="indicationMessage">You can upload up to 4 images.</p>
              {/* {formik.touched.name && formik.errors.name ? (
                <FormikErrorStyle>{formik.errors.name}</FormikErrorStyle>
              ) : null} */}
            </div>
          ):(<></>)}
          </div>
          
          <div className="productphotosmultiple">
          {imgGalleryArrayPreview&&imgGalleryArrayPreview.length>0&&imgGalleryArrayPreview.map((val,index)=>

            <>
            <div style={{position:"relative"}}>
              <img src={val}/>
              {data["isImageUpload"]&&!addBtnDisable?<><i onClick={()=>handleRemoveGalleryImg(index)} className="fa-solid fa-circle-xmark crossedit2"></i></>:<></>}
            </div>
            </>
                       
          )}
          </div>

          {data["isImageUpload"]&&!addBtnDisable?<></>:<><button onClick={()=>{handleEditProduct()}} type="button" class="btn btn-cu addbuttonedit2">Edit</button></>}
          {data["isImageUpload"] && !addBtnDisable ? (
          <div className="form-group" style={{display:'flex', alignItems:'center', gap:'10px'}}>
            {/* <label style={{display:'flex', alignItems:'center', gap:'5px', justifyContent:'center'}}>
              <input
                type="radio"
                name="category"
                value="enquiry"
                checked={formik.values.category === "enquiry"}
                onChange={formik.handleChange}
              />
             Enquiry
            </label> */}
            {formik.touched.category && formik.errors.category ? (
              <FormikErrorStyle>{formik.errors.category}</FormikErrorStyle>
            ) : null}
          </div>
        ) : (
          // <div>{data["category"]}</div>
          <></>
        )}

          {data["isImageUpload"] && !addBtnDisable ? (
            <button
              type="button"
              disabled={productId?false:(!formik.isValid || !formik.dirty || !formik.values.name
                || !formik.values.category || !formik.values.service || addBtnDisable )  
              }
              onClick={() => {
                formik.handleSubmit();
                handleAddService({
                  name: formik.values.name,
                  price: formik.values.price,
                  category: formik.values.category,
                  image: image,
                  imageView: data.imageView,
                  isImageUpload: data.isImageUpload,
                  gallery:imgGalleryArray,
                  quantity: formik.values.quantity,
                  service: formik.values.service,
                  description: formik.values.description,
                });
              }}
              className="btn btn-cu editbutton editButtonTextimonial"
            >
              Add
            </button>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
};

export default MiniMarketuproductForm;
