import React from 'react'

const Aboutus = () => {
  return (
    <div>
      <section className='pagecover'>
    <div className='container'>
        <div className='row'>
            <div className='col-12 col-lg-12'>
                <h1>About Us</h1>

            </div>
            <p>
            Ditch traditional cards and redefine business networking with digital cards
to ensure a lasting impression.
            </p>
        </div>
    </div>
</section>
    </div>
  )
}


export default Aboutus
