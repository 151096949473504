import React, { useContext, useState } from "react";
import { userContext } from "../../context/userContext";
import axios from "axios";
import { toast } from "react-toastify";
import { environmentVariables } from "../../config/env.config";
import PageName from "../Utils/PageName";
import CircularLoader from "../Components/CircularLoader/CircularLoader";
import SampleFile from "./../../../src/sample-sheet-for-users.xlsx";
import "./SuperAdminUpdatedStyle.css";
import PhoneInput from "react-phone-input-2";

const BulkUpload = () => {
  const [fontFile, setFontFile] = useState(null);
  const [validMsg, setValidMsg] = useState(null);
  const { userData } = useContext(userContext);
  const [isSubmit, setIsSubmit] = useState(false);
  const [selectedCountry, setCountry] = useState("91");
  const [mailTemplate, setMailTemplate] = useState("");

  const handleSubmit = () => {
    setIsSubmit(true);
    const fileExtension = fontFile?.name.split(".").pop().toLowerCase();
    // console.log(fileExtension,"fileExtensionfileExtensionfileExtension")//
    if (fileExtension === "xlsx" || fileExtension === "xls" || fileExtension === "csv") {
      let data = new FormData();
      data.append("file", fontFile);
      data.append("country", selectedCountry);
      data.append("mailTemplate", mailTemplate);

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${environmentVariables?.apiUrl}api/v1/admin/uploadxlsx`,
        headers: {
          _token: userData?.token,
        },
        // withCredentials: true,
        data: data,
      };
      axios
        .request(config)
        .then((response) => {
          setValidMsg(response?.data);
          toast.success("User Created successfully", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
          setIsSubmit(false);
        })
        .catch((err) => {
          setValidMsg(err?.response?.data);
          toast.error(err?.response?.data?.message || err?.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
          setIsSubmit(false);
        });
    } else {
      setIsSubmit(false);
      if (fileExtension) {
        toast.error("File not valid", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      } else {
        toast.error("Please choose a xls file", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      }
    }
  };
  const handleDownloadSample = () => {
    const sampleFileUrl = process.env.PUBLIC_URL + "/sample.xlsx";
    const link = document.createElement("a");
    link.href = sampleFileUrl;
    link.download = "sample.xlsx";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const [updateImageUrlLoading,setUpdateImageUrlLoading] = useState(false)
  const UpdateImageUrl = async()=>{
    // alert("kl/jfdsklfhsf")
    try {
      setUpdateImageUrlLoading(true)
      const getResponse = await axios.get(`${environmentVariables?.apiUrl}api/v1/admin/getimagesfromcdn`)
      setUpdateImageUrlLoading(false)
      console.log("hhhhhhh",getResponse)
      toast.success("Url Updated successfully", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
    } catch (error) {
      setUpdateImageUrlLoading(false)
      console.log(error)
      toast.error(error?.response?.data?.message || error?.message, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
    }
    

  }

  return (
    <>
      <PageName PageName="Upload Bulk Email" />
      <article>
        <div class="col-md-12">
          <div class="tile">
            <div>
              <test className="InstructionHeading"> Instruction</test>
            </div>
            <ul class="InstructionWrapper">
              <li class="Instruction">
                Organize user data in a CSV, match columns, and ensure format
                compliance.
              </li>
              <li class="Instruction">
                Utilize the designated import tool or API for seamless bulk data
                upload.
              </li>
              <li class="Instruction">
                Monitor and rectify discrepancies during the import process for
                accurate data transfer.
              </li>
            </ul>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="tile">
              <article></article>
              <article>
                <div class="row">
                  <div class="col-12 col-lg-12">
                    <div class="row">
                      <div class="col-12 col-lg-12">
                        <div class="DownloadSampleWrapper">
                          <a
                            href={SampleFile}
                            download={SampleFile}
                            id="downloadSampleButton"
                            className="DownloadSample"
                          >
                            Download Sample
                          </a>
                          <p class="sectitlecard">
                            <span>Import Bulk User Data</span>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        // display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <div
                        style={{
                          padding: "10px 0",
                          display: "flex",
                          margin: "0px 0 0px 0",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <div
                          style={{
                            fontSize: "16px",
                            fontWeight: "500",
                            padding: "5px 0",
                          }}
                        >
                          <label for="" class="">
                            Choose Country
                          </label>
                        </div>
                        <select
                          className="SelectCountrycode"
                          onChange={(e) => setCountry(e.target.value)}
                        >
                          <option value="">Select</option>
                          <option value="91">+91 India </option>
                          <option value="971">+971 Dubai </option>
                          <option value="27">+27 South Africa </option>
                        </select>
                      </div>
                      <div>
                        <div
                          style={{
                            fontSize: "16px",
                            fontWeight: "500",
                            padding: "5px 0",
                          }}
                        >
                          <label for="" class="">
                            Choose Mail Template
                          </label>
                        </div>
                        <select
                          className="SelectCountrycode"
                          onChange={(e) => setMailTemplate(e.target.value)}
                        >
                          <option value="">Select</option>
                          <option value="bulk_business_mail">
                            Enterprise Template
                          </option>
                          <option value="bulk_user_mail">User Template</option>
                          <option value="bulk_restaurent_mail">
                            Restaurent Template
                          </option>
                          <option value="bulk_minimarket_mail">
                            Mini Marketplace Template
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="row" style={{ marginTop: "15px" }}>
                      <div class="col-12 col-lg-12">
                        <div class="">
                          <label for="formFile" class="form-label">
                            upload .xlsx file
                          </label>
                          <input
                            class="form-control"
                            type="file"
                            id="formFile"
                            onChange={(e) => setFontFile(e.target.files[0])}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </article>
              <button onClick={handleSubmit} class="btn btn-primary mt-4" style={{marginRight:"10px"}}>
                Upload
                {isSubmit && <CircularLoader size={20} />}
              </button>
              {/*.. <button class="btn btn-primary mt-4" onClick={()=>UpdateImageUrl()}>
               {updateImageUrlLoading ? <CircularLoader size={20} /> :"CDN URL Update"} </button> */}
            </div>
          </div>

          <div class="col-md-6">
            {validMsg && (
              <div class="tile">
                <article></article>
                <article>
                  <div class="row">
                    <div class="col-12 col-lg-12">
                      {validMsg && validMsg?.status === false && (
                        <>
                          <h5 className="text-danger">Errors !</h5>
                          <>
                            <table style={{ width: "711px" }}>
                              <tr>
                                <td>
                                  <h4>Row ID</h4>
                                </td>
                                <td>
                                  <h4>Email</h4>
                                </td>
                                <td>
                                  <h4>Error</h4>
                                </td>
                              </tr>
                              {validMsg?.data.map((element, index) => (
                                <tr>
                                  <td>
                                    <h5>{element?.row}</h5>
                                  </td>
                                  <td>
                                    <h6>{element?.email}</h6>
                                  </td>
                                  <td>{element?.err}</td>
                                </tr>
                              ))}
                            </table>
                          </>
                        </>
                      )}

                      {validMsg && validMsg.status === true && (
                        <h5 className="text-success">
                          Users Created Successfully
                        </h5>
                      )}
                      {Array.isArray(validMsg?.data) &&
                        validMsg.status === true &&
                        validMsg?.data.map((element, index) => (
                          <p key={index}>{element?.email}</p>
                        ))}
                    </div>
                  </div>
                </article>
              </div>
            )}
          </div>
        </div>
      </article>
    </>
  );
};
export default BulkUpload;
