import styled from "styled-components";
import DetailsBackgroundImage from "../../../../Images/Restaurant/Menubackground.png";
import OrderImg from "../../../../Images/Restaurant/Reviewfirstimg.png";




export const Wrapper = styled.div``;
export const TopImageWrapper = styled.div`
position: relative;
background-color: #000;
`;
export const Image = styled.img`
width: 100%;
opacity: 0.5;
`;

export const TextDetails = styled.div`
    position: absolute;
    width: 100%;
    top: 0;
    bottom: 0;
    text-align: center;
    left: 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

${(p)=>p.InternalTheme && `
// top: 30px;
// text-align: center;
// left: 0px;
`}

@media(max-width: 480px){
    top: 50px;
    text-align: center;
    // right: 90px; 
}
@media(max-width: 425px){
    top: 50px;
    text-align: center;
    // right: 90px; 
}
`;
export const Name = styled.div`
font-family: "Plus Jakarta Sans", sans-serif;
font-weight: 700;
font-size: 36px;
line-height: 40px;
color: #fff;
text-transform: capitalize;
height: 82px !important;
overflow: hidden;
text-overflow: ellipsis;
display: -webkit-box;
-webkit-line-clamp: 2;
-webkit-box-orient: vertical;
white-space: normal;
word-wrap: break-word;
${(p)=>p.InternalTheme && `
font-size: 34px;
`}

@media(max-width: 480px){
    font-size: 36px;
}
@media(max-width: 425px){
    font-size: 26px;
}
`;
export const ChefName = styled.div`
font-family: "Plus Jakarta Sans", sans-serif;
font-weight: 400;
font-size: 28px;
color: #fff;
text-transform:capitalize;
// padding:5px 0;
@media(max-width: 480px){
    font-size: 24px;
}
`;
export const Designation = styled.div`
font-family: "Plus Jakarta Sans", sans-serif;
font-weight: 400;
font-size: 18px;
color: #fff;
`;

export const TabWrapperContainer = styled.div`
background-color: #fff;
margin: 20px 25px;
display: -webkit-box;
overflow: scroll;
scrollbar-width:none;
overflow-y: hidden;
// overflow-x: hidden;
&::-webkit-scrollbar {
    display: none;
}
`;
export const TabWrapper = styled.div`
display:flex;
// background-color: #F3F3F3;
background-color: ${({ background }) => background};
padding: 10px 20px;
margin: 0 10px;
border-radius: 60px;
cursor: pointer;
align-items: center;

`;
export const TabImageWrapper = styled.div`
width:max-content;
`;
export const TabTextWrapper = styled.div``;
export const TabText = styled.div`
font-family: "Plus Jakarta Sans", sans-serif;
font-weight: 500;
font-size: 20px;
// color: #A2A3A4;
color: ${({ color }) => color};
width: max-content;
`;
export const TabImage = styled.img`
// width: 100%;
padding: 0 10px;
width: 40px;
`;


/////////////////////////////Tab Menu ///////////
export const NewTabOrderMenuWrapper = styled.div`
    padding-bottom:30px
`;

export const NewTabMenuWrapper = styled.div`

background-image:url(${DetailsBackgroundImage});
background-position: center;
// background-repeat: no-repeat;
background-size: cover;
max-height: 600px;
min-height:500px;
overflow-y:scroll;
`;
export const NewTabOrderWrapper = styled.div`
// padding: 0px 10px;
`;
export const OrderstatusContainernew = styled.div`

background-image:url(${DetailsBackgroundImage});
background-position: center;
// background-repeat: no-repeat;
background-size: cover;
max-height: 600px;
min-height: 500px;
overflow-y:scroll;
padding: 0 20px;
margin-bottom:40px;

`;
export const Container = styled.div`
padding: 0px 25px;

${(p)=>p.InternalTheme && `
padding: 0 25px 45px 25px;
`}

@media(max-width: 480px){
    padding: 0 15px;
}
`;
export const NewTabMenuHeadingWrapper = styled.div`
    margin: 0 auto 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: #fff;
    // position: absolute;
    left: 0;
    right: 0;
    z-index: 999999;
    max-width: 520px;
    padding-bottom: 20px;
`;
export const NewTabMenuText = styled.div`
font-family: "Plus Jakarta Sans", sans-serif;
font-weight: 600;
font-size: 36px;
color: #101010;
display: flex;
flex-wrap: wrap;
gap: 20px;

${(p)=>p.InternalTheme && `
font-size: 30px;
color: #101010;
`}
`;

export const MenuDeatilsContainer = styled.div`
display: grid;
grid-template-columns: 1fr 1fr;
grid-gap: 20px;
margin: 0 10px 130px 10px;

${(p)=>p.InternalTheme && `
margin: 0 0px 30px 0px;
grid-gap: 15px 10px;
`}

@media(max-width: 480px){
    grid-gap: 15px 10px; 
}
`;
export const MenuCardWrapper = styled.div`
box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.1); 
padding: 15px;
position: relative;
border-radius:12px;
background-color: #fff;
`;
export const MenuImageWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-height: 120px;
    min-height: 120px;
    overflow: hidden;
`;
export const MenuImage = styled.img`
    width: 100%;

`;
export const MenuBestSaller = styled.img`
position: absolute;
top: 0;
left: 0;

`;
export const MenuVageFilter = styled.img`
    width: 12px;
    height: 12px;
    background-color: #0C7C54;
    border-radius: 100px;
`;
export const MenuNonvageFilter = styled.img`
    width: 12px;
    height: 12px;
    background-color: #7C0C0C;
    border-radius: 100px;
`;
export const MenuNameWrapper = styled.div`
padding-top: 10px;
`;
export const MenuName = styled.div`
font-family: "Inter", sans-serif;
font-weight: 500;
font-size: 18px;
color: #232323;
text-transform: capitalize;
${(p)=>p.InternalTheme && `
font-size: 18px;
text-transform: capitalize;
`}
@media(max-width: 480px){
    font-size: 18px;
}

`;
export const MenuStarWrapper = styled.div`
display: flex;
padding: 10px 0;
align-items: center;

`;
export const MenuStar = styled.img``;
export const MenuPrice = styled.div`
font-family: "Plus Jakarta Sans", sans-serif;
font-weight: 500;
font-size: 16px;
color: #6b6b6b;
${(p)=>p.InternalTheme && `
font-size: 18px;
`}

@media(max-width: 480px){
    font-size: 20px;
}
@media(max-width: 425px){
    font-size: 18px;
}

`;
export const MenuAddMoreWrapper = styled.div``;
export const MenuAddmoreIcon = styled.img`
width: 35px;
cursor: pointer;
${(p)=>p.InternalTheme && `
width: 30px;
`}
@media(max-width: 480px){
    width: 30px;
}
`;
export const MenuStarvalue = styled.div`
padding: 0 10px;
font-family: "Plus Jakarta Sans", sans-serif;
font-weight: 500;
font-size: 20px;
color: #101010;

@media(max-width: 480px){
    font-size: 19px;
}
@media(max-width: 425px){
    font-size: 18px;
}

`;
export const MenuPriceWrapper = styled.div`
display:flex;
justify-content: space-between;
align-items: center;
`;


export const MenuVagefilterWrapper = styled.div`
position: absolute;
width: 28px;
height: 28px;
background-color: white;
display: flex;
justify-content: center;
align-items: center;
border: 2px solid #0C7C54;
border-radius:8px;
top: 24px;
right:22px;
`;

export const MenuNonvagefilterWrapper = styled.div`
position: absolute;
width: 28px;
height: 28px;
background-color: white;
display: flex;
justify-content: center;
align-items: center;
border: 2px solid #7C0C0C;
border-radius:8px;
top: 24px;
right:22px;
`;


////////////////////// Order ////////////////////
export const OrderCardWrapperContainer = styled.div`

position: relative;
background-image:url(${DetailsBackgroundImage});
background-repeat: no-repeat;
background-size: cover;
background-position: initial;
height: 100%;
margin-bottom:120px;
`;
export const OrderCardWrapperContainerOH = styled(OrderCardWrapperContainer)`
height: 100vh;
`;
export const OrderCardWrapperContainerSM = styled(OrderCardWrapperContainer)`
height: 100vh;
`
export const OrderCardWrapperContainerABOUT = styled(OrderCardWrapperContainer)`
height: 100vh;
`
export const OrderCardWrapperContainerCI = styled(OrderCardWrapperContainer)`
height: 100vh;
`
export const OrderCardWrapper = styled.div`
position: relative;
margin: 20px 15px;

`;
export const OrderImageWrapper = styled.div`
box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.1);
background-color:#FFF;
border-radius:15px;
// height: 165px;
`;
export const OrderImage = styled.img`

width: 505px;
margin: 0 0 -10px 0;

${(p) => p.InternalTheme && `
width: 400px;
`}

@media(max-width: 520px){
    width: 100%;
}

`;
export const OrderTextWrapper = styled.div`
// position: absolute;
// bottom: 10px;
// left: 40px;
padding: 15px 25px;
background:#ccc7c752;
border-radius:10px;

@media(max-width: 480px){
bottom: 30px;
left: 40px;
}
`;
export const OrderNameWrapper = styled.div``;
export const Ordername = styled.div`
font-family: "Plus Jakarta Sans", sans-serif;
font-weight: 700;
font-size: 22px;
color: #000;
width: fit-content;

@media(max-width: 480px){
    font-size: 16px;
    }
`;
export const OrderParagraph = styled.div`
width: 100%;
font-family: "Plus Jakarta Sans", sans-serif;
font-weight: 400;
font-size: 17px;
color: #000;
// width: fit-content;

@media(max-width: 480px){
    font-size: 14px;
    font-weight: 400;
    }
`;
export const OrderStarImageWrapper = styled.div`
padding-bottom: 5px;
`;
export const OrderstarImage = styled.img`

`;

export const OrderStatusCardWrapper = styled.div`
box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.1);
padding: 10px 20px;
border-radius: 15px;
margin-top: 20px;
background-color:#fff;
`;
export const OrderStatusCardWrapper2 = styled.div`
box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.1);
padding: 10px 20px;
border-radius: 15px;
display:flex;
width: 100%;
align-items: center;
justify-content: flex-start;
position: relative;
background-color:#fff;
margin: 20px 0px
`;
export const OrderStatus = styled.div`
font-family: "Plus Jakarta Sans", sans-serif;
font-weight: 700;
font-size: 20px;
color: #101010;
`;
export const OrderStatusKeyValueWrapper = styled.div`
display:flex;
justify-content:space-between;
padding: 10px 0 0 0;
`;
export const OrderStatuskey = styled.div`
font-family: "Plus Jakarta Sans", sans-serif;
font-weight: 400;
font-size: 22px;
color: #8D92A3;

${(p)=>p.InternalTheme && `
font-size: 20px;
`}

@media(max-width: 480px){
    font-size: 20px;
}
@media(max-width: 425px){
    font-size: 18px;
}
`;
export const OrderStatusValue = styled.div`
font-family: "Plus Jakarta Sans", sans-serif;
font-weight: 400;
font-size: 22px;
color: #101010;
${(p)=>p.InternalTheme && `
font-size: 20px;
`}
@media(max-width: 480px){
    font-size: 20px;
}
@media(max-width: 425px){
    font-size: 18px;
}
`;



export const AddOrderContainer = styled.div`
/* width: 50%; */
margin-right: 20px;
`;
export const AddorderContainerWrapper = styled.div`
    width: 170px;
    height: 110px;
    overflow: hidden;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    `;
export const AddorderImage = styled.img`
width:100%;
${(p)=>p.InternalTheme && `
width:90%;
`}
`;
export const Addorderseller = styled.img``;
export const AddorderTextContainer = styled.div``;
export const AddorderText= styled.div`
font-family: "Plus Jakarta Sans", sans-serif;
font-weight: 500;
font-size: 19px;
color: #000;
${(p)=>p.InternalTheme && `
font-size: 20px;
`}
@media(max-width: 480px){
    font-size: 20px;
}
@media(max-width: 425px){
    font-size: 18px;
}
`;
export const AddorderValue = styled.div`
font-family: "Plus Jakarta Sans", sans-serif;
font-weight: 400;
font-size: 19px;
color: #8D92A3;
padding: 5px 0;
${(p)=>p.InternalTheme && `
font-size: 20px;
`}

@media(max-width: 480px){
    font-size: 20px;
}
@media(max-width: 425px){
    font-size: 18px;
}
`;
export const AddorderIncrementWrapper = styled.div`
 border: 1px solid #E1E1E1;
 border-radius: 24px;
 width: 93px;
`;
export const AddorderIncrement = styled.div`
display:flex;  
padding: 5px 10px;
font-size: 20px;
cursor: pointer;

`;
export const IncrementSpan = styled.div`
padding: 0px 10px;
font-size: 20px;
cursor: pointer;
`;

export const CrossWrapper = styled.div`
    position: absolute;
    top: -12px;
    right: 12px;
    color: red;
    font-size: 45px;
${(p)=>p.InternalTheme && `
top: 0px;
right: 8px;
color: red;
font-size: 20px;
`}
@media(max-width: 480px){
    font-size: 18px;
    right: 8px;
    top: -2px;
}
`;
export const Cross = styled.div`
cursor: pointer;
    transform: rotate(223deg);
    font-weight: 100;
`;




///////////////////// opening hours ////////////

export const OpeningHoursContainer = styled.div`
box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.1);
padding: 10px 20px;
border-radius: 15px;
margin: 10px 40px;
background-color: #fff;
text-align: center;
`;
export const OpeningHours = styled.div`
font-family: "Kaushan Script", cursive;
font-size:40px;
font-weight:400;
margin:10px 0;
`;

export const MondaySatWrapper = styled.div`
display:flex;
flex-direction:column;
margin: 15px 0 10px 0;
`;
export const MondayText = styled.div`
font-family: "Plus Jakarta Sans", sans-serif;
font-weight: 700;
font-size: 24px;
color: #101010;
text-transform: uppercase;

${(p)=>p.InternalTheme && `
font-size: 26px;
`}

@media(max-width: 480px){
    font-size: 30px;
}
@media(max-width: 425px){
    font-size: 28px;
}
`;
export const MondayTextvalue = styled.div`
font-size: 18px;
text-transform: none;
${(p)=>p.InternalTheme && `
font-size: 20px;
`}
@media(max-width: 480px){
    font-size: 26px;
}
@media(max-width: 425px){
    font-size: 24px;
}
`;
export const SundayText = styled.div`
font-family: "Plus Jakarta Sans", sans-serif;
font-weight: 700;
font-size: 24px;
color: #DE2F35;
text-transform: uppercase;
${(p)=>p.InternalTheme && `
font-size: 26px;
`}
`;
export const SundayTextValue = styled.div`
font-family: "Plus Jakarta Sans", sans-serif;
font-weight: 700;
font-size: 18px;
color: #101010;
${(p)=>p.InternalTheme && `
font-size: 20px;
`}
`;
export const MondayTextWrapper = styled.div`
font-family: "Plus Jakarta Sans", sans-serif;
font-weight: 700;
font-size: 26px;
color: #101010;
// margin: 10px 0;
`;
export const SundayWrapper = styled.div`
margin: 20px 0;
`;




///////////////////Gallary //////////////

export const NewTabGallaryWrapperContainer = styled.div`
position: relative;
background-image:url(${DetailsBackgroundImage});
background-repeat: no-repeat;
background-size: cover;
background-position: initial;
height: 100vh;
`;
export const NewTabGallaryWrapper = styled.div`
margin: 0 50px;
`;

export const GalleryWrapper = styled.div`
display:grid;
grid-template-columns: 1fr 1fr;
grid-gap: 15px 10px;

`;
export const GalleryImageWrapper = styled.div`
display: flex;
justify-content: center;
`;
export const GalleryImage = styled.img`
width: 100%;
height: 140px;
object-fit: cover;
border-radius: 10px;
`;



export const AboutTabWrapper = styled.div`
box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.1);
padding: 10px 20px;
border-radius: 15px;
width: 100%;

`;

export const AboutTextWrapper = styled.div`
margin: 10px;
`;
export const AboutText = styled.div`
font-family: "Plus Jakarta Sans", sans-serif;
font-weight: 700;
font-size: 36px;
color: #101010;
text-align:center;
margin:0 0 20px 0;


${(p)=>p.InternalTheme && `
font-size: 30px;
margin:0 0 10px 0;
`}
`;
export const AboutParagraph = styled.div`
font-family: "Plus Jakarta Sans", sans-serif;
font-weight: 400;
font-size: 20px;
color: #101010;
text-align:justify;
word-break: break-word;
`;




//////////////////////// TabSocialMedia ////////////////

export const TabSocialContainer = styled.div`
padding: 20px;
display:flex;
justify-content: center;
`;
export const TabSocialIConWrapper= styled.div`
background-color: #fff;
width: 75px;
height: 75px;
display: flex;
justify-content: center;
align-items: center;
border-radius: 50px;
box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.1);

${(p)=>p.InternalTheme && `
width: 65px;
height: 65px;
`}
`;
export const SocialIcon = styled.img`
width: 50px;
`;

export const SocialmediaIconn = styled.img`

`;



///////////////   Contact Info  //////////

export const TabContactInfoContainer = styled.div`
border-radius: 15px;
box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.1);
margin: 20px 20px 150px 20px;
padding: 25px 0;
`;
export const TabContactWrapper = styled.div`
display:flex;
justify-content:center;
align-items:center;
text-align:center;
`;
export const TabContactText = styled.div`
font-family: "Plus Jakarta Sans", sans-serif;
font-weight: 700;
font-size: 24px;
color: #101010;
`;
export const TabContactPhoneWrapper = styled.div`
display:flex;
justify-content:center;
flex-direction:column;
align-items:center;
text-align:center;
margin: 25px 0 30px 0;

${(p)=>p.InternalTheme && `
margin: 15px 0;
`}
`;
export const ContactIconWrapper = styled.div`
width: 60px;
height: 60px;
background-color:#FCEAEB;
display:flex;
justify-content:center;
align-items:center;
text-align:center;
border-radius: 30px;
box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.1);
padding: 25px 0;

${(p)=>p.InternalTheme && `
width: 50px;
height: 50px;
`}
`;
export const ContactTabIcon = styled.img`
width: 30px;
`;
export const ContactTabValue = styled.div`
font-family: "Plus Jakarta Sans", sans-serif;
font-weight: 500;
font-size: 20px;
color: #101010;
text-align:center;
${(p)=>p.InternalTheme && `
font-size: 18px;
`}
`;



///////////////  Customer Details /////////////
export const CustomerDetailsWrapper = styled.div`
display: flex;
justify-content: center;
align-items: center;
text-align: center;
margin: 10px 0;


`;
export const CustomerDetailText = styled.div`
font-family: "Plus Jakarta Sans", sans-serif;
font-weight: 600;
font-size: 24px;
color: #101010;
text-align:justify;
${(p)=>p.Custoomer && `
@media(max-width: 480px){
    font-size: 26px;
}
`}
`;

export const TabCustomerDetailsWrapper = styled.div`
background: #fff;
padding: 10px 0px;
border-radius: 15px;
margin: 10px 20px;
box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.1);
position: relative;
width: 100%;
`;

export const ReviewTabCustomerDetailsWrapper = styled(TabCustomerDetailsWrapper)`
padding: 10px 0px 100px 0;
`;
export const InputTextFieldWrapper = styled.div`
width: 100%;
margin: 10px 25px;
`;
export const InputTextField = styled.input`
background-color: #F6F6F6;
width: 91%;
padding: 16px 20px;
font-size:18px;
border: 1px solid #8080809c;
border-radius: 20px;
margin: 5px 0;
&:focus {
    outline: none; 
}
`;

export const ConfirmOrderWrapper = styled.div`
background-color: #DE2F35;
margin: 20px 25px 20px 25px;
padding: 16px 15px;
display: flex;
justify-content: center;
align-items: center;
text-align: center;
border-radius: 15px;
`;
export const ConfirmOrder = styled.button`
font-family: "Plus Jakarta Sans", sans-serif;
font-weight: 700;
font-size: 24px;
color: #fff;
text-align:justify;
`;



//////////////////////////// order review screen ///////////////

export const TabOrderReviewScreen = styled.div`
background: #fff;
padding: 20px 0px;
border-radius: 15px;
margin: 20px 20px;
box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.1);
`;
export const TaborderDetailWrapper = styled.div`
display: flex;
justify-content: center;
align-items: center;
text-align: center;
margin: 10px;
`;
export const OrderDetail = styled.div`
font-family: "Plus Jakarta Sans", sans-serif;
font-weight: 700;
font-size: 30px;
color: #2F3438;
text-align:justify;
`;



///////////////    Order Review Screen  ////////////


export const OrderreviewKeyValueWrapper = styled.div`
display:flex;
justify-content:space-between;
padding: 12px 0 12px 0;
margin: 0 25px;
`;

export const OrderreviewValue = styled.div`
font-family: "Plus Jakarta Sans", sans-serif;
font-weight: 400;
font-size: 22px;
color: #101010;
width: 55%;
text-align:end;
`;


export const PlaceOrderWrapper = styled.div`
background-color: #DE2F35;
margin: 20px 25px 80px 25px;
padding: 22px 15px;
display: flex;
justify-content: center;
align-items: center;
text-align: center; 
border-radius: 15px;
`;
export const PlaceOrder = styled.div`
font-family: "Plus Jakarta Sans", sans-serif;
font-weight: 700;
font-size: 24px;
color: #fff;
text-align:justify;
`;


////////////////////// Successpopup /////////////


export const TabPlaceOrderSuccessWrapper = styled.div`
background: #fff;
padding: 20px 0px;
border-radius: 15px;
margin: 20px 20px;
box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.1);
`;
export const TabPlaceOrderSuccess = styled.div`
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
text-align: center; 
margin-bottom: 20px;
`;
export const OrderSuccessImageWrapper = styled.div``;
export const OrderSuccessImage = styled.img`
width: 80%;
`;
export const ThankYouTextWrapper= styled.div``;

export const OrdersuccessfullyPlacedWrapper = styled.div`
display: flex;
flex-direction: column;
`;
export const OrdersuccessfullyPlaced = styled.div`
font-family: "Plus Jakarta Sans", sans-serif;
font-weight: 400;
font-size: 28px;
color: #2F3438;
`;
export const OrdersuccessIdNumber = styled.div`
font-family: "Plus Jakarta Sans", sans-serif;
font-weight: 400;
font-size: 28px;
color: #676D75;
margin: 5px 0;
`;
export const ThankyouText = styled.div`
font-family: "Plus Jakarta Sans", sans-serif;
font-weight: 700;
font-size: 34px;
color: #2F3438;
`;


export const DrawerCloseButtonWrapper= styled.div`
position: absolute;
top: -10px;
right: 10px;
`;
export const DrawerCloseButton = styled.div`
color:red;
cursor: pointer;
font-size: 40px;
transform: rotate(45deg);
`;



///////////////////////// Review rating ///////////////////


export const OrderDateIDWrapper = styled.div`
padding: 20px 40px;

`;
export const ReviewOrderDateWrapper = styled.div`
display:flex;
justify-content: space-between;
padding: 10px 0;
`;
export const ReviewOrderKey = styled.div`
font-family: "Plus Jakarta Sans", sans-serif;
font-weight: 400;
font-size: 24px;
color: #8D92A3;
${(p)=>p.Orderss && `
@media(max-width: 480px){
    font-size: 16px;
}
`}
`;
export const ReviewOrderValue = styled.div`
font-family: "Plus Jakarta Sans", sans-serif;
font-weight: 400;
font-size: 24px;
color: #101010;
${(p)=>p.Orderss && `
@media(max-width: 480px){
    font-size: 16px;
}
`}
`;

export const ReviewDishContentContainer = styled.div`
display:flex;
justify-content:start;
align-items:start;
text-align:start;
padding-bottom: 20px;
${(p)=>p.ReviewDishContentPhone && `
@media(max-width: 480px){
    display:flex;
    flex-direction: column;
    align-items:center;
    text-align:center;
    
}
`}
`;
export const ReviewDishContentWrapper= styled.div`
display:flex;
flex-direction: column;
padding-left: 15px;
${(p)=>p.ReviewDishpaddingphone && `
@media(max-width: 480px){
    padding: 0px !important;
}
`}
`;
export const ReviewDishImageWrapper= styled.div``;
export const ReviewDishImg= styled.img`

min-width: 100px;
max-width: 100px;
max-height: 100px;
min-height: 100px;
border-radius: 15px;

`;
export const ReviewDishCOntent= styled.div`
font-family: "Plus Jakarta Sans", sans-serif;
font-weight: 400;
font-size: 22px;
color: #000;

${(p)=>p.Delevered && `
font-size: 19px;
color: #929292;
padding: 5px 0;
`}
`;

export const Reviewstarwrapper = styled.div`
padding: 15px 0;
`;
export const Reviewstar = styled.img`
width: 35px;
padding: 0 5px;
cursor: pointer;
`;

export const RevieCommentWrapper= styled.div`
padding: 10px 40px;
`;
export const TypeCommentWrapper = styled.div``;
export const TypeText = styled.div`
font-family: "Plus Jakarta Sans", sans-serif;
font-weight: 400;
font-size: 22px;
color: #000;
`;
export const InputType = styled.textarea`
background-color: #F6F6F6;
width: 100%;
padding: 10px;
font-family: "Plus Jakarta Sans", sans-serif;
font-weight: 400;
font-size: 18px;
color: #6D6D6D;
margin: 15px 0 0 0;
border:1px solid #d0d0d0;
outline: none;
border-radius:5px;
`;

export const SpancurrencySymbol = styled.span`
font-size: 16px;
font-weight: 400;
`;