import React, { useEffect, useContext, useState, useRef, useCallback } from "react";
import { userContext } from "../../../../context/userContext";
import { environmentVariables } from "../../../../config/env.config";
import { useParams, useLocation } from "react-router-dom";
import axios from "axios";
import "./Theme20.css";
import Banner from "./../../../../Images/minimarketbg.png";
import LocationIcon from "./../../../../Images/locIcon.png";
import QrCodeGenerator from "../theme6/QRCode";
// import {fetchReviews } from "./TabReviewrating";
import whiteCircle from "../../../../../src/Images/white-circle.png";
import Radar from "radar-sdk-js";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import HomeComponent from "./HomeComponent";
import ExtContactInfo from "./ExtContactInfo";
import ExtHomeComponent from "./ExtHomeComponent";
import ExtAboutus from "./ExtAboutus";
import ProductDetailpage from "./ProductDetailpage";
import OrdersItem from "./OrdersItem";
import { getContactInfo } from "../../../../utils/utility";
import moment from "moment";


const Theme20Ext = (props) => {
  const rs = props.rs;
  const currencySymbol=props?.currencySymbol||"₹";
  const [businessLink, setBusinessLink] = useState("");
  const [storedData, setStoredData] = useState(
    localStorage.getItem("businessObj")
  );
  const baseUrl = environmentVariables?.apiUrl;
  const { userData } = useContext(userContext);
  let { param } = useParams();
  const [pparam, setPparam] = useState([]);
  const searchParams = new URLSearchParams(window.location.search);
  const appParams = searchParams.get('isApp');
  const paramValue = searchParams.get("param");
  const location = useLocation();
  const [galleryObject, setGalleryObject] = useState(null);
  const createUserRef = useRef(null);
  const currentDate = new Date();
  const formattedDate = currentDate.toISOString();
  const [order, setOrder] = useState(null);
  const [minDate, setMinDate] = useState("");
  const calendarInputRef = useRef(null);
  const activeTabParam = searchParams.get("activeTab");
  const [activeTab, setActiveTab] = useState("home");
  const [isReviewDrawerVisible, setIsReviewDrawerVisible] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const openDrawer = useCallback(() => setIsVisible(true), []);
  const closeDrawer = useCallback(() => setIsVisible(false), []);
  const [userReview, setUserReview] = useState();
  const openReviewDrawer = useCallback(() => setIsReviewDrawerVisible(true), []);
  const [mmProducts, setMmProducts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [pageSize] = useState(8); 
  const [productService, setProductService] = useState('');

  // const closeReviewDrawer = useCallback(() => setIsReviewDrawerVisible(false), []);
  const closeReviewDrawer = () => {
    setIsReviewDrawerVisible(false);
    const url = new URL(window.location.href);
    url.searchParams.delete("activeTab");
    url.searchParams.delete("orderId");
    window.history.replaceState({}, '', url);
  };
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };
  const [formData, setFormData] = useState(null);
  const [isOrderReviewVisible, setIsOrderReviewVisible] = useState(false);
  const openOrderReview = () => {
    setIsOrderReviewVisible(true);
  };

  const closeOrderReview = () => {
    setIsOrderReviewVisible(false);
  };

  const handleNextClick = () => {
    setActiveTab("order");
  };

  useEffect(() => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}`;
    setMinDate(formattedDate);
  }, []);

  useEffect(() => {
    if (activeTabParam) {
      setActiveTab(activeTabParam);
      if (activeTabParam === "review") {
        openReviewDrawer();
      }
      // Check if orderId is present in the URL
      const orderIdParam = searchParams.get("orderId");
      if (orderIdParam) {
        fetchOrdersByOrderId(orderIdParam);
      }
    }
  }, [activeTabParam]);
  // }, [activeTabParam, openReviewDrawer, searchParams]);

  const fetchOrdersByOrderId = async (orderId) => {
    try {
      // Make API call to fetch orders by orderId
      const response = await axios.get(`${environmentVariables?.apiUrl}api/v1/business/fetchOrdersByOrderId?orderId=${orderId}`);
      const orders = response?.data?.orders;
      setUserReview(response?.data?.orders)

    } catch (error) {
      console.error("Error fetching orders by orderId:", error);
      // Handle error
    }
  };

  let ff = rs?.contactInfo?.content;
  if (ff?.length) {
    ff = JSON?.parse(ff);
  }
  if (ff?.length) {
    ff = JSON?.parse(ff);
  }

  useEffect(() => {
    const path = location?.pathname;
    const pathParts = path.split("/");
    setPparam(pathParts);
  }, [location]);


  useEffect(() => {
    if (storedData != "undefined") {
      const businessData = JSON.parse(storedData);
      const slugb = businessData?.slug;
      const originUrl = window.location.origin;
      setBusinessLink(`${originUrl}/${slugb}`);
    }
  }, [storedData]);


  // useEffect(() => {
  //   if (rs?.menu?.is_enabled === 1) {
  //     setActiveTab("home");
  //   } else {
  //     setActiveTab("about");
  //   }
  // }, [rs]);

  let contactInfo = props?.rs?.contactInfo?.content;

  // parsing into string
  if (contactInfo?.length) {
    contactInfo = JSON.parse(contactInfo);
  }
  // parsing into object
  if (contactInfo?.length) {
    contactInfo = JSON.parse(contactInfo);
  }
  const VCardContent = `BEGIN:VCARD
VERSION:3.0
N:${props?.rs?.sub_title}
FN:${props?.rs?.sub_title}
ORG:${props?.rs?.title}
TITLE:${props?.rs?.designation}
URL:https://app.businessbay.io/${props?.rs?.slug}
TEL;TYPE=CELL:${contactInfo?.contactInfoPhone}
EMAIL;TYPE=INTERNET:${contactInfo?.contactInfoEmail}
ADR;TYPE=HOME:;${contactInfo?.contactInfoAddress}
URL:${contactInfo?.contactInfoWebUrl}
X-ABADR:country
X-ABLabel:Title
X-ABLabel:eBusiness Card
NOTE;CHARSET=UTF-8:${props?.rs?.description?.replace(/\s+/g, ' ').trim()}
END:VCARD`;

  const downloadVCard = () => {
    const blob = new Blob([VCardContent], { type: "text/vcard" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "contact.vcf"; // Set the desired filename here

    // Create a click event
    const clickEvent = new MouseEvent("click", {
      bubbles: true,
      cancelable: false,
      view: window,
    });

    // Dispatch the click event
    a.dispatchEvent(clickEvent);

    // Revoke the object URL to free up resources
    window.URL.revokeObjectURL(url);
  };

  useEffect(() => {
    getBlockOrder();
    if (rs?.newGalleryImages) {
      setGalleryObject(rs?.newGalleryImages);
    }
    if (rs?.appointmentObj && rs?.appointmentObj?.content) {
      try {

      } catch (err) {
        console.log(err);
      }
    }
  }, [rs]);
  const getBlockOrder = async () => {
    // const handleGetBlock = async () => {
    try {
      let id = props?.id;

      if (id) {
        let { data: get } = await axios.get(
          `${environmentVariables?.apiUrl}api/v1/business/FetchBlock?business_id=${id}`,
          {
            headers: {
              _token: userData?.token,
            },
          }
        );
        setOrder(get?.data?.order);
      }

    } catch (err) {
      console.log(err);
    }
    // };
  };

  useEffect(() => {
    getMMProducts(currentPage,productService);
}, [currentPage,productService]);

  const getMMProducts = async (page, productService) => {
    try {
      let data = {
        bid: props?.id,
        page: page,
        pageSize: pageSize,
        product_service: productService || '',
      };
  
      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${environmentVariables?.apiUrl}api/v1/business/getAllMiniMarketplaceProducts`,
        headers: {
          'Content-Type': 'application/json'
        },
        data: data
      };
      
      const response = await axios.request(config);
      setMmProducts(response?.data)
      setTotalPages(response?.data?.data?.pageCount);
      console.log("mmpdata==>", response.data.data) ;
    } catch (err) {
      console.log(err);
    }
  };
  

  const handleNextPage = () => {
    if (currentPage < totalPages) {
        setCurrentPage(currentPage + 1);
    }
};

const handlePrevPage = () => {
    if (currentPage > 1) {
        setCurrentPage(currentPage - 1);
    }
};




  const handleChildData =  (minidata)=>{
    whatsappclick(minidata)
  }

  const handleChildcomData = (data) => {
    if(data == "All") {
      setProductService('');
    }else {
      setProductService(data);
      setCurrentPage(1)
    }
  }

  const whatsappclick = async (minidata) => {
    const phoneNumber = contactInfo?.contactInfoPhone;
    const UserphoneNumber = minidata.phone;
    const { business_id, product_name, product_price, product_service,product_service_slug } = minidata?.item;
    const countryCode = minidata.countryCode;
    const enquiryData = {
      business_id: business_id,
      name: `${product_name}`, // Assuming name is not provided in minidata
      message: `${minidata?.description}`, // You can customize this message as needed
      phone: UserphoneNumber,
      countryCode: countryCode,
      product_id: minidata.item.id,
      enquiry_from: 0,
      captchaAns:minidata?.captchaAns
    };
    try {
      // Make the API call to create_mini_mkt_Enquiry
      const response = await axios.post(`${environmentVariables?.apiUrl}api/v1/business/create_minimkt_enquiry`, enquiryData);
      if (response.status === 201) {
        // console.log('Enquiry submitted successfully');
        const message = encodeURIComponent(`Hi, I'd like to enquire about this product.\nProduct details:\nName: ${product_name}, \ncategory: ${product_service_slug}`);
        const apiUrl = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${message}`;
        window.open(apiUrl, '_blank');
      } else {
        console.error('Failed to submit enquiry:', response.data.message);
        toast.error(response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      }
    } catch (error) {
      console.error('Error submitting enquiry:', error.message);
      toast.error(error?.response?.data?.message, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
    }
  };



  const renderContent = () => {
    switch (activeTab) {
      case "home":
        return <div> <ExtHomeComponent currencySymbol={currencySymbol} prodtdata={rs} sendDatafromParent = {handleChildData} prc={mmProducts} currentPage={currentPage}
                totalPages={totalPages}
                onNextPage={handleNextPage}
                onPrevPage={handlePrevPage}
                sendDatafromhomecomponet={handleChildcomData}
                
                /> 
                </div>;
      case "aboutus":
        return <div className="flex text-justify font-Tajawal text-[#101010] font-base text-lg">
              <ExtAboutus abouttext={rs}/>
        </div>;
      case "contactinfo":
        return <div><ExtContactInfo contdata={rs}/></div>;
      default:
        return null;
    }
  };

  useEffect(() => {
    if (rs?.is_mini_mktproduct == 1) {
      setActiveTab("home");
    } else {
      setActiveTab("aboutus");
    }
  }, [rs?.minimktproductArr[0]?.is_enabled]);


  const getImage = (image) => {
    if (image.includes("base64")) {
      return image;
    } else {
      return `${environmentVariables?.apiUrl}uploads/${image}`;
    }
  };

  const downloadCsv = async(bid) => {
    try {
      const response = await axios.post(`${environmentVariables?.apiUrl}api/v1/business/exportProducts`,{"bid":bid}, {
        responseType: 'blob', // Important: Specify response type as 'blob'
      });
      
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const a = document.createElement('a');
      a.href = url;
      const timestamp = moment().format('YYYY-MM-DDHH:mm:ss');      
      a.download = `products${timestamp}.csv`; // Specify the file name
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } catch (error) {
      console.error('Error downloading CSV:', error);
    }
  };

  return (
    <div id="temp20">
      <div className="main-temaplt" id="temp20">
        <div className="container">
          <div className="row justify-content-center">
          <div className="col-12 wtemplate p-4" style={{minHeight:"100vh"}}>
            {/* <ProductDetailpage /> */}
            {/* <OrdersItem /> */}
          <div>
          <div className="relative">
            <img src={rs?.banner ? getImage(rs.banner) : Banner} className="relative w-hundred object-cover rounded-xl" style={{maxHeight:"165px", minHeight:"165px"}}/>
            <div style={{position:"absolute", bottom:"10px", left:"10px"}}className="absolute bottom-10 left-10">
              <div className="text-white font-bold text-4xl font-Tajawal break-all capitalize minimarketheading">{rs.title || "Market Name"}</div>
              <div className="flex justify-start w-5 ">
                <img style={{width:"25px", height:"25px"}} src={LocationIcon} />
                <div>
                  <address className="mb-0 mx-2 text-sm font-normal text-white font-Tajawal inline minimarketaddress">{getContactInfo(rs?.contactInfo?.content)}</address>
                </div>
              </div>
            </div>
          </div>

          <div className="px-4 py-1 mx-4 flex justify-around items-center Exthome_tab_spacing">
          {rs?.is_mini_mktproduct == 1 && (
              <div>
                <div className={`my-4 text-lg  cursor-pointer  hover:font-bold hover:text-black hover:transition all ${activeTab === "home" ? "text-black font-bold text-2xl" : ""}`}
                
                onClick={() => setActiveTab("home")}>Home</div>
              </div>
          )}

              <div>
                <div className={`my-4 text-lg  cursor-pointer  hover:font-bold hover:text-black hover:transition all  ${activeTab === "aboutus" ? "text-black font-bold text-2xl" : ""}`}
                  onClick={() => setActiveTab("aboutus")}>About us</div>
              </div>
              {rs?.contactInfo?.is_enabled == 1 && (
              <div>
                <div className={`my-4 text-lg  cursor-pointer  hover:font-bold hover:text-black hover:transition all ${activeTab === "contactinfo" ? "text-black font-bold text-2xl" : ""}`}
                  onClick={() => setActiveTab("contactinfo")}>Contact Info</div>
              </div>
            ) }
          </div>

          <div className="px-2 py-2 ">{renderContent()}</div>

          </div>
    



          {appParams == null && <>
                <div className="fixedd">
                  <article className="fixedfooter">
                    <div className="footerleft">
                      <div
                        className="qrcodeshaere"
                        data-bs-toggle="modal"
                        data-bs-target="#qrscan"
                      >
                        <i class="fa-solid fa-qrcode"></i>
                      </div>
                      {/* <div className="share">
                      <i class="fa-solid fa-arrow-up"></i>
                    </div> */}
                    </div>
                    <div className="addtocinatct" onClick={()=>downloadCsv(rs?.id)}>
                      <i class="fa-solid fa-download"></i>
                      Download
                    </div>
                    <div className="addtocinatct" onClick={downloadVCard}>
                      <i class="fa-solid fa-user-plus"></i>
                      Add To Contact
                    </div>
                  </article>
              </div></>}




          </div>
          </div>
        </div>
      </div>
      <div
        class="modal fade"
        id="qrscan"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-body" style={{ position: "relative" }}>
            {rs && rs.logo ? (
              <img
                className="qr-logo-image"
                src={`${environmentVariables?.apiUrl}uploads/${rs.logo}`}
              />
            ) : (
              <img
                className="qr-logo-image"
                src={whiteCircle}
                alt="White Circle"
              />
            )}
            <QrCodeGenerator />
          </div>
        </div>
      </div>
      <ToastContainer />

    </div>
  );
};

export default Theme20Ext;
