import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { userContext } from "../../context/userContext";
import { toast } from "react-toastify";
import { environmentVariables } from "../../config/env.config";
import 'bootstrap/dist/css/bootstrap.min.css';
import "react-data-table-component-extensions/dist/index.css";
import CircularLoader from "../Components/CircularLoader/CircularLoader";
import "./UpdateRequestcardstyle.css";
import { Modal } from "react-bootstrap";
import { isValidEmail, isValidName, isValidPassword } from "../../utils/utility";

function CreateUser(props) {
  const { userData } = useContext(userContext);
  const [show, setShow] = useState(false);
  const [errorMsgName, setErrorMsgName] = useState(null);
  const [errorMsgEmail, setErrorMsgEmail] = useState(null);
  const [errorMsgPassword, setErrorMsgPassword] = useState(null);
  const [errorMsgRole, setErrorMsgRole] = useState(null);
  const [roleData, setRoleData] = useState(null);
  const [isSubmit, setIsSubmit] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [userCreateData, setUserCreateData] = useState({
    name: "",
    email: "",
    password: "",
    roleId: 0,
  });

  const handleClose = () => setShow(false);

  useEffect(()=>{
    setShow(props.showModal);
    setUserCreateData(props.userCreateData);
    setRoleData(props.roleData);
  },[props])

  const handleChangeUserData = (e, type) => {
    setErrorMsgName("");
    setUserCreateData({ ...userCreateData, [`${type}`]: e.target.value });
  };

  const handleSubmit = () => {
    setErrorMsgName("");
    setErrorMsgEmail("");
    setErrorMsgPassword("");
    setErrorMsgRole("");

    if (userCreateData["name"] === "" || userCreateData["name"]?.trim()?.length == 0) {
      setErrorMsgName("Name is mandatory");
      return;
    }
    else if (!isValidName(userCreateData["name"])) {
      if (userCreateData["name"].length < 3) {
        setErrorMsgName("Name should be at least 3 characters long");
        return;
      } else {
        setErrorMsgName("Name can only contain alphabets, hyphens, and spaces");
        return;
      }
    } else if (userCreateData["email"] === "") {
      setErrorMsgEmail("Email is mandatory");
      return;
    } else if (!isValidEmail(userCreateData["email"])) {
      setErrorMsgEmail("Invalid email format");
      return;
    } else if (!isValidPassword(userCreateData["password"])) {
      setErrorMsgPassword(
        "Password should be at least 8 characters long and contain at least one letter and one digit"
      );
      return;
    } else if (userCreateData["roleId"] === 0) {
      setErrorMsgRole("Role is mandatory");
      return;
    }
    if (
      userCreateData["name"] !== "" &&
      userCreateData["email"] !== "" &&
      userCreateData["password"] !== "" &&
      userCreateData["roleId"] !== 0
    ) {
      let data = JSON.stringify(userCreateData);
      setIsSubmit(true);
      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${environmentVariables?.apiUrl}api/v1/user/createUser`,
        headers: {
          _token: userData?.token,
          "Content-Type": "application/json",
        },
        // withCredentials: true,
        data: data,
      };

      axios
        .request(config)
        .then((response) => {
          handleClose();
          props.handleCreateUser(true);
          setIsSubmit(false);
          toast.success("User created", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
        })
        .catch((error) => {
          setIsSubmit(false);
          toast.error(error?.response?.data?.message || error?.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });

          // setErrorMsg(null);
        });
    } else {
      setIsSubmit(false);
      // setErrorMsg("Please fill all the data");
    }
  };
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title> 
            <h5 class="modal-title" id="exampleModalLabel">
              Create New User
            </h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="modal-body">
            <form class="row">
              <div class="mb-2 col-md-12">
                <input
                  onChange={(e) => {
                    handleChangeUserData(e, "name");
                  }}
                  value={userCreateData["name"]}
                  class="form-control"
                  type="text"
                  placeholder="Name"
                />
                <div
                  style={{
                    fontSize: "14px",
                    color: "red",
                    bottom: "70px",
                  }}
                >
                  {errorMsgName}
                </div>
              </div>
              <div class="mb-2 col-md-12">
                <input
                  value={userCreateData["email"]}
                  onChange={(e) => handleChangeUserData(e, "email")}
                  class="form-control"
                  type="text"
                  placeholder="Email"
                />
                <div
                  style={{
                    fontSize: "14px",
                    color: "red",

                    bottom: "70px",
                  }}
                >
                  {errorMsgEmail}
                </div>
              </div>
              <div class="mb-2 col-md-12">
                <input
                  class="form-control"
                  type={showPassword ? "text" : "password"}
                  placeholder="Password"
                  value={userCreateData["password"]}
                  onChange={(e) => handleChangeUserData(e, "password")}
                />
                <span
                  onClick={togglePasswordVisibility}
                  style={{
                    position: "absolute",
                    right: "25px",
                    top: "50%",
                    transform: "translateY(-50%)",
                    cursor: "pointer",
                  }}
                >
                  {showPassword ? (
                    <i className="fas fa-eye Eeye"></i>
                  ) : (
                    <i className="fas fa-eye-slash Eeye"></i>
                  )}
                </span>
                <div
                  style={{
                    fontSize: "14px",
                    color: "red",

                    bottom: "70px",
                  }}
                >
                  {errorMsgPassword}
                </div>
              </div>
              <div class="mb-2 col-md-12">
                <select
                  value={userCreateData["roleId"]}
                  onChange={(e) => handleChangeUserData(e, "roleId")}
                  class="form-select"
                >
                  <option value={""}>User Role</option>
                  {roleData &&
                    roleData.map((val, inde) => (
                      <option key={inde} value={val?.role_id}>{val?.role_name} </option>
                    ))}
                </select>
                <div
                  style={{
                    fontSize: "14px",
                    color: "red",

                    bottom: "70px",
                  }}
                >
                  {errorMsgRole}
                </div>
              </div>
              <div class="mb-2 flex-center col-md-12">
                <button
                  onClick={handleSubmit}
                  class="btn btn-primary me-3 save-cta"
                  type="button"
                >
                  {isSubmit ? <CircularLoader size={20} /> : "Save"}
                </button>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default CreateUser;
