import React from 'react'

function BusinessHourMake() {
  return (
    <div className='servicebox tile'>

    <div className='col-12 col-lg-12'>
      <div className='row bg-headeing mb-4'>
        <div className='col-12 col-lg-4'>Day</div>
        <div className='col-12 col-lg-4'>Start Time</div>
        <div className='col-12 col-lg-4'>End Time</div>
      </div>

      <div className='row mb-4'>
        <div className='col-12 col-lg-4'><div class="form-check">
  <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault"/>
  <label class="form-check-label" for="flexCheckDefault">
  Tuesday
  </label>
</div></div>
        <div className='col-12 col-lg-4'><input class="form-control" type="time" placeholder="Enter your name"/></div>
        <div className='col-12 col-lg-4'><input class="form-control" type="time" placeholder="Enter your name"/></div>
      </div>
      <div className='row mb-4'>
        <div className='col-12 col-lg-4'><div class="form-check">
  <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault"/>
  <label class="form-check-label" for="flexCheckDefault">
  Tuesday
  </label>
</div>
</div>
        <div className='col-12 col-lg-4'><input class="form-control" type="time" placeholder="Enter your name"/></div>
        <div className='col-12 col-lg-4'><input class="form-control" type="time" placeholder="Enter your name"/></div>
      </div>
      </div>
</div>
  )
}

export default BusinessHourMake