import React, { useContext, useEffect, useState } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js/pure";
import CheckoutFormPlan from "./CheckoutFormPlan";
import { useLocation } from "react-router";
import axios from "axios";
import { environmentVariables } from "../../config/env.config";
import { userContext } from "../../context/userContext";
import { currencyContext } from "../../context/currencyContext.js";
import PageName from "../Utils/PageName.js";

// let stripePromise;
const PaymentPlan = () => {

  const stripePromise = loadStripe(
    process.env.REACT_APP_STRIPE_KEY
  );

  // useEffect(()=>{
  //   console.log(process.env,"process.env.REACT_APP_STRIPE_KEY")
  // console.log(stripePromise,"strip ePromisestripePromise")
  // },[stripePromise])
  // const [stripeKey, setStipeKey] = useState("");
  // useEffect(() => {
  //   if (stripeKey && stripeKey.length) {
  //     console.log(stripeKey, "@#$@#$@ stripeKey");
  //     stripePromise = loadStripe(stripeKey);
  //   }
  // }, [stripeKey]);
  const [stripeName, setStipeName] = useState("STRIPE_SECRET_PK");
  const { userData, setUserData } = useContext(userContext);
  const [plan, setPlan] = useState({});
  const baseUrl = environmentVariables?.apiUrl;
  const [planRs, setPlanRs] = useState({});
  const location = useLocation();
  const clientSecret = location.state && location.state.data;
  const amount = location.state && location.state.amount;
  const cardId = location.state && location.state.cardId;
  const planId = location.state && location.state.planId;
  const options = {
    clientSecret: clientSecret,
  };
  const { currencyObjData, setCurrencyObjData } = useContext(currencyContext);
  // console.log(currencyObjData,"currencyObjData123")

  const getPlanName = () => {
    console.log("planId===>>>", planId);
    axios
      .get(`${baseUrl}api/v1/plan/fetchPlanByIdForUser`, {
        params: { planId: planId },
        headers: {
          _token: userData?.token,
        },
        // withCredentials: true,
      })
      .then((res) => {
        setPlanRs(res?.data?.result);
        localStorage.setItem("plan_pay", JSON.stringify(res?.data?.result));
      })
      .catch((err) => console.log(err));
  };
  // const getStripkey = () => {
  //   try {
  //     console.log("isndei stripek");
  //     axios
  //       .get(`${baseUrl}api/v1/plan/fetchStripeKey?stripeName=${stripeName}`, {
  //         headers: {
  //           _token: userData?.token,
  //         },
  //       })
  //       .then((res) => {
  //         console.log(res?.data?.data?.value, "response ");
  //         setStipeKey(res?.data?.data?.value);
  //       });
  //   } catch (err) {
  //     console.log(err, "error in fetch ");
  //   }
  // };
  useEffect(() => {
    getPlanName();
    // getStripkey();
  }, []);
  console.log("planRs====>>>", planRs);
  return (
    <>
      <PageName PageName="Payment" />

      <div class="container mt-3 w-50">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">Payment Information</h5>
            <p class="card-text">
              Receiver's name: Businessbay
              <br />
              {`Amount : ${currencyObjData?.symbol} ${( planRs.price)}`}
              <br />
              {`Plan :  ${planRs.name}`}
            </p>
          </div>

          <div className="mt-3 w-100 px-3">
              <Elements stripe={stripePromise} options={options}>
                <CheckoutFormPlan
                  // cardId={cardId}
                  planId={planRs?.id}
                  clientSecret={clientSecret}
                />
              </Elements>
          </div>
        </div>
      </div>
    </>
  );
};

export default PaymentPlan;
