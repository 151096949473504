import React from "react";
import { useState, useEffect } from "react";
import { fetchFiles } from "../../../utils/utility";

function SliderViewImageForCard(props) {
  const [imageSrc, setImageSrc] = useState(props?.defaultImage);
  useEffect(() => {
      const fetchData = async () => {
          const fileData = await fetchFiles(props?.fileName, props?.cardName);
          if (fileData) {
              setImageSrc(`data:image/jpeg;base64,${fileData}`);
          }
      };

      fetchData();
  }, [props?.fileName, props?.cardName]);

  return (
    <div>
        <img
            src={imageSrc}
            className="mx-auto d-block img-fluid"
            alt={props?.cardName}
        />
    </div>
  );
}

export default SliderViewImageForCard;