import React from "react";
import { useState } from "react";


function ServicesMake() {
  const [formList, setFormList] = useState([]);
  const [formCount, setFormCount] = useState(0);

  const createForm = () => {
    const key = { formCount };
    const newForm = (
      <div className="servicebox tile">
        <i
          className="fa-solid fa-circle-xmark"
          onClick={() => removeForm(formCount)}
        ></i>

        {/* <div className="boxbanner">      

     <label className="form-label">Banner:</label>
     <div className="bannerbox"><img src="" className="mx-auto d-block img-fluid"/></div>
     <input className="form-control" type="file" placeholder="Title"/>
     </div> */}

        <div className="mb-3">
          <label className="form-label">Name</label>
          <input className="form-control" type="text" placeholder="Name" />
        </div>
        {/* <div className="mb-3">
                  <label className="form-label">Description:</label>
                  <textarea className="form-control" rows="4" placeholder="Description"></textarea>
                </div>
                <div className="mb-3">
                  <label className="form-label">Button Name:</label>
                  <input className="form-control" type="text" placeholder="Button Name"/>
                </div>
                <div className="mb-3">
                  <label className="form-label">Button link:</label>
                  <input className="form-control" type="text" placeholder="Button link"/>
                </div> */}
      </div>
    );
    setFormList([...formList, newForm]);
    setFormCount(formCount + 1);
  };

  const removeForm = (formKey) => {
    const updatedFormList = formList.filter((form) => form.key !== formKey);
    setFormList(updatedFormList);
  };

  return (
    <>
      <div className="row" id="AddNewStyleForm">
        {formList.map((form, index) => (
          <div key={form.key} className="col-12 col-lg-6">
            {form}
          </div>
        ))}
      </div>

      <button type="button" onClick={createForm} className="btn btn-cu">
        + Add
      </button>
    </>
  );
}

export default ServicesMake;
