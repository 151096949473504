import React, { useState, useEffect } from 'react';
import axios from "axios";
// import './App.css';


 

const SuccessDisplay = ({ sessionId }) => {
  return (
    <section>
      <div className="product Box-root">
        <div className="description Box-root">
          <h3>Subscription to starter plan successful!</h3>
        </div>
      </div>
      <form action="/create-portal-session" method="POST">
        <input
          type="hidden"
          id="session-id"
          name="session_id"
          value={sessionId}
        />
        <button id="checkout-and-portal-button" type="submit">
          Manage your billing information
        </button>
      </form>
    </section>
  );
};

const Message = ({ message }) => (
  <section>
    <p>{message}</p>
  </section>
);

export default function StripeSubscription() {
  let [message, setMessage] = useState('');
  let [success, setSuccess] = useState(false);
  let [sessionId, setSessionId] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Serialize the form data into a JSON object
    const formData = new FormData(e.target);
    const formDataObject = {};
    formData.forEach((value, key) => {
      formDataObject[key] = value;
    });

    try {
      // Make a POST request to your server using Axios
      const response = await axios.post('http://localhost:5000/api/v1/pg/StripeCreateCheckoutSession', formDataObject);
      // Handle the response as needed
      if (response.data.status === 'success') {
        const checkoutUrl = response.data.data.url;
        // Redirect the user to the Stripe Checkout URL
        window.location.href = checkoutUrl;
      }
    } catch (error) {
      // Handle errors, e.g., display an error message to the user
      console.error(error);
    }
  }

  useEffect(() => {
    // Check to see if this is a redirect back from Checkout
    const query = new URLSearchParams(window.location.search);

    if (query.get('success')) {
      setSuccess(true);
      setSessionId(query.get('session_id'));
    }

    if (query.get('canceled')) {
      setSuccess(false);
      setMessage(
        "Order canceled -- continue to shop around and checkout when you're ready."
      );
    }
  }, [sessionId]);

    return (
          <>
            <section>
              <div className="product">
                <div className="description">
                  <h3>Starter plan</h3>
                  <h5>100.00 / month</h5>
                </div>
              </div>
              <form onSubmit={handleSubmit}>
                  <input type="hidden" name="lookup_key" value="200" />
                  <button id="checkout-and-portal-button" type="submit">
                    Checkout
                  </button>
              </form>
            </section>
        </>
        )
   }