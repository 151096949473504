import { createContext } from "react";

export const businessContext = createContext(null);

export const isBusinesCreatedContext = createContext(null);



export const businessNameContext = createContext([])

