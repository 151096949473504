import React, { useEffect, useState } from "react";
import logopic from "../../../Images/logoo.png";
import { useNavigate } from "react-router-dom";
import { environmentVariables } from "../../../config/env.config";
import {
  InnerHeaderWrapper,
  InnerLogoWrapper,
  Logo,
  InnerContainer,
  HeadingWrapper,
  InnerNameWrapper,
  InnerNamePara,
  InnerContactLists,
  InnerContactList,
  ContactListHeading,
  ContactListPara,
  ContactLink,
  InnerListLeft,
  InnerListRight,
  InnerContactHeading,
  InnerSummaryLists,
  SumaryList,
  InnerSummaryHeading,
  InnerSummaryPara,
  InnerProjectSummaryPara,
  InnerWorkHeading,
  InnerWorkList,
  InnerWorkInnerHeading,
  InnerTextWrapper,
  InnerWorkInnerPara,
  WorkInnerLists,
  InnerWorkInnerList,
  InnerSkillListB,
  InnerProductSubHeadingOrg,
  InnerProductSubHeading,
  InnerProductLink,
  EducationWrapper,
  InnerEducationLeft,
  InnerEducationRight,
  InnerEducationDegree,
  InnerEducationTime,
  InnerEducationCollege,
  InnerSkillWrapper,
  InnerWorkInnerPara2,
  InnerCertifiWorkInnerPara,
  InnerSkillList,
  ToolWrapper,
  ToolWrapperList,
  InnerToolPara,
  ProgressWrapper,
  ProgressContainer,
  ProgressSkill,
  AchievementWrapper,
  PersonalWrapper,
  InnerPersonalList,
  InnerPersonalTitle,
  PersonalText,
  InnerWrapperInner,
  InnerSumaryList,
} from "./Resume4ExtStyle";

const Resume4 = (props) => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const baseUrl = environmentVariables?.apiUrl;
  const navigate = useNavigate();

  const formatDate = (dateString) => {
    if (!dateString) return ""; // Return empty string if dateString is falsy

    const date = new Date(dateString); // Parse the date string
    const options = { month: "long", year: "numeric" }; // Format options
    return date.toLocaleDateString("en-US", options); // Format date to "Month-YYYY" format
  };

  const updateScreenWidth = () => {
    setScreenWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", updateScreenWidth);
    return () => {
      window.removeEventListener("resize", updateScreenWidth);
    };
  }, []);

  const renderSkills = () => {
    if (props?.onState?.services) {
      return (
        <InnerWrapperInner>
          <InnerSummaryLists>
            <InnerWorkHeading> key skill</InnerWorkHeading>
            <InnerSkillWrapper>
              {props.skillArray.map((skill, index) => (
                <InnerSkillList key={index}>{skill.skillname}</InnerSkillList>
              ))}
            </InnerSkillWrapper>
          </InnerSummaryLists>
        </InnerWrapperInner>
      );
    } else {
      return null;
    }
  };
  const renderWrkExp = () => {
    if (props?.onState?.workExp) {
      return (
        <InnerWrapperInner>
          <InnerSummaryLists>
            <InnerWorkHeading>Work experience</InnerWorkHeading>
            {props.workExpArray?.map((wrkExp, index) => (
              <InnerWorkList key={index}>
                <InnerWorkInnerHeading>
                  {wrkExp?.jobtitle}
                  <InnerTextWrapper>
                    {` ${formatDate(wrkExp?.startdate)} - ${
                      wrkExp?.enddate === "current"
                        ? "Current"
                        : formatDate(wrkExp?.enddate)
                    }`}
                  </InnerTextWrapper>
                </InnerWorkInnerHeading>
                <InnerWorkInnerPara>
                  {/* {`${wrkExp?.companyname}, ${wrkExp?.cityname}`} */}
                  {`${wrkExp?.companyname || ""} ${wrkExp?.cityname || ""}`}
                </InnerWorkInnerPara>
                <WorkInnerLists style={{paddingLeft:"0px"}} >
                  {wrkExp?.workSummary?.split("\n").map((summary, idx) => (
                    <InnerWorkInnerList key={idx}>{summary}</InnerWorkInnerList>
                  ))}
                </WorkInnerLists>
              </InnerWorkList>
            ))}
          </InnerSummaryLists>
        </InnerWrapperInner>
      );
    } else {
      null;
    }
  };

  const renderProjects = () => {
    if (props?.onState?.project) {
      return (
        <InnerWrapperInner>
          <InnerSummaryLists>
            <InnerSummaryHeading style={{ paddingLeft: "22px" }}>
              PROJECT
            </InnerSummaryHeading>
            {props.projectArray.map((project, index) => (
              <InnerSumaryList SummaryList key={index}>
                <InnerProductSubHeading>
                  {project.projectname}
                </InnerProductSubHeading>
                <InnerWorkInnerPara2>
                  {`${formatDate(project.startDate)} - ${
                    project.currentlyWorking
                      ? "Current"
                      : formatDate(project.endDate)
                  }`}
                </InnerWorkInnerPara2>
                <InnerProjectSummaryPara>
                  {project.projectdescription}
                </InnerProjectSummaryPara>
                <InnerProductLink href={project.projectlink} target="_blank">
                  {project.projectlink}
                </InnerProductLink>
              </InnerSumaryList>
            ))}
          </InnerSummaryLists>
        </InnerWrapperInner>
      );
    } else {
      return null;
    }
  };

  const renderTools = () => {
    if (props?.onState?.tools) {
      return (
        <InnerWrapperInner>
          <InnerSummaryLists>
            <InnerWorkHeading>tools</InnerWorkHeading>
            <ToolWrapper>
              <ToolWrapperList>
                {props.toolsArray.length > 0
                  ? props.toolsArray.map((tool, index) => (
                      <InnerToolPara key={index}>{tool.toolname}</InnerToolPara>
                    ))
                  : null}
              </ToolWrapperList>
            </ToolWrapper>
          </InnerSummaryLists>
        </InnerWrapperInner>
      );
    } else {
      return null;
    }
  };

  const renderEducation = () => {
    if (props?.onState?.isExperience) {
      return (
        <InnerWrapperInner>
          <InnerSummaryLists>
            <InnerWorkHeading> education </InnerWorkHeading>
            {props.socialMediaData.map((education, index) => (
              <InnerWorkList key={index}>
                <EducationWrapper>
                  <InnerEducationLeft>
                    <InnerEducationDegree>
                      {education.degree}
                    </InnerEducationDegree>
                    <InnerEducationCollege>
                      {education.schuniname}
                    </InnerEducationCollege>
                  </InnerEducationLeft>
                  <InnerEducationRight>
                    <InnerEducationTime>
                      ({formatDate(education.graduationyear)} -{" "}
                      {education.graduationendyear
                        ? formatDate(education.graduationendyear)
                        : ""}
                      )
                    </InnerEducationTime>
                  </InnerEducationRight>
                </EducationWrapper>
              </InnerWorkList>
            ))}
          </InnerSummaryLists>
        </InnerWrapperInner>
      );
    } else {
      return null;
    }
  };

  const renderCertifications = () => {
    if (props?.onState?.certification) {
      return (
        <InnerWrapperInner>
          <InnerSummaryLists>
            <InnerSummaryHeading style={{ paddingLeft: "22px" }}>
              Certifications
            </InnerSummaryHeading>
            {props.certificationArray.length > 0
              ? props.certificationArray.map((certification, index) => (
                  <SumaryList key={index}>
                    <InnerProductSubHeading>
                      {certification.certificatename}
                    </InnerProductSubHeading>
                    <InnerProductSubHeadingOrg>
                      {certification.organisationname}
                    </InnerProductSubHeadingOrg>
                    <InnerCertifiWorkInnerPara>
                      {formatDate(certification.date)}
                    </InnerCertifiWorkInnerPara>
                  </SumaryList>
                ))
              : null}
          </InnerSummaryLists>
        </InnerWrapperInner>
      );
    } else {
      return null;
    }
  };

  const renderLanguage = () => {
    if (props?.onState?.language) {
      return (
        <InnerWrapperInner>
          <InnerSummaryLists>
            <InnerWorkHeading> Languages </InnerWorkHeading>
            {props.languageArray.length > 0 ? (
              <InnerSkillWrapper>
                {props.languageArray.map((language, index) => (
                  <InnerSkillListB key={index}>
                    {language.languagename}
                  </InnerSkillListB>
                ))}
              </InnerSkillWrapper>
            ) : null}
          </InnerSummaryLists>
        </InnerWrapperInner>
      );
    } else {
      return null;
    }
  };

  const renderAdditionalDetail = () => {
    if (props?.onState?.adddetails) {
      return (
        <InnerWrapperInner>
          <InnerSummaryLists>
            <InnerWorkHeading> Hobbies </InnerWorkHeading>
            {props.additionaldetailArray.length > 0 ? (
              <InnerSkillWrapper>
                {props.additionaldetailArray.map((detail, index) => (
                  <InnerSkillListB key={index}>
                    {detail.skillname}
                  </InnerSkillListB>
                ))}
              </InnerSkillWrapper>
            ) : null}
          </InnerSummaryLists>
        </InnerWrapperInner>
      );
    } else {
      return null;
    }
  };

  const renderAchievement = () => {
    if (props?.onState?.achievement) {
      return (
        <InnerWrapperInner>
          <InnerSummaryLists>
            <InnerWorkHeading> Achievements </InnerWorkHeading>
            {props.achievementArray.length > 0 ? (
              <AchievementWrapper>
                <WorkInnerLists style={{padding:"0px"}}>
                  {props.achievementArray.map((achievement, index) => (
                    <InnerWorkInnerList InnerAchivement key={index} color="#2F2F2F">
                      {achievement.skillname}
                    </InnerWorkInnerList>
                  ))}
                </WorkInnerLists>
              </AchievementWrapper>
            ) : null}
          </InnerSummaryLists>
        </InnerWrapperInner>
      );
    } else {
      return null;
    }
  };

  const renderPrsnldetails = () => {
    if (props?.onState?.prsnldetail) {
      return (
        <InnerWrapperInner>
          <InnerSummaryLists>
            <InnerWorkHeading> PERSONAL INFORMATION </InnerWorkHeading>
            <PersonalWrapper>
              <InnerPersonalList>
                <InnerPersonalTitle>Father/ Spouse Name</InnerPersonalTitle>
                <PersonalText>
                  {props?.formikData?.values?.fathername}
                </PersonalText>
              </InnerPersonalList>
              <InnerPersonalList>
                <InnerPersonalTitle>Marital Status</InnerPersonalTitle>
                <PersonalText>
                  {props?.formikData?.values?.maritalstatus}
                </PersonalText>
              </InnerPersonalList>
              <InnerPersonalList>
                <InnerPersonalTitle>DOB</InnerPersonalTitle>
                <PersonalText>
                  {props?.formikData?.values?.userdob}
                </PersonalText>
              </InnerPersonalList>
            </PersonalWrapper>
          </InnerSummaryLists>
        </InnerWrapperInner>
      );
    } else {
      return null;
    }
  };

  return (
    <div style={{ width: "420px" }}>
      <InnerHeaderWrapper>
        <InnerLogoWrapper>
          <Logo
            src={
              props?.logoImageUrl
                ? props?.logoImageUrl?.includes("base64")
                  ? props?.logoImageUrl
                  : props?.logoImageUrl == "null"
                  ? ""
                  : `${baseUrl}uploads/resume/${props?.logoImageUrl}`
                : logopic
            }
            alt="logo"
          />
        </InnerLogoWrapper>
      </InnerHeaderWrapper>
      <InnerContainer>
        <InnerWrapperInner>
          <HeadingWrapper>
            <InnerNameWrapper>
              {props?.formikData?.values?.fullName || "Sushant"}
            </InnerNameWrapper>
            <InnerNamePara>{props?.designation || "React"}</InnerNamePara>
          </HeadingWrapper>

          <InnerContactLists>
            <InnerContactHeading> contact</InnerContactHeading>
            <InnerListLeft>
              <InnerContactList>
                <ContactListHeading>PHONE</ContactListHeading>
                <ContactListPara>
                  {props?.formikData?.values?.phone}
                </ContactListPara>
              </InnerContactList>

              <InnerContactList>
                <ContactListHeading>MAIL</ContactListHeading>
                <ContactListPara>
                  {props?.formikData?.values?.email}
                </ContactListPara>
              </InnerContactList>
            </InnerListLeft>

            <InnerListRight>
              <InnerContactList>
                <ContactListHeading>ADDRESS</ContactListHeading>
                <ContactListPara>
                  {props?.formikData?.values?.address}
                </ContactListPara>
              </InnerContactList>

              <InnerContactList>
                <ContactListHeading>SOCIAL LINK</ContactListHeading>
                <ContactLink href="/">
                  {props?.formikData?.values?.linkedIn}
                </ContactLink>
              </InnerContactList>
            </InnerListRight>
          </InnerContactLists>
        </InnerWrapperInner>

        <InnerWrapperInner>
          <InnerSummaryLists>
            <SumaryList>
              <InnerSummaryHeading>summary</InnerSummaryHeading>
              <InnerSummaryPara>
                {props?.formikData?.values?.description}
              </InnerSummaryPara>
            </SumaryList>
          </InnerSummaryLists>
        </InnerWrapperInner>

        {props?.onState?.workExp && renderWrkExp()}

        {props?.onState?.project && renderProjects()}

        {props?.onState?.isExperience && renderEducation()}

        {props?.onState?.services && renderSkills()}

        {props?.onState?.tools && renderTools()}

        {props?.onState?.certification && renderCertifications()}

        {props?.onState?.achievement && renderAchievement()}

        {props?.onState?.prsnldetail && renderPrsnldetails()}

        {props?.onState?.language && renderLanguage()}

        {props?.onState?.adddetails && renderAdditionalDetail()}
      </InnerContainer>
    </div>
  );
};

export default Resume4;
