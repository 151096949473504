import React from "react";
import "./LandingFoolterStyle.css";


function Term() {
  return (
    <div>
      <section className="pagecover">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-12">
              <h1>Terms and Conditions </h1>
            </div>
          </div>
        </div>
      </section>

      <section className="tandcpage" id="term_condition_Wrapper">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-12">
              <div className="conatntwrap pri">
                <div>
                  <div class="Term_condition_heading_paragraph">
                    {/* <p>
                      <p class="Term_condition_heading" >Introduction:</p>
                    </p> */}
                    <p class="Term_condition_paragraph">
                    This Agreement outlines the terms and conditions governing your use of BusinessBay.io, a platform facilitating global trade between suppliers and buyers. By accessing or using this website, you ("User" or "You") agree to be bound by the terms set forth by Metaspace Technologies Pvt. Ltd. ("We," "Us," or "Metaspace Technologies Pvt. Ltd."). The terms "You," "Your," or "User(s)" refer to any individual or entity accessing the website for any purpose. 
                    </p>
                    <p class="Term_condition_paragraph">
                    By subscribing to, engaging with other User(s), or participating in negotiations related to the sale or supply of goods or services through the website or Metaspace Technologies Pvt. Ltd. Services, you acknowledge and agree that you have thoroughly read, understood, and accepted the terms of this Agreement.
                    </p>
                    <p class="Term_condition_paragraph">
                 
                    If you do not agree with or wish to be bound by this Agreement, you are not permitted to access or use the website in any way.

                    </p>
                  </div>
                  <div class="Term_condition_heading_paragraph"> 
                    <p>
                      <p class="Term_condition_heading">I. WEBSITE AS A PLATFORM</p>
                    </p>
                    <p class="Term_condition_paragraph"> 
                    The website serves as a platform for User(s) to connect, negotiate, and engage with one another in discussions regarding the sale or supply of goods, their business creation, and registration, along with their digital and physical NFC Cards requirements. 
                    </p>
                    <p class="Term_condition_paragraph">
                    BusinessBay/Metaspace Technologies Pvt. Ltd. does not oversee, nor is it liable for, the quality, safety, authenticity, legality, or availability of the products or services offered on BusinessBay.io. Additionally, we are not responsible for ensuring that user(s) can have their commitments fulfilled once they offered or purchased goods or services, and created and listed their business with us. This Agreement does not establish any partnership, joint venture, or other collaborative business relationship between Metaspace Technologies Pvt. Ltd. and any other parties.
                    </p>
                  </div>
                  <div class="Term_condition_heading_paragraph">
                    <p>
                      <p class="Term_condition_heading">II. SERVICES PROVIDED BY BusinessBay</p>
                    </p>
                    <p class="Term_condition_paragraph">
                    BusinessBay offers the following services to its customers, with their respective definitions outlined below:
                    </p>
                    <p class="Term_condition_paragraph">
                     <strong>Leading Supplier-</strong> This service provides user(s) with priority listings within their chosen categories on BusinessBay, enhancing the visibility of their products.
                    </p>
                    <p class="Term_condition_paragraph">
                    <strong>Business Creation-</strong>  Whether you’re a manufacturer, wholesaler, distributor, or service provider, you can easily create a detailed profile that showcases your products or services. Highlight your unique selling points, upload high-quality images, and provide all the necessary information to attract potential customers and partners.
                    </p>

                    <p class="Term_condition_paragraph">
                    <strong>NFC Cards- </strong>  An eco-friendly networking tool that enables users to share their contact information with a single tap on a smartphone. Provide your details and have your digital landing page created within minutes. 
                    </p>

                    <p class="Term_condition_paragraph">
                    <strong>List Your Business-  </strong> List your business with us to connect with a wide network of potential partners, customers, and suppliers. Increase your visibility and expand your reach in the B2B marketplace. Whether you're a restaurant owner, a cab service provider, or a retail business, we ensure your listing reaches those who need your services the most.
                    </p>


                    <p class="Term_condition_paragraph">
                    <strong>Star Supplier- </strong> An additional service that grants User(s) a priority listing within their selected product categories. This service offers the advantage of increased leads and inquiries.
                    </p>

                    <p class="Term_condition_paragraph">
                    <strong>Verified User(s)- </strong> Users are considered verified if any of their primary or secondary contact information, including mobile number or email address, has been verified by Metaspace Technologies Pvt. Ltd.
                    </p>
                  
                  </div>
                  <div class="Term_condition_heading_paragraph">
                    <p>
                      <p class="Term_condition_heading">III. USER ELIGIBILITY</p>
                    </p>
                    <p class="Term_condition_paragraph">
                    User(s) affirm that they are legally entitled to use the services offered by BusinessBay.io., including but not limited to accessing the website or any other services related to the website ("Metaspace Technologies Pvt. Ltd. Services"). These services are available only to individuals as well as business entities, such as sole proprietorships, companies, and partnerships, that are legally authorized to form binding contracts under applicable law. Therefore, individuals under the age of 18 and business entities or organizations that are not legally permitted to operate in India or other jurisdictions are not allowed to use Metaspace Technologies Pvt. Ltd. 's Services.
                    </p>

                    <p class="Term_condition_paragraph">
                    User(s) agree to comply with this Agreement and any other applicable laws, regulations, or guidelines that may be imposed from time to time. Neither BusinessBay nor Metaspace Technologies Pvt. Ltd., bears any responsibility for the content, information, or other materials shared via Metaspace Technologies Pvt. Ltd.'s services, including any fraudulent, false, misleading, inaccurate, defamatory, offensive, or unlawful content. The responsibility and risk associated with such content lie entirely with each user. Users are advised to conduct their due diligence before engaging in any transactions with other users on the website. Metaspace Technologies Pvt. Ltd. reserves the right, at its sole discretion, to refuse service to anyone at any time. Additionally, our services are not available to User(s) whose accounts have been temporarily or permanently suspended by BusinessBay/Metaspace Technologies Pvt. Ltd.
                    </p>

                  </div>
                  <div class="Term_condition_heading_paragraph">
                    <p>
                      <p class="Term_condition_heading">IV. USER AGREEMENT</p>
                    </p>
                    <p class="Term_condition_paragraph">
                    This Agreement applies to any individual or entity that accesses or uses the platform’s services for any purpose. It also applies to any legal entity represented by an individual accessing or using the website, whether under actual or apparent authority. User(s) may utilize the BusinessBay.io and Metaspace Technologies Pvt. Ltd.’s Services solely for commercial or business purposes. Any transfer, sublicense, or assignment of rights, access, or privileges associated with any services provided by BusinessBay to a third party is strictly prohibited without obtaining explicit prior written consent from BusinessBay.
                    </p>
                    <p class="Term_condition_paragraph">
                    This Agreement governs all services offered on BusinessBay by Metaspace Technologies Pvt. Ltd., along with any additional terms and conditions applicable to specific services used or accessed by user(s) on BusinessBay. In the event of any conflict or inconsistency between the provisions of this Agreement and the additional terms and conditions for a specific service, the additional terms and conditions shall prevail. This Agreement supersedes any previous terms, agreements, or contracts executed between Metaspace Technologies Pvt. Ltd. and the User.
                    </p>
                  </div>
                  <div class="Term_condition_heading_paragraph">
                    <p>
                      <p class="Term_condition_heading">V. AMENDMENT TO USER AGREEMENT</p>
                    </p>
                    <p class="Term_condition_paragraph">
                    Metaspace Technologies Pvt. Ltd. reserves the right to amend, modify, or update this Agreement at any time. Such changes will become effective immediately upon being posted on our website. If you do not agree with the amended provisions, you must discontinue using the service immediately. Continued use of the service will be considered as acceptance of the revised Agreement.
                    </p>
                  </div>
                  <div class="Term_condition_heading_paragraph">
                    <p>
                      <p class="Term_condition_heading">VI. INTELLECTUAL PROPERTY RIGHTS</p>
                    </p>
                    <p class="Term_condition_paragraph">
                    Metaspace Technologies Pvt. Ltd. is the exclusive owner and lawful licensee of all rights to the website and its content ("website content"). Website Content refers to the design, layout, text, images, graphics, sound, video, and other elements available on the website. This content embodies trade secrets and other intellectual property rights that are protected by global copyright laws and other applicable intellectual property laws. All titles, ownership, and intellectual property rights in the website and its content are owned by Metaspace Technologies Pvt. Ltd., its affiliates, or licensors.
                    </p>
                    <p class="Term_condition_paragraph">
                    All rights not expressly granted under this Agreement by Metaspace Technologies Pvt. Ltd. are reserved. Any information, advertisements, or materials contained on, distributed through, linked to, downloaded, or accessed from the website or in connection with any service offered on the website ("website information") is provided solely for general informational purposes for the personal use of User(s). User(s) fully accept any responsibility and liabilities arising from the use of such Information. Metaspace Technologies Pvt. Ltd. does not endorse, represent, or warrant the accuracy or reliability of any website Information, nor the quality of any products or services obtained by the User(s) as a result of such information.
                    </p>
                    <p class="Term_condition_paragraph">
                    The Information is provided "as is" without any guarantees of completeness, accuracy, timeliness, or the results obtained from the use of the Information. No warranties of any kind, express or implied, including but not limited to, warranties of performance, merchantability, and fitness for a particular purpose, are made. Nothing in this Agreement should be considered a substitute for the independent investigations and sound technical and business judgment of User(s). Under no circumstances shall BusinessBay.io or Metaspace Technologies Pvt. Ltd. be liable for any direct, indirect, incidental, punitive, or consequential damages arising from the use of our services. User(s) acknowledge that any reliance on the Information is at their own risk and understand that the Information has been compiled from publicly available sources. BussinessBay.io respects the rights of such entities and does not infringe on the respective copyrights or business interests of these entities. We reserve the right, at its sole discretion and without any obligation, to make improvements to, or correct any errors or omissions in any portion of the Information.
                    </p>
                  </div>
                  <div class="Term_condition_heading_paragraph">
                    <p>
                      <p class="Term_condition_heading">VII. LINKS TO THIRD-PARTY SITES</p>
                    </p>
                    <p class="Term_condition_paragraph">
                    Our website may include links to third-party sites for the convenience of the user(s). User(s) acknowledge and agree that BusinessBay or Metaspace Technologies Pvt. Ltd. has no control over the content, resources, or materials available on these third-party sites.
                    </p>
                    <p class="Term_condition_paragraph">
                    Metaspace Technologies Pvt. Ltd. may permit user(s) to access content, products, or services provided by third parties through hyperlinks—whether as text links, banners, channels, or other forms—to such third-party websites ("Third-Party Websites"). Metaspace Technologies Pvt. Ltd. strongly encourages user(s) to review the terms of service and privacy policies of these Third-Party websites before accessing or using them. User(s) recognize and agree that Metaspace Technologies Pvt. Ltd. does not oversee the content on Third-Party websites and shall not be held responsible or liable for any content, products, or services available through these Third-Party Websites.
                    </p>
                  </div>{" "}
                  <div class="Term_condition_heading_paragraph">
                    <p>
                      <p class="Term_condition_heading">VIII. TERMINATION</p>
                    </p>
                    <p class="Term_condition_paragraph">
                    While much of the content and features on the website are available to User(s) at no cost, Metaspace Technologies Pvt. Ltd. reserves the right to terminate access to certain areas or features of the website (whether for paying or registered user(s) at any time, without providing a reason, and with or without notice. Metaspace Technologies Pvt. Ltd. also retains the discretion to deny access to specific user(s) to any or all of its services or content without prior notice or explanation, to safeguard the interests of BusinessBay and Metaspace Technologies Pvt. Ltd. and/or other user(s) of the website. Additionally, Metaspace Technologies Pvt. Ltd. reserves the right to restrict, deny, or modify access to the website and its features for different user(s), or to alter or introduce new features without prior notice.
                    </p>
                    <p class="Term_condition_paragraph">
                    While much of the content and features on the website are available to User(s) at no cost, Metaspace Technologies Pvt. Ltd. reserves the right to terminate access to certain areas or features of the website (whether for paying or registered user(s) at any time, without providing a reason, and with or without notice. Metaspace Technologies Pvt. Ltd. also retains the discretion to deny access to specific user(s) to any or all of its services or content without prior notice or explanation, to safeguard the interests of BusinessBay and Metaspace Technologies Pvt. Ltd. and/or other user(s) of the website. Additionally, Metaspace Technologies Pvt. Ltd. reserves the right to restrict, deny, or modify access to the website and its features for different user(s), or to alter or introduce new features without prior notice.
                    </p>
                    <p class="Term_condition_paragraph">
                    Metaspace Technologies Pvt. Ltd. may terminate the membership or subscription of any user(s), either temporarily or permanently, for reasons including but not limited to the following:
                    </p>
                    <p class="Term_condition_paragraph">
                    <strong>(a) </strong>  If a user(s) provides false information in connection with their account registered with Metaspace Technologies Pvt. Ltd., or engages in fraudulent or illegal activities/transactions.
                    </p>
                    <p class="Term_condition_paragraph">
                    <strong>(b) </strong>  If a user(s) violates any terms of the Agreement.
                    </p>
                    <p class="Term_condition_paragraph">
                    <strong>(c) </strong>  If a user(s) uses the website to send spam messages or repeatedly posts the same product information.
                    </p>
                    <p class="Term_condition_paragraph">
                    <strong>(d) </strong>  If a user(s) uploads content unrelated to trade or business cooperation.
                    </p>
                    <p class="Term_condition_paragraph">
                    <strong>(e) </strong>  If a user(s) impersonates another person or business entity to post information or conduct business.
                    </p>
                    <p class="Term_condition_paragraph">
                    <strong>(f) </strong>  If a user(s) engages in unauthorized access, use, modification, or control of the website’s database, network, or related services.
                    </p>
                    <p class="Term_condition_paragraph">
                    <strong>(g) </strong>  If a user(s) acquires another registered user(s)' Username and/or Password by any means.
                    </p>
                    <p class="Term_condition_paragraph">
                    <strong>(h) </strong>  If a user(s) engages in activities that conflict with ethical and honest business practices.
                    </p>
                    <p class="Term_condition_paragraph">
                    Should Metaspace Technologies Pvt. Ltd. terminate the membership of any registered User(s)—including those who have subscribed to paid services—the affected person shall not be entitled to re-enroll or join the website under a new account or name, unless explicitly invited by Metaspace Technologies Pvt. Ltd. in writing. In any case of termination, no subscription or membership fees paid by the User(s) will be refunded. However, in instances where services are discontinued for reasons not listed above, Metaspace Technologies Pvt. Ltd. may, at its discretion, process a pro-rata refund after adjusting any applicable charges.
                    </p>
                  </div>{" "}
                  <div class="Term_condition_heading_paragraph">
                    <p>
                      <p class="Term_condition_heading">IX. REGISTERED USER(S)</p>
                    </p>
                    <p class="Term_condition_paragraph">
                    To become a registered User of the website, a straightforward procedure has been provided for the convenience of user(s) to easily access and use the website.
                    </p>
                    <p class="Term_condition_paragraph">
                    User(s) can register by completing an online registration form available on the website, providing the necessary information such as name, contact details, and business information. Upon successful registration, Metaspace Technologies Pvt. Ltd. will create an account ("Account") for the User(s) and provide a user alias ("User ID") and password ("Password") for secure log-in access. Additionally, Metaspace Technologies Pvt. Ltd., at its discretion, may assign a web-based email or messaging account (“Email Account”) with limited storage capacity for sending and receiving messages. The User(s) will be solely responsible for the content of all communications sent through this account.
                    </p>
                  </div>

                  <div class="Term_condition_heading_paragraph">
                    <p>
                      <p class="Term_condition_heading">X. DATA PROTECTION</p>
                    </p>
                    <p class="Term_condition_paragraph">
                    Personal information provided by User(s) during their use of the website is protected and governed by Metaspace Technologies Pvt. Ltd.’s privacy policy ("Privacy Policy"). To learn more about the Privacy Policy, please click here.
                    </p>
                  
                  </div>

                  <div class="Term_condition_heading_paragraph">
                    <p>
                      <p class="Term_condition_heading">XI. POSTING CONTENT ON THE WEBSITE</p>
                    </p>
                    <p class="Term_condition_paragraph">
                    Some of the content displayed on our website is contributed or posted by third parties. User(s) may post their content in specific sections or services of our platform using the available self-help tools. In some cases, User(s) may need to register or pay to access or use these services.
                    </p>
                    <p class="Term_condition_paragraph">
                    User(s) represent, warrant, and agree that they are solely responsible for ensuring that any material or information they post on the website, provide to the website, or authorize for display on our website does not violate any third-party rights unless they have obtained proper permission from the rightful owner(s). User(s) further represent warrant and agree that they have the legal rights to manufacture, offer, sell, import, and distribute the products they offer and display on the website and that such activities do not infringe on any third-party rights.
                    </p>
                    <p class="Term_condition_paragraph">
                    User(s) agree not to use Metaspace Technologies Pvt. Ltd.’s content and services to send junk mail, chain letters, or engage in spamming. Additionally, registered User(s) agree not to use the Email Account provided by Metaspace Technologies Pvt. Ltd. for publishing, distributing, transmitting, or circulating unsolicited advertising or promotional material. Furthermore, user(s) represent, warrant, and agree that they will not host, display, upload, modify, publish, transmit, store, update, or share any content, material, or information on the website that:
                    </p>

                    <p class="Term_condition_paragraph">
                    <strong> - </strong> Contains fraudulent information, makes fraudulent offers, or involves the sale or attempted sale of counterfeit, stolen, or prohibited items.
                    </p>
                    
                    <p class="Term_condition_paragraph">
                    <strong> - </strong> Belongs to another person or entity without the legal right to use such content.
                    </p>
                    <p class="Term_condition_paragraph">
                    <strong> - </strong> Is intended to defraud other User(s) of the website or is used for any unlawful purpose.
                    </p>
                    <p class="Term_condition_paragraph">
                    <strong> - </strong>Misleads or deceives the recipient about the origin of the message, or is knowingly and intentionally used to communicate information that is patently false, grossly offensive, or menacing/misleading but may reasonably be perceived as a fact.
                    </p>
                    <p class="Term_condition_paragraph">
                    <strong> - </strong> Is intended to harass, financially exploit, or cause harm to any person, entity, or agency.
                    </p>
                   

                  </div>

                  <div class="Term_condition_heading_paragraph">
                    <p>
                      <p class="Term_condition_heading">XII. MISCELLANEOUS</p>
                    </p>
                    <p class="Term_condition_paragraph">
                    The headings in this Agreement are provided for reference purposes only and do not define, limit, interpret, or describe the scope or extent of any section.
                    </p>
                    <p class="Term_condition_paragraph">
                    Metaspace Technologies Pvt. Ltd.’s decision not to enforce any particular right or take action regarding a breach by a user(s) under this Agreement or the Privacy Policy does not constitute a waiver of that right. Similarly, it does not prevent BusinessBay and Metaspace Technologies Pvt. Ltd. from taking action in the event of future or similar breaches.
                    </p>
                    <p class="Term_condition_paragraph">
                    Metaspace Technologies Pvt. Ltd. reserves the right to assign its obligations and duties under this Agreement, or any related agreements concerning its services, to any person or entity.
                    </p>
                    <p class="Term_condition_paragraph">
                    If any provision of this Agreement is found to be invalid or unenforceable, that provision will be removed, and the remaining provisions will continue to be valid and enforceable.
                    </p>
                    <p class="Term_condition_paragraph">All calls to BusinessBay are kept strictly confidential. However, calls may be recorded to ensure quality of service. Additionally, for training purposes and to maintain high standards of customer service, calls from BusinessBay may be monitored and recorded.

                    </p>
                    <p class="Term_condition_paragraph">
                    This Agreement and the Privacy Policy represent the entire agreement between the user(s) and Metaspace Technologies Pvt. Ltd. concerning access to and use of BusinessBay, replacing any prior written or oral agreements related to the same subject matter.
                    </p>
                  
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Term;