import React, { useEffect, useRef, useState } from "react";

import QRCodeStyling from "qr-code-styling";
import whiteCircle from "../../../../Images/white-circle.png";
const qrCode = new QRCodeStyling({
  width: 230,
  height: 228,
  margin: 10,
  image: whiteCircle,
  qrOptions: {
    typeNumber: "0",
    mode: "Byte",
    errorCorrectionLevel: "H",
  },
  imageOptions: { 
    hideBackgroundDots: true, imageSize: 0.4, margin: 0
   },
  dotsOptions: { type: "dots", color: "#000" },
  backgroundOptions: { color: "#ffffff" },

  dotsOptionsHelper: {
    colorType: { single: true, gradient: false },
    gradient: {
      linear: true,
      radial: false,
      color1: "#000",
      color2: "#000",
      rotation: "0",
    },
  },
  cornersSquareOptions: { type: "extra-rounded", color: "#000" },
  cornersSquareOptionsHelper: {
    colorType: { single: true, gradient: false },
    gradient: {
      linear: true,
      radial: false,
      color1: "#000000",
      color2: "#000000",
      rotation: "0",
    },
  },
  cornersDotOptions: { type: "extra-rounded", color: "#000" },
  cornersDotOptionsHelper: {
    colorType: { single: true, gradient: false },
    gradient: {
      linear: true,
      radial: false,
      color1: "#000000",
      color2: "#000000",
      rotation: "0",
    },
  },
  backgroundOptionsHelper: {
    colorType: { single: true, gradient: false },
    gradient: {
      linear: true,
      radial: false,
      color1: "#ffffff",
      color2: "#ffffff",
      rotation: "0",
    },
  },
});

export default function QrCodeGenerator({rs, image }) {
  // const [url, setUrl] = useState(`${window.location.href}`);  
  const [url, setUrl] = useState(`https://www.google.com/search?q=${rs?.googlereview}`);
  
  const ref = useRef(null);

  useEffect(() => {
    qrCode.append(ref.current);
  }, []);

  useEffect(() => {
    qrCode.update({
      data: url,
    });
  }, [url]);

  return <div className="canvas-container" ref={ref} />;
}
