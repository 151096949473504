import React from 'react'
import PrviewTheme from './BusinessNew/PrviewTheme'
import ThemeDetails from './BusinessNew/ThemeDetails'

const BusinessNew = () => {
  return (
    <>
      <div className="row">
 


<ThemeDetails/>

<PrviewTheme/>

</div>
    </>
  )
}

export default BusinessNew
