import React, { useEffect, useContext } from "react";
import { useState } from "react";
import SocialForm from "./SocialForm";
import { toast } from "react-toastify";
// import { businessContext } from "../../../context/businessContext";
import { businessObjContext } from "../../../context/businessObjContext";
import "./../../Pages/Updatedcss/BusinessChangeBlockUpdatedStyle.css";
import TermsConditionForm from "./TermsConditionForm";

function TermsConditionsCreate({ setTermsConditionData, termsConditionData }) {
  const [formList2, setFormList2] = useState([]);
  const [formCount, setFormCount] = useState(0);
  const [foCount, setFoCount] = useState(0);
  const [isAddServiceDisable, setIsAddSocialDisable] = useState(false);

  // const [isAddDisable, setIsAddDisable] = useState(false);
  const [socialData, setSocialData] = useState([]);
  const { businessObjData } = useContext(businessObjContext);

  const createForm = () => {
    if (foCount < 3) {
      setIsAddSocialDisable(true);
      const newFormCount = formCount + 1;
      setFormCount(newFormCount);
      const newForm = {
        formCount: newFormCount,
        type: "",
        text: [],
      };
      const updatedSocialData = [...socialData, newForm];

      setSocialData(updatedSocialData);
    }
  };

  useEffect(() => {
    const formCounts = socialData.map((item) => item.formCount);
    const gt = formCounts.length
    setFoCount(gt);
    setFormList2(socialData);
    setTermsConditionData(socialData);
  }, [socialData]);

  useEffect(() => {
    let getServicesData;

    // console.log(businessObjData, "servce2i bb2j");
    if (businessObjData && businessObjData?.cabTermsConditions) {
      getServicesData = JSON.parse(businessObjData?.cabTermsConditions?.content);
      if (getServicesData.length) {
        getServicesData = JSON.parse(getServicesData);
      }
    }

    // console.log(getServicesData, "service22i  Obj");
    if (getServicesData && getServicesData?.length) {
      setFormList2(getServicesData);
      setFormCount(getServicesData?.length);
      setSocialData(getServicesData);
    } else {
      setFormList2([]);
      setFormCount(0);
      setSocialData([]);
    }
  }, [businessObjData]);

  const isTypeSelected = (type) => {
    return socialData.some((form) => form.type === type);
  };

  return (
    <>
      {/* {console.log(socialData,"rmList223")} */}
      {socialData ? (
        <div className="row" id="Socialformpadd">
          {socialData.map((item, index, arr) => (
            <>
              <TermsConditionForm
                formCount={item?.formCount}
                socialData={socialData}
                setIsAddSocialDisable={setIsAddSocialDisable}
                setSocialData={setSocialData}
                index={index}
                isTypeSelected={isTypeSelected}
              // removeForm={removeForm}
              ></TermsConditionForm>
            </>
          ))}
        </div>
      ) : null}

      {foCount < 3 && (
        <button
          type="button"
          onClick={createForm}
          className="btn btn-cu addbuttonedit"
        >
          + Add
        </button>
      )}
    </>
  );
}

export default TermsConditionsCreate;
