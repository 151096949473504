import React from 'react'

const ConatctInfo = () => {
  return (
    <div className='servicebox tile'>
       <div className='row'>
        <div className="mb-3 col-md-6">
        <label className="form-label">Phone Number:</label>
        <input className="form-control" type="text" placeholder="Phone Number"/>
        </div>
        <div className="mb-3 col-md-6">
        <label className="form-label">Email:</label>
        <input className="form-control" type="text" placeholder="Email"/>
        </div>
        {/* <div className="mb-3 col-md-12">
        <label className="form-label">Web Url:</label>
        <input className="form-control" type="text" placeholder="Email"/>
        </div> */}
        <div className="mb-3 col-md-12">
        <label className="form-label">Address:</label>
        <textarea className="form-control" rows="3" placeholder="Address"></textarea>
        </div>
        </div>
    </div>
  )
}

export default ConatctInfo
