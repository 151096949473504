import { useState, useEffect, useContext } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Theme1 from "./themes/theme1/Theme1";
import axios from "axios";
import { environmentVariables } from "../../config/env.config";
import Theme1Ext from "./themes/theme1/Theme1Ext";
import Theme3Ext from "./themes/theme3/Theme3Ext";
import Theme4Ext from "./themes/theme4/Theme4Ext";
import Theme2Ext from "./themes/theme2/Theme2Ext";
import Theme5Ext from "./themes/theme5/Theme5Ext";
import Theme6Ext from "./themes/theme6/Theme6Ext";
import Theme7Ext from "./themes/theme7/Theme7Ext";
import CircularLoader from "../Components/CircularLoader/CircularLoader";
import { toast } from "react-toastify";
import Theme8Ext from "./themes/theme8/Theme8Ext";
import Theme9Ext from "./themes/theme9/Theme9Ext";
import Theme10Ext from "./themes/theme10/Theme10Ext";
import { userContext } from "../../context/userContext";
import Resume1Ext from "../Pages/BusinessPages/Resume1Ext";
import Resume2Ext from "../Pages/BusinessPages/Resume2Ext";
import Resume3Ext from "../Pages/BusinessPages/Resume3Ext";
import Resume4Ext from "../Pages/BusinessPages/Resume4Ext";
import Resume5Ext from "../Pages/BusinessPages/Resume5Ext";
import Resume6Ext from "../Pages/BusinessPages/Resume6Ext";
const ResumeTemplate = (props) => {

  const { userData } = useContext(userContext);

  const navigate = useNavigate();
  const baseUrl = environmentVariables?.apiUrl;
  const location = useLocation();
  let { id } = useParams();
  const params = location?.pathname?.split("/")[1];
  const [resumeName, setResumeName] = useState(null);
  const [businessData, setBusinessData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [businessId, setBusinessId] = useState(null);
useEffect(()=>{
},[params])
  const countViewerBySlug = async () => {
    const data = {
      business_id: businessId,
      slug:params
    };
    try {
      const response = await axios.put(
        `${environmentVariables?.apiUrl}api/v1/business/countViewerBySlug`,
        data
      );
      if (response.status === 200) {
        const get = response.data;
        // console.log(get);
      } else {
        console.error(`Unexpected response status: ${response.status}`);
      }
    } catch (error) {
      console.error("An error occurred:", error.message);
    }
  };

  const getPlanName = () => {
    setIsLoading(true);
    setResumeName(null);
    axios
      .get(`${baseUrl}api/v1/resume/fetchResumeById`, {
        params: { id: id },
        headers: {
            // _token: userData?.token,
        },
        // withCredentials: true,
      })
      .then((res) => {
        // console.log(res?.data, "ERERER");
        setBusinessData(res?.data?.data);
        if (res?.data?.data?.resume_color) {
          setResumeName(res?.data?.data?.resume_color);
        } else {
          setResumeName("inactive");
        }
        setBusinessId(res?.data?.data?.id);
        setIsLoading(false);
      })
      .catch((err) => {
        // console.log(err?.response, "err");
        if (err?.response?.status == 400) {
          setResumeName("inactive");
          setTimeout(() => {
            navigate("/businesslist");
          }, 6000);
        } else {
          setResumeName("Bad Request");
          // setTimeout(() => {
          //   navigate("/");
          // }, 6000);
        }
        setIsLoading(false);
      });
  };
  useEffect(() => {
    getPlanName();
  }, []);

  useEffect(() => {
    countViewerBySlug();
  }, []);

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          height: "100vh",
          alignItems: "center",
        }}
      >
        <CircularLoader />
      </div>
    );
  }
  console.log(resumeName,"L112")

  switch (resumeName) {
    case "resume1":
      case "Resume_1":
      return <Resume1Ext resumeData={businessData} id={businessId} />;
      case "resume2":
        case "Resume_2":
      return <Resume2Ext resumeData={businessData} id={businessId} />;
      case "resume3":
        case "Resume_3":
        return <Resume3Ext resumeData={businessData} id={businessId} />;
        case "resume4":
          case "Resume_4":
          return <Resume4Ext resumeData={businessData} id={businessId} />;
          case "resume5":
            case "Resume_5":
            return <Resume5Ext resumeData={businessData} id={businessId} />;
            case "resume6":
              case "Resume_6":
              return <Resume6Ext resumeData={businessData} id={businessId} />;
    case "inactive":
      return (
        <div className="container">
          <section className="pagecover">
            <div className="row align-items-center justify-content-center vh-100">
              <div className="col-12 text-center">
                <div>
                  <h1>Resume is not active</h1>
                </div>
              </div>
            </div>
          </section>
        </div>
        // <div>
        //   <h1>Business is not active</h1>
        // </div>
      );
    case "Bad Request":
      return (
        <div
          className="bad-request"
          style={{
            textAlign: "center",
            marginBottom: "80px" /* Adjust margin as needed */,
            backgroundColor: "white" /* Set the background color to white */,
            padding: "20px" /* Add padding for better visibility */,
          }}
        >
          <div>
            <h1>Bad Request</h1>
          </div>
        </div>
       
      );

    default:
      return <div>Unknown Resume</div>;
  }
};
export default ResumeTemplate;
