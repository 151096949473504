import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';

const Charttb = (props) => {
  const [chartsRs, setChartsRs] = useState([]);
  useEffect(() => {
    if (props?.data) {
      setChartsRs(props?.data);
    }
  }, [props]);

  const colors = ['#008FFB', '#00E396', '#FEB019', '#FF4560', '#775DD0'];
  const options = {
    // series: [{
    //   data: props?.data?.resultViewr?.bviewer,
    // }],
    chart: {
      height: 350,
      type: 'bar',
      events: {
        click: function (chart, w, e) {
          // console.log(chart, w, e)
        },
      },
    },
    colors: colors,
    plotOptions: {
      bar: {
        columnWidth: '45%',
        distributed: true,
      },
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
    },
    xaxis: {
      categories: props?.data?.resultViewr?.business_title,
      labels: {
        style: {
          colors: colors,
          fontSize: '12px',
        },
      },
      title: {
        text: 'Business'
      }
    },
    yaxis: {
      show: true, 
      min: props?.data?.resultViewr?.maxmin?.min, 
      max: props?.data?.resultViewr?.maxmin?.max, 
      tickAmount: 5, 
      title: {
        text: 'Viewer'
      }
    },
  };
  const series = [{
    name: 'Viewer',
    data: props?.data?.resultViewr?.bviewer,
  }];

  return (
    <div id="chart">
      <ReactApexChart options={options} series={series} type="bar" height={350} />
    </div>
  );
};
//312 20:57
export default Charttb;