import React, { useContext } from "react";
import { userContext } from "../../../context/userContext";
import { environmentVariables } from "../../../config/env.config";
import { useNavigate } from "react-router";
import ResumeIcon from "./../../../Images/ResumeLogo.png";
import phoneIcon from "./../../../Images/PhoneCallingRounded.png";
import MessageIcon from "./../../../Images/Letter.png";
import MapIcon from "./../../../Images/MapPoint.png";
import LinkedIcon from "./../../../Images/linkedInIcon.png";
import FatherIcon from "./../../../Images/FatherNameIcon.png"
import DobIconIcon from "./../../../Images/DobIcon.png"
import "../../TemplateFolder/Comman.css";

import {
  Card,
  CardHeading,
  CardHeadingWrapper,
  CertificationContainer,
  Certificationdate,
  Certificationname,
  CompanyName,
  CompanyWrapper,
  ContactContainer,
  ContactIcon,
  ContactInfoApp,
  ContactInfoAppContainer,
  ContactLine,
  ContactWrapper,
  Container,
  Contant,
  ContantWrapper,
  Designation,
  DesignationWrapper,
  EducationCollegeWrapper,
  EducationDate,
  EducationHeadingWrapper,
  EducationPlace,
  EducationWrapper,
  HeaderWrapper,
  Horizontalline,
  Icon,
  Name,
  NameWrapper,
  PersonalContactWrapper,
  PersonalDetailName,
  PersonalName,
  Phone,
  PhoneNumberWrapper,
  ProfileContainer,
  ProfileLogo,
  ProfileWrapper,
  ProjectCompanyWrapper,
  ProjectDate,
  ProjectHeadingWrapper,
  ProjectLink,
  ProjectLinkWrapper,
  ProjectName,
  ProjectPoints,
  ProjectPointsWrapper,
  ProjectSummery,
  ProjectSummeryDate,
  ProjectSummeryDateWrapper,
  ProjectSummeryWrapper,
  ProjectWrapper,
  ProjectWrapper2,
  ProjectsummaryPoints,
  Root,
  Triangle,
} from "./ResumeViewStyleThemejs";

const Resume1 = (props) => {
  const { userData } = useContext(userContext);
  const baseUrl = environmentVariables?.apiUrl;
  const navigate = useNavigate();

  const formatDate = (dateString) => {
    if (!dateString) return ""; // Return empty string if dateString is falsy
  
    const date = new Date(dateString); // Parse the date string
    const options = { month: "long", year: "numeric" }; // Format options
    return date.toLocaleDateString("en-US", options); // Format date to "Month-YYYY" format
  };
  const renderSkills = () => {

    if (props?.onState?.services) {
      return (
        <Card>
          <CardHeadingWrapper>
            <CardHeading>Key Skills</CardHeading>
            <Triangle style={{ borderBottom: "35px solid #F3F5F7" }} />
          </CardHeadingWrapper>
          <EducationHeadingWrapper>
            {props.skillArray.length > 0
              ? props.skillArray.map((service, index) => (
                  <EducationCollegeWrapper key={index}>
                    <EducationWrapper>
                      <EducationPlace>{service.skillname}</EducationPlace>
                    </EducationWrapper>
                  </EducationCollegeWrapper>
                ))
              : null}
          </EducationHeadingWrapper>
        </Card>
      );
    } else {
      return null;
    }
  };

  const renderCertification = () => {
    if (props?.onState?.certification) {
      return (
        <Card>
          <CardHeadingWrapper>
            <CardHeading>Certificate</CardHeading>
            <Triangle style={{ borderBottom: "35px solid #F3F5F7" }} />
          </CardHeadingWrapper>
          <CertificationContainer>
            {props.certificationArray.length > 0
              ? props.certificationArray.map((certificate, index) => (
                  <div style={{padding:"0 0 15px 0"}}> 
                    <Certificationname CertificateName>
                      {certificate?.certificatename}
                    </Certificationname>
                    <Certificationname>
                      {certificate?.organisationname}
                    </Certificationname>
                    <Certificationname>{certificate?.certificateidnumber}</Certificationname>
                    <Certificationdate>{formatDate(certificate?.date)} - {formatDate(certificate?.enddate)} </Certificationdate>
                 
                    
                  </div>
                ))
              : null}
          </CertificationContainer>
        </Card>
      );
    } else {
      return null;
    }
  };

  const renderAdditionalDetail = () => {
    if (props?.onState?.adddetails) {
      return (
        <Card>
          <CardHeadingWrapper>
            <CardHeading>Additional details</CardHeading>
            <Triangle style={{ borderBottom: "35px solid #F3F5F7" }} />
          </CardHeadingWrapper>
          <EducationHeadingWrapper>
            {props.additionaldetailArray.length > 0
              ? props.additionaldetailArray.map((details, index) => (
                  <EducationCollegeWrapper key={index}>
                    <EducationWrapper>
                      <EducationPlace>{details.skillname}</EducationPlace>
                    </EducationWrapper>
                  </EducationCollegeWrapper>
                ))
              : null}
          </EducationHeadingWrapper>
        </Card>
      );
    } else {
      return null;
    }
  };

  const renderAchievement = () => {
    if (props?.onState?.achievement) {
      return (
        <Card>
          <CardHeadingWrapper>
            <CardHeading>Achievements</CardHeading>
            <Triangle style={{ borderBottom: "35px solid #F3F5F7" }} />
          </CardHeadingWrapper>
          <EducationHeadingWrapper>
            {props.achievementArray.length > 0
              ? props.achievementArray.map((achievement, index) => (
                  <EducationCollegeWrapper key={index}>
                    <EducationWrapper>
                      <EducationPlace>{achievement.skillname}</EducationPlace>
                    </EducationWrapper>
                  </EducationCollegeWrapper>
                ))
              : null}
          </EducationHeadingWrapper>
        </Card>
      );
    } else {
      return null;
    }
  };

  const renderTools = () => {
    if (props?.onState?.tools) {
      return (
        <Card>
          <CardHeadingWrapper>
            <CardHeading>Tools</CardHeading>
            <Triangle style={{ borderBottom: "35px solid #F3F5F7" }} />
          </CardHeadingWrapper>
          <EducationHeadingWrapper>
            {props.toolsArray.length > 0
              ? props.toolsArray.map((tool, index) => (
                  <EducationCollegeWrapper key={index}>
                    <EducationWrapper>
                      <EducationPlace>{tool.toolname}</EducationPlace>
                    </EducationWrapper>
                  </EducationCollegeWrapper>
                ))
              : null}
          </EducationHeadingWrapper>
        </Card>
      );
    } else {
      return null;
    }
  };

  const renderLanguage = () => {
    if (props?.onState?.language) {
      return (
        <Card>
          <CardHeadingWrapper>
            <CardHeading>Language</CardHeading>
            <Triangle style={{ borderBottom: "35px solid #F3F5F7" }} />
          </CardHeadingWrapper>
          <EducationHeadingWrapper>
            {props.languageArray.length > 0
              ? props.languageArray.map((laguage, index) => (
                  <EducationCollegeWrapper key={index}>
                    <EducationWrapper>
                      <EducationPlace>{laguage.languagename}</EducationPlace>
                    </EducationWrapper>
                  </EducationCollegeWrapper>
                ))
              : null}
          </EducationHeadingWrapper>
        </Card>
      );
    } else {
      return null;
    }
  };

  const renderProjects = () => {
    if (props?.onState?.project) {
      return (
        <Card>
          <CardHeadingWrapper>
            <CardHeading>Project</CardHeading>
            <Triangle style={{ borderBottom: "35px solid #F3F5F7" }} />
          </CardHeadingWrapper>
          {props?.projectArray?.map((project, index) => (
            <ProjectHeadingWrapper key={index}>
              <ProjectCompanyWrapper>
                <ProjectWrapper2>
                  <ProjectName>{project.projectname}</ProjectName>
                </ProjectWrapper2>
               
                <ProjectSummeryDateWrapper>
                  <ProjectSummeryDate>{formatDate(project?.startDate)} -  {project.currentlyWorking ? "Current" : `${formatDate(project?.endDate)}`}</ProjectSummeryDate>
                </ProjectSummeryDateWrapper>
                <ProjectSummeryWrapper>
                  <ProjectSummery>{project?.projectdescription}</ProjectSummery>
                </ProjectSummeryWrapper>
                {/* <ProjectSummeryWrapper>
                  <ProjectSummery>{formatDate(project?.startDate)} -  {project.currentlyWorking ? "Current" : `${formatDate(project?.endDate)}`}</ProjectSummery>
                </ProjectSummeryWrapper> */}
                <ProjectLinkWrapper>
                  <ProjectLink href={project?.projectlink} target="_blank">
                    {project?.projectlink}
                  </ProjectLink>
                </ProjectLinkWrapper>
              </ProjectCompanyWrapper>
              {index < props.projectArray.length - 1 && <Horizontalline />}
            </ProjectHeadingWrapper>
          ))}
        </Card>
      );
    } else {
      return null;
    }
  };

  const renderEducation = () => {
    if (props?.onState?.isExperience) {
      return (
        <Card>
          <CardHeadingWrapper>
            <CardHeading>education</CardHeading>
            <Triangle style={{ borderBottom: "35px solid #F3F5F7" }} />
          </CardHeadingWrapper>

          <EducationHeadingWrapper>
            {props.socialMediaData.length > 0
              ? props.socialMediaData.map((Education, index) => (
                  <EducationCollegeWrapper key={index} style={{listStyleType:"none"}}>
                    <EducationWrapper>
                      <EducationPlace>
                        {Education?.degree} ({Education?.boardname})
                        
                        <EducationPlace> {Education?.schuniname}</EducationPlace>
                        <EducationPlace> {Education?.location}</EducationPlace>
                      </EducationPlace>
                    </EducationWrapper>
                    <CompanyWrapper>
                      <EducationDate>
                        {formatDate(Education?.graduationyear)}
                      </EducationDate>
                      <EducationDate>
                       - { formatDate( Education?.graduationendyear)}
                      </EducationDate>

                    </CompanyWrapper>
                  </EducationCollegeWrapper>
                  
                ))
                
              : null}
          </EducationHeadingWrapper>
        </Card>
      );
    } else {
      return null;
    }
  };


  const renderWrkExp = () => {
    if (props?.onState?.workExp) {
      return (
        <Card>
          <CardHeadingWrapper>
            <CardHeading>Work experience</CardHeading>
            <Triangle style={{ borderBottom: "35px solid #F3F5F7" }} />
          </CardHeadingWrapper>
          <ProjectHeadingWrapper>
            {props.workExpArray.length > 0
              ? props.workExpArray.map((wrkExp, index) => (
                  <ProjectCompanyWrapper key={index}>
                    <ProjectWrapper >
                      <ProjectName>{wrkExp?.jobtitle}</ProjectName>
                    </ProjectWrapper>
                    <CompanyWrapper>
                      <CompanyName style={{color:"#2F2F2F"}}>
                        {wrkExp?.companyname}, {wrkExp?.cityname}
                      </CompanyName>
                    </CompanyWrapper>
                    <ProjectWrapper  style={{paddingTop:"0px"}}>
                      <ProjectSummeryDate  WorkExperience>
                      {formatDate(wrkExp?.startdate)} -  {wrkExp?.enddate === "current" ? "Current" : formatDate(wrkExp?.enddate)}
                      </ProjectSummeryDate>
                      {/* <ProjectDate>
                      {formatDate(wrkExp?.startdate)} -  {wrkExp?.enddate === "current" ? "Current" : formatDate(wrkExp?.enddate)}
                      </ProjectDate> */}
                    </ProjectWrapper>
                    <ProjectsummaryPoints>
                      {wrkExp?.workSummary}
                    </ProjectsummaryPoints>
                    {index < props.workExpArray.length - 1 && <Horizontalline />}
                  </ProjectCompanyWrapper>
                ))
              : null}
          </ProjectHeadingWrapper>
        </Card>
      );
    } else {
      return null;
    }
  };

  const renderPrsnldetails = () => {
    if (props?.onState?.prsnldetail) {
      return (
        <Card>
          <CardHeadingWrapper style={{marginBottom:"10px"}}>
            <CardHeading>Personal Details</CardHeading>
            <Triangle style={{ borderBottom: "35px solid #F3F5F7" }} />
          </CardHeadingWrapper>
         
          <PersonalContactWrapper>
            <PersonalDetailName>
              <PersonalName>Father/ Spouse Name</PersonalName>
            </PersonalDetailName>
              <PhoneNumberWrapper>
                <PersonalName>{props?.formikData?.values?.fathername}</PersonalName>
              </PhoneNumberWrapper>
            </PersonalContactWrapper>

            <PersonalContactWrapper>
            <PersonalDetailName>
              <PersonalName>Marital Status</PersonalName>
            </PersonalDetailName>
              <PhoneNumberWrapper>
                <PersonalName>{props?.formikData?.values?.maritalstatus}</PersonalName>
              </PhoneNumberWrapper>
            </PersonalContactWrapper>
            
            <PersonalContactWrapper>
            <PersonalDetailName>
              <PersonalName>DOB</PersonalName>
            </PersonalDetailName>
              <PhoneNumberWrapper>
                <PersonalName>{props?.formikData?.values?.userdob}</PersonalName>
              </PhoneNumberWrapper>
            </PersonalContactWrapper>
        
        </Card>
      );
    } else {
      return null;
    }
  }; 

  return (
    <>
      <Root>
        <HeaderWrapper>
          <ProfileContainer>
            <ProfileWrapper>
              <ProfileLogo
                // src={props?.logoImageUrl || ResumeIcon}
                src={
                  props?.logoImageUrl
                    ? props?.logoImageUrl?.includes("base64")
                      ? props?.logoImageUrl
                      :props?.logoImageUrl == "null"
                      ? ""
                      : `${baseUrl}uploads/resume/${props?.logoImageUrl}`
                    : ResumeIcon
                }
                className="mx-auto d-block img-fluid logo-profile"
              />
            </ProfileWrapper>
            <NameWrapper>
              <Name>
                {props?.formikData?.values?.fullName || "JANE COOPER"}
              </Name>
            </NameWrapper>
            <DesignationWrapper>
            <Designation>
              {props?.designation || "Node js"}
           </Designation>
            </DesignationWrapper>
          </ProfileContainer>
        </HeaderWrapper>
        <Container>
          <ContactContainer>
            <ContactInfoAppContainer>
              <ContactInfoApp>CONTACT INFO</ContactInfoApp>
              <ContactLine />
            </ContactInfoAppContainer>
            <ContactWrapper>
              <ContactIcon>
                <Icon src={phoneIcon} />
              </ContactIcon>
              <PhoneNumberWrapper>
                <Phone>{props?.formikData?.values?.phone}</Phone>
              </PhoneNumberWrapper>
            </ContactWrapper>
            <ContactWrapper MailTemplate>
              <ContactIcon>
                <Icon src={MessageIcon} />
              </ContactIcon>
              <PhoneNumberWrapper>
                <Phone>{props?.formikData?.values?.email}</Phone>
              </PhoneNumberWrapper>
            </ContactWrapper>
           
            <ContactWrapper>
              <ContactIcon>
                <Icon src={MapIcon} />
              </ContactIcon>
              <PhoneNumberWrapper>
                <Phone>{props?.formikData?.values?.address}</Phone>
              </PhoneNumberWrapper>
            </ContactWrapper>
            <ContactWrapper>
              <ContactIcon>
                <Icon style={{width:"30px"}} src={LinkedIcon} />
              </ContactIcon>
              <PhoneNumberWrapper>
                <Phone>{props?.formikData?.values?.linkedIn}</Phone>
              </PhoneNumberWrapper>
            </ContactWrapper>
           
          </ContactContainer>
          <Card>
            <CardHeadingWrapper>
              <CardHeading>SUMMARY</CardHeading>
              <Triangle style={{ borderBottom: "35px solid #F3F5F7" }} />
            </CardHeadingWrapper>
            <ContantWrapper>
              <Contant>{props?.formikData?.values?.description}</Contant>
            </ContantWrapper>
          </Card>
          {props?.onState?.project && renderProjects()}
          {props?.onState?.isExperience && renderEducation()}
          {props?.onState?.services && renderSkills()}
          {props?.onState?.workExp && renderWrkExp()}
          {props?.onState?.tools && renderTools()}
          {props?.onState?.certification && renderCertification()}
          {props?.onState?.adddetails && renderAdditionalDetail()}
          {props?.onState?.achievement && renderAchievement()}
          {props?.onState?.language && renderLanguage()}
          {props?.onState?.prsnldetail && renderPrsnldetails()}

 
        </Container>
      </Root>
    </>
  );
};

export default Resume1;
