import React, { useEffect, useCallback, useState } from 'react';
import DetailIcon from "./../../../../Images/banner9.png";
import BackIcon from "./../../../../Images/DetailProductBack.png";
import { useParams, useNavigate } from "react-router-dom";
import axios from 'axios';
import { environmentVariables } from '../../../../config/env.config';
import Drawer from "react-bottom-drawer";
import BottomDrawer from './BottomDrawer';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

const ProductDetailpage = () => {
  const [productDetails, setProductDetails] = useState(null);
  const [imagegallery, setGalleryImage] = useState([]);
  const [productImg, setProductImg] = useState('');
  const baseUrl = environmentVariables?.apiUrl;
  const fronturl = environmentVariables?.frntUrl;
  const httpProtocol = environmentVariables?.httpProtocol;
  const [isVisible, setIsVisible] = useState(false);
  const navigate = useNavigate();

  const openDrawer = useCallback(() => {
    setIsVisible(true);
  }, []);
  const closeDrawer = useCallback(() => setIsVisible(false), []);

  let { id } = useParams();

  const fetchProductDetails = () => {
    let data = JSON.stringify({
      "pid": id
    });

    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${baseUrl}api/v1/business/getProductDetailsById`,
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios.request(config)
      .then((response) => {
        if (response?.data?.success) {
          console.log("response?.data?.data.gallery", response?.data?.data.image)
          setProductImg(response?.data?.data?.image)
          setProductDetails(response?.data?.data);
          setGalleryImage(JSON.parse(response?.data?.data.gallery))
          console.log(response?.data?.data)
        } else {

        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  console.log("Image Gallery", imagegallery, typeof (imagegallery));
  useEffect(() => {
    fetchProductDetails();
  }, [id]);

  const handleFormSubmit = (values) => {
    const requestData = {
      countryCode: values.countryCode,
      phone: values.phone,
      business_id: values.item.business_id,
      product_id: values.item.id,
      name: values.item.product_name,
      message: values.description,
      enquiry_from: 0,
      captchaAns: values?.captchaAns
    };

    axios.post(`${baseUrl}api/v1/business/create_minimkt_enquiry`, requestData)
      .then((response) => {
        // handle success
        toast.success("Enquiry submitted successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        let contactInfoData = !!productDetails?.businessDetails?.contactInfos ? JSON.parse(productDetails?.businessDetails?.contactInfos?.content) : "";
        const phoneNumber = !!contactInfoData ? contactInfoData.contactInfoPhone : "";
        const { product_name, product_price, product_service } = values.item;

        const message = encodeURIComponent(`Hi, I'd like to Enquire about this product\nProduct details:\nName: ${product_name}\nPrice: ${product_price}\nService: ${product_service}`);
        const apiUrl = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${message}`;
        window.open(apiUrl, '_blank');
      })
      .catch((error) => {
        // handle error
        console.log(error);
        toast.error(error?.response?.data?.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      });
  };

  const handleBackArrowClick1 = () => {
    const currentUrl = window.location.href;
    const url = new URL(currentUrl);

    // Construct the base URL
    const baseUrl = `${url.protocol}//${url.host}`;

    // Optionally copy the base URL to clipboard
    navigator.clipboard.writeText(baseUrl).then(() => {
      console.log('Base URL copied to clipboard:', baseUrl);
    }).catch(err => {
      console.error('Failed to copy URL: ', err);
    });

    // Navigate to the base URL
    window.location.href = baseUrl;
  };

  const handleBackArrowClick = () => {
    let businessData = productDetails?.businessDetails;
    let detailsLink = (businessData?.slug_subdomain != null) ? httpProtocol + businessData?.slug_subdomain : (businessData?.slug != null) ? fronturl + businessData?.slug : "/";
    window.location.href = detailsLink;
  };
  

  return (
    <div id="temp20">
      <div className="main-temaplt" id="temp20">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 wtemplate p-4" style={{ minHeight: "100vh" }}>
              {!!productDetails &&
                <div>
                  <div className='relative'>
                    {/* <div> 
                    <img className="rounded DetailProductImage object-fill" src={`${baseUrl+'uploads/'+productDetails?.image}`} />
                  </div>
                  <div className='absolute cursor-pointer navbackdetail'>
                    <img className="w-3" src={BackIcon} />
                  </div> */}
                    <Carousel>
                      {/* {
                        imagegallery && imagegallery.map((galary, index) => {
                          return (
                            <div>
                              <img className="rounded DetailProductImage object-fill" src={`${baseUrl + 'uploads/' + galary}`} />
                            </div>
                          )
                        })
                      } */}
{
    (imagegallery && imagegallery.length > 0) 
      ? imagegallery.map((gallery, index) => {
          return (
            <div key={index}>
              <img className="rounded DetailProductImage object-fill" src={`${baseUrl + 'uploads/' + gallery}`} alt={`Gallery image ${index + 1}`} />
            </div>
          );
        })
      : (
        <div>
          <img className="rounded DetailProductImage object-fill" src={`${baseUrl + 'uploads/' + productImg}`} alt="Default product image" />
        </div>
      )
  }
                    
                    </Carousel>
                  </div>
                  <div
                    style={{ cursor: 'pointer' }}
                    onClick={handleBackArrowClick}
                  >
                    <i class="fa-solid fa-arrow-left"></i>
                  </div>

                  <div className='text-start font-medium text-2xl mt-2 pt-2 font-Tajawal break-all text-detailheading'>
                    {productDetails?.product_name}
                  </div>

                  <div className="flex justify-between items-center py-2">
                    {/* <div className="text-lg font-semibold text-black text-xl">
                    {productDetails?.businessDetails?.User?.currencyDetails?.symbol}{productDetails?.product_price}
                  </div> */}
                    {/* <div className='border px-1 flex items-center justify-center'><span className='px-2 py-1 font-bold cursor-pointer'> + </span> 1 <span className='px-2 py-1 font-bold cursor-pointer'> - </span></div> */}
                  </div>
                  {!!productDetails?.product_description &&
                    <div className='font-Tajawal text-base text-productDetailtext-400 my-2 leading-relaxed text-justify' style={{ paddingBottom: "70px", wordBreak: "break-word" }} dangerouslySetInnerHTML={{ __html: productDetails?.product_description }}>
                    </div>
                  }

                  {/* <div className='px-2 py-4'>
                  <div className=' bg-detailseprateline h-px w-11/12 DetailmidLine' />
                </div> */}

                  {/* <div>
                  <div className='text-detailheading text-start font-medium text-2xl font-Tajawal break-all'>Specification</div>
                </div>

                <div>
                  <div className='flex pt-4 pb-2'>
                    <div className='w-2/4 text-detailheading font-Tajawal  text-base'>Case material</div>
                    <div className='w-2/4 text-productDetailtext-300 font-Tajawal text-productDetailtext text-base'>: Titanium</div>
                  </div>

                  <div className='flex py-2'>
                    <div className='w-2/4 text-detailheading font-Tajawal  text-base '>Strap width </div>
                    <div className='w-2/4 text-productDetailtext-300 font-Tajawal text-productDetailtext text-base'>:  178 mm</div>
                  </div>
                  <div className='flex py-2'>
                    <div className='w-2/4 text-detailheading font-Tajawal  text-base'>Strap dimension </div>
                    <div className='w-2/4 text-productDetailtext-300 font-Tajawal text-productDetailtext text-base'>:  178 mm</div>
                  </div>
                  <div className='flex py-2'>
                    <div className='w-2/4 text-detailheading font-Tajawal  text-base'>Case material </div>
                    <div className='w-2/4 text-productDetailtext-300 font-Tajawal text-productDetailtext text-base'>: 178 mm</div>
                  </div>
                  <div className='flex py-2'>
                    <div className='w-2/4 text-detailheading font-Tajawal  text-base'>Strap diamension</div>
                    <div className='w-2/4 text-productDetailtext-300 font-Tajawal text-productDetailtext text-base '> : 200 cm</div>
                  </div>

                </div> */}

                  <div className=' bg-white py-4 fixed bottom-0 spacing w-[450px] text-center ContactSupplier border border-white'>
                    <button className='enquire-now-btn bg-coffee text-xl rounded text-white w-full font-normal font-Tajawal pt-3  pb-3 px-4' onClick={openDrawer} style={{ width: "100%" }}>
                      Enquire Now
                    </button>
                  </div>
                  <div >
                    <Drawer style={{ width: "520px" }}
                      duration={150}
                      hideScrollbars={true}
                      onClose={closeDrawer}
                      isVisible={isVisible}
                      className="drawer">
                      <BottomDrawer closeDrawer={closeDrawer} onSubmit={handleFormSubmit} selectedItem={productDetails} />
                    </Drawer>
                  </div>
                  <ToastContainer />
                </div>}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProductDetailpage
