import React, { useRef, useState } from "react";
import bannerImage from "../../../../Images/theme-27-banner.png";
import topright from "../../../../Images/theme18-top-right.png";
import logoImage from "../../../../Images/theme-27-logo.png";
// import testimonialImage from "../../../../Images/theme-24-test.png";
import FacebookIcon from "../../../../Images/theme18-social1.png";
import InstagramIcon from "../../../../Images/theme-18-social2.png";
import TweeterIcon from "../../../../Images/theme-18-social3.png";
import LinedinIcon from "../../../../Images/theme18-social4.png";
import WhatsappIcon from "../../../../Images/theme-18-social5.png";
import CalanderIconn from "../../../../Images/theme-27-calender.png";

import "./Theme27.css";
import GalleryCarousel from "./GalleryCarousel";
import TestimonialCarousel from "./TestimonialCarousel";
const Theme27Template = (props) => {
  const [selectedTime, setSelectedTime] = useState();
  const [selectedDate, setSelectedDate] = useState();
  const [currentIndex, setCurrentIndex] = useState(0);
  const calendarInputRef = useRef(null);
  const testimonialArray = [
    {
      name: "Rachel McDermott",
      description:
        "I have been under their care for several years now to every appointment.",
      formCount: "0",
      image: "null",
    },
    {
      name: "Ernest Reinger",
      description:
        "I have been under their care for several years now to every appointment.",
      formCount: "1",
      image: "null",
    },
    {
      name: " Reinger",
      description:
        "I have been under their care for several years now to every appointment.",
      formCount: "2",
      image: "null",
    },
  ];
  const galleryArray = [
    {
      id: 60,
      business_id: 10,
      image: "1701329881279Rectangle6.jpg",
      created_by: 72,
      created_at: "2023-11-30T07:38:01.000Z",
      updated_at: "2023-11-30T07:38:01.000Z",
    },
    {
      id: 61,
      business_id: 10,
      image: "1701329883848Rectangle7.jpg",
      created_by: 72,
      created_at: "2023-11-30T07:38:03.000Z",
      updated_at: "2023-11-30T07:38:03.000Z",
    },
    {
      id: 62,
      business_id: 10,
      image: "1701329887518Rectangle597.jpg",
      created_by: 72,
      created_at: "2023-11-30T07:38:07.000Z",
      updated_at: "2023-11-30T07:38:07.000Z",
    },
    {
      id: 63,
      business_id: 10,
      image: "1701329891477Rectangle598.jpg",
      created_by: 72,
      created_at: "2023-11-30T07:38:11.000Z",
      updated_at: "2023-11-30T07:38:11.000Z",
    },
    {
      id: 64,
      business_id: 10,
      image: "1701329899378Rectangle599.jpg",
      created_by: 72,
      created_at: "2023-11-30T07:38:19.000Z",
      updated_at: "2023-11-30T07:38:19.000Z",
    },
    {
      id: 65,
      business_id: 10,
      image: "1701329902425Rectangle600.jpg",
      created_by: 72,
      created_at: "2023-11-30T07:38:22.000Z",
      updated_at: "2023-11-30T07:38:22.000Z",
    },
  ];

  const handleChangeAppointmentDate = (dateVal) => {
    setSelectedDate(dateVal);

    const dateObject = new Date(dateVal);

    const dayIndex = dateObject.getDay();

    const daysOfWeek = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    if (props?.appointmentArray && props?.appointmentArray?.length != 0) {
      setAppointmentData(
        props?.appointmentArray[`${daysOfWeek[dayIndex].toLowerCase()}`]
      );
    } else {
      const parsedContent = JSON.parse(
        props?.businessObj?.appointmentObj?.content
      );
      setAppointmentData(
        parsedContent[`${daysOfWeek[dayIndex].toLowerCase()}`]
      );
      try {
        const parsedContent = JSON.parse(
          JSON.parse(props?.businessObj?.appointmentObj?.content)
        );
        setAppointmentData(
          parsedContent[`${daysOfWeek[dayIndex].toLowerCase()}`]
        );
      } catch (err) {
        console.log(err);
      }
    }
  };

  const openCalendar = () => {

    if (calendarInputRef.current) {
      calendarInputRef.current.showPicker();

    }
  };
  return (
    <div>
      <div class="main-temaplt" id="temp27">
        <div className="container">
          <div className="row justify-content-center">
            <div style={{ width: "420px" }} className="col-12 wtemplate">

              <article className="imgsechere sec">
                {/* <div className="imgsechere-top">
                  <div className="imgsechere-top-left">  9:41  </div>
                  <div className="imgsechere-top-right"> <img src={topright} alt="top right" />  </div>
                </div> */}
                <div className="imgsechere-main-banner">
                  <img
                    src={bannerImage}
                    className="mx-auto d-block img-fluid coverimghere "
                    style={{ width: "100%" }}
                  />
                       
                </div>
                <div >
                  <img
                    src={logoImage}
                    className="mx-auto d-block img-fluid logo-profile"
                  />
                </div>
              
              </article>

              <article className="personalinfo">
                <h3 className="username">Jenny Wilson</h3>
                <p className="titleuser">Lawyer in Delhi</p>
                {/* <p className="companyName">MetaSpace Technologies</p> */}
              </article>


              
              <article className="social-links-sec sec">
                {/* <h3 class="sectitle">Social Media</h3> */}
                <ul class="socil-link socil-link-inner">
                  <li>
                    <a>
                      <i class={`fa-brands fa-facebook`}></i>

                    </a>
                  </li>
                  <li>
                    <a>
                      <i class={`fa-brands fa-twitter`}></i>

                    </a>
                  </li>

                  <li>
                    <a>
                    <i class="fa-regular fa-envelope"></i>

                    </a>
                  </li>
                  <li>
                    <a>
                    <i class={"fa-brands fa-linkedin"}></i>
                      {/* <img src={WhatsappIcon} /> */}
                    </a>
                  </li>
                  <li>
                    <a>
                      <i class={`fa-brands fa-youtube`}></i>
                      {/* <img src={LinedinIcon} /> */}
                    </a>
                  </li>
                </ul>
              </article>
              <article class="contact sec">
                <h3 class="sectitle">Contact Info</h3>

                <ul class="conlink">
                  <li>
                    <a href="">
                      <i class="fa-solid fa-phone"></i>
                      <span>{`987654321`}</span>
                    </a>
                  </li>
                  <li>
                    <a href="">
                      <i class="fa-solid fa-globe"></i>
                      <span>{`www.metaspacechain.com`}</span>
                    </a>
                  </li>

                  <li>
                    <a href="">
                      <i class="fa-solid fa-envelope"></i>
                      <span>{`Christian@Meyer.com`}</span>
                    </a>
                  </li>

                  <li>
                    <a href="">
                      <i class="fa-solid  fa-location-dot"></i>
                      <span >{`No 17A / 22, Ajmal Khan Road,`}</span>
                    </a>
                  </li>
                </ul>
              </article>

              <article className="makeappitmneets sec">
                <h3 class="sectitle">Make Appointment</h3>

                <div className="contact-form">
                 <div className="appointmentdiv">
                 <div class="col-12 mb-3" style={{ position: "relative" }}>
                    <input
                      ref={calendarInputRef}
                      type="date"
                      class="form-control clear_string appoint  "
                      placeholder="Date"
                      name="currentDate"
                      value={selectedDate}
                      onChange={(e) => setSelectedDate(e.target.value)}
                    />
                    <div className="calanderIcon" onClick={openCalendar}><img src={CalanderIconn} alt="Calendar Icon" /></div>
                  </div>
                  <div class="col-12 mb-3">
                    <select
                      class="form-select appoint"
                      aria-label="Default select example"
                      onChange={(e) => setSelectedTime(e.target.value)}
                    >
                      <option value="Select time">Select time</option>
                      <option>{`2:00 PM to 5:00 PM`}</option>
                    </select>
                  </div>
                 </div>
                  <div class="col-12 mb-3">
                    <button
                      className="btn btnmakeappitmnets"
                      data-bs-toggle="modal"
                      data-bs-target="#appotimnets"
                    >
                    Create An Appointment
                    </button>
                  </div>
                </div>
              </article>

              <article className="servicesName sec">
                <h3 class="sectitle">Services </h3>

                <div className="mainservicebox">
                  <ul className="servesbox">
                    <li>Cardiology </li>
                    <li>Pediatric </li>
                    <li>Orthopedics </li>
                    <li> Neurology</li>
                    <li>Pediatric </li>
                    <li> Orthopedics</li>
                  </ul>
                </div>
              </article>

  
              <article className="conatctform sec">
                <h3 class="sectitle">Request a consultation</h3>
              
                <div class="contact-form">
                  <form>
                    <div class="col-12 mb-3">
                   
                      <input
                        type="text"
                        class="form-control clear_string"
                        placeholder="Name"
                        name="last_name"

                        required=""
                      />
                    </div>
                    <div class="col-12 mb-3">
                
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Email"
                        name="email"

                        required=""
                      />
                    </div>
                    <div class="col-12 mb-3">
                 
                      <input
                        type="number"
                        class="form-control clear_string"
                        maxlength="10"
                        minlength="10"
                        placeholder="Phone"
                        name="mobile_number"

                        required=""
                      />
                    </div>
                    <div class="col-12 mb-3">
                 
                      <textarea
                        class="form-control clear_string"
                        rows="3"
                        placeholder="Messege"
                        required=""
                        name="message"
                      ></textarea>
                    </div>

                    <div class="col-12 mb-3">
                      <button type="submit" class="btn btn-submitform">
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </article>
              <article class="testimoniyalbox sec">
                <h3 class="sectitle testimonial">Testimonial </h3>

                <div className="testimonials-wrapper">
                {/* <div className="carousel-container">
                    <button
                      onClick={() =>
                        currentIndex == 0
                          ? setCurrentIndex(testimonialArray.length - 1)
                          : setCurrentIndex((prev) => prev - 1)
                      }
                      className="carousel-btn"
                    >
                      <i class="fa fa-angle-left"></i>
                    </button>
                    <button
                      onClick={() => {
                        currentIndex == testimonialArray.length - 1
                          ? setCurrentIndex(0)
                          : setCurrentIndex((prev) => prev + 1);
                      }}
                      className="carousel-btn"
                    >
                      <i class="fa fa-angle-right"></i>
                    </button>
                  </div> */}
                  <TestimonialCarousel
                    testimonials={testimonialArray}
                    currentElem={currentIndex}
                  />
                  
                </div>
              </article> 



              <article className="abouttemplate sec">
                <h3 class="sectitle">About Us</h3>
                
                <p className="para">
                  {`Advocate A.P Singh is a lawyer in Delhi, Noida well known for providing the best, finest, and exceptional legal services with his solution-focused approach in addition to his very smart attitude towards his work. `}</p>
              </article>



              {/* <article class="gallery sec">
                <h3 class="sectitle">Gallery </h3>
              <div className="gallerysec"> <GalleryCarousel slides={galleryArray} />
              <p className="para" style={{textAlign:"center"}}>View All </p>
              </div>

              </article> */}





              {/* <article className="businessHours sec">
                <h3 class="sectitle">Working Hours</h3>
           
                <ul class="timelist">
                  <li>
                    <div className="day">Mon :</div>
                    <div className="time">
                      <span>8:00 AM</span>-<span>5:00 PM</span>
                    </div>
                  </li>
                  <li>
                    <div className="day">Tue :</div>
                    <div className="time">
                      <span>8:00 AM</span>-<span>5:00 PM</span>
                    </div>
                  </li>
                  <li>
                    <div className="day">Wed:</div>
                    <div className="time">
                      <span>8:00 AM</span>-<span>5:00 PM</span>
                    </div>
                  </li>
                  <li>
                    <div className="day">Thu :</div>
                    <div className="time">
                      <span>8:00 AM</span>-<span>5:00 PM</span>
                    </div>
                  </li>
                  <li>
                    <div className="day">Fri :</div>
                    <div className="time">
                      <span>8:00 AM</span>-<span>5:00 PM</span>
                    </div>
                  </li>
                  <li>
                    <div className="day">Sat :</div>
                    <div className="time">
                      <span>8:00 AM</span>-<span>5:00 PM</span>
                    </div>
                  </li>
                </ul>
              </article> */}

  


              {/* <article className="makeappitmneets sec">
                <h3 class="sectitle">Make Appointment</h3>
                <div className="contact-form">
                  <div class="col-12 mb-3">
                    <input
                      type="date"
                      class="form-control clear_string"
                      placeholder="Date"
                      name="currentDate"
                      // value={selectedDate}
                      // onChange={(e) => setSelectedDate(e.target.value)}
                    />
                  </div>
                  <div class="col-12 mb-3">
                    <select
                      class="form-select"
                      aria-label="Default select example"
                      // onChange={(e) => setSelectedTime(e.target.value)}
                    >
                      <option value="Select time">Select time</option>
                      <option>{`2:00 PM - 5:00 PM`}</option>
                    </select>
                  </div>
                  <div class="col-12 mb-3">
                    <button
                      className="btn btnmakeappitmnets"
                      data-bs-toggle="modal"
                      data-bs-target="#appotimnets"
                    >
                      Make An Appointment
                    </button>
                  </div>
                </div>
              </article> */}



            







            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Theme27Template;