import React, { useRef, useEffect, useState } from "react";
import ResumeLog from "../../../Images/Resume5Logo.png";
import { AchivementsList, AchivementsWrapper, AddressContainer, AddressKeyWrapper, AddressValueWrapper, CandidateDesignation, CandidateName, Card, CardName, CardNameWrapper, CardWrapper, CeartificationKeyName, CertificationValueName, CertificationWrapper, CompanyName, CompanyNameWrapper, Container, DesignationLocationDateWrapper, EducationBoardYear, EducationDegree, EducationSchoolName, EducationWrapper, ExpDesignation, ExpLocationDate, ExpSummary, ExperienceCompanyContainer, HeadingWrapper, HeadingWrapperContainer, LeftSideBar, LogoImage, LogoImageWrapper, NameDesignationWrapper, PersonalDetailContainer, PersonalNameKey, PersonalNameValue, PersonalNameWrapper, Power, PowerSpan, PoweredWrapper, ProDesignation, ProLocationDate, ProfileSummary, ProjectLink, RightSideBar,  Root, ToolsWrapper, ToolsWrapperList, Wrapper } from "./Resume5ExtStyle";
import { environmentVariables } from "../../../config/env.config";
import ShareVector from "./../../../Images/BlackShare.png";
import { IconContainer, ShareButtonWrapper, ShareIcon, SingleIcon, SingleIconWrapper } from "./StyleThemejs";
import { EmailIcon, LinkedinIcon, TelegramIcon, WhatsappIcon } from "react-share";

const Resume5Ext = (props) => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [educationData, setEducationData] = useState([]);
  const [projectData, setProjectData] = useState([]);
  const [certificateData, setCertificateData] = useState([]);
  const [skillData, setSkillData] = useState([]);
  const [additionalData, setAdditionalData] = useState([]);
  const [achievementData, setAchievementData] = useState([]);
  const [workExpData, setworkExpData] = useState([]);
  const [toolData, settoolData] = useState([]);
  const [language, setLanguage] = useState([]);
  const [showShareOptions, setShowShareOptions] = useState(false);

  const iconContainerRef = useRef(null);

  useEffect(() => {
    if (props?.resumeData?.educationcontent) {
      setEducationData(JSON.parse(props.resumeData.educationcontent));
    }
    if (props?.resumeData?.projectcontent) {
      setProjectData(JSON.parse(props.resumeData.projectcontent));
    }
    if (props?.resumeData?.certificationcontent) {
      setCertificateData(JSON.parse(props.resumeData.certificationcontent));
    }
    if (props?.resumeData?.skillscontent) {
      setSkillData(JSON.parse(props.resumeData.skillscontent));
    }
    if (props?.resumeData?.additionaldetailscontent) {
      setAdditionalData(JSON.parse(props.resumeData.additionaldetailscontent));
    }
    if (props?.resumeData?.toolscontent) {
      settoolData(JSON.parse(props.resumeData.toolscontent));
    }
    if (props?.resumeData?.languagecontent) {
      setLanguage(JSON.parse(props.resumeData.languagecontent));
    }
    if (props?.resumeData?.wrkExpcontent) {
      setworkExpData(JSON.parse(props.resumeData.wrkExpcontent));
    }
    if (props?.resumeData?.achievementcontent) {
      setAchievementData(JSON.parse(props.resumeData.achievementcontent));
    }
  }, [props.resumeData]);
  

  const getImage = (image) => {
    if (image && image.includes("base64")) {
      return image;
    } else if (image) {
      return `${environmentVariables?.apiUrl}uploads/resume/${image}`;
    } else {
      return "";
    }
  };

  const updateScreenWidth = () => {
    setScreenWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", updateScreenWidth);
    return () => {
      window.removeEventListener("resize", updateScreenWidth);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (iconContainerRef.current && !iconContainerRef.current.contains(event.target)) {
      setShowShareOptions(false);
    }
  };
  useEffect(() => {
      document.addEventListener("click", handleClickOutside, false);
      return () => {
        document.removeEventListener("click", handleClickOutside, false);
      };
  }, []);

  const formatDate = (dateString) => {
    if (!dateString) return ""; // Return empty string if dateString is falsy

    const date = new Date(dateString); // Parse the date string
    const options = { month: "long", year: "numeric" }; // Format options
    return date.toLocaleDateString("en-US", options); // Format date to "Month-YYYY" format
  };

  const handleShare = () => {
    setShowShareOptions(!showShareOptions);
  };

  const handleShareOnPlatform = (platform) => {
    const resumePreviewLink = window.location.href;
    const message = "Check out my resume!";
    const fullMessage = `${message} ${resumePreviewLink}`;
    let shareUrl = "";

    switch (platform) {
      case "linkedin":
        // shareUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(resumePreviewLink)}`;
        // break;
        navigator.clipboard
          .writeText(fullMessage)
          .then(() => {
            alert(
              "Message copied to clipboard! Please paste it in LinkedIn messages."
            );
            window.open("https://www.linkedin.com/messaging", "_blank"); // Opens LinkedIn Messaging
          })
          .catch((err) => {
            console.error("Could not copy text: ", err);
          });
        break;
      case "whatsapp":
        shareUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(
          resumePreviewLink
        )}`;
        break;
      case "telegram":
        shareUrl = `https://t.me/share/url?url=${encodeURIComponent(
          resumePreviewLink
        )}`;
        break;
      case "email":
        shareUrl = `mailto:?subject=Checkoutmyresume&body=${encodeURIComponent(
          resumePreviewLink
        )}`;
        // setShowEmailShare(true);
        break;
      // case 'facebook':

      //   break;
      default:
        break;
    }

    if (shareUrl) {
      window.open(shareUrl, "_blank");
    }
  };


  const renderEducation = () => (
    <HeadingWrapperContainer>
      <HeadingWrapper>
        Education
      </HeadingWrapper>
      {educationData.map((item, index) => (
        <EducationWrapper key={index}>
          <EducationDegree>{item.degree}</EducationDegree>
          <EducationSchoolName>{item.schuniname}</EducationSchoolName>
          <EducationBoardYear>{item.boardname}</EducationBoardYear>
          <EducationBoardYear>
            (
            {`${formatDate(item.graduationyear)?.substr(0, 3)} ${formatDate(item.graduationyear)?.substr(-4)} - ${
              item.currentlyWorking
                ? "Present"
                : `${formatDate(item.graduationendyear)?.substr(0, 3)} ${formatDate(item.graduationendyear)?.substr(-4)}`
            }`}
            )
          </EducationBoardYear>
        </EducationWrapper>
      ))}
    </HeadingWrapperContainer>
  );

  const renderWorkExperience = () => (
    <HeadingWrapperContainer>
     
        <HeadingWrapper>Work experience</HeadingWrapper>
        <ExperienceCompanyContainer>
        {workExpData.map((exp, index) => (
        
          <CompanyNameWrapper key={index}>
              <CompanyName> {exp.companyname} </CompanyName>
                    <DesignationLocationDateWrapper>
                      <ExpDesignation>{exp.jobtitle}</ExpDesignation>
                      <ExpLocationDate>{exp.cityname},   {`${formatDate(exp.startdate)?.substr(0, 3)} ${formatDate(
                  exp.startdate
                )?.substr(-4)} - ${
                  exp.currentlyWorking
                    ? "Current"
                    : `${formatDate(exp.enddate)?.substr(0, 3)} ${formatDate(
                        exp.enddate
                      )?.substr(-4)}`
                }`}</ExpLocationDate>
                    </DesignationLocationDateWrapper>
                    <ExpSummary>
                    {exp.workSummary}
                    </ExpSummary>
          </CompanyNameWrapper>
        ))}
     </ExperienceCompanyContainer>
    </HeadingWrapperContainer>
  );
  
  const renderProjects = () => (
    <HeadingWrapperContainer>
        <HeadingWrapper>
              PROJECT
              </HeadingWrapper>
              <ExperienceCompanyContainer>
          {projectData.map((project, index) => (
           <CompanyNameWrapper key={index}>           
           <DesignationLocationDateWrapper ProjectsWrapper>
             <ProDesignation>{project.projectname}</ProDesignation>
             <ProLocationDate> {`${formatDate(project.startDate)?.substr(0, 3)} ${formatDate(
                  project.startDate
                )?.substr(-4)} - ${
                  project.currentlyWorking
                    ? "Current"
                    : `${formatDate(project.endDate)?.substr(
                        0,
                        3
                      )} ${formatDate(project.endDate)?.substr(-4)}`
                }`}</ProLocationDate>
           </DesignationLocationDateWrapper>
           <ExpSummary>
           {project.projectdescription}
           </ExpSummary>

           <ProjectLink href={project.projectlink} target="_blank">  {project.projectlink}</ProjectLink>
       </CompanyNameWrapper>
          ))}
             </ExperienceCompanyContainer>
       </HeadingWrapperContainer>
  );

  const renderSkills = () => (
    <CardWrapper>
      <Card>
      <CardNameWrapper>
                  <CardName>skills</CardName>
                </CardNameWrapper>
        <ToolsWrapper>
          {skillData.map((skill, index) => (
            <ToolsWrapperList key={index}>{skill.skillname}</ToolsWrapperList>
          ))}
        </ToolsWrapper>
      </Card>
    </CardWrapper>
  );

  const renderTools = () => (
    <CardWrapper>
      <Card>
      <CardNameWrapper>
                  <CardName>Tools</CardName>
                </CardNameWrapper>
        <ToolsWrapper>
          {toolData.map((tool, index) => (
            <ToolsWrapperList key={index}>{tool.toolname}</ToolsWrapperList>
          ))}
        </ToolsWrapper>
      </Card>
    </CardWrapper>
  );

  const renderLanguages = () => (
    <CardWrapper>
      <Card>
      <CardNameWrapper>
                  <CardName>Languages</CardName>
                </CardNameWrapper>
        <ToolsWrapper>
          {language.map((lang, index) => (
            <ToolsWrapperList key={index}>{lang.languagename}</ToolsWrapperList>
          ))}
        </ToolsWrapper>
      </Card>
    </CardWrapper>
  );

  const renderAdditionalDetail = () => (
    <CardWrapper>
      <Card>
      <CardNameWrapper>
                  <CardName>Hobbies</CardName>
                </CardNameWrapper>
        <ToolsWrapper>
          {additionalData.map((hobby, index) => (
            <ToolsWrapperList key={index}>{hobby.skillname}</ToolsWrapperList>
          ))}
        </ToolsWrapper>
      </Card>
    </CardWrapper>
  );

  const renderAchievement = () => (
    <HeadingWrapperContainer>
    <HeadingWrapper>
    achievements
    </HeadingWrapper>
    <AchivementsWrapper>
    {achievementData.map((achievement, index) => (
              <AchivementsList key={index}>
                {achievement.skillname}
              </AchivementsList>
            ))}

    </AchivementsWrapper>
  </HeadingWrapperContainer>
  );

  const renderPrsnldetails = () => (
    <HeadingWrapperContainer>
    <HeadingWrapper>
    Personal Information
    </HeadingWrapper>
    <PersonalDetailContainer>
      <PersonalNameWrapper>
      {props.resumeData.fathername && (
        <PersonalNameKey>Father/ Spouse Name</PersonalNameKey>
      )}  
        <PersonalNameValue>{props.resumeData.fathername}</PersonalNameValue>
      </PersonalNameWrapper>
      <PersonalNameWrapper>
        {props.resumeData.maritalstatus && (
        <PersonalNameKey>Marital Status</PersonalNameKey>
        )}
        <PersonalNameValue>{props.resumeData.maritalstatus}</PersonalNameValue>
      </PersonalNameWrapper>
      <PersonalNameWrapper>
        {props.resumeData.userdob && (
        <PersonalNameKey>DOB</PersonalNameKey>
        )}

        <PersonalNameValue>{props.resumeData.userdob}</PersonalNameValue>
      </PersonalNameWrapper>
    </PersonalDetailContainer>
  
  </HeadingWrapperContainer>
  );

  const renderCertifications = () => (
    <CardWrapper>
      <Card>
        <CardNameWrapper>
          <CardName>Certifications </CardName>
        </CardNameWrapper>
        {certificateData.map((certificate, index) => (
         <CertificationWrapper>
          {/* {console.log(certificate,"certificatecertificatecertificate")} */}
         <CeartificationKeyName>
        {certificate.certificatename}
         </CeartificationKeyName>
         <CertificationValueName>
     {certificate.organisationname}
         </CertificationValueName>
         <CertificationValueName>
         {certificate.certificateidnumber}
         </CertificationValueName>
         <CertificationValueName>
         {formatDate(certificate.date)}
         </CertificationValueName>

        </CertificationWrapper>
        ))}
      </Card>
    </CardWrapper>
  );
  
  


  return (
    <Root>
      <Container style={{ position: "relative" }}>
      <ShareButtonWrapper style={{}} onClick={handleShare} ref={iconContainerRef}>
          <ShareIcon src={ShareVector} />
        </ShareButtonWrapper>
        {showShareOptions && (
          <IconContainer
            className="share-options-modal"
            style={{ background: "#F8F8EC" }}
          >
            <SingleIconWrapper className="share-options-list">
              <SingleIcon onClick={() => handleShareOnPlatform("telegram")}>
                <TelegramIcon size={35} round />
              </SingleIcon>
              <SingleIcon onClick={() => handleShareOnPlatform("email")}>
                <EmailIcon size={35} round />
              </SingleIcon>
              <SingleIcon onClick={() => handleShareOnPlatform("linkedin")}>
                <LinkedinIcon size={35} round />
              </SingleIcon>
              <SingleIcon onClick={() => handleShareOnPlatform("whatsapp")}>
                <WhatsappIcon size={35} round />
              </SingleIcon>
            </SingleIconWrapper>
          </IconContainer>
        )}
        <Wrapper>
          <LeftSideBar>
            <LogoImageWrapper LeftSideImageLogo>
              <LogoImage src={getImage(props.resumeData.logo)}/>
            </LogoImageWrapper>
            <NameDesignationWrapper LeftSideImageLogo>
              <CandidateName>{props?.resumeData?.fullName}</CandidateName>
              <CandidateDesignation>
               {props?.resumeData?.designation}
              </CandidateDesignation>
            </NameDesignationWrapper>

            <CardWrapper LeftSideImageLogo>
              <Card >
                <CardNameWrapper>
                  <CardName>Details</CardName>
                </CardNameWrapper>
                <AddressContainer>
                {props?.resumeData?.city && (
                    <AddressKeyWrapper>Address</AddressKeyWrapper>
                )} 
                  <AddressValueWrapper>{props?.resumeData?.city}</AddressValueWrapper>
                </AddressContainer>

                <AddressContainer>
                  <AddressKeyWrapper>Phone</AddressKeyWrapper>
                  <AddressValueWrapper>{props?.resumeData?.phone}</AddressValueWrapper>
                </AddressContainer>

                <AddressContainer>
                  <AddressKeyWrapper>Email </AddressKeyWrapper>
                  <AddressValueWrapper>{props?.resumeData?.email}</AddressValueWrapper>
                </AddressContainer>

                <AddressContainer>
                  {props?.resumeData?.linkedIn && (
                    <AddressKeyWrapper>Linkdin </AddressKeyWrapper>
                  )}
                 
                  <a
                        href={props?.resumeData?.linkedIn}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                    <AddressValueWrapper style={{cursor:"pointer"}}> {props?.resumeData?.linkedIn} </AddressValueWrapper>
                    </a>

                  {/* <AddressValueWrapper>{props?.resumeData?.linkedIn}</AddressValueWrapper> */}
                </AddressContainer>
              </Card>

            </CardWrapper>

            {props?.resumeData?.is_tools_is_enabled === 1 && renderTools()}

            {/* <CardWrapper>
              <Card>
              <CardNameWrapper>
                  <CardName>Tools</CardName>
                </CardNameWrapper>
                <ToolsWrapper>
                  <ToolsWrapperList> Figma   </ToolsWrapperList>
                  <ToolsWrapperList> Adobe Photoshop   </ToolsWrapperList>
                  <ToolsWrapperList> Adobe Illustration   </ToolsWrapperList>
                 
                </ToolsWrapper>

              </Card>
            </CardWrapper> */}

            {props?.resumeData?.is_skills_is_enabled === 1 && renderSkills()}

           

{props?.resumeData?.is_language_is_enabled === 1 && renderLanguages()}
            

{props?.resumeData?.is_certification_is_enabled === 1 &&
          renderCertifications()}

         

            {props?.resumeData?.isEnabledAdddetails === 1 &&
          renderAdditionalDetail()}

           

          </LeftSideBar>
          <RightSideBar>

          <LogoImageWrapper RightSideImageHandle>
              <LogoImage src={getImage(props.resumeData.logo)}/>
            </LogoImageWrapper>
            <NameDesignationWrapper RightSideImageHandle>
              <CandidateName >{props?.resumeData?.fullName}</CandidateName>
              <CandidateDesignation>
               {props?.resumeData?.designation}
              </CandidateDesignation>
            </NameDesignationWrapper>

            <CardWrapper RightSideImageHandle>
              <Card RightSideCardResp>
                <CardNameWrapper>
                  <CardName>Details</CardName>
                </CardNameWrapper>
                <AddressContainer>
                  {props?.resumeData?.city && (
  <AddressKeyWrapper>Address</AddressKeyWrapper>
                  )}
                
                  <AddressValueWrapper>{props?.resumeData?.city}</AddressValueWrapper>
                </AddressContainer>

                <AddressContainer>
                  <AddressKeyWrapper>Phone</AddressKeyWrapper>
                  <AddressValueWrapper>{props?.resumeData?.phone}</AddressValueWrapper>
                </AddressContainer>

                <AddressContainer>
                  <AddressKeyWrapper>Email </AddressKeyWrapper>
                  <AddressValueWrapper>{props?.resumeData?.email}</AddressValueWrapper>
                </AddressContainer>

                <AddressContainer>
                  {props?.resumeData?.linkedIn && (
                    <AddressKeyWrapper>Linkdin </AddressKeyWrapper>
                  )}
                  
                  <AddressValueWrapper>{props?.resumeData?.linkedIn}</AddressValueWrapper>
                </AddressContainer>
              </Card>

            </CardWrapper>

                  {props?.resumeData?.summary && (
                  <HeadingWrapperContainer ResponsivePadManage>
                  <HeadingWrapper>
                  profile
                  </HeadingWrapper>
                  <ProfileSummary>
                  {props?.resumeData?.summary}
                  </ProfileSummary>
                  </HeadingWrapperContainer>
                  )}

            {props?.resumeData?.is_wrkExp_is_enabled === 1 &&
          renderWorkExperience()}

            {/* <HeadingWrapperContainer>
              <HeadingWrapper>
              experience
              </HeadingWrapper>
              <ExperienceCompanyContainer>
                <CompanyNameWrapper>
                    <CompanyName>Uber</CompanyName>
                    <DesignationLocationDateWrapper>
                      <ExpDesignation>Product Designer,</ExpDesignation>
                      <ExpLocationDate>Hauzkhaz, Sep 2023 - May 2024</ExpLocationDate>
                    </DesignationLocationDateWrapper>
                    <ExpSummary>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                    </ExpSummary>
                </CompanyNameWrapper>
              </ExperienceCompanyContainer>
            </HeadingWrapperContainer> */}

            {/* <HeadingWrapperContainer>
              <HeadingWrapper>
              PROJECT
              </HeadingWrapper>
              <ExperienceCompanyContainer>
                <CompanyNameWrapper>
                  
                    <DesignationLocationDateWrapper ProjectsWrapper>
                      <ProDesignation>Veuzen Application ,</ProDesignation>
                      <ProLocationDate>Sep 2023 - May 2024</ProLocationDate>
                    </DesignationLocationDateWrapper>
                    <ExpSummary>
                    Rich in style and well-crafted, vuezen optical families to see clearly, and claim their fashion statement at the same time.
                    </ExpSummary>

                    <ProjectLink>https://veuzen.com</ProjectLink>
                </CompanyNameWrapper>

              
              </ExperienceCompanyContainer>
            </HeadingWrapperContainer> */}
             {props?.resumeData?.is_project_is_enabled === 1 && renderProjects()}

            {/* <HeadingWrapperContainer>
              <HeadingWrapper>
              Education
              </HeadingWrapper>
             <EducationWrapper>
              <EducationDegree> 12th</EducationDegree>
              <EducationSchoolName> DPS School</EducationSchoolName>
              <EducationBoardYear>CBSE Board</EducationBoardYear>
              <EducationBoardYear>2018-2019</EducationBoardYear>
             </EducationWrapper>
            </HeadingWrapperContainer> */}

{props?.resumeData?.is_education_is_enabled === 1 && renderEducation()}
{props?.resumeData?.is_achievement_is_enabled === 1 &&
          renderAchievement()}

{props?.resumeData?.is_prsnldetail_is_enabled === 1 &&
          renderPrsnldetails()}

            {/* <HeadingWrapperContainer>
              <HeadingWrapper>
              Personal Information
              </HeadingWrapper>
              <PersonalDetailContainer>
                <PersonalNameWrapper>
                  <PersonalNameKey>Father/ Spouse Name</PersonalNameKey>
                  <PersonalNameValue>Mr. Wade Warren</PersonalNameValue>
                </PersonalNameWrapper>
                <PersonalNameWrapper>
                  <PersonalNameKey>Marital Status</PersonalNameKey>
                  <PersonalNameValue>Married</PersonalNameValue>
                </PersonalNameWrapper>
                <PersonalNameWrapper>
                  <PersonalNameKey>DOB</PersonalNameKey>
                  <PersonalNameValue>07/03/2000</PersonalNameValue>
                </PersonalNameWrapper>
              </PersonalDetailContainer>
            
            </HeadingWrapperContainer> */}
            </RightSideBar>
          </Wrapper>
        <PoweredWrapper>
          <Power>
            Powered by <PowerSpan>BusinessBay</PowerSpan>
          </Power>
        </PoweredWrapper>
      </Container>
    </Root>
  );
};

export default Resume5Ext;
