import styled from "styled-components";

export const Root = styled.div`
display:flex;
justify-content: center;
`;

export const Container = styled.div`
width: 1260px;
`;

export const Wrapper = styled.div`
width: 100%;
display: flex;
padding: 0 20px;

@media(max-width: 768px){
    display: flex;
    flex-direction: column-reverse;
}
@media(max-width: 525px){
    padding: 0px;
}
`;
export const LeftSideBar = styled.div`
width: 30%;
background-color:#074C51;
padding: 0px 0;

@media(min-width: 768px) and (max-width: 940px){
    width: 40%;
}
@media(max-width: 768px){
    width: 100%;
    background-color:#fff;
    padding: 0;
}

`;
export const LeftSideBarJs = styled(LeftSideBar)`
width: 100%;
background-color:#fff;
padding: 0px;
`;

export const LogoImageWrapper = styled.div`
// @media(min-width: 768px){
//     display: none;
// }
${(p)=>p.LeftSideImageLogo && `
 @media(min-width: 0px) and (max-width: 768px){
    display: none;
 }
`}

${(p)=>p.RightSideImageHandle && `
 @media(min-width: 768px) and (max-width: 40000px){
    display: none;
 }
`}
`;
export const LogoImage = styled.img`
width: 100%;
`;

export const NameDesignationWrapper = styled.div`
display:flex;
justify-content: center;
align-items: center;
text-align: center;
flex-direction:column;
padding: 15px 0 0px 0;
// @media(min-width: 768px){
//     display: none;
// }
${(p)=>p.LeftSideImageLogo && `
 @media(min-width: 0px) and (max-width: 768px){
    display: none;
 }
`}
${(p)=>p.RightSideImageHandle && `
@media(min-width: 768px) and (max-width: 40000px){
    display: none;
 }
`}
`;
export const CandidateName = styled.div`
font-family: "Roboto", sans-serif;
font-weight: 700;
font-size: 28px;
color: #fff;
@media(max-width: 768px){
    color: #000;
}
`;
export const CandidateDesignation = styled.div`
font-family: "Roboto", sans-serif;
font-weight: 500;
font-size: 18px;
color: rgba(255, 255, 255, 0.70);
@media(max-width: 768px){
    color: rgba(0, 0, 0, 0.70);
}
`;


export const CardWrapper = styled.div`
${(p)=>p.LeftSideImageLogo && `
 @media(min-width: 0px) and (max-width: 768px){
    display: none;
 }
`}

${(p)=>p.RightSideImageHandle && `
 @media(min-width: 768px) and (max-width: 40000px){
    display: none;
 }
`}
`;
export const Card = styled.div`
padding-left: 35px;
@media(max-width: 1024px){
    padding-left: 30px;
}
${(p)=>p.RightSideCardResp && `
@media(max-width: 768px){
    padding-left: 0px !important;
}
`}
`;
export const CardNameWrapper = styled.div`
margin: 30px 0 15px 0;
`;
export const CardName = styled.div`
font-family: "Roboto", sans-serif;
font-weight: 700;
font-size: 32px;
color: #fff;
text-transform: uppercase;
letter-spacing:1px;

@media(max-width: 1260px){
    font-size: 30px;
}
@media(min-width: 940px) and (max-width: 1024px){
    font-size: 26px;
}
@media(max-width: 768px){
    color: #000;
}

`;
export const AddressContainer = styled.div`
padding: 8px 0;
`;
export const AddressKeyWrapper = styled.div`
font-family: "Roboto", sans-serif;
font-weight: 700;
font-size: 18px;
color: #fff;
@media(max-width: 768px){
    color: #000;
}
`;
export const AddressValueWrapper = styled.div`
font-family: "Roboto", sans-serif;
font-weight: 700;
font-size: 18px;
color: rgba(255, 255, 255, 0.70);
word-wrap: break-word;
@media(max-width: 768px){
    color: rgba(0, 0, 0, 0.70);
}
`;

export const ToolsWrapper = styled.ul`
padding-left: 30px;
`;
export const ToolsWrapperList = styled.li`
font-family: "Roboto", sans-serif;
font-weight: 700;
font-size: 18px;
color: rgba(255, 255, 255, 0.70);
list-style-type:disc;
padding: 4px 0;
@media(max-width: 768px){
    color: rgba(0, 0, 0, 0.70);
}
`;


export const CertificationWrapper = styled.div``;
export const CeartificationKeyName = styled.div`
font-family: "Roboto", sans-serif;
font-weight: 700;
font-size: 18px;
color: #fff;
padding: 8px 0;
@media(max-width: 768px){
    color: #000;
}
`;
export const CertificationValueName = styled.div`
font-family: "Roboto", sans-serif;
font-weight: 700;
font-size: 18px;
color: rgba(255, 255, 255, 0.70);
@media(max-width: 768px){
    color: rgba(0, 0, 0, 0.70);
}
`;


export const RightSideBar = styled.div`
width: 70%;
background-color:#fff;
padding: 40px;
@media(min-width: 768px) and (max-width: 940px){
    width: 60%;
}
@media(max-width: 768px){
    width: 100%;
}
@media(max-width: 600px){
    padding: 40px 40px 0 40px ;
}
`;

export const HeadingWrapperContainer = styled.div`

padding: 0 0 25px 0;

${(p)=>p. ResponsivePadManage && `
@media(max-width: 768px){
    padding: 25px 0;
}
`}
`;
export const HeadingWrapper = styled.div`
font-family: "Roboto", sans-serif;
font-weight: 700;
font-size: 32px;
color: #000;
text-transform: uppercase;
`;
export const ProfileSummary = styled.div`
padding: 10px 0;
font-family: "Roboto", sans-serif;
font-weight: 700;
font-size: 16px;
color: rgba(0, 0, 0, 0.70);
`;
export const ExperienceCompanyContainer = styled.div``;
export const CompanyNameWrapper = styled.div`
padding: 15px 0;
`;
export const CompanyName = styled.div`
font-family: "Roboto", sans-serif;
font-weight: 700;
font-size: 20px;
color: #000;
`;
export const DesignationLocationDateWrapper = styled.div`
display:flex;
padding: 2px 0 5px 0;

${(p)=>p.ProjectsWrapper && `
padding: 5px 0 10px 0;
`}
@media(min-width: 768px) and (max-width: 940px){
    display:flex;
    flex-direction: column;
}
@media(max-width: 600px){
    display:flex;
    flex-direction: column;
   
}
`;
export const ExpDesignation = styled.div`
font-family: "Roboto", sans-serif;
font-weight: 700;
font-size: 16px;
color: #000;
`;
export const ExpLocationDate = styled.div`
font-family: "Roboto", sans-serif;
font-weight: 700;
font-size: 16px;
padding: 0 10px;
color: rgba(0, 0, 0, 0.70);

@media(min-width: 768px) and (max-width: 940px){
    padding: 0px;
}

@media(max-width: 600px){
    padding: 0px;
}
`;
export const ExpSummary = styled.div`
font-family: "Roboto", sans-serif;
font-weight: 700;
font-size: 16px;
color: rgba(0, 0, 0, 0.70);
`;


export const ProDesignation = styled.div`
font-family: "Roboto", sans-serif;
font-weight: 700;
font-size: 20px;
color: #000;
`;

export const ProLocationDate = styled.div`
font-family: "Roboto", sans-serif;
font-weight: 700;
font-style: italic;
font-size: 20px;
padding: 0 10px;
color: rgba(0, 0, 0, 0.70);
@media(max-width: 600px){
    padding: 0px;
}
`;

export const ProjectLink = styled.div`
font-family: "Inter", sans-serif;
font-size: 20px;
color: #1D87E2;
padding: 5px 0;
cursor: pointer;
font-weight: 700;
`;

export const EducationWrapper= styled.div`
padding: 15px 0;
`;
export const EducationDegree= styled.div`
font-family: "Roboto", sans-serif;
font-weight: 700;
font-size: 20px;
color: #000;
padding: 2px 0;
`;
export const EducationSchoolName= styled.div`
font-family: "Roboto", sans-serif;
font-weight: 700;
font-size: 17px;
color: rgba(0, 0, 0, 0.70);
padding: 2px 0;
`;
export const EducationBoardYear= styled.div`
font-family: "Roboto", sans-serif;
font-weight: 700;
font-size: 17px;
color: #000;
padding: 2px 0;
`;

export const AchivementsWrapper = styled.ul`
padding: 15px 0 15px 30px;
`;
export const AchivementsList = styled.li`
font-family: "Roboto", sans-serif;
font-weight: 700;
font-size: 17px;
color: rgba(0, 0, 0, 0.70);
padding: 1px 0 1px 0px;
list-style-type: disc;
`;

export const PersonalDetailContainer = styled.div`
padding: 15px 0;
`;
export const PersonalNameWrapper = styled.div`
padding: 5px 0;
`;
export const PersonalNameKey = styled.div`
font-family: "Roboto", sans-serif;
font-weight: 700;
font-size: 20px;
color: #000;

`;
export const PersonalNameValue = styled.div`
font-family: "Roboto", sans-serif;
font-weight: 700;
font-size: 20px;
color: rgba(0, 0, 0, 0.70);
`;

export const PoweredWrapper = styled.div`
background-color: #F7F8F8;
margin: 0 20px;
padding: 15px 0;
display:flex;
justify-content: center;
align-items: center;
text-align: center;
`;
export const  Power = styled.div`

font-size: 18px;
font-family: "Roboto", sans-serif;
font-weight: 500;
display: flex;
color: rgba(0, 0, 0, 0.30);
`;
export const PowerSpan = styled.div`
font-size: 18px;
font-family: "Roboto", sans-serif;
font-weight: 500;
color: rgba(0, 0, 0, 0.50);
padding: 0 0 0 5px;
`;


export const InnerWrapper = styled(Wrapper)`
flex-direction: column;
padding: 0px;
`;

export const InnerLeftSideBar = styled(LeftSideBar)`
width: 100%;
background-color:#fff;
`;

export const InnerRightSideBar = styled(RightSideBar)`
width: 100%;
padding: 40px 40px 0 40px;
`;

export const InnerDesignationLocationDateWrapper = styled(DesignationLocationDateWrapper)`
display:flex;
flex-direction: column;
`;
export const InnerExpLocationDate = styled(ExpLocationDate)`
padding: 0px;
`;

export const InnerCandidateName = styled(CandidateName)`
color: #000;
`;

export const InnerCandidateDesignation = styled(CandidateDesignation)`
color: rgba(0, 0, 0, 0.70);
`;

export const InnerCardName = styled(CardName)`
color: #000;
`;

export const InnerAddressKeyWrapper = styled(AddressKeyWrapper)`
color: #000;
`;

export const InnerAddressValueWrapper = styled(AddressValueWrapper)`
color: rgba(0, 0, 0, 0.70);
`;

export const InnerToolsWrapperList = styled(ToolsWrapperList)`
color: rgba(0, 0, 0, 0.70);

`;

export const InnerCeartificationKeyName = styled(CeartificationKeyName)`
color: #000;
`;

export const InnerCertificationValueName = styled(CertificationValueName)`
color: rgba(0, 0, 0, 0.70);
`;

export const InnerProLocationDate = styled(ProLocationDate)`
padding: 0px;
`;