import React, { useContext, useEffect, useState, useRef } from "react";
import { json, useNavigate } from "react-router";
import { environmentVariables } from "../../config/env.config";
import { userContext } from "../../context/userContext";
import axios from "axios";
import { toast } from "react-toastify";



// import './App.css';

const ProductDisplay = () => (
  <section>
    <div className="product">
      <div className="description">
        <h3>Starter plan</h3>
        <h5>$20.00 / month</h5>
      </div>
    </div>
    <form action="http://localhost:5000/create-checkout-session" method="POST">
      {/* Add a hidden field with the lookup_key of your Price */}
      <input type="hidden" name="lookup_key" value="{{PRICE_LOOKUP_KEY}}" />
      <button id="checkout-and-portal-button" type="submit">
        Checkout
      </button>
    </form>
  </section>
);

const SuccessDisplay = ({ sessionId }) => {
  return (
    <section>
      <div className="product Box-root">
        <div className="description Box-root">
          <h3>Subscription to starter plan successful!</h3>
        </div>
      </div>
      <form action="/create-portal-session" method="POST">
        <input
          type="hidden"
          id="session-id"
          name="session_id"
          value={sessionId}
        />
        <button id="checkout-and-portal-button" type="submit">
          Manage your billing information
        </button>
      </form>
    </section>
  );
};

const Message = ({ message }) => (
  <section>
    <p>{message}</p>
  </section>
);



export default function StripeSubscriptionPaymentSuccess() {
  const [message, setMessage] = useState('');
  const [success, setSuccess] = useState(false);
  const [sessionId, setSessionId] = useState('');
  const navigate = useNavigate();
  const baseUrl = environmentVariables?.apiUrl;
  const { userData, setUserData } = useContext(userContext);

  const Add_create_plan_orders=(rs)=>{
  
    // console.log(rs ,"rs?.paymentIntent?.id @@$@")
    
    if(!rs&& rs?.payment_status=="paid"){
return
    }
    let vcard_plan=JSON.parse(rs?.metadata?.vcard_plan)
    let vcard_user=JSON.parse(rs?.metadata?.vcard_user)
     
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${environmentVariables?.apiUrl}api/v1/plan/create_plan_orders`,
      headers: {
        _token: userData?.token,
        "Content-Type": "application/json",
      },
      // withCredentials:true,
      data: {
        plan_id:vcard_plan?.id,
        plan_name:vcard_plan?.name,
        order_id: Date.now(),
        card_number: "1234",
        card_exp_month: "12",
        // price: rs?.paymentIntent?.amount / 100 || 100,
        price: Math.ceil(rs?.amount_total/100) ,
        // price_currency: rs?.paymentIntent?.currency || 'inr',
        price_currency: rs?.currency ,
        // txn_id: rs?.paymentIntent?.id || 'tx'+Math.floor(Math.random() * 9),
        txn_id: rs?.subscription,//need to discuss here
        payment_status: rs?.payment_status,
        receipt: "Receipt Info "+rs?.invoice,
        store_id: 1,
        payment_type: "Stripe "+rs?.mode,
        payment_data: JSON.stringify(rs?.paymentIntent),
        name: vcard_user?.name,
        email: vcard_user?.email,
        user_id:vcard_user?.id,
      },
    };
    axios
      .request(config)
      .then((response) => {
        //update localstorage value
        // console.log("vvvvvvvvc2a",JSON.stringify(obj))
        let sUser=JSON.parse(localStorage.getItem("user"));
        let obj={
          ...sUser
        }
        obj.plan=planData.id
        localStorage.setItem("user", JSON.stringify(obj));


        const date = new Date(response?.buy_date);

        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0"); // Adding 1 to month as it's zero-indexed
        const day = String(date.getDate()).padStart(2, "0");
  
        const expiryDate = new Date(response?.expiry_date);
        const expiryYear = expiryDate.getFullYear();
        const expiryMonth = String(expiryDate.getMonth() + 1).padStart(2, "0"); // Adding 1 to month as it's zero-indexed
        const expiryDay = String(expiryDate.getDate()).padStart(2, "0");
  
        setUserData({
          ...userData,
          plan: planId,
          findPlanArr: [
            ...userData?.findPlanArr,
            {
              buy_date: `${year}-${month}-${day}`,
              expiry_date: `${expiryYear}-${expiryMonth}-${expiryDay}`,
              plan_id: planData.id,
              start_date: `${year}-${month}-${day}`,
              user_id: userData?.id,
            },
          ],
        });

        // console.log("Paymentm succeededm");
        // setIsSubmit(false);
        // setPaymentIntent(paymentIntent);
        // setPaymentPopUp(true);
        // setPaymentSuccess(true);
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      });
  }

  // console.log(userData,"userDauserDatauserData")

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    if(!query.get('session_id')){
      return
    }
    const session_id = query.get('session_id');
  
    if (!session_id) {
      setMessage('Session ID not found in the URL.');
      return;
    }
  
    const data = { session_id };
  
    const config = {
      method: 'post',
      url: `${baseUrl}api/v1/pg/RetrieveSessions`,
      headers: {
        '_token': userData?.token,
        'Content-Type': 'application/json',
      },
      data: data,
    };
  
    axios
      .request(config)
      .then((response) => {
        // console.log("Response data:", response?.data?.data);
        Add_create_plan_orders(response?.data?.data);
        // navigate('/payment-plan-list')
        setSessionId(session_id);
        setSuccess(true);
        setMessage('Session retrieved successfully.');
      })
      .catch((error) => {
        console.error('Errqqqqqqqqqqor:', error);
        setMessage('Failed to retrieve session.');
      });
  }, []);


  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    let q_session_id= query.get('session_id')
    // console.log(q_session_id,"q_session_id")
    setSessionId(query.get('session_id'));



    if (query.get('canceled')) {
      setSuccess(false);
      setMessage(
        "Order canceled -- continue to shop around and checkout when you're ready."
      );
    }
  }, [sessionId]);

  if (!success && message === '') {
    // return <ProductDisplay />;
    navigate('/payment-plan-list')
  } else if (success && sessionId !== '') {

    // console.log("Asdf sessionIdsessionIdsessionId",success,sessionId)


    navigate('/payment-plan-list')

    // return <SuccessDisplay sessionId={sessionId} />;
  } else {
    return <Message message={message} />;
  }
}

 