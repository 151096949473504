import React from 'react'
import { Container } from 'react-bootstrap'
import HeroSection from './Components/Landing/HeroSection'
import SecondSection from './Components/Landing/SecondSection'
import AppointmentSection from './Components/Landing/AppointmentSection'
import TemplatesSection from './Components/Landing/TemplatesSection'
import EventSection from './Components/Landing/EventSection'
import Subscribe from './Components/Landing/Subscribe'
import Footer from './Components/Landing/Footer'
import { WhatsappIcon } from "./Components/WhatsappIcon";

const LandingNew = ({userData, setUserData}) => {
  return (
    <>
        <HeroSection/>
        <SecondSection/>
        <AppointmentSection/>
        <TemplatesSection userData={userData}/>
        <EventSection/>
        <Subscribe userData={userData}/>
        <Footer/>
        <WhatsappIcon />
    </>
  )
}

export default LandingNew