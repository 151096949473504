import React, { useEffect } from "react";
import { useState } from "react";
import { environmentVariables } from "../../../../config/env.config";

const GalleryCarousel = ({ slides, video }) => {
  return (
    <div className="newgallery" style={{marginBottom:"30px"}}>
      <ul>
        {slides.length > 0 &&
          slides.map((val) => (
            <li>
              <img
                src={`${environmentVariables?.apiUrl}uploads/gallery/${val?.image}`}
              />
            </li>
          ))}
      </ul>
    </div>
  );
};

export default GalleryCarousel;
