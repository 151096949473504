import React from "react";
function SubContactInfo(props) {
  let rs = props.rs;
  let ff = rs?.contactInfo?.content;
  if (ff?.length) {
    ff = JSON?.parse(ff);
  }
  if (ff?.length) {
    ff = JSON?.parse(ff);
  }

  // console.log(ff,"adfff")
  //return {rs?.contactInfo && ( )
  return (
    <>
      <article class="contact sec">
        <div className="contact-inner">
          <h3 class="sectitle contactinfo">Contact us</h3>

          <div class="conlink">
            <div className="contact-us-grid">
              <div className="contact-info-container">
                <div className="contact-info-details">
                  <div className="contact-info-icon">
                    <span className="icon-bg">
                      <i className="fa fa-solid fa-phone"></i>
                    </span>
                  </div>
                  {/* <p className="contact-info-label">Phone</p> */}
                  <a
                    className="contactinfolink content"
                    href={
                      ff?.contactInfoPhone ? `tel:${ff?.contactInfoPhone}` : ""
                    }
                  >
                    <span className="label">{`Mobile`}</span>
                    <span className="contactinfotext value">
                      {ff?.contactInfoPhone || ``}
                    </span>
                  </a>
                </div>
              </div>
              <div className="vertical-line"></div>

              <div className="vertical-line"></div>
              <div className="contact-info-container">
                <div className="contact-info-details">
                  <div className="contact-info-icon">
                    <span className="icon-bg">
                      <i className="fa fa-solid fa-envelope"></i>
                    </span>
                  </div>
                  {/* <p className="contact-info-label">Email</p> */}
                  <a
                  className="contactinfolink content"
                    href={
                      ff?.contactInfoEmail ? `mailto:${ff?.contactInfoEmail}` : ""
                    }
                  >
                    <span className="label">{`Email`}</span>
                    <span className="contactinfotext value">
                      {ff?.contactInfoEmail}
                    </span>
                  </a>
                </div>
              </div>

              <div className="contact-info-container">
                <div className="contact-info-details">
                  <div className="contact-info-icon">
                    <span className="icon-bg">
                      <i className="fa fa-solid fa-location-dot"></i>
                    </span>
                  </div>
                  {/* <p className="contact-info-label">Address</p> */}
                  <a className="contactinfolink content">
                  <span className="label">{`Address`}</span>
                    <span className="contactinfotext value">
                    {ff?.contactInfoAddress?.split('\n').map((line, index) => (
              <React.Fragment key={index}>
                {line}
                <br />
              </React.Fragment>
            ))}
                    </span>
                  </a>
                </div>
              </div>
            </div>
            <li>
          {/* <a href={(ff?.contactInfoWebUrl)?`http://${ff?.contactInfoWebUrl.replace(/^(https?:\/\/)?(www\.)?/, '')}`:''} target="_blank"> */}
          <a
              href={(ff?.contactInfoWebUrl) ? `http://${ff?.contactInfoWebUrl.replace(/^(https?:\/\/)?(www\.)?/, '')}` : '#'}
              onClick={(e) => {
                if (!ff?.contactInfoWebUrl) {
                  e.preventDefault(); // Prevents the default link behavior
                }
              }}
              target={ff?.contactInfoWebUrl ? '_blank' : undefined}
              className="contactinfolink content"
            >
               <i class="fa-solid fa-link"></i>
              <span>{ff?.contactInfoWebUrl}</span>
            </a>
          </li>
          </div>
        </div>
      </article>
    </>
  );
}
export default SubContactInfo;
