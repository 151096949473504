import React, { useState } from 'react'
import OneWayTabComp from './OneWayTabComp';
import Banner from "./../../../../Images/WelcomeScreenIcon.png";
import RoundTTabCom from './RoundTTabCom';
import { LocalTabCom } from './LocalTabCom';
import AirportCabCom from './AirportCabCom';

const CabsTab = () => {
    const [activeTab , setActiveTab] = useState("oneway");

const rendersTab = () =>{
    switch(activeTab){
        case "oneway":
            return <div><OneWayTabComp /></div>
        case "roundtrip":
            return <div><RoundTTabCom /></div>
        case "local":
            return <div><LocalTabCom /></div>
        case "airport":
            return <div><AirportCabCom /></div>
    }
}

const getTabClassName = (tabName) => {
    return `tab ${activeTab === tabName ? 'activejs' : 'inactivejs'}`;
};


return (
  <div className='w-hundred'>
      <div className='flex justify-center items-center py-2 ' style={{}}>
        <div 
        // className='font-Poppins text-white font-medium cursor-pointer' 
          className={getTabClassName('oneway')} onClick={()=>setActiveTab("oneway")}> one Way </div>
        <div  
        // className='font-Poppins text-white font-medium cursor-pointer' 
        className={getTabClassName('roundtrip')}
        //  style={tabStyles('roundtrip')}
          onClick={()=>setActiveTab("roundtrip")}> Round Trip </div>
        <div  
        // className='font-Poppins text-white font-medium cursor-pointer' 
        className={getTabClassName('local')}
          onClick={()=>setActiveTab("local")}>  Local </div>
        <div 
        //  className='font-Poppins text-white font-medium cursor-pointer'
        className={getTabClassName('airport')}
        onClick={()=>setActiveTab("airport")}>  Airport </div>
    </div>
    <div className='py-4 px-2'>{rendersTab()}</div>
  </div>
  )
}

export default CabsTab;