import {
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";
import { toast } from "react-toastify";
import { useState, useContext, useEffect } from "react";
import Swal from "sweetalert2";
import "./CheckoutForm.css";
import CircularLoader from "../Components/CircularLoader/CircularLoader";
import { json, useNavigate } from "react-router";
import { environmentVariables } from "../../config/env.config";
import { userContext } from "../../context/userContext";
import { planContext } from "../../context/planContext";
import successright from "../../Images/check.png";

import axios from "axios";

import { currencyContext } from "../../context/currencyContext.js";
const CheckoutFormPlan = ({ cardId, planId, clientSecret }) => {
  const [isSubmit, setIsSubmit] = useState(false);
  const { userData, setUserData } = useContext(userContext);
  // const { planData } = useContext(planContext);
  const navigate = useNavigate();
  const [paymentPopUp, setPaymentPopUp] = useState(false);
  const [paymentSuccess, setPaymentSuccess] = useState(null);
  const [paymentIntent1, setPaymentIntent] = useState(null);
  const stripe = useStripe();
  const elements = useElements();
  let planData = JSON.parse(localStorage.getItem("plan_pay"));
  const { currencyObjData, setCurrencyObjData } = useContext(currencyContext);
  const [responseData, setResponseData] = useState({});

  
  //   console.log(clientSecret, "client secret inside payment form");
  const handleClosePopUp = () => {
    setPaymentPopUp(false);
    if (paymentSuccess) {
      const date = new Date(responseData?.buy_date);

      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0"); // Adding 1 to month as it's zero-indexed
      const day = String(date.getDate()).padStart(2, "0");

      const expiryDate = new Date(responseData?.expiry_date);
      const expiryYear = expiryDate.getFullYear();
      const expiryMonth = String(expiryDate.getMonth() + 1).padStart(2, "0"); // Adding 1 to month as it's zero-indexed
      const expiryDay = String(expiryDate.getDate()).padStart(2, "0");

      setUserData({
        ...userData,
        plan: planId,
        findPlanArr: [
          // ...userData?.findPlanArr,
          {
            buy_date: `${year}-${month}-${day}`,
            expiry_date: `${expiryYear}-${expiryMonth}-${expiryDay}`,
            plan_id: planData.id,
            start_date: `${year}-${month}-${day}`,
            user_id:
              userData?.created_by == 0 ? userData?.id : userData?.created_by,
          },
        ],
      });
      // navigate("/payment-plan-list");
      navigate("/plans");
      // history.push('/payment-plan-list');
    } else {
      navigate("/create-card22");
    }
  };
  // useEffect(() => {
  //   console.log(userData, "userData @ 23# $");
  // }, [userData]);

  const handleSubmit = async (event) => {
    setIsSubmit(true);
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    try {
      const { error, paymentIntent } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: `${environmentVariables?.apiUrl}requestCard`,
        },
        redirect: "if_required",
      });
      // console.log(paymentIntent, "payment intentm");
      if (error) {
        console.error(error);
        // handleError();
        setIsSubmit(false);
        toast.error(error, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      } else if (paymentIntent && paymentIntent?.status === "succeeded") {
        let config = {
          method: "post",
          maxBodyLength: Infinity,
          url: `${environmentVariables?.apiUrl}api/v1/plan/create_plan_orders`,
          headers: {
            _token: userData?.token,
            "Content-Type": "application/json",
          },
          // withCredentials:true,
          data: {
            plan_id: planData?.id,
            plan_name: planData?.name,
            order_id: Date.now(),
            card_number: "1234",
            card_exp_month: "12",
            price: paymentIntent?.amount / currencyObjData?.current_value,
            price_currency: paymentIntent?.currency,
            txn_id: paymentIntent?.id,
            payment_status: "success",
            receipt: "receipt_info",
            store_id: 1,
            payment_type: "Stripe",
            payment_data: JSON.stringify(paymentIntent),
            name:
              userData?.created_by != 0
                ? userData?.parentUser?.name
                : userData?.name,
            email:
              userData?.created_by != 0
                ? userData?.parentUser?.email
                : userData?.email,
            user_id:
              userData?.created_by != 0 ? userData?.created_by : userData?.id,
          },
        };

        axios
          .request(config)
          .then((response) => {
            // console.log(response?.data, "responseresponse");
            setResponseData(response?.data?.data);
            //update localstorage value
            let sUser = JSON.parse(localStorage.getItem("user"));
            let obj = {
              ...sUser,
            };
            obj.plan = planData.id;
            // console.log(planData, "plandata@@!!!!!!!");
            localStorage.setItem("user", JSON.stringify(obj));

            // console.log("S22a",JSON.stringify(obj))
            // console.log("Paymentm succeededm");
            setIsSubmit(false);
            setPaymentIntent(paymentIntent);
            setPaymentPopUp(true);
            setPaymentSuccess(true);
          })
          .catch((error) => {
            navigate("/plans");
            toast.error(error?.response?.data?.message, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 1000,
            });
            // window.location.reload();
          });
      } else {
        console.log("Payment failed");
        // handleOther();

        // let config = {
        //   method: "post",
        //   maxBodyLength: Infinity,
        //   url: `${environmentVariables?.apiUrl}api/v1/plan/create_plan_orders`,
        //   headers: {
        //     _token: userData?.token,
        //     "Content-Type": "application/json",
        //   },
        //   // withCredentials:true,
        //   data: {
        //     plan_id: planData.id,
        //     plan_name: planData.name,
        //     order_id: Date.now(),
        //     card_number: "1234",
        //     card_exp_month: "12",
        //     price: paymentIntent?.amount / currencyObjData?.current_value,
        //     price_currency: paymentIntent?.currency,
        //     txn_id: paymentIntent?.id,
        //     payment_status: "failed",
        //     receipt: "receipt_info",
        //     store_id: 1,
        //     payment_type: "Stripe",
        //     payment_date: JSON.stringify(paymentIntent),
        //     name: userData.name,
        //     email: userData.email,
        //     user_id: userData.id,
        //   },
        // };

        // axios
        //   .request(config)
        //   .then((response) => {
        //     setIsSubmit(false);
        //     setPaymentIntent(paymentIntent);
        //     setPaymentPopUp(true);
        //     setPaymentSuccess(false);
        //   })
        //   .catch((error) => {
        //     toast.error(error?.response?.data?.message, {
        //       position: toast.POSITION.TOP_RIGHT,
        //     });
        //   });
      }
    } catch (error) {
      console.error(error);
      toast.error(error, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
      setIsSubmit(false);
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <PaymentElement />
        <button
          type="submit"
          className="btn try_theme_btn mt-3 mx-auto d-block"
          disabled={!stripe}
        >
          {isSubmit ? <CircularLoader size={20} /> : "Submit"}
        </button>
      </form>
      {paymentPopUp ? (
        <>
          {" "}
          <div className="popup-outer">
            <div className="popup-overlay"></div>
            <div className="popup-inner">
              <div className="payment_heaader">
                {paymentSuccess ? "Payment Successful" : "Payment Failed"}
              </div>
              <img src={successright} class="" />
              <div className="inner-box mt-4">
                <h1 className="name">Payment Details</h1>
                <div className="orderbox">
                  <ul className="list-group mb-3">
                    <li className="list-group-item d-flex justify-content-between">
                      <span>Receiver</span>
                      <strong>Businessbay</strong>
                    </li>
                    <li className="list-group-item d-flex justify-content-between">
                      <span> Amount</span>
                      {/* <strong>{parseInt(paymentIntent1?.amount / 100)}</strong> */}
                      <strong>{currencyObjData?.symbol} {parseInt(paymentIntent1?.amount)}</strong>
                    </li>

                    <li className="list-group-item d-flex justify-content-between">
                      <span>Payment Method Type</span>
                      <strong>{paymentIntent1?.payment_method_types[0]}</strong>
                    </li>
                    <li className="list-group-item d-flex justify-content-between">
                      <span>Transaction Id</span>
                      <strong>{paymentIntent1?.id}</strong>
                    </li>
                  </ul>
                  <button
                    type="submit"
                    className="btn try_theme_btn mt-3 mx-auto d-block save-cta"
                    onClick={handleClosePopUp}
                  >
                    close
                  </button>
                </div>
                <div className="order-box">
                  {/* Your payment details here */}
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
      {/* <div className="popup-outer">
        <div className="popup-inner">
          <div className="iinerboxxx mt-4">
            <h1 className="name">Payment Details</h1>

            <div className="orderbox">
              <ul className="list-group mb-3">
                <li className="list-group-item d-flex justify-content-between">
                  <span>Receiver</span>
                  <strong>Businessbay</strong>
                </li>
                <li className="list-group-item d-flex justify-content-between">
                  <span> Amount</span>
                  <strong>299</strong>
                </li>

                <li className="list-group-item d-flex justify-content-between">
                  <span>Payment Method Type</span>
                  <strong>Card</strong>
                </li>
                <li className="list-group-item d-flex justify-content-between">
                  <span>Transaction Id</span>
                  <strong>89127981723</strong>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <form onSubmit={handleSubmit}>
        <PaymentElement />
        <button
          type="submit"
          class="btn try_theme_btn mt-3  mx-auto d-block"
          // class="btn try_theme_btn mt-3 w-50 mx-auto d-block"
          disabled={!stripe}
        >
          {isSubmit ? <CircularLoader size={20} /> : "Submit"}
        </button>
      </form> */}
    </>
  );
};

export default CheckoutFormPlan;
