import React, { useState } from 'react'
import OneWayTabComp from './OneWayTabComp';
import RoundTTabCom from './RoundTTabCom';
import { LocalTabCom } from './LocalTabCom';
import AirportCabCom from './AirportCabCom';
import axios from 'axios';
import { environmentVariables } from "../../../../config/env.config";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ExtCabsTab = (props) => {
  const [activeTab , setActiveTab] = useState("oneway");
let businesscontact = props?.busdata?.contactInfo?.content;
if (businesscontact?.length) {
    businesscontact = JSON?.parse(businesscontact);
  }

  const handleEnquirySubmit = async (enquiryData, resetForm) => {
    const data = {...enquiryData,
        business_id:  props.busdata.id
    }
    try {
        const response = await axios.post(`${environmentVariables?.apiUrl}api/v1/business/create_cab_enquiry`, data);
        console.log("Enquiry submitted successfully:", response);
        whatsappclick(data)
        toast.success("Enquiry submitted successfully!", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
          resetForm(); 
    } catch (error) {
        console.error("Error submitting enquiry:", error);
        toast.error(error?.response?.data?.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });

    }
};

const whatsappclick = (data) => {
    console.log("whatsappclick",data)
        const phoneNumber = businesscontact?.contactInfoPhone;
        const { date, from, to,time,phone,type,} = data;
        let returntrip = data.return;
        let message = `Hi, I'd like to enquire about this ride.\nCustomer details:-\nContact Number: ${phone},\nType: ${type},\nFrom: ${from},\nDate: ${date},\nTime: ${time}`;

        if (to) {
            message += `,\nTo: ${to}`;
        }
        if (returntrip) {
            message += `,\nReturn: ${returntrip}`;
        }
        const apiUrl = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${message}`;
        window.open(apiUrl, '_blank');
      };

const rendersTab = () =>{
    switch(activeTab){
        case "oneway":
            return <div><OneWayTabComp onEnquirySubmit={handleEnquirySubmit} /></div>
        case "roundtrip":
            return <div><RoundTTabCom onEnquirySubmit={handleEnquirySubmit}  /></div>
        case "local":
            return <div><LocalTabCom onEnquirySubmit={handleEnquirySubmit} /></div>
        case "airport":
            return <div><AirportCabCom onEnquirySubmit={handleEnquirySubmit} /></div>
    }
}

const getTabClassName = (tabName) => {
    return `tab ${activeTab === tabName ? 'active' : 'inactive'}`;
};


return (
  <div className='w-hundred'>
      <div className='flex justify-center items-center py-2 '>
        <div className={getTabClassName('oneway')} onClick={()=>setActiveTab("oneway")}> one Way </div>
        <div className={getTabClassName('roundtrip')} onClick={()=>setActiveTab("roundtrip")}> Round Trip </div>
        <div className={getTabClassName('local')} onClick={()=>setActiveTab("local")}>  Local </div>
        <div className={getTabClassName('airport')} onClick={()=>setActiveTab("airport")}>  Airport </div>
    </div>
    <div className='py-4 px-2'>{rendersTab()}</div>
  </div>
  )
}

export default ExtCabsTab;