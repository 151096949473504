import React, { useEffect, useState } from "react";
import EventImg from "../../../Images/event-img.png";
import EventImgMobile from "../../../Images/event-img-mobile.png";
import "./AppointmentSection.css";
import { useNavigate } from "react-router-dom";

const EventSection = () => {
  const navigate = useNavigate(null);
  const [isMobile, setisMobile] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      if (window !== undefined) {
        if (window.innerWidth < 768) {
          setisMobile(true);
        } else {
          setisMobile(false);
        }
      }
    };

    // Attach the event listener for window resize
    window.addEventListener("resize", handleResize);

    // Initial check on component mount
    handleResize();

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []); // No dependencies in the array

  return (
    <>
      <div className="event-sec">
        <div className="container">
          <div className="row align-center">
            <div className="col-md-12 flex-col-center">
              <h2>Manage events with Businessbay.</h2>
              <h5>
                Ensure everyone and everything is accounted for on the big day
                with Businessbay.
              </h5>

              <img className="event-image-mobile" src={EventImgMobile} />

              <img style={{width:"100%"}}  className="event-image-desktop" src={EventImg} />

              <div
                style={{ cursor: "pointer" }}
                onClick={() => navigate("/register")}
                className="blue-button"
              >
                Register Now
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EventSection;
