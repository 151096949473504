import React from 'react'

const ExtAboutus = (props) => {
  return (
   <div>
     <div className="flex text-justify font-Tajawal text-[#101010] font-base text-lg minimarketabouttext">
    {props?.abouttext?.description}
  </div>
  {/* <div className=' bg-white py-4 fixed bottom-0 spacing w-[460px] text-center ContactSupplier border border-white'>
                  <button className='bg-coffee text-xl rounded text-white w-hundred font-bold font-Tajawal pt-3  p-2 data-drawer-target="drawer-bottom-example" data-drawer-show="drawer-bottom-example" data-drawer-placement="bottom" aria-controls="drawer-bottom-example"'
                  onClick={()=>setOpen(true)}
                  >
                    Contact supplier
                  </button>
        </div> */}
 
   </div>
  )
}

export default ExtAboutus