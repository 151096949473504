import React from "react";
function SubContactInfo(props) {
  let rs = props.rs;
  let ff = rs?.contactInfo?.content;
  if (ff?.length) {
    ff = JSON?.parse(ff);
  }
  if (ff?.length) {
    ff = JSON?.parse(ff);
  }

  // console.log(ff,"adfff")
  //return {rs?.contactInfo && ( )
  return (
    <>
      <article class="contact sec">
        <h3 class="sectitle">Contact Info</h3>
   
        <ul class="conlink">
          <li>
            <a href={ff?.contactInfoPhone ? `tel:${ff?.contactInfoPhone}` : ""}>
              <i class="fa-solid fa-phone"></i>
              <span>{ff?.contactInfoPhone}</span>
            </a>
          </li>

          <li>
            <a
              href={
                ff?.contactInfoEmail ? `mailto:${ff?.contactInfoEmail}` : ""
              }
            >
              <i class="fa-solid fa-envelope"></i>
              <span>{ff?.contactInfoEmail}</span>
            </a>
          </li>
          <li>
                    <a href="">
                      <i class="fa-solid fa-globe"></i>
                      <span>{ff?.web ||`www.metaspacechain.com`}</span>
                    </a>
                  </li>

          <li>
            <a  href={"" }>
            <i class="fa-solid fa-location-dot"></i>
              <span className="breakText"> 
              {ff?.contactInfoAddress?.split('\n').map((line, index) => (
              <React.Fragment key={index}>
                {line}
                <br />
              </React.Fragment>
            ))}
             </span>
            </a>
          </li>       
         
        </ul>
      </article>
    </>
  );
}
export default SubContactInfo;
