import React from 'react';
import "./newrequeststyle.css";
import DataTable from "react-data-table-component";
import SortIcon from "@material-ui/icons/ArrowDownward";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import { columns, data } from "./Datam";
import "./Datatable_styles.css";

export default function BasicDemo() {
    // let data=data;

  const tableData = {
    columns,
    data:data
  };

  // console.log("asdfasd",tableData)

  return (
    <div className="main">
      <DataTableExtensions {...tableData} filterPlaceholder="Search cardrequest">
        <DataTable
          columns={columns}
          data={data}
          noHeader
          defaultSortField="id"
          sortIcon={<SortIcon />}
          defaultSortAsc={true}
          pagination
          highlightOnHover
          dense
        />
      </DataTableExtensions>
    </div>
  );
}