import React from "react";
import { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./HeroSection.css";
import Lines from "../../../Images/lines.png";
import transLines from "../../../Images/texture.png";
// import phoneStill from '../../../Images/phone.png'
// import phoneStill from '../../../Images/phone2.png'
// import phoneStill from '../../../Images/phone3.png'
import phone from "../../../Images/phone.png";
import phone1 from "../../../Images/phone2.png";
import phone2 from "../../../Images/phone3.png";
import nfcCard from "../../../Images/nfc-card.png";
import Logo from "../../../Images/logo-white.png";
import { useNavigate } from "react-router-dom";

const HeroSection = () => {
  const navigate = useNavigate(null);
  const [currentImage, setCurrentImage] = useState(0);
  const images = [phone, phone1, phone2]; // Replace with your image file names

  // useEffect(() => {
  //   const initialDelay = setTimeout(() => {
  //     const intervalId = setInterval(() => {
  //       setCurrentImage((prevImage) => (prevImage + 1) % images.length);
  //     }, 2000);
  
  //     return () => clearInterval(intervalId);
  //   }, 1000);
  
  //   return () => clearTimeout(initialDelay);
  // }, [images.length]);

  useEffect(() => {
    const initialDelay = setTimeout(() => {
      const intervalId = setInterval(() => {
        setCurrentImage((prevImage) => (prevImage + 1) % images.length);
      }, 2000);
  
      return () => clearInterval(intervalId);
    }, 1000);
  
    return () => clearTimeout(initialDelay);
  }, [images.length]);

  return (
    <>
      <Container fluid className="heromain">
        <div className="top-lines">
          {" "}
          <img src={Lines} />{" "}
        </div>
        <div className="trans-lines">
          {" "}
          <img src={transLines} />{" "}
        </div>
        <div className="nfc-card">
          {" "}
          <img src={nfcCard} />
        </div>

        <Container className="hero-content">
          <div className="row align-center">
            <div className="header">
              <img
                style={{ cursor: "pointer", width:"156px" }}
                onClick={() => navigate("/")}
                src={Logo}
               
              />
              <div
                style={{ cursor: "pointer" }}
                onClick={() => navigate("/login")}
                className="blue-button"
              >
                Get Started
              </div>
            </div>
          </div>
          <div className="row align-center col-reverse">
            <div className="col-md-5">
              <h1>Empowering Connections Next-Gen Digital Cards</h1>
              <h2>
                Experience a 14-day FREE Trial that helps you connect with
                people easily and build important relationships.
              </h2>
              <div className="hero-buttons">
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => navigate("/register")}
                  className="blue-button"
                >
                  Start Free Trial
                </div>
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => navigate("/faqs")}
                  className="dark-button"
                >
                  How it Works
                </div>
              </div>
            </div>
            <div className="col-md-7 phones" style={{minHeight:"340px"}}>
              <img className="phone-still" src={images[currentImage]} />
              {/* <img className='phone-still' src={phoneStill}/> */}
            </div>
          </div>
          <div className="stats-main">
            <ul>
              <li>
                <h2 className="stats">30+</h2>
                <h5 className="stats-text">Countries Worldwide Shipping</h5>
              </li>
              <li>
                <h2 className="stats">50k</h2>
                <h5 className="stats-text">App Users</h5>
              </li>
              <li>
                <h2 className="stats">40k</h2>
                <h5 className="stats-text">Card Users</h5>
              </li>
              <li>
                <h2 className="stats">45k</h2>
                <h5 className="stats-text">Business Users</h5>
              </li>
            </ul>
          </div>
        </Container>
      </Container>
    </>
  );
};

export default HeroSection;
