import React from 'react'
import './RedirectLoader.css'
const RedirectLoader = () => {
    return (
        <div className="full-page-loader">
          <div className="loader-content">
            <div className="spinner"></div>
            <p>Redirecting, please wait...</p>
          </div>
        </div>
      );
}

export default RedirectLoader