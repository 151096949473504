
import NewGallerycreate from "../BusinessPages/NewGallerycreate";

import React from "react"

import { useState } from "react";
import AppointmentsMake from "./AppointmentsMake";
import Profile from "./Profile";
import BusinessHourMake from "./BusinessHourMake";
import SocialMediaLinkMake from "./SocialMediaLinkMake";
import TestimoniyalMake from "./TestimoniyalMake";
import ServicesMake from "./ServicesMake";
import GalleryMake from "./GalleryMake";
import ConatctInfo from "./ConatctInfo";



function MoreDetils() {
    const [divStates, setDivStates] = useState({
        div1: false,
        div2: false,
        div3: false,
        div4: false,
        div5: false,
        div6: false,
        div7: false,
        div8: false,
      });

        const toggleDiv = (divId) => {
            setDivStates({
              ...divStates,
              [divId]: !divStates[divId],
            });
          };
        

  return (
    <>
  
           <form className="row">

 

          
 

<div className="accordion" id="accordionExample">

<div className="accordion-item">
    <h2 className="accordion-header" id="profile">
      <button className="accordion-button collapsed bg-tab" type="button" data-bs-toggle="collapse" data-bs-target="#profilee" aria-expanded="true" aria-controls="collapseOne">
      <div className='d-flex'>
<h6> Profile</h6>
<div className="form-check form-switch">
<label className="form-check-label" for="flexSwitchCheckDefault">On/Off</label>
<input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" onChange={() => toggleDiv('div8')}/>
</div>
</div>
      </button>
    </h2>

    <div id="profilee" className="accordion-collapse collapse " aria-labelledby="profile" data-bs-parent="#accordionExample">
      <div className="accordion-body">
      {divStates.div8  && (
      <Profile/>
      )}
      </div>
    </div>
  </div>

  <div className="accordion-item">
    <h2 className="accordion-header" id="headingOne">
      <button className="accordion-button collapsed bg-tab" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
      <div className='d-flex'>
<h6>Contact Info</h6>
<div className="form-check form-switch">
<label className="form-check-label" for="flexSwitchCheckDefault">On/Off</label>
<input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" onChange={() => toggleDiv('div1')}/>
</div>
</div>
      </button>
    </h2>
    <div id="collapseOne" className="accordion-collapse collapse " aria-labelledby="headingOne" data-bs-parent="#accordionExample">
      <div className="accordion-body">

        
      {divStates.div1  && (
      <ConatctInfo/>
      )}
      </div>
    </div>
  </div>
  
  <div className="accordion-item">
    <h2 className="accordion-header" id="headingTwo">
      <button className="accordion-button collapsed bg-tab" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
      <div className='d-flex'>
<h6>Services</h6>
<div className="form-check form-switch">
<label className="form-check-label" for="flexSwitchCheckDefault">On/Off</label>
<input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" onChange={() => toggleDiv('div2')}/>
</div>
</div>
      </button>
    </h2>
    <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
      <div className="accordion-body">
      {divStates.div2 && (
        <div className='row'>



<ServicesMake/>





      
        </div>
      )}
      </div>
    </div>
  </div>
  <div className="accordion-item">
    <h2 className="accordion-header" id="headingThree">
      <button className="accordion-button collapsed bg-tab" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
      <div className='d-flex'>
<h6>Testimonials</h6>
<div className="form-check form-switch">
<label className="form-check-label" for="flexSwitchCheckDefault">On/Off</label>
<input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" onChange={() => toggleDiv('div3')}/>
</div>
</div>
      </button>
    </h2>
    <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
      <div className="accordion-body">
      {divStates.div3 && (
        <div className='row'>
       <TestimoniyalMake/>
        </div>
      )}
      </div>
    </div>
  </div>
  <div className="accordion-item">
    <h2 className="accordion-header" id="headingFour">
      <button className="accordion-button collapsed bg-tab" type="button" data-bs-toggle="collapse" data-bs-target="#four" aria-expanded="false" aria-controls="collapseThree">
      <div className='d-flex'>
<h6>Appointments</h6>
<div className="form-check form-switch">
<label className="form-check-label" for="flexSwitchCheckDefault">On/Off</label>
<input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" onChange={() => toggleDiv('div4')}/>
</div>
</div>
      </button>
    </h2>
    <div id="four" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
      <div className="accordion-body">
      {divStates.div4 && (
        <div className='row'>
       <AppointmentsMake/>
        </div>
      )}
      </div>
    </div>
  </div>
  <div className="accordion-item">
    <h2 className="accordion-header" id="headingFive">
      <button className="accordion-button collapsed bg-tab" type="button" data-bs-toggle="collapse" data-bs-target="#five" aria-expanded="false" aria-controls="collapseThree">
      <div className='d-flex'>
<h6>Social</h6>
<div className="form-check form-switch">
<label className="form-check-label" for="flexSwitchCheckDefault">On/Off</label>
<input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" onChange={() => toggleDiv('div5')}/>
</div>
</div>
      </button>
    </h2>
    <div id="five" className="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionExample">
      <div className="accordion-body">
      {divStates.div5 && (
        <div className='row'>
       <SocialMediaLinkMake/>
        </div>
      )}
      </div>
    </div>
  </div>
  <div className="accordion-item">
    <h2 className="accordion-header" id="headingsix">
      <button className="accordion-button collapsed bg-tab" type="button" data-bs-toggle="collapse" data-bs-target="#six" aria-expanded="false" aria-controls="collapseThree">
      <div className='d-flex'>
<h6>Business Hours</h6>
<div className="form-check form-switch">
<label className="form-check-label" for="flexSwitchCheckDefault">On/Off</label>
<input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" onChange={() => toggleDiv('div6')}/>
</div>
</div>
      </button>
    </h2>
    <div id="six" className="accordion-collapse collapse" aria-labelledby="headingsix" data-bs-parent="#accordionExample">
      <div className="accordion-body">
      {divStates.div6 && (
        <div className='row'>
       <BusinessHourMake/>
        </div>
      )}
      </div>
    </div>
  </div>

  <div className="accordion-item">
    <div className="accordion-header" id="headingseven">

      <div className="accordion-button collapsed bg-tab" type="button" data-bs-toggle="collapse" data-bs-target="#seven" aria-expanded="false" aria-controls="collapseThree">
      <div className='d-flex'>
<h6>Gallery</h6>
<div className="form-check form-switch">
<label className="form-check-label" for="flexSwitchCheckDefault">On/Off</label>
<input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" onChange={() => toggleDiv('div7')}/>
</div>

</div>
      </div>




    </div>
    <div id="seven" className="accordion-collapse collapse" aria-labelledby="headingseven" data-bs-parent="#accordionExample">
      <div className="accordion-body">
      {divStates.div7 && (
        <div className='row'>
       <GalleryMake/>
        </div>
      )}
      </div>
    </div>
  </div>


</div>



<div className='row justify-content-end'>
    <div className='col-12 col-lg-3'>
        <button type='Submit' className='btn btn-cu'>Save</button>
    </div>
</div>


          
              </form>

    </>
  )
}

export default MoreDetils