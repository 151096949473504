import React from "react";
import { useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Card.css";
import SliderViewImageForCard from "./SliderViewImageForCard";
import DefaultThumbnailImage from '../../../Images/thumbnail_image_Show.png'; 

function ThumbnailCard(props) {
  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 4,
    slidesToScroll: 4,
    responsive: [
      {
        breakpoint: 1260,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 550,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          dots: true,
          // infinite: false,
          arrows: false,
        },
      },
    ],
  };
  const handleTabClick = (tab) => {
    if (props.activeTab !== tab) {
      props.setPhysicalCardLoading(true);
      props.setActiveTab(tab);
      const activeCard = props.physicalCards.filter((val) => val?.id == tab);
      props.setActiveCardData(activeCard[0]);
    }
  };

  // console.log("props", props.physicalCards);

  useEffect(() => {
    if (props.physicalCards.length > 0) {
      handleTabClick(props.physicalCards[0]?.id);
    }
  }, [props.physicalCards]);

  return (
    <div>
      <Slider {...settings}>
        {props?.physicalCards.map((val) => {
          return(
            <>
            <div key={val.id}>
              <div
                className={`tab-item ${
                  props.activeTab === val?.id ? "activeThumbnailCard" : ""
                }`}
                onClick={() => handleTabClick(val?.id)}
              >
                <div className="cardthumbnail" style={{ cursor: "pointer" }}>
                  <SliderViewImageForCard fileName={val?.thumbnail_image} cardName={val?.card_name} defaultImage={DefaultThumbnailImage} />
                </div>
              </div>
            </div>
          </>
          )
        })}
      </Slider>
    </div>
  );
}

export default ThumbnailCard;
//812 a
