import React, { useState } from "react";
import styled from "styled-components";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import locationicon from '../../../../Images/target.png';
import whtsappicon from '../../../../Images/WhatsappIcon2.png'
import {
  ConfirmOrder,
  ConfirmOrderWrapper,
  CustomerDetailText,
  CustomerDetailsWrapper,
  DrawerCloseButton,
  DrawerCloseButtonWrapper,
  InputTextField,
  InputTextFieldWrapper,
  TabCustomerDetailsWrapper,
} from "./Theme14ExtStyleComponent";
import PhoneInput from "react-phone-input-2";
import { isValidPhoneNumber } from "../../../../utils/utility";

const Errormsg = styled.div`
    color: red;
    margin: 0 10px;
    font-size: 12px;
`;

const Overlay = styled.div`
    background-color: #000000bf;
    bottom: 0;
    height: 100vh;
    position: absolute;
    width: 100vw;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    @media(min-width: 520px){
    width: 520px;
}
`;


const CustomerDetails = ({ closeDrawer,onSubmit }) => {
  const [invalidPhoneErr, setInvalidPhoneErr] = useState("");
  // Define validation schema using Yup
  // const phoneRegExp = /^\d{14}$/;
  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    phone: Yup.string()
    // .matches(phoneRegExp, "Only 10 digit number is allowed")
    .min(10, "Minimum 10 digits are required")
    .required('Phone number is required'),
    // email: Yup.string().email('Invalid email').required('Email is required'),
    address: Yup.string().required('Address is required'),
    // city: Yup.string().required('City is required'),
    // state: Yup.string().required('State is required'),
  });

  // Initialize Formik with validateOnChange set to true
  const formik = useFormik({
    initialValues: {
      name: '',
      phone: '',
      // email: '',
      address: '',
      // city: '',
      // state: '',
    },
    validationSchema: validationSchema,
    validateOnChange: true, // Apply validation on each change
    onSubmit: (values) => {
      let cloneSubmittedValue = JSON.parse(JSON.stringify(values));
      let seperateCountryCodeFromPhone = cloneSubmittedValue?.phone?.split("-");
      if(seperateCountryCodeFromPhone.length>0){
        cloneSubmittedValue.phone = seperateCountryCodeFromPhone[1];
        cloneSubmittedValue.countryCode = seperateCountryCodeFromPhone[0];
        onSubmit(cloneSubmittedValue);
        closeDrawer();
      }
    },
  });

  const handleBlur = (value, phoneData) => {
    phoneData.iso2 = phoneData.countryCode;
    let contactNumber = formik.values.phone;
    if(!!contactNumber){
      let val = contactNumber?.split("-");
      let valArray = (val.length>0)?val[1]:"";
      if(valArray==''){
        setInvalidPhoneErr(`Please enter phone number.`);
      }else  if (!isValidPhoneNumber(valArray, phoneData)) {
        setInvalidPhoneErr(`Invalid phone number for ${phoneData.name}`);
      } else {
        setInvalidPhoneErr('');
      }
    }
  };

  return (
    <Overlay>
      <TabCustomerDetailsWrapper>
      <form onSubmit={formik.handleSubmit}>
        <CustomerDetailsWrapper>
          <CustomerDetailText>Customer Detail</CustomerDetailText>
        </CustomerDetailsWrapper>
        <InputTextFieldWrapper>
          <InputTextField
            type="text"
            placeholder="Name"
            name='name'
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.name}
          />
         <Errormsg> {formik.errors.name && formik.touched.name && (
            <div>{formik.errors.name}</div>
          )}</Errormsg>
        </InputTextFieldWrapper>
        <InputTextFieldWrapper>
        <PhoneInput
          country={"in"}
          enableSearch={true}
          value={formik.values.phone}
          onChange={(value, country) => {
            if (country.dialCode) {
              formik.setFieldValue(
                "phone",
                `+${country.dialCode}-${value.substring(
                  country.dialCode.length
                )}`
              );
              setInvalidPhoneErr('');
            } else {
              formik.setFieldValue(
                "phone",
                value
              );
              setInvalidPhoneErr('');
            }
          }}
          className="customer-info-phone"
          onBlur={(e, country) => handleBlur(e, country) && formik.handleBlur("phone")}
          isValid={(inputNumber, country) => isValidPhoneNumber(inputNumber, country)}
        />
        <p style={{ fontSize: "12px",paddingTop:"3px", paddingLeft: "5px" }}>
          Enter whatsapp no. only
        </p>
        <Errormsg>   {formik.errors.phone && formik.touched.phone && (
            <div>{formik.errors.phone}</div>
          )}</Errormsg>
        {invalidPhoneErr && <Errormsg>
                                    {invalidPhoneErr}
                                  </Errormsg>}
        </InputTextFieldWrapper>
        {/* <InputTextFieldWrapper>
          <InputTextField
            type="number"
            name='phone'
            placeholder='Phone'
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.phone}
          />
       <Errormsg>   {formik.errors.phone && formik.touched.phone && (
            <div>{formik.errors.phone}</div>
          )}</Errormsg>
        </InputTextFieldWrapper> */}
        {/* <InputTextFieldWrapper>
          <InputTextField
            type="email"
            name='email'
            placeholder='Email'
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.email}
          />
          <Errormsg>{formik.errors.email && formik.touched.email && (
            <div>{formik.errors.email}</div>
          )}</Errormsg>
        </InputTextFieldWrapper> */}
        <InputTextFieldWrapper>
          <InputTextField
            type='text'
            name='address'
            placeholder='Address'
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.address}
            // style={{ backgroundImage: `url(${locationicon})`, backgroundPosition: 'right 10px center', backgroundSize: '20px 20px', backgroundRepeat: 'no-repeat',paddingRight: '30px'}}
          />
          {/* <img src={locationicon} alt="Location Icon" /> */}
         <Errormsg> {formik.errors.address && formik.touched.address && (
            <div>{formik.errors.address}</div>
          )}</Errormsg>
        </InputTextFieldWrapper>
        {/* <InputTextFieldWrapper>
          <InputTextField
            type='text'
            name='city'
            placeholder='City'
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.city}
          />
          <Errormsg>{formik.errors.city && formik.touched.city && (
            <div>{formik.errors.city}</div>
          )}</Errormsg>
        </InputTextFieldWrapper>
        <InputTextFieldWrapper>
          <InputTextField
            name='state'
            placeholder='State'
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.state}
          />
         <Errormsg> {formik.errors.state && formik.touched.state && (
            <div>{formik.errors.state}</div>
          )}</Errormsg>
        </InputTextFieldWrapper> */}
        <ConfirmOrderWrapper>
          <button type='submit' 
          disabled={!formik.isValid || !!invalidPhoneErr}
              style={{background: "transparent", border: "none",fontWeight:"700",color:"#fff",fontSize:"24px", display:"flex", alignItems:"center", justifyContent:"center"}}>
            <img src={whtsappicon} alt="WhatsApp Icon" style={{ marginRight: "5px", width: "40px" }} />
              Place Order 
          </button>
        </ConfirmOrderWrapper>
        <DrawerCloseButtonWrapper onClick={closeDrawer}>
          <DrawerCloseButton>+</DrawerCloseButton>
        </DrawerCloseButtonWrapper>
      </form>
    </TabCustomerDetailsWrapper>
    </Overlay>
  );
};

export default CustomerDetails;
