import React, { useContext, useEffect, useState, useRef } from "react";
import PageName from "../Utils/PageName";
import { Link } from "react-router-dom";
import { environmentVariables } from "../../config/env.config";
import axios from "axios";
import { userContext } from "../../context/userContext";
import { ToastContainer, toast } from "react-toastify";
import ReactDOM from "react-dom";
import DataTable from "react-data-table-component";
import SortIcon from "@material-ui/icons/ArrowDownward";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import { businessContext } from "../../context/businessContext";
import CircularLoader from "../../Backend/Components/CircularLoader/CircularLoader";

function DisplayCardList_admin() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const baseUrl = environmentVariables?.apiUrl;
  const { userData, setUserData } = useContext(userContext);

  const [isCoupon, setIsCoupon] = useState(false);
  const [couponName, setCouponName] = useState("");
  const [isStatus, setIsStatus] = useState(false);
  const [couponCode, setCouponCode] = useState("");
  const [discountType, setDiscountType] = useState("");
  const [discountValue, setDiscountValue] = useState("");
  const [description, setDescription] = useState("");
  const [activate, setActivate] = useState(false);
  const [limit, setLimit] = useState("");
  const createCouponModalRef = useRef(null);

  const [isUpdate, setIsUpdate] = useState(false);
  const [couponId, setCouponId] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const fetchCards = async () => {
    try {
      axios
        .get(`${baseUrl}api/v1/phy_card/getAllCards`, {
          headers: {
            _token: userData?.token,
          },
        })
        .then((response) => {
          setData(response?.data?.result);
          setIsLoading(false)

        })
        .catch((error) => {
          console.log(error);
          setIsLoading(false)
        });
    } catch (err) { }
  };

  useEffect(() => {
    fetchCards();
  }, []);
 
  const handleToggleStatus = (businessId, currentStatus) => {
    const newStatus = currentStatus === "active" ? "inactive" : "active";
    // Make an API call to update the status
    axios
      .put(
        `${baseUrl}api/v1/business/updateStatus/${businessId}`,
        { status: newStatus },
        {
          headers: {
            _token: userData?.token,
          },
          // withCredentials: true
        }
      )
      .then((response) => {
        // console.log(
        //   response?.data?.message,
        //   "Business status updated successfully"
        // );
        toast.success(response?.data?.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        // Update the data in your state after a successful update
        const updatedBusinessListData = businesslistData.map((business) => {
          if (business.id === businessId) {
            return { ...business, status: newStatus };
          }
          return business;
        });
        setBusinesslistData(updatedBusinessListData);
        // window.location.reload();
      })

      .catch((error) => {
        toast.error(error?.response?.data?.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        console.error("Error updating business status:", error);
      });
  };
   
  
  const columns = [
    {
      name: "id",
      selector: (row) => row.id,
      sortable: true,
    },
    {
      name: "card_name",
      selector: (row) => row.card_name,
      sortable: true,
    },
    {
      name: "front_image",
      selector: (row) => row.front_image,
      sortable: true,
    },
    {
        name: "Action",
        sortable: false,
        cell: (d) => [
          <>
            <div className="form-check form-switch">
              <input
                className="form-check-input"
                type="checkbox"
                checked={d?.status == "active" ? true : false}
                id={`flexSwitchCheckDefault_${d.id}`}
                onClick={() => {
                  // console.log(d,"dddddddddd")
                  handleToggleStatus(d.id, d.status);
                }}
              />
            </div>
          </>,
        ],
      },
  ];



  const customStyles = {
    
    headRow: {
      style: {
        display: "flex",
        alignItems: "stretch",
        width: "100%",
        backgroundColor: "#E9E9E9",
        minHeight: "52px",
        borderRadius: "2px 2px 0px 0px",
        padding: "8px 25px",
        textTransform:"capitalize",
        border: "none",
       
      },
    },
    headCells: {
      style: {
        color: "#525252",
        fontSize: "16px",
        minHeight: "52px",
        fontSize:"15px",
      
      },
    },
    rows: {
      style: {
        padding: "20px 25px",
        fontSize: "14px",
        textTransform: "capitalize",
        minHeight: "48px ",
      },
    },
  };


  const tableData = {
    columns,
    data,
  };

  

  return (
    <>
      <PageName PageName="Display Card List" />

      <article>
        <div className="main">
            <Link to="/addPhysicalCard">+ Display Card List</Link>
          {isLoading ? (
            <CircularLoader size={40} />
          ) : (
            <DataTableExtensions
             {...tableData}
              filterPlaceholder="Search card"
              customStyles={customStyles}
              >
              <DataTable
                columns={columns}
                data={data}
                noHeader
                defaultSortField="id"
                sortIcon={<SortIcon />}
                defaultSortAsc={true}
                pagination
                highlightOnHover
                dense
                customStyles={customStyles}
              />
            </DataTableExtensions>
          )}
        </div>
        {/* {console.log(isCoupon,"isc333333")} */}
        <div
          className={`modal fade ${isCoupon ? "show" : ""}`}
          id="createCouponModal" // Match this with data-bs-target
          // data-bs-backdrop={isCoupon ? "static" : true}
          // data-bs-keyboard={isCoupon ? "false" : true}
          tabIndex="-1"
          ref={createCouponModalRef}
        >
          <div class="modal-dialog modal-xl">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title">
                  {" "}
                  {isUpdate && couponId != "" ? "Edit" : "Create New"} Coupon
                </h5>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={(e) => handleClearState(e)}
                ></button>
              </div>
              <div class="modal-body">
                <div className="d-flex justify-content-between mb-2">
                  <div>
                    Name
                    <input
                      type="text"
                      value={couponName}
                      className="form-control"
                      onChange={(e) => setCouponName(e.target.value)}
                    />
                  </div>

                  <div>
                    Code
                    <input
                      type="text"
                      className="form-control"
                      value={couponCode}
                      onChange={(e) => setCouponCode(e.target.value)}
                    />
                  </div>
                  <div>
                    Discount Type
                    <select
                      className="form-control"
                      onChange={(e) => setDiscountType(e.target.value)}
                      value={discountType}
                    >
                      <option value="">Select</option>
                      <option value="percentage">Percentage</option>
                      <option value="flat">Flat</option>
                    </select>
                  </div>
                  <div>
                    Discount value
                    <input
                      type="number"
                      className="form-control"
                      value={discountValue}
                      onChange={(e) => setDiscountValue(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div class="modal-body">
                <div className="d-flex justify-content-between mb-2">
                  <div>
                    Limit
                    <input
                      type="number"
                      className="form-control"
                      value={limit}
                      onChange={(e) => setLimit(e.target.value)}
                    />
                  </div>
                  <div>
                    Description
                    <input
                      type="text"
                      className="form-control"
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                    />
                  </div>

                  <div>
                    Activate
                    <div>
                      <label>
                        <input
                          type="radio"
                          checked={activate == true}
                          onChange={() => setActivate(true)}
                        />{" "}
                        Yes
                      </label>
                      <label>
                        <input
                          type="radio"
                          checked={activate == false}
                          onChange={() => setActivate(false)}
                        />{" "}
                        No
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-primary"
                  onClick={(e) => handleSubmit(e)}
                >
                  {isStatus ? (
                    <CircularLoader size={20} />
                  ) : isUpdate && couponId != "" ? (
                    "Edit"
                  ) : (
                    "Create"
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </article>
    </>
  );
}

export default DisplayCardList_admin;
