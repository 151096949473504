import React, { useState, useContext } from "react";
// import { useState } from 'react'
import { userContext } from "../../context/userContext";
import axios from "axios";
import { data } from "jquery";
import { environmentVariables } from "../../config/env.config";
// import { cp } from "fs";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CircularLoader from "../Components/CircularLoader/CircularLoader";
import Cookies from "js-cookie";
import "././Updatedcss/ProfileUpdateStyle.css";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { isValidPhoneNumber } from "../../utils/utility";

// import { baseUrl } from "./config";

function Profile() {
  const { userData, setUserData } = useContext(userContext);
  const navigate = useNavigate();
  // console.log(userData, "userData");
  const baseUrl = environmentVariables?.apiUrl;
  const [editedData, setEditedData] = useState({
    name: userData?.name || "",
    // lastname: userData?.lastname || '',
    email: userData?.email || "",
    contact: userData?.contact || "",
  });
  const [password, setPassword] = useState("");
  const [cpassword, setCpassword] = useState("");
  const [oPVisibility, setOPVisibility] = useState(false);
  const [pVisibility, setPVisibility] = useState(false);
  const [cpVisibility, setCPVisibility] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [activeTab, setActiveTab] = useState("tab1");
  const [invalidPhoneErr, setInvalidPhoneErr] = useState("");
  console.log(invalidPhoneErr,"invalidPhoneErr")
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };
  const [countryName, setCountryName] = useState("");

  // frontend validation
  const validateSchema = Yup.object().shape({
    oldPassword: Yup.string()
      .required("Please enter old password")
      .min(8, "Password must have at least 8 characters")
      .matches(/[0-9]/, `Your password must have at least 1 digit character`)
      .matches(
        /[a-z]/,
        `Your password must have at least 1 lowercase character`
      )
      .matches(
        /[A-Z]/,
        `Your password must have at least 1 uppercase character`
      ),
    password: Yup.string()
      .required("Please enter a new password")
      .min(8, "Password must have at least 8 characters")
      .matches(/[0-9]/, `Your password must have at least 1 digit character`)
      .matches(
        /[a-z]/,
        `Your password must have at least 1 lowercase character`
      )
      .matches(
        /[A-Z]/,
        `Your password must have at least 1 uppercase character`
      ),
    confirmPassword: Yup.string()
      .required("Please re-type your new password")
      .oneOf([Yup.ref("password")], "Passwords does not match"),
  });

  const formik = useFormik({
    initialValues: {
      oldPassword: "",
      password: "",
      confirmPassword: "",
    },
    validationSchema: validateSchema,
    onSubmit: (values, { resetForm }) => {
      setIsSubmit(true);
      handleChanePassword(values);
    },
  });

  const handleSave = async (e) => {
    e.preventDefault();
    try {
      const nameRegex = /^[A-Za-z\s]+$/;
      // setIsSubmit(true);
      // console.log(userData,"userDatauserDatauserData")
      if (editedData?.name == "" || editedData?.name?.trim() == "") {
        toast.error("Name is mandatory", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
        return;
      } else if (!nameRegex.test(editedData?.name)) {
        toast.error("Name can only contain alphabetic characters", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
        return;
      } else if (
        editedData?.name?.length > 40 ||
        editedData?.name?.trim()?.length > 40
      ) {
        toast.error("Name length maximum 40 character", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
        return;
      } else if (
        editedData?.name?.length < 3 ||
        editedData?.name?.trim()?.length < 3
      ) {
        toast.error("Name must be minimum 3 character", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
        return;
      }
      if (editedData?.contact == "" || editedData?.contact?.trim() == "") {
        toast.error("Contact number is mandatory", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
        return;
      } 
      if (invalidPhoneErr) {
        toast.error(invalidPhoneErr, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        setIsSubmit(false);
        return;
      }
      let config = {
        method: "put",
        url: `${baseUrl}api/v1/user/${
          userData == "super_admin" ? "updateUser" : "updateNormalUser"
        }`,
        headers: {
          _token: userData?.token,
          "Content-Type": "application/json",
        },
        // withCredentials: true,
        data: {
          userId: userData?.id,
          name: editedData?.name,
          email: editedData?.email,
          contact: editedData?.contact,
        },
      };

      axios
        .request(config)
        .then((response) => {
          setIsSubmit(false);
          // console.log("Update User info:j", response.data);
          // console.log(userData, "userDatssssssssssssssss");
          let obj = {
            ...userData,
          };
          obj.name = editedData?.name || obj.name;
          obj.email = editedData?.email || obj?.email;
          obj.contact = editedData?.contact || obj?.contact;
          setUserData(obj);
          localStorage.setItem("user", JSON.stringify(obj));

          // console.log(obj, "setUserDatasetUserDatasetUserData");
          toast.success("Your profile has been updated successfully.", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
        })
        .catch((error) => {
          setIsSubmit(false);
          console.error(
            "Update User API Error:",
            error?.response?.data?.message
          );
          toast.error(
            error?.response?.data?.message ||
              "Failed to update your profile. Please check your details and try again later.",
            {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 1000,
            }
          );
        });
    } catch (error) {
      setIsSubmit(false);
      console.error("API Error:", error);
      toast.error("An unexpected error occurred. Please try again later.", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
    }
  };

  const handleChanePassword = async (data) => {
    try {
      if (!!data) {
        let config = {
          method: "post",
          url: `${baseUrl}api/v1/user/changePassword`,
          headers: {
            _token: userData?.token,
            "Content-Type": "application/json",
          },
          data: {
            oldpassword: data.oldPassword,
            password: data.password,
            cpassword: data.confirmPassword,
          },
        };
        axios
          .request(config)
          .then(async (response) => {
            setIsSubmit(false);
            toast.success("Password updated successfully", {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 1000,
            });
            setTimeout(async () => {
              await logout();
              navigate("/");
            }, 1500);
          })
          .catch((error) => {
            setIsSubmit(false);
            if (
              error.response &&
              error.response.data &&
              error.response.data.message
            ) {
              toast.error(error.response.data.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 1000,
                id: "changePassError",
              });
            } else {
              toast.error(
                "Failed to update password. Please try again later.",
                {
                  position: toast.POSITION.TOP_RIGHT,
                  autoClose: 1000,
                }
              );
            }
          });
      } else {
        setIsSubmit(false);
        toast.error("Invalid data", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      }
    } catch (error) {
      setIsSubmit(false);
      toast.error("An unexpected error occurred. Please try again later.", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
    }
  };
  const logout = () => {
    const loginFormData = localStorage.getItem("login_form");
    localStorage.clear();
    if (loginFormData !== null) {
      localStorage.setItem("login_form", loginFormData);
    }
    setUserData(null);
    return true;
  };
  const toggleOPasswordVisibility = () => {
    setOPVisibility(!oPVisibility);
  };
  const togglePasswordVisibility = () => {
    setPVisibility(!pVisibility);
  };
  const toggleCPasswordVisibility = () => {
    setCPVisibility(!cpVisibility);
  };

  const handleBlur = (value, phoneData) => {
    phoneData.iso2 = phoneData.countryCode;
    let contactNumber = editedData.contact;
    if (!!contactNumber) {
      let val = contactNumber.split("-");
      let valArray = val.length > 0 ? val[1] : "";
      if(valArray==''){
        setInvalidPhoneErr(`Please enter phone number.`);
      }else if (!isValidPhoneNumber(valArray, phoneData)) {
        setInvalidPhoneErr(`Invalid phone number for ${phoneData.name}`);
      } else {
        setInvalidPhoneErr("");
      }
    }
  };

  

  return (
    <>
      <div className="row ProfileContainer">
        <div className="col-md-4 ProfileWrapper  ">
          <div className="tile">
            <ul className="conatctprofile ProfileflexContainer">
              <li
                className={`tab-item ${activeTab === "tab1" ? "active" : ""}`}
                onClick={() => handleTabClick("tab1")}
                id="PersonalInput"
              >
                Personal Info
              </li>
              <li
                className={`tab-item ${activeTab === "tab2" ? "active" : ""}`}
                onClick={() => handleTabClick("tab2")}
                id="PersonalInput"
              >
                Change Password
              </li>
            </ul>
          </div>
        </div>
        <div className="col-md-8 ProfileWrapper">
          <div className="tile">
            {activeTab === "tab1" && (
              <form>
                <div className="row mb-4">
                  <div className="col-md-6 ProfileNameWid">
                    {/* <label>Name</label> */}
                    <input
                      className="form-control"
                      placeholder="Name"
                      type="text"
                      value={editedData.name}
                      onChange={(e) =>
                        setEditedData({ ...editedData, name: e.target.value })
                      }
                    />
                  </div>
                  {/* <div className="col-md-6">
                    <label>Last Name</label>
                    <input className="form-control" type="text" value={editedData?.lastname || ''}
                    onChange={(e) => setEditedData({ ...editedData, name: e.target.value })}/>
                  </div> */}
                </div>
                <div className="row">
                  <div className="col-md-12 mb-4">
                    {/* <label>Email</label> */}
                    <input
                      placeholder="Email"
                      className="form-control"
                      type="text"
                      value={editedData?.email || ""}
                    />
                  </div>
                  <div className="clearfix"></div>
                  <div className="col-md-12 mb-4">
                    {/* <label>Mobile No</label> */}
                    {/* <input
                      className="form-control"
                      placeholder="Mobile No"
                      maxLength={16}
                      type="number"
                      value={editedData?.contact || ""}
                      onChange={(e) =>
                        setEditedData({
                          ...editedData,
                          contact: e.target.value,
                        })
                      }
                    /> */}
                    
                    {/* {invalidPhoneErr && <div id="error_msg"> {invalidPhoneErr} </div>} */}

                    <PhoneInput
        country={"in"}
        enableSearch={true}
        value={editedData.contact || ""}
        onChange={(value, country) => {
          if (country.dialCode) {
            setEditedData({
              ...editedData,
              contact: `+${country.dialCode}-${value.substring(
                country.dialCode.length
              )}`,
            });
            setInvalidPhoneErr("");
          } else {
            setEditedData({
              ...editedData,
              contact: value,
            });
            setInvalidPhoneErr("");
          }
        }}
        onBlur={(e, country) => handleBlur(e, country)}
        isValid={(inputNumber, country) =>
          isValidPhoneNumber(inputNumber, country)
        }
        className="DetailsInfoPhoneInput"
      />
      {invalidPhoneErr && (
        <div id="error_msg" className="error">
          {invalidPhoneErr}
        </div>
      )}
                  </div>
                  <div className="clearfix"></div>
                </div>
                <div className="row mb-10">
                  <div className="col-md-12 ProfileUpdatedButtonContainer">
                    <button
                      className="btn btn-primary ProfileUpdatedButton"
                      type="button"
                      onClick={(e) => handleSave(e)}
                    >
                      {/* <i className="bi bi-check-circle-fill me-2"></i>  */}
                      {/* {isSubmit ? <CircularLoader size={20} /> : "Update"} */}
                      Update
                    </button>
                  </div>
                </div>
                <ToastContainer />
              </form>
            )}
            {activeTab === "tab2" && (
              <>
                <form onSubmit={formik.handleSubmit}>
                  <div className="row mb-4 UpdatePasswordWrapper">
                    <div className="col-md-6 ProfileChangepassWid">
                      {/* <label>Password</label> */}
                      <input
                        id="oldPassword"
                        name="oldPassword"
                        className="form-control "
                        placeholder="Enter Old Password"
                        type={oPVisibility ? "text" : "password"}
                        onChange={formik.handleChange}
                        value={formik.values.oldPassword}
                      />
                      <span
                        onClick={toggleOPasswordVisibility}
                        style={{
                          position: "absolute",
                          right: "5%",
                          top: "15%",
                          transform: "translateY(-50%)",
                          cursor: "pointer",
                        }}
                      >
                        {formik.values.oldPassword.length >= 1 &&
                          (oPVisibility ? (
                            <i className="fas fa-eye Eeye"></i>
                          ) : (
                            <i className="fas fa-eye-slash Eeye"></i>
                          ))}
                      </span>
                      {formik.touched.oldPassword &&
                        formik.errors.oldPassword && (
                          <p className="text-danger">
                            {formik.errors.oldPassword}
                          </p>
                        )}
                    </div>
                    <div className="col-md-6 ProfileChangepassWid">
                      {/* <label>Password</label> */}
                      <input
                        id="password"
                        name="password"
                        className="form-control "
                        placeholder="Enter New Password"
                        type={pVisibility ? "text" : "password"}
                        onChange={formik.handleChange}
                        value={formik.values.password}
                      />
                      <span
                        onClick={togglePasswordVisibility}
                        style={{
                          position: "absolute",
                          right: "5%",
                          top: "38%",
                          transform: "translateY(-50%)",
                          cursor: "pointer",
                        }}
                      >
                        {/* {pVisibility ? (
                          <i className="fas fa-eye Eeye"></i>
                        ) : (
                          <i className="fas fa-eye-slash Eeye"></i>
                        )} */}
                        {formik.values.password.length >= 1 &&
                          (pVisibility ? (
                            <i className="fas fa-eye Eeye"></i>
                          ) : (
                            <i className="fas fa-eye-slash Eeye"></i>
                          ))}
                      </span>
                      {formik.touched.password && formik.errors.password && (
                        <p className="text-danger">{formik.errors.password}</p>
                      )}
                    </div>
                    <div className="col-md-6 ProfileChangepassWid">
                      {/* <label>Confirm Password</label> */}
                      <input
                        id="confirmPassword"
                        name="confirmPassword"
                        className="form-control"
                        placeholder="Confirm New Password"
                        type={cpVisibility ? "text" : "password"}
                        onChange={formik.handleChange}
                        value={formik.values.confirmPassword}
                      />
                      <span
                        onClick={toggleCPasswordVisibility}
                        style={{
                          position: "absolute",
                          right: "5%",
                          top: "63%",
                          transform: "translateY(-50%)",
                          cursor: "pointer",
                        }}
                      >
                        {/* {cpVisibility ? (
                          <i className="fas fa-eye Eeye"></i>
                        ) : (
                          <i className="fas fa-eye-slash Eeye"></i>
                        )} */}
                        {formik.values.confirmPassword.length >= 1 &&
                          (cpVisibility ? (
                            <i className="fas fa-eye Eeye"></i>
                          ) : (
                            <i className="fas fa-eye-slash Eeye"></i>
                          ))}
                      </span>
                      {formik.touched.confirmPassword &&
                        formik.errors.confirmPassword && (
                          <p className="text-danger">
                            {formik.errors.confirmPassword}
                          </p>
                        )}
                    </div>
                  </div>
                  <div className="row mb-10">
                    <div className="col-md-12 ProfileUpdatedButtonContainer">
                      <button
                        className="btn btn-primary ProfileUpdatedButton"
                        type="submit"
                      >
                        <i className="bi bi-check-circle-fill me-2"></i>
                        {isSubmit ? <CircularLoader size={20} /> : "Update"}
                      </button>
                    </div>
                  </div>
                  <ToastContainer />
                </form>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Profile;
