import React from 'react'

const CabAbout = (props) => {
  return (
    <div className='px-2'>
           <div className='capitalize font-Poppins text-xl text-white font-medium pb-2'>
           About
           </div>
        <div className='py-2 font-DM text-B8B8B8 text-lg'>
          {props?.abouttext?.description}
        </div>
    </div>
  )
}

export default CabAbout