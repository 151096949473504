import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { userContext } from "../../../context/userContext";
import { environmentVariables } from "../../../config/env.config";
import { useNavigate } from "react-router";
import LinkedInIcon from "./../../../Images/linkedIn3.png";
import PhoneIcons from "./../../../Images/resphone3.png";
import MailIcon from "./../../../Images/mail3.png";
import MapIcon from "./../../../Images/map3.png";
import LinkedInIcon768 from "./../../../Images/LinkedIn768.png";
import PhoneIcons768 from "./../../../Images/phone768.png";
import MailIcon768 from "./../../../Images/mail768.png";
import MapIcon768 from "./../../../Images/map768.png";
import { 
  ContainerJs,
  LeftSideBar,
  NameWrapper,
  NamedesignationWrapper,
  RightSideBar,
  Root,
  Name,
  DesignationWrapper,
  Designation,
  ContactContainerWrapperJs,
  ContactHeadingWrapper,
  ContactHeadingJs,
  PhoneMailmapLinWrapper,
  PhoneIconValueJs,
  WrapperJs,
  LineWrapper,
  LineJs,
  ContactContainerWrapperFirst,
  KeySkillsUl,
  KeySkillsliJs,
  CertificationWrapper,
  CertificationName,
  CertificationFrom,
  CertificationDate,
  ProfileLeftColorWrapper,
  ProfilecontainerWrapper,
  ProfileLeftColor,
  ProfileRightTextWrapper,
  ProfileHeadingWrapper,
  ProfileHeading,
  ProfileSummary,
  ProfilecontainerWrapperWithLine,
  RightLineWrapper,
  RightLine,
  WorkExperienceDateCompContainer,
  DesignationCompanyNameLocWrapper,
  CompanyDesignationNameJs,
  DesignationCompanyName,
  DesignationCompanyDate,
  DesignationCompanyDateWrapper,
  WorkExperienceDateCompContainerWithSummary,
  WorkExpSummaryWrapper,
  WrkSummary,
  ProjectLinkWrapper,
  ProjectLink,
  AchivementsWrapperUl,
  ActivementsListJs,
  PersonalInformationWrapperJs,
  PersonalInformationKey,
  PersonalInformationValue,
  PersonalInformationWrapperContainer,
  LeftSideBarJs,
  RightSideBarJs,
  CertificationNameJs,
  CertificationFromJs,
  CertificationDateJs,
  NamedesignationWrapperJs,
  WorkExperienceDateCompContainerJs,
  ProfileLeftColorWRKJS,
  ProfileLeftColorWrapperJs,
  DesignationCompanyNameJs,
  WrkSummaryJs,
 } from "./Resume3ExtStyle";


 export const PhoneIcon = styled.img`
width: 24px;
height:fit-content;
`;



const Resume3 = (props) => {
  const { userData } = useContext(userContext);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const baseUrl = environmentVariables?.apiUrl;
  const navigate = useNavigate();

  const formatDate = (dateString) => {
    if (!dateString) return ""; // Return empty string if dateString is falsy
  
    const date = new Date(dateString); // Parse the date string
    const options = { month: "long", year: "numeric" }; // Format options
    return date.toLocaleDateString("en-US", options); // Format date to "Month-YYYY" format
  };
 

  const updateScreenWidth = () => {
    setScreenWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", updateScreenWidth);
    return () => {
      window.removeEventListener("resize", updateScreenWidth);
    };
  }, []);


  const renderAchievement = () => {
    if (props?.onState?.achievement) {
      return (
        <ProfilecontainerWrapperWithLine>
          <ProfilecontainerWrapper>
            <ProfileLeftColorWrapperJs>
              <ProfileLeftColorWRKJS Achivements />
            </ProfileLeftColorWrapperJs>
            <ProfileRightTextWrapper>
              <ProfileHeadingWrapper>
                <ProfileHeading>Achievements</ProfileHeading>
              </ProfileHeadingWrapper>
              <WorkExperienceDateCompContainerWithSummary>
                <AchivementsWrapperUl>
                  {props.achievementArray.length > 0 ? (
                    props.achievementArray.map((achievement, index) => (
                      <ActivementsListJs key={index}>{achievement.skillname}</ActivementsListJs>
                    ))
                  ) : (
                    null
                  )}
                </AchivementsWrapperUl>
              </WorkExperienceDateCompContainerWithSummary>
            </ProfileRightTextWrapper>
          </ProfilecontainerWrapper>
          <RightLineWrapper>
            <RightLine />
          </RightLineWrapper>
        </ProfilecontainerWrapperWithLine>
      );
    } else {
      return null;
    }
  };

  const renderCertifications = () => {
    if (props?.onState?.certification) {
      return (
        <ContactContainerWrapperFirst>
          <div style={{display:"flex", }}>
          <ProfileLeftColorWrapperJs>
              <ProfileLeftColorWRKJS Certification />
            </ProfileLeftColorWrapperJs>
         <ContactContainerWrapperJs>
            <ContactHeadingWrapper>
              <ContactHeadingJs>Certifications</ContactHeadingJs>
            </ContactHeadingWrapper>
            <CertificationWrapper>
              {props.certificationArray.length > 0 ? (
                props.certificationArray.map((certification, index) => (
                  <div key={index} style={{margin:"10px 0 20px 0"}}>
                    <CertificationNameJs>{certification.certificatename}</CertificationNameJs>
                    <CertificationFromJs>{certification.organisationname}</CertificationFromJs>
                    <CertificationDateJs>{formatDate(certification.date)}</CertificationDateJs>
                  </div>
                ))
              ) : (
                null
              )}
            </CertificationWrapper>
          </ContactContainerWrapperJs>
          </div>
          <LineWrapper>
            <LineJs />
          </LineWrapper>
        </ContactContainerWrapperFirst>
      );
    } else {
      return null;
    }
  };

  const renderTools = () => {
    if (props?.onState?.tools) {
      return (
        <ContactContainerWrapperFirst>
          <div style={{display:"flex" }}>
          <ProfileLeftColorWrapperJs>
              <ProfileLeftColorWRKJS Tools />
            </ProfileLeftColorWrapperJs>
        
          <ContactContainerWrapperJs>
            <ContactHeadingWrapper>
              <ContactHeadingJs>Tools</ContactHeadingJs>
            </ContactHeadingWrapper>
            <KeySkillsUl>
              {props.toolsArray.length > 0 ? (
                props.toolsArray.map((tool, index) => (
                  <KeySkillsliJs key={index}>{tool.toolname}</KeySkillsliJs>
                ))
              ) : (
                null
              )}
            </KeySkillsUl>
          </ContactContainerWrapperJs>
          </div>
          <LineWrapper>
            <LineJs />
          </LineWrapper>
        </ContactContainerWrapperFirst>
      );
    } else {
      return null;
    }
  };
  
  const renderPrsnldetails = () => {
    if (props?.onState?.prsnldetail) {
      return (
        <ProfilecontainerWrapperWithLine>
          <ProfilecontainerWrapper>
            <ProfileLeftColorWrapperJs>
              <ProfileLeftColorWRKJS PersonalInformation />
            </ProfileLeftColorWrapperJs>
            <ProfileRightTextWrapper>
              <ProfileHeadingWrapper>
                <ProfileHeading>PERSONAL INFORMATION</ProfileHeading>
              </ProfileHeadingWrapper>
              <PersonalInformationWrapperContainer>
                <PersonalInformationWrapperJs>
                  <PersonalInformationKey>Father/ Spouse Name:</PersonalInformationKey>
                  <PersonalInformationValue>{props?.formikData?.values?.fathername}</PersonalInformationValue>
                </PersonalInformationWrapperJs>
  
                <PersonalInformationWrapperJs>
                  <PersonalInformationKey>Marital Status:</PersonalInformationKey>
                  <PersonalInformationValue>{props?.formikData?.values?.maritalstatus}</PersonalInformationValue>
                </PersonalInformationWrapperJs>
  
                <PersonalInformationWrapperJs>
                  <PersonalInformationKey>DOB:</PersonalInformationKey>
                  <PersonalInformationValue>{props?.formikData?.values?.userdob}</PersonalInformationValue>
                </PersonalInformationWrapperJs>
              </PersonalInformationWrapperContainer>
            </ProfileRightTextWrapper>
          </ProfilecontainerWrapper>
        </ProfilecontainerWrapperWithLine>
      );
    } else {
      return null;
    }
  };
  
  const renderWrkExp = () => {
    if (props?.onState?.workExp) {
      return (
        <ProfilecontainerWrapperWithLine>
          <ProfilecontainerWrapper>
            <ProfileLeftColorWrapperJs>
              <ProfileLeftColorWRKJS WorkExperience />
            </ProfileLeftColorWrapperJs>
            <ProfileRightTextWrapper>
              <ProfileHeadingWrapper>
                <ProfileHeading>Work experience</ProfileHeading>
              </ProfileHeadingWrapper>
              <WorkExperienceDateCompContainerWithSummary>
                {props.workExpArray.map((wrkExp, index) => (
                  <WorkExperienceDateCompContainerJs key={index}>
                    <DesignationCompanyNameLocWrapper>
                      <CompanyDesignationNameJs>
                        {wrkExp?.jobtitle}
                      </CompanyDesignationNameJs>
                      <DesignationCompanyNameJs>
                        {wrkExp?.companyname}, {wrkExp?.cityname}
                      </DesignationCompanyNameJs>
                    </DesignationCompanyNameLocWrapper>
                    <DesignationCompanyDateWrapper>
                      <DesignationCompanyDate>
                        {formatDate(wrkExp?.startdate)} -{" "}
                        {wrkExp?.enddate === "current" ? "Current" : formatDate(wrkExp?.enddate)}
                      </DesignationCompanyDate>
                    </DesignationCompanyDateWrapper>
                    <WorkExpSummaryWrapper>
                      <WrkSummaryJs>
                        {wrkExp?.workSummary}
                      </WrkSummaryJs>
                    </WorkExpSummaryWrapper>
                  </WorkExperienceDateCompContainerJs>
                ))}
              </WorkExperienceDateCompContainerWithSummary>
            </ProfileRightTextWrapper>
          </ProfilecontainerWrapper>
          <RightLineWrapper>
            <RightLine />
          </RightLineWrapper>
        </ProfilecontainerWrapperWithLine>
      );
    } else {
      return null;
    }
  };

  const renderProjects = () => {
    if (props?.onState?.project) {
      return (
        <ProfilecontainerWrapperWithLine>
          <ProfilecontainerWrapper>
            <ProfileLeftColorWrapperJs>
              <ProfileLeftColorWRKJS Project />
            </ProfileLeftColorWrapperJs>
            <ProfileRightTextWrapper>
              <ProfileHeadingWrapper>
                <ProfileHeading> PROJECT </ProfileHeading>
              </ProfileHeadingWrapper>
              {props.projectArray.map((project, index) => (
                <WorkExperienceDateCompContainerWithSummary key={index}>
                  <WorkExperienceDateCompContainer>
                    <DesignationCompanyNameLocWrapper>
                      <CompanyDesignationNameJs>{project.projectname}</CompanyDesignationNameJs>
                      <DesignationCompanyDate>
                        {formatDate(project.startDate)} - {project.currentlyWorking ? "Current" : formatDate(project.endDate)}
                      </DesignationCompanyDate>
                    </DesignationCompanyNameLocWrapper>
                  </WorkExperienceDateCompContainer>
                  <WorkExpSummaryWrapper>
                    <WrkSummary>{project.projectdescription}</WrkSummary>
                  </WorkExpSummaryWrapper>
                  <ProjectLinkWrapper>
                    <ProjectLink href={project.projectlink} target="_blank">{project.projectlink}</ProjectLink>
                  </ProjectLinkWrapper>
                </WorkExperienceDateCompContainerWithSummary>
              ))}
            </ProfileRightTextWrapper>
          </ProfilecontainerWrapper>
          <RightLineWrapper>
            <RightLine />
          </RightLineWrapper>
        </ProfilecontainerWrapperWithLine>
      );
    } else {
      return null;
    }
  };

  const renderSkills = () => {
    if (props?.onState?.services) {
      return (
        <ContactContainerWrapperFirst>
          <div style={{display:"flex", }}>
          <ProfileLeftColorWrapperJs>
              <ProfileLeftColorWRKJS Skills />
            </ProfileLeftColorWrapperJs>
          <ContactContainerWrapperJs>
            <ContactHeadingWrapper>
              <ContactHeadingJs> Key Skill</ContactHeadingJs>
            </ContactHeadingWrapper>
            <KeySkillsUl>
              {props.skillArray.map((skill, index) => (
                <KeySkillsliJs key={index}>{skill.skillname}</KeySkillsliJs>
              ))}
            </KeySkillsUl>
          </ContactContainerWrapperJs>
          </div>
          <LineWrapper>
            <LineJs />
          </LineWrapper>
        </ContactContainerWrapperFirst>
      );
    } else {
      return null;
    }
  };
  
  const renderEducation = () => {
    if (props?.onState?.isExperience) {
      return (
        <ProfilecontainerWrapperWithLine>
          <ProfilecontainerWrapper>
            <ProfileLeftColorWrapperJs>
              <ProfileLeftColorWRKJS Education />
            </ProfileLeftColorWrapperJs>
            <ProfileRightTextWrapper>
              <ProfileHeadingWrapper>
                <ProfileHeading> education </ProfileHeading>
              </ProfileHeadingWrapper>
              {props.socialMediaData.map((education, index) => (
                <WorkExperienceDateCompContainerWithSummary key={index}>
                  <WorkExperienceDateCompContainer>
                    <DesignationCompanyNameLocWrapper>
                      <CompanyDesignationNameJs>{education.degree}</CompanyDesignationNameJs>
                      <DesignationCompanyName>{education.schuniname}</DesignationCompanyName>
                    </DesignationCompanyNameLocWrapper>
                    <DesignationCompanyDateWrapper>
                      <DesignationCompanyDate>
                        {formatDate(education.graduationyear)} - {formatDate(education.graduationendyear)}
                      </DesignationCompanyDate>
                    </DesignationCompanyDateWrapper>
                  </WorkExperienceDateCompContainer>
                </WorkExperienceDateCompContainerWithSummary>
              ))}
            </ProfileRightTextWrapper>
          </ProfilecontainerWrapper>
          <RightLineWrapper>
            <RightLine />
          </RightLineWrapper>
        </ProfilecontainerWrapperWithLine>
      );
    } else {
      return null;
    }
  };

  const renderLanguage = () => {
    if (props?.onState?.language) {
      return (
        <ContactContainerWrapperFirst>
          <div style={{display:"flex", }}>
          <ProfileLeftColorWrapperJs>
              <ProfileLeftColorWRKJS Language />
            </ProfileLeftColorWrapperJs>
         <ContactContainerWrapperJs>
            <ContactHeadingWrapper>
              <ContactHeadingJs> Languages</ContactHeadingJs>
            </ContactHeadingWrapper>
            <KeySkillsUl>
              {props.languageArray.map((language, index) => (
                <KeySkillsliJs key={index}>{language.languagename}</KeySkillsliJs>
              ))}
            </KeySkillsUl>
          </ContactContainerWrapperJs>
         </div>
          <LineWrapper>
            <LineJs />
          </LineWrapper>
        </ContactContainerWrapperFirst>
      );
    } else {
      return null;
    }
  };
  
  const renderAdditionalDetail = () => {
    if (props?.onState?.adddetails) {
      return (
        <ContactContainerWrapperFirst>
       <div style={{display:"flex", }}>
          <ProfileLeftColorWrapperJs>
              <ProfileLeftColorWRKJS Hobbies />
            </ProfileLeftColorWrapperJs>
        <ContactContainerWrapperJs>
            <ContactHeadingWrapper>
              <ContactHeadingJs> Hobbies</ContactHeadingJs>
            </ContactHeadingWrapper>
            <KeySkillsUl>
              {props.additionaldetailArray.length > 0 ? (
                props.additionaldetailArray.map((detail, index) => (
                  <KeySkillsliJs key={index}>{detail.skillname}</KeySkillsliJs>
                ))
              ) : (
                null
              )}
            </KeySkillsUl>
          </ContactContainerWrapperJs>
        </div>
        <LineWrapper>
            <LineJs />
          </LineWrapper>
        </ContactContainerWrapperFirst>
      );
    } else {
      return null;
    }
  };

  return (
    <Root>
      <ContainerJs>
        <WrapperJs>
          <LeftSideBarJs>
            <NamedesignationWrapperJs>
              <NameWrapper>
                <Name> {props?.formikData?.values?.fullName || "Gagan"} </Name>
              </NameWrapper>
              <DesignationWrapper>
                <Designation>  {props?.designation || "Node js"}</Designation>
              </DesignationWrapper>
            </NamedesignationWrapperJs>
            <ContactContainerWrapperFirst>
              <ContactContainerWrapperJs>
                <ContactHeadingWrapper>
                  <ContactHeadingJs>contact info</ContactHeadingJs>
                </ContactHeadingWrapper>
                <PhoneMailmapLinWrapper>
                  <PhoneIcon src={!screenWidth <= 768 ? PhoneIcons768 : PhoneIcons} 
                   />
                  <PhoneIconValueJs>{props?.formikData?.values?.phone}</PhoneIconValueJs>
                </PhoneMailmapLinWrapper>

                <PhoneMailmapLinWrapper>
                  <PhoneIcon  src={!screenWidth <= 768 ? MailIcon768 : MailIcon}  />
                  <PhoneIconValueJs>{props?.formikData?.values?.email}</PhoneIconValueJs>
                </PhoneMailmapLinWrapper>
                <PhoneMailmapLinWrapper>
                  <PhoneIcon  src={!screenWidth <= 768 ? MapIcon768 : MapIcon}  />
                  <PhoneIconValueJs>
                  {props?.formikData?.values?.address}
                  </PhoneIconValueJs>
                </PhoneMailmapLinWrapper>
                <PhoneMailmapLinWrapper>
                  <PhoneIcon  src={!screenWidth <= 768 ? LinkedInIcon768 : LinkedInIcon}  />
                  <PhoneIconValueJs>
                  {props?.formikData?.values?.linkedIn} 
                  </PhoneIconValueJs>
                </PhoneMailmapLinWrapper>
              </ContactContainerWrapperJs>
              <LineWrapper>
                <LineJs />
              </LineWrapper>
            </ContactContainerWrapperFirst>

            {/* {props?.onState?.services && renderSkills()}
             {props?.onState?.tools && renderTools()}
            {props?.onState?.certification && renderCertifications()}
            {props?.onState?.language && renderLanguage()}
            {props?.onState?.adddetails && renderAdditionalDetail()} */}

          </LeftSideBarJs>
          <RightSideBarJs>
            <ProfilecontainerWrapperWithLine>
              <ProfilecontainerWrapper>
                <ProfileLeftColorWrapperJs>
                  <ProfileLeftColorWRKJS />
                </ProfileLeftColorWrapperJs>
                <ProfileRightTextWrapper>
                  <ProfileHeadingWrapper>
                    <ProfileHeading>Profile</ProfileHeading>
                  </ProfileHeadingWrapper>
                  <ProfileHeadingWrapper>
                    <ProfileSummary>
                    {props?.formikData?.values?.description}
                    </ProfileSummary>
                  </ProfileHeadingWrapper>
                </ProfileRightTextWrapper>
              </ProfilecontainerWrapper>
              <RightLineWrapper>
                <RightLine />
              </RightLineWrapper>
            </ProfilecontainerWrapperWithLine>
{props?.onState?.workExp && renderWrkExp()}
{props?.onState?.project && renderProjects()}
{props?.onState?.isExperience && renderEducation()}
{props?.onState?.achievement && renderAchievement()}
{props?.onState?.services && renderSkills()}
{props?.onState?.tools && renderTools()}
{props?.onState?.certification && renderCertifications()}
{props?.onState?.language && renderLanguage()}
{props?.onState?.adddetails && renderAdditionalDetail()}
{props?.onState?.prsnldetail && renderPrsnldetails()}
          </RightSideBarJs>
        </WrapperJs>
      </ContainerJs>
    </Root>
  );
};

export default Resume3;
