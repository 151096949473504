<style>
@import url('https://fonts.googleapis.com/css2?family=Balthazar&family=Be+Vietnam+Pro:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Josefin+Sans:ital,wght@0,100..700;1,100..700&family=Jost:ital,wght@0,100..900;1,100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap')
</style>




import styled from "styled-components";
import BackgroundWebImage from "./../../../Images/ResumewebHeader.png";
// const BackgroundWebImage =''



export const Root = styled.div`
  width: 430px;
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: center;
  text-align: center;
`;

export const HeaderWrapper = styled.div`
  background-image: url(${BackgroundWebImage});
  background-position: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  align-items: center;
  padding: 25px 0;
  width: 100%;
  background-size: contain;
  aspect-ratio: 2 / 1;
`;
export const Container = styled.div`
  width: 430px;

  @media (max-width: 1440px) {
    // width: 100%;
    padding: 0 0;
  }
  @media (max-width: 768px) {
    // width: 100%;
    padding: 0 20px;
  }
`;

export const Triangle = styled.div`
  width: 0;
  height: 0;
  border-left: 32px solid transparent;
  border-right: 50px solid transparent;
  // border-bottom: 57px solid #eff3f8;
  // transform: rotate(50deg);
  // position: absolute;
  // bottom: 5px;
  // right: -50px;
  border-bottom: 35px solid #FFF;
  transform: rotate(50deg);
  position: absolute;
  bottom: 5px;
  right: -45px;

  @media (max-width: 768px) {
    border-bottom: 35px solid #FFF;
    transform: rotate(50deg);
    position: absolute;
    bottom: 5px;
    right: -45px;
  }
`;

export const ProfileContainer = styled.div``;
export const ProfileWrapper = styled.div``;
export const ProfileLogo = styled.img`
width: 90px;
height: 90px;
`;
export const NameWrapper = styled.div``;
export const Name = styled.div`
  font-weight: 400;
  // font-size: 48px;
  text-transform: uppercase;
  font-size: 32px;
  font-family: "Jost", sans-serif;
  font-style: normal;
  color: #fff;

  @media (max-width: 768px) {
    font-size: 32px;
  }
`;
export const DesignationWrapper = styled.div``;
export const Designation = styled.div`
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 1px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  color: #e1e1e1;
  text-transform: uppercase;
  @media (max-width: 768px) {
    font-size: 14px;
    letter-spacing: 1px;
  }
`;

export const ContactContainer = styled.div`
  display: flex;
  text-align: center;
  // align-items: center;
  // justify-content: space-between;
  // padding: 20px 10px;
  flex-direction: column;
  box-shadow: 0 2px 6px 0 rgba(62, 87, 87, 0.21);
  margin: 10px 15px 25px 15px;
  padding: 20px 10px;
  justify-content: flex-start;
  align-items: start;

  @media (max-width: 1024px) {
    flex-direction: column;
    box-shadow: 0 2px 6px 0 rgba(62, 87, 87, 0.21);
    margin: 10px 15px 25px 15px;
    padding: 20px 10px;
    justify-content: flex-start;
    align-items: start;
  }
`;
export const ContactWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 10px 0px;

  ${(props) =>
    props.MailTemplate &&
    `
  @media (max-width: 1024px) {
    padding: 15px 0px;
  }
`}
`;
export const ContactIcon = styled.div`
  padding: 0 8px;
`;
export const Icon = styled.img``;
export const PhoneNumberWrapper = styled.div``;
export const Phone = styled.div`
  color: #2f2f2f;
  font-size: 16px;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  text-align: start;
  display: flex;
  word-break: break-all;
`;

export const ProjectsummaryPoints = styled.div`
color: #616161;
font-family: "Poppins", sans-serif;
font-size: 14px;
text-align: start;
padding-left: 20px;
padding: 5px 10px 10px 20px;
`;

export const Horizontalline = styled.div`
background: #CECECE;
width: 100%;
height: 1px;
opacity: 0.5;
margin: 15px 0 10px 0;

`;


export const ContactInfoAppContainer = styled.div`
width: 100%;
display: flex;
justify-content: center;
align-items: center;
text-align: center;
position: relative;
margin: 0 0 30px 0;
// display: none;
@media(max-width: 1024px){
  // display: flex;
}
`;
export const ContactInfoApp = styled.div`
color: #10657E;
font-size: 16px;
font-family: "Josefin Sans", sans-serif;
font-optical-sizing: auto;
font-weight: 400;
font-style: normal;
`;

export const ContactLine = styled.div`
width: 70px;
height: 3px;
background-color: #10657E;
bottom: -8px;
position: absolute;
`;

export const Card = styled.div`
  box-shadow: 0 2px 6px 0 rgba(62, 87, 87, 0.21);
  margin: 10px 15px 25px 15px;
  padding: 10px 20px;
`;
export const CardHeadingWrapper = styled.div`
  background-color: #10657e;
  color: #ffff;
  font-size: 24px;
  font-weight: 500;
  text-align: start;
  padding: 5px 40px 5px 20px;
  position: relative;
  // display: inline-block;
  display: table;
`;
export const CardHeading = styled.div`
  text-transform: uppercase;
  font-family: "Josefin Sans", sans-serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
  font-size: 16px;

  @media (max-width: 768px) {
    font-size: 16px;
  }
`;
export const ContantWrapper = styled.div`
  padding: 10px 0;
`;
export const Contant = styled.div`
  color: #2f2f2f;
  // font-size: 20px;
  font-size: 15px;
  text-align: start;
  padding: 3px 20px;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  letter-spacing: 0.2px;
  word-wrap: break-word;

  @media (max-width: 768px) {
    font-size: 15px;
  }
`;

export const ProjectHeadingWrapper = styled.div``;
export const ProjectCompanyWrapper = styled.div``;
export const ProjectWrapper = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: start;
  padding: 10px 20px 0px 20px;
  font-size: 15px;


  @media (max-width: 768px) {
    font-size: 15px;
  }
`;
export const ProjectWrapper2 = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: start;
  padding: 15px 20px 0px 20px;
  font-size: 15px;
  @media (max-width: 768px) {
    font-size: 15px;
  }
`;

export const ProjectName = styled.div`
  color: #2f2f2f;
  // font-size: 24px;
  font-family: "Poppins", sans-serif;
  font-weight: bold;
  padding: 0px;
  color:#2F2F2F;
  font-size: 14px;
  @media (max-width: 768px) {
    padding: 0px;
    font-size: 14px;
  }
`;
export const ProjectDate = styled.div`
  font-weight: 400;
  // font-size: 20px;
  // padding: 0 10px;
  padding: 0px;
  font-size: 14px;
  font-family: "Be Vietnam Pro", sans-serif;
  font-weight: 400;
  font-style: italic;

  @media (max-width: 768px) {
    padding: 0px;
    font-size: 14px;
  }
`;
export const CompanyWrapper = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: start;
  padding: 0px 15px;
`;
export const CompanyName = styled.div`
  font-weight: 400;
  // font-size: 20px;
  // padding: 0 10px;
  padding: 0 5px;
  font-size: 14px;
  font-family: "Be Vietnam Pro", sans-serif;
  font-weight: 400;
  font-style: normal;

  @media (max-width: 768px) {
    padding: 0 5px;
    font-size: 14px;
  }
`;
export const ProjectPointsWrapper = styled.ul`
  text-align: start;
  padding: 15px 20px;
`;
export const ProjectPoints = styled.li`
  color: #616161;
  font-weight: 400;
  // font-size: 20px;
  // padding: 8px 0;
  padding: 5px 0;
  font-size: 14px;
  font-family: "Be Vietnam Pro", sans-serif;
  font-weight: 400;
  font-style: normal;

  &::before {
    content: "•";
    margin-right: 10px;
  }

  @media (max-width: 768px) {
    padding: 5px 0;
    font-size: 14px;

    &::before {
      content: "•";
      margin-right: 10px;
    }
  }
`;

export const ProjectSummeryWrapper = styled.div`
  // font-size: 20px;
  font-size: 14px;
  font-weight: 400;
  text-align: start;
  padding: 10px 20px 0px 20px;

  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

export const ProjectSummeryDateWrapper = styled.div`
  // font-size: 20px;
  font-size: 14px;
  font-weight: 400;
  text-align: start;
  // padding: 15px 20px 10px 20px;
  padding: 0px 0 0 20px;

  @media (max-width: 768px) {
    font-size: 14px;
  }
`;
export const ProjectSummery = styled.div`
color: #616161;
font-weight: 400;
font-size: 14px;
font-family: "Poppins", sans-serif !important;
word-break: break-all;
`;

export const ProjectSummeryDate = styled.div`
color: #2F2F2F;
font-weight: 400;
font-size: 14px;
font-family: "Poppins", sans-serif !important;
font-style: italic;

${(p)=>p.WorkExperience && `
padding-bottom: 10px;
`}
`;
export const ProjectLinkWrapper = styled.div`
  color: #2f2f2f;
  font-size: 20px;
  font-weight: 400;
  text-align: start;
  padding: 3px 20px;
`;
export const ProjectLink = styled.a`
  color: #407bff;
  font-weight: 400;
  font-size: 16px;
  font-family: "Poppins", sans-serif !important;
  font-style: italic;
  text-decoration: underline;
  word-break: break-all;
`;

export const EducationPlace = styled.div`
  font-family: "Be Vietnam Pro", sans-serif;
  font-style: normal;
  // font-weight: 400;
  // font-size: 24px;
  font-size: 15px;
  font-weight: 500;
  color: #2c2626;

  @media (max-width: 768px) {
    font-size: 15px;
    font-weight: 500;
  }
`;
export const EducationWrapper = styled.div`
  display: flex;
  align-items: center;
  text-align: start;
  justify-content: start;
  // padding: 10px 0px 10px 0px;
  padding: 10px 0px 2px 0px;
  & > ${EducationPlace} {
    margin-left: 10px;
    padding-left: 10px;
    position: relative;

    &::before {
      content: "•";
      position: absolute;
      left: -10px;
    }
  }
  @media (max-width: 768px) {
    padding: 10px 0px 2px 0px;
  }
`;

export const EducationDate = styled.div`
font-weight: 400;
  // font-size: 20px;
  // padding: 0 7px;
  font-size: 15px;
  padding: 0 4px;
  font-family: "Poppins", sans-serif;
  font-style: italic;

  @media (max-width: 768px) {
    font-size: 15px;
    padding: 0 4px;
    font-family: "Poppins", sans-serif;
    font-style: italic;
  }
`;

export const EducationHeadingWrapper = styled.ul`
  display: grid;
  // grid-template-columns: 1fr 1fr;
  grid-template-columns: 1fr;
  margin: 20px 0;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

export const EducationCollegeWrapper = styled.li`
padding-bottom: 10px;
  @media (max-width: 768px) {
    padding-bottom: 10px;
  }
`;

export const Certificationname = styled.div`
  // font-size: 24px;
  text-align: start;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-size: 16px;

  @media (max-width: 768px) {
    font-weight: 500;
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-size: 16px;
  }

  ${(p)=>p.CertificateName && `
  color:#2F2F2F;
  font-weight: 500;
  `}
`;
export const Certificationdate = styled.div`
  // font-size: 22px;
  font-size: 16px;
  color: #545252;
  font-weight: 500;
  text-align: start;
  padding: 5px 0;
  font-family: "Be Vietnam Pro", sans-serif;
  font-style: italic;

  @media (max-width: 768px) { 
    font-size: 16px;
  }
`;
export const CertificationContainer = styled.div`
  padding: 20px 20px 10px 20px;
`;


export const PersonalDetailName = styled.div``;
export const PersonalName = styled.div`
font-family: "Poppins", sans-serif;
font-weight:bold;
font-size:14px;
color: #2F2F2F;
`;
export const PersonalContactWrapper = styled.div`
display: flex;
justify-content: space-between;
padding: 5px 20px;
`;