import React, { useState, useContext } from "react";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import { userContext } from "../../../context/userContext";
import { environmentVariables } from "../../../config/env.config";
import { useEffect } from "react";
import PageName from "../../Utils/PageName";

const ThemeSelect = () => {
  const { userData, setUserData } = useContext(userContext);
  const [imageView, setImageView] = useState(null);
  const [selectedTheme1, setSelectedTheme1] = useState("");
  const [selectedTheme2, setSelectedTheme2] = useState("");
  const [selectedTheme3, setSelectedTheme3] = useState("");
  const [selectedTheme4, setSelectedTheme4] = useState("");
  const [imageView1, setImageView1] = useState(null);
  const [imageView2, setImageView2] = useState(null);
  const [imageView3, setImageView3] = useState(null);
  const [imageView4, setImageView4] = useState(null);
  const [themeColor1, setThemeColor1] = useState("");
  const [themeColor2, setThemeColor2] = useState("");
  const [themeColor3, setThemeColor3] = useState("");
  const [themeColor4, setThemeColor4] = useState("");

  const [fontFile, setFontFile] = useState(null);
  const [cssFile, setCssFile] = useState(null);
  const [cssContent, setCssContent] = useState(null);

  const [isSubmit, setIsSubmit] = useState(false);
  const [selectedStyle, setSelectedStyle] = useState(null);
  const handleImageChange = (e) => {
    if (e.target.name == "theme1") {
      const file = e.target.files[0];
      setSelectedTheme1(e.target.files[0]);
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          setImageView(e.target.result);
          setImageView1(e.target.result);
        };
        reader.readAsDataURL(file);
      }
    }
    if (e.target.name == "theme2") {
      setSelectedTheme2(e.target.files[0]);
      const file = e.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          setImageView2(e.target.result);
          setImageView(e.target.result);
        };

        reader.readAsDataURL(file);
      }
    }
    if (e.target.name == "theme3") {
      setSelectedTheme3(e.target.files[0]);
      const file = e.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          setImageView3(e.target.result);
          setImageView(e.target.result);
        };

        reader.readAsDataURL(file);
      }
    }
    if (e.target.name == "theme4") {
      setSelectedTheme4(e.target.files[0]);
      const file = e.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          setImageView4(e.target.result);
          setImageView(e.target.result);
        };

        reader.readAsDataURL(file);
      }
    }
  };
  const handleChangePreviwPic = (e) => {
    try {
      let name = e.target.name;
      // console.log(name, "eeename ");
      if (name == "imageView3") {
        setImageView(imageView3);
      }
      if (name == "imageView4") {
        setImageView(imageView4);
      }
      if (name == "imageView2") {
        setImageView(imageView2);
      }
      if (name == "imageView1") {
        setImageView(imageView1);
      }
    } catch (err) {
      console.log(err, "Error in pic");
    }
  };

  const handleBannerChange = (e) => {
    const file = e.target.files[0];
    setBannerData(e.target.files[0]);
    // Use the FileReader API to display the selected image
    const reader = new FileReader();
    reader.onload = () => {
      setBannerImageUrl(reader.result);
    };
    reader.readAsDataURL(file);
  };

  function clearData() {
    try {
      setImageView(null);
      setSelectedTheme1("");
      setSelectedTheme2("");
      setSelectedTheme3("");
      setSelectedTheme4("");
      setImageView1(null);
      setImageView2(null);
      setImageView3(null);
      setImageView4(null);
      setThemeColor1("");
      setThemeColor2("");
      setThemeColor3("");
      setThemeColor4("");
    } catch (er) {
      console.log(er, "error ");
    }
  }
  const handleChangeStyleContent = (e) => {
    setCssContent(e.target.value);
    setSelectedStyle("text");
    if (e.target.value == "") {
      setSelectedStyle(null);
    }
  };
  const handleSubmit = async (e) => {
    try {
      const baseUrl = environmentVariables?.apiUrl;
      // console.log(cssFile,"cssFile  cssContent",        cssContent)
      e.preventDefault();
      if (selectedTheme1 == "") {
        toast.error("Theme 1 is mandatory", {
          position: toast.POSITION.TOP_RIGHT,
        });
        return;
      } else if (selectedTheme2 == "") {
        toast.error("Theme 2 is mandatory", {
          position: toast.POSITION.TOP_RIGHT,
        });
        return;
      } else if (selectedTheme3 == "") {
        toast.error("Theme 3 is mandatory", {
          position: toast.POSITION.TOP_RIGHT,
        });
        return;
      } else if (selectedTheme4 == "") {
        toast.error("Theme 4 is mandatory", {
          position: toast.POSITION.TOP_RIGHT,
        });
        return;
      }else  if (!fontFile) {
        toast.error("Font File is mandatory", {
          position: toast.POSITION.TOP_RIGHT,
        });
        return;
      }
      else  if (
        !cssFile &&
        (cssContent == null || cssContent == "" || cssContent.trim() == "")
      ) {
        toast.error("Css file or Css text is required", {
          position: toast.POSITION.TOP_RIGHT,
        });
        return;
      }

      const formData = new FormData();
      formData.append("theme1", selectedTheme1);
      formData.append("theme2", selectedTheme2);
      formData.append("theme3", selectedTheme3);
      formData.append("theme4", selectedTheme4);

      formData.append("themeColor1", themeColor1);
      formData.append("themeColor2", themeColor2);
      formData.append("themeColor3", themeColor3);
      formData.append("themeColor4", themeColor4);

      formData.append("font_file", fontFile);
      if (!cssFile && cssContent) {
        formData.append("style_content", cssContent);
         }
         if (!cssContent && cssFile) {
          formData.append("style_file", cssFile);
         }
     
        //  let config = {
        //    method: "post",
        //    maxBodyLength: Infinity,
        //    url: `${baseUrl}api/v1/business/createBusinessTheme`,
        //    formData,
        //    headers: {
        //      _token: userData?.token,
        //    },
        //  };
        //  let { data: res } = await axios.request(config)


      let { data: res } = await axios.post(
        `${baseUrl}api/v1/business/createBusinessTheme`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            _token: userData?.token,
          },
        }
      );
      // console.log(res,"resssssss")
      if (res && res?.success) {
        window.location.reload()
        toast.success(res?.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        clearData();
      }
    } catch (err) {
      toast.error(err, {
        position: toast.POSITION.TOP_RIGHT,
      });
      console.log(err, "error in saving the theme ");
    }
  };
  const handleChangeStyleFile = (e) => {
    if (e.target?.files.length > 0) {
      setCssFile(e.target.files[0]);
      setSelectedStyle("file");
    } else {
      setSelectedStyle(null);
    }
  };
  useEffect(()=>{
// console.log(selectedTheme1,"imageView121!!!!@@")
  },[selectedTheme1])
  return (
    <>
      <PageName PageName="Create Business Theme" />

      <div className="row">
        <div className="col-12 col-lg-4">
          <div className="tile">
            {/* <p>Selected Color: {selectedTheme}</p> */}
            <form>
              <div class="mb-3">
                <label for="exampleInputEmail1" class="form-label">
                  Choose Theme 1 image
                </label>
                <input
                  name="theme1"
                  type="file"
                  class="form-control"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  // value={selectedTheme1&&selectedTheme1?.name?.length?selectedTheme1?.name:""}
                  onChange={handleImageChange}
                />
              </div>
              <div class="mb-3">
                <label for="exampleInputEmail1" class="form-label">
                  Choose Theme Color 1
                </label>
                <input
                  type="color"
                  class="form-control"
                  id="exampleInputPassword1"
                  value={themeColor1}
                  onChange={(e) => setThemeColor1(e.target.value)}
                />
              </div>
              <div class="mb-3">
                <label for="exampleInputEmail1" class="form-label">
                  Choose Theme 2
                </label>
                <input
                  name="theme2"
                  type="file"
                  class="form-control"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  onChange={handleImageChange}
                />
              </div>
              <div class="mb-3">
                <label for="exampleInputEmail1" class="form-label">
                  Choose Theme Color 2
                </label>
                <input
                  type="color"
                  class="form-control"
                  id="exampleInputPassword1"
                  value={themeColor2}
                  onChange={(e) => setThemeColor2(e.target.value)}
                />
              </div>
              <div class="mb-3">
                <label for="exampleInputEmail1" class="form-label">
                  Choose Theme 3
                </label>
                <input
                  name="theme3"
                  type="file"
                  class="form-control"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  onChange={handleImageChange}
                />
              </div>
              <div class="mb-3">
                <label for="exampleInputEmail1" class="form-label">
                  Choose Theme Color 3
                </label>
                <input
                  type="color"
                  class="form-control"
                  id="exampleInputPassword1"
                  value={themeColor3}
                  onChange={(e) => setThemeColor3(e.target.value)}
                />
              </div>
              <div class="mb-3">
                <label for="exampleInputEmail1" class="form-label">
                  Choose Theme 4
                </label>
                <input
                  name="theme4"
                  type="file"
                  class="form-control"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  onChange={handleImageChange}
                />
              </div>
              <div class="mb-3">
                <label for="exampleInputEmail1" class="form-label">
                  Choose Theme Color 4
                </label>
                <input
                  type="color"
                  class="form-control"
                  id="exampleInputPassword1"
                  value={themeColor4}
                  onChange={(e) => setThemeColor4(e.target.value)}
                />
              </div>

              <button
                type="submit"
                class="btn btn-primary"
                onClick={(e) => handleSubmit(e)}
              >
                Submit
              </button>
            </form>
          </div>
        </div>

        {/* <div className="col-12 col-lg-4">
        <div className="tile">
        <div class="mb-3">
  <label for="formFile" class="form-label">Font</label>
  <input class="form-control" type="file" id="formFile"/>
</div>

<div class="mb-3">
  <label for="exampleFormControlTextarea1" class="form-label">Css</label>
  <textarea class="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>
</div> 

<small style={{display:"block", textAlign:'center' }}><b>OR</b></small>

<div class="mb-3">
  <label for="formFile" class="form-label">Css File</label>
  <input class="form-control" type="file" id="formFile"/>
</div>
</div>
        </div>  */}
            <div className="col-12 col-lg-4">
        <article>
          <div class="row">
            <div class="col-12 col-lg-12">
              <p class="sectitlecard">
                <span>Font File</span>
              </p>

              <div class="row">
                <div class="col-12 col-lg-12">
                  <div class="">
                    <label for="formFile" class="form-label">
                      Upload Font File
                    </label>
                    <input
                      class="form-control"
                      type="file"
                      id="formFile"
                      onChange={(e) => setFontFile(e.target.files[0])}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </article>
        <article>
          <div class="row">
            <div class="col-12 col-lg-12">
              <p class="sectitlecard">
                <span>CSS File</span>
              </p>

              <div class="row">
                <div class="col-12 col-lg-12">
                  <textarea
                    class="form-control"
                    placeholder="CSS"
                    row="10"
                    style={{ height: "150px" }}
                    value={cssContent}
                    disabled={selectedStyle == "file"}
                    onChange={(e) => handleChangeStyleContent(e)}
                  ></textarea>
                  <small className="d-block text-center mt-3">Or</small>
                  <div class="">
                    <label for="formFile" class="form-label">
                      Upload Css File
                    </label>
                    <input
                      class="form-control"
                      type="file"
                      id="formFile"
                      disabled={selectedStyle == "text"}
                      onChange={(e) => handleChangeStyleFile(e)}
                    />
                  </div>
                  {/* {err ? (
                    <div style={{ color: "red", fontSize: "14px" }}>{err}</div>
                  ) : (
                    <></>
                  )} */}
                  {/* <button
                    onClick={handleSubmit}
                    class="btn btn-primary mt-4"
                    disabled={isSubmit}
                  >
                    Submit
                  </button> */}
                </div>
              </div>
            </div>
          </div>
        </article>
        </div>
        {imageView && (
          <div className="col-12 col-lg-4">
            <div className="tile">
              {imageView && (
                <>
                  <h6>Preview</h6>
                  <img
                
                    src={imageView}
                    alt="Preview"
                    className="mx-auto d-block img-fluid"
                  />
                </>
              )}

              <ul className="colorcircle">
                {imageView1 && (
                  <li style={{ background: "#fff" }}>
                    <img
                       style={{width:"100px",height:"50px"}}
                      name="imageView1"
                      onClick={(e) => handleChangePreviwPic(e)}
                      src={imageView1}
                      alt="Preview"
                      className="mx-auto d-block img-fluid"
                    />{" "}
                  </li>
                )}
                {imageView2 && (
                  <li style={{ background: "#fff" }}>
                    <img
                       style={{width:"100px",height:"50px"}}
                      name="imageView2"
                      onClick={(e) => handleChangePreviwPic(e)}
                      src={imageView2}
                      alt="Preview"
                      className="mx-auto d-block img-fluid"
                    />
                  </li>
                )}
                {imageView3 && (
                  <li style={{ background: "#fff" }}>
                    <img
                       style={{width:"100px",height:"50px"}}
                      name="imageView3"
                      onClick={(e) => handleChangePreviwPic(e)}
                      src={imageView3}
                      alt="Preview"
                      className="mx-auto d-block img-fluid"
                    />
                  </li>
                )}
                {imageView4 && (
                  <li style={{ background: "#fff" }}>
                    <img
                       style={{width:"100px",height:"50px"}}
                      name="imageView4"
                      onClick={(e) => handleChangePreviwPic(e)}
                      src={imageView4}
                      alt="Preview"
                      className="mx-auto d-block img-fluid"
                    />
                  </li>
                )}
              </ul>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default ThemeSelect;
