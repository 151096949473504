import React, { useContext, useEffect, useState } from "react";
import PageName from "../Utils/PageName";
import { Link } from "react-router-dom";
import { environmentVariables } from "../../config/env.config";
import axios from "axios";
import { userContext } from "../../context/userContext";


function Email_template_admin() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const baseUrl = environmentVariables?.apiUrl;
  const { userData, setUserData } = useContext(userContext);
  useEffect(() => {
    let obj = {
      "business_id": "5"
    }
    axios
    .post(`${baseUrl}api/v1/plan/fatchPlans`,obj,{
      headers:{
        _token:userData?.token
      },
      withCredentials:true,
    })
    .then((response) => {
      // console.log(response,"@@@@@@@@@@CCCCCCCC");

      setData(response?.data?.result);
    })
    .catch((error) => {
      console.log(error);
    });

 }, []);

// const RequestCard = () => {
  return (
    <>
      <PageName PageName="Appointment Created" />

      <article>
        <div class="row">
          <div class="col-md-12">
            <div class="tile">
              <div class="tile-body">
                <div class="table-responsive">
                  <table class="table table-bordered">
                    <thead>
                      <tr>
                        <th>USER NAME</th>
                        <th>PLAN NAME</th>
                        <th>BUSINESS</th>
                        <th>DURATION</th>
                        <th>DATE</th>
                        <th>ACTION</th>
                      </tr> 							
                    </thead>
                    <tbody>
    {loading ? (
      <>
      {data?.map((item) => (
        <tr key={item.id}>
          <td>{item.order_id}</td>
          <td>{item.name}</td>
          <td>{item.business}</td>
          <td>{item.duration}</td>
          <td>{item.created_at 	}</td>
          {/* <td><span class="badge rounded p-2 m-1 px-3 bg-success">{item.status}</span></td> */}
          <td>
                          <div class="btn-group">
                             
                            
                            <Link to="/">
                              <button class="btn btn-danger" href="#">
                                <i class="bi bi-trash fs-5"></i>
                              </button>
                            </Link>
                          </div>
                        </td>
        </tr>
      ))}
  </>
      ) : (
        <p>Loading...</p>
        
      )}

                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </article>
    </>
  );
};

export default Email_template_admin;
