import axios from 'axios';

const IPIFY_URL = 'https://api.ipify.org?format=json';

export const getIpAddress = async () => {
  try {
    const response = await axios.get(IPIFY_URL);
    return response.data.ip;
  } catch (error) {
    console.error('Error fetching IP address:', error);
    return null;
  }
};
