import axios from 'axios';

const IPAPI_URL = 'https://ipapi.co/'; // Base URL for ipapi

export const getIPLocation = async (ip) => {
  try {
    const response = await axios.get(`${IPAPI_URL}${ip}/json/`);
    return response.data;
  } catch (error) {
    console.error('Error fetching location:', error);
    return null;
  }
};
