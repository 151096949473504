// store.js
import { createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { combineReducers } from 'redux';
// Reducer
const counterReducer = (state = { count: 0 }, action) => {
  switch (action.type) {
    case 'INCREMENT':
      return { count: state.count + 1 };
    case 'DECREMENT':
      return { count: state.count - 1 };
    default:
      return state;
  }
};



const userReducer = (state = { userObject: null }, action) => {
  
    switch (action.type) {
      case 'SET_USER_OBJECT':
        return { userObject: action.payload };
      case 'SET_USER':
        return { userObject: action.payload };
      case 'CLEAR_USER':
        return { userObject: null };
      default:
        return state;
    }
  };
  
  const rootReducer = combineReducers({
    counter: counterReducer,
    user: userReducer,
  });


const store = createStore(
  rootReducer,
  composeWithDevTools() 
);

export default store;
