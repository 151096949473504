import { useQuery } from 'react-query';
import { environmentVariables } from "../../config/env.config";
import axios from 'axios';

export function TestQuery() {
    const baseUrl = environmentVariables?.apiUrl;
    const fetAllRestaurants = async ()=>{
        let response = await axios.post(`${baseUrl}api/v1/business/getAllRestaurants`);
        return response?.data?.data?.records;
    }
    const { isLoading, error, data } = useQuery('restaurants', fetAllRestaurants);

    if (isLoading) return <p>Loading...</p>;

    if (error) return <p>An error has occurred: {error.message}</p>;

    return (
    <ul>
        {data.length>0?data.map(restaurant => (
        <li key={restaurant.id}>
            <h5 className='text-bold '><a target="_blank" href={restaurant?.slug_subdomain != null?"http://"+restaurant?.slug_subdomain:"http://localhost:3000/"+restaurant?.slug}>{restaurant?.title}</a> </h5>
        </li>
        )):""}
    </ul>
    );
}