import React, { useState, useEffect } from "react";
import PhoneInput from "react-phone-input-2";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import "react-phone-input-2/lib/bootstrap.css";
import "./../Updatedcss/BusinessChangeBlockUpdatedStyle.css";

const ResumeEducationSocialForm = ({
  formCount,
  socialData,
  setIsAddSocialDisable,
  setSocialData,
  index,
}) => {
  const [data, setData] = useState({
    formCount: formCount,
    nameOfSocial: "",
    link: "",
    name: "", 
    location: "",
    graduationyear: "", 
    graduationendyear:"",
    degree: "",
    boardname:"",
    schuniname:"",

  });
  // console.log('datadata',data)
  const [countryCode, setCountryCode] = useState("");
  const [isAddDisable, setIsAddDisable] = useState(false);
  const [isAddShow, setIsAddShow] = useState(false);
  const [allFieldsFilled, setAllFieldsFilled] = useState(false);
  const [errors, setErrors] = useState({});
  const [isAddButtonDisabled, setIsAddButtonDisabled] = useState(true);
  const [isEditMode, setIsEditMode] = useState(true);
   useEffect(() => {
    // Check if all fields are filled
    const filled =
      data.name &&
      data.location &&
      data.graduationyear &&
      data.graduationendyear &&
      data.degree && 
      data.boardname && 
      data.schuniname
    setAllFieldsFilled(filled);
  }, [data]);
  useEffect(() => {
    let currentForm = socialData.filter((val) => val.formCount == formCount);
    if (currentForm.length > 0) {
      setData(currentForm[0]);
    }
    currentForm[0]["degree"] ? setIsAddShow(false) : setIsAddShow(true);
  }, [socialData]);
  const getSocialLinkLabel = (name) => {
    if (name == "whatsapp") {
      return "Mobile";
    } else if (name == "telegram") {
      return "Username";
    } else if (name == "discord") {
      return "Channel Name";
    } else if (name == "pinterest") {
      return "Username";
    } else if (name == "gmail") {
      return "Email Id";
    } else {
      return "Link";
    }
  };
  const handleAddSocial = () => {
    let selectedForm = socialData.filter(
      (obj) => obj.formCount == data.formCount
    );
    if (selectedForm.length > 0) {
      const updateItem = (formCount) => {
        const index = socialData.findIndex(
          (item) => item.formCount == formCount
        );

        const updatedItems = [...socialData];

        if (index !== -1) {

          updatedItems[index] = {
            ...updatedItems[index],
            degree: data.degree,
            link: data.link,
            graduationyear: data.graduationyear,
            location: data.location,
            boardname: data.boardname,
            schuniname: data.schuniname,
            graduationendyear: data.graduationendyear,
          };
        }
        setSocialData(updatedItems);
      };
      updateItem(formCount);
    } else {
      setSocialData([...socialData, data]);
    }
    setIsAddDisable(true);
    setIsAddSocialDisable(false);
    setIsEditMode(false);
  };

  const handleInputChange = (e, field) => {
    // setData({ ...data, [field]: e.target.value });
    const inputValue = e.target.value; // Get the input value
    let updatedData = { ...data, [field]: inputValue };
    let error = ""; 
    if (field === "location") {
      // const regex = /^[A-Za-z\s]{2,50}$/; 
      const regex = /^[A-Za-z0-9,\s]{2,100}$/;
      if (!regex.test(inputValue)) {
        error = "contain only Comma, Number and alphabetic characters";
      } 
    }

    if (field === "boardname") {
      // const regex = /^[A-Za-z\s]{2,50}$/; 
      const regex = /^[A-Za-z0-9,\s]{2,50}$/;
      if (!regex.test(inputValue)) {
        error = "contain only Comma, Number and alphabetic characters";
      } 
    }

    if (field === "schuniname") {
      // const regex = /^[A-Za-z\s]{2,50}$/; 
      const regex = /^[A-Za-z0-9,\s]{2,100}$/;
      if (!regex.test(inputValue)) {
        error = "contain only Comma, Number and alphabetic characters";
      } 
    }
    
    setData(updatedData); // Update state only after validation
    setErrors(prevErrors => ({
      ...prevErrors,
      [field]: error
    }));
  };

  const removeForm = (formKey) => {
    // console.log(formKey,"fmKey,formKey,",socialData)
    const newFormList = socialData.filter((val, index) => index !== formKey);
    // console.log(newFormList,"newF342")
    setSocialData([...newFormList]);
    // console.log(socialData, "socialData!@#!@#");

    // if (socialData.length == 0) {
    setIsAddSocialDisable(false);
    // }
  };

  const handleSelectChange = (e) => {
    const selectedValue = e.target.value;
    setData({ ...data, degree: selectedValue });
  };

  const toggleEditMode = () => {
    setIsEditMode(!isEditMode);
  };

  useEffect(() => {
    // Check if any errors exist in the errors object
    const hasErrors = Object.values(errors).some(error => error !== "");
    // Update the add button disabled state accordingly
    setIsAddButtonDisabled(hasErrors);
  }, [errors]);

  return (
    <div key={`form-${formCount}`} className="col-12" id="Socialformsidepad">
      <div className="serviceboxedittime" id="SocialformsideMar">
        <i
          class="fa-solid fa-circle-xmark crossedit"
          onClick={() => removeForm(index)}
        ></i>

        <div className="">
          <div className="row mb-3" id="ResumeSocialInnerFormPaddissu" style={{marginBottom:"0px !important"}}>
            {/* <span onClick={toggleEditMode}>X</span> */}
            <span onClick={toggleEditMode} style={{padding:"2px 15px 10px 0px", cursor:"pointer", textAlign:"end"}}><i class="fa-solid fa-pen-to-square"></i></span>
            <div className="">
              <div className="row mb-3" id="ResumeSocialInnerFormPaddissu">
                {/* <div class=" col-md-12">
                  <label class="form-label">Name</label>
                  <input
                    class="form-control"
                    type="text"
                    placeholder="School Name"
                    value={data.name}
                    onChange={(e) => handleInputChange(e, "name")}
                    disabled={!isAddShow}
                  />
                </div> */}
                    <div class=" col-md-12">
                {/* <label class="form-label">Degree</label> */}
              <select
                // disabled={!isAddShow}
                disabled={!isEditMode}
                name="type"
                class="form-select"
                onChange={handleSelectChange}
                value={data["degree"]}
              >
                <option value="">Select Degree</option>
                <option value="10th">10th</option>
                <option value="12th">12th</option>
                <option value="Engineering">Engineering </option>
                <option value="Architecture">Architecture</option>
                <option value="BachelorofArts">BA</option>
                <option value="CA">CA</option>
                <option value="Bachelor In Technologies">BT</option>
                <option value="Law">Law</option>
                <option value="Pharmacy">Pharmacy</option>
                <option value="Animation">Animation</option>
                <option value="Mass Communication">MC</option>
                <option value="MBA">MBA</option>
                <option value="Nursing">Nursing</option>
                <option value="BBA">BBA </option>
                <option value="Management">Management</option>
                {/* <option value="other">other</option> */}
              </select>
                </div>
                <div class=" col-md-12">       
                 <input
                   class="form-control"
                   type="text"
                   placeholder="School, College Name"
                   value={data.schuniname}
                   onChange={(e) => handleInputChange(e, "schuniname")}
                  //  disabled={!isAddShow}
                  disabled={!isEditMode}
                 />
                 {errors.schuniname && <div className="error" style={{ color: 'red', fontSize:"12px" }}>{errors.schuniname}</div>}
               </div>
              
                <div class=" col-md-12">       
                 <input
                   class="form-control"
                   type="text"
                   placeholder="BoardName (UpBoard, CBSC , AKTU)"
                   value={data.boardname}
                   onChange={(e) => handleInputChange(e, "boardname")}
                  //  disabled={!isAddShow}
                  disabled={!isEditMode}
                 />
                 {errors.boardname && <div className="error" style={{ color: 'red', fontSize:"12px" }}>{errors.boardname}</div>}
               </div>

               <div class=" col-md-12">
                 
                 <input
                   class="form-control"
                   type="text"
                   placeholder="Location"
                   value={data.location}
                   onChange={(e) => handleInputChange(e, "location")}
                  //  disabled={!isAddShow}
                  disabled={!isEditMode}
                 />
                 {errors.location && <div className="error" style={{ color: 'red', fontSize:"12px" }}>{errors.location}</div>}
               </div>

                <div class=" col-md-12">
                  {/* <label class="form-label">Graduation Year</label> */}
                  <input
                    class="form-control"
                    type="date"
                    // placeholder="School Name"
                    value={data.graduationyear}
                    onChange={(e) => handleInputChange(e, "graduationyear")}
                    // disabled={!isAddShow}
                    disabled={!isEditMode}
                  />
                </div>


                <div class=" col-md-12">
                  {/* <label class="form-label">Graduation Year</label> */}
                  <input
                    class="form-control"
                    type="date"
                    // placeholder="School Name"
                    value={data.graduationendyear}
                    onChange={(e) => handleInputChange(e, "graduationendyear")}
                    // disabled={!isAddShow}
                    disabled={!isEditMode}
                  />
                </div>
              </div>
            </div>
           
            {/* <div class=" col-md-12">
              <label class="form-label">Degree</label>
              <select
                disabled={!isAddShow}
                name="type"
                class="form-select"
                onChange={handleSelectChange}
                value={data["nameOfSocial"]}
              >
                <option value="">Select Degree</option>
                <option value="Engineering">Engineering </option>
                <option value="Architecture">Architecture</option>
                <option value="Bachelor of Arts">BA</option>
                <option value="CA">CA</option>
                <option value="Bachelor">Bachelor of Technology</option>
                <option value="Law">Law</option>
                <option value="Pharmacy">Pharmacy</option>
                <option value="Animation">Animation</option>
                <option value="Mass">Mass Communication</option>
                <option value="MBA">MBA</option>
                <option value="Nursing">Nursing</option>
                <option value="BBA">BBA </option>
                <option value="Management">Management</option>
                <option value="other">other</option>
              </select>
            </div> */}
            {/* <div class=" col-md-12 mt-3">
              <label class="form-label">
                {getSocialLinkLabel(data["nameOfSocial"].toLowerCase())}
              </label>

              {data["nameOfSocial"] == "whatsapp" ? (
                <PhoneInput
                  disabled={!isAddShow}
                  country={"in"}
                  enableSearch={true}
                  value={data["link"]}
                  onChange={(e) => setData({ ...data, link: e })}
                />
              ) : (
                <input
                  disabled={!isAddShow}
                  class="form-control"
                  type="text"
                  placeholder="Link"
                  onChange={(e) => setData({ ...data, link: e.target.value })}
                  value={data["link"]}
                />
              )}
            </div> */}
          </div>
        </div>

        {isEditMode ? (
       <div class="resumeworkexpaddbutton">
           <button
            type="button"
            // disabled={isAddDisable}
            // disabled={!data["nameOfSocial"] || !data["link"]}
            onClick={handleAddSocial}
            // className="btn btn-cu addbuttoneditform editButtonTextimonial"
            className={`btn btn-cu addbuttoneditform editButtonTextimonial ${allFieldsFilled ? 'filled' : ''}`}
            disabled={isAddButtonDisabled}
          >
            Add
          </button>
        </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default ResumeEducationSocialForm;
