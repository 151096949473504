import React from 'react'
import Slider from 'react-slick';
import { useDispatch, useSelector } from 'react-redux';
import img1 from '../../../../src';

// console.log(img1,"img11111")
// import bannerImage from "../../../../Images/bannerimage.jpg";
// import logoImage from "../../../../Images/logo1.jpg";
// // import "./Template11.css";
// import axios from "axios";
// import theme6strip from "../../../../Images/theme6strip.png";
// import CalanderIconn from "../../../../Images/DarkcalendarIcondays.png";
// import TestimonialCarousel from "./TestimonialCarousel";
// import GalleryCarousel from "./GalleryCarousel";
// // import { Name, NameWrapper } from "./StyleThemejs";
// import ResumeIcon from "./../../../../Images/ResumeLogo.png";
// import phoneIcon from "./../../../../Images/PhoneCallingRounded.png";
// import MessageIcon from "./../../../../Images/Letter.png";
// import MapIcon from "./../../../../Images/MapPoint.png";


 

const ResumeTemplateSlider = () => {

    // const imageUrls = Array.from({ length: 12 }, (_, index) => `https://picsum.photos/200/300?random=${index}`);
    // source_theme4="public/theme/theme3/color1.png"

    // const data = [
    //   { themeName: "theme1", image: "public/resumetheme/1.jpg" },
    //   { themeName: "theme2", image: "public/resumetheme/2.jpg" },
    //   { themeName: "theme3", image: "public/resumetheme/3.jpg" },
    //   { themeName: "theme4", image: "public/resumetheme/4.jpg" }
    // ];

    

    const dispatch = useDispatch();
    const ReduxObject = useSelector(state => state.user?.userObject);

    const data = [ 
      { imageUrl: "resumetheme/1.jpg" },
      // { imageUrl: "http://localhost:3000/resumetheme/1.jpg" },
      { imageUrl: "resumetheme/2.jpg" },
      // { imageUrl: "http://localhost:3000/resumetheme/2.jpg" },
      { imageUrl: "resumetheme/3.jpg" },
      // { imageUrl: "http://localhost:3000/resumetheme/2.jpg" }
      { imageUrl: "resumetheme/4.jpg" },
      { imageUrl: "resumetheme/5.jpg" },
      { imageUrl: "resumetheme/6.jpg" },
      // { imageUrl: "resumetheme/7.jpg" },
    ];

    const imageUrls=data;
    const showNavigation = imageUrls.length >= 3;


    if (!ReduxObject?.SelectResumeId) {
      dispatch({
          type: 'SET_USER_OBJECT' ,
          payload: { ...ReduxObject, SelectResumeId: 'Resume_1'}
      });
  }
  
    const SampleNextArrow = (props) => {
      const { onClick } = props;
      return (
          <div className="slick-arrow slick-next" onClick={onClick} >
              Next
          </div>
      );
  };

  const SamplePrevArrow = (props) => {
      const { onClick } = props;
      return (
          <div className="slick-arrow slick-prev" onClick={onClick} style={{ paddingLeft: '15px', zIndex:"" }}>
              Prev
          </div>
      );
  };
    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        initialSlide:1,
        responsive: [
          {
            breakpoint: 425,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
            }
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
            }
          },
          {
            breakpoint: 890,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
            }
          },
          {
            breakpoint: 1260,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
            }
          },
          {
            breakpoint: 1450,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
            }
          },
          {
            breakpoint: 1600,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
            }
          }
        ],
        nextArrow: showNavigation ? <SampleNextArrow /> : null,
        prevArrow: showNavigation ? <SamplePrevArrow /> : null
      };

    

    const sendId=(event)=> {
        var id = event.target.id;
        dispatch({
          type: 'SET_USER_OBJECT',
          payload: { ...ReduxObject,SelectResumeId:id }
        });
        // console.log("Clicked image ID: ", id);
    }


  return (
    <Slider {...settings}>

    {imageUrls.map((imageUrl, index) => (
      <div key={index + 1} >
        <h3 style={{display:"flex", justifyContent:"center"}}>
          <img
            src={imageUrl.imageUrl}
            alt={`Slide ${index + 1}`}
            onClick={sendId}
            id={`Resume_${index + 1}`}
            style={{width:"90%", cursor: 'pointer', borderRadius:"10px", boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px"}}
          />
        </h3>
      </div>
    ))}
  </Slider>
  )
}

export default ResumeTemplateSlider