import React, { useContext,useState, useEffect, useRef } from "react";
import { environmentVariables } from "../../../config/env.config";
import testimonialdefault from "../../../Images/testimonialdefault.png";
import avatarImage from "../../../Images/DishReview.png";
import "./../Updatedcss/BusinessChangeBlockUpdatedStyle.css";
import { useFormik } from "formik";
import * as Yup from "yup";
import styled from "styled-components";
import { currencyContext } from "../../../context/currencyContext.js";

const FormikErrorStyle = styled.div`
font-size:11px;
color: #ff3333;
text-align: justify;
    padding-bottom: 10px;
`;


const restaurantForm = ({
  formCount,
  restaurantData,
  setIsAddTestimonialDisable,
  setRestaurantData,
  index,
  removeForm,
}) => {
  const fileInputRef = useRef();
  const openFileInput = () => {
    fileInputRef.current.click();
  };
  const baseUrl = environmentVariables?.apiUrl;
  const [image, setImage] = useState(null);
  const [addBtnDisable, setAddBtnDisable] = useState(false);
  const { currencyObjData } = useContext(currencyContext);

  const [data, setData] = useState({
    formCount: formCount,
    image: null,
    name: "",
    price: "",
    category: "",
    imageView: null,
    isImageUpload: true,
  });


  const getImage = (data) => {
    if (data["imageView"]) {
      return data["imageView"];
    } else {
      if (data["image"]) {
        if (data["image"] != "null") {
          return `${baseUrl}uploads/${data["image"]}`;
        } else {
          return testimonialdefault;
        }
      } else {
        return avatarImage;
      }
    }
  };

  const validationSchema = Yup.object({
    name: Yup.string()
      .matches(/^[a-zA-Z0-9 ,.?\/()&@$'_\-\\"]*$/, "Only ‘,.?-_/@$&’ characters are allowed")
      .required("Name is required")
      .min(2, "Minimum 2 characters")
      .max(45, "Maximum 45 Characters"),
    description: Yup.string()
      .min(10, "Minimum 10 characters")
      .max(400, "Maximum 400 characters"),
    category: Yup.string(),
  });

  const formik = useFormik({
    initialValues: {
      name: data.name || "",
      price: data.price || "",
      category: data.category || "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleAddService(values);
    },
  });

  const handleAddService = (values) => {
    setAddBtnDisable(true);
    setIsAddTestimonialDisable(false);
    const newRestaurantData = restaurantData.map((val, index) => {
      if (val.formCount == formCount) {
        val.name = values?.name;
        val.price = values?.price;
        val.category = values?.category;
        val.image = image;
        val.imageView = data?.imageView;
        val.isImageUpload = data?.isImageUpload;
        return val;
      }
      return val;
    });

    setRestaurantData(newRestaurantData);
  };

  const handleChangeImage = (e) => {
    setImage(e.target.files);
    if (e.target.files.length > 0) {
      const reader = new FileReader();
      reader.onload = () => {
        setData({ ...data, imageView: reader.result });
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  };
  useEffect(() => {
    let currentForm = restaurantData[index];
    console.log("Current Form Data on UseEffect:", currentForm); 
    setData({
      name: currentForm?.name,
      price: currentForm?.price,
      category: currentForm?.category,
      image: currentForm?.image,
      imageView: currentForm?.imageView,
      formCount: index,
      isImageUpload: currentForm?.isImageUpload,

    });

  }, [restaurantData]);

  return (
    <div
      key={`form-${formCount}`}
      className="col-12"
      id="AddNewStyleFormfieldpad"
    >
      <div className="serviceboxedittesti MobileviewServices">
        <i
          className="fa-solid fa-circle-xmark crossedit"
          onClick={() => removeForm(index)}
        >
          {" "}
        </i>

        <div className="boxbanner">
          <div className="bannerboxedittesti ">
            <img
              style={{
                cursor:
                  data["isImageUpload"] && !addBtnDisable
                    ? "pointer"
                    : "inherit",
              }}
              onClick={() => {
                data["isImageUpload"] && !addBtnDisable
                  ? openFileInput()
                  : null;
              }}
              src={getImage(data)}
              className="mx-auto d-block img-fluid"
            />
            <i
              style={{
                display:
                  data["isImageUpload"] && !addBtnDisable ? "block" : "none",
              }}
              onClick={() => {
                data["isImageUpload"] && !addBtnDisable
                  ? openFileInput()
                  : null;
              }}
              id="uploadicon"
              className="fa fa-solid fa-upload"
            ></i>
          </div>
          {data["isImageUpload"] && !addBtnDisable && (
            <input
              ref={fileInputRef}
              style={{ display: "none" }}
              onChange={(e) => {
                handleChangeImage(e);
              }}
              className="form-control"
              type="file"
              placeholder="Title"
              accept=".png, .jpg, .jpeg"
            />
          )}
        </div>
        <div className="AddDetailsTextimonialphnview">
          <div className="" style={{ fontSize: "16px", fontWeight: "500" }}>
            {data["isImageUpload"] && !addBtnDisable ? (
              <div>
                <input
                  {...formik.getFieldProps("name")}
                  className="form-control"
                  type="text"
                  placeholder="Dish Name"
                />
                {formik.touched.name && formik.errors.name ? (
                  <FormikErrorStyle>{formik.errors.name}</FormikErrorStyle>
                ) : null}
              </div>
            ) : (
              <div>{data["name"] == "undefined" ? "" : data["name"]}</div>
            )}
          </div>

          <div className="" style={{ fontSize: "16px", fontWeight: "500" }}>
            {data["isImageUpload"] && !addBtnDisable ? (
              <div>
                <input
                  {...formik.getFieldProps("price")}
                  className="form-control"
                  type="number"
                  placeholder={`Price in ${currencyObjData?.name} `}
                />
                {formik.touched.price && formik.errors.price ? (
                  <FormikErrorStyle>{formik.errors.price}</FormikErrorStyle>
                ) : null}
              </div>
            ) : (
              <div>{data["price"] == "undefined" ? "" : data["price"]}</div>
            )}
          </div> 
          {data["isImageUpload"] && !addBtnDisable ? (
          <div className="form-group" style={{display:'flex', alignItems:'center', gap:'10px'}}>
            <label style={{display:'flex', alignItems:'center', gap:'5px', justifyContent:'center'}}>
              <input
                type="radio"
                name="category"
                value="veg"
                checked={formik.values.category === "veg"}
                onChange={formik.handleChange}
              />
              Veg
            </label>
            <label style={{display:'flex', alignItems:'center', gap:'5px', justifyContent:'center'}}>
              <input
                type="radio"
                name="category"
                value="nonveg"
                checked={formik.values.category === "nonveg"}
                onChange={formik.handleChange}
              />
              Non-Veg
            </label>
            {formik.touched.category && formik.errors.category ? (
              <FormikErrorStyle>{formik.errors.category}</FormikErrorStyle>
            ) : null}
          </div>
        ) : (
          <div>{data["category"]}</div>
        )}

          {data["isImageUpload"] && !addBtnDisable ? (
            <button
              type="button"
              disabled={!formik.isValid || !formik.dirty || !formik.values.name || 
              !formik.values.price || !formik.values.category || !image ||
              addBtnDisable  
              }
              onClick={() => {
                formik.handleSubmit();
                handleAddService({
                  name: data.name,
                  price: data.price,
                  category: data.category,
                  image: image,
                  imageView: data.imageView,
                  isImageUpload: data.isImageUpload,
                });
              }}
              className="btn btn-cu editbutton editButtonTextimonial"
            >
              Add
            </button>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
};

export default restaurantForm;
