import React, { useState,useContext } from 'react';
import Camera from "./../../../../Images/camcollection.png";
import Sink from "./../../../../Images/sinkcollection.png";
import Shoes from "./../../../../Images/shoescollection.png";
import Headphone from "./../../../../Images/headphonecollection.png";
import PhoneInput from "react-phone-input-2";
import Drawer from "react-bottom-drawer";
import BottomDrawer from './BottomDrawer';
import { environmentVariables } from "../../../../config/env.config";
import { currencyContext } from "../../../../context/currencyContext.js";

const AllCollection = (props) => {
    const data = props?.products
    const [open, setOpen] = useState(false);
    const [isVisible, setIsVisible] = React.useState(true);
    const openDrawer = React.useCallback(() => setIsVisible(true), []);
    const closeDrawer = React.useCallback(() => setIsVisible(false), []);
    const { currencyObjData } = useContext(currencyContext);

    const imageMap = {
        Camera: Camera,
        Sink: Sink,
        Shoes: Shoes,
        Headphone: Headphone,
    };

  return (


<div>
<div className='py-4 px-0 grid grid-cols-2 gap-2'>
    {data?.map((item, index) => (
        <div key={index} className='border border-efefef p-2 rounded-2xl'>
            <img src={item?.imageView ? item.imageView : `${environmentVariables?.apiUrl}uploads/${item?.image}`} className='object-cover prodimg' alt={item?.name} />
            <div className="text-start font-normal text-base mt-2 px-1 pt-2 font-Tajawal">{item?.name || item?.product_name}</div>
            <div className="flex justify-between items-center mt-1 px-1 pb-2">
                {/* <div className="text-lg font-bold text-black text-sm">{currencyObjData?.symbol}{item?.price || item?.product_price}</div> */}
                <div className='bg-coffee rounded'>
                <button className="bg-blue-500 text-sm font-semibold text-white font-bold px-2 py-2 rounded">
                                    {item?.product_category === 'sales' ? 'Enquire Now' : 'Enquire Now'}
                                </button>
                </div>
            </div>
        </div>
    ))}
</div>
</div>
);
};


export default AllCollection