import React from "react";
import "./LandingFoolterStyle.css";

function Refund() {
  return (
    <div>
      <section className="pagecover">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-12">
              <h1>Cancellation & Refund Policy</h1>
            </div>
          </div>
        </div>
      </section>

      <section class="blog">
        <div class="container" id="refund_container">
          <div class="row">
            <div class="col-lg-12 col-md-12 pt-5">
              <p class="refund_container_paragraph">
                This Cancellation and Refund Policy outlines the terms and
                conditions governing cancellations and refunds for products and
                services purchased from businessbay.io ("the Website"). <br />
                By purchasing from our Website, you agree to the terms stated
                herein.
              </p>
              <div class="refund_container_division">
                {/* <h2>Cancellation Policy</h2> */}
                <div class="refund_heading">Cancellation Policy</div>
                <p  class="refund_container_division_paragraph">
                  <strong>a. Order Cancellation:</strong> Customers may request
                  to cancel their order within 24 hours after the purchase.
                  Requests for cancellation after this period will be subject to
                  the terms outlined below.
                </p>
                <p class="refund_container_division_paragraph">
                  <strong>b. Service Subscription Cancellation:</strong>{" "}
                  Customers with service subscriptions may cancel their
                  subscription at any time, subject to the terms outlined below.
                </p>
              </div>

              <div class="refund_container_division">
                {/* <h2>Refund Policy</h2> */}
                <div class="refund_heading">Refund Policy</div>
                <p class="refund_container_division_paragraph">
                  <strong>a. Products:</strong> Refunds for products will be
                  considered if the request is made within 3-4 working days of
                  the purchase date. The product must be unused and in its
                  original packaging. Refunds may be subject to a restocking
                  fee.
                </p>
                <p>
                  <strong>b. Services:</strong> Refunds for services will be
                  considered if the request is made within 2 business days of
                  the purchase date. The refund amount may be prorated based on
                  the portion of the service that has been delivered.
                </p>
</div>
<div class="refund_container_division">
                {/* <h3>Non-Refundable Items and Services:</h3> */}
                <div class="refund_heading">Non-Refundable Items and Services:</div>
                <p class="refund_container_division_paragraph">
                  Certain items and services are non-refundable, including but
                  not limited to [list non-refundable items/services]. These
                  items/services are clearly indicated as non-refundable at the
                  time of purchase.
                </p>
              </div>

              <div class="refund_container_division">
                {/* <h2>Refund Process</h2> */}
                <div class="refund_heading">Refund Process</div>
                <p class="refund_container_division_paragraph">
                  <p>
                    a. To request a refund, customers must contact our customer
                    support team at{" "}
                    <a href="mailto:info@businessbay.io">info@businessbay.io</a>{" "}
                    with their order details.
                  </p>
                </p>
                <p class="refund_container_division_paragraph"> 
                  <p>
                    b. Refunds will be issued to the original payment method
                    used for the purchase. Processing times for refunds may vary
                    based on the payment provider.
                  </p>
                </p>
              </div>
              <div class="refund_container_division">
                {/* <h2>Exceptions</h2> */}
                <div class="refund_heading"> Exceptions</div>
                <p class="refund_container_division_paragraph">
                  <p>
                    a. In cases where the product or service received is
                    defective or materially different from what was advertised,
                    customers may be eligible for a full refund or replacement.
                  </p>
                </p>
                <p class="refund_container_division_paragraph">
                  <p>
                    b. Events beyond our control (e.g., natural disasters,
                    technical failures) may result in disruptions to services.
                    In such cases, refunds may be provided at our discretion.
                  </p>
                </p>
              </div>

              <div class="refund_container_division">
                {/* <h2>Changes to Policy</h2> */}
                <div class="refund_heading"> Changes to Policy</div>
                <p class="refund_container_division_paragraph">
                businessbay.io reserves the right to modify or update this
                  Cancellation and Refund Policy at any time. Changes will be
                  effective upon posting on the Website. Customers are advised
                  to review the policy periodically.
                </p>
              </div>

              <div class="refund_container_division">
                {/* <h2>Contact Information</h2> */}
                <div class="refund_heading"> Contract Information</div>
                <p class="refund_container_division_paragraph">
                  For questions or concerns regarding cancellations and refunds,
                  customers can contact our customer support team at{" "}
                  <a href="mailto:info@businessbay.io">info@businessbay.io</a>.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Refund;
