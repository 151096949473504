import React, { useContext, useEffect, useState, useRef } from "react";
import PageName from "../Utils/PageName";
import { Link } from "react-router-dom";
import { environmentVariables } from "../../config/env.config";
import axios from "axios";
import { userContext } from "../../context/userContext";
import ReactDOM from "react-dom";
import DataTable from "react-data-table-component";
import SortIcon from "@material-ui/icons/ArrowDownward";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import { businessContext } from "../../context/businessContext";
import { businessObjContext } from "../../context/businessObjContext";
import CircularLoader from "../Components/CircularLoader/CircularLoader";
import { businessNameContext } from "../../context/businessContext";
import FilterIcon from "../../../src/Images/FilterIcon.png";
import Swal from "sweetalert2";
import DatePicker from "react-datepicker";
import { ToastContainer, toast } from "react-toastify";
import Modal from "@mui/material/Modal";
import { Box } from "@mui/material";
import "./UpdateRequestcardstyle.css";
import { useCallback, useMemo } from "react";
import { format } from 'date-fns';

const Restaurantorders = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const baseUrl = environmentVariables?.apiUrl;
  const { userData, setUserData } = useContext(userContext);

  const { businessObjData, setBusinessObjData } =
    useContext(businessObjContext);
  const [isLoading, setIsLoading] = useState(true);
  const { businessNameData, setBusinessNameData } =
    useContext(businessNameContext);
  const [selectedOption, setSelectedOption] = useState("");
  const [selectedData, setSelectedData] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [orderId, setOrderId] = useState("");
  const [contactNo, setContactNo] = useState("");

  const [businessname, setBusinessname] = useState("");
  const [businessData, setBusinessData] = useState([]);
  const [tempData, setTempData] = useState([]);
  const tableExtensions = { export: false, print: false };
  const [isSubmit, setIsSubmit] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const fromDate = useRef(null);
  const toDate = useRef(null);
  const [action] = useState({ fromUser: false });
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [paginationTotalRows, setPaginationTotalRows] = useState(0);
  const [paginationPerPage, setPaginationPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedRowsPerPage, setSelectedRowsPerPage] = useState([]);
  const [activeBusinessName, setActiveBusinessName] = useState("");
  const [selectedDateFrom, setSelectedDateFrom] = useState("");
  const [selectedDateTo, setSelectedDateTo] = useState("");
  const [showPopUp, setShowPopUp] = useState("");


  const handleFilterOpen = () => {
    setShowFilter(!showFilter);
  };
  const handleFilterClose = () => {
    setShowFilter(false);
  };


  //////////oreders ///////////////////////////////////////
  const [orders, setOrders] = useState([]);

  const fetchOrders = async (page) => {
    try {
      const response = await axios.get(`${baseUrl}api/v1/business/fetchOrdersByUserId`, {
        headers: {
          _token: userData?.token,
        },
        params: {
          userId: userData.id,
          page: page,
          pageSize: rowsPerPage
        }
      });
      setOrders(response.data.orders);
      setPaginationTotalRows(response?.data?.orders?.count)
      setIsLoading(false);
      setData(response?.data?.orders?.rows)
    } catch (error) {
      console.error('Error fetching orders:', error);
      setIsLoading(false);
    }
  };

  ///////////////////orders end //////////////////////////////

  const Boxstyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    // height: 500,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    zIndex: 999,
    borderRadius: "15px",
    border: "none",
    outline: "none",
    focus: "none",
    boxShadow:
      " rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;",
    padding: "3rem 5rem",
    "@media (max-width: 650px)": {
      width: "425px",
      padding: "2rem",
    },
    "@media (max-width: 430px)": {
      width: "380px",
      padding: "2rem",
    },
    "@media (max-width: 390px)": {
      width: "350px",
      padding: "2rem",
    },
  };

  const customStyles = {
    dataTableExtensions: {
      backgroundColor: "lightgray",
      padding: "10px",
    },
    headRow: {
      style: {
        backgroundColor: "#e9e9e9",
        borderRadius: "2px 2px 0 0",
        paddingLeft: "25px",
        border: "none",
        textTransform: "capitalize",
        position: "sticky",
        left: 0,
        top: 0,
        zIndex: 1,
        boxShadow: "0 2px 2px -1px rgba(0, 0, 0, 0.1)",
        overflow: "hidden",
        whiteSpace: "nowrap",
      },
    },
    headCells: {
      style: {
        color: "#525252",
        fontSize: "16px",
        minHeight: "52px",
      },
    },
    rows: {
      style: {
        padding: "6px 0px 6px 25px",
        fontSize: "14px",
        textTransform: "capitalize",
        minHeight: "48px !important",
      },
    },
    BusinessColumn: {
      position: "sticky",
      left: "0",
      zIndex: "1",
      backgroundColor: "#fff",
      overflow: "hidden",
      whiteSpace: "nowrap",
    }
  };



  const handleOnSelectedRowsChange = useCallback(
    ({ selectedRows }) => {
      if (!action.fromUser) return; //the component always trigger this with 0 selected rows when it renders a page, what would clear the selection

      selectedRowsPerPage[currentPage] = selectedRows; //there is no way to tell if a row was DEselected, so I had to control the selected rows per page,
      //the array would get an index to control each page
      // console.log(JSON.stringify(selectedRowsPerPage));
    },
    [currentPage, selectedRowsPerPage, action.fromUser]
  );

  const handlePageChange = (page) => {
    const nextPage = page;
    fetchOrders(nextPage);
    // console.log(page, "");
    // fetchBusinessData(page);
    setCurrentPage(page);
  };

  const handleRowsPerPageChange = async (newRowsPerPage) => {
    // if (!businesslistData.length) return;
    // Update the state first
    setRowsPerPage(newRowsPerPage);
    setCurrentPage(1); // Reset current page to 1
    setSelectedRowsPerPage([]);
    // Now call the function with the updated state
    fetchOrders(1);
  };

  useEffect(() => {
    fetchOrders();
  }, [rowsPerPage]);

  useEffect(() => {
    fetchOrders();
  }, [businessObjData]);





  const handleToggleStatus = () => { };
  const fetchData = async (obj) => {
    try {
      setOrderId(obj.orderId);
      setBusinessname(obj.BusinessName)
      setSelectedOption(obj.status);
      setContactNo(obj.phone)
    } catch (error) {
      console.error("API Error:", error);
    }
  };
  const columns = [
    {
      name: "orderId",
      selector: row=>row.orderId,
      sortable: true,
      width: "13%",
    },
    {
      name: "Restaurant Name",
      selector: row=>row.BusinessName,
      sortable: true,
      width: "13%",
    },
    {
      name: "Date",
      selector: (row) => {
        const date = new Date(row?.createdAt);
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
      },
      sortable: true,
    },
    {
      name: "Customer Name",
      selector: row=>row.username,
      sortable: true,
    },
    {
      name: "Phone",
      selector: row=>row.phone,
      sortable: true,
    },
    {
      name: "Address",
      selector: row=>row.address,
      sortable: true,
    },
    {
      name: "Status",
      selector: row=>row.status,
      sortable: true,
      cell: (d) => {
        const capitalizedStatus = d?.status ? d?.status.replace(/"/g, '') : '';
        return capitalizedStatus.charAt(0).toUpperCase() + capitalizedStatus.slice(1);
      },
    },

    {
      name: "Action",
      sortable: false,
      cell: (d, index) => (
        <>
          {/* <i
              style={{ width: "50px" }}
              onClick={() => handleDeleteAppoinment(d?.id)}
              className="fas fa-trash-alt"
            ></i> */}

          <i
            key={d?.orderId}
            data-bs-toggle="modal"
            data-bs-target="#planselect"
            onClick={() => fetchData(d)}
            className="first fas fa-pen"
          ></i>
        </>
      ),
      minWidth: "1px",
    }

  ];
  const tableData = {
    columns,
    data,
  };
  const handleUpdate = () => { };
  const [editedData, setEditedData] = useState({
    date: new Date(),
  });
  const handleDateChange = (date) => {
    setEditedData({
      ...editedData,
      date: date,
    });
  };
  const handleDropdownChange = (e) => {
    if (e.target.value == "pending") {
      setSelectedOption(e.target.value);
    } else if (e.target.value == "Delivered") {
      setSelectedOption(e.target.value);
    } else if (e.target.value == "confirm") {
      setSelectedOption(e.target.value);
    } else if (e.target.value == "cancel") {
      setSelectedOption(e.target.value);
    }
  };

  const handleSubmit = (e) => {
    setIsSubmit(true);
    let obj = {
      orderId,
      businessname,
      selectedOption,
    };
    axios
      .put(`${baseUrl}api/v1/business/updateorderstatus`, obj, {
        headers: {
          _token: userData?.token,
        },
        withCredentials: true,
      })
      .then((response) => {

        // window.location.reload(5000);
        toast.success(response?.data?.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        whatsappclick()
        fetchOrders()
        setIsSubmit(false);
      })
      .catch((error) => {
        console.log(error, "Appointment response");
        setIsSubmit(false);
      });
  };

  const whatsappclick = () => {
    const phoneNumber = contactNo;
    // const phoneNumber = "8929099255";
    let messageBody = '';

    switch (selectedOption) {
      case "confirm":
        messageBody = `Dear User, 
Your order has been confirmed.`;
        break;
      case "cancel":
        messageBody = `Dear User, 
Your order has been canceled.
We apologize for any inconvenience caused.`;
        break;
      case "Delivered":
        messageBody = `Dear User, 
Your order has been delivered.`;
        break;
      default:
        messageBody = "Your order status has been updated.";
    }
    const message = encodeURIComponent(messageBody);

    const apiUrl = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${message}`;
    window.open(apiUrl, '_blank');

  };

  const handleChangeBusinessData = (e) => {
    setActiveBusinessName(e.target.value);
  };


  useEffect(() => {
    if (selectedDateTo != "" && selectedDateFrom != "") {
      if (selectedDateTo < selectedDateFrom) {
        toast.warning("To Date should be equal to or greater then From Date", {
          position: toast.POSITION.TOP_RIGHT,
        });
        return;
      }
    }
    const filters = {
      businessId: activeBusinessName === "All Business" ? null : activeBusinessName,
      startDate: selectedDateFrom,
      endDate: selectedDateTo,
    };
    fetchOrders(1, filters);
  }, [activeBusinessName, selectedDateFrom, selectedDateTo]);

  const handleSearch = () => {
    loadPageFilter()
    setShowFilter(false);
  };

  const loadPageFilter = () => {
    const nextPage = 1
    setCurrentPage(nextPage);
    fetchOrders(nextPage)
  };

  return (
    <>
      <PageName PageName="Orders" />
      <article>
        <div className="row d-none">
          <div className="col-md-12">
            <div className="tile">
              <div className="tile-body">
                <form className="row">
                  <div className="mb-2 col-md-3">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Search....."
                    />
                  </div>
                  <div className="mb-2 col-md-3">
                    <select className="form-select">
                      <option value="">Select an option</option>
                      <option value="option1">xxx </option>
                      <option value="option2">xxxx</option>
                    </select>
                  </div>
                  <div className="mb-2 col-md-4 align-self-end">
                    <button className="btn btn-primary me-3" type="button">
                      <i className="bi bi-check-circle-fill ms-2 "></i>Search
                    </button>
                    <button className="btn btn-danger" type="button">
                      <i className="bi bi-check-circle-fill me-2"></i>Reset
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="main">
          {isLoading ? (
            <div
              style={{
                position: "absolute",
                top: "50%",
                left: "55%",
                display: "flex",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <CircularLoader size={40} />
            </div>
          ) : (

            <>
              <div className="" style={{ position: 'relative', top: '-64px', width: '70%', left: '20px' }}>

              </div>

              <div className="filter-button-wrapper requestcardfilterbuttonWrapper">
                <span class="FilterIconTextWrapper" onClick={handleFilterOpen}>
                  {" "}
                  <div className="FilterIcon">
                    {" "}
                    <img src={FilterIcon} width={28} />
                    <div className="filtertext">Filters</div>
                  </div>
                </span>
                {/* <button
                  className="btn btn-primary requestcardfilterbuttonstyle"
                  onClick={handleFilterOpen}
                  id="FilterIconText"
                >
                  Filter
                </button> */}
              </div>
              <Modal
                open={showFilter}
                onClose={handleFilterClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={Boxstyle}>
                  {showFilter && (
                    <div className="Contact_filter_fieldbutton_wrapper ModalPopupContainer">
                      <div className="Contact_filter_field_wrapper ModalPopupWrapper">
                        <div className="col-md-2 Contact_filter_Inputfield" id="ModalSelectfield">
                          <select
                            value={activeBusinessName}
                            onChange={(e) => handleChangeBusinessData(e)}
                            className="form-select"
                          >
                            {" "}
                            <option value={"All Business"}>All Business</option>
                            {businessData &&
                              businessData.map((val, index) => (
                                <option value={val}>{val} </option>
                              ))}
                          </select>
                          <div
                            style={{
                              fontSize: "14px",
                              color: "red",
                              bottom: "70px",
                            }}
                          ></div>
                        </div>


                        <div className="col-md-2 Contact_filter_Inputfield" id="ModalSelectfield">
                          <div className="dateiconmain">
                            <DatePicker
                              placeholderText="From date"
                              selected={selectedDateFrom}
                              onChange={(date) => setSelectedDateFrom(date)}
                              className="form-control"
                              ref={fromDate}
                              dateFormat="dd/MM/yyyy"
                              popperPlacement="bottom-end"
                            />
                            <div
                              className="contact_filter_toDate_calander_icon"
                              onClick={() => fromDate.current.setOpen(true)}
                            >
                              <i className="fa-regular fa-calendar"></i>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-2 Contact_filter_Inputfield" id="ModalSelectfield">
                          <div className="dateiconmain">
                            <DatePicker
                              placeholderText="To Date"
                              selected={selectedDateTo}
                              onChange={(date) => setSelectedDateTo(date)}
                              className="form-control"
                              ref={toDate}
                              dateFormat="dd/MM/yyyy"
                              popperPlacement="bottom-end"
                            />
                            <div
                              className="contact_filter_toDate_calander_icon"
                              onClick={() => toDate.current.setOpen(true)}
                            >
                              <i className="fa-regular fa-calendar"></i>
                            </div>
                          </div>
                        </div>
                        <div className="contact_filter_buttonwrapper">

                          <button
                            className="contact_filter_buttonstyle"
                            onClick={() => handleReset()}
                          >
                            Reset{" "}
                          </button>
                          <button
                            class="contact_filter_buttonstyle"
                            onClick={() => handleSearch()}
                          >
                            Search
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </Box>
              </Modal>

              <div className="scrollable-columns">
                <div className="sticky-header-container">
                  <DataTableExtensions
                    {...tableData}
                    {...tableExtensions}
                    customStyles={customStyles}
                    filterPlaceholder="Search Orders"
                  >
                    <DataTable
                      columns={columns}
                      data={data}
                      noHeader
                      defaultSortField="id"
                      sortIcon={<SortIcon />}
                      defaultSortAsc={true}
                      pagination
                      highlightOnHover
                      paginationServer
                      paginationTotalRows={paginationTotalRows}
                      paginationPerPage={paginationPerPage}
                      paginationRowsPerPageOptions={[10, 20, 50, 100, 300, 1000, 10000]} // Customize as needed
                      onChangePage={handlePageChange}
                      onChangeRowsPerPage={handleRowsPerPageChange}
                      onSelectedRowsChange={handleOnSelectedRowsChange}
                      dense
                      customStyles={customStyles}
                    />
                  </DataTableExtensions>
                </div>
              </div>
            </>
          )}
        </div>
      </article>
      <div
        className="modal fade"
        id="planselect"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-md">
          <div className="modal-content EditStatus_form">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Edit Status
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <form onSubmit={handleUpdate}>
                <div className="row g-3 mb-3">
                  <div className="col d-none">
                    <textarea
                      className="form-control"
                      placeholder="Enter Remarks"
                      aria-label="Name"
                    ></textarea>
                  </div>
                  <div className="col " >
                    <select
                      name="type"
                      className="form-select register_inputfield"
                      value={selectedOption}
                      onChange={handleDropdownChange}
                    >
                      <option value="pending">Pending</option>
                      <option value="confirm">Confirm </option>
                      <option value="cancel">Cancel</option>
                      <option value="Delivered">Delivered </option>
                    </select>
                  </div>
                  <div className="col d-none">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Plan Name"
                      aria-label="Name"
                      name="txn_id"
                      onChange={(e) => console.log("w")}
                    />
                  </div>
                </div>
                <div class="ChangesEditform">
                  <button
                    type="button"
                    className="btn btn-primary save-cta"
                    data-bs-dismiss="modal"
                    onClick={(e) => handleSubmit(e)}
                  >
                    {isSubmit ? <CircularLoader size={20} /> : " Save changes"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Restaurantorders;
