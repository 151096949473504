import React, { useRef, useContext, useEffect, useState } from "react";
import ProfilPic from "../../../Images/ResumeProfile.png";
import PhoneIcon from "../../../Images/Resume2phoneIcon.png";
import LinkedInIcon from "../../../Images/Resume2linkedInIcon.png";
import MapIcon from "../../../Images/Resume2mapIcon.png";
import MailIcon from "../../../Images/Resume2mailIcon.png";
import ShareVector from "./../../../Images/BlackShare.png";
import {
  AchivementsUl,
  AchivementsWrapper,
  ActivementsList,
  CertificationContainerWrapper,
  CertificationName,
  CertificationValue,
  CertificatoinContainerWrapper,
  CompanyLocationName,
  CompanyLocationWrapper,
  Container,
  Date,
  DateCompanyLocationWrapper,
  DateWrapper,
  Designation,
  DesignationWrapper,
  EducationSingleContainer,
  EducationWrapper,
  Experties,
  ExpertiesContainerWrapper,
  ExpertiexListWrapper,
  HobbiesContainerWrapper,
  HorizontalLine,
  HorizontalLineWrapper,
  Image,
  Imagewrapper,
  KeySkillsContainerWrapper,
  LeftContainer,
  OneProject,
  PassingYear,
  PassingYearWrapper,
  PersonalDetailContainerWrapper,
  PersonalDetailsWrapper,
  PersonalInfoWrapper,
  PersonalInformationWrapper,
  PersonalName,
  PersonalNameValue,
  PersonalWrapper,
  PersonalWrapperValue,
  PhoneIconTextWrapper,
  PhoneText,
  PhoneTextValueWrapper,
  PhoneValue,
  PhoneWrapper,
  ProfileHeaading,
  ProfileHeadingWrapper,
  ProfileSummary,
  ProfileSummaryWrapper,
  ProjectLink,
  ProjectLinkWrapper,
  ProjectWrapperContainer,
  ResumeLeftRightContainer,
  ResumeLeftWrapper,
  ResumeRightWrapper,
  RightContainer,
  Root,
  SchoolUniversity,
  SchoolUniversityBordName,
  SchoolUniversityWrapper,
  SingleWorkExperience,
  UserDesignation,
  UserDesignationWrapper,
  UserProfileDestinationWrapper,
  UserProfileNameWrapper,
  Username,
  WorkExperienceBoldHeadingWrapper,
  WorkExperienceHeading,
  WorkexperienceSummary,
  WorkexperienceSummaryWrapper,
} from "./Resume2ExtStyle";
import { userContext } from "../../../context/userContext";
import { environmentVariables } from "../../../config/env.config";
import { IconContainer, Power, PowerSpan, PoweredWrapper, ShareButtonWrapper, ShareIcon, SingleIcon, SingleIconWrapper } from "./StyleThemejs";
import { EmailIcon, LinkedinIcon, TelegramIcon, WhatsappIcon } from "react-share";

const Resume2Ext = (props) => {
  const { userData } = useContext(userContext);
  const [educationData, setEducationData] = useState([]);
  const [projectData, setProjectData] = useState([]);
  const [certificateData, setCertificateData] = useState([]);
  const [skillData, setSkillData] = useState([]);
  const [additionalData, setAdditionalData] = useState([]);
  const [achievementData, setAchievementData] = useState([]);
  const [workExpData, setworkExpData] = useState([]);
  const [toolData, settoolData] = useState([]);
  const [language, setLanguage] = useState([]);
  const [showShareOptions, setShowShareOptions] = useState(false);
  const [showEmailShare, setShowEmailShare] = useState(false);
  const baseUrl = environmentVariables?.apiUrl;

  const iconContainerRef = useRef(null);

  useEffect(() => {
    if (props?.resumeData?.educationcontent) {
      setEducationData(JSON.parse(props.resumeData.educationcontent));
    }
    if (props?.resumeData?.projectcontent) {
      setProjectData(JSON.parse(props.resumeData.projectcontent));
    }
    if (props?.resumeData?.certificationcontent) {
      setCertificateData(JSON.parse(props.resumeData.certificationcontent));
    }
    if (props?.resumeData?.skillscontent) {
      setSkillData(JSON.parse(props.resumeData.skillscontent));
    }
    if (props?.resumeData?.additionaldetailscontent) {
      setAdditionalData(JSON.parse(props.resumeData.additionaldetailscontent));
    }
    if (props?.resumeData?.toolscontent) {
      settoolData(JSON.parse(props.resumeData.toolscontent));
    }
    if (props?.resumeData?.languagecontent) {
      setLanguage(JSON.parse(props.resumeData.languagecontent));
    }
    if (props?.resumeData?.wrkExpcontent) {
      setworkExpData(JSON.parse(props.resumeData.wrkExpcontent));
    }
    if (props?.resumeData?.achievementcontent) {
      setAchievementData(JSON.parse(props.resumeData.achievementcontent));
    }
  }, [props.resumeData]);

  const handleClickOutside = (event) => {
    if (iconContainerRef.current && !iconContainerRef.current.contains(event.target)) {
      setShowShareOptions(false);
    }
  };
  useEffect(() => {
    document.addEventListener("click", handleClickOutside, false);
    return () => {
      document.removeEventListener("click", handleClickOutside, false);
    };
}, []);

  const getImage = (image) => {
    if (image && image.includes("base64")) {
      return image;
    } else if (image) {
      return `${environmentVariables?.apiUrl}uploads/resume/${image}`;
    } else {
      return "";
    }
  };

  const formatDate = (dateString) => {
    if (!dateString) return "";

    const date = new window.Date(dateString);
    const options = { month: "long", year: "numeric" };
    return date.toLocaleDateString("en-US", options);
  };

  const handleShare = () => {
    setShowShareOptions(!showShareOptions);
  };

  const handleShareOnPlatform = (platform) => {
    const resumePreviewLink = window.location.href;
    const message = "Check out my resume!";
    const fullMessage = `${message} ${resumePreviewLink}`;
    let shareUrl = '';

    switch (platform) {
      case 'linkedin':
        // shareUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(resumePreviewLink)}`;
        // break;
        navigator.clipboard.writeText(fullMessage).then(() => {
          alert("Message copied to clipboard! Please paste it in LinkedIn messages.");
          window.open("https://www.linkedin.com/messaging", "_blank"); // Opens LinkedIn Messaging
        }).catch(err => {
          console.error('Could not copy text: ', err);
        });
        break;
      case 'whatsapp':
        shareUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(resumePreviewLink)}`;
        break;
      case 'telegram':
        shareUrl = `https://t.me/share/url?url=${encodeURIComponent(resumePreviewLink)}`;
        break;
      case 'email':
        shareUrl = `mailto:?subject=Checkoutmyresume&body=${encodeURIComponent(resumePreviewLink)}`;
        // setShowEmailShare(true);
        break;
      // case 'facebook':

      //   break;
      default:
        break;
    }

    if (shareUrl) {
      window.open(shareUrl, "_blank");
    }
  };

  const renderSkills = () => {
    if (skillData?.length) {
      return (
        <KeySkillsContainerWrapper>
          <WorkExperienceBoldHeadingWrapper>
            <WorkExperienceHeading>Key Skills</WorkExperienceHeading>
            <HorizontalLineWrapper>
              <HorizontalLine />
            </HorizontalLineWrapper>
          </WorkExperienceBoldHeadingWrapper>
          <ExpertiexListWrapper>
            {skillData.map((skill, index) => (
              <Experties key={index}>{skill?.skillname}</Experties>
            ))}
          </ExpertiexListWrapper>
        </KeySkillsContainerWrapper>
      );
    } else {
      return null;
    }
  };

  const renderPrsnldetails = () => {
    return props?.resumeData?.is_prsnldetail_is_enabled === 1 ? (
      <PersonalInformationWrapper>
        <WorkExperienceBoldHeadingWrapper>
          <WorkExperienceHeading>PERSONAL INFORMATION</WorkExperienceHeading>
          <HorizontalLineWrapper>
            <HorizontalLine />
          </HorizontalLineWrapper>
        </WorkExperienceBoldHeadingWrapper>

        <PersonalInfoWrapper>
          {props?.resumeData?.fathername && (
          <PersonalWrapper>
            <PersonalName>Father/ Spouse Name:</PersonalName>
          </PersonalWrapper>
          )}
          <PersonalWrapperValue>
            <PersonalNameValue>
           {props?.resumeData?.fathername}
            </PersonalNameValue>
          </PersonalWrapperValue>
        </PersonalInfoWrapper>

        <PersonalInfoWrapper>
          {props?.resumeData?.maritalstatus && (
          <PersonalWrapper>
            <PersonalName>Marital Status:</PersonalName>
          </PersonalWrapper>
          )}
          <PersonalWrapperValue>
            <PersonalNameValue>
              {props?.resumeData?.maritalstatus}
            </PersonalNameValue>
          </PersonalWrapperValue>
        </PersonalInfoWrapper>

        <PersonalInfoWrapper>
          {props?.resumeData?.userdob && (
          <PersonalWrapper>
          <PersonalName>DOB:</PersonalName>
        </PersonalWrapper>
          )}
        
          <PersonalWrapperValue>
            <PersonalNameValue>{props?.resumeData?.userdob}</PersonalNameValue>
          </PersonalWrapperValue>
        </PersonalInfoWrapper>

        {/* Add more PersonalInfoWrapper sections for additional personal details as needed */}
      </PersonalInformationWrapper>
    ) : null;
  };

  const renderAdditionalDetail = () => {
    if (additionalData?.length) {
      return (
        <HobbiesContainerWrapper>
          <WorkExperienceBoldHeadingWrapper>
            <WorkExperienceHeading>hobbies</WorkExperienceHeading>
            <HorizontalLineWrapper>
              <HorizontalLine />
            </HorizontalLineWrapper>
          </WorkExperienceBoldHeadingWrapper>
          <ExpertiexListWrapper>
            {additionalData.map((details, index) => (
              <Experties key={index}>{details?.skillname}</Experties>
            ))}
          </ExpertiexListWrapper>
        </HobbiesContainerWrapper>
      );
    } else {
      return null;
    }
  };

  const renderAchievement = () => {
    if (achievementData?.length) {
      return (
        <AchivementsWrapper>
          <WorkExperienceBoldHeadingWrapper>
            <WorkExperienceHeading>achievements</WorkExperienceHeading>
            <HorizontalLineWrapper>
              <HorizontalLine />
            </HorizontalLineWrapper>
          </WorkExperienceBoldHeadingWrapper>
          <AchivementsUl>
            {achievementData.map((details, index) => (
              <ActivementsList key={index}>
                {details?.skillname}
              </ActivementsList>
            ))}
          </AchivementsUl>
        </AchivementsWrapper>
      );
    } else {
      return null;
    }
  };

  const renderProjects = () => {
    if (projectData?.length) {
      return (
        <ProjectWrapperContainer>
          <ProfileHeadingWrapper>
            <ProfileHeaading>Project</ProfileHeaading>
            <HorizontalLineWrapper>
              <HorizontalLine />
            </HorizontalLineWrapper>
          </ProfileHeadingWrapper>

          {projectData.map((project, index) => (
            <OneProject key={index}>
              <DateCompanyLocationWrapper>
                <DateWrapper>
                  <Date>
                    {`${formatDate(project?.startDate)?.substr(
                      0,
                      3
                    )} ${formatDate(project?.startDate)?.substr(-4)} - ${project.currentlyWorking
                        ? "Current"
                        : `${formatDate(project?.endDate)?.substr(
                          0,
                          3
                        )} ${formatDate(project?.endDate)?.substr(-4)}`
                      }`}
                  </Date>
                </DateWrapper>
                <CompanyLocationWrapper>
                  <CompanyLocationName>
                    {project.projectname}
                  </CompanyLocationName>
                </CompanyLocationWrapper>
              </DateCompanyLocationWrapper>

              <WorkexperienceSummaryWrapper>
                <WorkexperienceSummary>
                  {project?.projectdescription}
                </WorkexperienceSummary>
              </WorkexperienceSummaryWrapper>

              <ProjectLinkWrapper>
                <ProjectLink href={project?.projectlink} target="_blank">
                  {project?.projectlink}
                </ProjectLink>
              </ProjectLinkWrapper>
            </OneProject>
          ))}
        </ProjectWrapperContainer>
      );
    } else {
      return null;
    }
  };

  const renderCertification = () => {
    if (certificateData?.length) {
      return (
        <CertificationContainerWrapper>
          <WorkExperienceBoldHeadingWrapper>
            <WorkExperienceHeading>Certifications</WorkExperienceHeading>
            <HorizontalLineWrapper>
              <HorizontalLine />
            </HorizontalLineWrapper>
          </WorkExperienceBoldHeadingWrapper>

          {certificateData.map((certificate, index) => (
            <div key={index}>
              <DesignationWrapper>
                <Designation>{certificate?.certificatename}</Designation>
              </DesignationWrapper>
              <CertificationName>
                {certificate?.organisationname}
              </CertificationName>
              <CertificationValue>
                {certificate?.certificateidnumber}
              </CertificationValue>
              <CertificationValue>
                {`${formatDate(certificate?.date)?.substr(0, 3)} ${formatDate(
                  certificate?.date
                )?.substr(-4)} - ${certificate.currentlyWorking
                    ? "Current"
                    : `${formatDate(certificate?.enddate)?.substr(
                      0,
                      3
                    )} ${formatDate(certificate?.enddate)?.substr(-4)}`
                  }`}
              </CertificationValue>
            </div>
          ))}
        </CertificationContainerWrapper>
      );
    } else {
      return null;
    }
  };

  return (
    <Root>
      <Container style={{ position: "relative" }}>
        <ShareButtonWrapper style={{}} onClick={handleShare} ref={iconContainerRef}>
          <ShareIcon src={ShareVector} />
        </ShareButtonWrapper>
        {showShareOptions && (
          <IconContainer className="share-options-modal" style={{ background: "#F8F8EC" }}>
            <SingleIconWrapper className="share-options-list">
              <SingleIcon onClick={() => handleShareOnPlatform('telegram')}>
                <TelegramIcon size={35} round />
              </SingleIcon>
              <SingleIcon onClick={() => handleShareOnPlatform('email')}>
                <EmailIcon size={35} round />
              </SingleIcon>
              <SingleIcon onClick={() => handleShareOnPlatform('linkedin')}>
                <LinkedinIcon size={35} round />
              </SingleIcon>
              <SingleIcon onClick={() => handleShareOnPlatform('whatsapp')}>
                <WhatsappIcon size={35} round />
              </SingleIcon>
            </SingleIconWrapper>
          </IconContainer>
        )}
        <ResumeLeftRightContainer style={{ paddingTop: "30px" }}>

          <ResumeLeftWrapper>
            <LeftContainer>
              <Imagewrapper>
                <Image
                  // src={ProfilPic}
                  src={getImage(props.resumeData.logo)}
                />
                <UserProfileDestinationWrapper>
                  <UserProfileNameWrapper>
                    <Username>
                      {props?.resumeData?.fullName || "Akshay"}
                    </Username>
                  </UserProfileNameWrapper>
                  <UserDesignationWrapper>
                    <UserDesignation>
                      {props?.resumeData?.designation || "Nodejs"}
                    </UserDesignation>
                  </UserDesignationWrapper>
                </UserProfileDestinationWrapper>
              </Imagewrapper>

              <PersonalDetailContainerWrapper>
                <PersonalDetailsWrapper>
                  <PhoneIconTextWrapper>
                    <PhoneWrapper src={PhoneIcon} />
                  </PhoneIconTextWrapper>
                  <PhoneTextValueWrapper>
                    <PhoneText>Phone</PhoneText>
                    <PhoneValue>{props?.resumeData?.phone}</PhoneValue>
                  </PhoneTextValueWrapper>
                </PersonalDetailsWrapper>

                <PersonalDetailsWrapper>
                  <PhoneIconTextWrapper>
                    <PhoneWrapper src={MailIcon} />
                  </PhoneIconTextWrapper>
                  <PhoneTextValueWrapper>
                    <PhoneText>Email</PhoneText>
                    <PhoneValue>{props?.resumeData?.email}</PhoneValue>
                  </PhoneTextValueWrapper>
                </PersonalDetailsWrapper>
                {props?.resumeData?.city && (
                  <PersonalDetailsWrapper>
                                  <PhoneIconTextWrapper>
                                    <PhoneWrapper src={MapIcon} />
                                  </PhoneIconTextWrapper>
                                  <PhoneTextValueWrapper>
                                    <PhoneText>Location</PhoneText>
                                    <PhoneValue> {props?.resumeData?.city}</PhoneValue>
                                  </PhoneTextValueWrapper>
                  </PersonalDetailsWrapper>
                )}
                
                {props?.resumeData?.linkedIn && (
                  <PersonalDetailsWrapper>
                                  <PhoneIconTextWrapper>
                                    <PhoneWrapper src={LinkedInIcon} />
                                  </PhoneIconTextWrapper>
                                  <PhoneTextValueWrapper>
                                    <PhoneText>Social Media Link</PhoneText>
                                    <a
                                      href={props?.resumeData?.linkedIn}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      <PhoneValue style={{ cursor: "pointer" }}> {props?.resumeData?.linkedIn} </PhoneValue>
                                    </a>
                                  </PhoneTextValueWrapper>
                                </PersonalDetailsWrapper>
                )}
                
              </PersonalDetailContainerWrapper>

              {props?.resumeData?.is_tools_is_enabled === 1 && (
                <ExpertiesContainerWrapper>
                  <WorkExperienceBoldHeadingWrapper>
                    <WorkExperienceHeading>Expertise</WorkExperienceHeading>
                    <HorizontalLineWrapper>
                      <HorizontalLine />
                    </HorizontalLineWrapper>
                  </WorkExperienceBoldHeadingWrapper>

                  <ExpertiexListWrapper>
                    {toolData.map((tool, index) => (
                      <Experties key={index}>{tool?.toolname}</Experties>
                    ))}
                  </ExpertiexListWrapper>
                </ExpertiesContainerWrapper>
              )}

              {props?.resumeData?.is_skills_is_enabled === 1 && renderSkills()}
              {props?.resumeData?.is_certification_is_enabled === 1 &&
                renderCertification()}

              {props?.resumeData?.is_language_is_enabled === 1 && (
                <ExpertiesContainerWrapper>
                  <WorkExperienceBoldHeadingWrapper>
                    <WorkExperienceHeading>Languages</WorkExperienceHeading>
                    <HorizontalLineWrapper>
                      <HorizontalLine />
                    </HorizontalLineWrapper>
                  </WorkExperienceBoldHeadingWrapper>

                  <ExpertiexListWrapper>
                    {language.length > 0
                      ? language.map((language, index) => (
                        <Experties key={index}>
                          {language?.languagename}
                        </Experties>
                      ))
                      : null}
                  </ExpertiexListWrapper>
                </ExpertiesContainerWrapper>
              )}

              {props?.resumeData?.isEnabledAdddetails === 1 &&
                renderAdditionalDetail()}
            </LeftContainer>
          </ResumeLeftWrapper>
          <ResumeRightWrapper>
            <RightContainer>
              {props?.resumeData?.summary && (
                  <ProfileHeadingWrapper Profile>
                  <ProfileHeaading>Profile</ProfileHeaading>
                  <HorizontalLineWrapper>
                    <HorizontalLine />
                  </HorizontalLineWrapper>
                </ProfileHeadingWrapper>
                )}
                
              <ProfileSummaryWrapper>
                <ProfileSummary>{props?.resumeData?.summary}</ProfileSummary>
              </ProfileSummaryWrapper>

              {props?.resumeData?.is_wrkExp_is_enabled === 1 && (
                <>
                  <WorkExperienceBoldHeadingWrapper>
                    <WorkExperienceHeading>
                      Work Experience
                    </WorkExperienceHeading>
                    <HorizontalLineWrapper>
                      <HorizontalLine />
                    </HorizontalLineWrapper>
                  </WorkExperienceBoldHeadingWrapper>

                  {workExpData?.length ? (
                    workExpData.map((exp, index) => (
                      <SingleWorkExperience key={index}>
                        <DesignationWrapper>
                          <Designation>{exp?.jobtitle}</Designation>
                        </DesignationWrapper>

                        <DateCompanyLocationWrapper>
                          <DateWrapper>
                            <Date>
                              {`${formatDate(exp?.startdate)?.substr(
                                0,
                                3
                              )} ${formatDate(exp?.startdate)?.substr(-4)} - ${exp.currentlyWorking
                                  ? "Currently Working"
                                  : `${formatDate(exp?.enddate)?.substr(
                                    0,
                                    3
                                  )} ${formatDate(exp?.enddate)?.substr(-4)}`
                                }`}
                            </Date>
                          </DateWrapper>
                          <CompanyLocationWrapper>
                            <CompanyLocationName>
                              {exp?.companyname}
                            </CompanyLocationName>
                          </CompanyLocationWrapper>
                        </DateCompanyLocationWrapper>

                        <WorkexperienceSummaryWrapper>
                          <WorkexperienceSummary>
                            {exp?.workSummary}
                          </WorkexperienceSummary>
                        </WorkexperienceSummaryWrapper>
                      </SingleWorkExperience>
                    ))
                  ) : (
                    <></>
                  )}
                </>
              )}

              {props?.resumeData?.is_project_is_enabled === 1 &&
                renderProjects()}

            

              {props?.resumeData?.is_education_is_enabled === 1 && (
                <EducationWrapper>
                  <WorkExperienceBoldHeadingWrapper>
                    <WorkExperienceHeading>Education</WorkExperienceHeading>
                    <HorizontalLineWrapper>
                      <HorizontalLine />
                    </HorizontalLineWrapper>
                  </WorkExperienceBoldHeadingWrapper>

                  {educationData?.length ? (
                    educationData.map((item, index) => (
                      <EducationSingleContainer key={index}>
                        <DesignationWrapper DesignationName>
                          <Designation>{item.degree}</Designation>
                        </DesignationWrapper>
                        <SchoolUniversityWrapper>
                          <SchoolUniversity>{item.schuniname}</SchoolUniversity>
                          <SchoolUniversityBordName>
                            {item.boardname}
                          </SchoolUniversityBordName>
                          <PassingYearWrapper>
                            <PassingYear>
                              {`${formatDate(item.graduationyear)?.substr(
                                0,
                                3
                              )} ${formatDate(item.graduationyear)?.substr(
                                -4
                              )} - ${item.currentlyWorking
                                  ? "Current"
                                  : `${formatDate(
                                    item.graduationendyear
                                  )?.substr(0, 3)} ${formatDate(
                                    item.graduationendyear
                                  )?.substr(-4)}`
                                }`}
                            </PassingYear>
                          </PassingYearWrapper>
                        </SchoolUniversityWrapper>
                      </EducationSingleContainer>
                    ))
                  ) : (
                    <></>
                  )}
                </EducationWrapper>
              )}

              {props?.resumeData?.is_achievement_is_enabled === 1 &&
                renderAchievement()}

              {props?.resumeData?.is_prsnldetail_is_enabled === 1 &&
                renderPrsnldetails()}
            </RightContainer>
          </ResumeRightWrapper>
        </ResumeLeftRightContainer>

        <PoweredWrapper>
          <Power>
            Powered by <PowerSpan>BusinessBay</PowerSpan>
          </Power>
        </PoweredWrapper>
      </Container>
    </Root>
  );
};

export default Resume2Ext;
