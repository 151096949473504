import React, { useContext, useEffect, useRef, useState } from "react";
import PageName from "../Utils/PageName";
import { Link, useSearchParams } from "react-router-dom";
import axios from "axios";
import { userContext } from "../../context/userContext";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { environmentVariables } from "../../config/env.config";

import ReactDOM from "react-dom";
import DataTable from "react-data-table-component";
import SortIcon from "@material-ui/icons/ArrowDownward";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import { businessContext } from "../../context/businessContext";
import CircularLoader from "../Components/CircularLoader/CircularLoader";
// import CircularLoader from "../../Backend/Components/CircularLoader/CircularLoader";

function AllUser() {
  const { userData } = useContext(userContext);
  const [isLoader, setIsloader] = useState(true);

  const createUserRef = useRef(null);
  const [openUserModal, setOpenUserModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const openModal = () => {
    // console.log(createUserRef?.current?.style?.display);
  };
  const closeModal = () => {
    // console.log(createUserRef?.current?.style?.display);
    createUserRef.current.style.display = "none";
  };
  const [userCreateData, setUserCreateData] = useState({
    name: "",
    email: "",
    password: "",
    roleId: 0,
  });
  const [roleData, setRoleData] = useState(null);
  const [businessData, setBusinessData] = useState("");
  const [usersData, setUsersData] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [searchOption, setSearchOption] = useState("-1");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const baseUrl = environmentVariables?.apiUrl;

  // console.log(businessData, "businessDatabusinessData");

  const [usersDataSearch, setUsersDataSearch] = useState(null);
  const [usersDataApi, setUsersDataApi] = useState(null);
  const [userId, setUserId] = useState(null);
  const [errorMsg, setErrorMsg] = useState(null);
  const [businesslistData, setBusinesslistData] = useState([]);

  const handleChangeUserData = (e, type) => {
    setUserCreateData({ ...userCreateData, [`${type}`]: e.target.value });
  };
  const handleEditUser = (id) => {
    if (usersData?.length > 0) {
      const editUserData = usersData.filter((val) => val?.id == id);
      setUserId(id);
      setUserCreateData({
        name: editUserData[0]?.name,
        email: editUserData[0]?.email,
        // password: "",
        roleId: editUserData[0]?.role_id,
      });
    } else {
      alert("users not available");
    }
  };
  const searchByRoleId = () => {
    setUsersData(usersDataApi);
    if (searchOption != "-1" && searchInput == "") {
      const newUsersData = usersDataApi.filter(
        (item) => item?.role_id == searchOption
      );
      setUsersData(newUsersData);
      // item?.role_name.toLowerCase().includes(searchInput?.toLowerCase())
    } else if (searchInput != "" && searchOption == "-1") {
      const newUsersData = usersDataApi.filter((item) =>
        item?.name.toLowerCase().includes(searchInput?.toLowerCase())
      );
      setUsersData(newUsersData);
    } else if (searchInput != "" && searchOption != "1") {
      const newUsersData = usersDataApi.filter(
        (item) => item?.role_id == searchOption
      );
      const newUsersData2 = newUsersData.filter((item) =>
        item?.name.toLowerCase().includes(searchInput?.toLowerCase())
      );
      setUsersData(newUsersData2);
    } else {
      setUsersData(usersDataApi);
    }
  };
  const handleDeleteUser = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        let data = JSON.stringify({
          userId: id,
        });

        let config = {
          method: "delete",
          maxBodyLength: Infinity,
          url: `${environmentVariables?.apiUrl}api/v1/admin/deleteUser`,
          headers: {
            _token: userData?.token,
            "Content-Type": "application/json",
          },
          // withCredentials: true,
          data: data,
        };

        axios
          .request(config)
          .then((response) => {
            Swal.fire("Deleted!", "Selected user has been deleted.", "success");
            getUsersData();
          })
          .catch((error) => {
            Swal.fire(
              "Error!",
              error?.response?.data?.message || error?.message,
              "error"
            );
          });
      }
    });
  };
  const handleSubmit = () => {
    if (
      userCreateData["name"] !== "" &&
      userCreateData["email"] !== "" &&
      userCreateData["password"] !== "" &&
      userCreateData["roleId"] !== 0
    ) {
      let data = JSON.stringify(userCreateData);

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${environmentVariables?.apiUrl}api/v1/user/createUser`,
        headers: {
          _token: userData?.token,
          "Content-Type": "application/json",
        },
        // withCredentials: true,
        data: data,
      };

      axios
        .request(config)
        .then((response) => {
          toast.success("User created", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
        })
        .catch((error) => {
          toast.error(error?.response?.data?.message || error?.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });

          setErrorMsg(null);
        });
    } else {
      setErrorMsg("Please fill all the data");
    }
  };
  const isValidEmail = (email) => {
    const emailRegex = /^[A-Za-z0-9._%-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/;
    return emailRegex.test(email);
  };
  const handleEditSubmit = () => {
    if (!isValidEmail(userCreateData.email)) {

      toast.error("Invalid email address. Please enter a valid email.", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
      return;
    }

    let data;
    if (
      userCreateData["password"] == "" ||
      userCreateData["password"] == null ||
      userCreateData["password"] == undefined ||
      userCreateData["password"].trim() == ""
    ) {
      data = JSON.stringify({
        roleId: `${userCreateData["roleId"]}`,
        name: userCreateData["name"],
        email: userCreateData["email"],
        userId: `${userId}`,
      });
    } else {
      data = {
        roleId: userCreateData["roleId"].toString(),
        name: userCreateData["name"],
        password: userCreateData["password"],
        email: userCreateData["email"],
        userId: userId.toString(),
      };
    }

    let config = {
      method: "put",
      maxBodyLength: Infinity,
      url: `${environmentVariables?.apiUrl}api/v1/user/updateUser`,
      headers: {
        _token: userData?.token,
        "Content-Type": "application/json",
      },
      // withCredentials: true,
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        toast.success("User updated", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        getUsersData();
        if (typeof bootstrap !== "undefined" && bootstrap.Modal) {
          const modal = new bootstrap.Modal(createUserRef.current);
          modal.hide(); // Close the modal
        }
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message || error?.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      });
  };
  const clearData = () => {
    setUserCreateData({
      name: "",
      email: "",
      password: "",
      roleId: 0,
    });
  };
  const getRoleData = () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${environmentVariables?.apiUrl}api/v1/role/rolesPermissionList`,
      headers: {
        _token: userData?.token,
      },
      // withCredentials: true,
    };
    axios
      .request(config)
      .then((response) => {
        // console.log(response?.data?.data);
        setRoleData(response?.data?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getUsersData = () => {
    const params = {
      // page: currentPage,
      // limit: 20,
      // filterField: "createdAt",
      // filterValue: "Last 30 Days",
    };

    // console.log(userData.token, "usertokn")
    // console.log(userData.token,"usertokn")

    // Convert the parameters object into a query string
    // const queryString = new URLSearchParams(params).toString();
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${environmentVariables?.apiUrl}api/v1/admin/allUsers`,
      headers: {
        _token: userData?.token,
      },
      // withCredentials: true,
    };

    axios
      .request(config)
      .then((response) => {
        // console.log(response?.data?.result, "getUsersDatagetUsersData");
        setUsersData(response?.data?.result);
        setUsersDataSearch(response?.data?.result?.rows);
        setUsersDataApi(response?.data?.result?.rows);
        setIsLoading(false)
        setIsloader(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const goToPreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
      getUsersData();
    }
    // console.log("pll", currentPage);
  };
  const goToNextPage = () => {
    // console.log("pit raha ", currentPage);
    // You can add a condition to prevent going beyond the last page if needed
    setCurrentPage(currentPage + 1);
    getUsersData();
  };
  const fetchBusinessData = async (userid) => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${environmentVariables?.apiUrl}api/v1/business/fetchBusiness?id=${userid}`,
      headers: {
        _token: userData?.token,
      },
      // withCredentials: true,
    };

    axios
      .request(config)
      .then((response) => {
        // console.log(response?.data?.data);
        setBusinessData(response?.data?.data);
      })
      .catch((error) => {
        console.log(error?.response?.data?.message);
      });
  };
  const handleToggleStatus = (businessId, currentStatus) => {
    // Determine the new status based on the current status
    const newStatus = currentStatus === 'active' ? 'inactive' : 'active';

    // Make an API call to update the status
    axios
      .put(`${baseUrl}api/v1/business/updateStatus/${businessId}`,
        { status: newStatus },
        {
          headers: {
            _token: userData?.token
          },
          // withCredentials: true 
        })
      .then((response) => {
        // console.log("Business status updated successfully");
        // Update the data in your state after a successful update
        const updatedBusinessListData = businesslistData.map((business) => {
          if (business.id === businessId) {
            return { ...business, status: newStatus };
          }
          return business;
        });
        setBusinesslistData(updatedBusinessListData);
        window.location.reload();
      })

      .catch((error) => {
        console.error("Error updating business status:", error);
      });
  };

  
  const handleToggleStatusOfUser = (userId, currentStatus) => {
    // console.log(userId, currentStatus)
    const newStatus = currentStatus == "0" ? "1" : "0";
    // console.log(newStatus,"newStatusnewStatusnewStatus",baseUrl)
    // Make an API call to update the status
    axios
      .put(
        `${baseUrl}api/v1/user/updateStatusOfUser/${userId}`,
        { status: newStatus },
        {
          headers: {
            _token: userData?.token,
          },
        }
      )
      .then((response) => {
        toast.success(response?.data?.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        getUsersData()
        // const updatedUserListData = usersData.map((el) => {
        //   if (el.id == userId) {
        //     return { ...el, status: newStatus };
        //   }
        //   return el;
        // });
        // console.log(updatedUserListData,"updatedUserListData12")
        // setUsersData(updatedUserListData);
        // setUsersDataSearch(updatedUserListData);
        // setUsersDataApi(updatedUserListData);
      })

      .catch((error) => {
        toast.error(error?. response?. data?.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        console.error("Error updating user status:", error);
      });
  };


  useEffect(() => {
    getRoleData();
    getUsersData();
  }, []);

  const columns = [
  
    {
      name: "Name",
      selector: (row) => row?.name,
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => row?.email,
      sortable: true,
    },
    {
      name: "Mobile",
      selector: (row) => row?.contact,
      sortable: true,
    },
    {
      name: "Parent Name",
      selector: (row) => row?.parentUser?.name || "--",
      sortable: true
    },
    {
      name: "Date",
      // selector: (row) => new Date(row?.updatedAt).toLocaleString(),
      selector: (row) => new Date(row?.updatedAt).toLocaleDateString(),
      sortable: true,
    },
    {
      name: "Action",
      sortable: false,
      selector: "null",
      cell: (d) => [
        <i
          key={d.id}
          data-bs-toggle="modal"
          data-bs-target="#edituser"
          onClick={() => handleEditUser(d.id)}
          className="first fas fa-pen"
        ></i>,
        <i
          onClick={handleDeleteUser.bind(this, d.id)}
        // className="fas fa-trash-alt" style={{ marginRight: '10px' }}
          // className="fas fa-trash-alt" style={{ marginRight: '10px' }}
        ></i>,
        d?.current_business > 0 ? (
          <i
            data-bs-toggle="modal"
            data-bs-target="#businessTable"
            onClick={() => fetchBusinessData(d.id)}
            className="fa fa-briefcase fs-5 business-active"
          ></i>
        ) : (
          <i
            className="fa fa-briefcase fs-5 business-disabled"
            style={{ cursor: "not-allowed" }}
          ></i>
        ),
      ],
    },
    {
      name: "Active / Deactive",
      sortable: false,
      cell: (d) => (
        <div>
          <div className="form-check form-switch">
            <input
              className="form-check-input"
              // type="checkbox"
              type="checkbox"
              checked={d?.isDeleted=="0"? true:false}
              id={`flexSwitchCheckDefault_${d.id}`}
              onClick={() =>{
                // console.log(d,"dddddddddd")
                handleToggleStatusOfUser(d.id, d.isDeleted)
              }}
            />
          </div>
        </div>
      ),
    },
  ];

  const tableData = {
    columns,
    data: usersData,
  };

  // console.log(tableData, "tabledataalluser123");

  return (
    <>
      <PageName PageName="User" />

      <article>
        <div className="row">
          <div className="col-md-12">
            <p>
              <a
                className="btn btn-primary icon-btn"
                data-bs-toggle="modal"
                data-bs-target="#newuser"
                onClick={() => clearData()}
              >
                Add User
              </a>
            </p>
          </div>
        </div>
{/* 
        <div className="row">
          <div className="col-md-12">
            <div className="tile">
              <div className="tile-body">
                <form className="row">
                  <div className="mb-2 col-md-4">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Search....."
                      value={searchInput}
                      onChange={(e) => {
                        setSearchInput(e.target.value);
                      }}
                    />
                  </div>
                  <div className="mb-2 col-md-4">
                    <select
                      onChange={(e) => setSearchOption(e.target.value)}
                      className="form-select"
                    >
                      <option value="-1">Select an option</option>
                      {roleData &&
                        roleData.map((val, index) => (
                          <option value={val?.role_id} key={index}>
                            {val?.role_name}{" "}
                          </option>
                        ))}
                    </select>
                  </div>
                   <div className="mb-2 col-md-4 align-self-end">
                    <button
                      onClick={searchByRoleId}
                      className="btn btn-primary me-3"
                      type="button"
                    >
                      <i className="bi bi-check-circle-fill ms-2 "></i>Search
                    </button>
                    <button
                      onClick={() => {
                        setSearchInput("");
                        setSearchOption("-1");
                        setUsersData(usersDataApi);
                      }}
                      className="btn btn-danger"
                      type="button"
                    >
                      <i className="bi bi-check-circle-fill me-2"></i>Reset
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div> */}
        {isLoader ? (
        <div style={{position:"absolute", top:"50%", left:"55%",display:"flex", alignItems:"center", textAlign:"center"}}>
        <CircularLoader size={20} />
    </div>
        ) : (
          <div className="main">
            <DataTableExtensions {...tableData} filterPlaceholder="Search Users">
              <DataTable
                columns={columns}
                data={tableData.data}
                noHeader
                defaultSortField="id"
                sortIcon={<SortIcon />}
                defaultSortAsc={true}
                pagination
                highlightOnHover
                dense
              />
            </DataTableExtensions>
          </div>
        )}
        {/* <div className="row">
          <div className="col-md-12">
            <div className="tile">
              <div className="tile-body">
                <div className="table-responsive">
                  <table className="table ">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th> Name</th>
                        <th>Email</th>
                        <th>Mobile</th>
                        {/* <th>Business</th>
                        <th>Reg. Date</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {usersData &&
                        usersData
                          .slice(
                            (currentPage - 1) * itemsPerPage,
                            currentPage * itemsPerPage
                          )
                          .map((val, index) => (
                            // {usersData.length > 0 &&
                            //   usersData.map((val, index) => (
                            <tr>
                              <td>{index + 1}</td>
                              <td>{val?.name}</td>
                              <td>{val?.email}</td>
                              <td>
                                {val?.contact == null ||
                                val?.contact == undefined
                                  ? "Not available"
                                  : val?.contact}
                              </td>
                              <td>
                                <a data-bs-toggle="modal" data-bs-target="#businessTable" onClick={ ()=> fetchBusinessData(val.id)} >
                                    {val?.current_business}
                                </a>
                            </td>
                              <td>{val?.updatedAt.split("T")[0]}</td>
                              <td>
                                <div className="btn-group">
                                  {val?.current_business > 0 && (
                                    <Link>
                                      <button
                                        className="btn btn-success"
                                        data-bs-toggle="modal"
                                        data-bs-target="#businessTable"
                                        onClick={() =>
                                          fetchBusinessData(val.id)
                                        }
                                      >
                                        <i className="fa fa-briefcase fs-5"></i>
                                      </button>
                                    </Link>
                                  )}

                                  <Link>
                                    <button
                                      data-bs-toggle="modal"
                                      data-bs-target="#edituser"
                                      className="btn btn-warning"
                                      href="#"
                                      onClick={() => handleEditUser(val?.id)}
                                    >
                                      <i className="bi bi-pencil-square fs-5"></i>
                                    </button>
                                  </Link>
                                  <Link>
                                    <button
                                      className="btn btn-danger"
                                      data-target="#deleteuser"
                                      data-toggle="modal"
                                      onClick={() => handleDeleteUser(val?.id)}
                                    >
                                      <i className="bi bi-trash fs-5"></i>
                                    </button>
                                  </Link>
                                </div>
                              </td>
                            </tr>
                          ))}
                    </tbody>
                  </table>
                  {/* ))} 
                  <div className="mb-2 col-md-12">
                    <button
                      onClick={goToPreviousPage}
                      className="btn btn-primary me-3"
                      type="button"
                    >
                      <i className="bi bi-arrow-left"></i> Previous
                    </button>
                    <button
                      onClick={goToNextPage}
                      className="btn btn-primary"
                      type="button"
                    >
                      Next <i className="bi bi-arrow-right"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </article>

      <div
        className="modal fade"
        id="newuser"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        ref={createUserRef}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Create New User
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <form className="row">
                <div className="mb-2 col-md-6">
                  <input
                    onChange={(e) => {
                      handleChangeUserData(e, "name");
                    }}
                    value={userCreateData["name"]}
                    className="form-control"
                    type="text"
                    placeholder="Name"
                  />
                </div>
                <div className="mb-2 col-md-6">
                  <input
                    value={userCreateData["email"]}
                    onChange={(e) => handleChangeUserData(e, "email")}
                    className="form-control"
                    type="text"
                    placeholder="Email"
                  />
                </div>
                <div className="mb-2 col-md-6">
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Password"
                    value={userCreateData["password"]}
                    onChange={(e) => handleChangeUserData(e, "password")}
                  />
                </div>
                <div className="mb-2 col-md-6">
                  <select
                    value={userCreateData["roleId"]}
                    onChange={(e) => handleChangeUserData(e, "roleId")}
                    className="form-select"
                  >
                    <option value={0}>User Role</option>
                    {roleData &&
                      roleData.map((val) => (
                        <option value={val?.role_id}>{val?.role_name} </option>
                      ))}
                  </select>
                </div>
                <div
                  style={{
                    fontSize: "14px",
                    color: "red",
                    position: "absolute",
                    bottom: "70px",
                  }}
                >
                  {errorMsg}
                </div>
                <div className="mb-2 mt-4 col-md-12">
                  <button
                    onClick={handleSubmit}
                    className="btn btn-primary me-3"
                    type="button"
                  >
                    <i className="bi bi-check-circle-fill ms-2 "></i>Save
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="edituser"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        ref={createUserRef}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Update User
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <form className="row">
                <div className="mb-2 col-md-6">
                  <input
                    onChange={(e) => {
                      handleChangeUserData(e, "name");
                    }}
                    value={userCreateData["name"]}
                    className="form-control"
                    type="text"
                    placeholder="Name"
                  />
                </div>
                <div className="mb-2 col-md-6">
                  <input
                    value={userCreateData["email"]}
                    onChange={(e) => handleChangeUserData(e, "email")}
                    className="form-control"
                    type="text"
                    placeholder="Email"
                  />
                </div>
                {/* <div className="mb-2 col-md-6">
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Password"
                    value={userCreateData["password"]}
                    onChange={(e) => handleChangeUserData(e, "password")}
                  />
                </div> */}
                <div className="mb-2 col-md-6">
                  <select
                    value={userCreateData["roleId"]}
                    onChange={(e) => handleChangeUserData(e, "roleId")}
                    className="form-select"
                  >
                    <option value={0}>User Role</option>
                    {roleData &&
                      roleData.map((val) => (
                        <option value={val?.role_id}>{val?.role_name} </option>
                      ))}
                  </select>
                </div>
                <div
                  style={{
                    fontSize: "14px",
                    color: "red",
                    position: "absolute",
                    bottom: "70px",
                  }}
                >
                  {errorMsg}
                </div>
                <div className="mb-2 mt-4 col-md-12">
                  <button
                    onClick={handleEditSubmit}
                    className="btn btn-primary me-3"
                    type="button"
                    data-bs-dismiss="modal"
                  >
                    <i className="bi bi-check-circle-fill ms-2 "></i>Update
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="businessTable"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content ">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Business Data
              </h5>

              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="table-responsive">
                <table className="table ">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th> Name</th>
                      <th>Status</th>
                      <th>Date</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {/* {usersData &&
businessData.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage).map((val, index) => ( */}

                    {businessData.length > 0 &&
                      businessData.map((val, index) => (
                        <tr>
                          <td>{index + 1}</td>
                          <td>{val?.title}</td>
                          <td>{val?.status}</td>
                          <td>
                            {val?.created_at == null ||
                              val?.created_at == undefined
                              ? "Not available"
                              : val?.created_at}
                          </td>
                          {/* <td>
                            <a data-bs-toggle="modal" data-bs-target="#businessTable" >
                                {val?.current_business}
                            </a>
                        </td> */}

                          <td>
                            <div className="btn-group">
                              {/* <Link>
                                <button className="btn btn-success" href="#">
                                  <i className="bi bi-eye-fill fs-5"></i>
                                </button>
                              </Link> */}
                              <Link>
                                <button
                                  onClick={() =>
                                    handleToggleStatus(val?.id, val?.status)
                                  }
                                  className={
                                    val?.status === "active"
                                      ? "active-button"
                                      : "inactive-button"
                                  }
                                >
                                  {val?.status === "active"
                                    ? "Active"
                                    : "Inactive"}
                                </button>
                              </Link>

                              {/* <Link>
                                <button
                                  className="btn btn-danger"
                                  data-target="#deleteuser"
                                  data-toggle="modal"
                                  onClick={() => handleDeleteUser(val?.id)}
                                >
                                  <i className="bi bi-trash fs-5"></i>
                                </button>
                              </Link> */}
                            </div>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
                {/* ))} */}
                <div className="mb-2 col-md-12"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AllUser;
