import React from 'react'
import ThemeSelect from './ThemeSelect'
import EditQrLink from './EditQrLink'
import MoreDetils from './MoreDetils'

function ThemeDetails({}) {
  return (
    <>
 <div className="col-md-8">
    <div className="tile">

<EditQrLink  />
<ThemeSelect/>
<MoreDetils/>


</div>
</div>

      
    </>
  )
}

export default ThemeDetails
