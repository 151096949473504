import React from "react";

function Privacy() {
  return (
    <div>
      <section className="pagecover">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-12">
              <h1> Privacy Policy </h1>
            </div>
          </div>
        </div>
      </section>

      <section class="tandcpage" id="privacy_wrapper">
        <div class="container">
          <div class="row">
            <div class="col-12 col-lg-12">
              <div class="conatntwrap pri" id="privacy_container">
                <div>
                  <div class="heading_paragraph_wrapper">
                    
                    <p>
                    This Privacy Policy of BusinessBay.io governs the collection, storage, handling, and protection of personally identifiable information (PII) and sensitive personal data (hereinafter collectively referred to as "information") of users. All users are urged to read and understand this Privacy Policy, which has been established to safeguard user privacy and outline the steps users can take to protect their information.
                    </p>
                    <p>By using our site, you agree to the terms of this Privacy Policy. This policy applies to all users accessing the site via mobile or any other device.

                    </p>
                  </div>
                  <div class="heading_paragraph_wrapper">
                    <p>
                      <div className="boldfont" >COLLECTION OF INFORMATION</div>
                    </p>
                    <p>
                    We collect information from you solely to provide the services available on the site. During registration, we gather user details, including but not limited to name, company name, email address, phone number, postal address, and other business-related information, such as statutory details and tax registration numbers.
                    </p>
                    <p>
                    We may also record conversations and archive correspondence between users and site representatives for quality control or training purposes. For paid services, we may collect sensitive information, such as bank account details, to facilitate transactions.
                    </p>
                    <p>
                    In addition, we gather and store usage statistics, including IP addresses, pages viewed, user behavior patterns, session counts, and browser information, to improve user experience and provide value-added services.
                    </p>
                    <p>
                    Once registered, users are no longer anonymous to us, and all provided information is stored and processed to deliver requested services and comply with legal obligations.
                    </p>
                    <p>
                    Users can update, correct, or confirm their information by logging into their accounts or contacting info@businessbay.io. Please note that requested changes may take time due to verification processes.
                    </p>
                    <p>
                    Users may also request to delete or deactivate their accounts, which will be evaluated on a case-by-case basis under applicable laws. Information may remain in archival records for compliance or other lawful purposes.
                    </p>
                  </div>

                  <div class="heading_paragraph_wrapper">
                    <p>
                      <div className="boldfont" >PURPOSE AND USAGE OF INFORMATION</div>
                    </p>
                    <p>
                    We collect information for the following purposes:
                    </p>
                 
                    <p>
                    <strong> - </strong> To verify identity, eligibility, and registration.
                    </p>
                    <p>
                    <strong> - </strong> To facilitate services offered on the site.
                    </p>
                    <p>
                    <strong> - </strong> For advertising, marketing, and publication.
                    </p>
                    <p>
                    <strong> - </strong> To communicate with users and enhance business opportunities.
                    </p>
                    <p>
                    <strong> - </strong> To generate business inquiries and trade leads.
                    </p>
                    <p>
                    <strong> - </strong> To send communications, notifications, newsletters, and customized mailers.
                    </p>
                    <p>
                    <strong> - </strong> To provide a seamless and convenient user experience.
                    </p>
                    <p>
                    If you wish to object to any data processing purposes, please contact us at the email provided. Please note that if you withdraw consent for data processing, we may discontinue providing services through the site.[]
                    </p>
                  </div>
                  <div class="heading_paragraph_wrapper">
                    <p>
                      <div className="boldfont" >DISCLOSURE OF INFORMATION</div>
                    </p>
                    <p>
                    Information collected may be disclosed to external service providers who assist in delivering services to you or us, including:
                    </p>
                    <p>
                    <strong> - </strong> Affiliated companies for improved efficiency and personalized services.
                    </p>
                    <p>
                    <strong> - </strong> Government or law enforcement agencies as required by law for identity verification or crime prevention.
                    </p>
                    <p>
                    <strong> - </strong> Service providers, including payment processors and cloud computing services, to facilitate user services.
                    </p>
                    <p>
                    <strong> - </strong> Business partners for sending independent business offers.
                    </p>
                    <br />
                    <p>
                    Please note that external websites linked to the site are governed by their privacy policies, and this policy does not apply to those sites. We recommend reviewing their policies before use.
                    </p>
                    <p>
                    Disclosure recipients are required to:
                    </p>

                    <p>
                    <strong> - </strong> Limit information sharing to necessary parties and use it solely for the intended business transaction.
                    </p>

                    <p>
                    <strong> - </strong> Maintain confidentiality and security of the information.
                    </p>

                    <p>
                    <strong> - </strong> Refrain from further disclosing the information and adhere to the site's Privacy Policy.
                    </p>

                    <p>
                    <strong> - </strong> Users should be aware that any information posted online may be accessible to the public and could result in unsolicited messages.
                    </p>
                  </div>

                  <div class="heading_paragraph_wrapper">
                    <p>
                      <div className="boldfont" >PROTECTION OF INFORMATION</div>
                    </p>
                    <p>
                    We implement industry-standard security measures to protect against unauthorized access, maintain data accuracy, and ensure proper use of information. While we strive to protect your information, no data transmission over the internet can be guaranteed to be completely secure.
                    </p>
                    <p>
                    Users are advised not to share passwords for email accounts, online banking, or other sensitive credentials with our employees or agents, as we will never request such information. Registered users should also log out of their accounts after use, especially on shared or public computers, to prevent unauthorized access.
                    </p>
                  </div>

                  <div class="heading_paragraph_wrapper">
                    <p>
                      <div className="boldfont" >COOKIES</div>
                    </p>
                    <p>
                    We and our partners may use cookies, pixel tags, web beacons, mobile device IDs, and similar technologies to collect and store information about your use of the site and track visits to third-party websites. Cookies may be used to recognize your browser and provide features like recommendations and personalization.
                    </p>
                    <p>
                    Third-party sites accessible through our site may also use cookies or similar tools. We recommend reviewing their privacy policies for information on their practices.
                    </p>
                  </div>

                  <div class="heading_paragraph_wrapper">
                    <p>
                      <div className="boldfont" >DATA COLLECTION RELATING TO CHILDREN</div>
                    </p>
                    <p>
                    We are committed to protecting the privacy of children. We do not knowingly collect or maintain personally identifiable information from individuals under 18 years of age, and our site is not directed toward them. If you are under 18, please refrain from using our services. If we become aware of any such information collected without verified parental consent, we will delete it promptly. For concerns regarding this, please contact info@businessbay.io.
                    </p>
                  
                  </div>
                  <div class="heading_paragraph_wrapper">
                    <p>
                      <div className="boldfont" >DATA TRANSFERS</div>
                    </p>
                    <p>
                    Information collected from users may be transferred to and stored with our affiliates, partners, or service providers, whether within or outside the user's country of residence. By submitting your data, you consent to these transfers.
                    </p>
                    <p>
                    We will protect your information according to this Privacy Policy and, where necessary, ensure appropriate safeguards are in place to process your data transparently and fairly.
                    </p>
                  </div>

                  <div class="heading_paragraph_wrapper">
                    <p>
                      <div className="boldfont" >QUESTIONS</div>
                    </p>
                    <p>
                    For any questions, clarifications, or concerns regarding this Privacy Policy, please contact us at info@businessbay.io.
                    </p>
                  </div>
              
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Privacy;