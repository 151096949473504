import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import WhatsappChatIcon from "../../Images/WhatsappIcon1.png";
import { getIPLocation } from './GetIPLocation';
import { getIpAddress } from './GetIPAddress';



const Root = styled.div``;
const Container = styled.div``;
const ImageWrapper = styled.div`
position: fixed;
bottom: 40px;
right: 40px;
cursor: pointer;
z-index: 999999;
`;
const Image = styled.img`
width: 65px;
height: 65px;
border-radius: 50%; 
border: 2px solid #25d366;
padding: 5px;
`;

export const WhatsappIcon = () => {
    const [whatsappNumber, setWhatsappNumber] = useState('');

    useEffect(() => {
        const fetchLocation = async () => {
            const ip = await getIpAddress();            
            if (ip) {
                const loc = await getIPLocation(ip);
                if (loc) {
                    if (loc.country_name === 'United Arab Emirates') {
                        setWhatsappNumber('+971565954252');
                    } else if (loc.country_name === 'India') {
                        setWhatsappNumber('+919818867316');
                    } else {
                        setWhatsappNumber('+919818867316'); 
                    }
                }
            }
        };
        fetchLocation();
    }, []);

    const openWhatsappChat = () => {
        const whatsappChatUrl = `https://wa.me/${whatsappNumber}`;
        window.open(whatsappChatUrl, '_blank');
    };

    

  return (
   <Root>
    <Container>
        <ImageWrapper onClick={openWhatsappChat}>
            <Image src={WhatsappChatIcon} alt="WhatsApp Icon"/>
        </ImageWrapper>
    </Container>
   </Root>
  )
}
