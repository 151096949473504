import React from 'react';
import './ProgressBar.css';

const ProgressBar = ({ percentage }) => {
  return (
    <div className="progress-bar-container">
      <div className="progress-bar">
        <div
          className="progress-bar-fill"
          style={{ width: `${percentage}%` }}
        ></div>
      </div>
      <div className="progress-bar-text">Resume Completed<span style={{
        backgroundColor: "#E7F4ED",
        padding: "5px 10px 5px 10px",
        margin: "10px 2px 10px 10px",
        borderRadius: "6px",
      }}>{percentage}%</span> </div>
    </div>
  );
};

export default ProgressBar;