import ThemTemplate from "./ThemTemplate";
import React, { useContext, useEffect, useState } from "react";
import { userContext } from "../../../context/userContext";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { environmentVariables } from "../../../config/env.config";
import ViewThem from "./ViewThem";
import { Navigate, useLocation, useNavigate } from "react-router-dom";

import {
  businessContext,
  isBusinesCreatedContext,
} from "../../../context/businessContext";

import CircularLoader from "../../../Backend/Components/CircularLoader/CircularLoader";
import "./../Updatedcss/BusinessChangeBlockUpdatedStyle.css";

function Them({
  mobilePreviewLoading,
  businessObj,
  setThemeColor,
  setActiveTab,
  setThemeNumber,
  themeColor,
  themeNumber,
  isChecking,
  setIsChecking,
  selectedTheme,
  setSelectedTheme,
  businessName,
  setBusinessName,
  setIsStatus,
  isStatus,
  isBusinessCreated,
  setIsBusinessCreated,
  activeTab,
  isLoader,
}) {
  const baseUrl = environmentVariables?.apiUrl;
  const location = useLocation();
  const [selectThemeColor, setSelectThemeColor] = useState("");
  const { userData, setUserData } = useContext(userContext);
  const [title, setTitle] = useState("");
  // const [businessObj, setBusinessObj] = useState(null);
  const [designation, setDesignation] = useState("");
  const [subTitle, setSubTitle] = useState("");
  const [description, setDescription] = useState("");
  const [bannerImageUrl, setBannerImageUrl] = useState("");
  const [logoImageUrl, setLogoImageUrl] = useState("");
  const [slug, setSlug] = useState("");

  //contact info
  const [isEnabledContactInfo, setIsEnabledContactInfo] = useState(false);
  const [isEnabledContactForm, setIsEnabledContactForm] = useState(false);
  const [contactInfoPhone, setContactInfoPhone] = useState("");
  const [contactInfoEmail, setContactInfoEmail] = useState("");
  const [isSubmit, setIsSubmit] = useState(false);
  const [contactInfoWebUrl, setContactInfoWebUrl] = useState("");
  const [contactInfoAddress, setContactInfoAddress] = useState("");
  const [businessId, setBusinessId] = useState("");
  const [logoData, setLogoData] = useState("");
  const [bannerData, setBannerData] = useState("");
  const [onState, setOnState] = useState({
    contactInfo: false,
    services: false,
    testimonials: false,
    socials: false,
    businessHours: false,
    gallery: false,
    appointment: false,
    contactForm: false,
  });
  //services array
  const [servicesArray, setServicesArray] = useState([]);
  const [isEnabledServices, setIsEnabledServices] = useState(false);

  //testimonial arry
  const [testimonialArray, setTestimonialArray] = useState([]);
  const [isTestimonial, setIsTestimonial] = useState(false);

  //appointment array
  const [isAppointment, setIsAppointment] = useState(false);
  const [appointmentArray, setAppointmentArray] = useState([]);

  //social
  const [isSocial, setIsSocial] = useState(false);
  const [socialMediaData, setSocialMediaData] = useState([]);

  //business hours
  const [businessHourObject, setBusinessHourObject] = useState({});
  const [IsBusinessHourObject, setIsBusinessHourObject] = useState(false);

  //galary array
  const [gallaryObject, setGallaryObject] = useState({});
  const [isGallaryEnable, SetIsGallaryEnable] = useState(false);
  const [galleryData, setGalleryData] = useState([]);
  const [businessTheme, setBusinessTheme] = useState([]);
  

  //mini marketplace array
  const [minimarketArray,setMiniMarketplaceArray]=useState([])
  const [cabArray, setCabArray] = useState([]);

  // const [templateName, setTemplateName] = useState("template1");
  const navigate = useNavigate();
  useEffect(() => {
    if (businessObj && businessObj?.id) {
      setBusinessId(businessObj?.id);
      setTitle(businessObj?.title || "");
      setDesignation(businessObj?.designation || "");
      setSubTitle(businessObj?.sub_title || "");
      setDescription(businessObj?.description || "");
      setBannerImageUrl(businessObj?.banner || "");
      setLogoImageUrl(businessObj?.logo || "");
      setSlug(businessObj?.slug || "");
      setMiniMarketplaceArray(businessObj?.miniMktProductArr||[])
      setCabArray(businessObj?.cabproduct||[])
    } else {
      setBusinessId("");
      setTitle("");
      setDesignation("");
      setSubTitle("");
      setDescription("");
      setBannerImageUrl("");
      setLogoImageUrl("");
      setSlug("");
    }
    let contactInfoData;
    if (
      businessObj &&
      businessObj?.contactInfo &&
      businessObj?.contactInfo?.business_id &&
      businessObj?.contactInfo?.content
    ) {
      contactInfoData = JSON.parse(businessObj?.contactInfo?.content);
      if (contactInfoData.length > 0) {
        contactInfoData = JSON.parse(contactInfoData);
      }
      // console.log(contactInfoData, "phonen");
    }
    if (
      contactInfoData?.contactInfoAddress ||
      contactInfoData?.contactInfoPhone ||
      contactInfoData?.contactInfoWebUrl ||
      contactInfoData?.contactInfoEmail
    ) {
      // setIsEnabledContactInfo(true);
      
      setContactInfoPhone(contactInfoData?.contactInfoPhone);
      setContactInfoEmail(contactInfoData?.contactInfoEmail);
      setContactInfoWebUrl(contactInfoData?.contactInfoWebUrl);
      setContactInfoAddress(contactInfoData?.contactInfoAddress);
      // console.log(contactInfoData, "contactInfoData12");
    } else {
      // setIsEnabledContactInfo(false);
      setContactInfoPhone("");
      setContactInfoEmail("");
      setContactInfoWebUrl("");
      setContactInfoAddress("");
    }
    if (
      businessObj &&
      businessObj?.gallaryObj &&
      businessObj?.gallaryObj?.is_enabled
    ) {
      SetIsGallaryEnable(true);
      setOnState({ ...onState, gallery: true });
      setGallaryObject(JSON.parse(businessObj?.gallaryObj?.content));
      setGalleryData(businessObj?.newGalleryImages);
    }
    if (
      businessObj &&
      businessObj?.businessHourObj &&
      businessObj?.businessHourObj?.is_enabled
    ) {
      setIsBusinessHourObject(true);
      setOnState({ ...onState, businessHours: true });
      setBusinessHourObject(JSON.parse(businessObj?.businessHourObj?.content));
    }
    if (
      businessObj &&
      businessObj?.SocialMediaObj &&
      businessObj?.SocialMediaObj?.is_enabled
    ) {
      setIsSocial(true);
      setOnState({ ...onState, socials: true });
      // console.log(
      //   JSON.parse(JSON.parse(businessObj?.SocialMediaObj?.content)),
      //   "inside theme view"
      // );
      setSocialMediaData(
        JSON.parse(JSON.parse(businessObj?.SocialMediaObj?.content))
      );
    }
    if (
      businessObj &&
      businessObj?.appointmentObj &&
      businessObj?.appointmentObj?.is_enabled
    ) {
      setIsAppointment(true);
      setOnState({ ...onState, appointment: true });
      if (businessObj?.appointmentObj) {
        try {
          setAppointmentArray(
            JSON.parse(JSON.parse(businessObj?.appointmentObj?.content))
          );
        } catch (err) {
          console.log(err);
        }
      }
    } else {
      setIsAppointment(false);
    }
    if (
      businessObj &&
      businessObj?.testimonialObj &&
      businessObj?.testimonialObj?.is_enabled == 1
    ) {
      // console.log("testimonialObj2@@@@@@@@2",businessObj?.testimonialObj?.is_enabled)
      setIsTestimonial(true);
      setOnState({ ...onState, testimonials: true });
      setTestimonialArray(JSON.parse(businessObj?.testimonialObj?.content));
    } else {
      setIsTestimonial(false);
    }
    if (
      businessObj &&
      businessObj?.serviceObj &&
      businessObj?.serviceObj?.is_enabled
    ) {
      setIsEnabledServices(true);
      setOnState({
        ...onState,
        services: true,
      });
      if(typeof businessObj?.serviceObj?.content=='string'){
        let parsedContent=JSON.parse(businessObj?.serviceObj?.content)
        setServicesArray(parsedContent);
      }else if(typeof businessObj?.serviceObj?.content=='object'&&Array.isArray(businessObj?.serviceObj?.content)){
        setServicesArray(businessObj?.serviceObj?.content)
      }
    } else {
      setIsEnabledServices(false);
    }
    if (
      businessObj &&
      businessObj?.contactInfo &&
      businessObj?.contactInfo?.is_enabled
    ) {
      setIsEnabledContactInfo(true);

      setOnState({
        ...onState,
        contactInfo: true,
      });
    } else {
      setIsEnabledContactInfo(false);
    }
    if (businessObj && businessObj?.is_contact_form_enabled) {
      setIsEnabledContactForm(true);
      setOnState({
        ...onState,
        contactForm: true,
      });
    } else {
      setIsEnabledContactForm(false);
    }
  }, [businessObj]);

  useEffect(() => {
    let stateObj = {};
    if (businessObj?.testimonialObj?.is_enabled) {
      stateObj["testimonials"] = true;
    }
    if (businessObj?.serviceObj?.is_enabled) {
      stateObj["services"] = true;
    }
    if (businessObj?.contactInfo?.is_enabled) {
      stateObj["contactInfo"] = true;
    }
    if (businessObj?.gallaryObj?.is_enabled) {
      stateObj["gallery"] = true;
    }
    if (businessObj?.businessHourObj?.is_enabled) {
      stateObj["businessHours"] = true;
    }
    if (businessObj?.SocialMediaObj?.is_enabled) {
      stateObj["socials"] = true;
    }
    if (businessObj?.appointmentObj?.is_enabled) {
      stateObj["appointment"] = true;
    }
    if (businessObj?.is_contact_form_enabled) {
      stateObj["contactForm"] = true;
    }
    setOnState(stateObj);
  }, [businessObj]);

  const clearData = () => {
    setBusinessName("");
    setSelectedTheme("");
    setSelectThemeColor("");
  };
// console.log(servicesArray,typeof servicesArray,'///////////////////;;;;;;;;;;;;;;;;;;;;;')
  const handleSubmit = (e) => {
    try {
      e.preventDefault();
      if (
        isChecking == null &&
        (businessName == "" || businessName?.trim() == "")
      ) {
        toast.error("Please provide Business Name", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        return;
      }
      if (selectedTheme == "" || selectedTheme?.trim() == "") {
        toast.error("Theme is mandatory", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        return;
      }
      // if (selectThemeColor == "" || selectThemeColor?.trim() == "") {
      //   toast.error("Theme color is mandatory", {
      //     position: toast.POSITION.TOP_RIGHT,
      //   });
      //   return;
      // }
      let obj = {
        theme: selectedTheme,
        // theme_color: selectThemeColor + `-${selectedTheme}`,
        theme_color: selectedTheme,
      };
      if (isChecking == null) {
        obj.business_title = businessName;
      } else {
        obj.businessId = businessObj?.id;
      }
      setIsStatus(true);
      if (isChecking == null) {
        axios
          .post(`${baseUrl}api/v1/business/create`, obj, {
            headers: {
              _token: userData?.token,
            },
            withCredentials: true,
          })
          .then((res) => {
            if (res?.data?.success) {
              toast.success(res?.data?.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 1000,
              });
              let tempObj = {};
              tempObj.businessId = res?.data?.businessId;
              tempObj.businessName = businessName;
              setIsBusinessCreated(tempObj);
              navigate(`?id=${res?.data?.businessId}`);
              clearData();
              // navigate("/dashboard");
            }
          })
          .catch((err) => {
            // console.log(err,"eeeeeeewwefww");
            toast.error(err?.response?.data?.message, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 1000,
            });
          })
          .finally(() => {
            setIsStatus(false);
          });
      } else {
        axios
          .post(`${baseUrl}api/v1/business/edit`, obj, {
            headers: {
              _token: userData?.token,
            },
            withCredentials: true,
          })
          .then((res) => {
            // console.log(res, "EEEEEEEEedit ");
            if (res?.data?.success) {
              toast.success(res?.data?.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 1000,
              });
              setActiveTab("tab2");
              // navigate("/dashboard");
            }
          })
          .catch((err) => {
            toast.error(err?.response?.data?.message, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 2000,
            });
          })
          .finally(() => {
            setIsStatus(false);
          });
      }
    } catch (err) {
      toast.error(err, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
      return;
    }
  };

  useEffect(() => {
    const params = new URLSearchParams(location?.search);
    if (params.get("create") == 1) {
      setActiveTab("tab1");
      setSelectedTheme("theme2");
    }
    setIsChecking(params.get("id"));
  }, [location]);

  const handleFetchTheme = async () => {
    try {
      const { data: res } = await axios.get(
        `${baseUrl}api/v1/business/fetchTheme`,
        {
          headers: {
            _token: userData?.token,
          },
          withCredentials: true,
        }
      );
      // console.log(res?.data, "Res themem 2");
      setBusinessTheme(res?.data);
      // toast.error("Theme is mandatory", {
      //   position: toast.POSITION.TOP_RIGHT,
      // });
    } catch (err) {
      toast.error(err, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
    }
  };

  useEffect(() => {
    handleFetchTheme();
  }, []);

  const getComponenent = (value, data) => {
    const obj = {
      // template1: <Component1 data={data} />,
      // template2: <Component2 data={data} />,
    };

    return obj[value];
  };
  return (
    <>
      <div className="ChangeBlock_Sequenceview_Wrapper">
        <div className="row mt-mobile ChangeBlock_Sequenceview_Container">
          <div className="col-12 col-lg-8 ChangeBlock_Container">
            <div className="tile scrollview L415">
              {/* <div className="row justify-content-end px-0 mb-4">
                <div className="col-12 col-lg-3 Changeblock_saveandpreview_buttonWrapper">
                  {isChecking != null && isStatus ? (
                    <CircularLoader size={40} />
                  ) : isChecking != null ? (
                    <button
                      type="Submit"
                      className="btn btn-cu save-cta Changeblock_saveandpreview_button"
                      onClick={(e) => handleSubmit(e)}
                    >
                      {isChecking != null ? "Save & Next" : "Create"}
                    </button>
                  ) : (
                    <></>
                  )}
                </div>
              </div> */}
              <div className="row">
                <ThemTemplate
                  setSelectedTheme={setSelectedTheme}
                  setSelectThemeColor={setSelectThemeColor}
                  setThemeNumber={setThemeNumber}
                  setThemeColor1={setThemeColor}
                  isNewTheme={false}
                  businessName={businessName}
                  setBusinessName={setBusinessName}
                  isChecking={isChecking}
                  businessTheme={businessTheme}
                />
              </div>
              <div className="row justify-content-end px-0 mb-4">
                <div className="col-12 col-lg-3 Changeblock_saveandpreview_buttonWrapper">
                  {isChecking != null && isStatus ? (
                    <CircularLoader size={40} />
                  ) : isChecking != null ? (
                    <button
                      type="Submit"
                      className="btn btn-cu save-cta Changeblock_saveandpreview_button"
                      onClick={(e) => handleSubmit(e)}
                    >
                      {isChecking != null ? "Save & Next" : "Create"}
                    </button>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="ChangeBlockView_Container">
            <ViewThem
              mobilePreviewLoading={mobilePreviewLoading}
              handleSubmit={handleSubmit}
              setThemeColor={setThemeColor}
              selectedTheme={selectedTheme}
              activeTab={activeTab}
              isChecking={isChecking}
              themeColor={selectedTheme}
              themeNumber={themeNumber}
              businessObj={businessObj}
              title={title}
              designation={designation}
              subTitle={subTitle}
              description={description}
              bannerImageUrl={bannerImageUrl}
              logoImageUrl={logoImageUrl}
              contactInfo={{
                contactInfoAddress,
                contactInfoEmail,
                contactInfoPhone,
                contactInfoWebUrl,
              }}
              appointmentArray={appointmentArray}
              businessHourObject={businessHourObject}
              socialMediaData={socialMediaData}
              servicesArray={servicesArray}
              testimonialArray={testimonialArray}
              gallaryObject={gallaryObject}
              // gallaryObj={gallaryObject}
              galleryData={galleryData}
              onState={onState}
              isLoader={isLoader}
              minimarketArray={minimarketArray}
              cabArray = {cabArray}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default Them;
