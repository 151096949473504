import React, { useContext, useEffect, useState } from 'react'
import RedirectLoader from '../../Components/RedirectLoader/RedirectLoader'
import axios from 'axios'
import { environmentVariables } from '../../../config/env.config'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import { userContext } from '../../../context/userContext'

const Redirect = () => {
  const { userData } = useContext(userContext);
    const navigate=useNavigate()
    // const [planRedirect,setPlanRedirect]=useState(false);
    const verifyCashFreeTransaction=()=>{
        const params=new URLSearchParams(location.search);
        const orderId=params.get("order_id");
        const action=params.get("action");
        console.log(action)
       
        if(orderId==null||orderId==undefined||orderId.trim()==""){
            toast.error("Order Id not found!");
           if(action=="plans"){
            navigate('/plans')
            return;
           }else{
            navigate('/requestCard')
            return;
           }
        }
        axios
        .get(`${environmentVariables?.apiUrl}api/v1/pg/cashfree/CheckOrder/${orderId}`, {
          headers: {
            _token: userData?.token,
          },
          withCredentials: true,
        }).then(response=>{
            console.log(response?.data,'//////////////////////////////////////')
            if(response?.data?.success==true){
                const amount=parseInt(response?.data?.data?.payment_amount)/100

                if(action=="plans"){
                  
                navigate(`/paymentPlans?status=success&txn_id=${response?.data?.data?.cf_payment_id}&orderid=${response?.data?.data?.order_id}&price=${amount}`)
                  
                }else{

                  navigate(`/paymentPhysical?status=success&transId=${response?.data?.data?.cf_payment_id}&orderid=${response?.data?.data?.order_id}&amount=${amount}`)
                }
            }else{
                toast.error(response?.data?.message);
                navigate('/requestCard')
            }
        }).catch(error=>{
            console.log(error);
            toast.error(error?.response?.data?.message||error?.message||"Something went wrong");
            navigate('/requestCard')
        })
    }
  useEffect(()=>{
    verifyCashFreeTransaction();
  },[])
  return (
    <div><RedirectLoader /></div>
  )
}

export default Redirect