import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { environmentVariables } from "../config/env.config";
import { userContext } from "../context/userContext";
import Cookies from 'js-cookie';
function VerifyEmail() {
  const { userData, setUserData } = useContext(userContext);
  const location = new useLocation();
  const navigate = useNavigate();
  const [authToken, setAuthToken] = useState(null);
  

  const AutoVerifyLogin = (auth)=>{
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${environmentVariables?.apiUrl}api/v1/user/verify`,
      headers: {
        _token: auth,
      },
    };
    axios
      .request(config)
      .then((res) => {
        if (res?.data?.result?.token) {
          // localStorage.setItem("user", JSON.stringify(res?.data?.result));
          // Cookies.set('userTokenvv', 'your-token-valuevv', { expires: 6, domain: 'examplevv.com', path: '/', secure: true });
          // setUserData(res?.data?.result);
          toast.success("Your email has been verified successfully.", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          }); 
          // setIsLoginDisabled(true);
          // navigate("/dashboard");

          setTimeout(() => {
            navigate("/login");
          }, 3000); 

        }         
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message || error?.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      });
  }
  
  
  
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const auth = params.get("auth");
    if (!auth) {
      toast.error("Auth token not found", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
    } else {
      setAuthToken(auth);
      // AutoVerifyLogin(auth);
    }
  }, []);

  return (
    <section className="py-5" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh' }}>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-lg-4">
            <div className="loginform">
              {/* <h3>Verified Email Successfully</h3> */}
              {/* <h3>Verif Email</h3> */}

              <div style={{ textAlign: 'center', padding: '20px' }}>
      <h3>Please click the link below to verify your email:</h3>
      <button
        onClick={()=>AutoVerifyLogin(authToken)}
        style={{
          display: "block",
          width: "100%",
          textAlign: "center",
          color: "#fff",
          marginBottom: "13px",  // Corrected from margin-bottom to marginBottom
          padding: "15px 40px",
          fontWeight: "bold",
          fontSize: "16px",
          borderRadius: "50px",
          background: "linear-gradient(to right, #01c3cc, #7d2ae8)",  // Corrected from background: linear-gradient(...) to "linear-gradient(...)"
          border: "0",  // Corrected from border: 0 to "border: 0"
          boxShadow: "0px 8px 24px rgba(191, 173, 223, 0.61)",  // Corrected from boxShadow: rgb(...) to "boxShadow: ..."
          textDecoration: "none !important",
        }}
      >
        Click Here to Verify
      </button>
    <p>
        <Link to="/login" style={{ color: '#0D0D0E', textDecoration: 'none', display: 'block', marginTop: '10px', fontWeight:"400" }} >
          Go Back to Login Page
        </Link>
      </p>
    </div>
            </div>
            <ToastContainer />
          </div>
        </div>
      </div>
    </section>
  );
}

export default VerifyEmail;
