import React, { useRef, useEffect, useState } from "react";
import ShareVector from "./../../../Images/Share.png";
import {
  BackgroundImage,
  BackgroundImageContainer,
  Container,
  EducationCompanyName,
  ExperienceCompanyName,
  ExperienceDate,
  ExperienceDesignationName,
  ExperienceSummary,
  ExtCertificateAcd,
  ExtCertificateDate,
  ExtCertificateID,
  ExtCertificateName,
  ExternalAddressValue,
  ExternalAddressWrapper,
  ExternalCertificateWrapper,
  ExternalDesignation,
  ExternalEducation,
  ExternalExperienceResumeWrapper,
  ExternalFatherSpauseNameKey,
  ExternalFatherSpauseNameValue,
  ExternalLink,
  ExternalLogoImage,
  ExternalLogoImageContainer,
  ExternalNameWrapper,
  ExternalParticularContainer,
  ExternalParticularHeadingName,
  ExternalParticularHeadingWrapper,
  ExternalParticularkeyWrapper,
  ExternalPersonalWrapper,
  ExternalSkillsList,
  ExternalSkillsWrapper,
  ExternalSummary,
  ExternalWorkExperienceWrapper,
  ExternalWrapper,
  Externalachivemeentlist,
  LeftRightWrapper,
  LeftSideWrapper,
  LogoImage,
  LogoImageContainer,
  Power,
  PowerSpan,
  PoweredWrapper,
  RightSideWrapper,
  Root,
  Wrapper,
} from "./Resume6ExtStyle";
import Resumelogo from "../../../Images/memoji.png";
import ResumeBackground from "../../../Images/Resume6bckgroundimg.png";
import { environmentVariables } from "../../../config/env.config";
import {
  IconContainer,
  ShareButtonWrapper,
  ShareIcon,
  SingleIcon,
  SingleIconWrapper,
} from "./StyleThemejs";
import {
  EmailIcon,
  LinkedinIcon,
  TelegramIcon,
  WhatsappIcon,
} from "react-share";

const Resume6Ext = (props) => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [educationData, setEducationData] = useState([]);
  const [projectData, setProjectData] = useState([]);
  const [certificateData, setCertificateData] = useState([]);
  const [skillData, setSkillData] = useState([]);
  const [additionalData, setAdditionalData] = useState([]);
  const [achievementData, setAchievementData] = useState([]);
  const [workExpData, setworkExpData] = useState([]);
  const [toolData, settoolData] = useState([]);
  const [language, setLanguage] = useState([]);
  const [showShareOptions, setShowShareOptions] = useState(false);

  const iconContainerRef = useRef(null);

  useEffect(() => {
    if (props?.resumeData?.educationcontent) {
      setEducationData(JSON.parse(props.resumeData.educationcontent));
    }
    if (props?.resumeData?.projectcontent) {
      setProjectData(JSON.parse(props.resumeData.projectcontent));
    }
    if (props?.resumeData?.certificationcontent) {
      setCertificateData(JSON.parse(props.resumeData.certificationcontent));
    }
    if (props?.resumeData?.skillscontent) {
      setSkillData(JSON.parse(props.resumeData.skillscontent));
    }
    if (props?.resumeData?.additionaldetailscontent) {
      setAdditionalData(JSON.parse(props.resumeData.additionaldetailscontent));
    }
    if (props?.resumeData?.toolscontent) {
      settoolData(JSON.parse(props.resumeData.toolscontent));
    }
    if (props?.resumeData?.languagecontent) {
      setLanguage(JSON.parse(props.resumeData.languagecontent));
    }
    if (props?.resumeData?.wrkExpcontent) {
      setworkExpData(JSON.parse(props.resumeData.wrkExpcontent));
    }
    if (props?.resumeData?.achievementcontent) {
      setAchievementData(JSON.parse(props.resumeData.achievementcontent));
    }
  }, [props.resumeData]);

  const getImage = (image) => {
    if (image && image.includes("base64")) {
      return image;
    } else if (image) {
      return `${environmentVariables?.apiUrl}uploads/resume/${image}`;
    } else {
      return "";
    }
  };

  const updateScreenWidth = () => {
    setScreenWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", updateScreenWidth);
    return () => {
      window.removeEventListener("resize", updateScreenWidth);
    };
  }, []);
  
  const handleClickOutside = (event) => {
    if (iconContainerRef.current && !iconContainerRef.current.contains(event.target)) {
      setShowShareOptions(false);
    }
  };
  useEffect(() => {
      document.addEventListener("click", handleClickOutside, false);
      return () => {
        document.removeEventListener("click", handleClickOutside, false);
      };
  }, []);

  const formatDate = (dateString) => {
    if (!dateString) return ""; // Return empty string if dateString is falsy

    const date = new Date(dateString); // Parse the date string
    const options = { month: "long", year: "numeric" }; // Format options
    return date.toLocaleDateString("en-US", options); // Format date to "Month-YYYY" format
  };

  const handleShare = () => {
    setShowShareOptions(!showShareOptions);
  };

  const handleShareOnPlatform = (platform) => {
    const resumePreviewLink = window.location.href;
    const message = "Check out my resume!";
    const fullMessage = `${message} ${resumePreviewLink}`;
    let shareUrl = "";

    switch (platform) {
      case "linkedin":
        // shareUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(resumePreviewLink)}`;
        // break;
        navigator.clipboard
          .writeText(fullMessage)
          .then(() => {
            alert(
              "Message copied to clipboard! Please paste it in LinkedIn messages."
            );
            window.open("https://www.linkedin.com/messaging", "_blank"); // Opens LinkedIn Messaging
          })
          .catch((err) => {
            console.error("Could not copy text: ", err);
          });
        break;
      case "whatsapp":
        shareUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(
          resumePreviewLink
        )}`;
        break;
      case "telegram":
        shareUrl = `https://t.me/share/url?url=${encodeURIComponent(
          resumePreviewLink
        )}`;
        break;
      case "email":
        shareUrl = `mailto:?subject=Checkoutmyresume&body=${encodeURIComponent(
          resumePreviewLink
        )}`;
        // setShowEmailShare(true);
        break;
      // case 'facebook':

      //   break;
      default:
        break;
    }

    if (shareUrl) {
      window.open(shareUrl, "_blank");
    }
  };

  const renderWorkExperience = () => (
    <ExternalParticularContainer>
      <ExternalParticularHeadingWrapper Experience>
        <ExternalParticularHeadingName>
          experience
        </ExternalParticularHeadingName>
      </ExternalParticularHeadingWrapper>
      {workExpData.map((exp, index) => (
        <ExternalExperienceResumeWrapper key={index}>
          {/* {console.log(exp,"asdasdasda")} */}
          <ExperienceCompanyName>{exp.companyname}</ExperienceCompanyName>
          <ExperienceDesignationName>
            {exp.jobtitle} , {exp?.cityname}
          </ExperienceDesignationName>
          <ExperienceDate>
            {`${formatDate(exp.startdate)?.substr(0, 3)} ${formatDate(
              exp.startdate
            )?.substr(-4)} - ${
              exp.currentlyWorking
                ? "Present"
                : `${formatDate(exp.enddate)?.substr(0, 3)} ${formatDate(
                    exp.enddate
                  )?.substr(-4)}`
            }`}
          </ExperienceDate>
          <ExperienceSummary>{exp.workSummary}</ExperienceSummary>
        </ExternalExperienceResumeWrapper>
      ))}
    </ExternalParticularContainer>
  );

  const renderProjects = () => (
    <ExternalParticularContainer>
      <ExternalParticularHeadingWrapper Experience>
        <ExternalParticularHeadingName>PROJECT</ExternalParticularHeadingName>
      </ExternalParticularHeadingWrapper>
      {projectData.map((project, index) => (
        <ExternalExperienceResumeWrapper
          key={index}
          style={{ padding: "12px 0" }}
        >
          <EducationCompanyName>
            {project.projectname} ,
            {`${formatDate(project.startDate)?.substr(0, 3)} ${formatDate(
              project.startDate
            )?.substr(-4)} - ${
              project.currentlyWorking
                ? "Current"
                : `${formatDate(project.endDate)?.substr(0, 3)} ${formatDate(
                    project.endDate
                  )?.substr(-4)}`
            }`}
          </EducationCompanyName>
          <ExternalEducation>{project.projectdescription}</ExternalEducation>

          <ExternalLink href={project.projectlink} target="_blank">
            {" "}
            {project.projectlink}
          </ExternalLink>
        </ExternalExperienceResumeWrapper>
      ))}
    </ExternalParticularContainer>
  );

  const renderSkills = () => (
    <ExternalParticularContainer>
      <ExternalParticularHeadingWrapper Skills>
        <ExternalParticularHeadingName>SKills</ExternalParticularHeadingName>
      </ExternalParticularHeadingWrapper>
      <ExternalSkillsWrapper>
        {skillData.map((skill, index) => (
          <ExternalSkillsList key={index}>{skill.skillname}</ExternalSkillsList>
        ))}
      </ExternalSkillsWrapper>
    </ExternalParticularContainer>
  );

  const renderTools = () => (
    <ExternalParticularContainer>
      <ExternalParticularHeadingWrapper Skills>
        <ExternalParticularHeadingName>Tools</ExternalParticularHeadingName>
      </ExternalParticularHeadingWrapper>
      <ExternalSkillsWrapper>
        {toolData.map((tool, index) => (
          <ExternalSkillsList key={index}>{tool.toolname}</ExternalSkillsList>
        ))}
      </ExternalSkillsWrapper>
    </ExternalParticularContainer>
  );

  const renderEducation = () => (
    <ExternalParticularContainer>
      <ExternalParticularHeadingWrapper Experience>
        <ExternalParticularHeadingName>Education</ExternalParticularHeadingName>
      </ExternalParticularHeadingWrapper>
      {educationData.map((item, index) => (
        <ExternalExperienceResumeWrapper
          style={{ padding: "10px 0 10px 0" }}
          key={index}
        >
          <EducationCompanyName>{item.degree}</EducationCompanyName>
          <ExternalEducation>
            {item.schuniname} , {item.boardname}
          </ExternalEducation>
          <ExternalEducation>
            {item.location} ,
            {`${formatDate(item.graduationyear)?.substr(0, 3)} ${formatDate(
              item.graduationyear
            )?.substr(-4)} - ${
              item.currentlyWorking
                ? "Present"
                : `${formatDate(item.graduationendyear)?.substr(
                    0,
                    3
                  )} ${formatDate(item.graduationendyear)?.substr(-4)}`
            }`}
          </ExternalEducation>
        </ExternalExperienceResumeWrapper>
      ))}
    </ExternalParticularContainer>
  );

  const renderCertifications = () => (
    <ExternalParticularContainer>
      <ExternalParticularHeadingWrapper Skills>
        <ExternalParticularHeadingName>
          Certifications
        </ExternalParticularHeadingName>
      </ExternalParticularHeadingWrapper>
      {certificateData.map((certificate, index) => (
        <ExternalCertificateWrapper key={index} style={{ padding: "10px 0" }}>
          {/* {console.log(certificate,"certificatecertificatecertificate")}  */}
          <ExtCertificateName>
            {" "}
            {certificate.certificatename}
          </ExtCertificateName>
          <ExtCertificateAcd>{certificate.organisationname}</ExtCertificateAcd>
          <ExtCertificateID>
            {" "}
            {certificate.certificateidnumber}
          </ExtCertificateID>
          <ExtCertificateDate>
            {" "}
            {formatDate(certificate.date)}{" "}
          </ExtCertificateDate>
        </ExternalCertificateWrapper>
      ))}
    </ExternalParticularContainer>
  );

  const renderLanguages = () => (
    <ExternalParticularContainer>
      <ExternalParticularHeadingWrapper Skills>
        <ExternalParticularHeadingName>Languages</ExternalParticularHeadingName>
      </ExternalParticularHeadingWrapper>
      <ExternalSkillsWrapper>
        {language.map((language, index) => (
          <ExternalSkillsList key={index}>
            {" "}
            {language.languagename}{" "}
          </ExternalSkillsList>
        ))}
      </ExternalSkillsWrapper>
    </ExternalParticularContainer>
  );

  const renderAchievement = () => (
    <ExternalParticularContainer>
      <ExternalParticularHeadingWrapper Skills>
        <ExternalParticularHeadingName>
          achievements
        </ExternalParticularHeadingName>
      </ExternalParticularHeadingWrapper>
      <ExternalSkillsWrapper>
        {achievementData.map((achievement, index) => (
          <Externalachivemeentlist key={index}>
            {achievement.skillname}
          </Externalachivemeentlist>
        ))}
      </ExternalSkillsWrapper>
    </ExternalParticularContainer>
  );

  const renderPrsnldetails = () => (
    <ExternalParticularContainer>
      <ExternalParticularHeadingWrapper Experience>
        <ExternalParticularHeadingName>
          Personal Information
        </ExternalParticularHeadingName>
      </ExternalParticularHeadingWrapper>
      <ExternalWorkExperienceWrapper>
        <ExternalPersonalWrapper>
          {props.resumeData.fathername && (
              <ExternalFatherSpauseNameKey>
              Father/ Spouse Name
            </ExternalFatherSpauseNameKey>
          )}
          <ExternalFatherSpauseNameValue>
            {props.resumeData.fathername}
          </ExternalFatherSpauseNameValue>
        </ExternalPersonalWrapper>
        <ExternalPersonalWrapper>
          {props.resumeData.maritalstatus && (
            <ExternalFatherSpauseNameKey>
            Marital Status
            </ExternalFatherSpauseNameKey>
          )}
          <ExternalFatherSpauseNameValue>
            {props.resumeData.maritalstatus}
          </ExternalFatherSpauseNameValue>
        </ExternalPersonalWrapper>
        <ExternalPersonalWrapper>
          {props.resumeData.userdob && (
          <ExternalFatherSpauseNameKey> DOB </ExternalFatherSpauseNameKey>
          )}
          <ExternalFatherSpauseNameValue>
            {props.resumeData.userdob}
          </ExternalFatherSpauseNameValue>
        </ExternalPersonalWrapper>
      </ExternalWorkExperienceWrapper>
    </ExternalParticularContainer>
  );

  const renderAdditionalDetail = () => (
    <ExternalParticularContainer>
      <ExternalParticularHeadingWrapper Skills>
        <ExternalParticularHeadingName>Hobbies</ExternalParticularHeadingName>
      </ExternalParticularHeadingWrapper>
      <ExternalSkillsWrapper>
        {additionalData.map((hobby, index) => (
          <ExternalSkillsList key={index}>{hobby.skillname}</ExternalSkillsList>
        ))}
      </ExternalSkillsWrapper>
    </ExternalParticularContainer>
  );
  return (
    <Root>
      <Container style={{ position: "relative" }}>
        <ShareButtonWrapper style={{Zindex:"9999999"}} onClick={handleShare} ref={iconContainerRef}>
          <ShareIcon src={ShareVector} />
        </ShareButtonWrapper>
        {showShareOptions && (
          <IconContainer
            className="share-options-modal"
            style={{ background: "#F8F8EC" }}
          >
            <SingleIconWrapper className="share-options-list">
              <SingleIcon onClick={() => handleShareOnPlatform("telegram")}>
                <TelegramIcon size={35} round />
              </SingleIcon>
              <SingleIcon onClick={() => handleShareOnPlatform("email")}>
                <EmailIcon size={35} round />
              </SingleIcon>
              <SingleIcon onClick={() => handleShareOnPlatform("linkedin")}>
                <LinkedinIcon size={35} round />
              </SingleIcon>
              <SingleIcon onClick={() => handleShareOnPlatform("whatsapp")}>
                <WhatsappIcon size={35} round />
              </SingleIcon>
            </SingleIconWrapper>
          </IconContainer>
        )}
        <ExternalWrapper>
          <BackgroundImageContainer>
         
            <BackgroundImage  src={getImage(props.resumeData?.banner) || ResumeBackground} />
          </BackgroundImageContainer>
          <ExternalWrapper>
            <ExternalLogoImageContainer>
              <ExternalLogoImage src={getImage(props.resumeData?.logo) || Resumelogo} />

              <ExternalNameWrapper>
                {props?.resumeData?.fullName}
              </ExternalNameWrapper>
              <ExternalDesignation>
                {props?.resumeData?.designation}
              </ExternalDesignation>
            </ExternalLogoImageContainer>
            <LeftRightWrapper>
              <LeftSideWrapper>
                <ExternalParticularContainer>
                  <ExternalParticularHeadingWrapper>
                    <ExternalParticularHeadingName>
                      Details
                    </ExternalParticularHeadingName>
                  </ExternalParticularHeadingWrapper>
                  <ExternalParticularkeyWrapper>
                    {props?.resumeData?.city && (
                     <ExternalAddressWrapper>Address</ExternalAddressWrapper>
                    )}
                   
                    <ExternalAddressValue>
                      {" "}
                      {props?.resumeData?.city}
                    </ExternalAddressValue>
                  </ExternalParticularkeyWrapper>
                  <ExternalParticularkeyWrapper>
                    <ExternalAddressWrapper>Phone</ExternalAddressWrapper>
                    <ExternalAddressValue>
                      {" "}
                      {props?.resumeData?.phone}
                    </ExternalAddressValue>
                  </ExternalParticularkeyWrapper>
                  <ExternalParticularkeyWrapper>
                    <ExternalAddressWrapper>Email</ExternalAddressWrapper>
                    <ExternalAddressValue>
                      {props?.resumeData?.email}
                    </ExternalAddressValue>
                  </ExternalParticularkeyWrapper>

                  <ExternalParticularkeyWrapper>
                    {props?.resumeData?.linkedIn && (
                        <ExternalAddressWrapper>LinkedIn</ExternalAddressWrapper>
                    )}
                  
                   
                     <a
                        href={props?.resumeData?.linkedIn}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <ExternalAddressValue >{props?.resumeData?.linkedIn}</ExternalAddressValue>
                      </a>
                  </ExternalParticularkeyWrapper>
                </ExternalParticularContainer>

                {props?.resumeData?.is_skills_is_enabled === 1 &&
                  renderSkills()}

                {props?.resumeData?.is_tools_is_enabled === 1 && renderTools()}

                {props?.resumeData?.is_certification_is_enabled === 1 &&
                  renderCertifications()}

                {props?.resumeData?.is_achievement_is_enabled === 1 &&
                  renderAchievement()}

                {props?.resumeData?.is_language_is_enabled === 1 &&
                  renderLanguages()}

                {props?.resumeData?.isEnabledAdddetails === 1 &&
                  renderAdditionalDetail()}
              </LeftSideWrapper>

              <RightSideWrapper>
                <ExternalParticularContainer PhoneResponsive>
                  <ExternalParticularHeadingWrapper>
                    <ExternalParticularHeadingName>
                      Details
                    </ExternalParticularHeadingName>
                  </ExternalParticularHeadingWrapper>
                  <ExternalParticularkeyWrapper>
                    <ExternalAddressWrapper>Address</ExternalAddressWrapper>
                    <ExternalAddressValue>
                      {" "}
                      {props?.resumeData?.city}
                    </ExternalAddressValue>
                  </ExternalParticularkeyWrapper>
                  <ExternalParticularkeyWrapper>
                    <ExternalAddressWrapper>Phone</ExternalAddressWrapper>
                    <ExternalAddressValue>
                      {" "}
                      {props?.resumeData?.phone}
                    </ExternalAddressValue>
                  </ExternalParticularkeyWrapper>
                  <ExternalParticularkeyWrapper>
                    <ExternalAddressWrapper>Email</ExternalAddressWrapper>
                    <ExternalAddressValue>
                      {props?.resumeData?.email}
                    </ExternalAddressValue>
                  </ExternalParticularkeyWrapper>

                  <ExternalParticularkeyWrapper>
                    <ExternalAddressWrapper>LinkedIn</ExternalAddressWrapper>
                    <a
                      // href={`${props?.formikData?.values?.linkedIn}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ textDecoration: "none" }}
                    >
                      <ExternalAddressValue style={{ cursor: "pointer" }}>
                        {props?.resumeData?.linkedIn}
                      </ExternalAddressValue>
                    </a>
                  </ExternalParticularkeyWrapper>
                </ExternalParticularContainer>

                <ExternalParticularContainer>
                {props?.resumeData?.summary && (
                  <ExternalParticularHeadingWrapper>
                         <ExternalParticularHeadingName>
                         Profile
                       </ExternalParticularHeadingName>                   
                  </ExternalParticularHeadingWrapper>
                   )}
                  <ExternalSummary>
                    {props?.resumeData?.summary}
                  </ExternalSummary>
                </ExternalParticularContainer>
                {props?.resumeData?.is_wrkExp_is_enabled === 1 &&
                  renderWorkExperience()}

                {props?.resumeData?.is_education_is_enabled === 1 &&
                  renderEducation()}

                {props?.resumeData?.is_project_is_enabled === 1 &&
                  renderProjects()}
                {props?.resumeData?.is_prsnldetail_is_enabled === 1 &&
                  renderPrsnldetails()}
              </RightSideWrapper>
            </LeftRightWrapper>
          </ExternalWrapper>
          <PoweredWrapper>
            <Power>
              Powered by <PowerSpan>BusinessBay</PowerSpan>
            </Power>
          </PoweredWrapper>
        </ExternalWrapper>
      </Container>
    </Root>
  );
};

export default Resume6Ext;
