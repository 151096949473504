import React from "react";
import "./Theme1.css";
import testimonialdefault from "../../../../Images/testimonialdefault.png";
import { environmentVariables } from "../../../../config/env.config";
const TestimonialCarousel = ({
  testimonials,
  currentElem,
  handleTouchStart,
  handleTouchMove,
  handleTouchEnd,
}) => {
  const testimonialStyle = {
    transform: `translateX(-${(currentElem * 100) / testimonials?.length}%)`,
    transition: "transform 0.5s ease-in-out",
    // width: `${testimonials * 100}%`,
  };
  const getImage = (val) => {
    if (val?.imageView) {
      return `${val?.imageView}`;
    }
    if (val?.image == "null") {
      return `${testimonialdefault}`;
    }
    if (val?.image && val?.image != null) {
      return `${environmentVariables?.apiUrl}uploads/${val?.image}`;
    }
  };
  //   console.log(testimonials, currentElem, "inside carousel");
  return (
    <div
      className="testimonials-container"
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
    >
      <div className="testimonials" style={testimonialStyle}>
        {testimonials.map((val, index) => {
          return (
            <div class="testibox" key={index}>
              <div className="testimonialcontent">
                <div className="testiimg">
                  <img
                    src={getImage(val)}
                    class="mx-auto d-block img-fluid clint-img2"
                    alt="Testimonial Image"
                  />
                </div>
                <div className="clintname">
                  <b className="testimonialname">
                    {val?.name != "undefined" ? val?.name : ""}
                  </b>
                  <p className="testimonialdescription">
                    {val?.description != "undefined" ? val?.description : ""}
                  </p>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default TestimonialCarousel;
