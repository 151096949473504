// Captcha.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import "./Captcha.css";
import RefreshIcon from "./../Images/refreshcaptcha.png";
// import RefreshIcon from "./../Images/"
import { environmentVariables } from "../config/env.config";

function Captcha({ onCaptchaSolved }) {
  const [captcha, setCaptcha] = useState({ question: '', answer: 0 });
  const [userAnswer, setUserAnswer] = useState('');
  const [invalidCaptchaErr, setInvalidCaptchaErr] = useState("");

  useEffect(() => {
    fetchCaptcha();
  }, []);

  const fetchCaptcha = async () => {
    const response = await axios.get(`${environmentVariables?.apiUrl}api/v1/business/fetchCaptcha`);
    setCaptcha(response.data);
    setUserAnswer('');
    onCaptchaSolved("");
  };

  const handleChange = (e) => {
    setUserAnswer(e.target.value);
    onCaptchaSolved(e.target.value);
  };

  const handleKeyPress = (e) => {
    // Allow only numeric input
    if (e.key && !/[0-9]/.test(e.key)) {
      e.preventDefault();
    }
  };

  return (
    <div>
         <p className='heading'>What is : {captcha.question}</p>
         <div className='inputbuttoncontainer'>
      <input
        type="text"
        value={userAnswer}
        onChange={handleChange}
        onKeyPress={handleKeyPress}
        placeholder="Input your answer"
          className='captchainput'
          maxLength={2}
      />
      {/* <button onClick={fetchCaptcha}>Refresh CAPTCHA</button> */}
      <button type="button" className='refresscaptchabtn' onClick={fetchCaptcha}>
        <img className='refreshicon' src={RefreshIcon} alt="Refresh CAPTCHA" /> Refresh
      </button>
      </div>
    </div>
  );
}

export default Captcha;
