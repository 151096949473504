import React from "react";
import styled from "styled-components";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { OrderDetail, OrderStatuskey, OrderreviewKeyValueWrapper, 
  OrderreviewValue, PlaceOrder, PlaceOrderWrapper, TabOrderReviewScreen, 
  TaborderDetailWrapper,DrawerCloseButtonWrapper,DrawerCloseButton } from "./Theme14ExtStyleComponent";


const Orderreview = ({ formData,createOrder,closeOrderReview }) => {
const formatDate = (date) => {
  const options = { month: "short", day: "numeric", year: "numeric" };
  return new Date(date).toLocaleDateString(undefined, options);
};

// Get today's date in the desired format
const today = formatDate(new Date());

const handlePlaceOrderClick = () => {
  createOrder();
  closeOrderReview();
};


  return (
    <TabOrderReviewScreen>
         <TaborderDetailWrapper>
           <OrderDetail>
             Order Review Screen
           </OrderDetail>
          </TaborderDetailWrapper>
  
          <OrderreviewKeyValueWrapper>
           <OrderStatuskey>Name</OrderStatuskey>
           <OrderreviewValue>{formData?.name}</OrderreviewValue>
         </OrderreviewKeyValueWrapper>
         <OrderreviewKeyValueWrapper>
           <OrderStatuskey> Phone </OrderStatuskey>
           <OrderreviewValue>{formData?.phone} </OrderreviewValue>
         </OrderreviewKeyValueWrapper>
         <OrderreviewKeyValueWrapper>
           <OrderStatuskey> Delivery Address  </OrderStatuskey>
           <OrderreviewValue> {formData?.city} {formData?.state} </OrderreviewValue>
         </OrderreviewKeyValueWrapper>
         {/* <OrderreviewKeyValueWrapper>
           <OrderStatuskey>Order ID</OrderStatuskey>
           <OrderreviewValue>#FM209391 </OrderreviewValue>
         </OrderreviewKeyValueWrapper> */}
         <OrderreviewKeyValueWrapper>
           <OrderStatuskey> Order date</OrderStatuskey>
           <OrderreviewValue>{today} </OrderreviewValue>
         </OrderreviewKeyValueWrapper>
         <OrderreviewKeyValueWrapper>
           <OrderStatuskey> Payment Method </OrderStatuskey>
           <OrderreviewValue>COD </OrderreviewValue>
         </OrderreviewKeyValueWrapper>
         <PlaceOrderWrapper>
           <button onClick={handlePlaceOrderClick}
           style={{background: "transparent", border: "none",fontWeight:"700",color:"#fff",fontSize:"24px"}}>
             Place Order
           </button>
         </PlaceOrderWrapper>
         <DrawerCloseButtonWrapper onClick={closeOrderReview}>
          <DrawerCloseButton>X</DrawerCloseButton>
        </DrawerCloseButtonWrapper>
       </TabOrderReviewScreen>
  );
};
export default Orderreview;
