import React, { useEffect, useState } from "react";
import ResumeLog from "../../../Images/Resume5Logo.png";
import {
  AchivementsList,
  AchivementsWrapper,
  AddressContainer,
  AddressKeyWrapper,
  AddressValueWrapper,
  CandidateDesignation,
  CandidateName,
  Card,
  CardName,
  CardNameWrapper,
  CardWrapper,
  CeartificationKeyName,
  CertificationValueName,
  CertificationWrapper,
  CompanyName,
  CompanyNameWrapper,
  Container,
  DesignationLocationDateWrapper,
  EducationBoardYear,
  EducationDegree,
  EducationSchoolName,
  EducationWrapper,
  ExpDesignation,
  InnerExpLocationDate,
  ExpSummary,
  ExperienceCompanyContainer,
  HeadingWrapper,
  HeadingWrapperContainer,
  InnerDesignationLocationDateWrapper,
  InnerLeftSideBar,
  InnerRightSideBar,
  InnerWrapper,
  LeftSideBar,
  LogoImage,
  LogoImageWrapper,
  NameDesignationWrapper,
  PersonalDetailContainer,
  PersonalNameKey,
  PersonalNameValue,
  PersonalNameWrapper,
  Power,
  PowerSpan,
  PoweredWrapper,
  ProDesignation,
  ProLocationDate,
  ProfileSummary,
  ProjectLink,
  RightSideBar,
  Root,
  ToolsWrapper,
  ToolsWrapperList,
  Wrapper,
  InnerCandidateName,
  InnerCandidateDesignation,
  InnerCardName,
  InnerAddressKeyWrapper,
  InnerAddressValueWrapper,
  InnerToolsWrapperList,
  InnerCeartificationKeyName,
  InnerCertificationValueName,
  InnerProLocationDate,
} from "./Resume5ExtStyle";
import { environmentVariables } from "../../../config/env.config";
import { useNavigate } from "react-router-dom";

const Resume5 = (props) => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const baseUrl = environmentVariables?.apiUrl;
  const navigate = useNavigate();

  const formatDate = (dateString) => {
    if (!dateString) return ""; // Return empty string if dateString is falsy

    const date = new Date(dateString); // Parse the date string
    const options = { month: "long", year: "numeric" }; // Format options
    return date.toLocaleDateString("en-US", options); // Format date to "Month-YYYY" format
  };

  const updateScreenWidth = () => {
    setScreenWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", updateScreenWidth);
    return () => {
      window.removeEventListener("resize", updateScreenWidth);
    };
  }, []);

  const renderWrkExp = () => {
    if (props?.onState?.workExp && props.workExpArray?.length > 0) {
      return (
        <HeadingWrapperContainer>
          <HeadingWrapper>Experience</HeadingWrapper>
          {props.workExpArray.map((wrkExp, index) => (
            <ExperienceCompanyContainer key={index}>
              <CompanyNameWrapper>
                <CompanyName>{wrkExp?.companyname}</CompanyName>
                <InnerDesignationLocationDateWrapper>
                  <ExpDesignation>{wrkExp?.jobtitle},</ExpDesignation>
                  <InnerExpLocationDate>
                    {`${wrkExp?.cityname || ""}, ${formatDate(
                      wrkExp?.startdate
                    )} - ${
                      wrkExp?.enddate === "current"
                        ? "Current"
                        : formatDate(wrkExp?.enddate)
                    }`}
                  </InnerExpLocationDate>
                </InnerDesignationLocationDateWrapper>
                <ExpSummary>{wrkExp?.workSummary}</ExpSummary>
              </CompanyNameWrapper>
            </ExperienceCompanyContainer>
          ))}
        </HeadingWrapperContainer>
      );
    } else {
      return null;
    }
  };

  const renderProjects = () => {
    if (props?.onState?.project && props.projectArray?.length > 0) {
      return (
        <HeadingWrapperContainer>
          <HeadingWrapper>PROJECT</HeadingWrapper>
          {props.projectArray.map((project, index) => (
            <ExperienceCompanyContainer key={index}>
              <CompanyNameWrapper>
                <InnerDesignationLocationDateWrapper ProjectsWrapper>
                  <ProDesignation>{project.projectname},</ProDesignation>
                  <InnerProLocationDate>
                    {`${formatDate(project.startDate)} - ${
                      project.currentlyWorking
                        ? "Current"
                        : formatDate(project.endDate)
                    }`}
                  </InnerProLocationDate>
                </InnerDesignationLocationDateWrapper>
                <ExpSummary>{project.projectdescription}</ExpSummary>
                <ProjectLink href={project.projectlink} target="_blank">
                  {project.projectlink}
                </ProjectLink>
              </CompanyNameWrapper>
            </ExperienceCompanyContainer>
          ))}
        </HeadingWrapperContainer>
      );
    } else {
      return null;
    }
  };

  const renderSkills = () => {
    if (props?.onState?.services && props.skillArray?.length > 0) {
      return (
        <CardWrapper>
          <Card>
            <CardNameWrapper>
              <InnerCardName>Skills</InnerCardName>
            </CardNameWrapper>
            <ToolsWrapper>
              {props.skillArray.map((skill, index) => (
                <InnerToolsWrapperList key={index}>
                  {skill.skillname}
                </InnerToolsWrapperList>
              ))}
            </ToolsWrapper>
          </Card>
        </CardWrapper>
      );
    } else {
      return null;
    }
  };

  const renderTools = () => {
    if (props?.onState?.tools) {
      return (
        <CardWrapper>
          <Card>
            <CardNameWrapper>
              <InnerCardName>Tools</InnerCardName>
            </CardNameWrapper>
            <ToolsWrapper>
              {props.toolsArray.map((tool, index) => (
                <InnerToolsWrapperList key={index}>
                  {tool.toolname}
                </InnerToolsWrapperList>
              ))}
            </ToolsWrapper>
          </Card>
        </CardWrapper>
      );
    } else {
      return null;
    }
  };

  const renderEducation = () => {
    if (props?.onState?.isExperience && props.socialMediaData?.length > 0) {
      return (
        <HeadingWrapperContainer>
          <HeadingWrapper>Education</HeadingWrapper>
          {props.socialMediaData.map((education, index) => (
            <EducationWrapper key={index}>
              {/* {console.log(education,"educationeducationeducation")} */}
              <EducationDegree>{education.degree}</EducationDegree>
              <EducationSchoolName>{education.schuniname}</EducationSchoolName>
              <EducationSchoolName>{education.boardname } {education.location}</EducationSchoolName>
              <EducationBoardYear>
                {education.graduationendyear
                  ? `${formatDate(education.graduationyear)} - ${formatDate(
                      education.graduationendyear
                    )}`
                  : formatDate(education.graduationyear)}
              </EducationBoardYear>
            </EducationWrapper>
          ))}
        </HeadingWrapperContainer>
      );
    } else {
      return null;
    }
  };

  const renderLanguage = () => {
    if (props?.onState?.language) {
      return (
        <CardWrapper>
          <Card>
            <CardNameWrapper>
              <InnerCardName>Languages</InnerCardName>
            </CardNameWrapper>
            <ToolsWrapper>
              {props.languageArray.map((language, index) => (
                <InnerToolsWrapperList key={index}>
                  {language.languagename}
                </InnerToolsWrapperList>
              ))}
            </ToolsWrapper>
          </Card>
        </CardWrapper>
      );
    } else {
      return null;
    }
  };

  const renderAdditionalDetail = () => {
    if (props?.onState?.adddetails && props.additionaldetailArray.length > 0) {
      return (
        <CardWrapper>
          <Card>
            <CardNameWrapper>
              <InnerCardName>Hobbies</InnerCardName>
            </CardNameWrapper>
            <ToolsWrapper>
              {props.additionaldetailArray.map((detail, index) => (
                <InnerToolsWrapperList key={index}>
                  {detail.skillname}
                </InnerToolsWrapperList>
              ))}
            </ToolsWrapper>
          </Card>
        </CardWrapper>
      );
    } else {
      return null;
    }
  };

  const renderPrsnldetails = () => {
    if (props?.onState?.prsnldetail) {
      return (
        <HeadingWrapperContainer>
          <HeadingWrapper>Personal Information</HeadingWrapper>
          <PersonalDetailContainer>
            <PersonalNameWrapper>
              <PersonalNameKey>Father/ Spouse Name</PersonalNameKey>
              <PersonalNameValue>
                {props?.formikData?.values?.fathername}
              </PersonalNameValue>
            </PersonalNameWrapper>
            <PersonalNameWrapper>
              <PersonalNameKey>Marital Status</PersonalNameKey>
              <PersonalNameValue>
                {props?.formikData?.values?.maritalstatus}
              </PersonalNameValue>
            </PersonalNameWrapper>
            <PersonalNameWrapper>
              <PersonalNameKey>DOB</PersonalNameKey>
              <PersonalNameValue>
                {props?.formikData?.values?.userdob}
              </PersonalNameValue>
            </PersonalNameWrapper>
          </PersonalDetailContainer>
        </HeadingWrapperContainer>
      );
    }
  };

  const renderAchievement = () => {
    if (props?.onState?.achievement && props.achievementArray.length > 0) {
      return (
        <HeadingWrapperContainer>
          <HeadingWrapper>Achievements</HeadingWrapper>
          <AchivementsWrapper>
            {props.achievementArray.map((achievement, index) => (
              <AchivementsList key={index}>
                {achievement.skillname}
              </AchivementsList>
            ))}
          </AchivementsWrapper>
        </HeadingWrapperContainer>
      );
    } else {
      return null;
    }
  };

  const renderCertifications = () => {
    if (props?.onState?.certification && props.certificationArray.length > 0) {
      return (
        <CardWrapper>
          <Card>
            <CardNameWrapper>
              <InnerCardName>Certifications</InnerCardName>
            </CardNameWrapper>
            <CertificationWrapper>
              {props.certificationArray.map((certification, index) => (
                <div key={index}>
                  {/* {console.log(certification,"certificationcertificationcertification")} */}
                  <InnerCeartificationKeyName>
                    {certification.certificatename}
                  </InnerCeartificationKeyName>
                  <InnerCertificationValueName>
                    {certification.organisationname}
                  </InnerCertificationValueName>
                  <InnerCertificationValueName>
                    {certification.certificateidnumber}
                  </InnerCertificationValueName>
                  <InnerCertificationValueName>
                    {formatDate(certification.date)}
                  </InnerCertificationValueName>
                </div>
              ))}
            </CertificationWrapper>
          </Card>
        </CardWrapper>
      );
    }
  };

  return (
    <Root style={{ width: "420px" }}>
      <Container>
        <InnerWrapper>
          <InnerLeftSideBar>
            <LogoImageWrapper LeftSideImageLogo>
              <LogoImage
                src={
                  props?.logoImageUrl
                    ? props?.logoImageUrl?.includes("base64")
                      ? props?.logoImageUrl
                      : props?.logoImageUrl == "null"
                      ? ""
                      : `${baseUrl}uploads/resume/${props?.logoImageUrl}`
                    : ResumeLog
                }
                alt="logo"
              />
            </LogoImageWrapper>
            <NameDesignationWrapper LeftSideImageLogo>
              <InnerCandidateName>
                {" "}
                {props?.formikData?.values?.fullName || "Sushant"}
              </InnerCandidateName>
              <InnerCandidateDesignation>
                {props?.designation || "React"}
              </InnerCandidateDesignation>
            </NameDesignationWrapper>

            <CardWrapper LeftSideImageLogo>
              <Card>
                <CardNameWrapper>
                  <InnerCardName>Details</InnerCardName>
                </CardNameWrapper>
                <AddressContainer>
                  <InnerAddressKeyWrapper>Address</InnerAddressKeyWrapper>
                  <InnerAddressValueWrapper>
                    {" "}
                    {props?.formikData?.values?.address}
                  </InnerAddressValueWrapper>
                </AddressContainer>

                <AddressContainer>
                  <InnerAddressKeyWrapper>Phone</InnerAddressKeyWrapper>
                  <InnerAddressValueWrapper>
                    {" "}
                    {props?.formikData?.values?.phone}
                  </InnerAddressValueWrapper>
                </AddressContainer>

                <AddressContainer>
                  <InnerAddressKeyWrapper>Email </InnerAddressKeyWrapper>
                  <InnerAddressValueWrapper>
                    {" "}
                    {props?.formikData?.values?.email}
                  </InnerAddressValueWrapper>
                </AddressContainer>

                <AddressContainer>
                  <InnerAddressKeyWrapper>Linkdin </InnerAddressKeyWrapper>
                  <InnerAddressValueWrapper href="/">
                    {" "}
                    {props?.formikData?.values?.linkedIn}
                  </InnerAddressValueWrapper>
                </AddressContainer>
              </Card>
            </CardWrapper>
            
            {props?.onState?.tools && renderTools()}
            {props?.onState?.services && renderSkills()}
            {props?.onState?.language && renderLanguage()}
            {props?.onState?.certification && renderCertifications()}
            {props?.onState?.adddetails && renderAdditionalDetail()}
          </InnerLeftSideBar>
          <InnerRightSideBar>
            <LogoImageWrapper RightSideImageHandle>
              <LogoImage
                src={
                  props?.logoImageUrl
                    ? props?.logoImageUrl?.includes("base64")
                      ? props?.logoImageUrl
                      : props?.logoImageUrl == "null"
                      ? ""
                      : `${baseUrl}uploads/resume/${props?.logoImageUrl}`
                    : ResumeLog
                }
                alt="logo"
              />
            </LogoImageWrapper>
            <NameDesignationWrapper RightSideImageHandle>
              <InnerCandidateName>Joseph Gonzalez</InnerCandidateName>
              <InnerCandidateDesignation>
                Creative designer
              </InnerCandidateDesignation>
            </NameDesignationWrapper>
            <CardWrapper RightSideImageHandle>
              <Card RightSideCardResp>
                <CardNameWrapper>
                  <InnerCardName>Details</InnerCardName>
                </CardNameWrapper>
                <AddressContainer>
                  <InnerAddressKeyWrapper>Address</InnerAddressKeyWrapper>
                  <InnerAddressValueWrapper>
                    {props?.formikData?.values?.address}
                  </InnerAddressValueWrapper>
                </AddressContainer>

                <AddressContainer>
                  <InnerAddressKeyWrapper>Phone</InnerAddressKeyWrapper>
                  <InnerAddressValueWrapper>
                    {props?.formikData?.values?.phone}
                  </InnerAddressValueWrapper>
                </AddressContainer>

                <AddressContainer>
                  <InnerAddressKeyWrapper>Email </InnerAddressKeyWrapper>
                  <InnerAddressValueWrapper>
                    {props?.formikData?.values?.email}
                  </InnerAddressValueWrapper>
                </AddressContainer>
                <AddressContainer>
                  <InnerAddressKeyWrapper>Linkdin </InnerAddressKeyWrapper>
                  <InnerAddressValueWrapper href="/">
                    {" "}
                    {props?.formikData?.values?.linkedIn}
                  </InnerAddressValueWrapper>
                </AddressContainer>
              </Card>
            </CardWrapper>
            <HeadingWrapperContainer ResponsivePadManage>
              <HeadingWrapper>profile</HeadingWrapper>
              <ProfileSummary>
                {props?.formikData?.values?.description}
              </ProfileSummary>
            </HeadingWrapperContainer>
            {props?.onState?.workExp && renderWrkExp()}
            {props?.onState?.project && renderProjects()}
            {props?.onState?.isExperience && renderEducation()}
            {props?.onState?.achievement && renderAchievement()}
            {props?.onState?.prsnldetail && renderPrsnldetails()}
          </InnerRightSideBar>
        </InnerWrapper>
        <PoweredWrapper>
          <Power>
            Powered by <PowerSpan>BusinessBay</PowerSpan>
          </Power>
        </PoweredWrapper>
      </Container>
    </Root>
  );
};

export default Resume5;
