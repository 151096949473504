import React, { useContext, useEffect, useState, useRef } from "react";
import PageName from "../Utils/PageName";
import { environmentVariables } from "../../config/env.config";
import axios from "axios";
import { userContext } from "../../context/userContext";
import DataTable from "react-data-table-component";
import SortIcon from "@material-ui/icons/ArrowDownward";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import { businessObjContext } from "../../context/businessObjContext";
import CircularLoader from "../Components/CircularLoader/CircularLoader";
import "./UpdateRequestcardstyle.css";
import { useCallback, useMemo } from "react";
import Modal from "@mui/joy/Modal";
import ModalClose from "@mui/joy/ModalClose";
import Typography from "@mui/joy/Typography";
import Sheet from "@mui/joy/Sheet";

// Modal.setAppElement('#root');

const CabEnquiry = () => {
  const [data, setData] = useState([]);
  const [selectedMessage, setSelectedMessage] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const baseUrl = environmentVariables?.apiUrl;
  const { userData, setUserData } = useContext(userContext);
  const { businessObjData, setBusinessObjData } =
    useContext(businessObjContext);
  const [isLoading, setIsLoading] = useState(true);
  const tableExtensions = { export: false, print: false };
  const [action] = useState({ fromUser: false });
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [paginationTotalRows, setPaginationTotalRows] = useState(0);
  const [paginationPerPage, setPaginationPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedRowsPerPage, setSelectedRowsPerPage] = useState([]);
  const [open, setOpen] = useState(false);



  const fetchOrders = async (page) => {
    try {
      const response = await axios.get(`${baseUrl}api/v1/business/fetch_cab_enquiry`, {
        headers: {
          _token: userData?.token,
        },
        params: {
          userId: userData.id,
          page: page,
          pageSize: rowsPerPage
        }
      });
      setPaginationTotalRows(response?.data?.pagination?.totalCount)
      setIsLoading(false);
      setData(response?.data?.data?.records)
    } catch (error) {
      console.error('Error fetching Enquiries:', error);
      setIsLoading(false);
    }
  };

  const customStyles = {
    dataTableExtensions: {
      backgroundColor: "lightgray",
      padding: "10px",
    },
    headRow: {
      style: {
        backgroundColor: "#e9e9e9",
        borderRadius: "2px 2px 0 0",
        paddingLeft: "25px",
        border: "none",
        textTransform: "capitalize",
        position: "sticky",
        left: 0,
        top: 0,
        zIndex: 1,
        boxShadow: "0 2px 2px -1px rgba(0, 0, 0, 0.1)",
        overflow: "hidden",
        whiteSpace: "nowrap",
      },
    },
    headCells: {
      style: {
        color: "#525252",
        fontSize: "16px",
        minHeight: "52px",
      },
    },
    rows: {
      style: {
        padding: "6px 10px 6px 25px",
        fontSize: "14px",
        textTransform: "capitalize",
        minHeight: "48px !important",
      },
    },
    BusinessColumn: {
      position: "sticky",
      left: "0",
      zIndex: "1",
      backgroundColor: "#fff",
      overflow: "hidden",
      whiteSpace: "nowrap",
    }
  };

  const handleOnSelectedRowsChange = useCallback(
    ({ selectedRows }) => {
      if (!action.fromUser) return; //the component always trigger this with 0 selected rows when it renders a page, what would clear the selection

      selectedRowsPerPage[currentPage] = selectedRows; //there is no way to tell if a row was DEselected, so I had to control the selected rows per page,
      //the array would get an index to control each page
      // console.log(JSON.stringify(selectedRowsPerPage));
    },
    [currentPage, selectedRowsPerPage, action.fromUser]
  );

  const handlePageChange = (page) => {
    const nextPage = page;
    fetchOrders(nextPage);
    setCurrentPage(page);
  };

  const handleRowsPerPageChange = async (newRowsPerPage) => {
    setRowsPerPage(newRowsPerPage);
    setCurrentPage(1); // Reset current page to 1
    setSelectedRowsPerPage([]);
    fetchOrders(1);
  };

  useEffect(() => {
    fetchOrders();
  }, [rowsPerPage]);

  useEffect(() => {
    fetchOrders();
  }, [businessObjData]);

  const handleViewMessage = (message) => {
    setOpen(true);
    setSelectedMessage(message); // Set the selected message
    setIsModalOpen(true); // Open the modal
  };

  const handleCloseModal = () => {
    setIsModalOpen(false); // Close the modal
  };

  const handleWhatsAppRedirect = (phoneNumber) => {
    const formattedPhoneNumber = phoneNumber.replace(/[^0-9]/g, ""); 
    const message = "Hello, you have placed an inquiry for the 'Cab' , Please let me know how I can help?";
    const whatsappURL = `https://wa.me/${formattedPhoneNumber}?text=${encodeURIComponent(message)}`;
    window.open(whatsappURL, "_blank");
  };


  const columns = [
    {
      name: "Business Name",
      selector: row => row.businessTitle,
      sortable: true,
      width: "10%",
      cell: (row) => (
        <div
          title={row.businessTitle} // Native tooltip
          style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", pointerEvents:"none" }}
        >
          {row.businessTitle}
        </div>
      ),

    },
    {
      name: "Date",
      selector: (row) => {
        const date = new Date(row?.created_at);
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
      },
      sortable: true,
    },
    {
      name: "Customer Phone",
      selector: row => {
        return (((row.countryCode) ? row.countryCode : "+91") + "-" + row.phone)
      },
      sortable: true,
    },
    {
      name: "From",
      selector: row => row.from,
      sortable: true,
    },
    {
      name: "To",
      selector: row => row.to,
      sortable: true,
    },
    {
      name: "Return",
      selector: row => row.return,
      sortable: true,
    },
    {
      name: "Time",
      selector: row => row.time,
      sortable: true,
    },
    {
      name: "Type",
      selector: row => row.type,
      sortable: true,
    },
    {
      name: "Message",
      selector: row => {
        return (<p style={{ fontSize: "14px" }}> {row.message} </p>)
      },
      sortable: true,
      cell: (row) => (
        <div
          style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", pointerEvents:"none" }}
        >
          {row.message}
        </div>
      ),
    },
    {
      name: "Action",
      sortable: false,
      cell: (row) => (
        <i
          className="first fas fa-eye"
          onClick={() => handleViewMessage(row)} // Handle view action
          style={{ cursor: "pointer", marginRight: "10px" }}
        ></i>
      ),
    },


  ];
  const tableData = {
    columns,
    data,
  };

  return (
    <>
      <PageName PageName="Cab Enquiries" />
      <article>
        <div className="main">
          {isLoading ? (
            <div
              style={{
                position: "absolute",
                top: "50%",
                left: "55%",
                display: "flex",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <CircularLoader size={40} />
            </div>
          ) : (
            <>
              <div className="scrollable-columns">
                <div className="sticky-header-container">
                  <DataTableExtensions
                    {...tableData}
                    {...tableExtensions}
                    customStyles={customStyles}
                    filterPlaceholder="Search Orders"
                  >
                    <DataTable
                      columns={columns}
                      data={data}
                      noHeader
                      defaultSortField="id"
                      sortIcon={<SortIcon />}
                      defaultSortAsc={true}
                      pagination
                      highlightOnHover
                      paginationServer
                      paginationTotalRows={paginationTotalRows}
                      paginationPerPage={rowsPerPage}
                      paginationRowsPerPageOptions={[10, 20, 50, 100, 300, 500, 1000, 10000]} // Customize as needed
                      onChangePage={handlePageChange}
                      onChangeRowsPerPage={handleRowsPerPageChange}
                      onSelectedRowsChange={handleOnSelectedRowsChange}
                      dense
                      customStyles={customStyles}
                    />
                  </DataTableExtensions>
                </div>
              </div>
            </>
          )}
        </div>
        {/* <Modal
          isOpen={isModalOpen}
          onRequestClose={handleCloseModal}
          contentLabel="View Message"
          style={{
            content: {
              top: '50%',
              left: '50%',
              right: 'auto',
              bottom: 'auto',
              marginRight: '-50%',
              transform: 'translate(-50%, -50%)',
              width: '50%',
              padding: '20px',
            },
          }}
        >
          <h2>Message</h2>
          <p>{selectedMessage}</p>
          <button onClick={handleCloseModal}>Close</button>
        </Modal> */}
           <Modal
          aria-labelledby="modal-title"
          aria-describedby="modal-desc"
          open={open}
          onClose={() => setOpen(false)}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          style={{outline: "none"}}
        >
          <Sheet
            variant="outlined"
            sx={{
              maxWidth: 500,
              borderRadius: "md",
              p: 3,
              boxShadow: "lg",
            }}
          >
            <ModalClose variant="plain" sx={{ m: 1 }} />
            <Typography
              component="h2"
              id="modal-title"
              level="h4"
              textColor="inherit"
              fontWeight="lg"
              mb={2}
              textAlign="center"
            >
              ENQUIRY DETAILS
            </Typography>
            {/* <div className="modalparacontainer">
              <div className="modalheadingdiv">
                Enquiry From:

              </div>

             <div className="modalcontentdiv"> 
              {selectedMessage?.enquiry_from === 1
                ? "Marketplace"
                : "Mini Marketplace"}
              </div>
            </div> */}

            <div className="modalparacontainer">
              <div className="modalheadingdiv">
              Business Name:

              </div>

             <div className="modalcontentdiv"> 
             {selectedMessage?.businessTitle}
              </div>
            </div>

            <div className="modalparacontainer">
              <div className="modalheadingdiv">
              Date:

              </div>

             <div className="modalcontentdiv"> 
             {(() => {
                const date = new Date(selectedMessage?.created_at);
                const day = date.getDate().toString().padStart(2, "0");
                const month = (date.getMonth() + 1).toString().padStart(2, "0");
                const year = date.getFullYear();
                return `${day}/${month}/${year}`;
              })()}
              </div>
            </div>
        
            {/* <div className="modalparacontainer">
              <div className="modalheadingdiv">
              Customer Phone:

              </div>
              
             <div className="modalcontentdiv" style={{display:"flex"}}> 
            <div style={{paddingRight:"5px"}}> 
            <i class="fa-brands fa-whatsapp"></i></div>
             {(selectedMessage?.countryCode
                ? selectedMessage.countryCode
                : "+91") +
                "-" +
                selectedMessage?.phone}
                
              </div>
            </div> */}

          <div className="modalparacontainer">
              <div className="modalheadingdiv">Customer Phone:</div>
              <div className="modalcontentdiv">
                <div
                  style={{ cursor: "pointer", color: "green" }}
                  onClick={() => handleWhatsAppRedirect(
                    (selectedMessage?.countryCode ? selectedMessage?.countryCode : "+91") + selectedMessage?.phone,
                    `Hi, I am ${userData?.name || 'Customer'}`,
                  )}
                >
                  <i className="fa-brands fa-whatsapp" style={{ marginRight: "5px" }}></i>
                  {(selectedMessage?.countryCode ? selectedMessage?.countryCode : "+91") + selectedMessage?.phone}
                </div>
              </div>
            </div>

            <div className="modalparacontainer">
              <div className="modalheadingdiv">
              Type:
              </div>
             <div className="modalcontentdiv"> 
             {selectedMessage?.type ? selectedMessage?.type : " - "}
              </div>
            </div>

            <div className="modalparacontainer">
              <div className="modalheadingdiv">
              Message:

              </div>

             <div className="modalcontentdiv"> 
             {selectedMessage?.message ? selectedMessage.message : "Not Available"}
              </div>
            </div>
          </Sheet>
        </Modal>
      </article>
    </>
  );
};
export default CabEnquiry;
