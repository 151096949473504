import axios from "axios";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { environmentVariables } from "../../config/env.config";

const Root = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 20px;
`;

const CardWrapper = styled.div`
  background: #ffffff;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 0px 17px 2px rgb(0 0 0 / 6%);
  margin-bottom: 30px;
  transition: all 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;
const Card = styled.div`
  font-size: 18px;
  color: #463737;
`;

const IconWrapper = styled.div``;
const Icon = styled.a`
  font-size: 14px;
`;

const ModalBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  // height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 60px;

`;

const ModalContent = styled.div`
  background-color: white;
  // padding: 20px;
  border-radius: 8px;
  position: relative;
  max-height: 100vh; 
  overflow-y: auto; 
  margin:20px 0;
  z-index: 1000;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  cursor: pointer;
  color: #ffffff;
  font-size: 12px;
  font-weight: 900;
  background-color: red;
  border-radius: 50px;
`;

const MailTemplate = () => {
  const [templaData, setTemplateDate] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState("");
  const baseUrl = environmentVariables?.apiUrl;

  const getData=()=>{
    axios
    .get(`${baseUrl}api/v1/admin/mailTemplate`)
    .then((res) => {
      // console.log("asdasd", res.data);
      setTemplateDate(res.data);
    })
    .catch((err) => {
      console.log(err);
    });
  }
  
  useEffect(() => {
    getData()
  }, [])

  const handleEyeClick = (content) => {
    setModalContent(content);
    setShowModal(true);
  };
  
// console.log(templaData,"templaData123")
  return (
    <Root>
      {templaData.length > 0 &&
        templaData.map((data, key) => {
          return (
            <CardWrapper key={key}>
              <Card> {data.template_name}</Card>
              <div> {data.template_key}</div>
              <IconWrapper>
                <Icon onClick={() => handleEyeClick(data.html_content)}>
                  <i class="fa-solid fa-eye"></i>
                </Icon>
              </IconWrapper>
            </CardWrapper>
          );
        })}
      {showModal && (
        <ModalBackground>
          <ModalContent>
          <div dangerouslySetInnerHTML={{ __html: modalContent }} />
          <CloseButton onClick={() => setShowModal(false)}>X</CloseButton>
          </ModalContent>
        </ModalBackground>
      )}
    </Root>
  );
};

export default MailTemplate;
