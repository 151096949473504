import React, { useState, useEffect, useRef } from "react";
import { environmentVariables } from "../../../config/env.config";
import testimonialdefault from "../../../Images/testimonialdefault.png";
import avatarImage from "../../../Images/avatar.png";
import "./../Updatedcss/BusinessChangeBlockUpdatedStyle.css";
import { useFormik } from "formik";
import * as Yup from "yup";
import styled from "styled-components";
import Swal from "sweetalert2";

const FormikErrorStyle = styled.div`
  font-size: 11px;
  color: #ff3333;
`;

const ResumeCertificationForm = ({
  formCount,
  testimonialData,
  setIsAddTestimonialDisable,
  setTestimonialData,
  index,
  setFormEditable,
  formEditable,
  removeForm,
}) => {
  const fileInputRef = useRef();
  const openFileInput = () => {
    fileInputRef.current.click();
  };
  const baseUrl = environmentVariables?.apiUrl;
  const [image, setImage] = useState(null);
  const [addBtnDisable, setAddBtnDisable] = useState(false);
  const [isDuplicateId, setIsDuplicateId] = useState(false);

  const [data, setData] = useState({
    formCount: formCount,
    image: null,
    certificatename: "",
    organisationname: "",
    date: "",
    enddate:"",
    imageView: null,
    isImageUpload: true,
    certificateidnumber:""
  });

  const getImage = (data) => {
    // data["imageView"]
    //   ? data["imageView"]
    //   : data["image"]
    //   ? `${baseUrl}uploads/${data["image"]}`
    //   : avatarImage;

    if (data["imageView"]) {
      return data["imageView"];
    } else {
      if (data["image"]) {
        if (data["image"] != "null") {
          return `${baseUrl}uploads/${data["image"]}`;
        } else {
          return testimonialdefault;
        }
      } else {
        return avatarImage;
      }
    }
  };

  const validationSchema = Yup.object({
    certificatename: Yup.string()
      // .matches(/^[a-zA-Z' -]+$/, "Only alphabets are allowed")
      .matches(/^[a-zA-Z0-9' -]+$/, "Only alphabets and Numbers are allowed")
      .required("Certification name is required")
      .min(2, "Minimum 2 characters")
      .max(45, "Maximum 45 Characters"),
    organisationname: Yup.string()
      // .matches(/^[a-zA-Z' -]+$/, "Only alphabets are allowed")
      .matches(/^[a-zA-Z0-9' -]+$/, "Only alphabets and Numbers are allowed")
      .required("Organisation name is required")
      .min(2, "Minimum 2 characters")
      .max(45, "Maximum 45 Characters"),

      certificateidnumber: Yup.string()
      // .matches(/^[a-zA-Z' -]+$/, "Only alphabets are allowed")
      .matches(/^[a-zA-Z0-9' -]+$/, "Only alphabets and Numbers are allowed")
     
      .min(2, "Minimum 2 characters")
      .max(45, "Maximum 45 Characters"),
    // date: Yup.string()
    //   .required("Date is required")
    //   .matches(/^[0-9]{4}-[0-9]{2}-[0-9]{2}$/, "Invalid date format (YYYY-MM-DD)"),
  });

  const formik = useFormik({
    initialValues: {
      certificatename: data.certificatename || "",
      date: data.date || "",
      enddate: data.enddate || "",
      organisationname: data.organisationname || "",
      certificateidnumber: data.certificateidnumber || "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleAddService(values);
    },
  });

  useEffect(() => {
    if (
      formik.values.certificatename &&
      formik.values.date &&
      formik.values.enddate &&
      formik.organisationname && 
      formik.values.certificateidnumber

    ){
      handleAddService(formik.values);
    }
  }, [formik.values]);

  // console.log(formik.values,"formikvaluescertificate")

  const handleAddService = (values) => {
    if ( formEditable == 0 || formEditable) {
      formCount = formEditable;
      // values.organisationname = data?.organisationname
    }
    const isDuplicate = testimonialData.some(
      (item, idx) =>
        idx !== index && item.certificateidnumber === values.certificateidnumber
    );
  if (isDuplicate) {
    // Set duplicate ID state to true
    setIsDuplicateId(true);
    setAddBtnDisable(false);
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: 'Certification ID/number must be unique. Please choose a different ID.',
    });
    return;
  } else {
    // Reset duplicate ID state
    setIsDuplicateId(false);
  }
    setAddBtnDisable(true);
    // console.log(testimonialData,"testimonialData!@#",data,"@!#",formCount)
    setIsAddTestimonialDisable(false);
    setFormEditable(null)
    const newTestimonialData = testimonialData.map((val, index) => {
      if (val.formCount == formCount) {
        val.certificatename = values?.certificatename;
        val.organisationname = values?.organisationname;
        val.date = data?.date;
        val.enddate = data?.enddate
        val.image = image;
        val.imageView = data?.imageView;
        val.isImageUpload =  false // data?.isImageUpload;
        val.certificateidnumber = values?.certificateidnumber
        return val;
      }
      return val;
    });
    // const newTestimonialData = testimonialData.map((val,index) => {
    //   if (index ==data?.formCount) {
    //     val.name = data?.name;
    //     val.description = data?.description;
    //     val.image = image||data?.image;
    //     val.imageView = data?.image;
    //     return val;
    //   }
    //   return val;
    // });
    // console.log(newTestimonialData, "newTestimonialData@!#");
    setTestimonialData(newTestimonialData);
  };


  const handleEditService = (index) => {
    setAddBtnDisable(false);
    let currentForm = testimonialData[index];
    setFormEditable(index);
    setData({
      certificatename: currentForm?.certificatename,
      organisationname: currentForm?.organisationname,
      date: currentForm?.date,
      enddate: currentForm?.enddate,
      image: currentForm?.image,
      imageView: currentForm?.imageView,
      formCount: index,
      isImageUpload: true,
      certificateidnumber: currentForm?.certificateidnumber
    });
  };

  const handleChangeImage = (e) => {
    setImage(e.target.files);
    if (e.target.files.length > 0) {
      const reader = new FileReader();
      reader.onload = () => {
        // setImage(reader.result);
        setData({ ...data, imageView: reader.result });
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  };
  useEffect(() => {
    // console.log( testimonialData, "234testimonialData23")
    let currentForm = testimonialData[index];
    // console.log(currentForm,"currentForm21")
    // currentForm=currentForm[0]
    setData({
      certificatename: currentForm?.certificatename,
      organisationname: currentForm?.organisationname,
      date: currentForm?.date,
      enddate: currentForm?.enddate,
      image: currentForm?.image,
      imageView: currentForm?.imageView,
      formCount: index,
      isImageUpload: currentForm?.isImageUpload,
      certificateidnumber: currentForm?.certificateidnumber
    });
    // if (currentForm.length > 0) {
    // console.log(currentForm)
    // }
  }, [testimonialData]);

  return (
    <div
      key={`form-${formCount}`}
      className="col-12"
      id="AddNewStyleFormfieldpad"
    >
      <div className="serviceboxedittesti MobileviewServices">
        <i
          className="fa-solid fa-circle-xmark crossedit"
          onClick={() => removeForm(index)}
        >
          {" "}
        </i>

        <div className="AddDetailsTextimonialphnview" id="ResumeProjectdetails">
          {/* <span onClick={() => handleEditService(index)}>X</span> */}
          <span onClick={() => handleEditService(index)} style={{padding:"2px 15px 10px 0px", cursor:"pointer", textAlign:"end", width:"100%"}}><i class="fa-solid fa-pen-to-square"></i></span>

          <div
            className=""
            style={{ fontSize: "16px", fontWeight: "500", width: "100%" }}
          >
            {/* <label className="form-label">Certificate</label> */}
            {data["isImageUpload"] && !addBtnDisable ? (
              <div>
                <input
                  {...formik.getFieldProps("certificatename")}
                  className="form-control"
                  type="text"
                  placeholder="Certificate Name"
                  onChange={(e) => {
                    setData({ ...data, certificatename: e.target.value })
                  formik.setFieldValue("certificatename", e.target.value)}}
                  value={data["certificatename"] == "undefined" ? "" : data["certificatename"]}
                />
                {formik.touched.certificatename &&
                formik.errors.certificatename ? (
                  <FormikErrorStyle id="ResumeProjectdetails">
                    {formik.errors.certificatename}
                  </FormikErrorStyle>
                ) : null}
              </div>
            ) : (
              <div>
                {data["certificatename"] == "undefined"
                  ? ""
                  : data["certificatename"]}
              </div>
            )}
          </div>

          <div
            className=""
            style={{ fontSize: "16px", fontWeight: "500", width: "100%" }}
          >
            {data["isImageUpload"] && !addBtnDisable ? (
              <div>
                <input
                  {...formik.getFieldProps("organisationname")}
                  className="form-control"
                  type="link"
                  placeholder="Organisation Name"
                  onChange={(e) => {setData({ ...data, organisationname: e.target.value })
                formik.setFieldValue("organisationname", e.target.value)}}
                  value={data["organisationname"] == "undefined" ? "" : data["organisationname"]}

                />
                {formik.touched.organisationname &&
                formik.errors.organisationname ? (
                  <FormikErrorStyle>
                    {formik.errors.organisationname}
                  </FormikErrorStyle>
                ) : null}
              </div>
            ) : (
              <div>
                {data["organisationname"] == "undefined"
                  ? ""
                  : data["organisationname"]}
              </div>
            )}
          </div>

          <div
            className=""
            style={{ fontSize: "16px", fontWeight: "500", width: "100%" }}
          >
            {data["isImageUpload"] && !addBtnDisable ? (
              <div>
                <input
                  {...formik.getFieldProps("certificateidnumber")}
                  className="form-control"
                  type="link"
                  placeholder="Certification ID/ Number"
                  onChange={(e) => {setData({ ...data, certificateidnumber: e.target.value })
                  formik.setFieldValue("certificateidnumber", e.target.value)}}
                  value={data["certificateidnumber"] == "undefined" ? "" : data["certificateidnumber"]}
                />
                {formik.touched.certificateidnumber &&
                formik.errors.certificateidnumber ? (
                  <FormikErrorStyle>
                    {formik.errors.certificateidnumber}
                  </FormikErrorStyle>
                ) : null}
              </div>
            ) : (
              <div>
                {data["certificateidnumber"] == "undefined"
                  ? ""
                  : data["certificateidnumber"]}
              </div>
            )}
          </div>
        

          <div
            className=""
            style={{
              fontSize: "14px",
              fontWeight: "400",
            
              width: "100%",
            }}
          >
           

            {data["isImageUpload"] && !addBtnDisable ? (
              <input
                {...formik.getFieldProps("date")}
                className="form-control "
                // rows="4"
                type="date"
                name="date"
                placeholder="Date"
                onChange={(e) => setData({ ...data, date: e.target.value })}
                value={data["date"] == "undefined" ? "" : data["date"]}
              ></input>
            ) : data["date"] == "undefined" ? (
              ""
            ) : (
              data["date"]
            )}
            {formik.touched.date && formik.errors.date ? (
              <FormikErrorStyle id="ResumeProjectdetails">
                {formik.errors.date}
              </FormikErrorStyle>
            ) : null}
          </div>
          <div
            className=""
            style={{
              fontSize: "14px",
              fontWeight: "400",
            
              width: "100%",
            }}
          >
           

            {data["isImageUpload"] && !addBtnDisable ? (
              <input
                {...formik.getFieldProps("enddate")}
                className="form-control "
                // rows="4"
                type="date"
                name="enddate"
                placeholder="enddate"
                onChange={(e) => setData({ ...data, enddate: e.target.value })}
                value={data["enddate"] == "undefined" ? "" : data["enddate"]}
              ></input>
            ) : data["enddate"] == "undefined" ? (
              ""
            ) : (
              data["enddate"]
            )}
            {formik.touched.enddate && formik.errors.enddate ? (
              <FormikErrorStyle id="ResumeProjectdetails">
                {formik.errors.enddate}
              </FormikErrorStyle>
            ) : null}
          </div>
          
          {data["isImageUpload"] && !addBtnDisable ? (
            <button
              style={{ marginTop: "15px", marginBottom: "10px" }}
              type="button"
              // disabled={addBtnDisable}
              // onClick={handleAddService}
              disabled={
                formEditable != null
                ? false
                : !formik.isValid || !formik.dirty || !data.date || !data.enddate
                // || !data["imageView"]
              }
              onClick={() => {
                formik.handleSubmit();
                handleAddService({
                  certificatename: data.certificatename,
                  organisationname: data.organisationname,
                  date: data.date,
                  enddate:data.enddate,
                  image: image,
                  imageView: data.imageView,
                  isImageUpload: data.isImageUpload,
                  certificateidnumber: data.certificateidnumber,
                });
              }}
              // className="btn btn-cu editbutton editButtonTextimonial"
              className={`btn btn-cu addbuttoneditform editButtonTextimonial`}
            >
              Add
            </button>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
};

export default ResumeCertificationForm;