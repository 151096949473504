import React from "react";
import { environmentVariables } from "../../../../config/env.config";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const GalleryCarousel = ({ slides, video }) => {

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    draggable: true,
    arrows: false,
    centerMode: true,
  };

  return (
    <div>
      <Slider {...settings}>
        {slides.map((slide, slideIndex) => {
          return (
            <img src={`${environmentVariables?.apiUrl}uploads/gallery/${slide?.image}`} alt={`Slide ${slideIndex}`} className="img-fluid" />
          );
        })}
      </Slider>
    </div>
  );
};

export default GalleryCarousel;
