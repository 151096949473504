import React, { useEffect, useState } from "react";
import ResumeBg from "../../../Images/Resume6bckgroundimg.png";
import Resumelogo from "../../../Images/ResumeLogo.png";
import {
  AddressValue,
  AddressWrapper,
  BackgroundImage,
  BackgroundImageWrapper,
  Container,
  DesignationWrapper,
  EducationUniversityDate,
  EducationUniversityName,
  FatherSpauseNameKey,
  FatherSpauseNameValue,
  InnerContainer,
  LogoImage,
  LogoImageContainer,
  NameDesignationWrapper,
  NameWrapper,
  ParticularContainer,
  ParticularHeadingName,
  ParticularHeadingWrapper,
  ParticularkeyWrapper,
  PersonalWrapper,
  ProfileSummary,
  ProjectDatesWrapper,
  ProjectLink,
  Root,
  SkillsWrapperUl,
  SkillslistName,
  WorkExperienceWrapper,
  Wrapper,
  WrkExperienceCompanyloc,
  WrkExperienceDesignation,
  WrkExperiencesummary,
} from "./Resume6ExtStyle";
import { useNavigate } from "react-router-dom";
import { environmentVariables } from "../../../config/env.config";

const Resume6 = (props) => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const baseUrl = environmentVariables?.apiUrl;
  const navigate = useNavigate();

  const formatDate = (dateString) => {
    if (!dateString) return "";

    const date = new Date(dateString);
    const options = { month: "long", year: "numeric" };
    return date.toLocaleDateString("en-US", options);
  };

  const updateScreenWidth = () => {
    setScreenWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", updateScreenWidth);
    return () => {
      window.removeEventListener("resize", updateScreenWidth);
    };
  }, []);

  const renderWrkExp = () => {
    if (props?.onState?.workExp && props.workExpArray?.length > 0) {
      return (
        <ParticularContainer>
          <ParticularHeadingWrapper>
            <ParticularHeadingName>experience</ParticularHeadingName>
          </ParticularHeadingWrapper>
          {props.workExpArray.map((wrkExp, index) => (
            <WorkExperienceWrapper key={index}>
              <WrkExperienceDesignation>
                {wrkExp?.jobtitle}
              </WrkExperienceDesignation>
              <WrkExperienceCompanyloc>
                {`${wrkExp?.companyname}, ${wrkExp?.cityname || ""}`}
              </WrkExperienceCompanyloc>
              <WrkExperienceCompanyloc>
                {`${formatDate(wrkExp?.startdate)} - ${
                  wrkExp?.enddate === "current"
                    ? "Current"
                    : formatDate(wrkExp?.enddate)
                }`}
              </WrkExperienceCompanyloc>
              <WrkExperiencesummary>{wrkExp?.workSummary}</WrkExperiencesummary>
            </WorkExperienceWrapper>
          ))}
        </ParticularContainer>
      );
    } else {
      return null;
    }
  };

  const renderProjects = () => {
    if (props?.onState?.project && props.projectArray?.length > 0) {
      return (
        <ParticularContainer>
          <ParticularHeadingWrapper>
            <ParticularHeadingName>projects</ParticularHeadingName>
          </ParticularHeadingWrapper>
          {props.projectArray.map((project, index) => (
            <WorkExperienceWrapper key={index}>
              <WrkExperienceDesignation>
                {project.projectname}
              </WrkExperienceDesignation>
              <ProjectDatesWrapper>
                {`${formatDate(project.startDate)} - ${
                  project.currentlyWorking
                    ? "Current"
                    : formatDate(project.endDate)
                }`}
              </ProjectDatesWrapper>
              <WrkExperiencesummary>
                {project.projectdescription}
              </WrkExperiencesummary>
              <ProjectLink href={project.projectlink} target="_blank">
                {project.projectlink}
              </ProjectLink>
            </WorkExperienceWrapper>
          ))}
        </ParticularContainer>
      );
    } else {
      return null;
    }
  };

  const renderSkills = () => {
    if (props?.onState?.services && props.skillArray?.length > 0) {
      return (
        <ParticularContainer>
          <ParticularHeadingWrapper>
            <ParticularHeadingName>Skills</ParticularHeadingName>
          </ParticularHeadingWrapper>
          <SkillsWrapperUl>
            {props.skillArray.map((skill, index) => (
              <SkillslistName key={index}>{skill.skillname}</SkillslistName>
            ))}
          </SkillsWrapperUl>
        </ParticularContainer>
      );
    } else {
      return null;
    }
  };

  const renderTools = () => {
    if (props?.onState?.tools && props.toolsArray?.length > 0) {
      return (
        <ParticularContainer>
          <ParticularHeadingWrapper>
            <ParticularHeadingName>Tools</ParticularHeadingName>
          </ParticularHeadingWrapper>
          <SkillsWrapperUl>
            {props.toolsArray.map((tool, index) => (
              <SkillslistName key={index}>{tool.toolname}</SkillslistName>
            ))}
          </SkillsWrapperUl>
        </ParticularContainer>
      );
    } else {
      return null;
    }
  };

  const renderEducation = () => {
    if (props?.onState?.isExperience && props.socialMediaData?.length > 0) {
      return (
        <ParticularContainer>
          <ParticularHeadingWrapper>
            <ParticularHeadingName>education</ParticularHeadingName>
          </ParticularHeadingWrapper>
          {props.socialMediaData?.map((education, index) => (
            <WorkExperienceWrapper key={index}>
              <WrkExperienceDesignation EducationDegree>
                {education.degree}
              </WrkExperienceDesignation>
              <EducationUniversityName>
                {education.schuniname}
              </EducationUniversityName>
              <EducationUniversityName>
                {education.boardname} {education.location}
              </EducationUniversityName>
              <EducationUniversityDate>
                {education.graduationendyear
                  ? `${formatDate(education.graduationyear)} - ${formatDate(
                      education.graduationendyear
                    )}`
                  : formatDate(education.graduationyear)}
              </EducationUniversityDate>
            </WorkExperienceWrapper>
          ))}
        </ParticularContainer>
      );
    } else {
      return null;
    }
  };

  const renderCertifications = () => {
    if (props?.onState?.certification && props.certificationArray?.length > 0) {
      return (
        <ParticularContainer>
          <ParticularHeadingWrapper>
            <ParticularHeadingName>Certifications</ParticularHeadingName>
          </ParticularHeadingWrapper>
          {props.certificationArray.map((certification, index) => (
            <WorkExperienceWrapper key={index}>
              <WrkExperienceDesignation>
                {certification.certificatename}
              </WrkExperienceDesignation>
              <EducationUniversityName>
                {certification.organisationname}
              </EducationUniversityName>
              <EducationUniversityName>
                {certification.certificateidnumber}
              </EducationUniversityName>
              <EducationUniversityDate>
                {certification.date
                  ? formatDate(certification.date)
                  : `${formatDate(certification.startDate)} - ${formatDate(
                      certification.endDate
                    )}`}
              </EducationUniversityDate>
            </WorkExperienceWrapper>
          ))}
        </ParticularContainer>
      );
    } else {
      return null;
    }
  };

  const renderLanguage = () => {
    if (props?.onState?.language && props.languageArray?.length > 0) {
      return (
        <ParticularContainer>
          <ParticularHeadingWrapper>
            <ParticularHeadingName>Languages</ParticularHeadingName>
          </ParticularHeadingWrapper>
          <SkillsWrapperUl>
            {props.languageArray.map((language, index) => (
              <SkillslistName key={index}>
                {language.languagename}
              </SkillslistName>
            ))}
          </SkillsWrapperUl>
        </ParticularContainer>
      );
    } else {
      return null;
    }
  };

  const renderPrsnldetails = () => {
    if (props?.onState?.prsnldetail) {
      return (
        <ParticularContainer>
          <ParticularHeadingWrapper>
            <ParticularHeadingName>PERSONAL INFORMATION</ParticularHeadingName>
          </ParticularHeadingWrapper>
          <WorkExperienceWrapper>
            <PersonalWrapper>
              <FatherSpauseNameKey>Father/ Spouse Name</FatherSpauseNameKey>
              <FatherSpauseNameValue>
                {props?.formikData?.values?.fathername}
              </FatherSpauseNameValue>
            </PersonalWrapper>
            <PersonalWrapper>
              <FatherSpauseNameKey>Marital Status</FatherSpauseNameKey>
              <FatherSpauseNameValue>
                {props?.formikData?.values?.maritalstatus}
              </FatherSpauseNameValue>
            </PersonalWrapper>
            <PersonalWrapper>
              <FatherSpauseNameKey>DOB</FatherSpauseNameKey>
              <FatherSpauseNameValue>
                {props?.formikData?.values?.userdob}
              </FatherSpauseNameValue>
            </PersonalWrapper>
          </WorkExperienceWrapper>
        </ParticularContainer>
      );
    } else {
      return null;
    }
  };

  const renderAchievement = () => {
    if (props?.onState?.achievement && props.achievementArray?.length > 0) {
      return (
        <ParticularContainer>
          <ParticularHeadingWrapper>
            <ParticularHeadingName>Achievements</ParticularHeadingName>
          </ParticularHeadingWrapper>
          <SkillsWrapperUl>
            {props.achievementArray.map((achievement, index) => (
              <SkillslistName key={index}>
                {achievement.skillname}
              </SkillslistName>
            ))}
          </SkillsWrapperUl>
        </ParticularContainer>
      );
    } else {
      return null;
    }
  };

  const renderAdditionalDetail = () => {
    if (props?.onState?.adddetails && props.additionaldetailArray?.length > 0) {
      return (
        <ParticularContainer>
          <ParticularHeadingWrapper>
            <ParticularHeadingName>Hobbies</ParticularHeadingName>
          </ParticularHeadingWrapper>
          <SkillsWrapperUl>
            {props.additionaldetailArray.map((detail, index) => (
              <SkillslistName key={index}>{detail.skillname}</SkillslistName>
            ))}
          </SkillsWrapperUl>
        </ParticularContainer>
      );
    } else {
      return null;
    }
  };

  return (
    <Root style={{ width: "420px" }}>
      <Container>
        <Wrapper>
          <InnerContainer>
            <BackgroundImageWrapper>
              {/* <BackgroundImage src={ResumeBg} /> */}
              {props.bannerImageUrl ? (
                <BackgroundImage
                  src={
                    props.bannerImageUrl.includes("base64")
                      ? props.bannerImageUrl
                      : props.bannerImageUrl === "null"
                      ? ""
                      : `${baseUrl}uploads/resume/${props.bannerImageUrl}`
                  }
                  alt="logo"
                />
              ) : (
                <BackgroundImage src={ResumeBg} alt="default-logo" />
              )}
            </BackgroundImageWrapper>

            <LogoImageContainer>
              {props.logoImageUrl ? (
                <LogoImage
                  src={
                    props.logoImageUrl.includes("base64")
                      ? props.logoImageUrl
                      : props.logoImageUrl === "null"
                      ? ""
                      : `${baseUrl}uploads/resume/${props.logoImageUrl}`
                  }
                  alt="logo"
                />
              ) : (
                <LogoImage src={Resumelogo} alt="default-logo" />
              )}
            </LogoImageContainer>
            <NameDesignationWrapper>
              <NameWrapper>
                {" "}
                {props?.formikData?.values?.fullName || "Jeremy Clifford"}
              </NameWrapper>
              <DesignationWrapper>
                {" "}
                {props?.designation || "Product Owner"}
              </DesignationWrapper>
            </NameDesignationWrapper>

            <ParticularContainer>
              <ParticularHeadingWrapper>
                <ParticularHeadingName>Details</ParticularHeadingName>
              </ParticularHeadingWrapper>
              <ParticularkeyWrapper>
                <AddressWrapper>Address</AddressWrapper>
                <AddressValue>
                  {" "}
                  {props?.formikData?.values?.address}
                </AddressValue>
              </ParticularkeyWrapper>
              <ParticularkeyWrapper>
                <AddressWrapper>Phone</AddressWrapper>
                <AddressValue> {props?.formikData?.values?.phone}</AddressValue>
              </ParticularkeyWrapper>
              <ParticularkeyWrapper>
                <AddressWrapper>Email</AddressWrapper>
                <AddressValue> {props?.formikData?.values?.email}</AddressValue>
              </ParticularkeyWrapper>

              <ParticularkeyWrapper>
                <AddressWrapper>LinkedIn</AddressWrapper>
                <a
                  href={`${props?.formikData?.values?.linkedIn}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ textDecoration: "none" }}
                >
                  <AddressValue style={{ cursor: "pointer" }}>
                    {props?.formikData?.values?.linkedIn}
                  </AddressValue>
                </a>
              </ParticularkeyWrapper>
            </ParticularContainer>

            <ParticularContainer>
              <ParticularHeadingWrapper>
                <ParticularHeadingName>profile</ParticularHeadingName>
              </ParticularHeadingWrapper>
              <ProfileSummary>
                {" "}
                {props?.formikData?.values?.description}
              </ProfileSummary>
            </ParticularContainer>
            {props?.onState?.workExp && renderWrkExp()}
            {props?.onState?.project && renderProjects()}
            {props?.onState?.isExperience && renderEducation()}
            {props?.onState?.services && renderSkills()}
            {props?.onState?.services && renderSkills()}
            {props?.onState?.tools && renderTools()}
            {props?.onState?.certification && renderCertifications()}
            {props?.onState?.language && renderLanguage()}
            {props?.onState?.adddetails && renderAdditionalDetail()}
            {props?.onState?.achievement && renderAchievement()}
            {props?.onState?.prsnldetail && renderPrsnldetails()}
          </InnerContainer>
        </Wrapper>
      </Container>
    </Root>
  );
};

export default Resume6;
