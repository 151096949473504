import styled from "styled-components";
import ResumeBg from "../../../Images/Resume6bckgroundimg.png";

export const Root = styled.div`
display:flex;
justify-content: center;
`;

export const Container = styled.div`
width: 1260px;
`;

export const Wrapper = styled.div`
width: 100%;
display: flex;
flex-direction: column;
// padding: 0 20px;
@media(max-width: 768px){
    display: flex;
    flex-direction: column-reverse;
}
@media(max-width: 525px){
    padding: 0px;
}
`;
export const ExternalWrapper = styled.div`
width: 100%;
display: flex;
flex-direction: column;
background-color:#424242;

@media(max-width: 768px){
    display: flex;
    flex-direction: column;
}
@media(max-width: 525px){
    padding: 0px;
}
`;

export const BackgroundImageContainer = styled.div``;


export const BackgroundImage = styled.img`
width: 100%;
height: 160px;
`;
export const BackgroundImageWrapper = styled.div``;


export const LogoImageContainer = styled.div`
baclground-color: #424242;
width: 100%;
display:flex;
justify-content: center;
align-items: center;
text-align: center;
margin-top: -45px;

`;

export const ExternalLogoImageContainer = styled.div`
baclground-color: #424242;
width: 100%;
display:flex;
flex-direction: column;
justify-content: center;
align-items: center;
text-align: center;
margin-top: -95px;
`;

export const LogoImage = styled.img`
width:100px;
border: 2px solid #fff;
border-radius: 50%;
`;
export const ExternalLogoImage = styled(LogoImage)`
width:170px;
`;


export const InternalWrapper = styled.div`
background-color: #424242;
`;

export const InnerContainer = styled.div`
background-color:#424242;
`;


export const NameDesignationWrapper = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
text-align: center;
margin: 25px 0;
`;
export const NameWrapper = styled.div`
color:#fff;
font-family: "Taviraj", serif;
font-weight: 600;
font-size: 32px;
text-transform: capitalize;
`;

export const ExternalNameWrapper= styled(NameWrapper)`
padding: 15px 0 0px 0;
font-size: 40px;
`;
export const DesignationWrapper = styled.div`
color:#fff;
font-family: "Taviraj", serif;
font-weight: 400;
font-size: 18px;
opacity: 60%;
`;

export const ExternalDesignation= styled(DesignationWrapper)`
padding: 0px 0 15px 0;
font-size: 24px;
`;


export const ParticularContainer = styled.div`
color:#fff;
padding: 10px 20px;
`;
export const ParticularHeadingWrapper = styled.div`
color:#fff;
padding: 10px 0;
`;
export const ParticularHeadingName = styled.div`
font-family: "Roboto", sans-serif;
font-weight: 700;
font-size: 20px;
color:#fff;
text-transform: uppercase;

`;
export const ParticularkeyWrapper = styled.div`
color:#fff;
padding: 5px 0;
`;
export const AddressWrapper = styled.div`
color:#fff;
font-family: "Roboto", sans-serif;
font-weight: 400;
font-size: 13px;
text-transform: capitalize;

`;
export const AddressValue = styled.div`

font-family: "Roboto", sans-serif;
font-weight: 400;
font-size: 15px;
color:rgba(255,255,255,0.70);
`;

export const ProfileSummary  = styled.div`
font-family: "poppins", sans-serif;
font-weight: 400;
font-size: 13px;
color:rgba(255,255,255,0.70);
`;



export const WorkExperienceWrapper= styled.div`
padding: 0 0 15px 0;
`;
export const WrkExperienceDesignation = styled.div`
font-family: "poppins", sans-serif;
font-weight: 500;
font-size: 14px;
color:#fff;
text-transform: capitalize;

${(p)=>p.EducationDegree && `
padding: 5px 0;
`}
`;
export const WrkExperienceCompanyloc = styled.div`
font-family: "poppins", sans-serif;
font-weight: 500;
font-size: 13px;
padding: 1px 0;
color:rgba(255,255,255,0.70);
`;

export const WrkExperiencesummary = styled.div`
font-family: "poppins", sans-serif;
font-weight: 400;
font-size: 13px;
padding: 5px 0;
color:rgba(255,255,255,0.70);

`;


export const ProjectDatesWrapper = styled.div`
font-family: "poppins", sans-serif;
font-weight: 400;
font-size: 13px;
padding: 5px 0;
color:rgba(255,255,255,0.70);
font-style:italic;
`;
export const ProjectLink = styled.div`
color: #407BFF;
font-family: "poppins", sans-serif;
font-weight: 400;
font-size: 13px;
padding: 5px 0;
`;

export const EducationUniversityName = styled.div`
font-family: "poppins", sans-serif;
font-weight: 400;
font-size: 13px;
color:rgba(255,255,255,0.70);
`;
export const EducationUniversityDate = styled.div`
font-family: "inter", sans-serif;
font-weight: 400;
font-size: 13px;
color:rgba(255,255,255,0.70);
`;

export const SkillsWrapperUl = styled.ul`
padding: 0 0 0 25px;
`;
export const SkillslistName = styled.li`
font-family: "poppins", sans-serif;
font-weight: 400;
font-size: 14px;
list-style-type: disc;
padding: 2px 0 2px 0px;
text-transform: capitalize;
`;

export const PersonalWrapper = styled.div`
padding: 5px 0;
`;
export const FatherSpauseNameKey = styled.div`
font-family: "poppins", sans-serif;
font-weight: 400;
font-size: 13px;
color:#fff;
text-transform: capitalize;
`;
export const FatherSpauseNameValue = styled.div`
font-family: "poppins", sans-serif;
font-weight: 400;
font-size: 15px;
color:rgba(255,255,255,0.70);
text-transform: capitalize;
`;



// export const ExternalWrapper = styled.div`
// background-color:#424242;
// `;



export const LeftRightWrapper = styled.div`
width: 100%;
display:flex;
justify-content: center;
align-items: flex-start;
padding: 15px 50px;
@media(max-width: 991px){
    padding: 15px 30px; 
}
@media(max-width: 768px){
    padding: 15px 30px; 
    display:flex;
    flex-direction: column-reverse;
}
`;
export const LeftSideWrapper = styled.div`
width: 45%;
@media(max-width: 768px){
    width: 100%;
}
`;
export const RightSideWrapper = styled.div`
width: 55%;
@media(max-width: 768px){
    width: 100%;
}
`;


export const ExternalParticularContainer = styled.div`
@media(max-width: 768px){
    padding: 10px 0;
}
${(p)=> p.PhoneResponsive && `
@media(min-width: 768px) and (max-width: 40000px){
    display: none;

}
`}


`;
export const ExternalParticularHeadingWrapper = styled.div`
${(p)=>p.Skills && `

padding-top: 20px;

`}
${(p)=>p.Experience && `

padding-top: 25px;

`}
`;
export const ExternalParticularHeadingName = styled.div`
font-family: "poppins", sans-serif;
font-weight: 700;
font-size: 32px;
color:#fff;
text-transform: uppercase;
@media(max-width: 550px){
    font-size: 28px;
}
`;
export const ExternalParticularkeyWrapper = styled.div`
padding: 5px 0;
`;
export const ExternalAddressWrapper = styled.div`
font-family: "poppins", sans-serif;
font-weight: 500;
font-size: 20px;
padding: 1px 0;
color:#fff;
`;
export const ExternalAddressValue = styled.div`
font-family: "poppins", sans-serif;
font-weight: 500;
font-size: 16px;
padding: 1px 0;
color:rgba(255,255,255,0.70);
`;


export const ExternalSkillsWrapper = styled.ul`
padding-left: 30px;
padding-top: 5px;
`;
export const ExternalSkillsList = styled.li`
font-family: "poppins", sans-serif;
font-weight: 500;
font-size: 20px;
padding: 3px 0;
color:rgba(255,255,255,0.70);
list-style-type:disc;
`;


export const ExternalCertificateWrapper = styled.div``;
export const ExtCertificateName = styled.div`
font-family: "poppins", sans-serif;
font-weight: 500;
font-size: 20px;
padding: 3px 0;
color:#fff;
`;
export const ExtCertificateAcd = styled.div`
font-family: "poppins", sans-serif;
font-weight: 500;
font-size: 16px;
padding: 3px 0;
color:rgba(255,255,255,0.70);
`;
export const ExtCertificateID = styled.div`
font-family: "poppins", sans-serif;
font-weight: 500;
font-size: 16px;
color:rgba(255,255,255,0.70);
`;
export const ExtCertificateDate = styled.div`
font-family: "poppins", sans-serif;
font-weight: 500;
font-size: 16px;
color:rgba(255,255,255,0.70);
`;

export const Externalachivemeentlist = styled.li`
font-family: "poppins", sans-serif;
font-weight: 500;
font-size: 16px;
color:rgba(255,255,255,0.70);
list-style-type:disc;
`;


export const ExternalSummary = styled.div`
font-family: "poppins", sans-serif;
font-weight: 500;
font-size: 20px;
color:rgba(255,255,255,0.70);
text-align:justify;
padding-top:10px;
`;

export const ExperienceCompanyName = styled.div`
font-family: "poppins", sans-serif;
font-weight: 700;
font-size: 20px;
color: #fff;
`;
export const ExperienceDesignationName = styled.div`
font-family: "poppins", sans-serif;
font-weight: 400;
font-size: 16px;
padding: 2px 0;
color: #fff;
`;
export const ExperienceDate = styled.div`
font-family: "poppins", sans-serif;
font-weight: 400;
font-size: 14px;
color:rgba(255,255,255,0.70);
`;
export const ExperienceSummary = styled.div`
font-family: "poppins", sans-serif;
font-weight: 400;
font-size: 16px;
color:rgba(255,255,255,0.70);
padding: 5px 0;
`;

export const ExternalExperienceResumeWrapper = styled.div`
padding-top: 10px;
`;

export const EducationCompanyName = styled.div`
font-family: "poppins", sans-serif;
font-weight: 500;
font-size: 20px;
color: #fff;
`;

export const ExternalEducation = styled.div`
font-family: "poppins", sans-serif;
font-weight: 500;
font-size: 16px;
padding: 0px 0;
color:rgba(255,255,255,0.70);
`;

export const ExternalLink = styled.div`
font-family: "poppins", sans-serif;
font-weight: 500;
font-size: 20px;
color:#1D87E2;
;
`;

export const ExternalWorkExperienceWrapper = styled.div``;
export const ExternalPersonalWrapper = styled.div`

padding: 8px 0;
`;
export const ExternalFatherSpauseNameKey = styled.div`
font-family: "poppins", sans-serif;
font-weight: 500;
font-size: 20px;
color:#fff;
`;
export  const ExternalFatherSpauseNameValue= styled.div`
font-family: "poppins", sans-serif;
font-weight: 500;
font-size: 20px;
color:rgba(255,255,255,0.70);
`;


export const PoweredWrapper = styled.div`
background-color: #6C6C6C;
padding: 20px 0;
`;
export const Power = styled.div`
font-family: "Be Vietnam", sans-serif;
color: #000;
font-weight: 400;
display: flex;
justify-content: center;
align-items: center;
font-size: 16px;
`;
export const PowerSpan = styled.div`
color: #fff;
padding-left: 8px;
font-size: 16px;
`;
