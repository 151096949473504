import React, { useRef, useEffect, useState } from "react";
import logopic from "../../../Images/logoo.png";
import ShareVector from "./../../../Images/BlackShare.png";
import {
  HeaderWrapper,
  LogoWrapper,
  Logo,
  Container,
  HeadingWrapper,
  NameWrapper,
  NamePara,
  ContactLists,
  ContactList,
  ContactListHeading,
  ContactListPara,
  ContactLink,
  ListLeft,
  ListRight,
  ContactHeading,
  SummaryLists,
  SumaryList,
  SummaryHeading,
  SummaryPara,
  ProjectSummaryPara,
  WorkHeading,
  WorkList,
  WorkInnerHeading,
  TextWrapper,
  WorkInnerPara,
  WorkInnerLists,
  WorkInnerList,
  SkillListB,
  ProductSubHeadingOrg,
  ProductSubHeading,
  ProductLink,
  EducationWrapper,
  EducationLeft,
  EducationRight,
  EducationDegree,
  EducationTime,
  EducationCollege,
  SkillWrapper,
  WorkInnerPara2,
  CertifiWorkInnerPara,
  SkillList,
  ToolWrapper,
  ToolWrapperList,
  ToolPara,
  ProgressWrapper,
  ProgressContainer,
  ProgressSkill,
  AchievementWrapper,
  PersonalWrapper,
  PersonalList,
  PersonalTitle,
  PersonalText,
  WrapperInner,
} from "./Resume4ExtStyle";
import {
  IconContainer,
  Power,
  PowerSpan,
  PoweredWrapper,
  ShareButtonWrapper,
  ShareIcon,
  SingleIcon,
  SingleIconWrapper,
} from "./StyleThemejs";
import { environmentVariables } from "../../../config/env.config";
import {
  EmailIcon,
  LinkedinIcon,
  TelegramIcon,
  WhatsappIcon,
} from "react-share";

const Resume4Ext = (props) => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [educationData, setEducationData] = useState([]);
  const [projectData, setProjectData] = useState([]);
  const [certificateData, setCertificateData] = useState([]);
  const [skillData, setSkillData] = useState([]);
  const [additionalData, setAdditionalData] = useState([]);
  const [achievementData, setAchievementData] = useState([]);
  const [workExpData, setworkExpData] = useState([]);
  const [toolData, settoolData] = useState([]);
  const [language, setLanguage] = useState([]);
  const [showShareOptions, setShowShareOptions] = useState(false);


  const iconContainerRef = useRef(null);

  useEffect(() => {
    if (props?.resumeData?.educationcontent) {
      setEducationData(JSON.parse(props.resumeData.educationcontent));
    }
    if (props?.resumeData?.projectcontent) {
      setProjectData(JSON.parse(props.resumeData.projectcontent));
    }
    if (props?.resumeData?.certificationcontent) {
      setCertificateData(JSON.parse(props.resumeData.certificationcontent));
    }
    if (props?.resumeData?.skillscontent) {
      setSkillData(JSON.parse(props.resumeData.skillscontent));
    }
    if (props?.resumeData?.additionaldetailscontent) {
      setAdditionalData(JSON.parse(props.resumeData.additionaldetailscontent));
    }
    if (props?.resumeData?.toolscontent) {
      settoolData(JSON.parse(props.resumeData.toolscontent));
    }
    if (props?.resumeData?.languagecontent) {
      setLanguage(JSON.parse(props.resumeData.languagecontent));
    }
    if (props?.resumeData?.wrkExpcontent) {
      setworkExpData(JSON.parse(props.resumeData.wrkExpcontent));
    }
    if (props?.resumeData?.achievementcontent) {
      setAchievementData(JSON.parse(props.resumeData.achievementcontent));
    }
  }, [props.resumeData]);

  const handleClickOutside = (event) => {
    if (iconContainerRef.current && !iconContainerRef.current.contains(event.target)) {
      setShowShareOptions(false);
    }
  };
  useEffect(() => {
      document.addEventListener("click", handleClickOutside, false);
      return () => {
        document.removeEventListener("click", handleClickOutside, false);
      };
  }, []);

  const getImage = (image) => {
    if (image && image.includes("base64")) {
      return image;
    } else if (image) {
      return `${environmentVariables?.apiUrl}uploads/resume/${image}`;
    } else {
      return "";
    }
  };

  const updateScreenWidth = () => {
    setScreenWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", updateScreenWidth);
    return () => {
      window.removeEventListener("resize", updateScreenWidth);
    };
  }, []);

  const formatDate = (dateString) => {
    if (!dateString) return ""; // Return empty string if dateString is falsy

    const date = new Date(dateString); // Parse the date string
    const options = { month: "long", year: "numeric" }; // Format options
    return date.toLocaleDateString("en-US", options); // Format date to "Month-YYYY" format
  };

  const handleShare = () => {
    setShowShareOptions(!showShareOptions);
  };

  const handleShareOnPlatform = (platform) => {
    console.log("Sharing via", platform);
    const resumePreviewLink = window.location.href;
    const message = "Check out my resume!";
    const fullMessage = `${message} ${resumePreviewLink}`;
    let shareUrl = "";

    switch (platform) {
      case "linkedin":
        // shareUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(resumePreviewLink)}`;
        // break;
        navigator.clipboard
          .writeText(fullMessage)
          .then(() => {
            alert(
              "Message copied to clipboard! Please paste it in LinkedIn messages."
            );
            window.open("https://www.linkedin.com/messaging", "_blank"); // Opens LinkedIn Messaging
          })
          .catch((err) => {
            console.error("Could not copy text: ", err);
          });
        break;
      case "whatsapp":
        shareUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(
          resumePreviewLink
        )}`;
        break;
      case "telegram":
        shareUrl = `https://t.me/share/url?url=${encodeURIComponent(
          resumePreviewLink
        )}`;
        break;
      case "email":
        shareUrl = `mailto:?subject=Checkoutmyresume&body=${encodeURIComponent(
          resumePreviewLink
        )}`;
        // setShowEmailShare(true);
        break;
      // case 'facebook':

      //   break;
      default:
        break;
    }

    if (shareUrl) {
      window.open(shareUrl, "_blank");
    }
  };

  const renderWorkExperience = () => (
    <WrapperInner>
      <SummaryLists>
        <WorkHeading>Work experience</WorkHeading>
        {workExpData.map((exp, index) => (
          <WorkList key={index}>
            <WorkInnerHeading>
              {exp.jobtitle}
              <TextWrapper>
                {" "}
                (
                {`${formatDate(exp.startdate)?.substr(0, 3)} ${formatDate(
                  exp.startdate
                )?.substr(-4)} - ${exp.currentlyWorking
                    ? "Current"
                    : `${formatDate(exp.enddate)?.substr(0, 3)} ${formatDate(
                      exp.enddate
                    )?.substr(-4)}`
                  }`}
                )
              </TextWrapper>
            </WorkInnerHeading>
            <WorkInnerPara>{exp.companyname}</WorkInnerPara>
            <WorkInnerLists style={{ padding: "10px 0 0 0px" }}>
              <WorkInnerList WorkExperience>{exp.workSummary}</WorkInnerList>
            </WorkInnerLists>
          </WorkList>
        ))}
      </SummaryLists>
    </WrapperInner>
  );

  const renderProjects = () => (
    <WrapperInner>
      <SummaryLists>
        <SumaryList>
          <SummaryHeading> PROJECT </SummaryHeading>
          {projectData.map((project, index) => (
            <div key={index} style={{ marginBottom: "30px" }}>
              <ProductSubHeading>{project.projectname}</ProductSubHeading>
              <WorkInnerPara2>
                (
                {`${formatDate(project.startDate)?.substr(0, 3)} ${formatDate(
                  project.startDate
                )?.substr(-4)} - ${project.currentlyWorking
                    ? "Current"
                    : `${formatDate(project.endDate)?.substr(
                      0,
                      3
                    )} ${formatDate(project.endDate)?.substr(-4)}`
                  }`}
                )
              </WorkInnerPara2>
              <ProjectSummaryPara>
                {project.projectdescription}
              </ProjectSummaryPara>
              <ProductLink href={project.projectlink} target="_blank">
                {project.projectlink}
              </ProductLink>
            </div>
          ))}
        </SumaryList>
      </SummaryLists>
    </WrapperInner>
  );

  const renderEducation = () => (
    <WrapperInner>
      <SummaryLists>
        <WorkHeading>Education</WorkHeading>
        {educationData.map((item, index) => (
          <WorkList key={index}>
            <EducationWrapper>
              <EducationLeft>
                <EducationDegree>{item.degree}</EducationDegree>
                <EducationCollege>{item.schuniname}</EducationCollege>
              </EducationLeft>
              <EducationRight>
                <EducationTime>
                  (
                  {`${formatDate(item.graduationyear)?.substr(
                    0,
                    3
                  )} ${formatDate(item.graduationyear)?.substr(-4)} - ${item.currentlyWorking
                      ? "Present"
                      : `${formatDate(item.graduationendyear)?.substr(
                        0,
                        3
                      )} ${formatDate(item.graduationendyear)?.substr(-4)}`
                    }`}
                  )
                </EducationTime>
              </EducationRight>
            </EducationWrapper>
          </WorkList>
        ))}
      </SummaryLists>
    </WrapperInner>
  );

  const renderSkills = () => (
    <WrapperInner>
      <SummaryLists>
        <WorkHeading>Key Skills</WorkHeading>
        <SkillWrapper>
          {skillData.map((skill, index) => (
            <SkillList key={index}>{skill.skillname}</SkillList>
          ))}
        </SkillWrapper>
      </SummaryLists>
    </WrapperInner>
  );

  const renderTools = () => (
    <WrapperInner>
      <SummaryLists>
        <WorkHeading>Tools</WorkHeading>
        <ToolWrapper>
          <ToolWrapperList>
            {toolData.map((tool, index) => (
              <ToolPara key={index}>{tool.toolname}</ToolPara>
            ))}
          </ToolWrapperList>
        </ToolWrapper>
      </SummaryLists>
    </WrapperInner>
  );

  const renderCertifications = () => (
    <WrapperInner>
      <SummaryLists>
        <SumaryList>
          <SummaryHeading>Certifications</SummaryHeading>
          {certificateData.map((certificate, index) => (
            <React.Fragment key={index}>
              <ProductSubHeading>
                {certificate.certificatename}
              </ProductSubHeading>
              <ProductSubHeadingOrg>
                {certificate.organisationname}
              </ProductSubHeadingOrg>
              <CertifiWorkInnerPara>
                {formatDate(certificate.date)}
              </CertifiWorkInnerPara>
            </React.Fragment>
          ))}
        </SumaryList>
      </SummaryLists>
    </WrapperInner>
  );

  const renderLanguages = () => (
    <WrapperInner>
      <SummaryLists>
        <WorkHeading>Languages</WorkHeading>
        <SkillWrapper>
          {language.map((lang, index) => (
            <SkillListB key={index}>{lang.languagename}</SkillListB>
          ))}
        </SkillWrapper>
      </SummaryLists>
    </WrapperInner>
  );

  const renderAdditionalDetail = () => (
    <WrapperInner>
      <SummaryLists>
        <WorkHeading>Hobbies</WorkHeading>
        <SkillWrapper>
          {additionalData.map((hobby, index) => (
            <SkillListB key={index}>{hobby.skillname}</SkillListB>
          ))}
        </SkillWrapper>
      </SummaryLists>
    </WrapperInner>
  );

  const renderAchievement = () => (
    <WrapperInner>
      <SummaryLists>
        <WorkHeading>Achievements</WorkHeading>
        <AchievementWrapper>
          <WorkInnerLists>
            {achievementData.map((achievement, index) => (
              <WorkInnerList key={index} color="#2F2F2F">
                {achievement.skillname}
              </WorkInnerList>
            ))}
          </WorkInnerLists>
        </AchievementWrapper>
      </SummaryLists>
    </WrapperInner>
  );

  const renderPrsnldetails = () => (
    <WrapperInner>
      <SummaryLists>
        <WorkHeading>PERSONAL INFORMATION</WorkHeading>
        <PersonalWrapper>
          <PersonalList>
           {props.resumeData.fathername  && (
            <PersonalTitle>Father/ Spouse Name</PersonalTitle>
           )} 
            <PersonalText>{props.resumeData.fathername}</PersonalText>
          </PersonalList>

          <PersonalList>
          {props.resumeData.maritalstatus && (
        <PersonalTitle>Marital Status</PersonalTitle>
      )}  
            <PersonalText>{props.resumeData.maritalstatus}</PersonalText>
          </PersonalList>

          <PersonalList>
            {props.resumeData.userdob && (
            <PersonalTitle>DOB</PersonalTitle>
            )}
           
            <PersonalText>{props.resumeData.userdob}</PersonalText>
          </PersonalList>
        </PersonalWrapper>
      </SummaryLists>
    </WrapperInner>
  );

  return (
    <div>
      <HeaderWrapper style={{ position: "relative" }}>
        <ShareButtonWrapper style={{}} onClick={handleShare} ref={iconContainerRef}>
          <ShareIcon src={ShareVector} />
        </ShareButtonWrapper>
        {showShareOptions && (
          <IconContainer
            className="share-options-modal"
            style={{ background: "#F8F8EC" }}
          >
            <SingleIconWrapper className="share-options-list">
              <SingleIcon onClick={() => handleShareOnPlatform("telegram")}>
                <TelegramIcon size={35} round />
              </SingleIcon>
              <SingleIcon onClick={() => handleShareOnPlatform("email")}>
                <EmailIcon size={35} round />
              </SingleIcon>
              <SingleIcon onClick={() => handleShareOnPlatform("linkedin")}>
                <LinkedinIcon size={35} round />
              </SingleIcon>
              <SingleIcon onClick={() => handleShareOnPlatform("whatsapp")}>
                <WhatsappIcon size={35} round />
              </SingleIcon>
            </SingleIconWrapper>
          </IconContainer>
        )}

        <LogoWrapper>
          <Logo src={getImage(props.resumeData.logo)} alt="logo" />
        </LogoWrapper>
      </HeaderWrapper>
      <Container>
        <WrapperInner>
          <HeadingWrapper>
            <NameWrapper>{props?.resumeData?.fullName}</NameWrapper>
            <NamePara> {props?.resumeData?.designation} </NamePara>
          </HeadingWrapper>

          <ContactLists>
            <ContactHeading> contact</ContactHeading>
            <ListLeft>
              <ContactList>
                <ContactListHeading>PHONE</ContactListHeading>
                <ContactListPara>{props?.resumeData?.phone}</ContactListPara>
              </ContactList>

              <ContactList>
                <ContactListHeading>MAIL</ContactListHeading>
                <ContactListPara>{props?.resumeData?.email}</ContactListPara>
              </ContactList>
            </ListLeft>

            <ListRight>
              {props?.resumeData?.city && (
                <ContactList>
                <ContactListHeading>ADDRESS</ContactListHeading>
                <ContactListPara>{props?.resumeData?.city}</ContactListPara>
                </ContactList>
              )}
             
             {props?.resumeData?.linkedIn && (
                <ContactList>
                <ContactListHeading>SOCIAL LINK</ContactListHeading>

                <a
                  href={props?.resumeData?.linkedIn}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <ContactLink style={{ cursor: "pointer" }}> {props?.resumeData?.linkedIn} </ContactLink>
                </a>
              </ContactList>
             )}

           
            </ListRight>
          </ContactLists>
        </WrapperInner>
          {props?.resumeData?.summary && (
          <WrapperInner>
          <SummaryLists>
            <SumaryList>
              <SummaryHeading>summary</SummaryHeading>
              <SummaryPara>{props?.resumeData?.summary}</SummaryPara>
            </SumaryList>
          </SummaryLists>
          </WrapperInner>
          )}
       

        {props?.resumeData?.is_wrkExp_is_enabled === 1 &&
          renderWorkExperience()}

        {props?.resumeData?.is_project_is_enabled === 1 && renderProjects()}

        {props?.resumeData?.is_education_is_enabled === 1 && renderEducation()}

        {props?.resumeData?.is_skills_is_enabled === 1 && renderSkills()}

        {props?.resumeData?.is_tools_is_enabled === 1 && renderTools()}

        {props?.resumeData?.is_certification_is_enabled === 1 &&
          renderCertifications()}

        {props?.resumeData?.is_achievement_is_enabled === 1 &&
          renderAchievement()}

        {props?.resumeData?.is_prsnldetail_is_enabled === 1 &&
          renderPrsnldetails()}

        {props?.resumeData?.is_language_is_enabled === 1 && renderLanguages()}

        {props?.resumeData?.isEnabledAdddetails === 1 &&
          renderAdditionalDetail()}
        <PoweredWrapper>
          <Power>
            Powered by <PowerSpan>BusinessBay</PowerSpan>
          </Power>
        </PoweredWrapper>
      </Container>
    </div>
  );
};

export default Resume4Ext;
