import React from 'react'
import { useState } from 'react'


function Paystripe() {

    const [activeTab, setActiveTab] = useState('tab1'); // Initial active tab

    const handleTabClick = (tab) => {
      setActiveTab(tab);
    };




  return (
    <>
  <div className="row">
  <div className="col-md-4">
    <div className="tile">

    <ul className='conatctprofile'>
        <li
          className={`tab-item ${activeTab === 'tab1' ? 'active' : ''}`}
          onClick={() => handleTabClick('tab1')}
        >
        Stripe
                                
        </li>
        <li
          className={`tab-item ${activeTab === 'tab2' ? 'active' : ''}`}
          onClick={() => handleTabClick('tab2')}
        >
      Razorpay 
        </li>

        </ul>
        </div>
        </div>
    <div className="col-md-8">
    <div className="tile">
    <form>
<div class="row mb-4">
  <div class="col-md-6">
    <label>First Name</label>
    <input class="form-control" type="text"/>
  </div>
  <div class="col-md-6">
    <label>Last Name</label>
    <input class="form-control" type="text"/>
  </div>
</div>
<div class="row">
  <div class="col-md-12 mb-4">
    <label>Email</label>
    <input class="form-control" type="text"/>
  </div>
  <div class="clearfix"></div>
  <div class="col-md-12 mb-4">
    <label>Mobile No</label>
    <input class="form-control" type="text"/>
  </div>
  <div class="clearfix"></div>
</div>
<div class="row mb-10">
  <div class="col-md-12">
    <button class="btn btn-primary" type="button"><i class="bi bi-check-circle-fill me-2"></i> Save</button>
  </div>
</div>
</form> 

        
           
   
        
      
        </div>
        </div>
        </div>
    </>
  )
}

export default Paystripe
