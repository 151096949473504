import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { environmentVariables } from "../../../config/env.config";
import React, { useContext, useEffect, useState } from "react";
import { userContext } from "../../../context/userContext";
import { businessObjContext } from "../../../context/businessObjContext";

const Coockie = ({ businessObj }) => {
  const [cookieTitle, setCookieTitle] = useState("");
  const [strictlycookieTitle, setStrictlyCookieTitle] = useState("");
  const [cookieDescription, setCookieDescription] = useState("");
  const [strictlyCookieDescription, setStricktlyCookieDescription] =
    useState("");
  const [contactUsDescription, setContactUsDescription] = useState("");
  const [contactUsUrl, setContactUsUrl] = useState("");
  const baseUrl = environmentVariables?.apiUrl;
  const { userData, setUserData } = useContext(userContext);
  const { businessObjData, setBusinessObjData } =
    useContext(businessObjContext);
  const handleFetchBusinessData = async () => {
    try {
      // console.log(businessObj?.id, "businessObj?.id", "parmasn");
      let { data: res } = await axios.get(
        `${baseUrl}api/v1/business/fetchBusinessById?id=${businessObjData?.id}`,
        {
          headers: {
            _token: userData?.token,
          },
          // withCredentials: true,
        }
      );
      let getBusinessData = res?.data;
      let obj = JSON.parse(getBusinessData?.gdpr_text);
      // console.log(obj, "objssData");

      if (obj && obj != null) {
        setCookieTitle(obj?.cookieTitle);
        setStrictlyCookieTitle(obj?.strictlycookieTitle);
        setCookieDescription(obj?.cookieDescription);
        setStricktlyCookieDescription(obj?.strictlyCookieDescription);
        setContactUsDescription(obj?.contactUsDescription);
        setContactUsUrl(obj?.contactUsUrl);
      } else {
        setCookieTitle("");
        setStrictlyCookieTitle("");
        setCookieDescription("");
        setStricktlyCookieDescription("");
        setContactUsDescription("");
        setContactUsUrl("");
      }
    } catch (err) {
      // toast.error(err?.response?.data?.message, {
      //   position: toast.POSITION.TOP_RIGHT,
      // });
    }
  };

  useEffect(() => {
    handleFetchBusinessData();
  }, [businessObj]);

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      let obj = {
        businessId: businessObj?.id,
        cookieTitle: cookieTitle,
        strictlycookieTitle: strictlycookieTitle,
        cookieDescription: cookieDescription,
        strictlyCookieDescription: strictlyCookieDescription,
        contactUsDescription: contactUsDescription,
        contactUsUrl: contactUsUrl,
      };
      // console.log(obj,'object cookk')
      let { data: res } = await axios.post(
        `${baseUrl}api/v1/business/businessCookie`,
        obj,
        {
          headers: {
            _token: userData?.token,
          },
          // withCredentials: true,
        }
      );
      if (res?.success) {
        toast.success(res?.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        handleFetchBusinessData();
      }
      // console.log(res,"respon cookie")
    } catch (err) {
      console.log(err);
      toast.error(err?.message || err, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
    }
  };
  return (
    <>
      <div className="row">
        <div className="col-12 col-lg-12">
          <div className="tile">
            <form>
              <div className="mb-3 ">
                <label className="form-label" for="exampleInputEmail1">
                  Cookie Title
                </label>
                <input
                  className="form-control"
                  id="exampleInputEmail1"
                  type="text"
                  aria-describedby="emailHelp"
                  placeholder="Enter Cookie title"
                  value={cookieTitle}
                  onChange={(e) => setCookieTitle(e.target.value)}
                />
              </div>
              <div className="mb-3">
                <label className="form-label" for="exampleInputEmail1">
                  Strickly Cookie Title
                </label>
                <input
                  className="form-control"
                  id="exampleInputEmail1"
                  type="text"
                  aria-describedby="emailHelp"
                  placeholder="Enter Cookie title"
                  value={strictlycookieTitle}
                  onChange={(e) => setStrictlyCookieTitle(e.target.value)}
                />
              </div>
              <div className="mb-3">
                <label className="form-label" for="exampleInputEmail1">
                  Cookie description
                </label>
                <input
                  className="form-control"
                  id="exampleInputEmail1"
                  type="text"
                  aria-describedby="emailHelp"
                  placeholder="Enter Cookie description"
                  value={cookieDescription}
                  onChange={(e) => setCookieDescription(e.target.value)}
                />
              </div>
              <div className="mb-3">
                <label className="form-label" for="exampleInputEmail1">
                  Strickly Cookie description
                </label>
                <input
                  className="form-control"
                  id="exampleInputEmail1"
                  type="text"
                  aria-describedby="emailHelp"
                  value={strictlyCookieDescription}
                  placeholder="Enter Strickly Cookie description"
                  onChange={(e) =>
                    setStricktlyCookieDescription(e.target.value)
                  }
                />
              </div>
              <div className="mb-3">
                <label className="form-label" for="exampleInputEmail1">
                  Contact Us Description
                </label>
                <input
                  className="form-control"
                  id="exampleInputEmail1"
                  type="text"
                  aria-describedby="emailHelp"
                  value={contactUsDescription}
                  placeholder="Enter Contact Us Description"
                  onChange={(e) => setContactUsDescription(e.target.value)}
                />
              </div>
              <div className="mb-3">
                <label className="form-label" for="exampleInputEmail1">
                  Contact Us URL
                </label>
                <input
                  className="form-control"
                  id="exampleInputEmail1"
                  type="text"
                  value={contactUsUrl}
                  aria-describedby="emailHelp"
                  placeholder="Enter Contact Us URL"
                  onChange={(e) => setContactUsUrl(e.target.value)}
                />
              </div>
              <div className="mb-3">
                <button
                  type="Submit"
                  className="btn btn-cu"
                  onClick={(e) => handleSubmit(e)}
                >
                  Save
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Coockie;
