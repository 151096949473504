import React from 'react';
import location from "./../../../../Images/minilocation.png";
import msg from "./../../../../Images/minimsg.png";
import web from "./../../../../Images/miniweb.png";
import phn from "./../../../../Images/miniphn.png";
import Twitter from "./../../../../Images/XCollectionIcon.png";
import Insta from "./../../../../Images/instaCollectionIcon.png";
import LinkedIn from "./../../../../Images/linkedincollectionicon.png";
import Facebook from "./../../../../Images/facbookCollectionicon.png";



 const ContactInfo = (props) => {
  const getBrandNameForSocial = (brand) => {
    if (brand == "gmail") {
      return "google";
    } else return `${brand}`;
  };

  return (
    <div>
      {props?.contdata?.onState?.contactInfo && (
        <div>
        
            <div className='flex items-center'>
                <div style={{width:"40px"}} className='z-0 p-2 rounded-2xl w-5'>
                  <img className='z-10 w-5' src={phn}/>
                </div>
                <div className='mx-2'> {props?.contdata?.contactInfo?.contactInfoPhone}</div>
            </div>

            <div className='flex items-center'>
                <div style={{width:"40px"}} className='z-0 p-2 rounded-2xl w-5'>
                  <img className='z-10 w-5' src={web}/>
                </div>
                <div className='mx-2'>{props?.contdata?.contactInfo?.contactInfoWebUrl}</div>
            </div>

            <div className='flex items-center'>
                <div style={{width:"40px"}} className='z-0 p-2 rounded-2xl w-5'>
                  <img className='z-10 w-5' src={msg}/>
                </div>
                <div className='mx-2'>  {props?.contdata?.contactInfo?.contactInfoEmail}</div>
            </div>

            <div className='flex items-center'>
                <div style={{width:"40px"}} className='z-0 p-2 rounded-2xl w-5'>
                  <img className='z-10 w-5' src={location}/>
                </div>
                <div className='mx-2'>{props?.contdata?.contactInfo?.contactInfoAddress}</div>
            </div>
        </div>
        )}
      <div>
       
      <div style={{position:"absolute", left:"0", bottom:"10px"}} className=' bg-white px-4 py-4 spacing -bottom-25 w-full text-center'>
      <div className='flex justify-center items-center'>
      <div style={{width:"100px", height:"1.5px", backgroundColor:"#E8E8E8"}} className='rounded' />
      </div>
      {props?.contdata?.onState?.socials && (
       <div className='flex items-center justify-evenly px-2 py-4'>
       <ul class="socil-link socil-link-inner" >
       {props?.contdata?.socialMediaData?.map(
                (val) =>
                  val.link &&
                  getBrandNameForSocial(val.nameOfSocial.toLowerCase()) && (
                    <li className="social_media_li" key={val.link}>
                      <a>
                       
                        <i
                          className={`fa-${
                            val?.nameOfSocial.toLowerCase() === "star"
                              ? "solid"
                              : "brands"
                          } fa-${getBrandNameForSocial(
                            val?.nameOfSocial.toLowerCase()
                          )}`}
                        ></i>
                      </a>
                    </li>
                  )
              )}
            </ul>
       </div>
       )}
               {/* <div>
               <button className='bg-coffee text-xl rounded text-white w-full font-bold font-Tajawal pt-3  p-2 '
                 >
                   Contact supplier
                 </button>
               </div> */}
        </div>
      </div>
    </div>
  )
}

export default ContactInfo;