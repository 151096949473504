import React from "react";
import { useState } from "react";

function SocialMediaLinkMake() {
  const [formList, setFormList] = useState([]);
  const [formCount, setFormCount] = useState(0);

  const createForm = () => {
    const key = { formCount };
    const newForm = (
      <div className="servicebox tile">
        <div className="row mb-3">
          <div class=" col-md-4">
            <label class="form-label">Social medai Name</label>
            <select name="type" class="form-select">
              <option value="">Select Social Medai</option>
              <option value="option1">FaceBook </option>
              <option value="option2">Twitter</option>
            </select>
          </div>
          <div class=" col-md-7">
            <label class="form-label">Link</label>
            <input class="form-control" type="text" placeholder="Link" />
          </div>
          <div class=" col-md-1">
            <i
              class="fa-solid fa-circle-xmark"
              onClick={() => removeForm(formCount)}
            ></i>
          </div>
        </div>
      </div>
    );

    setFormList([...formList, newForm]);
    setFormCount(formCount + 1);
  };

  const removeForm = (formKey) => {
    const updatedFormList = formList.filter((form) => form.key !== formKey);
    setFormList(updatedFormList);
  };

  return (
    <>
      <div className="row">
        {formList.map((form, index) => (
          <div key={form.key} className="col-12 col-lg-12">
            {form}
          </div>
        ))}
      </div>

      <button type="button" onClick={createForm} className="btn btn-cu">
        + Add New
      </button>
    </>
  );
}

export default SocialMediaLinkMake;
