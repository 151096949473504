import React, { useContext, useEffect, useRef, useState } from "react";
import PageName from "../Utils/PageName";
import { Link } from "react-router-dom";
import { environmentVariables } from "../../config/env.config";
import axios from "axios";
import { userContext } from "../../context/userContext";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ReactDOM from "react-dom";
import DataTable from "react-data-table-component";
import SortIcon from "@material-ui/icons/ArrowDownward";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import { businessContext } from "../../context/businessContext";
import CircularLoader from "../Components/CircularLoader/CircularLoader";
import { toast } from "react-toastify";
import $ from "jquery";
import { useCallback,useMemo } from "react";

function RequestCard() {

  const debounceTimer = useRef(null);
  const [data, setData] = useState([]);
  const [remark, setRemark] = useState([]);
  const [loading, setLoading] = useState(true);
  const baseUrl = environmentVariables?.apiUrl;
  const { userData, setUserData } = useContext(userContext);
  const [editOrderid, setEditOrderid] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [editedData, setEditedData] = useState({
    date: new Date(),
  });
  const [isSubmit, setIsSubmit] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const closeButtonRef = useRef(null);
  const [selectedDateFrom, setSelectedDateFrom] = useState("");
  const [selectedDateTo, setSelectedDateTo] = useState("");
  const [tempData, setTempData] = useState([]);
  const [businesslistData, setBusinesslistData] = useState([]);
  const [cardType, setCardType] = useState("");
  const [paymentStatus, setPaymentStatus] = useState("");
  const [orderStatus, setOrderStatus] = useState("");
  const [activeBusinessName, setActiveBusinessName] = useState("");
  const fromDate = useRef(null);
  const toDate = useRef(null);
  const [action] = useState({ fromUser: false });
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [paginationTotalRows, setPaginationTotalRows] = useState(0);
  const [paginationPerPage, setPaginationPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedRowsPerPage, setSelectedRowsPerPage] = useState([]);
  const createdDate = editedData?.created_at ? new Date(editedData.created_at) : null;

  const viewPdf = async (d) => {
    const routeName = `/ViewCardDownload/${d.id}`;
    const fullUrl = `${window.location.origin}${routeName}`;
    window.open(fullUrl, "_blank");
  };

  const handleChangeBusinessStatus = (e) => {
    if (e.target.value == "active") {
      setBusinessStatus("active");
    } else if (e.target.value == "inactive") {
      setBusinessStatus("inactive");
    } else if (e.target.value == "All Status") {
      setBusinessStatus("All Status");
    }
  };

  const showModal = () => {
    setIsModalOpen(true);
  };
  const hideModal = () => {
    $(closeButtonRef.current).trigger("click");
    setIsModalOpen(false);
  };

  const statusMap = {
    1: "Accepted",
    2: "Printed",
    3: "Dispatched",
    4: "Cancelled",
    5: "Completed",
  };

  const MainData = (page, limit) => {
    let obj = {
      // "business_id": localStorage.getItem("business_id"),
      type:cardType,
      business:activeBusinessName,
      status:orderStatus,
      to:selectedDateTo,
      from:selectedDateFrom
    };
    axios
      .post(`${baseUrl}api/v1/phy_card/adminvieworder_withpagination`, { page, limit, ...obj }, {
        headers: {
          _token: userData?.token,
        },
        withCredentials: true,
      })
      .then((response) => {
        setData(
          response?.data?.result?.sort((x, y) => y?.order_id - x?.order_id)
        );
        setTempData(
          response?.data?.result?.sort((x, y) => y?.order_id - x?.order_id)
        );
        setIsLoading(false);
        setPaginationTotalRows(response?.data?.pagination?.totalCount)        
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };
  const getAllBusinessByPhysicalCard = ()=>{
    axios
    .post(`${baseUrl}api/v1/phy_card/getAllBusinessByPhysicalCard`, {}, {
      headers: {
        _token: userData?.token,
      },
      withCredentials: true,
    })
    .then((response) => {
      let temp = response?.data?.result?.map((el) => el?.title);
      temp = temp.filter((el) => el != null);
      temp = new Set(temp);
      temp = [...temp];
      setBusinesslistData(temp);
    })
    .catch((error) => {
      console.log(error);
      setIsLoading(false);
    });
  }
  useEffect(()=>{
    getAllBusinessByPhysicalCard();
  },[])

  const handleOnSelectedRowsChange = useCallback(
    ({ selectedRows }) => {
      if (!action.fromUser) return; //the component always trigger this with 0 selected rows when it renders a page, what would clear the selection

      selectedRowsPerPage[currentPage] = selectedRows; //there is no way to tell if a row was DEselected, so I had to control the selected rows per page,
      //the array would get an index to control each page
      console.log(JSON.stringify(selectedRowsPerPage));
    },
    [currentPage, selectedRowsPerPage, action.fromUser]
  );

  const handlePageChange = (page) => {
    const nextPage = page; 
    MainData(nextPage);
    setCurrentPage(page);
  };

  const handleRowsPerPageChange = async (newRowsPerPage) => {
    if (!businesslistData.length) return;
    // Update the state first
    setRowsPerPage(newRowsPerPage);
    setCurrentPage(1); // Reset current page to 1
    setSelectedRowsPerPage([]);
    // Now call the function with the updated state
    MainData(1, newRowsPerPage);
  };

  useEffect(() => {
    MainData(1, rowsPerPage);
  }, []);

  useEffect(() => {
    setEditedData({ ...editedData, status: selectedStatus });
  }, [selectedStatus]);

  const fetchData = async (id) => {
    try {
      showModal();
      setEditOrderid(id.order_id);
      let obj = {
        order_id: id,
      };
      axios
        .post(`${baseUrl}api/v1/phy_card/ViewRequestOrderBySuperAdmin`, obj, {
          headers: {
            _token: userData?.token,
          },
        })
        .then((response) => {
          const updatedResponse = {
            ...response?.data?.result?.rs[0],
            date: response?.data?.result?.rs[0]?.delivery_date,
          };
          setEditedData(updatedResponse);
          setSelectedStatus(updatedResponse.status);
        })
        .catch((error) => {
          console.error("Error:", error?.response?.data?.message);
        });
    } catch (error) {
      console.error("API Error:", error?.response?.data?.message);
    }
  };
  const handleUpdate = async (e) => {
    e.preventDefault();
    setIsSubmit(true);
    editedData.selectedStatus = selectedStatus;
    editedData.physicalCardid = editOrderid;
    let sdata = editedData;
    sdata.editOrderid = editOrderid;
    if (sdata?.date !== undefined && sdata?.delivery_date !== undefined) {
      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${baseUrl}api/v1/phy_card/EditphysicalCardStatusBySuperAdmin`,
        headers: {
          _token: userData?.token,
          "Content-Type": "application/json",
        },
        data: sdata,
      };
      axios
        .request(config)
        .then((response) => {
          MainData();
          hideModal();
          toast.success("Successfully change status", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
          setIsSubmit(false);
        })
        .catch((error) => {
          console.log(error, "error");
          setIsSubmit(false);
        });
    } else {
      toast.warning("Kindly Fill the Date", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
      setIsSubmit(false);
    }
  };

  const handleDateChange = (date) => {
    setEditedData({
      ...editedData,
      date: date,
      delivery_date: date,
    });
  };

  const tableExtensions = { export: false, print: false };

  const customStyles = {
    dataTableExtensions: {
      backgroundColor: "lightgray",
      padding: "10px",
    },
    headRow: {
      style: {
        backgroundColor: "#e9e9e9",
        borderRadius: "2px 2px 0 0",
        padding: "25px",
        border: "none",
      },
    },
    headCells: {
      style: {
        color: "#525252",
        fontSize: "16px",
      },
    },
    rows: {
      style: {
        padding: "6px 0px 6px 25px",
        fontSize: "14px",
        textTransform: "capitalize",
        minHeight: "58px !important",
      },
    },
  };

  const columns = [
    {
      name: "Order Id",
      selector: row=>row.order_id,
      sortable: true,
      width: "15%",
    },
    {
      name: "Card Type",
      selector: row=>row.card_type,
      sortable: true,
    },
    {
      name: "Business Details",
      selector: row=>row.print_business_name,
      sortable: true,
    },
    {
      name: "Order Date",
      selector: (row) => {
        let dateString = row?.created_at.split("T")[0];
        const [year, month, day] = dateString.split("-");
        return `${day}/${month}/${year}`;
      },
      sortable: true,
    },
    {
      name: "Delivery Date",
      selector: row=>row.delivery_date,
      selector: (row) => {
        let dateString = row?.delivery_date?.split("T")[0];
        const [year, month, day] = dateString.split("-");
        return `${day}/${month}/${year}`;
      },
      sortable: true,
    },
    {
      name: "Tx. ID",
      selector: row=>row.txn_id,
      sortable: true,
    },
    {
      name: "Payment Status",
      selector: row=>row.payment_status,
      sortable: true,
      cell: (d) => {
        const capitalizedName =
          d?.payment_status?.charAt(0)?.toUpperCase() +
          d?.payment_status?.slice(1);
        return capitalizedName;
      },
    },
    {
      name: "Delivery Instructions",
      selector: "delivery_instructions" !== 0 ? row=>row.delivery_instructions : "--",
      sortable: true,
    },
    {
      name: "Order Status",
      selector: (row) => {
        if (row?.status === 4) {
          return "Cancelled";
        } else if (row?.status == 5) {
          return "Completed";
        } else if (row?.status == 3) {
          return "Dispatched";
        } else if (row?.status == 2) {
          return "Printed";
        } else if (row?.status == 1) {
          return "Accepted";
        } else {
          return "--";
        }
      },
      sortable: true,
    },
    {
      name: "Action",
      sortable: false,
      selector: "null",
      cell: (d) => [
        <>
          <i
            key={d?.id}
            data-bs-toggle="modal"
            data-bs-target="#planselect"
            onClick={() => fetchData(d)}
            className="first fas fa-pen"
          ></i>
          <i
            key={d?.id}
            onClick={() => viewPdf(d)}
            className="first fas fa-eye"
          ></i>
        </>,
      ],
    },
  ];

  const tableData = {
    columns,
    data,
  };

  const setFilterValueWithDebounce = () => {
    if (debounceTimer.current) {
      clearTimeout(debounceTimer.current);
    }
    debounceTimer.current = setTimeout(() => {
      MainData(1, rowsPerPage);
    }, 1000 * 1); // 1 seconds debounce time
  };
  useEffect(() => {
    setFilterValueWithDebounce();
    return () => {
      if (debounceTimer.current) {
        clearTimeout(debounceTimer.current);
      }
    };
  }, [cardType,
    orderStatus,
    activeBusinessName,
    selectedDateFrom,
    selectedDateTo]);

  const handleReset = () => {
    setSelectedDateFrom("");
    setSelectedDateTo("");
    setCardType("");
    setOrderStatus("");
    setActiveBusinessName("");
  };

  return (
    <>
      <PageName PageName="Physical Card Orders" />
      <article>
        <div className="main">
          {isLoading ? (
            <CircularLoader size={40} />
          ) : (
            <>
              <div class="Contact_filter_fieldbutton_wrapper">
                <div class="Contact_filter_field_wrapper">
                  <div class="col-md-2 Contact_filter_Inputfield">
                    {/* <h5 className="mb-3">Card Type</h5> */}
                    <select
                      value={cardType}
                      onChange={(e) => setCardType(e.target.value)}
                      class="form-select"
                    >
                      <option value="">All Type</option>
                      <option value="pvc">PVC </option>
                      <option value="metal">Metal </option>
                    </select>
                    <div
                      style={{
                        fontSize: "14px",
                        color: "red",
                        bottom: "70px",
                      }}
                    ></div>
                  </div>
                  <div class="col-md-2 Contact_filter_Inputfield">
                    {/* <h5 className="mb-3">Business Name</h5> */}
                    <select
                      value={activeBusinessName}
                      onChange={(e) => setActiveBusinessName(e.target.value)}
                      class="form-select"
                    >
                      <option value="All Business">All Business</option>
                      {businesslistData &&
                        businesslistData?.length &&
                        businesslistData?.map((el, index) => {
                          return <option value={el}>{el}</option>;
                        })}
                    </select>
                    <div
                      style={{
                        fontSize: "14px",
                        color: "red",
                        bottom: "70px",
                      }}
                    ></div>
                  </div>
                  <div class="col-md-2 Contact_filter_Inputfield">
                    {/* <h5 className="mb-3"> Order Status</h5> */}
                    <select
                      value={orderStatus}
                      onChange={(e) => setOrderStatus(e.target.value)}
                      class="form-select"
                    >
                      <option value="">All Status</option>
                      <option value="1">Accepted </option>
                      <option value="2">Printed </option>
                      <option value="3">Dispatched </option>
                      <option value="4">Cancelled </option>
                      <option value="5">Completed </option>
                    </select>
                    <div
                      style={{
                        fontSize: "14px",
                        color: "red",
                        bottom: "70px",
                      }}
                    ></div>
                  </div>

                  <div class="col-md-2 Contact_filter_Inputfield">
                    {/* <h5 className="mb-3"> Order Date From </h5> */}
                    <div className="dateiconmain">
                      <DatePicker
                        placeholderText="Oder Date From"
                        selected={selectedDateFrom}
                        onChange={(date) => setSelectedDateFrom(date)}
                        className="form-control"
                        ref={fromDate}
                        dateFormat="dd/MM/yyyy"
                        popperPlacement="bottom-end"
                      />
                      <div
                        class="contact_filter_toDate_calander_icon"
                        onClick={() => fromDate.current.setOpen(true)}
                      >
                        <i class="fa-solid fa-calendar"></i>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-2 Contact_filter_Inputfield">
                    {/* <h5 className="mb-3"> Order Date To</h5> */}
                    <div className="dateiconmain">
                      <DatePicker
                        placeholderText="Order Date To"
                        selected={selectedDateTo}
                        onChange={(date) => setSelectedDateTo(date)}
                        className="form-control"
                        ref={toDate}
                        dateFormat="dd/MM/yyyy"
                        popperPlacement="bottom-end"
                      />
                      <div
                        class="contact_filter_toDate_calander_icon"
                        onClick={() => toDate.current.setOpen(true)}
                      >
                        <i class="fa-solid fa-calendar"></i>
                      </div>
                    </div>
                  </div>

                  <div class="contact_filter_buttonwrapper">
                    <button
                      class="contact_filter_buttonstyle"
                      onClick={() => handleReset()}
                    >
                      Reset{" "}
                    </button>
                  </div>
                </div>
              </div>{" "}
              <DataTableExtensions
                {...tableData}
                {...tableExtensions}
                customStyles={customStyles}
                filterPlaceholder="Search Physical Card"
              >
                <DataTable
                  columns={columns}
                  data={data}
                  noHeader
                  defaultSortField="id"
                  sortIcon={<SortIcon />}
                  defaultSortAsc={true}
                  pagination
                  highlightOnHover
                  paginationServer
                  paginationTotalRows={paginationTotalRows}
                  paginationPerPage={paginationPerPage}
                  paginationRowsPerPageOptions={[10,20,50,100,300,1000,10000]} // Customize as needed
                  onChangePage={handlePageChange}
                  onChangeRowsPerPage={handleRowsPerPageChange}
                  onSelectedRowsChange={handleOnSelectedRowsChange}
                  dense
                  customStyles={customStyles}
                />
              </DataTableExtensions>
            </>
          )}
        </div>
      </article>
      <div
        className={`modal fade ${isModalOpen ? "show" : ""}`}
        id="planselect"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-lg">
          <div class="modal-content ">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
                Edit Status
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                ref={closeButtonRef}
              ></button>
            </div>
            <div class="modal-body">
              <h5>Order id : {editedData?.order_id}</h5>
              <hr />
              <form onSubmit={handleUpdate}>
                <div className="row g-3 mb-3">
                  <div className="col">
                    <h5>Status </h5>
                    <br />
                    <select
                      className="form-select"
                      value={editedData?.status}
                      onChange={(e) => setSelectedStatus(e.target.value)}
                    >
                      {Object.keys(statusMap)?.map((statusKey, inde) => (
                        <option key={inde} value={statusKey}>
                          {statusMap[statusKey]}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col ">
                    <h5>Delivery Date</h5>
                    <br />
                    <DatePicker
                      selected={
                        editedData?.delivery_date
                          ? new Date(editedData.delivery_date)
                          : null
                      }
                      onChange={handleDateChange}
                      className="form-select"
                      dateFormat="yyyy-MM-dd"
                      popperPlacement="bottom-end"
                      minDate={createdDate}
                    />
                  </div>
                </div>
                <div id="Coupanadmin_Button_Wrapper">
                  <button type="submit" className="btn btn-primary save-cta">
                    {isSubmit ? <CircularLoader size={20} /> : " Save changes"}
                    {/* Save changes */}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default RequestCard;
//612
