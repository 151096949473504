import React, { useState } from 'react';
import AllCollection from './AllCollection';
import { getServicesArray } from '../../../../utils/utility';

const HomeComponent = (props) => {
    const [activeTabb, setActiveTabb] = useState("All");
    const servicesArray = Array.isArray(props?.productdata?.servicesArray) ? props?.productdata?.servicesArray : [];
    const minimarketArray = Array.isArray(props?.productdata?.minimarketArray) ? props?.productdata?.minimarketArray : [];
    const renderContent = () => {
        let filteredProducts;

        if (activeTabb === "All") {
            filteredProducts = minimarketArray;
        } else {
            filteredProducts = minimarketArray.filter(
                product => product.product_service == activeTabb
            );
        }

        return <AllCollection products={filteredProducts} />;
    };

    const getButtonClass = (tabName) => {
        return tabName === activeTabb
            ? 'bg-coffee border-none px-4 py-1 text-base rounded-full text-white'
            : 'px-4 py-1 border border-darkUnactive rounded-full text-base  text-b45';
    };

    return (
        props?.productdata?.onState?.miniproduct ? (
        <div>
            <div className='flex overflow-x-auto'>
            <div>
                    <button
                        className={getButtonClass("All")}
                        onClick={() => setActiveTabb("All")}
                        style={{ margin: '0 10px' }}
                    >
                        All
                    </button>
                </div>
                {getServicesArray(servicesArray).map((service, index) => (
                    <div key={index}>
                        <button
                            className={getButtonClass(service.id)}
                            onClick={() => setActiveTabb(service.id)}
                            style={{ margin: '0 10px', width:"max-content" }}
                        >
                            {service.name}
                        </button>
                    </div>
                ))}
            </div>
            <div className="px-2 py-2">{renderContent()}</div>
        </div>
        ) : null
    );
};

export default HomeComponent;
