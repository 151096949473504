import React, { useEffect, useContext, useState, useRef, useCallback } from "react";
import Drawer from "react-bottom-drawer";
import { userContext } from "../../../../context/userContext";
import { environmentVariables } from "../../../../config/env.config";
import { useParams, useLocation } from "react-router-dom";
import axios from "axios";
import QRCode from "qrcode.react";
import "./Theme14.css";
import CircularLoader from "../../../Components/CircularLoader/CircularLoader";
import banner from "../../../../Images/SalonBanner.png";
import logo from "../../../../Images/logo1.jpg";
import QrCodeGenerator from "../theme6/QRCode";
import TopHeaderImage from "../../../../Images/Restaurant/TopImage.png";
import MenuIcon from "../../../../Images/Restaurant/Tabmenu.png";
import MenuIcon2 from "../../../../Images/Restaurant/Tabmenu2.png";
import ReviewIcon from "../../../../Images/Restaurant/TabReview.png";
import ReviewIcon2 from "../../../../Images/Restaurant/TabReview2.png";
import OrderIcon from "../../../../Images/Restaurant/tabOrder.png";
import OrderIcon2 from "../../../../Images/Restaurant/tabOrder2.png";
import OpeningIcon from "../../../../Images/Restaurant/tabopeninghr.png";
import OpeningIcon2 from "../../../../Images/Restaurant/tabopeninghr2.png";
import GalleryIcon from "../../../../Images/Restaurant/tabimage-gallery.png";
import GalleryIcon2 from "../../../../Images/Restaurant/tabimage-gallery2.png";
import SocialIcon from "../../../../Images/Restaurant/tabsocial-media.png";
import SocialIcon2 from "../../../../Images/Restaurant/tabsocial-media2.png";
import ContactIcon from "../../../../Images/Restaurant/tabcontact-us.png";
import ContactIcon2 from "../../../../Images/Restaurant/tabcontact-us2.png";
import AboutIcon from "../../../../Images/Restaurant/tabinfo-button.png";
import AboutIcon2 from "../../../../Images/Restaurant/tabinfo-button2.png";
import {
  ChefName, ConfirmOrder, ConfirmOrderWrapper, CustomerDetailText, CustomerDetailsWrapper,
  Designation, DrawerCloseButton, DrawerCloseButtonWrapper, Image, InputTextField, InputTextFieldWrapper,
  Name, TabCustomerDetailsWrapper, TabImage, TabImageWrapper, TabText, TabTextWrapper, TabWrapper, TabWrapperContainer,
  TextDetails, TopImageWrapper, Wrapper
} from "./Theme14ExtStyleComponent";
import TabMenu from "./TabMenu";
import { TabReviewrating, fetchReviews } from "./TabReviewrating";

import TabOrder from "./TabOrder";
import TabOpeningHours from "./TabOpeningHours";
import { TabGallery } from "./TabGallery";
import { TabSocialMedia } from "./TabSocialMedia";
import { TabContactInfo } from "./TabContactInfo";
import TabAbout from "./TabAbout";
import CustomerDetails from "./CustomerDetails";
import CustomerReview from "./CustomerReview";
import Orderreview from "./Orderreview";
import whiteCircle from "../../../../../src/Images/white-circle.png";
import Swal from "sweetalert2";
import Radar from "radar-sdk-js";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getContactInfo, getServicesArray } from "../../../../utils/utility";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


const DineIn = styled.div`
    color: #fff;
    font-size: 10px;
    background: linear-gradient(to right, #3D8C20, #7BD25B);
    padding: 7px;
    border-radius: 50px 0 0 50px;
    width: 100px;
    right: 0;
    top: 20px;
    position: absolute;
      @media(max-width:480px) {
              position: absolute;
              right: 0;
              top: -35px!important;
            }
`;
const LocationInfo = styled.div`
    color: #fff;
    font-size: 12px;
    background: #252525bd;
    padding: 7px 15px;
    margin-top: 5px;
    border-radius: 50px;
    border: 1px solid #CD2F34;
    margin: 0 30px;
`;


const Theme14Ext = (props) => {
  const rs = props.rs;
  const [businessLink, setBusinessLink] = useState("");
  const [storedData, setStoredData] = useState(
    localStorage.getItem("businessObj")
  );
  const baseUrl = environmentVariables?.apiUrl;
  const { userData } = useContext(userContext);
  let { param } = useParams();
  const [pparam, setPparam] = useState([]);
  const searchParams = new URLSearchParams(window.location.search);
  const appParams = searchParams.get('isApp');
  const paramValue = searchParams.get("param");
  const location = useLocation();
  const [galleryObject, setGalleryObject] = useState(null);
  const createUserRef = useRef(null);
  const currentDate = new Date();
  const formattedDate = currentDate.toISOString();
  const [order, setOrder] = useState(null);
  const [minDate, setMinDate] = useState("");
  const calendarInputRef = useRef(null);
  const activeTabParam = searchParams.get("activeTab");
  const [activeTab, setActiveTab] = useState("");
  const [isReviewDrawerVisible, setIsReviewDrawerVisible] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const openDrawer = useCallback(() => setIsVisible(true), []);
  const closeDrawer = useCallback(() => setIsVisible(false), []);
  const [userReview, setUserReview] = useState();
  const openReviewDrawer = useCallback(() => setIsReviewDrawerVisible(true), []);
  // const closeReviewDrawer = useCallback(() => setIsReviewDrawerVisible(false), []);
  const closeReviewDrawer = () => {
    setIsReviewDrawerVisible(false);
    const url = new URL(window.location.href);
    url.searchParams.delete("activeTab");
    url.searchParams.delete("orderId");
    window.history.replaceState({}, '', url);
  };
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };
  const [formData, setFormData] = useState(null);
  const [isOrderReviewVisible, setIsOrderReviewVisible] = useState(false);
  const openOrderReview = () => {
    setIsOrderReviewVisible(true);
  };

  const closeOrderReview = () => {
    setIsOrderReviewVisible(false);
  };

  const handleNextClick = () => {
    setActiveTab("order");
  };

  const [currencySymbol, setCurrencySymbol] = useState('₹');

  useEffect(() => {
      if (props.rs?.contactInfo?.content) {
        const contactInfo = JSON.parse(props.rs.contactInfo.content);
        const phoneNumber = contactInfo.contactInfoPhone;
        if(!!phoneNumber){
          const countryCode = phoneNumber.match(/^\+(\d+)/)[1]; // Extract the country code
    
          switch (countryCode) {
            case '91':
              setCurrencySymbol('₹');
              break;
            case '1':
              setCurrencySymbol('$');
              break;
            case '971':
              setCurrencySymbol('AED');
              break;
            // Add other country codes and their currency symbols as needed
            default:
              setCurrencySymbol('₹'); // Default to INR
              break;
          }
        }
      }
    }, [props.rs?.contactInfo]);

  useEffect(() => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}`;
    setMinDate(formattedDate);
  }, []);

  useEffect(() => {
    if (activeTabParam) {
      setActiveTab(activeTabParam);
      if (activeTabParam === "review") {
        openReviewDrawer();
      }
      // Check if orderId is present in the URL
      const orderIdParam = searchParams.get("orderId");
      if (orderIdParam) {
        fetchOrdersByOrderId(orderIdParam);
      }
    }
  }, [activeTabParam]);
  // }, [activeTabParam, openReviewDrawer, searchParams]);

  const fetchOrdersByOrderId = async (orderId) => {
    try {
      // Make API call to fetch orders by orderId
      const response = await axios.get(`${environmentVariables?.apiUrl}api/v1/business/fetchOrdersByOrderId?orderId=${orderId}`);
      const orders = response?.data?.orders;
      setUserReview(response?.data?.orders)

    } catch (error) {
      console.error("Error fetching orders by orderId:", error);
      // Handle error
    }
  };

  let ff = rs?.contactInfo?.content;
  if (ff?.length) {
    ff = JSON?.parse(ff);
  }
  if (ff?.length) {
    ff = JSON?.parse(ff);
  }

  useEffect(() => {
    const path = location?.pathname;
    const pathParts = path.split("/");
    setPparam(pathParts);
  }, [location]);


  useEffect(() => {
    if (storedData != "undefined") {
      const businessData = JSON.parse(storedData);
      const slugb = businessData?.slug;
      const originUrl = window.location.origin;
      setBusinessLink(`${originUrl}/${slugb}`);
    }
  }, [storedData]);


  useEffect(() => {
    if (rs?.menu?.is_enabled === 1) {
      setActiveTab("menu");
    } else {
      setActiveTab("about");
    }
  }, [rs]);

  let contactInfo = props?.rs?.contactInfo?.content;

  // parsing into string
  if (contactInfo?.length) {
    contactInfo = JSON.parse(contactInfo);
  }
  // parsing into object
  if (contactInfo?.length) {
    contactInfo = JSON.parse(contactInfo);
  }
  const VCardContent = `BEGIN:VCARD
VERSION:3.0
N:${props?.rs?.sub_title}
FN:${props?.rs?.sub_title}
ORG:${props?.rs?.title}
TITLE:${props?.rs?.designation}
URL:https://app.businessbay.io/${props?.rs?.slug}
TEL;TYPE=CELL:${contactInfo?.contactInfoPhone}
EMAIL;TYPE=INTERNET:${contactInfo?.contactInfoEmail}
ADR;TYPE=HOME:;${contactInfo?.contactInfoAddress}
URL:${contactInfo?.contactInfoWebUrl}
X-ABADR:country
X-ABLabel:Title
X-ABLabel:eBusiness Card
NOTE;CHARSET=UTF-8:${props?.rs?.description?.replace(/\s+/g, ' ').trim()}
END:VCARD`;

  const downloadVCard = () => {
    const blob = new Blob([VCardContent], { type: "text/vcard" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "contact.vcf"; // Set the desired filename here

    // Create a click event
    const clickEvent = new MouseEvent("click", {
      bubbles: true,
      cancelable: false,
      view: window,
    });

    // Dispatch the click event
    a.dispatchEvent(clickEvent);

    // Revoke the object URL to free up resources
    window.URL.revokeObjectURL(url);
  };

  useEffect(() => {
    getBlockOrder();
    if (rs?.newGalleryImages) {
      setGalleryObject(rs?.newGalleryImages);
    }
    if (rs?.appointmentObj && rs?.appointmentObj?.content) {
      try {

      } catch (err) {
        console.log(err);
      }
    }
  }, [rs]);
  const getBlockOrder = async () => {
    // const handleGetBlock = async () => {
    try {
      let id = props?.id;

      if (id) {
        let { data: get } = await axios.get(
          `${environmentVariables?.apiUrl}api/v1/business/FetchBlock?business_id=${id}`,
          {
            headers: {
              _token: userData?.token,
            },
          }
        );
        setOrder(get?.data?.order);
      }

    } catch (err) {
      console.log(err);
    }
    // };
  };

  const [orderItems, setOrderItems] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [enableCreateOrder, setEnableCreateOrder] = useState(false);

  const handleAddToOrder = (name, price, image, quantity = 1) => {
    const newItem = { name, price, image, quantity };
    setOrderItems([...orderItems, newItem]);
  };

  const handleRemoveFromOrder = (name) => {
    setOrderItems(orderItems.filter(item => item.name !== name));
};
  const removeItem = (index) => {
    setOrderItems((prevItems) => prevItems.filter((item, i) => i !== index));
  };

  const handleFormSubmit = (formData) => {
    setFormData(formData);
    setEnableCreateOrder(true);
    // openOrderReview();
  };
  useEffect(() => {
    if (enableCreateOrder) {
      createOrder();
      setEnableCreateOrder(false);
      whatsappclick();
    }
  }, [enableCreateOrder])

  const updateTotalAmount = (amount) => {
    setTotalAmount(amount);
  };

  const createOrder = async () => {
    try {
      const requestData = {
        businessId: props?.id,
        BusinessName: props?.rs?.slug,
        userId: props?.rs?.created_by,
        totalAmount,
        ...formData,
        items: orderItems,
      };

      // Make API call to create order
      const response = await axios.post(`${environmentVariables?.apiUrl}api/v1/business/createOrder`, requestData,
        {
          headers: {
            _token: userData?.token,
          },
        });

      // Swal.fire({
      //   icon: "success",
      //   title: "Thank You",
      //   text: "Your order has been successfully placed",
      //   // icon: "question"
      // });
      toast.success("Your order has been successfully placed", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
      setFormData(null);
      setOrderItems([]);
      closeOrderReview();
      setActiveTab("menu")
    } catch (error) {
      console.error("Error creating order:", error);
      toast.error("Something went wrong while creating order", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
    }
  };


  const [lat, setLat] = useState(null);
  const [long, setLong] = useState(null);

  useEffect(() => {
    Radar.initialize("prj_live_pk_8cef6dee5f31e220f09eba68a33c1556053a8e3b");
  }, []);

  const getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(showPosition);
    } else {
      console.log("Geolocation is not supported by this browser.");
    }
  };

  const showPosition = (position) => {
    setLat(position.coords.latitude);
    setLong(position.coords.longitude);
    Radar.reverseGeocode(
      {
        latitude: position.coords.latitude,
        longitude: position.coords.longitude,
      },

      function (err, result) {
        if (err) {
          console.log(err, "error");
          return;
        }

      }
    );
  };


  const fetchReviewsFromTabReviewrating = (businessId) => {
    fetchReviews(businessId);
    setActiveTab("menu")
  };

  const itemsInfo = orderItems.map(item => `${item.quantity} x ${item.name}: ${currencySymbol} ${item.price},`).join('\n');



  const whatsappclick = () => {
    const phoneNumber = contactInfo?.contactInfoPhone;
    // const phoneNumber = "8929099255";
    const { name, phone, address } = formData;
    const message = encodeURIComponent(`Hi, I'd like to place an order.\nCustomer details:\nName: ${name}\nPhone: ${phone}\nAddress: ${address}\nOrder Detail: \n${itemsInfo}\nTotal Amount: ${currencySymbol} ${totalAmount}`);
    const message2 = encodeURIComponent(`Hi, I'd like to place an order: ${itemsInfo} ${formData}.`)
    const apiUrl = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${message}`;
    window.open(apiUrl, '_blank');

  };

  const getImage = (image) => {
    if (image.includes("base64")) {
      return image;
    } else {
      return `${environmentVariables?.apiUrl}uploads/${image}`;
    }
  };



  //{`${isVisible ? "blur-background " : ""}main-temaplt`}
  return (
    <div id="temp14">
      <div className="main-temaplt" id="temp14">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 wtemplate p-0">
              {/******************** Reasturant  *********/}
              <Wrapper>
                <TopImageWrapper>
                  <Image src={rs?.banner ? getImage(rs.banner) : TopHeaderImage} />
                  <TextDetails>
                    {rs?.is_dine && <DineIn>Dine-in available</DineIn>}
                      <Name>{rs.title || "Restaurant Name"} </Name>
                      {rs?.serviceObj?.is_enabled == 1 && (
                        <div className="mainservicebox">
                          {rs?.serviceObj?.content && (
                            <ul className="servesbox">
                             {getServicesArray(rs.serviceObj.content)
                             .slice(0, 7).map((val, index, array) => (
                                  val?.name && (
                                    <React.Fragment key={index}>
                                      <li className="service-list">
                                        {val.name}
                                      </li>
                                      {index < array.length - 1 && (
                                        <li key={`${index}-dash`} className="service-list dash">
                                          -
                                        </li>
                                      )}
                                    </React.Fragment>
                                  )
                                ))}
                            </ul>
                          )}
                        </div>
                      )}
                    {getContactInfo(rs?.contactInfo?.content) &&
                      <LocationInfo>
                        {getContactInfo(rs?.contactInfo?.content)}
                      </LocationInfo>
                    }
                    
                    {/* <ChefName>{rs.sub_title || "Name"}</ChefName>
                    <Designation>{rs.designation || "Designation"}</Designation> */}
                  </TextDetails>
                </TopImageWrapper>

                <TabWrapperContainer>
                  {rs?.menu?.is_enabled == 1 && (
                    <TabWrapper
                      active={activeTab === "menu"}
                      onClick={() => handleTabClick("menu")}
                      background={activeTab === "menu" ? "#FCEBEB" : "#F3F3F3"}
                      color={activeTab === "menu" ? "#FCEBEB" : "#F3F3F3"}
                    >
                      <TabImageWrapper>
                        <TabImage src={activeTab === "menu" ? MenuIcon2 : MenuIcon} />
                      </TabImageWrapper>
                      <TabTextWrapper>
                        <TabText
                          color={activeTab === "menu" ? "#DE3036" : "#A2A3A4"}
                        >Menu</TabText>
                      </TabTextWrapper>

                    </TabWrapper>
                  )}
                  {/* {rs?.is_enabledGooglereview == 1 && (
                    <TabWrapper
                      active={activeTab === "review"}
                      onClick={() => handleTabClick("review")}
                      background={activeTab === "review" ? "#FCEBEB" : "#F3F3F3"}
                    >
                      <TabImageWrapper>
                        <TabImage
                          src={activeTab === "review" ? ReviewIcon2 : ReviewIcon}
                        />
                      </TabImageWrapper>
                      <TabTextWrapper>
                        <TabText
                          color={activeTab === "review" ? "#DE3036" : "#A2A3A4"}
                        >Review & Rating</TabText>
                      </TabTextWrapper>

                    </TabWrapper>
                  )} */}
                  {rs?.is_order_enabled == 1 && (

                    <TabWrapper
                      active={activeTab === "order"}
                      onClick={() => handleTabClick("order")}
                      background={activeTab === "order" ? "#FCEBEB" : "#F3F3F3"}
                    >
                      <TabImageWrapper>
                        <TabImage
                          src={activeTab === "order" ? OrderIcon2 : OrderIcon}
                        />
                      </TabImageWrapper>
                      <TabTextWrapper>
                        <TabText
                          color={activeTab === "order" ? "#DE3036" : "#A2A3A4"}
                        >Order</TabText>
                      </TabTextWrapper>

                    </TabWrapper>
                  )}
                  {rs?.businessHourObj?.is_enabled == 1 && (
                    <TabWrapper
                      active={activeTab === "openinghours"}
                      onClick={() => handleTabClick("openinghours")}
                      background={activeTab === "openinghours" ? "#FCEBEB" : "#F3F3F3"}
                    >
                      <TabImageWrapper>
                        <TabImage src={activeTab === "openinghours" ? OpeningIcon2 : OpeningIcon} />
                      </TabImageWrapper>
                      <TabTextWrapper>
                        <TabText
                          color={activeTab === "openinghours" ? "#DE3036" : "#A2A3A4"}
                        >Opening Hours</TabText>
                      </TabTextWrapper>
                    </TabWrapper>
                  )}
                  {rs?.gallaryObj?.is_enabled == 1 && (
                    <TabWrapper
                      active={activeTab === "Gallery"}
                      onClick={() => handleTabClick("Gallery")}
                      background={activeTab === "Gallery" ? "#FCEBEB" : "#F3F3F3"}
                    >
                      <TabImageWrapper>
                        <TabImage src={activeTab === "Gallery" ? GalleryIcon2 : GalleryIcon} />
                      </TabImageWrapper>
                      <TabTextWrapper>
                        <TabText
                          color={activeTab === "Gallery" ? "#DE3036" : "#A2A3A4"}
                        >Gallery</TabText>
                      </TabTextWrapper>
                    </TabWrapper>
                  )}
                  {rs?.SocialMediaObj?.is_enabled == 1 && (
                    <TabWrapper
                      active={activeTab === "Socialmedia"}
                      onClick={() => handleTabClick("Socialmedia")}
                      background={activeTab === "Socialmedia" ? "#FCEBEB" : "#F3F3F3"}
                    >
                      <TabImageWrapper>
                        <TabImage src={activeTab === "Socialmedia" ? SocialIcon2 : SocialIcon} />
                      </TabImageWrapper>
                      <TabTextWrapper>
                        <TabText
                          color={activeTab === "Socialmedia" ? "#DE3036" : "#A2A3A4"}
                        >Social Media</TabText>
                      </TabTextWrapper>
                    </TabWrapper>
                  )}
                  {rs?.contactInfo?.is_enabled == 1 && (
                    <TabWrapper
                      active={activeTab === "Contactinfo"}
                      onClick={() => handleTabClick("Contactinfo")}
                      background={activeTab === "Contactinfo" ? "#FCEBEB" : "#F3F3F3"}
                    >
                      <TabImageWrapper>

                        <TabImage src={activeTab === "Contactinfo" ? ContactIcon2 : ContactIcon} />

                      </TabImageWrapper>
                      <TabTextWrapper>
                        <TabText
                          color={activeTab === "Contactinfo" ? "#DE3036" : "#A2A3A4"}
                        >Contact Info</TabText>
                      </TabTextWrapper>
                    </TabWrapper>
                  )}
                  <TabWrapper
                    active={activeTab === "about"}
                    onClick={() => handleTabClick("about")}
                    background={activeTab === "about" ? "#FCEBEB" : "#F3F3F3"}
                  >
                    <TabImageWrapper>
                      <TabImage
                        src={activeTab === "about" ? AboutIcon2 : AboutIcon} />

                    </TabImageWrapper>
                    <TabTextWrapper>
                      <TabText
                        color={activeTab === "about" ? "#DE3036" : "#A2A3A4"}
                      > About</TabText>
                    </TabTextWrapper>

                  </TabWrapper>
                </TabWrapperContainer>
              </Wrapper>

              {activeTab === "menu" && (
                <>
                <div>
                  <TabMenu rs={rs} handleAddToOrder={handleAddToOrder} addedItems={orderItems} handleRemoveFromOrder={handleRemoveFromOrder} />
                </div>
                </>
              )}
              {activeTab === "review" && (
                <>
                  <div>
                    <TabReviewrating rs={rs} fetchReviews={fetchReviews} />
                  </div>
                  <div className="fixedd" style={{}}>
                    <Drawer
                      duration={150}
                      hideScrollbars={true}
                      onClose={closeReviewDrawer}
                      isVisible={isReviewDrawerVisible}
                      className="drawer"
                    >
                      <CustomerReview closeReviewDrawer={closeReviewDrawer} onSubmit={handleFormSubmit} userReview={userReview} fetchReviewsFromTabReviewrating={fetchReviewsFromTabReviewrating} toast={toast} />

                    </Drawer>

                  </div>
                </>
              )}
              {activeTab === "order" && (
                <div>
                  <TabOrder orderItems={orderItems} removeItem={removeItem} updateTotalAmount={updateTotalAmount} setOrderItems={setOrderItems} rs={rs}/>
                </div>
              )}
              {activeTab === "openinghours" && (
                <div>
                  <TabOpeningHours rs={rs} />
                </div>
              )}
              {activeTab === "Gallery" && (
                <div>
                  <TabGallery rs={rs} />
                </div>
              )}
              {activeTab === "Socialmedia" && (
                <div>
                  <TabSocialMedia rs={rs} />
                </div>
              )}
              {activeTab === "Contactinfo" && (
                <div>
                  <TabContactInfo rs={rs} />
                </div>
              )}
              {activeTab === "about" && (
                <div>
                  <TabAbout rs={rs} />
                </div>
              )}
              {/****************  Reasturant  ************/}
              {
                activeTab === "order" ? (
                  <>
                    {orderItems.length > 0 ? (

                      <div className="fixedd">
                        <article className="fixedfooter sec"
                          onClick={openDrawer}>
                          <div className="addtocinatct"
                            style={{
                              fontFamily: "Plus Jakarta Sans, sans-serif",
                              fontWeight: "700",
                              fontSize: "22px",
                              color: "#fff",
                              cursor: "pointer",
                            }}
                          >
                            Place Order
                          </div>
                        </article>
                        <div style={{ width: "100%",}}>
                          <Drawer
                            duration={150}
                            hideScrollbars={true}
                            onClose={closeDrawer}
                            isVisible={isVisible}
                            className="drawer">
                            <CustomerDetails closeDrawer={closeDrawer} onSubmit={handleFormSubmit} />
                          </Drawer>
                        </div>

                        <div>
                          <Drawer
                            duration={150}
                            hideScrollbars={true}
                            onClose={closeOrderReview}
                            isVisible={isOrderReviewVisible}
                            className="drawer">
                            <Orderreview formData={formData} createOrder={createOrder} closeOrderReview={closeOrderReview} />
                          </Drawer>
                        </div>

                      </div>
                    ) : (
                      <></>
                    )}
                  </>
                ) : (

                  appParams == null &&
                  <>
                    {/* <div className="fixedd">
                      <article className="fixedfooter sec">
                        <div className="footerleft">
                          <div
                            className="qrcodeshaere"
                            data-bs-toggle="modal"
                            data-bs-target="#qrscan"
                          >
                            <i class="fa-solid fa-qrcode"></i>
                          </div>
                        </div>
                        <div className="addtocinatct" onClick={downloadVCard}>
                          <i class="fa-solid fa-download"></i>
                          Download
                        </div>
                        <div className="addtocinatct" onClick={downloadVCard}>
                          <i class="fa-solid fa-user-plus"></i>
                          Add To Contact
                        </div>
                      </article>
                    </div> */}
                  </>
                )}
                {orderItems.length > 0 && activeTab === "menu" && (
                  <div className="fixedd">
                  <article className="fixedfooter sec" onClick={handleNextClick}>
                    <div
                      className="addtocinatct"
                      style={{
                        fontFamily: "Plus Jakarta Sans, sans-serif",
                        fontWeight: "700",
                        fontSize: "14px",
                        color: "#fff",
                        cursor: "pointer",
                      }}
                    >

                      {orderItems?.length} Item selected 
                      <span style={{fontSize:'20px'}}>continue  &nbsp;
                        <i class="fa fa-arrow-right" aria-hidden="true"></i>
                      </span>  
                    </div>
                  </article>
                  </div>
                  )}
            </div>
          </div>
        </div>
      </div>
      <div
        class="modal fade"
        id="qrscan"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-body" style={{ position: "relative" }}>
            {rs && rs.logo ? (
              <img
                className="qr-logo-image"
                src={`${environmentVariables?.apiUrl}uploads/${rs.logo}`}
              />
            ) : (
              <img
                className="qr-logo-image"
                src={whiteCircle}
                alt="White Circle"
              />
            )}
            <QrCodeGenerator />
          </div>
        </div>
      </div>
      <ToastContainer />

    </div>
  );
};

export default Theme14Ext;
