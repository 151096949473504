import React, { useContext, useEffect, useState,useCallback, useRef } from "react";
import PageName from "../Utils/PageName";
import { environmentVariables } from "../../config/env.config";
import axios from "axios";
import { userContext } from "../../context/userContext";
import DataTable from "react-data-table-component";
import SortIcon from "@material-ui/icons/ArrowDownward";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import CircularLoader from "../../Backend/Components/CircularLoader/CircularLoader";
import Modal from 'react-modal';

Modal.setAppElement('#root');

function Businessbay_enquiry() {
  const [data, setData] = useState([]);
  const [selectedMessage, setSelectedMessage] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const baseUrl = environmentVariables?.apiUrl;
  const { userData } = useContext(userContext);
  const createCouponModalRef = useRef(null);
  const [isLoading, setIsLoading] = useState(true);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selectedRowsPerPage, setSelectedRowsPerPage] = useState([]);
  const [action] = useState({ fromUser: false });
  const [paginationTotalRows, setPaginationTotalRows] = useState(0);
  const [paginationPerPage, setPaginationPerPage] = useState(10);


  const fetchEvents = async (page) => {
    try {
      axios
        .get(`${baseUrl}api/v1/info/fetchBusinessbayEnquiry?page=${page}&limit=${rowsPerPage}`, {
          headers: {
            _token: userData?.token,
          },
          withCredentials: true,
        })
        .then((response) => {
          console.log(response?.data.totalItems,"23ed")
          setData(response?.data?.result);
          setPaginationTotalRows(response?.data?.totalItems);
          setTotalRows(response?.data?.totalItems || 0);
          setIsLoading(false)

        })
        .catch((error) => {
          console.log(error);
          setIsLoading(false)
        });
    } catch (err) { }
  };
  useEffect(() => {
    fetchEvents(currentPage, perPage);
  }, [currentPage, perPage]);

  const handlePageChange = (page) => {
    const nextPage = page; 
    fetchEvents(nextPage);
    setCurrentPage(page);
  };

  const handleRowsPerPageChange = async (newRowsPerPage) => {
    setRowsPerPage(newRowsPerPage);
    setCurrentPage(1);
    setSelectedRowsPerPage([]);
    fetchEvents(1);
  };

  const handleOnSelectedRowsChange = useCallback(
    ({ selectedRows }) => {
      if (!action.fromUser) return; //the component always trigger this with 0 selected rows when it renders a page, what would clear the selection

      selectedRowsPerPage[currentPage] = selectedRows; //there is no way to tell if a row was DEselected, so I had to control the selected rows per page,
    },
    [currentPage, selectedRowsPerPage, action.fromUser]
  );

  const handleViewMessage = (message) => {
    setSelectedMessage(message); // Set the selected message
    setIsModalOpen(true); // Open the modal
  };

  const handleCloseModal = () => {
    setIsModalOpen(false); // Close the modal
  };

  const columns = [
    {
      name: "Name",
      selector: (row) => row.first_name,
      sortable: true,
      width: "180px",
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
      width: "300px",
    },
    {
      name: "Date",
      selector: (row) => {
        if (!row.created_at) {
          return "--"; // Return "--" if the date is null or undefined
        }
        const date = new Date(row.created_at);
        return isNaN(date.getTime()) ? "--" : date.toLocaleDateString('en-GB');
      },
      sortable: true,
      width: "180px",
    },
    {
      name: "Message",
      selector: (row) => {
        return <span> {row.message} </span>
      },
      sortable: true,
      // width: "200px",
    },
    {
      name: "Action",
      sortable: false,
      cell: (row) => (
        <i
          className="first fas fa-eye"
          onClick={() => handleViewMessage(row.message)} // Handle view action
          style={{ cursor: "pointer", marginRight: "10px" }}
        ></i>
      ),
    },
  ];

  const customStyles = {
    
    headRow: {
      style: {
        display: "flex",
        alignItems: "stretch",
        width: "100%",
        backgroundColor: "#E9E9E9",
        minHeight: "52px",
        borderRadius: "2px 2px 0px 0px",
        padding: "8px 25px",
        border: "none",
       
      },
    },
    headCells: {
      style: {
        color: "#525252",
        fontSize: "16px",
        minHeight: "52px",
        fontSize:"15px",
      
      },
    },
    rows: {
      style: {
        padding: "20px 25px",
        fontSize: "14px",
        textTransform: "capitalize",
        minHeight: "48px ",
      },
    },
  };

  const tableData = {
    columns,
    data,
  };


  return (
    <>
      <PageName PageName="BusinessBay Enquiry" />

      <article>
        <div className="main">
          {isLoading ? (
            <CircularLoader size={40} />
          ) : (
            <DataTableExtensions 
            {...tableData} 
            filterPlaceholder="Search Event"
            customStyles={customStyles}
            print={false} // Disable print functionality
             export={false}
            >
              <DataTable
                columns={columns}
                data={data}
                noHeader
                defaultSortField="id"
                sortIcon={<SortIcon />}
                defaultSortAsc={true}
                pagination
                highlightOnHover
                paginationServer
                dense
                paginationTotalRows={paginationTotalRows}
                paginationPerPage={paginationPerPage}
                paginationRowsPerPageOptions={[10,20,50,100,300,1000,10000]} // Customize as needed
                onChangePage={handlePageChange}
                onChangeRowsPerPage={handleRowsPerPageChange}
                onSelectedRowsChange={handleOnSelectedRowsChange}

                customStyles={customStyles}
              />
            </DataTableExtensions>
          )}
        </div>
        <Modal
          isOpen={isModalOpen}
          onRequestClose={handleCloseModal}
          contentLabel="View Message"
          style={{
            content: {
              top: '50%',
              left: '50%',
              right: 'auto',
              bottom: 'auto',
              marginRight: '-50%',
              transform: 'translate(-50%, -50%)',
              width: '50%',
              padding: '20px',
            },
          }}
        >
          <h2>Message</h2>
          <p>{selectedMessage}</p>
          <button onClick={handleCloseModal}>Close</button>
        </Modal>
      </article>
    </>
  );
}

export default Businessbay_enquiry;
