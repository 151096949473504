import React from "react";
import { environmentVariables } from "../../../../config/env.config";
import theme6strip from "../../../../Images/theme6strip.png";
import { getServicesArray } from "../../../../utils/utility";

function SubService(props) {
  let rs = props.rs;

  // console.log(props, "rssubservice");
  return (
    <>
      <article className="servicesName sec">
        <h3 class="sectitle service">Services </h3>
        <div className="mainservicebox">
          {/* <ul className="servesbox">
            {JSON.parse(rs?.serviceObj?.content).map((val) => (
              <li className="service-list">{val?.name}</li>
            ))}{" "}
          </ul> */}
          {rs?.serviceObj?.content && (
            <ul className="servesbox">
              {getServicesArray(rs.serviceObj.content).map((val, index) => (
                val?.name && (
                  <li key={index} className="service-list">
                    {val.name}
                  </li>
                )
              ))}
            </ul>
          )}
        </div>
      </article>
      {/* <img src={theme6strip} className="stripservice" /> */}
    </>
  );
}
export default SubService;
