
import React from 'react'
import { useState } from 'react'

function AppointmentsMake() {

    const [formList, setFormList] = useState([]);
    const [formCount, setFormCount] = useState(0);

    const createForm = () => {
      const key = {formCount}
      const newForm = (
       
<div className='servicebox tile'>
<div className='row mb-3'>
                <div class=" col-md-5">
                  <label class="form-label">Start Time</label>
                  <input class="form-control" type="time" placeholder="Enter your name"/>
                </div>
                <div class=" col-md-5">
                  <label class="form-label">End Time</label>
                  <input class="form-control" type="time" placeholder="Enter your name"/>
                </div>
                <div class=" col-md-1">
               <i class="fa-solid fa-circle-xmark" onClick={() => removeForm(formCount)}></i>
                </div>
                </div>
</div>
      

      );
      
      setFormList([...formList, newForm]);
      setFormCount(formCount + 1);

      
    };


    const removeForm = (formKey) => {
        const updatedFormList = formList.filter((form) => form.key !== formKey);
        setFormList(updatedFormList);
      };
    

  return (
    <>


      

       <div className="row">
        {formList.map((form, index) => (
          <div key={form.key} className='col-12 col-lg-12'>{form}  
              </div>
        ))}
      </div>



      <button type='button' onClick={createForm} className='btn btn-cu'>+ Add New</button>
      


     
    </>
  )
}

export default AppointmentsMake