import React from "react";
import { environmentVariables } from "../../../../config/env.config";
import { getServicesArray } from "../../../../utils/utility";
function SubService(props) {
  let rs = props.rs;

  console.log(rs.serviceObj, "rssubservice");
  return (
    <> 
      <article className="servicesName sec">
        <h3 class="sectitle" style={{marginBottom:"0"}}>Services </h3>
        <div className="mainservicebox">
          {rs?.serviceObj?.content && (
            <ul className="servesbox" style={{height:"260px", overflow:"scroll", marginLeft:"0", marginRight:"0", paddingLeft:"15px"}}>
            <h3 style={{color:"#fff",fontFamily:"circular", paddingBottom:"10px"}}>Male</h3>
              {getServicesArray(rs.serviceObj.content).map((val, index) => (
                val?.name && 
                (  (val?.description === "male" || val?.description === "all") && (
                  <li key={index} className="service-list">
                    {val.name}
                  </li>
                )
              )))}
            </ul>
          )}

{rs?.serviceObj?.content && (
            <ul className="servesbox" style={{height:"260px", overflow:"scroll", marginLeft:"0", marginRight:"0", paddingLeft:"15px"}}>
            <h3 style={{color:"#fff",fontFamily:"circular", paddingBottom:"10px"}}>Female</h3>
              {getServicesArray(rs.serviceObj.content).map((val, index) => (
                val?.name && 
                (  (val?.description === "female" || val?.description === "all") && (
                  <li key={index} className="service-list">
                    {val.name}
                  </li>
                )
              )))}
            </ul>
          )}
      
        </div>
      </article>
    </>
  );
}
export default SubService;
