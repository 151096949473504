

import React from 'react'
import { useState } from 'react'

function TestimoniyalMake() {

  const [formList, setFormList] = useState([]);
  const [formCount, setFormCount] = useState(0);

  const createForm = () => {
    const key = {formCount}
    const newForm = (
       
       
<div className='servicebox tile'>

<i class="fa-solid fa-circle-xmark" onClick={() => removeForm(formCount)}></i>
<div class="boxbanner">      
     <label class="form-label">Banner:</label>
     <div class="bannerbox"><img src="" class="mx-auto d-block img-fluid"/></div>
     <input class="form-control" type="file" placeholder="Title"/>
     </div>


                 <div class="mb-3">
                  <label class="form-label">Name:</label>
                  <input class="form-control" type="text" placeholder="Name"/>
                </div>
                <div class="mb-3">
                  <label class="form-label">Description:</label>
                  <textarea class="form-control" rows="4" placeholder="Description"></textarea>
                </div>
              
</div>
      

      );
      setFormList([...formList, newForm]);
      setFormCount(formCount + 1);

    };
  

    const removeForm = (formKey) => {
      const updatedFormList = formList.filter((form) => form.key !== formKey);
      setFormList(updatedFormList);
    };


  
  return (
    <>


      

       <div className="row">
        {formList.map((form, index) => (
          <div key={index} className='col-12 col-lg-6'>{form}</div>
        ))}
      </div>



      <button type='button' onClick={createForm} className='btn btn-cu'>+ Add new testimonials</button>
      


     
    </>
  )
}

export default TestimoniyalMake