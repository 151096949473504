import React, { useContext, useState, useEffect } from 'react'
import { Container, MenuAddMoreWrapper, MenuAddmoreIcon, MenuBestSaller,MenuNonvagefilterWrapper,MenuNonvageFilter, MenuCardWrapper, MenuDeatilsContainer, MenuImage, MenuImageWrapper, MenuName, MenuNameWrapper, MenuPrice, MenuPriceWrapper, MenuStar, MenuStarWrapper, MenuStarvalue, MenuVageFilter, MenuVagefilterWrapper, NewTabMenuHeadingWrapper, NewTabMenuText, NewTabMenuWrapper,SpancurrencySymbol } from './Theme14ExtStyleComponent'
import FirstDishImg from "../../../../Images/Restaurant/FirstMenuimg.png";
import StarIcon from "../../../../Images/Restaurant/Star.png";
import AddmoreIcon from "../../../../Images/Restaurant/addmoreicon.png";
import rytIcon from "../../../../Images/Restaurant/restaurantrightclick.jpg";
import BestSellerIcon from "../../../../Images/Restaurant/Bestsellertitle.png";
import { environmentVariables } from "../../../../config/env.config";
import axios from 'axios';
import { currencyContext } from "../../../../context/currencyContext.js";

import { userContext } from "../../../../context/userContext";
import styled from 'styled-components';
import { getServicesArray } from '../../../../utils/utility.js';

const MenuVagefilterWrapper1 = styled.div`
    width: 22px;
    height: 22px;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid #0C7C54;
    border-radius: 8px;
    margin-right: 5px;
`;

const MenuNonvagefilterWrapper1 = styled.div`
width: 22px;
height: 22px;
background-color: white;
display: flex;
justify-content: center;
align-items: center;
border: 2px solid #7C0C0C;
border-radius:8px;
margin-right:5px;
`;

const MenuVageFilter1 = styled.img`
    width: 10px;
    height: 10px;
    background-color: #0C7C54;
    border-radius: 100px;
`;

const MenuNonvageFilter1 = styled.img`
    width: 10px;
    height: 10px;
    background-color: #7C0C0C;
    border-radius: 100px;
`;

const TabMenu = (props) => {
    const [addedItems, setAddedItems] = useState(props.addedItems);
    const [menuItems, setMenuItems] = useState([]);
    const [filter, setFilter] = useState('all');
    const [averageRatings, setAverageRatings] = useState({});
    const { currencyObjData } = useContext(currencyContext);
    const { userData } = useContext(userContext);
    const [currencySymbol, setCurrencySymbol] = useState('₹');

    useEffect(() => {
        if (props.rs?.contactInfo?.content) {
            const contactInfo = JSON.parse(props.rs.contactInfo.content);
            let phoneNumber = contactInfo.contactInfoPhone;
            phoneNumber = phoneNumber.replace(/[^\d+]/g, '');
    
            let countryCode = phoneNumber.match(/^\+(\d{1,3})/)[1];
    
            if (countryCode.length < 3) {
                if (phoneNumber.startsWith("+971")) {
                    countryCode = "971";
                } else if (phoneNumber.startsWith("+91")) {
                    countryCode = "91";
                } else if (phoneNumber.startsWith("+1")) {
                    countryCode = "1";
                } else {
                    countryCode = "default"; 
                }
            }
    
            switch (countryCode) {
                case '91':
                    setCurrencySymbol('₹');
                    break;
                case '1':
                    setCurrencySymbol('$');
                    break;
                case '971':
                    setCurrencySymbol('AED');
                    break;
                default:
                    setCurrencySymbol('₹'); // Default to INR
                    break;
            }
        }
    }, [props.rs?.contactInfo]);
    
    
  const fetchMenuItemsAndReviews = async () => {
      try {
          const menuItemsFromAPI = (props.rs.menu.content);
          setMenuItems(menuItemsFromAPI);
  
          const response = await axios.get(`${environmentVariables.apiUrl}api/v1/business/fetchreviews?businessId=${props.rs.id}`);
          
          if (Array.isArray(response.data.averageRatings)) {
              const ratingsMap = {};
  
              response.data.averageRatings.forEach(item => {
                  ratingsMap[item.name.toLowerCase()] = item.avgrating;
              });
  
              setAverageRatings(ratingsMap);
          } else {
              console.error('Average ratings data is not an array:', response.data.averageRatings);
          }
      } catch (error) {
          console.error('Error fetching menu items or reviews:', error);
      }
  };
    useEffect(() => {
        fetchMenuItemsAndReviews();
    }, [props?.rs?.id, props?.rs?.menu?.content]);
    
    

    // const handleAddToOrder = (menuItem) => {
    //     const newItem = { name: menuItem.name, price: menuItem.price, image: menuItem.image,quantity:1 };
    //     setAddedItems([...addedItems, newItem]);
    //     props.handleAddToOrder(menuItem.name, menuItem.price, menuItem.image,1);
    // };

    const handleAddToOrder = (menuItem) => {
        const isAdded = isItemAdded(menuItem);
        if (isAdded) {
            setAddedItems(addedItems.filter(item => item.name !== menuItem.name));
            props.handleRemoveFromOrder(menuItem.name);
        } else {
            const newItem = { name: menuItem.name, price: menuItem.price, image: menuItem.image, quantity: 1 };
            setAddedItems([...addedItems, newItem]);
            props.handleAddToOrder(menuItem.name, menuItem.price, menuItem.image, 1);
        }
    };

    const isItemAdded = (menuItem) => {
        return addedItems.some((item) => item.name === menuItem.name);
    };
    const filterMenuItems = (type) => {
        
        let items = getServicesArray(menuItems)
        console.log("items====>>>", items);
        if (type === 'veg') {
            return items.filter((menuItem) => menuItem.category === 'veg');
        } else if (type === 'nonveg') {
            return items.filter((menuItem) => menuItem.category === 'nonveg' || menuItem.category== 'non-veg');
        } else {
            return items;
        }
    };
    const handleFilterClick = (type) => {
        setFilter(prevFilter => (prevFilter === type ? 'all' : type));
      };
    
    return (
        <NewTabMenuWrapper>
            <NewTabMenuHeadingWrapper>
                <NewTabMenuText>
                    <div
                        onClick={() => handleFilterClick('veg')}
                        style={{
                        backgroundColor: filter === 'veg' ? '#f4fff4' : 'white',
                        color: filter === 'veg' ? 'black' : 'black',
                        fontSize: '17px',
                        borderRadius: '50px',
                        fontWeight: '600',
                        padding: '5px 15px',
                        position:'relative',
                        border:'1px solid #a3a3a3',
                        display:'flex',
                        alignItems:'center',
                    }}
                    >
                <MenuVagefilterWrapper1>
                    <MenuVageFilter1 />
                </MenuVagefilterWrapper1>
                Veg
            </div>
            <div
                onClick={() => handleFilterClick('nonveg')}
                style={{
                backgroundColor: filter === 'nonveg' ? '#fcebeb' : 'white',
                color: filter === 'nonveg' ? 'black' : 'black',
                fontSize: '17px',
                borderRadius: '50px',
                fontWeight: '600',
                padding: '5px 15px',
                border:'1px solid #a3a3a3',
                display:'flex',
                alignItems:'center',
                }}
            >
                <MenuNonvagefilterWrapper1>
                    <MenuNonvageFilter1 />
                </MenuNonvagefilterWrapper1>
                Non-Veg
            </div>
                </NewTabMenuText>
            </NewTabMenuHeadingWrapper>
            <Container>
                <MenuDeatilsContainer>
                    {getServicesArray(filterMenuItems(filter)).map((menuItem, index) => (
                        <MenuCardWrapper key={index}>
                            <MenuImageWrapper>
                                {/* <MenuImage src={`${environmentVariables.apiUrl}uploads/${menuItem?.image}`} /> */}
                                <MenuImage
                                    src={
                                    menuItem?.image === ""
                                        ? `${environmentVariables.apiUrl}uploads/No image available.png`
                                        : menuItem?.image.includes('/')
                                        ? `https://businessbay.b-cdn.net${menuItem?.image}`
                                        : `${environmentVariables.apiUrl}uploads/${menuItem?.image}`
                                    }
                                />
                                {/* <MenuBestSaller src={BestSellerIcon}></MenuBestSaller> */}
                                {/* {index === 0 && 
                                <MenuBestSaller src={BestSellerIcon}></MenuBestSaller>} */}
                               {menuItem.category !== "veg" ? (
                                <MenuNonvagefilterWrapper>
                                <MenuNonvageFilter />
                                </MenuNonvagefilterWrapper>
                                ) : (
                                <MenuVagefilterWrapper>
                                    <MenuVageFilter />
                                </MenuVagefilterWrapper>
                                )}
                            </MenuImageWrapper>
                            <MenuNameWrapper>
                                {/* <MenuName>{menuItem?.name}</MenuName> */}
                                <MenuName> <div className='menu2line'> {menuItem?.name ? (menuItem.name.length >= 45 ? `${menuItem.name.slice(0, 42)}...` : menuItem.name) : ""}</div></MenuName>

                            </MenuNameWrapper>
                            {/* <MenuStarWrapper>
                                <MenuStar src={StarIcon} />
                                <MenuStarvalue>  { (averageRatings[menuItem?.name?.toLowerCase()] || 4).toFixed(2) }</MenuStarvalue>
                            </MenuStarWrapper> */}
                            <MenuPriceWrapper>
                                <MenuPrice><SpancurrencySymbol>{currencySymbol}</SpancurrencySymbol> {menuItem?.price}</MenuPrice>
                                <MenuAddMoreWrapper>
                                    {isItemAdded(menuItem) ? (
                                        // <span style={{fontSize:"20px", fontWeight: "bold", color:"green"}}>Added</span>
                                        <img 
                                            src={rytIcon} 
                                            style={{ width: "35px", cursor: "pointer" }} 
                                            alt="Added" 
                                            onClick={() => handleAddToOrder(menuItem)}
                                        />
                                    ) : (
                                        <MenuAddmoreIcon
                                            src={AddmoreIcon}
                                            onClick={() => handleAddToOrder(menuItem)}
                                        />
                                    )}
                                </MenuAddMoreWrapper>
                            </MenuPriceWrapper>
                        </MenuCardWrapper>
                    ))}
                </MenuDeatilsContainer>
            </Container>
        </NewTabMenuWrapper>
    )
}
export default TabMenu
