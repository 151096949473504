import React, { useEffect, useState } from 'react'
import ReactApexChart from 'react-apexcharts';

function Charttc(data_p1) {
  const [appData, setAppData] = useState([]);
  const [dateRange, setDateRange] = useState([]);
  const [contData, setContData] = useState([]);
  const [maxmin, setMaxmin] = useState({});

  useEffect(() => {
    if (data_p1?.data?.dateRange) {
      setDateRange(data_p1?.data?.dateRange);
    }
    if (data_p1?.data?.resultA?.sum_of_appointments) {
      setAppData(data_p1?.data?.resultA?.sum_of_appointments);
    }
    if (data_p1?.data?.resultC?.sum_of_contacts) {
      setContData(data_p1?.data?.resultC?.sum_of_contacts);
    }
  }, [data_p1]);

  const months = [
    'JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN',
    'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'
  ];

  const currentDate = new Date();
  const [chartData, setChartData] = useState({
    series: [{
      name: 'Appointments',
      data: appData//[31, 40, 28, 51, 42, 109, 100] //appData//data_p1?.data?.resultA?.sum_of_appointments
    },
    //  {
    //   name: 'Contacts',
    //   data: contData//[31, 40, 28, 51, 42, 109, 100] //contData//data_p1?.data?.resultC?.sum_of_contacts
    // }
  ],
    options: {
      chart: {
        height: 350,
        type: 'area'
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: 'smooth'
      },
      xaxis: {
        type: 'date',
        categories: dateRange,
        title: {
          text: 'Month'
        }
      },
      yaxis: {
        show: true, 
        min: data_p1?.data?.maxmin?.min||0, 
        max: data_p1?.data?.maxmin?.max||5, 
        tickAmount: 5,  
      },
      tooltip: {
        x: {
          format: 'dd/MM/yy HH:mm'
        },
      },
    },
  });

   useEffect(()=>{
    setChartData({
      series: [{
        name: 'Appointments',
        data: appData//,[31, 40, 28, 51, 42, 109, 100] //appData//data_p1?.data?.resultA?.sum_of_appointments
      },
      //  {
      //   name: 'Contacts',
      //   data: contData//[31, 40, 28, 51, 42, 109, 100] //contData//data_p1?.data?.resultC?.sum_of_contacts
      // }
    ],
      options: {
        chart: {
          height: 350,
          type: 'area'
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'smooth'
        },
        xaxis: {
          type: 'date',
          categories: dateRange,
          title: {
            text: ''
          }
        },
        yaxis: {
          show: true,  
          min: data_p1.data.maxmin.min, 
          max: data_p1.data.maxmin.max,  
          tickAmount: 5, 
        },
        tooltip: {
          x: {
            format: 'dd/MM/yy HH:mm'
          },
        },
      },
    })
   },[appData,
    contData])
  return (
    <>
    <div id="chartww">
      <ReactApexChart options={chartData.options} series={chartData.series} type="area" height={350} />
    </div>
    </>
  )
}
//312 20:57
export default Charttc