import React, {useRef, useContext, useEffect, useState } from "react";
import { userContext } from "../../../context/userContext";
import { environmentVariables } from "../../../config/env.config";
import { useNavigate } from "react-router";
import ResumeIcon from "./../../../Images/ResumeLogo.png";
import phoneIcon from "./../../../Images/PhoneCallingRounded.png";
import MessageIcon from "./../../../Images/Letter.png";
import MapIcon from "./../../../Images/MapPoint.png";
import LinkedInIcon from "./../../../Images/linkedInIcon.png";
import ShareVector from "./../../../Images/Share.png";
import "./Resume1.css";
import {
  Card,
  CardHeading,
  CardHeadingWrapper,
  CertificationContainer,
  Certificationdate,
  Certificationname,
  CompanyName,
  CompanyWrapper,
  ContactContainer,
  ContactIcon,
  ContactInfoApp,
  ContactInfoAppContainer,
  ContactLine,
  ContactWrapper,
  ContactWrapper2,
  Container,
  Contant,
  ContantWrapper,
  Designation,
  DesignationWrapper,
  EducationCollegeWrapper,
  EducationDate,
  EducationHeadingWrapper,
  EducationPlace,
  EducationWrapper,
  EducationWrapper2,
  EmailLinkedInWrapper,
  HeaderWrapper,
  Icon,
  Name,
  NameWrapper,
  PersonalContactWrapper,
  PersonalDetailName,
  Phone,
  PhoneAddressWrapper,
  PhoneEmailLinkedAddressWrapper,
  PhoneNumberWrapper,
  ProfileContainer,
  ProfileLogo,
  ProfileWrapper,
  ProjectCompanyWrapper,
  ProjectDate,
  ProjectHeadingWrapper,
  ProjectLink,
  ProjectLinkWrapper,
  ProjectName,
  ProjectNameSpan,
  ProjectPoints,
  ProjectPointsWrapper,
  ProjectSummeryWrapper,
  ProjectSummeryWrapper2,
  ProjectWrapper,
  ProjectWrapperNameWrapper,
  ProjectWrapperSummary,
  Root,
  RootContainerWrapperr,
  Triangle,
  PersonalName,
  PersonalDetailNameValue,
  PersonaldetailWrapper,
  PoweredWrapper,
  Power,
  PowerSpan,
  ShareButtonWrapper,
  ShareIcon,
  IconContainer,
  SingleIcon,
  SingleIconWrapper,
} from "./StyleThemejs.js";
import {
  FacebookIcon,
  LinkedinIcon,
  WhatsappIcon,
  TelegramIcon,
  EmailIcon,
  EmailShareButton,
} from "react-share";

const Resume1Ext = (props) => {
  const { userData } = useContext(userContext);
  const [educationData, setEducationData] = useState([]);
  const [projectData, setProjectData] = useState([]);
  const [certificateData, setCertificateData] = useState([]);
  const [skillData, setSkillData] = useState([]);
  const [additionalData, setAdditionalData] = useState([]);
  const [achievementData, setAchievementData] = useState([]);
  const [workExpData, setworkExpData] = useState([]);
  const [toolData, settoolData] = useState([]);
  const [language, setLanguage] = useState([]);
  const [showShareOptions, setShowShareOptions]= useState(false);
  const [showEmailShare, setShowEmailShare] = useState(false);
  const baseUrl = environmentVariables?.apiUrl;

  const iconContainerRef = useRef(null);

  const navigate = useNavigate();
  useEffect(() => {
    if (props?.resumeData?.educationcontent) {
      setEducationData(JSON.parse(props.resumeData.educationcontent));
    }
    if (props?.resumeData?.projectcontent) {
      setProjectData(JSON.parse(props.resumeData.projectcontent));
    }
    if (props?.resumeData?.certificationcontent) {
      setCertificateData(JSON.parse(props.resumeData.certificationcontent));
    }
    if (props?.resumeData?.skillscontent) {
      setSkillData(JSON.parse(props.resumeData.skillscontent));
    }
    if (props?.resumeData?.additionaldetailscontent) {
      setAdditionalData(JSON.parse(props.resumeData.additionaldetailscontent));
    }
    if (props?.resumeData?.toolscontent) {
      settoolData(JSON.parse(props.resumeData.toolscontent));
    }
    if (props?.resumeData?.languagecontent) {
      setLanguage(JSON.parse(props.resumeData.languagecontent));
    }
    if (props?.resumeData?.wrkExpcontent) {
      setworkExpData(JSON.parse(props.resumeData.wrkExpcontent));
    }
    if (props?.resumeData?.achievementcontent) {
      setAchievementData(JSON.parse(props.resumeData.achievementcontent));
    }
  }, [props.resumeData]);

  const handleClickOutside = (event) => {
    if (iconContainerRef.current && !iconContainerRef.current.contains(event.target)) {
      setShowShareOptions(false);
    }
  };

  useEffect(() => {
      document.addEventListener("click", handleClickOutside, false);
      return () => {
        document.removeEventListener("click", handleClickOutside, false);
      };
  }, []);

  const getImage = (image) => {
    if (image && image.includes("base64")) {
      return image;
    } else if (image) {
      return `${environmentVariables?.apiUrl}uploads/resume/${image}`;
    } else {
      // Handle case when image is null or undefined
      return ""; // or return a default image URL if needed
    }
  };


  const formatDate = (dateString) => {
    if (!dateString) return ""; // Return empty string if dateString is falsy

    const date = new Date(dateString); // Parse the date string
    const options = { month: "long", year: "numeric" }; // Format options
    return date.toLocaleDateString("en-US", options); // Format date to "Month-YYYY" format
  };


  const handleShare = () => {
    setShowShareOptions(!showShareOptions);
  };


  // const handleShare = () => {
  //   setShowShareOptions(prevState => !prevState); 
  // };

  

  const handleShareOnPlatform = (platform) => {
    const resumePreviewLink = window.location.href;
    const message = "Check out my resume!";
    const fullMessage = `${message} ${resumePreviewLink}`;
    let shareUrl = '';

    switch (platform) {
      case 'linkedin':
        // Since LinkedIn does not support direct messaging via URL, copy the message to clipboard and show a prompt
        // shareUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(resumePreviewLink)}`;
        // break;
        navigator.clipboard.writeText(fullMessage).then(() => {
          alert("Message copied to clipboard! Please paste it in LinkedIn messages.");
          window.open("https://www.linkedin.com/messaging", "_blank"); // Opens LinkedIn Messaging
        }).catch(err => {
          console.error('Could not copy text: ', err);
        });
        break;
      case 'whatsapp':
        shareUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(resumePreviewLink)}`;
        break;
      case 'telegram':
        shareUrl = `https://t.me/share/url?url=${encodeURIComponent(resumePreviewLink)}`;
        break;
      case 'email':
        shareUrl = `mailto:?subject=Checkoutmyresume&body=${encodeURIComponent(resumePreviewLink)}`;
        // setShowEmailShare(true);
        break;
      // case 'facebook':
      
      //   break;
      default:
        break;
    }

    if (shareUrl) {
      window.open(shareUrl, "_blank"); 
    }
  };

  const renderSkills = () => {
    if (skillData?.length) {
      return (
        <Card>
          <CardHeadingWrapper>
            <CardHeading>Key Skills</CardHeading>
            <Triangle />
          </CardHeadingWrapper>
          <EducationHeadingWrapper>
            {skillData.map((skill, index) => (
              <EducationCollegeWrapper key={index}>
                <EducationWrapper>
                  <EducationPlace>{skill?.skillname}</EducationPlace>
                </EducationWrapper>
              </EducationCollegeWrapper>
            ))}
          </EducationHeadingWrapper>
        </Card>
      );
    } else {
      return null;
    }
  };

  
  const renderPrsnldetails = () => {
    return props?.resumeData?.is_prsnldetail_is_enabled === 1 ? (
      <Card>
        <CardHeadingWrapper>
          <CardHeading>Personal Information</CardHeading>
          <Triangle />
        </CardHeadingWrapper>

        <PersonalContactWrapper>
          <PersonaldetailWrapper>
           {props?.resumeData?.fathername &&  <PersonalName>Father/ Spouse Name</PersonalName> }
           {props?.resumeData?.maritalstatus && <PersonalName>Marital Status </PersonalName> }
           {props?.resumeData?.userdob && <PersonalName> DOB </PersonalName> }
          </PersonaldetailWrapper>
          <PersonaldetailWrapper PersonalValue>
          {props?.resumeData?.fathername &&  <PersonalName>{props?.resumeData?.fathername}</PersonalName> }
          {props?.resumeData?.maritalstatus &&   <PersonalName>{props?.resumeData?.maritalstatus}</PersonalName> }
          {props?.resumeData?.userdob &&   <PersonalName>{props?.resumeData?.userdob}</PersonalName> }
          </PersonaldetailWrapper>
        </PersonalContactWrapper>
      </Card>
    ) : null;
  };


  const renderAdditionalDetail = () => {
    if (additionalData?.length) {
      return (
        <Card>
          <CardHeadingWrapper>
            <CardHeading>ADDITIONAL DETAILS</CardHeading>
            <Triangle />
          </CardHeadingWrapper>
          <EducationHeadingWrapper>
            {additionalData.map((details, index) => (
              <EducationCollegeWrapper key={index}>
                <EducationWrapper>
                  <EducationPlace>{details?.skillname}</EducationPlace>
                </EducationWrapper>
              </EducationCollegeWrapper>
            ))}
          </EducationHeadingWrapper>
        </Card>
      );
    } else {
      return null;
    }
  };

  const renderAchievement = () => {
    if (achievementData?.length) {
      return (
        <Card>
          <CardHeadingWrapper>
            <CardHeading>Achievements</CardHeading>
            <Triangle />
          </CardHeadingWrapper>
          <EducationHeadingWrapper>
            {achievementData.map((details, index) => (
              <EducationCollegeWrapper key={index}>
                <EducationWrapper>
                  <EducationPlace>{details?.skillname}</EducationPlace>
                </EducationWrapper>
              </EducationCollegeWrapper>
            ))}
          </EducationHeadingWrapper>
        </Card>
      );
    } else {
      return null;
    }
  };

  const renderProjects = () => {
    if (projectData?.length) {
      return (
        <Card>
          <CardHeadingWrapper>
            <CardHeading>Project</CardHeading>
            <Triangle />
          </CardHeadingWrapper>
          {projectData.map((project, index) => (
            <ProjectHeadingWrapper key={index}>
              <ProjectCompanyWrapper>
                <ProjectWrapperNameWrapper>
                  <ProjectName ProjectNamee>{project.projectname}</ProjectName>
                </ProjectWrapperNameWrapper>
                <ProjectSummeryWrapper>
                  <ProjectNameSpan>
                  {/* {formatDate(project?.startDate)} - {project.currentlyWorking ? "Current" : `formatDate(project?.endDate)`} */}
                  {/* {`${formatDate(project?.startDate)} - ${project.currentlyWorking ? "Current" : formatDate(project?.endDate)}`} */}
                  ( {`${formatDate(project?.startDate)?.substr(0, 3)} ${formatDate(project?.startDate)?.substr(-4)} - ${project.currentlyWorking ? "Current" : formatDate(project?.endDate)?.substr(0, 3)} ${formatDate(project?.endDate)?.substr(-4)}`})
                  </ProjectNameSpan>
                </ProjectSummeryWrapper>
                <ProjectSummeryWrapper2>
                  <ProjectWrapperSummary>
                    {project?.projectdescription}
                  </ProjectWrapperSummary>
                </ProjectSummeryWrapper2>
                <ProjectLinkWrapper>
                  <ProjectLink href={project?.projectlink} target="_blank">
                    {project?.projectlink}
                  </ProjectLink>
                </ProjectLinkWrapper>
              </ProjectCompanyWrapper>
            </ProjectHeadingWrapper>
          ))}
        </Card>
      );
    } else {
      return null;
    }
  };

  const renderCertification = () => {
    if (certificateData?.length) {
      return (
        <Card>
          <CardHeadingWrapper>
            <CardHeading>certification</CardHeading>
            <Triangle />
          </CardHeadingWrapper>
          {certificateData.map((certificate, index) => (
            <CertificationContainer key={index}>
              <Certificationname>
                {certificate?.certificatename}
              </Certificationname>
              <Certificationname>
                {certificate?.organisationname}
              </Certificationname>
              <Certificationname>
                {certificate?.certificateidnumber}
              </Certificationname>
              <ProjectNameSpan Certification>
                {/* {formatDate(certificate?.date)}-{" "}
                {formatDate(certificate?.enddate)}{" "} */}

                ( {`${formatDate(certificate?.date)?.substr(0, 3)} ${formatDate(certificate?.date)?.substr(-4)} - ${certificate.currentlyWorking ? "Current" : formatDate(certificate?.enddate)?.substr(0, 3)} ${formatDate(certificate?.enddate)?.substr(-4)}`})
              </ProjectNameSpan>
            </CertificationContainer>
          ))}
        </Card>
      );
    } else {
      return null;
    }
  };

  return (
    <>
      <Root>
        <RootContainerWrapperr>
          <HeaderWrapper>
          
            <ShareButtonWrapper onClick={handleShare} ref={iconContainerRef}>
              <ShareIcon src={ShareVector} />
            </ShareButtonWrapper>
            {showShareOptions && (
              <IconContainer className="share-options-modal">
                <SingleIconWrapper className="share-options-list">
                <SingleIcon onClick={() => handleShareOnPlatform('telegram')}>
                    <TelegramIcon size={35} round />
                  </SingleIcon>
                <SingleIcon onClick={() => handleShareOnPlatform('email')}>
                    <EmailIcon size={35} round />
                  </SingleIcon>
                  <SingleIcon onClick={() => handleShareOnPlatform('linkedin')}>
                    <LinkedinIcon size={35} round />
                  </SingleIcon>
                  <SingleIcon onClick={() => handleShareOnPlatform('whatsapp')}>
                    <WhatsappIcon size={35} round />
                  </SingleIcon>
                </SingleIconWrapper>
              </IconContainer>
            )} 
     
            <ProfileContainer>
              <ProfileWrapper>
                {/* <ProfileLogo src={props?.resumeData?.logo ? getImage(props.resumeData.logo) : ResumeIcon} className="logoFile" /> */}
                <ProfileLogo
                  src={getImage(props.resumeData.logo)}
                  className="logoFile"
                />
               
              </ProfileWrapper>
              <NameWrapper>
                <Name>{props?.resumeData?.fullName }</Name>
              </NameWrapper>
              <DesignationWrapper>
                <Designation>
                  {props?.resumeData?.designation}
                </Designation>
              </DesignationWrapper>
            </ProfileContainer>
          </HeaderWrapper>

          <Container>
            <ContactContainer>
              <ContactInfoAppContainer>
                <ContactInfoApp>CONTACT INFO</ContactInfoApp>
                <ContactLine />
              </ContactInfoAppContainer>

              <PhoneEmailLinkedAddressWrapper>
                <PhoneAddressWrapper>
                  
                  <ContactWrapper AddressPhoneWrapper>
                    <ContactIcon>
                      <Icon src={phoneIcon} />
                    </ContactIcon>
                    <PhoneNumberWrapper>
                      <Phone>{props?.resumeData?.phone}</Phone>
                    </PhoneNumberWrapper>
                  </ContactWrapper>

                
                  <ContactWrapper MailTemplate AddressPhoneWrapper>
                    <ContactIcon>
                      <Icon src={MessageIcon} />
                    </ContactIcon>
                    <PhoneNumberWrapper>
                      <Phone>{props?.resumeData?.email}</Phone>
                    </PhoneNumberWrapper>
                  </ContactWrapper>

                </PhoneAddressWrapper>

                <EmailLinkedInWrapper>
                {props?.resumeData?.city && (
                  <ContactWrapper AddressPhoneWrapper>
                    <ContactIcon>
                      <Icon src={MapIcon} />
                    </ContactIcon>
                    <PhoneNumberWrapper>
                      <Phone>{props?.resumeData?.city}</Phone>
                    </PhoneNumberWrapper>
                  </ContactWrapper>
                )}

                {props?.resumeData?.linkedIn && (
                  <ContactWrapper AddressPhoneWrapper>
                    <ContactIcon>
                      <Icon
                        src={LinkedInIcon}
                        style={{ width: "30px", marginTop: "-5px" }}
                      />
                    </ContactIcon>
                    <PhoneNumberWrapper>
                      <a
                        href={props?.resumeData?.linkedIn}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Phone LinkedInLinkLength>{props?.resumeData?.linkedIn}</Phone>
                      </a>
                      {/* <Phone>{props?.resumeData?.linkedIn}</Phone> */}
                    </PhoneNumberWrapper>
                  </ContactWrapper>
                )}
                </EmailLinkedInWrapper>
              </PhoneEmailLinkedAddressWrapper>
            </ContactContainer>
              {props?.resumeData?.summary && (
              <Card>
              <CardHeadingWrapper>
                <CardHeading>SUMMARY</CardHeading>
                <Triangle />
              </CardHeadingWrapper>
              <ContantWrapper>
                <Contant>{props?.resumeData?.summary}</Contant>
              </ContantWrapper>
              </Card>
              )}
           

            {props?.resumeData?.is_wrkExp_is_enabled === 1 && (
              <Card>
                <CardHeadingWrapper>
                  <CardHeading>Work experience</CardHeading>
                  <Triangle />
                </CardHeadingWrapper>
                <ProjectHeadingWrapper>
                  <ProjectCompanyWrapper>
                    {workExpData?.length ? (
                      workExpData.map((exp, index) => {
                        return (
                          <ProjectWrapper
                            key={index}
                            style={{ display: "block" }}
                          >
                            <ProjectName style={{ textAlign: "left" }}>
                              {exp?.jobtitle}{" "}
                              <ProjectNameSpan>
                                {/* {`${formatDate(exp?.startdate)} - ${exp.currentlyWorking ? "Current" : formatDate(exp?.enddate)}`} */}
                               {/* ({`${formatDate(exp?.startdate)?.substr(0, 3)} ${formatDate(exp?.startdate)?.substr(-4)} - ${exp.currentlyWorking ? "Currently Working" : formatDate(exp?.enddate)?.substr(0, 3)} ${formatDate(exp?.enddate)?.substr(-4)}`}) */}
                               ({`${formatDate(exp?.startdate)?.substr(0, 3)} ${formatDate(exp?.startdate)?.substr(-4)} - ${exp.currentlyWorking ? "Currently Working" : `${formatDate(exp?.enddate)?.substr(0, 3)} ${formatDate(exp?.enddate)?.substr(-4)}`}`})

                              </ProjectNameSpan>
                            </ProjectName>
                            <ProjectDate style={{ textAlign: "left" }}>
                              {exp?.companyname}{" "}
                            </ProjectDate>
                            <ProjectWrapperSummary
                              style={{ textAlign: "left" }}
                            >
                              {exp?.workSummary}
                            </ProjectWrapperSummary>
                          </ProjectWrapper>
                        );
                      })
                    ) : (
                      <></>
                    )}
                  </ProjectCompanyWrapper>
                </ProjectHeadingWrapper>
              </Card>
            )}

            {props?.resumeData?.is_project_is_enabled === 1 && renderProjects()}

            {props?.resumeData?.is_certification_is_enabled === 1 &&
              renderCertification()}

            {props?.resumeData?.is_education_is_enabled === 1 && (
              <Card>
                <CardHeadingWrapper>
                  <CardHeading>education</CardHeading>
                  <Triangle />
                </CardHeadingWrapper>
                <EducationHeadingWrapper>
                  {educationData?.length ? (
                    educationData.map((item) => {
                      return (
                        <EducationCollegeWrapper
                          style={{ paddingBottom: "15px" }}
                        >
                          <EducationWrapper>
                            <EducationPlace>
                              {item?.degree} ({item?.boardname})
                              <EducationPlace>
                                {item?.schuniname}
                              </EducationPlace>
                              <EducationPlace> {item?.location}</EducationPlace>
                            </EducationPlace>
                          </EducationWrapper>
                          <CompanyWrapper>
                            {/* <EducationDate>
                               {formatDate(item?.graduationyear)} 
                            </EducationDate> */}
                            <EducationDate>
                              {" "}
                              {/* - {formatDate(item?.graduationendyear)} */}
                              ( {`${formatDate(item?.graduationyear)?.substr(0, 3)} ${formatDate(item?.graduationyear)?.substr(-4)} - ${item.currentlyWorking ? "Current" : formatDate(item?.graduationendyear)?.substr(0, 3)} ${formatDate(item?.graduationendyear)?.substr(-4)}`})
                            </EducationDate>
                          </CompanyWrapper>
                        </EducationCollegeWrapper>
                      );
                    })
                  ) : (
                    <></>
                  )}
                </EducationHeadingWrapper>
              </Card>
            )}
            {props?.resumeData?.is_skills_is_enabled === 1 && renderSkills()}

            {props?.resumeData?.is_tools_is_enabled === 1 && (
              <Card>
                <CardHeadingWrapper>
                  <CardHeading>Tools</CardHeading>
                  <Triangle />
                </CardHeadingWrapper>
                <EducationHeadingWrapper>
                  {toolData?.map((tool, index) => (
                      <EducationCollegeWrapper key={index}>
                        <EducationWrapper>
                          {console.log(tool.toolname,"toollength")}
                          <EducationPlace>{tool?.toolname}</EducationPlace>
                        </EducationWrapper>
                      </EducationCollegeWrapper>
                    ))}
                </EducationHeadingWrapper>
              </Card>
            )}
            {props?.resumeData?.is_language_is_enabled === 1 && (
              <Card>
                <CardHeadingWrapper>
                  <CardHeading>Languages</CardHeading>
                  <Triangle />
                </CardHeadingWrapper>
                <EducationHeadingWrapper>
                  {language?.map((language, index) => (
                      <EducationCollegeWrapper key={index}>
                        <EducationWrapper>
                          <EducationPlace>
                            {language?.languagename}
                          </EducationPlace>
                        </EducationWrapper>
                      </EducationCollegeWrapper>
                    ))}
                </EducationHeadingWrapper>
              </Card>
            )}
            {props?.resumeData?.isEnabledAdddetails === 1 &&
              renderAdditionalDetail()}
            {props?.resumeData?.is_achievement_is_enabled === 1 &&
              renderAchievement()}

            {props?.resumeData?.is_prsnldetail_is_enabled === 1 &&
              renderPrsnldetails()}
              <PoweredWrapper>
                <Power>Powered by <PowerSpan>BusinessBay</PowerSpan></Power>
              </PoweredWrapper>
          </Container>
        </RootContainerWrapperr>
      </Root>
    </>
  );
};

export default Resume1Ext;
