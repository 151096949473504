import React, { useContext, useState, useRef } from "react";
// import "./TemplatComman.css";
import { userContext } from "../../../../context/userContext";
import { environmentVariables } from "../../../../config/env.config";
import { useEffect } from "react";
import bannerImage from "../../../../Images/bannerimage.jpg";
import logoImage from "../../../../Images/logo1.jpg";
// import "./Template11.css";
import axios from "axios";
import theme6strip from "../../../../Images/theme6strip.png";
import CalanderIconn from "../../../../Images/DarkcalendarIcondays.png";
import TestimonialCarousel from "./TestimonialCarousel";
import GalleryCarousel from "./GalleryCarousel";
import GoogleImage from "../../../../Images/GoogleIcon.png";
import { getServicesArray } from "../../../../utils/utility";
// import "./Theme12.css";
// import "./Template10.css";
const Theme12 = (props) => {
  console.log(props, " theme12",);
  const { userData } = useContext(userContext);
  const [selectedDate, setSelectedDate] = useState();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [appointmentData, setAppointmentData] = useState([]);
  const touchStartX = useRef(0);
  const calendarInputRef = useRef(null);
  const handleChangeAppointmentDate = (dateVal) => {
    setSelectedDate(dateVal);
    // console.log(
    //   JSON.parse(props?.businessObj?.appointmentObj?.content),
    //   "appointmentinsidetheme1date"
    // );
    const dateObject = new Date(dateVal);

    const dayIndex = dateObject.getDay();

    const daysOfWeek = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    if (props?.appointmentArray && props?.appointmentArray?.length != 0) {
      setAppointmentData(
        props?.appointmentArray[`${daysOfWeek[dayIndex].toLowerCase()}`]
      );
    } else {
      try {
        const parsedContent = JSON.parse(
          JSON.parse(props?.businessObj?.appointmentObj?.content)
        );
        setAppointmentData(
          parsedContent[`${daysOfWeek[dayIndex].toLowerCase()}`]
        );
      } catch (err) {
        console.log(err);
      }
    }
  };
  const handleTouchStart = (e) => {
    touchStartX.current = e.touches[0].clientX;
  };

  const handleTouchMove = (e) => {
    const touchEndX = e.touches[0].clientX;
    const deltaX = touchEndX - touchStartX.current;

    if (Math.abs(deltaX) > 50) {
      // You can adjust this threshold to control how much swipe is needed to trigger the slide
      setCurrentIndex((prev) =>
        deltaX > 0
          ? Math.max(prev - 1, 0)
          : Math.min(prev + 1, testimonials.length - 1)
      );
    }
  };

  const openCalendar = () => {

    if (calendarInputRef.current) {
      calendarInputRef.current.showPicker();

    }
  };

  const handleTouchEnd = () => {
    touchStartX.current = 0;
  };

  useEffect(() => {
    getBlockOrder();
  }, [props?.businessObj, props.businessId, props.orderOfBlocks]);
  const getBrandNameForSocial = (brand) => {
    // console.log(brand);
    if (brand == "gmail") {
      return "google";
    } else return `${brand}`;
  };
  const getBlockOrder = async () => {
    // const handleGetBlock = async () => {
    if (props.orderOfBlocks) {

      let orders = {};
      props.orderOfBlocks.map((val) => {
        if (val.content == "Description") {
          orders["description"] = val?.position;
        } else if (val.content == "Contact Info") {
          orders["contact_info"] = val?.position;
        } else if (val.content == "Appointment") {
          orders["appointment"] = val?.position;
        } else if (val.content == "Service") {
          orders["service"] = val?.position;
        } else if (val.content == "Gallery") {
          orders["gallery"] = val.position;
        } else if (val.content == "Contact Form") {
          orders["contact_form"] = val.position;
        } else if (val.content == "Testimonials") {
          orders["testimonials"] = val.position;
        } else if (val.content == "Social") {
          orders["social"] = val.position;
        } else if (val.content == "Business Hour") {
          orders["business_hour"] = val.position;
        } else if (val.content == "Google Review") {
          orders["google_review"] = val.position;
        }
      });
      props.setOrder(orders);
    } else {
      try {
        let id = props?.businessObj?.id || props.businessId;
        if (props?.businessObj?.id) {
          let { data: get } = await axios.get(
            `${environmentVariables?.apiUrl}api/v1/business/FetchBlock?business_id=${id}`,
            {
              headers: {
                _token: userData?.token,
              },
              // withCredentials: true,
            }
          );
          // console.log(get?.data?.order, "order data");
          props.setOrder(get?.data?.order);
        }

        // console.log(get.data.order,"Getetgwef")
      } catch (err) {
        console.log(err, "error in template");
      }
    }
    // };
  };

  
  const getJSXElement = (elemName) => {
  if (elemName == "contact_info") {
      return (
        props?.onState?.contactInfo && (
          <article class="contact sec">
            <h3 class="sectitle">Contact Info</h3>
            <ul class="conlink">
              <li>
                <a>
                  <i class="fa-solid fa-phone"></i>
                  <span>{props?.contactInfo?.contactInfoPhone}</span>
                </a>
              </li>

              <li>
                <a>
                  <i class="fa-solid fa-envelope"></i>
                  <span>{props?.contactInfo?.contactInfoEmail}</span>
                </a>
              </li>
              {/* <li>
                <a href="">
                <i class="fa-solid fa-envelope"></i>
                  <span>
                   
                    {props?.contactInfo?.contactInfoWebUrl ||
                      `www.Shreyassinghal.com`}
                  </span>
                </a>
              </li> */}
              <li>
                <a>
                  <i class="fa-solid fa-location-dot"></i>
                  <span>{props?.contactInfo?.contactInfoAddress}</span>
                </a>
              </li>
            </ul>
          </article>
        )
      );
    } 
    //  else if (elemName == "google_review") {
    //   return (
    //      props?.onState?.googlereview == true &&  (
    //       <article class="contact sec">
    //         <h3 class="sectitle">Google review</h3>
    //         <ul class="conlink">
    //           <li>
    //              <p style={{wordBreak:"break-all", maxHeight:"77px",overflow:"hidden", fontSize:"16px"}}>
    //               {props?.googlereview !== undefined && props?.googlereview}
    //               </p>
    //           </li>
    //         </ul>
    //       </article>
    //     )
    //   );
    // }
    
    else if (elemName == "custom_html") {
      return <></>;
    } 
    else if (elemName == "description") {
      return (
        <article className="abouttemplate sec">
          <h3 class="sectitle">About</h3>
          <p class="sectitleDescription">
            {props.description ||
              `This organization excels in customer service, providing high-quality services/products. Their attention to detail, professionalism, and commitment to client satisfaction make them a standout choice in their industry. Highly recommended for anyone seeking excellence.`}
          </p>
        </article>
      );
    } else if (elemName == "gallery") {
      return (
        props?.onState?.gallery && (
          <article class="gallery sec sec-common">
            <h3 class="sectitle">Portfolio </h3>
            {props?.galleryData && (
              <>
                <GalleryCarousel slides={props?.galleryData} />
              </>
            )}
            {/* {props?.gallaryObject?.gallaryArray && (
              <GalleryCarousel slides={props?.gallaryObject?.gallaryArray} />
            )}
            {props.galleryView && (
              <GalleryCarousel slides={props?.galleryView} staticImage={true} />
            )} */}
            {/* {props?.gallaryObject?.gallary_video && (
              <video className="mx-auto d-block img-fluid" controls>
                <source
                  src={
                    props?.gallaryObject?.gallary_video &&
                    `${environmentVariables?.apiUrl}uploads/${props?.gallaryObject?.gallary_video}`
                  }
                  type="video/mp4"
                />
                Your browser does not support the video tag.
              </video>
            )} */}
          </article>
        )
      );
    } else if (elemName == "more") {
      return <></>;
    } 
   else if (elemName == "social") {
      return (
        props?.onState?.socials && (
          <article className="social-links-sec sec">
            <h3 class="sectitle">Social Links</h3>
            <ul class="socil-link">
              {props?.socialMediaData?.map((val) => (
                <li>
                  <a>
                    {/* <i
                      class={`fa-brands fa-${getBrandNameForSocial(
                        val?.nameOfSocial.toLowerCase()
                      )}`}
                    ></i> */}
                    <i
                      className={`fa-${val?.nameOfSocial.toLowerCase() === 'star' ? 'solid' : 'brands'} fa-${getBrandNameForSocial(
                        val?.nameOfSocial.toLowerCase()
                      )}`}
                    ></i>
                  </a>
                </li>
              ))}
            </ul>
          </article>
        )
      );
    } else if (elemName == "testimonials") {
      return (
        props?.onState?.testimonials && (
          <article class="testimoniyalbox sec">
            <h3 class="sectitle testimonial">Testimonials </h3>

            <div>
              <TestimonialCarousel
                testimonials={props?.testimonialArray}
                currentElem={currentIndex}
                handleTouchStart={handleTouchStart}
                handleTouchMove={handleTouchMove}
                handleTouchEnd={handleTouchEnd}
              />
              <div className="carousel-container">
                <button
                  onClick={() =>
                    currentIndex == 0
                      ? setCurrentIndex(props.testimonialArray.length - 1)
                      : setCurrentIndex((prev) => prev - 1)
                  }
                  className="carousel-btn"
                >
                  <i class="fa fa-angle-left"></i>
                </button>
                <button
                  onClick={() => {
                    currentIndex == props.testimonialArray.length - 1
                      ? setCurrentIndex(0)
                      : setCurrentIndex((prev) => prev + 1);
                  }}
                  className="carousel-btn"
                >
                  <i class="fa fa-angle-right"></i>
                </button>
              </div>
            </div>
          </article>
        )
      );
    }
  };

  const getBlocksOrder = () => {
    let BlocksArray = [];
    for (const key in props.order) {
      BlocksArray[props.order[key]] = getJSXElement(key);
    }
    return BlocksArray;
  };

  return (
    <div>
      <div class="main-temaplt" id="temp12">
        <div className="container">
          <div className="row justify-content-center">
            <div style={{ width: "420px" }} className="col-12 wtemplate ">
              <article className="imgsechere sec" style={{margin:"0"}}>
                {/* <img
                  src={
                    props?.bannerImageUrl
                      ? props.getImage(props.bannerImageUrl)
                      : bannerImage
                  }
                  className="mx-auto d-block img-fluid coverimghere "
                /> */}
                <img
                  src={
                    props?.logoImageUrl
                      ? props.getImage(props?.logoImageUrl)
                      : logoImage
                  }
                  className="mx-auto d-block img-fluid logo-profile"
                />
              </article>

              <article className="personalinfo sec">
                <h3 className="username">{props.subTitle || "Name"}</h3>
                <p className="titleuser">
                  {props.designation || "Designation"}
                 
                </p>
                <p className="companeyname">{props.title || "Company Name"}</p>
              </article>
              

              <article className="personalinfo sec">
                <div className="HorizontalLine"></div>
              </article>

              <article className="personalinfo sec googleContainericon">
                <h3 className="ReviewUser">Review us on</h3>
                <img className="googleimage" src={GoogleImage} />
                {/* <img className="googlestar" src={GoogleStar} /> */}
              </article>

              {/* Comment for not required in the google review theme */}

              {getBlocksOrder().map((val) => (val == undefined ? <></> : val))}
              
           
  <>

  </>


              
              <div
                class="modal fade"
                id="appotiments"
                tabindex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div class="modal-dialog modal-dialog-centered">
                  <div class="modal-content">
                    <div class="modal-header">
                      <h5 class="modal-title" id="exampleModalLabel">
                        Modal title
                      </h5>
                      <button
                        type="button"
                        class="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div class="modal-body">
                      <article class="personalinfo modal-info ">
                        <canvas
                          height="160"
                          width="160"
                          style={{ height: "128px", width: "128px" }}
                        ></canvas>
                        <h3 class="username">Subhan</h3>
                        <p class="titleuser">Software Developer</p>
                        <p class="companeyname">Xenophobe Tech</p>
                      </article>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    
      <div
        class="modal fade"
        //   ref={createUserRef}
        id="appotimnets"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="staticBackdropLabel">
                Make Appointment
              </h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <form className="row">
                <div class="col-12 mb-3">
                  <input
                    type="text"
                    class="form-control clear_string"
                    placeholder="Full Name"
                  //   value={appointmentName}
                  //   onChange={(e) =>
                  //     setAppointmentName(e.target.value)
                  //   }
                  />
                  <div
                    style={{
                      fontSize: "14px",
                      color: "red",

                      bottom: "70px",
                    }}
                  >
                    eror
                  </div>
                </div>
                <div class="col-12 mb-3">
                  <input
                    type="text"
                    class="form-control clear_string"
                    placeholder="Email"
                  //   value={appointmentEmail}
                  //   onChange={(e) =>
                  //     setAppointmentEmail(e.target.value)
                  //   }
                  />
                  <div
                    style={{
                      fontSize: "14px",
                      color: "red",
                      bottom: "70px",
                    }}
                  >
                    'errormsg'
                  </div>
                </div>
                <div class="col-12 mb-3">
                  <input
                    type="text"
                    class="form-control clear_string"
                    placeholder="Mobile No."
                  //   value={appointmentContactNumber}
                  //   onChange={(e) =>
                  //     setAppointmentContactNumber(e.target.value)
                  //   }
                  />
                  <div
                    style={{
                      fontSize: "14px",
                      color: "red",

                      bottom: "70px",
                    }}
                  >
                    'errormsgni'
                  </div>
                </div>
                <div class="mb-2 mt-4 col-md-12">
                  <button
                    //   onClick={handleSubmits}
                    class="btn btn-primary me-3"
                    type="button"
                  >
                    <i class="bi bi-check-circle-fill ms-2 "></i>
                    Submit
                  </button>
                </div>
              </form>
            </div>
            {/* <ToastContainer /> */}
          </div>
        </div>
      </div>



      
           
            </div>
    // </div>
  );
};

export default Theme12;
