import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { environmentVariables } from "../../../config/env.config";
import React, { useContext, useEffect, useState } from "react";
import { userContext } from "../../../context/userContext";

function QRCodes({ businessObj }) {
  const [forGroundColor, setforGroundColor] = useState("");
  const [backGroundColor, setBackGroundColor] = useState("");
  const [cornerRadius, setCornerRadius] = useState("");
  const baseUrl = environmentVariables?.apiUrl;
  const { userData, setUserData } = useContext(userContext);
  let businessId = localStorage.getItem("business_id");

  const handleFetchBusinessData = async () => {
    try {
      // console.log(businessObj?.id,"businessqrs code")
      let { data: res } = await axios.get(
        `${baseUrl}api/v1/business/fetchBusinessQrById?business_id=${businessObj?.id}`,
        {
          headers: {
            _token: userData?.token,
          },
          // withCredentials: true,
        }
      );
      let getBusinessData = res?.data;
      // console.log(getBusinessData,"gtBusinessDataqr code ")
      if (getBusinessData && getBusinessData?.id) {
        setforGroundColor(getBusinessData?.foreground_color);
        setBackGroundColor(getBusinessData?.background_color);
        setCornerRadius(getBusinessData?.radius);
      } else {
        setforGroundColor("");
        setBackGroundColor("");
        setCornerRadius("");
      }
    } catch (err) {
      toast.error(err?.response?.data?.message, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
    }
  };
  useEffect(() => {
    handleFetchBusinessData();
  }, [businessObj]);

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      let obj = {
        business_id: businessObj?.id,
        foreground_color: forGroundColor,
        background_color: backGroundColor,
        radius: cornerRadius,
        // qr_type: qr_type,
        // qr_text: qr_text,
        // qr_text_color:qr_text_color,
        // size: size,
      };
      // console.log(obj, "ovovovo");
      let { data: get } = await axios.post(
        `${baseUrl}api/v1/business/businessQrs`,
        obj,
        {
          headers: {
            _token: userData?.token,
          },
          // withCredentials: true,
        }
      );
      if (get?.success) {
        toast.success(get?.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
      // console.log(get,"Getgegetge")
    } catch (err) {
      toast.error(err?.response?.data?.message, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
    }
  };

  return (
    <>
      <div className="row">
        <div className="col-12 col-lg-8">
          <div className="tile">
            <div class="row justify-content-end px-0 mb-4">
              <div class="col-12 col-lg-3">
                <button
                  type="Submit"
                  class="btn btn-cu"
                  onClick={(e) => handleSubmit(e)}
                >
                  Save
                </button>
              </div>
            </div>
            <div class="mb-3">
              <label class="form-label" for="exampleInputPassword1">
                Forground Color
              </label>
              <input
                value={forGroundColor}
                class="form-control"
                id="exampleInputPassword1"
                type="color"
                onChange={(e) => setforGroundColor(e.target.value)}
              />
            </div>

            <div class="mb-3">
              <label class="form-label" for="exampleInputPassword2">
                Background Color
              </label>
              <input
                value={backGroundColor}
                onChange={(e) => setBackGroundColor(e.target.value)}
                class="form-control"
                id="exampleInputPassword1"
                type="color"
              />
            </div>
            <div class="mb-3">
              <label class="form-label" for="exampleInputPassword1">
                Corner Radius
              </label>
              <input
                value={cornerRadius}
                onChange={(e) => setCornerRadius(e.target.value)}
                type="range"
                class="form-range form-control"
                id="customRange1"
              />
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-4">
          <div className="tile">
            <img src="../images/qr.png" className="mx-auto d-block img-fluid" />
          </div>
        </div>
      </div>
    </>
  );
}

export default QRCodes;
