import React from 'react'
import "./LandingFoolterStyle.css";


function Shipping() {
  return (
    <div>

<section className='pagecover'>
    <div className='container'>
        <div className='row'>
            <div className='col-12 col-lg-12'>
                <h1>Shipping and Delivery Policy</h1>
            </div>
        </div>
    </div>
</section>

      <section class="blog">
    <div class="container">
       
        <div class="row">
            <div class="col-lg-12 col-md-12 pt-5 shipping_first_Paragraph" >
   

            <p>At <a href="https://app.businessbay.io/">businessbay.io</a>, our dedication to excellence and ensuring complete customer satisfaction is unwavering. We take pride in providing shipping services that prioritize prompt delivery. Rest assured, we are making every effort to expedite the processing and shipment of your order.</p>

    {/* <h2>SHIPPING</h2> */}
    <div className='boldfont'>Shipping</div>
    <p>All orders for our products undergo processing and are subsequently delivered within a timeframe of 5 working business days. It is important to note that orders are neither dispatched nor delivered during weekends or holidays. In instances where we are confronted with a substantial influx of orders, there may be a slight delay in the shipment process, for which we kindly request your understanding. We advise you to account for additional transit days when considering the delivery of your order. If there is a significant delay in the order shipment, we will contact you via email.</p>

    {/* <h2>WRONG ADDRESS DISCLAIMER</h2> */}
    <div className='boldfont'>Wrong address disclaimer</div>
    <p>Customers must ensure that the shipping address they provide is accurate. We make every effort to process and ship orders quickly, allowing for a limited opportunity to amend an incorrect shipping address. If you believe you need to provide a correct shipping address, please reach out to us without delay.</p>

    {/* <h2>UNDELIVERABLE ORDERS</h2> */}
    <div className='boldfont'>Undeliverable Orders</div>
    <p>Items returned to us as undeliverable due to inaccurate shipping information may incur a restocking fee, the amount of which will be determined at our discretion.</p>

    {/* <h2>Lost or Stolen Packages</h2> */}
    <div className='boldfont'>Lost or Stolen Packages</div>
    <p><a href="https://app.businessbay.io/">businessbay.io</a> is not accountable for lost or stolen packages. If your tracking information indicates that your package was delivered to your address but you have not received it, please promptly report the matter to your local authorities.</p>

    {/* <h2>RETURN REQUEST DAYS</h2> */}
    <div className='boldfont'>Return request days</div>
    <p><a href="https://app.businessbay.io/">businessbay.io</a> allows you to return its item(s) within a period of 3 days. Please be informed that the item(s) must be returned in their unopened, original, and unused condition.</p>

    {/* <h2>OUT-OF-STOCK ITEM PROCESS</h2> */}
    <div className='boldfont'>Out-of-stock item process</div>
    <p>In the event there are items that are out of stock at <a href="https://app.businessbay.io/">businessbay.io</a>, we request you to wait for all items to be in stock before dispatching.</p>

    {/* <h2>Import Duty and Taxes</h2> */}
    <div className='boldfont'>Import Duty and Taxes</div>
    <p>When transacting with <a href="https://app.businessbay.io/">businessbay.io</a>, you have the following choices regarding taxes and import duties: You may be responsible for paying the necessary fees when the items reach your destination country.</p>

    {/* <h2>Acknowledgment</h2> */}
    <div className='boldfont'>Acknowledgment</div>
    <p>By accessing our website and making a purchase, you willingly agree to the terms outlined in this Shipping Policy.</p>

    {/* <h2>Contact Information</h2> */}
    <div className='boldfont'>Contact Information</div>
    <p>If you have any questions or wish to provide feedback, kindly get in touch with us using the following contact information: <a href="mailto:info@businessbay.io">info@businessbay.io</a>.</p>

                </div>
            </div>
    </div>

</section>
    </div>
  )
}

export default Shipping
