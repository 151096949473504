import React, { useContext } from "react";
import { useState } from "react";
import Template1 from "../../Templates/Template1";
import { environmentVariables } from "../../../config/env.config";
import { toast } from "react-toastify";
import plinkimg from "../../../Images/linkqr.png";
import Theme1 from "../../Templates/themes/theme1/Theme1";
import Theme2 from "../../Templates/themes/theme2/Theme2";
import Theme3 from "../../Templates/themes/theme3/Theme3";
import Theme4 from "../../Templates/themes/theme4/Theme4";
import Theme5 from "../../Templates/themes/theme5/Theme5";
import Theme6 from "../../Templates/themes/theme6/Theme6";
import Theme7 from "../../Templates/themes/theme7/Theme7";
import Theme11 from "../../Templates/themes/theme11/Theme11";
import Theme12 from "../../Templates/themes/theme12/Theme12";
import Theme16 from "../../Templates/themes/theme16/Theme16";
import Theme17 from "../../Templates/themes/theme17/Theme17";
import Theme15 from "../../Templates/themes/theme15/Theme15";

import Theme1Template from "../../Templates/themes/theme1/Theme1Template";
import Theme2Template from "../../Templates/themes/theme2/Theme2Template";
import Theme3Template from "../../Templates/themes/theme3/Theme3Template";
import Theme4Template from "../../Templates/themes/theme4/Theme4Template";
import Theme5Template from "../../Templates/themes/theme5/Theme5Template";
import Theme6Template from "../../Templates/themes/theme6/Theme6Template";
import Theme7Template from "../../Templates/themes/theme7/Theme7Template";
import Theme11Template from "../../Templates/themes/theme11/Theme11Template";
import Theme12Template from "../../Templates/themes/theme12/Theme12Template";
import Theme17Template from "../../Templates/themes/theme17/Theme17Template";
import Theme16Template from "../../Templates/themes/theme16/Theme16Template";
import Theme18Template from "../../Templates/themes/theme18/Theme18Template";
import Theme20Template from "../../Templates/themes/theme20/Theme20Template";
import Theme22Template from "../../Templates/themes/theme22/Theme22Template";
import Theme15Template from "../../Templates/themes/theme15/Theme15Template";
import Theme27Template from "../../Templates/themes/theme27/Theme27Template";
import CircularLoader from "../../Components/CircularLoader/CircularLoader";
import { useNavigate } from "react-router";
import { userContext } from "../../../context/userContext";
import axios from "axios";
import "./MobileStyle.css";
import Theme8 from "../../Templates/themes/theme8/Theme8";
import Theme8Template from "../../Templates/themes/theme8/Theme8Template";
import Theme9Template from "../../Templates/themes/theme9/Theme9Template";
import Theme9 from "../../Templates/themes/theme9/Theme9";
import Theme10Template from "../../Templates/themes/theme10/Theme10Template";
import Theme10 from "../../Templates/themes/theme10/Theme10";
import Theme14Template from "../../Templates/themes/theme14/Theme14Template";
import Theme14 from "../../Templates/themes/theme14/Theme14";
import Theme18 from "../../Templates/themes/theme18/Theme18";
import Theme20 from "../../Templates/themes/theme20/Theme20";
import Theme22 from "../../Templates/themes/theme22/Theme22";
import Theme27 from "../../Templates/themes/theme27/Theme27";

const ViewThem = (props) => {
  // console.log(props,"v^%YT")
  const { userData } = useContext(userContext);
  const baseUrl = environmentVariables?.apiUrl;
  const fronturl = environmentVariables?.frntUrl;
  const navigate = useNavigate();

  const [activeTab, setActiveTab] = useState(1);
  const [loadingSavePreview, setLoadingSavePreview] = useState(false);
  const [order, setOrder] = useState(null);
  const [businessId, setBusinessId] = useState(
    localStorage.getItem("business_id")
  );
  const getTemplate = (theme) => {
    // const appointmentFilterArray = props?.appointmentArray.filter(item =>
    //   item.startDate !== '' && item.startDate !== null &&
    //   item.endDate !== '' && item.endDate !== null
    // );
    switch (theme) {
      case "theme6":
        return (
          <Theme1
            galleryData={props.galleryData}
            changeTimeFormat={changeTimeFormat}
            existingGalleryImages={props.existingGalleryImages}
            galleryView={props.galleryView}
            orderOfBlocks={props.orderOfBlocks}
            themeColor={props.themeColor}
            themeNumber={props.themeNumber}
            title={props.title}
            designation={props.designation}
            googlereview={props?.googlereview}
            subTitle={props.subTitle}
            description={props.description}
            bannerImageUrl={props.bannerImageUrl}
            logoImageUrl={props.logoImageUrl}
            contactInfo={props.contactInfo}
            businessHourObject={props.businessHourObject}
            socialMediaData={props.socialMediaData}
            servicesArray={props.servicesArray}
            testimonialArray={props.testimonialArray}
            onState={props.onState}
            businessObj={props.businessObj}
            gallaryObject={props.gallaryObject}
            appointmentArray={props?.appointmentArray}
            order={order}
            setOrder={setOrder}
            businessId={businessId}
            getImage={getImage}
            review={props.reviews}
            rating={props.rating}
          />
        );
      case "theme5":
        return (
          <Theme2
            galleryData={props.galleryData}
            changeTimeFormat={changeTimeFormat}
            existingGalleryImages={props.existingGalleryImages}
            galleryView={props.galleryView}
            orderOfBlocks={props.orderOfBlocks}
            themeColor={props.themeColor}
            themeNumber={props.themeNumber}
            title={props.title}
            designation={props.designation}
            googlereview={props?.googlereview}
            subTitle={props.subTitle}
            description={props.description}
            bannerImageUrl={props.bannerImageUrl}
            logoImageUrl={props.logoImageUrl}
            contactInfo={props.contactInfo}
            businessHourObject={props.businessHourObject}
            socialMediaData={props.socialMediaData}
            servicesArray={props.servicesArray}
            testimonialArray={props.testimonialArray}
            onState={props.onState}
            businessObj={props.businessObj}
            gallaryObject={props.gallaryObject}
            appointmentArray={props?.appointmentArray}
            order={order}
            setOrder={setOrder}
            businessId={businessId}
            getImage={getImage}
            review={props.reviews}
            rating={props.rating}
          />
        );
      case "theme3":
        return (
          <Theme3
            galleryData={props.galleryData}
            changeTimeFormat={changeTimeFormat}
            existingGalleryImages={props.existingGalleryImages}
            galleryView={props.galleryView}
            orderOfBlocks={props.orderOfBlocks}
            themeColor={props.themeColor}
            themeNumber={props.themeNumber}
            title={props.title}
            designation={props.designation}
            googlereview={props.googlereview}
            subTitle={props.subTitle}
            description={props.description}
            bannerImageUrl={props.bannerImageUrl}
            logoImageUrl={props.logoImageUrl}
            contactInfo={props.contactInfo}
            businessHourObject={props.businessHourObject}
            socialMediaData={props.socialMediaData}
            servicesArray={props.servicesArray}
            testimonialArray={props.testimonialArray}
            onState={props.onState}
            businessObj={props.businessObj}
            gallaryObject={props.gallaryObject}
            appointmentArray={props?.appointmentArray}
            order={order}
            setOrder={setOrder}
            businessId={businessId}
            getImage={getImage}
            review={props.reviews}
            rating={props.rating}
          />
        );
      case "theme4":
        return (
          <Theme4
            galleryData={props.galleryData}
            changeTimeFormat={changeTimeFormat}
            existingGalleryImages={props.existingGalleryImages}
            galleryView={props.galleryView}
            orderOfBlocks={props.orderOfBlocks}
            themeColor={props.themeColor}
            themeNumber={props.themeNumber}
            title={props.title}
            designation={props.designation}
            googlereview={props.googlereview}
            subTitle={props.subTitle}
            description={props.description}
            bannerImageUrl={props.bannerImageUrl}
            logoImageUrl={props.logoImageUrl}
            contactInfo={props.contactInfo}
            businessHourObject={props.businessHourObject}
            socialMediaData={props.socialMediaData}
            servicesArray={props.servicesArray}
            testimonialArray={props.testimonialArray}
            onState={props.onState}
            businessObj={props.businessObj}
            gallaryObject={props.gallaryObject}
            appointmentArray={props?.appointmentArray}
            order={order}
            setOrder={setOrder}
            businessId={businessId}
            getImage={getImage}
            review={props.reviews}
            rating={props.rating}
          />
        );
      case "theme2":
        return (
          <Theme5
            galleryData={props.galleryData}
            changeTimeFormat={changeTimeFormat}
            existingGalleryImages={props.existingGalleryImages}
            galleryView={props.galleryView}
            orderOfBlocks={props.orderOfBlocks}
            themeColor={props.themeColor}
            themeNumber={props.themeNumber}
            title={props.title}
            designation={props.designation}
            googlereview={props?.googlereview}
            subTitle={props.subTitle}
            description={props.description}
            bannerImageUrl={props.bannerImageUrl}
            logoImageUrl={props.logoImageUrl}
            contactInfo={props.contactInfo}
            businessHourObject={props.businessHourObject}
            socialMediaData={props.socialMediaData}
            servicesArray={props.servicesArray}
            testimonialArray={props.testimonialArray}
            onState={props.onState}
            businessObj={props.businessObj}
            gallaryObject={props.gallaryObject}
            appointmentArray={props?.appointmentArray}
            order={order}
            setOrder={setOrder}
            businessId={businessId}
            getImage={getImage}
            review={props.reviews}
            rating={props.rating}
          />
        );
      case "theme1":
        return (
          <Theme6
            galleryData={props.galleryData}
            changeTimeFormat={changeTimeFormat}
            existingGalleryImages={props.existingGalleryImages}
            galleryView={props.galleryView}
            orderOfBlocks={props.orderOfBlocks}
            themeColor={props.themeColor}
            themeNumber={props.themeNumber}
            title={props.title}
            designation={props.designation}
            googlereview={props.googlereview}
            subTitle={props.subTitle}
            description={props.description}
            bannerImageUrl={props.bannerImageUrl}
            logoImageUrl={props.logoImageUrl}
            contactInfo={props.contactInfo}
            businessHourObject={props.businessHourObject}
            socialMediaData={props.socialMediaData}
            servicesArray={props.servicesArray}
            testimonialArray={props.testimonialArray}
            onState={props.onState}
            businessObj={props.businessObj}
            gallaryObject={props.gallaryObject}
            appointmentArray={props?.appointmentArray}
            order={order}
            setOrder={setOrder}
            businessId={businessId}
            getImage={getImage}
            review={props.reviews}
            rating={props.rating}
          />
        );
      case "theme3":
        return (
          <Theme3
            galleryData={props.galleryData}
            changeTimeFormat={changeTimeFormat}
            existingGalleryImages={props.existingGalleryImages}
            galleryView={props.galleryView}
            orderOfBlocks={props.orderOfBlocks}
            themeColor={props.themeColor}
            themeNumber={props.themeNumber}
            title={props.title}
            designation={props.designation}
            googlereview={props.googlereview}
            subTitle={props.subTitle}
            description={props.description}
            bannerImageUrl={props.bannerImageUrl}
            logoImageUrl={props.logoImageUrl}
            contactInfo={props.contactInfo}
            businessHourObject={props.businessHourObject}
            socialMediaData={props.socialMediaData}
            servicesArray={props.servicesArray}
            testimonialArray={props.testimonialArray}
            onState={props.onState}
            businessObj={props.businessObj}
            gallaryObject={props.gallaryObject}
            appointmentArray={props?.appointmentArray}
            order={order}
            setOrder={setOrder}
            businessId={businessId}
            getImage={getImage}
            review={props.reviews}
            rating={props.rating}
          />
        );
      case "theme7":
        return (
          <Theme7
            galleryData={props.galleryData}
            changeTimeFormat={changeTimeFormat}
            existingGalleryImages={props.existingGalleryImages}
            galleryView={props.galleryView}
            orderOfBlocks={props.orderOfBlocks}
            themeColor={props.themeColor}
            themeNumber={props.themeNumber}
            title={props.title}
            designation={props.designation}
            googlereview={props.googlereview}
            subTitle={props.subTitle}
            description={props.description}
            bannerImageUrl={props.bannerImageUrl}
            logoImageUrl={props.logoImageUrl}
            contactInfo={props.contactInfo}
            businessHourObject={props.businessHourObject}
            socialMediaData={props.socialMediaData}
            servicesArray={props.servicesArray}
            testimonialArray={props.testimonialArray}
            onState={props.onState}
            businessObj={props.businessObj}
            gallaryObject={props.gallaryObject}
            appointmentArray={props?.appointmentArray}
            order={order}
            setOrder={setOrder}
            businessId={businessId}
            getImage={getImage}
            review={props.reviews}
            rating={props.rating}
          />
        );
      case "theme8":
        return (
          <Theme8
            galleryData={props.galleryData}
            changeTimeFormat={changeTimeFormat}
            existingGalleryImages={props.existingGalleryImages}
            galleryView={props.galleryView}
            orderOfBlocks={props.orderOfBlocks}
            themeColor={props.themeColor}
            themeNumber={props.themeNumber}
            title={props.title}
            designation={props.designation}
            googlereview={props.googlereview}
            subTitle={props.subTitle}
            description={props.description}
            bannerImageUrl={props.bannerImageUrl}
            logoImageUrl={props.logoImageUrl}
            contactInfo={props.contactInfo}
            businessHourObject={props.businessHourObject}
            socialMediaData={props.socialMediaData}
            servicesArray={props.servicesArray}
            testimonialArray={props.testimonialArray}
            onState={props.onState}
            businessObj={props.businessObj}
            gallaryObject={props.gallaryObject}
            appointmentArray={props?.appointmentArray}
            order={order}
            setOrder={setOrder}
            businessId={businessId}
            getImage={getImage}
            review={props.reviews}
            rating={props.rating}
          />
        );
      case "theme9":
        return (
          <Theme9
            galleryData={props.galleryData}
            changeTimeFormat={changeTimeFormat}
            existingGalleryImages={props.existingGalleryImages}
            galleryView={props.galleryView}
            orderOfBlocks={props.orderOfBlocks}
            themeColor={props.themeColor}
            themeNumber={props.themeNumber}
            title={props.title}
            designation={props.designation}
            googlereview={props.googlereview}
            subTitle={props.subTitle}
            description={props.description}
            bannerImageUrl={props.bannerImageUrl}
            logoImageUrl={props.logoImageUrl}
            contactInfo={props.contactInfo}
            businessHourObject={props.businessHourObject}
            socialMediaData={props.socialMediaData}
            servicesArray={props.servicesArray}
            testimonialArray={props.testimonialArray}
            onState={props.onState}
            businessObj={props.businessObj}
            gallaryObject={props.gallaryObject}
            appointmentArray={props?.appointmentArray}
            order={order}
            setOrder={setOrder}
            businessId={businessId}
            getImage={getImage}
            review={props.reviews}
            rating={props.rating}
          />
        );
      case "theme10":
        return (
          <Theme10
            galleryData={props.galleryData}
            changeTimeFormat={changeTimeFormat}
            existingGalleryImages={props.existingGalleryImages}
            galleryView={props.galleryView}
            orderOfBlocks={props.orderOfBlocks}
            themeColor={props.themeColor}
            themeNumber={props.themeNumber}
            title={props.title}
            designation={props.designation}
            googlereview={props.googlereview}
            subTitle={props.subTitle}
            description={props.description}
            bannerImageUrl={props.bannerImageUrl}
            logoImageUrl={props.logoImageUrl}
            contactInfo={props.contactInfo}
            businessHourObject={props.businessHourObject}
            socialMediaData={props.socialMediaData}
            servicesArray={props.servicesArray}
            testimonialArray={props.testimonialArray}
            onState={props.onState}
            businessObj={props.businessObj}
            gallaryObject={props.gallaryObject}
            appointmentArray={props?.appointmentArray}
            order={order}
            setOrder={setOrder}
            businessId={businessId}
            getImage={getImage}
            review={props.reviews}
            rating={props.rating}
          />
        );
      case "theme11":
        return (
          <Theme11
            galleryData={props.galleryData}
            changeTimeFormat={changeTimeFormat}
            existingGalleryImages={props.existingGalleryImages}
            galleryView={props.galleryView}
            orderOfBlocks={props.orderOfBlocks}
            themeColor={props.themeColor}
            themeNumber={props.themeNumber}
            title={props.title}
            designation={props.designation}
            googlereview={props.googlereview}
            subTitle={props.subTitle}
            description={props.description}
            bannerImageUrl={props.bannerImageUrl}
            logoImageUrl={props.logoImageUrl}
            contactInfo={props.contactInfo}
            businessHourObject={props.businessHourObject}
            socialMediaData={props.socialMediaData}
            servicesArray={props.servicesArray}
            testimonialArray={props.testimonialArray}
            onState={props.onState}
            businessObj={props.businessObj}
            gallaryObject={props.gallaryObject}
            appointmentArray={props?.appointmentArray}
            order={order}
            setOrder={setOrder}
            businessId={businessId}
            getImage={getImage}
            review={props.reviews}
            rating={props.rating}
          />
        );
      case "theme12":
        return (
          <Theme12
            galleryData={props.galleryData}
            changeTimeFormat={changeTimeFormat}
            existingGalleryImages={props.existingGalleryImages}
            galleryView={props.galleryView}
            orderOfBlocks={props.orderOfBlocks}
            themeColor={props.themeColor}
            themeNumber={props.themeNumber}
            title={props.title}
            designation={props.designation}
            googlereview={props.googlereview}
            subTitle={props.subTitle}
            description={props.description}
            bannerImageUrl={props.bannerImageUrl}
            logoImageUrl={props.logoImageUrl}
            contactInfo={props.contactInfo}
            businessHourObject={props.businessHourObject}
            socialMediaData={props.socialMediaData}
            servicesArray={props.servicesArray}
            testimonialArray={props.testimonialArray}
            onState={props.onState}
            businessObj={props.businessObj}
            gallaryObject={props.gallaryObject}
            appointmentArray={props?.appointmentArray}
            order={order}
            setOrder={setOrder}
            businessId={businessId}
            getImage={getImage}
            review={props.reviews}
            rating={props.rating}
          />
        );
      case "theme15":
        return (
          <Theme15
            galleryData={props.galleryData}
            changeTimeFormat={changeTimeFormat}
            existingGalleryImages={props.existingGalleryImages}
            galleryView={props.galleryView}
            orderOfBlocks={props.orderOfBlocks}
            themeColor={props.themeColor}
            themeNumber={props.themeNumber}
            title={props.title}
            designation={props.designation}
            googlereview={props.googlereview}
            subTitle={props.subTitle}
            description={props.description}
            bannerImageUrl={props.bannerImageUrl}
            logoImageUrl={props.logoImageUrl}
            contactInfo={props.contactInfo}
            businessHourObject={props.businessHourObject}
            socialMediaData={props.socialMediaData}
            servicesArray={props.servicesArray}
            testimonialArray={props.testimonialArray}
            onState={props.onState}
            businessObj={props.businessObj}
            gallaryObject={props.gallaryObject}
            appointmentArray={props?.appointmentArray}
            order={order}
            setOrder={setOrder}
            businessId={businessId}
            getImage={getImage}
            review={props.reviews}
            rating={props.rating}
          />
        );
      case "theme14":
        return (
          <Theme14
            galleryData={props.galleryData}
            changeTimeFormat={changeTimeFormat}
            existingGalleryImages={props.existingGalleryImages}
            galleryView={props.galleryView}
            orderOfBlocks={props.orderOfBlocks}
            themeColor={props.themeColor}
            themeNumber={props.themeNumber}
            title={props.title}
            isDine={props.isDine}
            designation={props.designation}
            subTitle={props.subTitle}
            description={props.description}
            bannerImageUrl={props.bannerImageUrl}
            logoImageUrl={props.logoImageUrl}
            contactInfo={props.contactInfo}
            businessHourObject={props.businessHourObject}
            socialMediaData={props.socialMediaData}
            servicesArray={props.servicesArray}
            testimonialArray={props.testimonialArray}
            restaurantArray={props.restaurantArray}
            onState={props.onState}
            businessObj={props.businessObj}
            gallaryObject={props.gallaryObject}
            appointmentArray={props?.appointmentArray}
            order={order}
            setOrder={setOrder}
            businessId={businessId}
            getImage={getImage}
            review={props.reviews}
            rating={props.rating}
          />
        );
      case "theme16":
        return (
          <Theme16
            galleryData={props.galleryData}
            changeTimeFormat={changeTimeFormat}
            existingGalleryImages={props.existingGalleryImages}
            galleryView={props.galleryView}
            orderOfBlocks={props.orderOfBlocks}
            themeColor={props.themeColor}
            themeNumber={props.themeNumber}
            title={props.title}
            designation={props.designation}
            subTitle={props.subTitle}
            description={props.description}
            bannerImageUrl={props.bannerImageUrl}
            logoImageUrl={props.logoImageUrl}
            contactInfo={props.contactInfo}
            businessHourObject={props.businessHourObject}
            socialMediaData={props.socialMediaData}
            servicesArray={props.servicesArray}
            testimonialArray={props.testimonialArray}
            restaurantArray={props.restaurantArray}
            onState={props.onState}
            businessObj={props.businessObj}
            gallaryObject={props.gallaryObject}
            appointmentArray={props?.appointmentArray}
            order={order}
            setOrder={setOrder}
            businessId={businessId}
            getImage={getImage}
            review={props.reviews}
            rating={props.rating}
          />
        );

      case "theme17":
        return (
          <Theme17
            galleryData={props.galleryData}
            changeTimeFormat={changeTimeFormat}
            existingGalleryImages={props.existingGalleryImages}
            galleryView={props.galleryView}
            orderOfBlocks={props.orderOfBlocks}
            themeColor={props.themeColor}
            themeNumber={props.themeNumber}
            title={props.title}
            designation={props.designation}
            subTitle={props.subTitle}
            description={props.description}
            bannerImageUrl={props.bannerImageUrl}
            logoImageUrl={props.logoImageUrl}
            contactInfo={props.contactInfo}
            businessHourObject={props.businessHourObject}
            socialMediaData={props.socialMediaData}
            servicesArray={props.servicesArray}
            testimonialArray={props.testimonialArray}
            restaurantArray={props.restaurantArray}
            onState={props.onState}
            businessObj={props.businessObj}
            gallaryObject={props.gallaryObject}
            appointmentArray={props?.appointmentArray}
            order={order}
            setOrder={setOrder}
            businessId={businessId}
            getImage={getImage}
            review={props.reviews}
            rating={props.rating}
          />
        );

      case "theme18":
        return (
          <Theme18
            galleryData={props.galleryData}
            changeTimeFormat={changeTimeFormat}
            existingGalleryImages={props.existingGalleryImages}
            galleryView={props.galleryView}
            orderOfBlocks={props.orderOfBlocks}
            themeColor={props.themeColor}
            themeNumber={props.themeNumber}
            title={props.title}
            designation={props.designation}
            subTitle={props.subTitle}
            description={props.description}
            bannerImageUrl={props.bannerImageUrl}
            logoImageUrl={props.logoImageUrl}
            contactInfo={props.contactInfo}
            businessHourObject={props.businessHourObject}
            socialMediaData={props.socialMediaData}
            servicesArray={props.servicesArray}
            testimonialArray={props.testimonialArray}
            restaurantArray={props.restaurantArray}
            onState={props.onState}
            businessObj={props.businessObj}
            gallaryObject={props.gallaryObject}
            appointmentArray={props?.appointmentArray}
            order={order}
            setOrder={setOrder}
            businessId={businessId}
            getImage={getImage}
            review={props.reviews}
            rating={props.rating}
          />
        );
      case "theme20":
        return (
          <Theme20
            galleryData={props.galleryData}
            changeTimeFormat={changeTimeFormat}
            existingGalleryImages={props.existingGalleryImages}
            galleryView={props.galleryView}
            orderOfBlocks={props.orderOfBlocks}
            themeColor={props.themeColor}
            themeNumber={props.themeNumber}
            title={props.title}
            designation={props.designation}
            googlereview={props.googlereview}
            subTitle={props.subTitle}
            description={props.description}
            bannerImageUrl={props.bannerImageUrl}
            logoImageUrl={props.logoImageUrl}
            contactInfo={props.contactInfo}
            businessHourObject={props.businessHourObject}
            socialMediaData={props.socialMediaData}
            servicesArray={props.servicesArray}
            testimonialArray={props.testimonialArray}
            onState={props.onState}
            businessObj={props.businessObj}
            gallaryObject={props.gallaryObject}
            appointmentArray={props?.appointmentArray}
            order={order}
            setOrder={setOrder}
            businessId={businessId}
            getImage={getImage}
            minimarketArray={props?.minimarketArray}
            review={props.reviews}
            rating={props.rating}
          />
        );
        case "theme22":
          return (
            <Theme22
              galleryData={props.galleryData}
              changeTimeFormat={changeTimeFormat}
              existingGalleryImages={props.existingGalleryImages}
              galleryView={props.galleryView}
              orderOfBlocks={props.orderOfBlocks}
              themeColor={props.themeColor}
              themeNumber={props.themeNumber}
              title={props.title}
              designation={props.designation}
              googlereview={props.googlereview}
              subTitle={props.subTitle}
              description={props.description}
              bannerImageUrl={props.bannerImageUrl}
              logoImageUrl={props.logoImageUrl}
              contactInfo={props.contactInfo}
              businessHourObject={props.businessHourObject}
              socialMediaData={props.socialMediaData}
              termsConditionData={props.termsConditionData}
              servicesArray={props.servicesArray}
              testimonialArray={props.testimonialArray}
              onState={props.onState}
              businessObj={props.businessObj}
              gallaryObject={props.gallaryObject}
              appointmentArray={props?.appointmentArray}
              cabArray={props?.cabArray}
              order={order}
              setOrder={setOrder}
              businessId={businessId}
              getImage={getImage}
              review={props.reviews}
              rating={props.rating}
            />
          );
          case "theme27":
          return (
            <Theme27
              galleryData={props.galleryData}
              changeTimeFormat={changeTimeFormat}
              existingGalleryImages={props.existingGalleryImages}
              galleryView={props.galleryView}
              orderOfBlocks={props.orderOfBlocks}
              themeColor={props.themeColor}
              themeNumber={props.themeNumber}
              title={props.title}
              designation={props.designation}
              googlereview={props.googlereview}
              subTitle={props.subTitle}
              description={props.description}
              bannerImageUrl={props.bannerImageUrl}
              logoImageUrl={props.logoImageUrl}
              contactInfo={props.contactInfo}
              businessHourObject={props.businessHourObject}
              socialMediaData={props.socialMediaData}
              servicesArray={props.servicesArray}
              testimonialArray={props.testimonialArray}
              onState={props.onState}
              businessObj={props.businessObj}
              gallaryObject={props.gallaryObject}
              appointmentArray={props?.appointmentArray}
              order={order}
              setOrder={setOrder}
              businessId={businessId}
              getImage={getImage}
              review={props.reviews}
              rating={props.rating}
            />
          );
      default:
        return null;
    }
  };
  const getStaticTemplate = (theme) => {
    switch (theme) {
      case "theme6":
        return <Theme1Template />;
      case "theme5":
        return <Theme2Template />;
      case "theme3":
        return <Theme3Template />;
      case "theme4":
        return <Theme4Template />;
      case "theme2":
        return <Theme5Template />;
      case "theme1":
        return <Theme6Template />;
      case "theme7":
        return <Theme7Template />;
      case "theme8":
        return <Theme8Template />;
      case "theme9":
        return <Theme9Template />;
      case "theme10":
        return <Theme10Template />;
      case "theme11":
        return <Theme11Template />;
      case "theme15":
        return <Theme15Template />;
      case "theme14":
        return <Theme14Template />;
      case "theme12":
        return <Theme12Template />;
      case "theme16":
        return <Theme16Template />;
      case "theme17":
        return <Theme17Template />;
      case "theme18":
        return <Theme18Template />;
      case "theme20":
        return <Theme20Template />;
      case "theme22":
          return <Theme22Template />
          case "theme27":
            return <Theme27Template />
      default:
        return null;  
    }
  };
  const handleTabChange = (tabNumber) => {
    setActiveTab(tabNumber);
  };
  // console.log(props.themeColor, "props of details");
  const changeTimeFormat = (time24) => {
    // Split the time string into hours and minutes
    const [hours, minutes] = time24.split(":").map(Number);

    // Create a Date object with today's date
    const date = new Date();

    // Set the hours and minutes to the Date object
    date.setHours(hours);
    date.setMinutes(minutes);

    // Format the time in AM/PM format
    const time12 = date.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    });

    return time12;
  };

  const getImage = (image) => {
    if (image.includes("base64")) {
      return image;
    } else {
      return `${environmentVariables?.apiUrl}uploads/${image}`;
    }
  };
  const handleCopyClick = () => {
    navigator.clipboard
      .writeText(
        `${fronturl}${
          props?.businessObj?.slug ? props?.businessObj?.slug : ""
        }`
      )
      .then(() => {
        setCopied(true);
        toast.success("link copied", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      })
      .catch((error) => {
        console.error("Failed to copy link: ", error);
        toast.error(error, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      });
    toast.success("link copied", {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 1000,
    });
  };
  const httpProtocol = environmentVariables?.httpProtocol;
  const handleUpdateBusiness = (e) => {
    setLoadingSavePreview(true);
    if (props.selectedTheme) {
      e.stopPropagation();
      let obj = {
        theme: props?.selectedTheme,
        theme_color: props?.selectedTheme,
        businessId: props?.businessObj?.id,
      };
      axios
        .post(`${baseUrl}api/v1/business/edit`, obj, {
          headers: {
            _token: userData?.token,
          },
          withCredentials: true,
        })
        .then((res) => {
          if (res?.data?.success) {
            let businessDataBE = res.data.data;
            let redirectUrl =
              businessDataBE?.slug_subdomain != null
                ? httpProtocol + businessDataBE?.slug_subdomain+"?pswutlzoq=install"
                : props?.businessObj?.slug
                ? "/" + props?.businessObj?.slug+"?pswutlzoq=install"
                : "/";

            window.open(`${redirectUrl}`, "_blank");
            setLoadingSavePreview(false);
            props.setThemeColor(selectedTheme);
            // navigate("/dashboard");
          }
        })
        .catch((err) => {
          setLoadingSavePreview(false);
          toast.error(err?.response?.data?.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
        });
    } else if (props.activeTab == "tab2") {
      props.handleSubmit(e);
      setLoadingSavePreview(false);
    } else if (props.activeTab == "tab3") {
      props.handleSubmit(e);
      setLoadingSavePreview(false);
    } else {
      window.open(
        `/${props?.businessObj?.slug ? props?.businessObj?.slug : ""}`,
        "_blank"
      );
    }
  };

  return (
    <>
      <div className="col-12 col-lg-4">
        <div className="tile-aaa">
          <div className="privewmobile">
            <div className="viewlink border-bottom d-none">
              <div className="linkl">
                <p>{`${fronturl}${
                  props?.businessObj?.slug ? props?.businessObj?.slug : ""
                }`}</p>
              </div>
              <div className="linkr">
                <ul className="linkandcopy">
                  <li onClick={handleCopyClick}>
                    <i class="fa-solid fa-copy"></i>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      href={`${fronturl}${
                        props?.businessObj?.slug ? props?.businessObj?.slug : ""
                      }`}
                    >
                      <i class="fa-solid fa-arrow-up-right-from-square"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            <div className="viewlink  preview_nav d-none">
              <div className="linkl">
                <ul className="btntabhere">
                  <li
                    onClick={() => handleTabChange(1)}
                    className={activeTab === 1 ? "active" : ""}
                  >
                    <i class="fa-solid fa-eye"></i>
                  </li>
                  <li
                    onClick={() => handleTabChange(2)}
                    className={activeTab === 2 ? "active" : ""}
                  >
                    <i class="fa-solid fa-qrcode"></i>
                  </li>
                </ul>
              </div>
              <div className="linkr">
                <button className="btn btn-save">Save</button>
              </div>
            </div>
            {activeTab === 1 &&
              (props.mobilePreviewLoading ? (
                <CircularLoader size={40} />
              ) : (
                <div className="movileview LL3007 MobileSaveandpreviewHeight">
                  <>
                    <div class="device-header"></div>
                    {/* <iframe
                  src="http://localhost:3000/template1"
                  width="100%"
                  height="600"
                  title="Example Iframe"
                /> */}
                    {props.activeTab == "tab1" &&
                    (props.isChecking == null || props.isChecking == undefined)
                      ? getStaticTemplate(props.themeColor)
                      : getTemplate(props.themeColor)}
                  </>
                </div>
              ))}

            {activeTab === 2 && (
              <div>
                <img
                  src="https://upload.wikimedia.org/wikipedia/commons/thumb/4/41/QR_Code_Example.svg/1200px-QR_Code_Example.svg.png"
                  className="mx-auto d-block img-fluid"
                />
              </div>
            )}
          </div>
          {props.activeTab == "tab1" &&
          (props.isChecking == null || props.isChecking == undefined) ? (
            <></>
          ) : (
            <>
              {loadingSavePreview || props.isLoadingSubmit ? (
                <CircularLoader />
              ) : (
                <>
                  {props.mobilePreviewLoading ? (
                    <></>
                  ) : (
                    <p className="linkcopunextwindiow" >
                      <a
                        target="_blank"
                        style={{ cursor: "pointer",display:"flex", justifyContent:"center",alignItems:"center", textAlign:"center" }}
                        onClick={(e) => {
                          handleUpdateBusiness(e);
                        }}
                      >
                        Save & Preview 
                        <img style={{ padding: "0 10px" }} src={plinkimg} />
                      </a>
                    </p>
                  )}
                </>
              )}
            </>
          )}
          {/* {(props?.isChecking != null || props?.isChecking != undefined) &&
            props?.activeTab != "tab1" && (
              <p className="linkcopunextwindiow">
                <a
                  target="_blank"
                  href={`/${
                    props?.businessObj?.slug ? props?.businessObj?.slug : ""
                  }`}
                >
                  Live Preview <img src={plinkimg} />
                </a>
              </p>
            )} */}
        </div>
      </div>
    </>
  );
};
//112 16:28
export default ViewThem;
