import React, { useContext, useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import Footer from "./Footer";
import Header from "./Header";
import Sidebar from "./Sidebar";
import { ThemeContext } from "../../context/ThemeContext";

function DashbordLayout({
  setBusinessId,
  businessId,
  isChangeDynamicBusinessName,
  setDynamicBusinessName,
  dynamicBusinessName,
}) {
  const [sideBarWidth, setSideBarWidth] = useState();
  const [viewNavbar, setViewNavbar] = useState(false);
  const handleToggleSidebar = () => {};
  useEffect(() => {
    const params = window.location.pathname;
    if (params == "/") {
      setViewNavbar(false);
    } else {
      setViewNavbar(true);
    }
  }, []);

  const themeData = useContext(ThemeContext)
  const { light , dark } = themeData;

  return (
    <div>
      {viewNavbar && (
        <Header
          setDynamicBusinessName={setDynamicBusinessName}
          dynamicBusinessName={dynamicBusinessName}
          businessId={businessId}
          setBusinessId={setBusinessId}
          handleToggleSidebar={handleToggleSidebar}
          isChangeDynamicBusinessName={isChangeDynamicBusinessName}
        />
      )}
      <Sidebar />
      <main className="app-content">
        <Outlet />
      </main>
      {/* <Footer /> */}
    </div>
  );
}

export default DashbordLayout;
