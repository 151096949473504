import React, { useContext, useEffect, useState, useRef } from "react";
import PageName from "../Utils/PageName";
import { environmentVariables } from "../../config/env.config";
import axios from "axios";
import { userContext } from "../../context/userContext";
import { ToastContainer, toast } from "react-toastify";
import DataTable from "react-data-table-component";
import SortIcon from "@material-ui/icons/ArrowDownward";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import CircularLoader from "../../Backend/Components/CircularLoader/CircularLoader";
import Swal from 'sweetalert2';

function Plan_enquiry() {
  const [data, setData] = useState([]);
  const [totalCount, setTotalCount] = useState([]);
  const [loading, setLoading] = useState(true);
  const baseUrl = environmentVariables?.apiUrl;
  const { userData } = useContext(userContext);
  const createCouponModalRef = useRef(null);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedOption, setSelectedOption] = useState("");
  const [enquiryMsg, setEnquiryMsg] = useState("");
  const [isSubmit, setIsSubmit] = useState(false);
  const [enquiry, setEnquiry] = useState("");

  const fetchEvents = async () => {
    try {
      axios
        .get(`${baseUrl}api/v1/user/enterprisePlanQueries`, {
          headers: {
            _token: userData?.token,
          },
          withCredentials: true,
        })
        .then((response) => {
          setData(response?.data?.result);
          setTotalCount(response?.data?.pagination?.totalCount);
          setIsLoading(false)

        })
        .catch((error) => {
          console.log(error);
          setIsLoading(false)
        });
    } catch (err) { }
  };
  useEffect(() => {
    fetchEvents();
    return () => {
      $(createCouponModalRef.current).off("hidden.bs.modal");
    };
  }, []);



  const columns = [
    {
      name: "Name",
      selector: (row) => row.firstname,
      sortable: true,
      width: "140px",
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
      width: "200px",
    },
    {
      name: "Mobile",
      selector: (row) => row.mobile,
      sortable: true,
      width: "160px",
    },
    {
      name: "Message",
      selector: (row) => {
        return <span> {row.message} </span>
      },
      sortable: true,
      width: "200px",
    },
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
      width: "130px",
    },
    {
      name: "Plan",
      selector: (row) => {
        return row?.planObj != null?row?.planObj?.name:"--"
      },
      sortable: true,
      width: "170px",
    },
    {
      name: "Action",
      sortable: false,
      width: "100px",
      cell: (d) => [
        <>
         <i
              key={d?.id}
              data-bs-toggle="modal"
              data-bs-target="#planselect"
              onClick={() => fetchData(d)}
              className="first fas fa-pen"
            ></i>
        </>
      ],
    },
  ];

  const customStyles = {
    
    headRow: {
      style: {
        display: "flex",
        alignItems: "stretch",
        width: "100%",
        backgroundColor: "#E9E9E9",
        minHeight: "52px",
        borderRadius: "2px 2px 0px 0px",
        padding: "8px 25px",
        border: "none",
       
      },
    },
    headCells: {
      style: {
        color: "#525252",
        fontSize: "16px",
        minHeight: "52px",
        fontSize:"15px",
      
      },
    },
    rows: {
      style: {
        padding: "20px 25px",
        fontSize: "14px",
        textTransform: "capitalize",
        minHeight: "48px ",
      },
    },
  };

  const tableData = {
    columns,
    data,
  };

  const handleDropdownChange = (e) => {
    if (e.target.value == "pending") {
      setSelectedOption(e.target.value);
    } else if (e.target.value == "confirm") {
      setSelectedOption(e.target.value);
    } else if (e.target.value == "cancel") {
      setSelectedOption(e.target.value);
    }
  };

  const fetchData = async (obj) => {
    try {
      setEnquiry(obj?.id);
      setEnquiryMsg(obj?.message)
      let statusData = obj.status != null?obj.status:"pending";
      setSelectedOption(statusData);
    } catch (error) {
      console.error("API Error:", error);
    }
  };
  const handleUpdate = () => {};
  const [editedData, setEditedData] = useState({
    date: new Date(),
  });

  const handleSubmit = (e) => {
    setIsSubmit(true);
    let obj = {
      enquiry_id:enquiry,
      enquiry_status:selectedOption,
    };
    axios
      .post(`${baseUrl}api/v1/user/updateEnterpriseQuery`, obj, {
        headers: {
          _token: userData?.token,
        },
        withCredentials: true,
      })
      .then((response) => {     
        window.location.reload(5000);
        toast.success(response?.data?.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        setIsSubmit(false);
      })
      .catch((error) => {
        console.log(error, "error");
        setIsSubmit(false);
      });
  };

  return (
    <>
      <PageName PageName="Manage Plan Enquiry" />

      <article>
        <div className="main">
          {isLoading ? (
            <CircularLoader size={40} />
          ) : (
            <DataTableExtensions 
            {...tableData} 
            filterPlaceholder="Search Event"
            customStyles={customStyles}
            print={false} // Disable print functionality
             export={false}
            >
              <DataTable
                columns={columns}
                data={data}
                noHeader
                defaultSortField="id"
                sortIcon={<SortIcon />}
                defaultSortAsc={true}
                pagination
                highlightOnHover
                dense
                customStyles={customStyles}
              />
            </DataTableExtensions>
          )}
        </div>
        <div
        className="modal fade"
        id="planselect"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-md">
          <div className="modal-content EditStatus_form">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Edit Status
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <form 
              onSubmit={handleUpdate}
              >
                <div className="row g-3 mb-3">
                  <div className="col">
                    <textarea
                      className="form-control"
                      placeholder="Enter Remarks"
                      aria-label="Name"
                      value= {enquiryMsg}
                      disabled
                    ></textarea>
                  </div>
                  <div className="col " >
                    <select
                      name="type"
                      className="form-select register_inputfield"
                      value={selectedOption}
                      onChange={handleDropdownChange}
                    >
                      <option value="pending">Pending</option>
                      <option value="confirm">Confirm </option>
                      <option value="cancel">Cancel</option>
                    </select>
                  </div>
                </div>
               <div class="ChangesEditform">
               <button
                  type="button"
                  className="btn btn-primary save-cta"
                  onClick={(e) => handleSubmit(e)}
                >
                  {isSubmit ? <CircularLoader size={20} /> : " Save changes"}
                </button>
               </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      </article>
    </>
  );
}

export default Plan_enquiry;
