import React from "react";
import { Link } from "react-router-dom";
function PageName(props) {
  return (
    <>
      <div class="app-title ">
        <div>
          {/* <h1>
            {props?.icon} {props?.PageName}
          </h1> */}
        </div>
        <ul class="app-breadcrumb breadcrumb">
          <li class="breadcrumb-item"> {props?.PageName}</li>
          <li class="breadcrumb-item"><Link to="/">Home</Link></li>
        </ul>
      </div>
    </>
  );
}

export default PageName;
