import React, { useContext, useEffect, useState, useRef, useCallback } from "react";
import PageName from "../Utils/PageName";
import { environmentVariables } from "../../config/env.config";
import axios from "axios";
import { userContext } from "../../context/userContext";
import { ToastContainer, toast } from "react-toastify";
import DataTable from "react-data-table-component";
import SortIcon from "@material-ui/icons/ArrowDownward";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import CircularLoader from "../../Backend/Components/CircularLoader/CircularLoader";
import Swal from 'sweetalert2';

function Events_admin() {
  const [data, setData] = useState([]);
  const [totalCount, setTotalCount] = useState([]);
  const [loading, setLoading] = useState(true);
  const baseUrl = environmentVariables?.apiUrl;
  const { userData, setUserData } = useContext(userContext);
  const [isEvent, setIsEvent] = useState(false);
  const [eventName, setEventName] = useState("");
  const [eventDate, setEventDate] = useState(null);
  const [isStatus, setIsStatus] = useState(false);
  const [location, setLocation] = useState("");
  const [offer, setOffer] = useState("");
  const [description, setDescription] = useState("");
  const createCouponModalRef = useRef(null);
  const [paginationTotalRows, setPaginationTotalRows] = useState(0);
  const [paginationPerPage, setPaginationPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedRowsPerPage, setSelectedRowsPerPage] = useState([]);
  const [isUpdate, setIsUpdate] = useState(false);
  const [eventId, setEventId] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [rowsPerPage, setRowsPerPage] = useState(10); //change to 10 after you remove paginationRowsPerPageOptions
  const [page, setPage] = useState(1); //change to 10 after you remove paginationRowsPerPageOptions

  const fetchEvents = async (page = 1, rowsPerPage) => {
    try {
      axios
        .get(`${baseUrl}api/v1/events/getAllEvents?page=${page}&limit=${rowsPerPage}`, {
          headers: {
            _token: userData?.token,
          },
          withCredentials: true,
        })
        .then((response) => {
          setData(response?.data?.events);
          setTotalCount(response?.data?.pagination?.totalCount);
          setPaginationTotalRows(response?.data?.pagination?.totalCount);
          setIsLoading(false)

        })
        .catch((error) => {
          console.log(error);
          setIsLoading(false)
        });
    } catch (err) { }
  };
  useEffect(() => {
    fetchEvents(page, rowsPerPage);
    return () => {
      $(createCouponModalRef.current).off("hidden.bs.modal");
    };
  }, []);

  const handleClearState = () => {
    try {
      // e.preventDefault();
      setEventName("");
      setEventDate("");
      setLocation("");
      setOffer("");
      setDescription("");
      setEventId("");
    } catch (err) {
      console.log(err, "error");
    }
  };
  const handleEdit = (el) => {
    try {
      setIsUpdate(true);
      setEventId(el?.id);
      setEventName(el?.name);
      setEventDate(el?.Date)
      setLocation(el?.Location);
      setOffer(el?.offers);
      setDescription(el?.Summary);
      $(createCouponModalRef.current).modal("show");
    } catch (err) {
      console.log(err, "error");
    }
  };

  const handleShow = (el) => {
    try {
      setIsUpdate(false); // Set isUpdate to false when the eye button is clicked
      setEventId(el?.id);
      setEventName(el?.name);
      setEventDate(el?.Date)
      setLocation(el?.Location);
      setOffer(el?.offers);
      setDescription(el?.Summary);
      $(createCouponModalRef.current).modal("show");
    } catch (err) {
      console.log(err, "error");
    }
  };

  const handleDelete = async (d) => {
    try {
      // Display confirmation dialog using Swal.fire
      const result = await Swal.fire({
        title: "Are you sure?",
        text: `You won't be able to revert this!`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      });

      // Check if the user confirmed the deletion
      if (result.isConfirmed) {
        // Proceed with the deletion API call
        let { data: res } = await axios.delete(
          `${baseUrl}api/v1/events/deleteEvent/${d?.id}`,
          {
            headers: {
              _token: userData?.token,
            },
          }
        );

        // Check the response from the API call
        if (res && res?.success) {
          fetchEvents(page, rowsPerPage);
          toast.success(res?.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
        }
      }
    } catch (err) {
      console.log(err, "error");
    }
  };



  const columns = [
    {
      name: "Event Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Location",
      selector: (row) => row.Location,
      sortable: true,
    },
    {
      name: "Date",
      selector: (row) => row.Date,
      sortable: true,
    },
    {
      name: "Offers",
      selector: (row) => row.offers,
      sortable: true,
    },
    {
      name: "Total Business",
      selector: (row) => row.businessCount,
      sortable: true,
    },
    {
      name: "Summary",
      selector: (row) => {
        return row.Summary !== '' ? row.Summary : "--";
      },
      sortable: true,
    },
    {
      name: "Action",
      sortable: false,
      cell: (d) => [
        <>
          <i
            key={`edit-${d.id}`}
            className="first fas fa-eye"
            onClick={() => {
              handleShow(d);
            }}
          ></i>
          <i
            key={`edit-${d.id}`}
            className="first fas fa-pen"
            onClick={() => {
              handleEdit(d);
            }}
          ></i>
          <i
            key={`trash-${d.id}`}
            className="fas fa-trash-alt"
            onClick={() => handleDelete(d)}
          ></i>{" "}
          &nbsp;&nbsp; &nbsp;{" "}


        </>
      ],
    },
  ];

  const customStyles = {

    headRow: {
      style: {
        display: "flex",
        alignItems: "stretch",
        width: "100%",
        backgroundColor: "#E9E9E9",
        minHeight: "52px",
        borderRadius: "2px 2px 0px 0px",
        padding: "8px 25px",
        border: "none",

      },
    },
    headCells: {
      style: {
        color: "#525252",
        fontSize: "16px",
        minHeight: "52px",
        fontSize: "15px",

      },
    },
    rows: {
      style: {
        padding: "20px 25px",
        fontSize: "14px",
        textTransform: "capitalize",
        minHeight: "48px ",
      },
    },
  };



  const tableData = {
    columns,
    data,
  };

  const handleSubmit = async (e) => {
    try {
      if (isUpdate == false && eventId == "") {
        if (eventName == "" || eventName?.trim() == "") {
          toast.error("Event name is mandatory", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
          return;
        } else if (location == "" || location?.trim() == "") {
          toast.error("location is mandatory", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
          return;
        } else if (eventDate == "" || eventDate?.trim() == "") {
          toast.error("eventDate is mandatory", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
          return;
        }
      }

      let obj = {
        name: eventName,
        Date: eventDate,
        Location: location,
        offers: offer,
        Summary: description,
      };
      let res;
      // setIsStatus(true);
      if (isUpdate == true && eventId != "") {
        res = await axios.put(
          `${baseUrl}api/v1/events/editEvent/${eventId}`,
          obj,
          {
            headers: {
              _token: userData?.token,
            },
          }
        );
      } else {
        res = await axios.post(`${baseUrl}api/v1/events/createEvent`, obj, {
          headers: {
            _token: userData?.token,
          },
        });
      }

      if (res && res?.data && res?.data?.success) {
        fetchEvents(page, rowsPerPage);
        handleClearState();
        toast.success(res?.data?.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        // Close the modal backdrop and reset the modal
        $(createCouponModalRef.current).modal("hide");
        // This line above manually closes the modal and its backdrop
      }
    } catch (err) {
      toast.error(err?.response?.data?.message, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
    }
  };

  const handlePageChange = (page) => {
    fetchEvents(page, rowsPerPage);
    setCurrentPage(page);
  };

  const handleRowsPerPageChange = async (newRowsPerPage) => {
    if (!data.length) return; 
    fetchEvents(1, newRowsPerPage);
    setRowsPerPage(newRowsPerPage);
    setCurrentPage(1);
    setSelectedRowsPerPage([]);
  };
  

  return (
    <>
      <PageName PageName="Manage Events" />

      <article>
        <div className="main">

          <div className="eventstyling">
            <button
              id="Currency_admin_button"
              type="button"
              className="save-cta"
              onClick={(e) => setIsEvent(!isEvent)}
              data-bs-toggle="modal" data-bs-target="#createCouponModal"
            >
              + Create Event{" "}
            </button>
            <div
              class="tile bbox Total_Titlebox_container"
              style={{
                minHeight: "107px",
                minWidth: "100px",
                margin: "0 15px",
              }}
            >
              <i class="fa-solid fa-business-time"></i>
              <h3>Total Event</h3>
              <h5 className="tlcounts">{totalCount}</h5>
            </div>
          </div>



          {isLoading ? (
            <CircularLoader size={40} />
          ) : (
            <DataTableExtensions
              {...tableData}
              filterPlaceholder="Search Event"
              customStyles={customStyles}
              print={false} // Disable print functionality
              export={false}
            >
              <DataTable
                columns={columns}
                data={data}
                noHeader
                defaultSortField="id"
                sortIcon={<SortIcon />}
                defaultSortAsc={true}
                pagination
                highlightOnHover
                paginationServer
                paginationTotalRows={paginationTotalRows}
                paginationPerPage={paginationPerPage}
                paginationRowsPerPageOptions={[
                  10, 20, 50, 100, 300, 600, 1000
                ]} // Customize as needed
                onChangePage={handlePageChange}
                onChangeRowsPerPage={handleRowsPerPageChange}
                dense
                customStyles={customStyles}
              />
            </DataTableExtensions>
          )}
        </div>
        <div
          className={`modal fade ${isEvent ? "show" : ""}`}
          id="createCouponModal"

          tabIndex="-1"
          ref={createCouponModalRef}
        >
          <div class="modal-dialog modal-xl">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title">
                  {" "}

                  {/* {isUpdate && eventId != "" ? "Edit" : "Create New"} Event */}
                  {!isUpdate && eventId !== "" ? "Event Details" : (isUpdate && eventId !== "" ? "Edit Event" : "Create New Event")}
                </h5>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={(e) => handleClearState(e)}
                ></button>
              </div>
              <div class="modal-body" style={{ padding: "0" }}>
                <div className="mb-2" id="Coupons_admin_createform_container">
                  <div>
                    <input
                      placeholder="Name"
                      type="text"
                      disabled={!isUpdate && eventId !== "" ?true:false}
                      value={eventName}
                      className="form-control"
                      onChange={(e) => setEventName(e.target.value)}
                    />
                  </div>

                  <div>
                    <input
                      placeholder="Location"
                      type="text"
                      className="form-control"
                      disabled={!isUpdate && eventId !== "" ?true:false}
                      value={location}
                      onChange={(e) => setLocation(e.target.value)}
                    />
                  </div>
                  <div>
                    <input
                      class="form-control"
                      type="date"
                      placeholder="Date"
                      value={eventDate}
                      disabled={!isUpdate && eventId !== "" ?true:false}
                      onChange={(e) => setEventDate(e.target.value)}
                    />
                  </div>


                </div>
              </div>
              <div class="modal-body" style={{ padding: "0" }}>
                <div className="mb-2">
                  <div>
                    <input
                      placeholder="Offer"
                      type="text"
                      className="form-control"
                      disabled={!isUpdate && eventId !== "" ?true:false}
                      value={offer}
                      onChange={(e) => setOffer(e.target.value)}
                    />
                  </div>
                  <div>
                    <input
                      placeholder="Description (optional)"
                      type="text"
                      className="form-control"
                      disabled={!isUpdate && eventId !== "" ?true:false}
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                    />
                  </div>
                </div>
              </div>

              <div class="modal-footer" id="Coupanadmin_Button_Wrapper">
                {!isUpdate && eventId !== "" ? null : (
                  <button
                    id="Coupan_edit_Button"
                    type="button"
                    class="btn btn-primary"
                    onClick={(e) => handleSubmit(e)}
                  >
                    {isStatus ? (
                      <CircularLoader size={20} />
                    ) : isUpdate && eventId != "" ? (
                      "Update"
                    ) : (
                      "Create"
                    )}
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </article>
    </>
  );
}

export default Events_admin;
