import React,{ useContext,useState,useEffect } from 'react'
import { AddOrderContainer, AddorderContainerWrapper, AddorderImage, AddorderIncrement, AddorderIncrementWrapper, AddorderText, AddorderTextContainer, AddorderValue, Addorderseller, Cross, CrossWrapper, IncrementSpan, NewTabMenuHeadingWrapper, NewTabMenuText, NewTabMenuWrapper, NewTabOrderMenuWrapper, OrderStatus, OrderStatusCardWrapper, OrderStatusCardWrapper2, OrderStatusKeyValueWrapper, OrderStatusValue, OrderStatuskey, OrderstatusContainernew,SpancurrencySymbol } from './Theme14ExtStyleComponent'
import OrderIcon from "../../../../Images/Restaurant/order.png";
import { environmentVariables } from "../../../../config/env.config";
import { userContext } from "../../../../context/userContext";

const TabOrder = ({orderItems, removeItem, updateTotalAmount,setOrderItems,rs}) => {
  const [itemQuantities, setItemQuantities] = useState({});
  const { userData } = useContext(userContext);

  const [currencySymbol, setCurrencySymbol] = useState('₹');

    useEffect(() => {
    const initialQuantities = {};
    orderItems.forEach((item, index) => {
      initialQuantities[index] = item.quantity || 1;
    });
    setItemQuantities(initialQuantities);
  }, [orderItems]);

  useEffect(() => {
    if (rs?.contactInfo?.content) {
      const contactInfo = JSON.parse(rs.contactInfo.content);
      const phoneNumber = contactInfo.contactInfoPhone;
      if(!!phoneNumber){
        const countryCode = phoneNumber.match(/^\+(\d+)/)[1]; // Extract the country code

        switch (countryCode) {
          case '91':
            setCurrencySymbol('₹');
            break;
          case '1':
            setCurrencySymbol('$');
            break;
          case '971':
            setCurrencySymbol('AED');
            break;
          // Add other country codes and their currency symbols as needed
          default:
            setCurrencySymbol('₹'); // Default to INR
            break;
        }
      }
    }
  }, [rs?.contactInfo]);

  const increaseQuantity = (index,data) => {
    setItemQuantities((prevQuantities) => ({
      ...prevQuantities,
      [index]: (prevQuantities[index] || 1) + 1,
    }));
  };

  const decreaseQuantity = (index,data) => {
    if (itemQuantities[index] > 0) {
      setItemQuantities((prevQuantities) => ({
        ...prevQuantities,
        [index]: prevQuantities[index] - 1,
      }));
    }
  };
useEffect(()=>{
for(let el in itemQuantities){
let get=  orderItems.map((elem,index)=>{
    if(index==el){
      elem.quantity=itemQuantities[el]
    } return el
  })
}
},[itemQuantities])
  const handleRemoveItem = (index) => {
    removeItem(index);
    setItemQuantities((prevQuantities) => {
      const updatedQuantities = { ...prevQuantities };
      delete updatedQuantities[index];
      return updatedQuantities;
    });
  };

  const calculateTotalPrice = (price, quantity) => {
    return price * quantity;
  };

  const calculateCartTotal = () => {
    let total = 0;
    orderItems.forEach((item, index) => {
      total += calculateTotalPrice(item.price, itemQuantities[index] || 1);
    });
    return total;
  };

  useEffect(() => {
    updateTotalAmount(calculateCartTotal());
  }, [orderItems, itemQuantities]);

  return (
    <NewTabOrderMenuWrapper style={{paddingBottom:"25px"}}>
    {/* <NewTabMenuHeadingWrapper>
        <NewTabMenuText> Orders</NewTabMenuText>
    </NewTabMenuHeadingWrapper> */}
  <OrderstatusContainernew>
  {orderItems.length === 0 ? (
          <div style={{
            fontFamily: "Plus Jakarta Sans",
            fontWeight: 400,
            fontSize: "20px",
            color: "#101010",
            textAlign: 'center',
            height: '500px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
            Oops! Your cart is empty.
          </div>
        ) : (
    orderItems.map((item, index) => (
          <OrderStatusCardWrapper2 key={index} >
            <AddOrderContainer>
              <AddorderContainerWrapper>
                {/* <AddorderImage src={`${environmentVariables.apiUrl}uploads/${item.image}`} /> */}
                <AddorderImage src={
                                    item?.image === "No image available"
                                        ? `${environmentVariables.apiUrl}uploads/No image available.png`
                                        : item?.image.includes('/')
                                        ? `https://businessbay.b-cdn.net${item?.image}`
                                        : `${environmentVariables.apiUrl}uploads/${item?.image}`
                                    } />
                {/* Render any other details you need for each item */}
                <Addorderseller />
              </AddorderContainerWrapper>
            </AddOrderContainer>
            <AddorderTextContainer>
              <AddorderText>{item.name}</AddorderText>
              <AddorderValue><SpancurrencySymbol>{currencySymbol} </SpancurrencySymbol>{calculateTotalPrice(item.price, itemQuantities[index] || 1)}</AddorderValue>
              {/* Render any other details you need for each item */}
              <AddorderIncrementWrapper>
                <AddorderIncrement>
                  <IncrementSpan onClick={() => decreaseQuantity(index,item)}>-</IncrementSpan> 
                  {itemQuantities[index] || 1} 
                  <IncrementSpan onClick={() => increaseQuantity(index,item)}>+</IncrementSpan>
                </AddorderIncrement>
              </AddorderIncrementWrapper>
            </AddorderTextContainer>
            <CrossWrapper>
            <Cross onClick={() => handleRemoveItem(index)}>+</Cross>
            </CrossWrapper>
          </OrderStatusCardWrapper2>
        ))
       )}
  </OrderstatusContainernew>
</NewTabOrderMenuWrapper>
  )
}

export default TabOrder